import * as userRatingsActionTypes from "../../../actionTypes";
const initialState = {
  userRatings:[],
  userReviews: [],
  isProfileDataAdded:false
}

export default function(state=initialState, action) { 
  switch (action.type) {
    case userRatingsActionTypes.USER_RATINGS_SET_STATE_ELEMENTS: {
      const {name,content } = action.payload; 
      return {
        ...state,[name]:content
      }
    }
    default:
      return state;
  }
}