import React, {Component} from "react";
import FaceRecognition from '../faceRecognition'
import ObjectDetection from '../objectDetection';





let myvar;

class ObjectAndFeceRecognition extends Component{
  
    constructor(props){
        super(props);
        this.state = {
          
        }
    }
    
  
  componentDidMount() {
   var myvar=sessionStorage.getItem('proctoring')
   if(myvar=='true'){
    window.location.reload()
   }
   
    // var myvar = setTimeout(window.location.reload, 5000);
  //  var myvar= window.location.reload()
    
  //  clearTimeout(myvar)
  
   }

//     componentDidMount(){
//      this.objDetectionLoad()
// }
// backToProctoring=(props)=>{
//   props.history.push("/sub-admin/courses")
// }
    

    
    
   
    render(){
      
        return (
            <div className="">
              <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingBottom: 10}}>
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
                    <div className="col-md-12 col-sm-12 nj-card" >
                      <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                        <div className="nj-card__header">
                          <div className="nj-card__title">
                            <h4 className="font_family_montserrat">Face Recognition and Object Detection</h4>
                          </div>
                          {/* <div style={{paddingBottom:'9px'}}>
                            <button className="nj-flow-button pull-right" type="button" onClick={() => this.props.history.push('/sub-admin/proctoring')}>Back</button>
                          </div> */}
                        </div>
                      </div>
                      <div>
                        <div className="row">
                          <div className="col-md-12">
                            <div>
                              <FaceRecognition  />
                            </div>
                            <div style={{marginTop:"10px",marginBottom:"40px"}}>
                               <ObjectDetection  />
                            </div>                         
                          </div>
                      </div>
                     </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
    }
}
 export default ObjectAndFeceRecognition;

 
  

  

