import React, { useState, useEffect } from 'react';
import './ViewPlaylist.scss';
import { connect } from "react-redux";
import * as viewPlaylistContainer from "../../container/actions/ViewPlaylist/action";
import * as resourceContainer from "../../container/actions/Resources/action";
import { bindActionCreators } from 'redux';
import VideoPlayer from '../../packages/VideoPlayer';
import snackbar from '../../packages/Snackbar/index';

function ViewPlaylist (props) {
  // const [playlistArray,setPlaylistArray] = useState([])
  const [showQuiz,setShowQuiz] = useState(true)
  const [quiz,setQuiz] = useState([])
  var userDetails=JSON.parse(sessionStorage.getItem("userData"));
  var  msg="Please take quiz";
  

  useEffect(()=>{
    props.getPlaylistResource();
  },[])
  
  const handleReturn = () =>{
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    if(userDetails.type===0){
      props.history.push('/user/courses');
    } else if(userDetails.type===1){
      props.history.push('/resources');
    } else if(userDetails.type===2){
      props.history.push('/dlt/resources');
    }else if(userDetails.type===4){
      props.history.push('/shared/resources');
    }else{
      props.history.push('/sub-admin/resources');
    }
  }
  const handleNext = () =>{
    if(showQuiz===true){
      setShowQuiz(false)
    }
   else{
      setShowQuiz(true)
   }

  }
  
  const submitAnswer=(e,question)=>{
     var obj={};
     const dummyArray=["a","b","c","d","e","f","g","g","h","i","j","k","l","m","n"];
     var value=JSON.parse(e.target.value)
    
     obj.quizQuestionId=question.id;
     obj.question=question.question;
     obj.correctOption=question.correctOption;
     obj.correctAnswer=question.correctAnswer.option;
     obj.selectedOption=dummyArray[value.optionIndex];
     obj.selectedAnswer=value.option;
     obj.grade=question.grade;
     
     var array=[];
    //  console.log("quiz",JSON.parse(JSON.stringify(quiz)));
     array=JSON.parse(JSON.stringify(quiz));
     array.push(obj);
     setQuiz(array)
  }

  var handleSubmit=()=>{
    if( quiz.length===0){
      props.setViewPlaylistElements("errorAlertMessage",msg);
      snackbar.errorSnackbar();
    }
    else{
    props.handleSubmit(props.viewPlaylistState.playlistName,props.viewPlaylistState.selectedPlaylistTitle.title,props.viewPlaylistState.selectedPlaylistSubtitle,quiz);
    handleNext();
    setQuiz([]);
  }
  }
  return (
    <div>
    
      <div className="add-on-load-animation">
       
        <div id="successSnackbar">{props.viewPlaylistState.successAlertMessage}</div>
        <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.viewPlaylistState.errorAlertMessage}</div>
        <div className="col-md-3 ca-left-tab nj-card hidden-xs hidden-sm margin-bottom-sm-35">
          <div className="row">
            <div className="nj-card__header col-md-12 tbl-content preventCopy" style={{paddingTop:'9px',maxHeight:"92vh"}}>
              {props.viewPlaylistState.playlistArray.length>0 && props.viewPlaylistState.playlistArray.map((data,index)=>{
                return (
                  <div key={data.id} className="administrator-names_style col-md-12 col-xs-12 col-sm-12 " style={{marginBottom:"5px"}}>
                    <div className={"administrator-names_style col-md-12 administrator-child_style"} onClick={()=>{props.setSelectedPlaylistTitle(data);setQuiz([]);setShowQuiz(true)}} style={{fontWeight:'500'}}> 
                      {data.title}
                    </div>
                    {props.viewPlaylistState.selectedPlaylistTitle.id===data.id && <div id="div-height" className={"nj-card__header col-md-12 tbl-content "+((props.viewPlaylistState.selectedPlaylistTitle.id===data.id) ? "expandDownDropDown":"expandUpDropDown")} style={(props.viewPlaylistState.selectedPlaylistTitle.id===data.id ? {display:"block",paddingTop:'9px',maxHeight:"50vh"}:{display:"none"})}>
                      {data.subtitle.map((subtitle,index1)=>{
                        return (
                          <div key={subtitle.id} className={"administrator-names_style administrator-child_style col-md-12 "+((props.viewPlaylistState.selectedPlaylistSubtitle.id===subtitle.id)?'selected_cp_bg_color':'')} onClick={()=>{props.setSelectedPlaylistSubtitle(subtitle);setQuiz([]);setShowQuiz(true)}}><span>{subtitle.title}</span></div>   
                        )
                      })} 
                    </div>}
                  </div>   
                )
              })}  
            </div>
          </div>
        </div>
        <div className="col-sm-12 hidden-md-ca-dropdown">
          <div className="row">
          <label>Select Title</label>
            {props.viewPlaylistState.playlistArray.length>0 && <div className="nj-form-select" style={{marginBottom:15}}>
              <select name="selectedCustomerAdminForSmallDevice" value={JSON.stringify(props.viewPlaylistState.selectedPlaylistTitle)} onChange={($event)=>props.setViewPlaylistElements('selectedPlaylistTitle',JSON.parse($event.target.value))} autoComplete="off" required>
                {props.viewPlaylistState.playlistArray.map((item,index)=>{
                  return (
                    <option value={JSON.stringify(item)} key={index}>{item.title}</option>  
                  )
                })}
              </select>
            </div>}
          </div>
        </div>
        <div className="col-sm-12 hidden-md-ca-dropdown">
          <div className="row">
          <label>Select Subtitle</label>
            {Object.keys(props.viewPlaylistState.selectedPlaylistTitle).length>0 && props.viewPlaylistState.selectedPlaylistTitle.subtitle.length>0 && <div className="nj-form-select" style={{marginBottom:15}}>
              <select name="selectedCustomerAdminForSmallDevice" value={JSON.stringify(props.viewPlaylistState.selectedPlaylistSubtitle)} onChange={($event)=>props.setViewPlaylistElements('selectedPlaylistSubtitle',JSON.parse($event.target.value))} autoComplete="off" required>
                {props.viewPlaylistState.selectedPlaylistTitle.subtitle.map((item,index)=>{
                  return (
                    <option value={JSON.stringify(item)} key={index}>{item.title}</option>  
                  )
                })}
              </select>
            </div>}
          </div>
        </div>
        <div className="col-md-9 col-sm-12 ca-right-tab">
          <div className="row nj-card ca-tab-wrap">
            <div className="nj-card__header">
              <div className="nj-card__title">
                <h4>{Object.keys(props.viewPlaylistState.selectedPlaylistSubtitle).length>0 && props.viewPlaylistState.selectedPlaylistSubtitle.title}</h4>
              </div>
             
              {showQuiz?<div>
              <button type="button" className="nj-flow-button" style={{marginTop:'-10px'}} onClick={handleReturn}>Back</button>
              {props.viewPlaylistState.selectedPlaylistSubtitle.quizquestions!==undefined&&<span>
                {props.viewPlaylistState.selectedPlaylistSubtitle.quizquestions.length?
                  <button type="button" className="nj-flow-button" style={{marginTop:'-10px',marginLeft:"20px"}} onClick={handleNext}>Quiz</button>:null
                }
             </span>}
            </div>:<div>
              <button type="button" className="nj-flow-button" style={{marginTop:'-10px'}} onClick={handleNext}>Back</button></div>}
            </div>
          </ div>
          {showQuiz ?
          <div className="row nj-card ca-tab-wrap">
            <div className="col-md-12">
              <p style={{fontSize:16,fontWeight:'500',marginTop:10}}>Resources</p>
           
              <div className="resources-wrapper">
                {Object.keys(props.viewPlaylistState.selectedPlaylistSubtitle).length>0 && props.viewPlaylistState.selectedPlaylistSubtitle.resources.map((item, i) => {
                    //  {console.log("resourcessss",item)}
                  return (
                    <div className="resource_card add-rebound-animation" key={i}>
                      <div className="resource_card-content">
                        {item.type===2 && <div className="resource_card-content-img-wrapper"  onClick={()=>props.openResourceLink(item,window,props.history)}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                        </div>}
                        {item.type===0 && <div className="resource_card-content-img-wrapper"  onClick={()=>props.openResourceLink(item,window,props.history)}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource" />
                        </div>}
                        {item.type===4 && <div className="resource_card-content-img-wrapper"  onClick={()=>props.openResourceLink(item,window,props.history)}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/file.svg"} alt="resource" />
                        </div>}
                        <div className="resource-card-float" style={{marginTop:'-25px',paddingRight:5}}>
                        <div className="pull-left" style={{marginLeft:"5px"}}>
                            <span style={{color:"rgb(99, 112, 173)",verticalAlign:"middle"}} title="Time investment in minutes" >
                              <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock">
                                <circle cx={12} cy={12} r={10} />
                                <polyline points="12 6 12 12 16 14" />
                             </svg>
                            <span style={{verticalAlign:"top",fontSize:11,paddingLeft:2}}>{parseFloat(parseFloat(parseInt(item.file[0].timeInvestment)/60).toPrecision(2))+" Min"}</span>     
                            </span>
                        </div>
                      </div>
                      </div>
                      <div className="resource_card-footer" onClick={()=>props.openResourceLink(item,window,props.history)}>
                        <p className="font_family_montserrat">{item.displayName}</p>
                      </div>
                    </div>
                  );
                })}
              </div>  
            </div>
          </div> :
         
          <div className="row nj-card ca-tab-wrap">
            <div className="col-md-12">
              <div className="row">
                  <div className="nj-card__header">
                    <div className="nj-card__title">
                      <h4>Quiz</h4>
                    </div>
                  </div>
              </div>
              <div className="col-md-12" >
                <div className="row" style={{padding:'10px 0'}}>
                  {Object.keys(props.viewPlaylistState.selectedPlaylistSubtitle).length>0  && 
                <div>
                 
                     {props.viewPlaylistState.selectedPlaylistSubtitle.quizquestions.map((quizquestion,questindex)=>{
                      return(
                        
                        <div  key={quizquestion.id}>
                         < div className="row col-md-12 col-sm-12">
                            <div className="col-md-10 col-sm-10"  style={{fontSize:16,fontWeight:'500',paddingTop:"10px",paddingBottom:"10px"}}>
                                Q{questindex+1} : {(JSON.parse(JSON.stringify(quizquestion.question)))}
                            </div>
                            <div className="col-md-2 col-sm-2" style={{paddingBottom:"px",fontSize:14,fontWeight:'500'}} >
                              <span>Grade : {(JSON.parse(JSON.stringify(quizquestion.grade)))}</span>
                          </div>
                          </ div>
                          <div className="col-md-8  col-sm-8">
                            {quizquestion.options.map((option,index)=>{
                              if(option.option!==""){
                              return (
                                <div  key={option.id}>
                                  <div className="col-md-6  col-sm-6">
                                    <label className="container">{option.option}
                                    {userDetails.type === 0 ? <span> <input type="radio" name={questindex}  onClick={(e)=>submitAnswer(e,quizquestion)} value={JSON.stringify(option)}/><span className="checkmark"></span></span>:<span className="checkmark1">{index+1})</span>}
                                    </label>
                                  </div>
                                </div>
                          
                                )}})}
                            </div>
                            {userDetails.type!==0 && <div className="col-md-8  col-sm-8" style={{paddingBottom:"10px",fontSize:14,fontWeight:'420'}} >
                              <span style={{color:"blue"}}>Answer : {quizquestion.correctAnswer.option}</span>
                          </div>}
                        </div>
                        )
                      })} 
                  </div>}
                </div>
            </div>

            {userDetails.type === 0 &&
            <div className="resource-proceed pull-right">
                  <button className="nj-flow-button" type="button"   style={{marginLeft:10}} onClick={()=>handleSubmit()}>Submit</button>
            </div> }

          </div></div>}
         
        </div>
        {/*popup to play resources*/}
        <div id="playplayListVideoPopup" className={"nj-overly add-rebound-animation"}>
          <div className="popup-nj  my-popup" style={{maxWidth:700}}>
            <header className="popup-header">
              <span>{props.viewPlaylistState.resourceVideo.displayName}</span>
              <span onClick={props.closeVideoPopup}><i className="popup-close material-icons">close</i></span>
            </header>
            {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
            <div className="popup-content">
              <div className="col-md-12">
                {Object.keys(props.viewPlaylistState.resourceVideo).length>0 && <VideoPlayer link={props.viewPlaylistState.resourceVideo.file[0].path} key={props.viewPlaylistState.resourceVideo._id} resourceId={props.viewPlaylistState.resourceVideo._id} currenttime={0} />}
              </div>
            </div>
          </div>
        </div>
        {/* popup for pdf open */}
        <div id="userPdfPopup" className={"nj-overly add-rebound-animation"}>
          <div className="popup-nj  my-popup" style={{maxWidth:750}}>
            <header className="popup-header">
              <span id="userPdfPopupTitle" style={{textTransform:'capitalize'}}></span>
              <span onClick={()=>{document.getElementById('userPdfPopup').style.display='none'}}><i className="popup-close material-icons">close</i></span>
            </header>
            <div className="popup-content">
              <div className="col-md-12">
                {!props.resourceState.pdfLoader?<>
                <div id="canvas_container">
                  <canvas id="pdf_renderer"></canvas>
                </div>
                <div className="pdf-controls">
                  <div id="navigation_controls">
                    <button className="nj-flow-button" id="go_previous" onClick={props.goPreviousPage} style={{padding:'0 1rem',fontSize:16}}>{'<'}</button>
                    <input className="vs-input--input" id="current_page" defaultValue={1} type="number" onKeyDown={props.currentPage}  style={{width:40,margin:'0 5px',padding:0,textAlign:'center'}} />
                    <button className="nj-flow-button" id="go_next" onClick={props.goNextPage} style={{padding:'0 1rem',fontSize:16}}>{'>'}</button>
                  </div>
                  <div id="zoom_controls">  
                    <span style={{marginRight:5,fontWeight:'500'}}>Zoom</span>
                    <button className="nj-flow-button" id="zoom_out" onClick={props.zoomOutPage}  style={{padding:'0 1rem',fontSize:16,marginRight:5}}>-</button>
                    <button className="nj-flow-button" id="zoom_in" onClick={props.zoomInPage}  style={{padding:'0 1rem',fontSize:16}}>+</button>
                  </div>
                </div>
                </>:
                <div className="" style={{height:"42vh",textAlign:'center'}}>
                  <div className="col-md-12" style={{marginTop:"15vh"}}>
                    <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
  viewPlaylistState: state.viewPlaylistState,
  resourceState:state.resourceState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getPlaylistResource:viewPlaylistContainer.getPlaylistResource,
  setSelectedPlaylistTitle:viewPlaylistContainer.setSelectedPlaylistTitle,
  setSelectedPlaylistSubtitle:viewPlaylistContainer.setSelectedPlaylistSubtitle,
  openResourceLink:viewPlaylistContainer.openResourceLink,
  closeVideoPopup: viewPlaylistContainer.closeVideoPopup,
  setViewPlaylistElements: viewPlaylistContainer.setViewPlaylistElements,
  handleSubmit:viewPlaylistContainer.handleSubmit,
  togglePdf:resourceContainer.togglePdf,
  renderPdf:resourceContainer.renderPdf,
  goPreviousPage:resourceContainer.goPreviousPage,
  goNextPage:resourceContainer.goNextPage,
  currentPage:resourceContainer.currentPage,
  zoomInPage:resourceContainer.zoomInPage,
  zoomOutPage:resourceContainer.zoomOutPage
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(ViewPlaylist);
