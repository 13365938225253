import React,{Component} from 'react'
import '../faceRecognition/index.css';
import videojs from 'video.js';
import '../ObjectDetection and FaceRecognition/css/video.css';
import axios from 'axios';
// import 'videojs-markers';
import 'videojs-markers';
import video_1 from './json/sample_1.json';
import video_2 from './json/sample_2.json';
import video1 from './video/video_1.mp4'
// import video2 from './video/video_2.mp4'
// var vid = videojs('example_video_1');

class Identification extends React.Component{
   
   constructor(props) {
      super(props);
      this.state = {
            markers:video_1,
            videoNum:"vid2",
            videoSrc:require('./video/video_1.mp4'),
     

      };
   }

    objMarker=(objarray)=>{
      var video = videojs('FR_example_video');
      video.markers({     
            markers:objarray
           }); 


    }
    loadMarker=(markers)=>{
       console.log("markers",markers)
      var objMarkers=[];  
      for(var i=0;i<markers.length;i++){                                                      
          for(var k=0;k<markers[i].timestamp.length;k++){
            objMarkers.push(markers[i].timestamp[k])
            console.log(objMarkers)
          }          
       }  
      var video = videojs('FR_example_video');   

      video.markers.reset(   
         objMarkers
      );
      console.log("vidodomakdlkd",video.markers)
    }
    createMarker=(markers)=>{
      var objMarkers=[];  
      for(var i=0;i<markers.length;i++){                                                      
          for(var k=0;k<markers[i].timestamp.length;k++){
            objMarkers.push(markers[i].timestamp[k])
            console.log(objMarkers)
          }          
       }    
        this.objMarker(
         objMarkers
        )
        
    }

   componentDidMount(){  
      this.createMarker(this.state.markers);
      console.log("time checkfjhfhfehfhfff",this.state.markers)
      };
      handleMarkerClick(item){
         var video = videojs('FR_example_video');   
         video.markers.reset(   
         item.timestamp 
         );
         video.currentTime(item.timestamp[0].time)

         console.log("time check here ><>>>>>>>>>>>>>>>>>>>>>>>",item.timestamp)
         }

      handleChange=(e)=>{
            this.setState({videoNum:e.target.value},() => {console.log("oogogorgrgr",this.state.videoNum)})
            var videoRet=videojs('FR_example_video')
          

          
               this.setState({videoSrc:require('./video/video_1.mp4'),markers:video_2},() => {console.log("ttioyiyy",this.state.videoSrc)})
               videoRet.src({type:'video/mp4', src:video1});
               this.loadMarker(video_2);
            
            // }else{
            //    this.setState({videoSrc:require('./video/video_1.mp4'),markers:video_1},() => {console.log("rtddwqewe",this.state.videoSrc)})
            //    videoRet.src({type:'video/mp4', src:video1});
            //    this.loadMarker(video_1);
            // }
           
        
         }
    

render(){
      return(

      <div>
         <div className="obj-section">
            <div className="row">  
               <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
               <h4  className="title">Face Recognition</h4>               
               </div>
               <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <h4>Choose a video</h4>

                  <select  onChange={this.handleChange} className="video-list">
                     <option value="vid1" >video_1</option>
                     {/* <option value="vid2">video_2</option>                      */}
                  </select> 

               </div>
                 
               <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12"> 
                         
                <div> <video key={'vid1'}
               id="FR_example_video" 
               class="video-js vjs-default-skin"
               controls 
               width="640" 
               height="264"
               data-setup='{"example_option":true}'
               muted>
               <source src={video1} type="video/mp4"></source>
               </video> </div>
               </div>
               <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div>
                     <h4>Reference Image</h4>
                        <div className="video-label-boxes">
                           
                           <img  src={require('./refimages/test_1.jpg')} height={90} width={110}  alt="loader" />
                        </div>                       
                     
                     

                     </div>                      
                  <div>

                
                  <h4>People</h4>
                  <div className="col-md-4">
                  {
                  this.state.markers.map((item,index)=>(
                  <div key={index}>
                     <h6 className="label-list" onClick={()=>{this.handleMarkerClick(item)}}>{item.name}</h6><br/>                                                                        
                  </div>                
                  ))}
                  </div>  
                  </div>             
               </div> 
                           
            </div> 
         </div>
         
         
         
      </div>
      
  
      );
       
    }
  }

export default Identification;