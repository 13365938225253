import React, { useState, useEffect,useRef } from 'react';
// import axios from 'axios';
import './DLTeam.scss';
import snackbar from  '../../packages/Snackbar/';
import customHttp from '../../packages/CustomHttp';

  function DLTeam () {
  const obj = {"email":"",}
  const [createArray,setcreateArray]=useState(obj);
  const [getUserDetails,setGetUserDetails] = useState([]);
  const [emailExist,setEmailExist]=useState(false);
  const[userData , setUserData] = useState({});
  const[showLoader , setShowLoader] = useState(false);
  const[submitLoader,setSubmitLoader]=useState(false);
  const[confirmLoader,setConfirmLoader]=useState(false);
  const[successSnackbarMessage,setSuccessSnackbarMessage]=useState();
  const[errorSnackbarMessage,setErrorSnackbarMessage]=useState("");
  const[disableCancelButton,setCancelButtonDisable]=useState(false);
  const[errorEmail,setErrorEmail]=useState(false);
  const[valueCheck,setValueCheck]=useState();
  const[disabled,setDisabled]=useState(false);
  const[getDepartment,setGetDepartment] = useState([]);
  const[department,setDepartment] = useState("");
  const[submitDepartmentLoader,setSubmitDepartmentLoader] = useState(false);
  // const[createUserDepartment,setCreateUserDepartment] = useState("");
  const[loadDepartment,setLoadDepartment] = useState("");
  const[showCreateUser, setShowCreateUser] = useState(true);
  const[showCreateDepartment, setShowCreateDepartment] = useState(true);
  const[showUserList,setShowUserList] = useState(true);
  const[departmentData , setDepartmentData] = useState({});
  const[expanded, setExpanded] = useState(false);
  const[departmentArray, setDepartmentArray] = useState([]);
  const[departmentAlreadyExists,setDepartmentAlreadyExists]=useState(false);
  const node = useRef();
  const[noDepartment,setNoDepartment] = useState(false);
  const[departmentNameValidation,setDepartmentNameValidation]=useState(false);
  // Edit variables
  const[errorSnackbarMessageForEdit,setErrorSnackbarMessageForEdit]=useState();
  const[hideEditUserCancelButton,setHideEditUserCancelButton] = useState(true);
  const[editUserConfirmLoader,setEditUserConfirmLoader]=useState(true);
  const[editUserData,setEditUserData]=useState({});
  const[expandedEdit, setExpandedEdit] = useState(false);
  const[selectedDepartmentArrayForEdit, setSelectedDepartmentArrayForEdit] = useState([]);
  const[tempArray, setTempArray] = useState([]);
  const editSubNode = useRef();
  const[displayDept, setDisplayDept] = useState([]);
  const[noDepartmentwhileEditing,setNoDepartmentwhileEditing] = useState(false);

  const[createdUsersideDepartment,setCreatedUsersideDepartment]=useState("");

  useEffect(() => {
    // document.addEventListener('mousedown',handleClick);
    
    getDepartmentDetails();
    return () => {
      // document.removeEventListener("mousedown", handleClick);
    };
  },[])

  // var handleClick = (e) => {
  //   if(node.current!== null){
  //     if (node.current.contains(e.target)) {
  //     return;

  //     }
  //     else{

  //       setExpanded(false);
  //       document.getElementById('checkboxes').style.display="none";
  //     }
  //   }

  // }

  var loadUserDetails=(dept)=>{
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    var url = '/api/adminUser/getUser/type/'+2+'/belongsTo/'+userDetails.belongsTo+'/department/'+dept ;
    customHttp.get(url)
    .then(response => {
      console.log("rtyuioiytyrtrsdtfy",response)
    setGetUserDetails(response.data.message);
    setShowLoader(true);
    }).catch((error)=>{
      console.log("failed",error);
    });

  }


  var handleChangeCreateUser =(event)=>{
    //set value in variable once click in the input field
    setcreateArray({...createArray,[event.target.name]:event.target.value});
    setErrorEmail(false);
    setValueCheck(event.target.value);
    // axios.get("/api/user/validateUser/email/" + event.target.value.toLowerCase())
    var url = "/api/user/validateUser/email/" + event.target.value.toLowerCase() ;
    customHttp.get(url)
      .then(response => {
        setEmailExist(response.data.message.userExists);
        // let checkEmail = response.data;
        // console.log("checkEmail", checkEmail,response.data.message.userExists);
        let temp = response.data.message.userExists;
        if(!temp){
          console.log("email not registered")
        }else{
          console.log("email already taken")
        }
      }).catch(error => {
        console.log("failed", error);
      });

  }

  var formSubmit =(event)=>{
    event.preventDefault();
    var adminId=JSON.parse(sessionStorage.getItem("userData"))
    var userDetails={};
    userDetails.department = departmentArray
    userDetails.email = createArray.email;
    userDetails.type = 2;
    userDetails.adminId = adminId._id;
    userDetails.belongsTo = adminId.belongsTo
    // userDetails.department = createUserDepartment;
  

    //check email validation using regex it shows invalid error
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(valueCheck)=== false)
    {
      // console.log("incorrect email");
      setErrorEmail(true);
    }
    //check already existing email in db
    else if(emailExist===true){
      console.log("this email exist");
    }
    else if(departmentArray.length === 0){
      setNoDepartment(true);
  }
    else{
      setSubmitLoader(true);
      //post data to db
      // axios.post('/api/adminUser/createUser',userDetails)
      // console.log("userDetails",userDetails)
      var url = '/api/adminUser/createUser' ;
      customHttp.post(url , userDetails)
        .then(res => {
          setSubmitLoader(false);
          clearUserInputFields();
          setDepartmentArray([]);
          // showCheckboxes();
          document.getElementById('checkboxes').style.display="none";

          unCheckAllCheckbox();
          loadUserDetails(loadDepartment);
          snackbar.successSnackbar();
          setSuccessSnackbarMessage("User Created Successfully!");
        }).catch((error)=>{
          setSubmitLoader(false);
          snackbar.errorSnackbar();
          setErrorSnackbarMessage("Error try again.");
          console.log("There is an API error");
      });
    }
  }

  //onclick delete button popup will appear
  var getConfirmDeletePopup= async(data)=>{
    // console.log("delete data",data);
    setUserData(data);
    setConfirmLoader(false);
    setDisabled(false);
    document.getElementById("deleteUserPopup").style.display="block";
  }

  //onclick confirm button table row will delete
  var deleteRequestedContent=(e,data)=>{
    e.preventDefault();
    setConfirmLoader(true);
    setCancelButtonDisable(false);
    setDisabled(true);
    // console.log("msg",data);
    // axios.delete('api/adminUser/createUser',{data:data})
    var url = "/api/adminUser/createUser";
    customHttp.delete(url,data)
    .then(response=>{
      setConfirmLoader(false);
      setDisabled(false);
      document.getElementById("deleteUserPopup").style.display='none';
      loadUserDetails(data.department);
      snackbar.successSnackbar();
      setSuccessSnackbarMessage("User Deleted Successfully!");
    })
    .catch(error => {
      setConfirmLoader(false);
      setDisabled(false);
      snackbar.errorSnackbar();
      setErrorSnackbarMessage("Error try again.");
      console.log("error",error)
    });

  }

  var clearUserInputFields=()=>{
    setcreateArray({"email":""});
  }

  var hideDeletePopUp=(e)=>{
    e.preventDefault();
    setUserData("");
    // setDisabled(!disabled);
    document.getElementById("deleteUserPopup").style.display="none";
  }

  var handleChangeCreateDepartment=(event)=>{
      if (/^[0-9A-Za-z- ]*$/.test(event.target.value)){
        setDepartmentNameValidation(false)
      }else{
        setDepartmentNameValidation(true)
      }
      setDepartmentAlreadyExists(false)
      setDepartment(event.target.value);

  }

  var formSubmitDepartment =(e)=>{
    setSubmitDepartmentLoader(true);
    e.preventDefault();
    setDepartment("");
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    var obj = {};
    obj.name = department;
    obj.adminId = userDetails._id;
    obj.type = 2;
    obj.belongsTo = userDetails.belongsTo;
    var url = '/api/department/createDepartment' ;
        customHttp.post(url , obj)
          .then(res => {
            if(res.data.message === "department name already exists"){
              setDepartmentAlreadyExists(true)
              setSubmitDepartmentLoader(false);
              // getDepartmentDetails();
              // setErrorSnackbarMessage("Department already exists");
              // snackbar.errorSnackbar();
            }
            else{
              // setDepartment("")
              setSubmitDepartmentLoader(false);
              snackbar.successSnackbar();
              setSuccessSnackbarMessage("Department Created Successfully!");
              getDepartmentDetails();
            }
          }).catch((error)=>{
            setSubmitDepartmentLoader(false);
            setErrorSnackbarMessage("Error try again.");
            snackbar.errorSnackbar();
            console.log("There is an API error");
        });
  }

  var getDepartmentDetails =()=>{
    var userDetails = JSON.parse(sessionStorage.getItem("userData"))
    // let type = 2;
    let belongsTo = userDetails.belongsTo;

    var url = 'api/department/getDepartment/belongsTo/'+belongsTo;
      customHttp.get(url)
        .then(res => {
          var array = res.data.message;
          if(array.length>0){
            var dept = array[0].name;
            setLoadDepartment(dept);
            loadUserDetails(dept);
            setCreatedUsersideDepartment(dept);
          }
          else{
            setGetUserDetails([]);
            setShowLoader(true);
          }


          setGetDepartment(res.data.message);

        }).catch((error)=>{
          setShowLoader(true);
          setGetUserDetails([]);
          console.log("There is an API error");
      });
  }

  // var handleChangeCreateUserDepartment = (event) =>{
  //   setCreateUserDepartment(event.target.value);
  // }

  var handleChangeDepartmentDropdown = (event) =>{
    setLoadDepartment(event.target.value);
    // console.log(event.target.value);
    let dept = event.target.value
    setCreatedUsersideDepartment(event.target.value);
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    var url = '/api/adminUser/getUser/type/'+2+'/belongsTo/'+userDetails.belongsTo+'/department/'+dept ;
    customHttp.get(url)
    .then(response => {
    setGetUserDetails(response.data.message);
    setShowLoader(true);
    }).catch((error)=>{

    });
  }

  var showCreateUserTab=()=>{
    setShowCreateDepartment(true)
    setShowCreateUser(true)
    setShowUserList(true)
    getDepartmentDetails();

  }

  var showCreateDepartmentTab=()=>{
    setShowCreateUser(false)
    setShowCreateDepartment(false)
    setShowUserList(false)
    getDepartmentDetails();
  }

  var hideDepartmentDeletePopUp=(e)=>{
    e.preventDefault();
    setDepartmentData("");
    // setDisabled(!disabled);
    document.getElementById("deleteDepartmentPopup").style.display="none";
  }

  var getConfirmDeletePopupForDepartment = async(data)=>{
    // console.log("delete data",data);
    setDepartmentData(data);
    setConfirmLoader(false);
    setDisabled(false);
    document.getElementById("deleteDepartmentPopup").style.display="block";
  }

  var deleteDepartmentContent=(e,data1)=>{
  // console.log("deleteDepartmentContent",data1);
  e.preventDefault();
  setConfirmLoader(true);
  setDisabled(true);
  let data = {}
  data.name = data1.name;
  data.belongsTo = data1.belongsTo;
  // axios.delete('api/adminUser/createUser',{data:data})
  var url = ' /api/department/createDepartment' ;
  customHttp.delete(url,data)
  .then(response=>{
  setConfirmLoader(false);
  setDisabled(false);
  document.getElementById("deleteDepartmentPopup").style.display='none';
  getDepartmentDetails();
  snackbar.successSnackbar();
  setSuccessSnackbarMessage("Department Deleted Successfully!");
  })
  .catch(error => {
    setConfirmLoader(false);
    setDisabled(false);
    setErrorSnackbarMessage("Error try again.");
    snackbar.errorSnackbar();

    console.log("error",error)
  });
  }

  var showCheckboxes=()=>{
    var checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
      checkboxes.style.display= "block";
      setExpanded(true)
    } else {
      checkboxes.style.display= "none";
      setExpanded(false);
    }
  }

  var handleChangeCheckbox=(e,data)=>{
    setNoDepartment(false);
    if(e.target.checked){
      let obj = {}
      obj._id = data._id;
      obj.name = data.name
      departmentArray.push(obj);
    }
    else{
      let obj = {}
      obj._id = data._id;
      obj.name = data.name;

      for(var i=0; i<departmentArray.length; i++){
        if(departmentArray[i]._id ===  obj._id){
          departmentArray.splice(i,1);
        }
      }
      console.log("msg",departmentArray);
    
    }

  }


  var unCheckAllCheckbox=()=>{
    var nodes = document.getElementsByTagName('input');
    for (var i = 0; i < nodes.length; i++) {
      var node = nodes[i];
   
      if (node.getAttribute('type') === 'checkbox') {
        node.checked=false;
      }
    } 
  }

  // Edit dlt start //

var editDltOpenPopup = (data)=>{
  setEditUserData(data);
  setNoDepartmentwhileEditing(false);
  setSelectedDepartmentArrayForEdit(data.departmentArrayForEdit)
  setDisplayDept(data.departmentArrayForEdit);
  for(var i=0; i<getDepartment.length; i++){
	for(var j=0; j<data.departmentArrayForEdit.length; j++){
		if(getDepartment[i].name == data.departmentArrayForEdit[j].name){
			getDepartment[i].checked=true
		}
	}
  }
  setEditUserConfirmLoader(false)
  setHideEditUserCancelButton(false);
  document.getElementById("editDltPopup").style.display="block";
}

var hideEditPopUp=(e)=>{
  e.preventDefault();
  unCheckAllCheckboxEditPopup();
  // setDisabled(!disabled);
  document.getElementById("editCheckboxes").style.display="none";
  document.getElementById("editDltPopup").style.display="none";
  getDepartmentDetails()
}

var editUserSubmit=(e)=>{
  e.preventDefault();

  var data = {};
  data._id = editUserData._id;
  data.department = selectedDepartmentArrayForEdit;

  if(selectedDepartmentArrayForEdit.length === 0){
    setNoDepartmentwhileEditing(true);
   }

  else{
  var url = '/api/adminUser/createUser';
  customHttp.put(url,data)
  .then(response=>{
	    console.log("success",response.data.message)
		loadUserDetails(createdUsersideDepartment);
		document.getElementById("editDltPopup").style.display="none";
		snackbar.successSnackbar();
		setSuccessSnackbarMessage("Dlt Updated Successfully!");
		unCheckAllCheckboxEditPopup();
		document.getElementById("editCheckboxes").style.display="none";
  })
  .catch(error=>{
	  console.log("failed",error)
	  snackbar.errorSnackbar();
	  setErrorSnackbarMessageForEdit("Error try again!");
  })
}
}

var handleChangeEditCheckbox=(e,data)=>{
  if(e.target.checked){
  	let obj = {};
  	obj._id = data._id;
  	obj.name = data.name;
    selectedDepartmentArrayForEdit.push(obj);
    setNoDepartmentwhileEditing(false);
  for(var i=0; i<getDepartment.length; i++){
    if(getDepartment[i].name ===data.name){
    
      getDepartment[i].checked=true;
      let temp=[];
      temp = JSON.stringify(getDepartment)
      setGetDepartment( JSON.parse(temp) )
    }
  }
  }
  else{
  	let obj = {};
  	obj._id = data._id;
    obj.name = data.name;
  
    for(var i=0; i<selectedDepartmentArrayForEdit.length; i++){
      if(selectedDepartmentArrayForEdit[i]._id ===  obj._id){  
        selectedDepartmentArrayForEdit.splice(i,1);
      }
    }

    for(var i=0; i<getDepartment.length; i++){
      if(getDepartment[i].name ===data.name){
        getDepartment[i].checked=false;
        let temp=[];
        temp = JSON.stringify(getDepartment)
        setGetDepartment( JSON.parse(temp) )
      }
    }
  }
}

var unCheckAllCheckboxEditPopup=()=>{
  var subNodes = document.getElementsByTagName('input');
  for (var i = 0; i < subNodes.length; i++) {
    var node1 = subNodes[i];

    if (node1.getAttribute('type') == 'checkbox') {
      node1.checked=false;
    }
  }
}

var showEditCheckboxes=()=>{
  var editCheckboxes = document.getElementById("editCheckboxes");
  if (!expandedEdit) {
    editCheckboxes.style.display= "block";
    setExpandedEdit(true)
  } else {
    editCheckboxes.style.display= "none";
    setExpandedEdit(false);
  }
}
  return (
      <div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          {showCreateUser ?
            <button type="button" className="nj-flow-button-dlt-selected" style={{outline: 'none'}}>Create User</button>
            :
            <button type="button" onClick={showCreateUserTab} className="nj-flow-button-dlt-unselected" style={{outline: 'none'}}>Create User</button>
          }

          {showCreateDepartment ?
            <button type="button"  onClick={showCreateDepartmentTab} className="nj-flow-button-dlt-unselected" style={{outline: 'none'}}>Create Department</button>
            :
            <button type="button"  className="nj-flow-button-dlt-selected"  style={{outline: 'none'}}>Create Department</button>
          }
        </div>
    </div>

      {showUserList ?
      <div className="row">
        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
          <div className="col-md-12 col-sm-12 nj-card">
            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4 className="font_family_montserrat">Created Users</h4>
                </div>
              </div>
            </div>
            {/** code for department drpodown */}
            {getDepartment.length>0 &&
                <div className="col-md-4 nj-popup-form-wrap" style={{margin: '21px 0px',paddingLeft:'0px'}}>
                    <p className="popup-content-label" style={{fontSize:14}}>Select Department</p>
                    <div className="nj-form-select">
                      <select className="vs-input--input" style={{textTransform: 'capitalize'}} onChange={handleChangeDepartmentDropdown}>
                        {getDepartment.map((data,i) =>{
                          return <option key={i} value={data.name}>{data.name}</option>
                        })}
                      </select>
                    </div>
                </div>
            }
            <div className="col-md-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
            {showLoader ?
              <div className="course-table-margin-top">
                {getUserDetails.length>0?
                // <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"69vh"}}>
                <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"63.3vh"}}>
                  <table className="nj-table nj-table--tbody-table font_family_montserrat">
                    <thead className="nj-table--thead">
                      <tr>
                        <th>
                          <div className="nj-table-text">SL NO.</div>
                        </th>
                        <th>
                          <div className="nj-table-text">Email</div>
                        </th>
                        <th>
                          <div className="nj-table-text">Department</div>
                        </th>
                        <th style={{textAlign:"center"}}>
                          <div className="nj-table-text">ACTIONS</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="dl-team_tbody">
                        {getUserDetails.map((data,i) => {
                           return(
                        <tr key={i} className="tr-values nj-table--tr tr-table-state-null ">
                        <td className="td nj-table--td">{i+1}</td>
                        <td className="td nj-table--td" >{data.email}</td>
                        <td className="td nj-table--td" style={{textTransform: 'capitalize'}}>{data.department}</td>
                            <td className="td dl-team--td">
                              <button type="button" onClick={()=>editDltOpenPopup(data)} className="nj-flow-button">Edit</button>
                              <button  type="button"  onClick={()=>getConfirmDeletePopup(data)} className="nj-flow-button bg-color-red addRoles_button"  style={{float:"none",marginTop:"5px",marginLeft:"5px"}}>Delete</button>
                          </td>

                          </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
                :
                <div className="col-md-12 idams_noDataFound" style={{height:"62vh"}}>
                  <span className="font_family_montserrat">No data found</span>
                </div>
              }
              </div> :
                 <div className="row" style={{height:"76.3vh"}}>
                 <div className="col-md-12 aligh-loader-image-center" >
                   <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                 </div>
               </div>
               }
            </div>
          </div>
        </div>

        {/* onclick delete button in table this popup will appear*/}
        <div id="deleteUserPopup" className={"nj-overly add-rebound-animation"}>
          <div className="popup-nj my-popup">
            <header className="popup-header">
              <span>Delete User</span>
              <span onClick={hideDeletePopUp} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
            </header>
            <div className="popup-content">
              <div className="col-md-12">
                <form name="roleForm">
                  <div className="nj-popup-form-wrap">
                    <p className="popup-content-label font_family_montserrat" style={{fontSize:"16px"}}>Do you really want to delete?</p>
                  </div>
                  {confirmLoader ?
                  <div className={"pull-right"}>
                  <img src={"/img/loaders/spinningwheel.gif"} style={{marginTop:"0px"}} height={50} width={50} alt="loader" /></div> :<button type="submit" onClick={(e)=>deleteRequestedContent(e,userData)} className="nj-flow-button pull-right" style={{margin:5}}> Confirm</button>}
                  <div><button type="button" onClick={hideDeletePopUp}  disabled={disabled} className="nj-flow-button bg-color-red pull-right"  style={{margin:5}}>Cancel</button></div>
                </form>
              </div>
            </div>
          </div>
        </div>


        {/* creating a new user form start*/}
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <div id="successSnackbar">{successSnackbarMessage}</div>
          <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{errorSnackbarMessage}</div>
          <div className="col-md-12 col-sm-12 nj-card">
            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4 className="font_family_montserrat">Create New User</h4>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  dl-team_form" style={{marginTop:'10px'}}>
                <form onSubmit={formSubmit}>
                  {/* <div className="row dl-team_form_row" style={{marginTop:'0px'}}>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <label className="idams-label pull-left">Select Department</label>
                          <div className="nj-form-select">
                            <select className="vs-input--input" style={{textTransform: 'capitalize'}} onChange={handleChangeCreateUserDepartment} required>
                              <option value="">Select Department</option>
                              {getDepartment.map((data,i) =>{
                                return <option key={i} value={data.name}>{data.name}</option>
                              })}
                            </select>
                          </div>

                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom:"20px",marginTop:"10px"}}>
                          <label className="idams-label pull-left">Email</label>
                          <input type="email" name="email" value={createArray.email} onChange={handleChangeCreateUser}  className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="ex : john@email.com" autoComplete="off" required />
                          {errorEmail && <p style={{marginTop:'9px',color:'red'}}>Invalid email </p>}
                          {emailExist && <p style={{marginTop:'9px',color:'red',marginBottom:'0px'}}>Email is already taken!</p>}

                      </div>
                      <div className="col-lg-3 col-md-2 col-sm-4 col-xs-4 pull-right" style={{paddingBottom:"15px"}}>
                      {submitLoader ?<div style={{height:'42',width:"30px"}}><img src={"/img/loaders/spinningwheel.gif"} height={55} width={65} alt="loader" /></div>: <button  type="submit" className="nj-flow-button pull-right" >Submit</button>}
                      </div>
                  </div> */}


                  <div className="row dl-team_form_row" style={{marginTop:'0px'}}>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <label className="idams-label pull-left">Select Department</label>
                          {/* <div className="nj-form-select">
                            <select className="vs-input--input" onChange={handleChangeCreateUserDepartment} required>
                              <option value="">Select a Department</option>
                              {getDepartment.map((data,i) =>{
                                return <option key={i} value={data.name}>{data.name}</option>
                              })}
                            </select>
                          </div> */}
                          <div className="multiselect" style={{cursor: 'pointer'}} ref={node}>
                            <div className="nj-form-select" onClick={showCheckboxes}>
                              <select className="vs-input--input" required> 
                                <option>Select a Department</option>
                              </select>
                              <div className="overSelect"></div>
                            </div>
                            {getDepartment.length>0 ?
                            <div id="checkboxes">
                                {getDepartment.map((data,i) =>{
                                  return ( 
                                  /*   <label style={{paddingLeft:"5px"}} key={i}>
                                  <input type="checkbox" key={i} onChange={e => handleChangeCheckbox(e)} value={data.name}/>{data.name} </label> */
                                  
                                  <label className="checkbox coloured" key={i}>
                                    <input type="checkbox" key={i} onChange={e => handleChangeCheckbox(e,data)} style={{paddingLeft:"5px"}} value={data.name}/><span className="checkbox-material"><span className="check"></span></span>  {data.name}
                                 </label>
                                  )
                              })}

                            </div>
                            :
                            <div id="checkboxes">
                              <p style={{textAlign:"center",marginTop:"2%",fontFamily:"Montserrat',Sans-serif"}}> No Department found</p>
                            </div>
                          }
                          </div>
                          
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom:"20px",marginTop:"10px"}}>
                          <label className="idams-label pull-left">Email</label>
                          <input type="email" name="email" value={createArray.email} onChange={handleChangeCreateUser}  className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="ex : john@email.com" autoComplete="off" required />
                          {errorEmail && <p style={{marginTop:'9px',color:'red'}}>Invalid email </p>}
                          {emailExist && <p style={{marginTop:'9px',color:'red',marginBottom:'0px'}}>Email is already taken!</p>}
                          {noDepartment && <p style={{marginTop:'9px',color:'red',marginBottom:'0px'}}>Select atleast one Department!</p>}
                      </div>
                      <div className="col-lg-3 col-md-2 col-sm-4 col-xs-4 pull-right" style={{paddingBottom:"15px"}}>
                      {submitLoader ?<div style={{height:'42',width:"30px"}}><img src={"/img/loaders/spinningwheel.gif"} height={55} width={65} alt="loader" /></div>: <button  type="submit" className="nj-flow-button pull-right" >Submit</button>}
                      </div>
                  </div>



                </form>
              </div>
            </div>
          </div>
        </div>
        {/* creating a new user form ends*/}


         {/* onclick edit button in user table this popup will appear*/}
            <div id="editDltPopup" className={"nj-overly add-rebound-animation"}>
            <div id="successSnackbar">{successSnackbarMessage}</div>
              <div id="errorSnackbar">{errorSnackbarMessageForEdit}</div>
              <div className="popup-nj my-popup">
                <header className="popup-header">
                  <span>Edit User</span>
                  <span onClick={hideEditPopUp} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
                </header>
                <div className="popup-content">
                  <div className="col-md-12">
                    <form name="roleForm" onSubmit={editUserSubmit}>
                      <div className="nj-popup-form-wrap">
                        <label className="idams-label pull-left" style={{paddingBottom: '10px'}}>Email : {editUserData.email}</label>
                      </div><br/>

                      <div className="nj-popup-form-wrap">
                      <label className="idams-label pull-left">Select Department</label>
                      <div className="editMultiselect" style={{cursor: 'pointer'}} ref={editSubNode}>
                        <div className="nj-form-select" onClick={showEditCheckboxes}>
                          <select className="vs-input--input" required>
                            <option>Select a Department</option>
                          </select>
                          <div className="editOverSelect"></div>
                        </div>

                        <div id="editCheckboxes">
                            {getDepartment.map((data,i) =>{
                              return (
                              //   <label style={{paddingLeft:"5px"}} key={i}>
                              // <input type="checkbox" key={i} onChange={e => handleChangeCheckbox(e)} value={data.name}/> {data.name} </label>
                              <label className="checkbox coloured" key={data._id}>
                                <input type="checkbox"  key={data._id} checked={data.checked } onChange={e => handleChangeEditCheckbox(e, data)} style={{paddingLeft:"5px"}} value={data.name}/>
                                <span className="checkbox-material">
                                <span className="check"></span>
                                </span>  {data.name}
                              </label> 
                              
                              )
                          })}

                        </div>
                        </div>
                        {noDepartmentwhileEditing && <p style={{marginTop:'9px',color:'red',marginBottom:'0px'}}>Select atleast one Department!</p>}
                      </div>
                      {editUserConfirmLoader ? <div className={"pull-right"}> <img src={"/img/loaders/spinningwheel.gif"} height={50} width={70} alt="loader" /></div> :<button type="submit" className="nj-flow-button pull-right" style={{margin:5}}>Submit</button>}
                      {hideEditUserCancelButton ? <div><button type="button" onClick={hideEditPopUp} className="nj-flow-button bg-color-red pull-right" style={{margin:5}}>Cancel</button></div>:<div><button type="button" onClick={hideEditPopUp} className="nj-flow-button bg-color-red pull-right" style={{margin:5}}>Cancel</button></div>}
                    </form>
                  </div>
                </div>
              </div>
            </div>
         {/* onclick edit button in user table this popup will appear end*/}

      <div>
    </div>
  </div>

  :

  <div className="row">
        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
          <div className="col-md-12 col-sm-12 nj-card">
            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4 className="font_family_montserrat">Created Departments</h4>
                </div>
              </div>
            </div>

            <div className="col-md-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
            {showLoader ?
              <div className="course-table-margin-top">
                {getDepartment.length>0?
                // <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"69vh"}}>
                <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"63.3vh"}}>
                  <table className="nj-table nj-table--tbody-table font_family_montserrat">
                    <thead className="nj-table--thead">
                      <tr>
                        <th>
                          <div className="nj-table-text">SL NO.</div>
                        </th>
                        <th>
                          <div className="nj-table-text">Department</div>
                        </th>
                        <th style={{textAlign:"center"}}>
                          <div className="nj-table-text">ACTIONS</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="dl-team_tbody">
                        {getDepartment.map((data,i) => {
                           return(
                        <tr key={i} className="tr-values nj-table--tr tr-table-state-null ">
                        <td className="td nj-table--td">{i+1}</td>
                        <td className="td nj-table--td" style={{textTransform: 'capitalize'}}>{data.name}</td>
                            <td className="td dl-team--td">
                              <button  type="button"  onClick={()=>getConfirmDeletePopupForDepartment(data)} className="nj-flow-button bg-color-red addRoles_button"  style={{float:"none",marginTop:"5px",marginLeft:"5px"}}>Delete</button>
                          </td>
                          </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
                :
                <div className="col-md-12 idams_noDataFound" style={{height:"67.3vh"}}>
                  <span className="font_family_montserrat">No data found</span>
                </div>
              }
              </div> :
                 <div className="row" style={{height:"76.3vh"}}>
                 <div className="col-md-12 aligh-loader-image-center" >
                   <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                 </div>
               </div>
               }
            </div>
          </div>
        </div>

        {/* onclick delete button in table this popup will appear*/}
        <div id="deleteDepartmentPopup" className={"nj-overly add-rebound-animation"}>
          <div className="popup-nj my-popup">
            <header className="popup-header">
              <span>Delete Department</span>
              <span onClick={hideDepartmentDeletePopUp} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
            </header>
            <div className="popup-content">
              <div className="col-md-12">
                <form name="roleForm">
                  <div className="nj-popup-form-wrap">
                    <p className="popup-content-label font_family_montserrat" style={{fontSize:"16px"}}>Do you really want to delete?</p>
                  </div>
                  {confirmLoader ?
                  <div className={"pull-right"}>
                  <img src={"/img/loaders/spinningwheel.gif"} style={{marginTop:"0px"}} height={50} width={50} alt="loader" /></div> :<button type="submit" onClick={(e)=>deleteDepartmentContent(e,departmentData)} className="nj-flow-button pull-right" style={{margin:5}}> Confirm</button>}
                  <div><button type="button" onClick={hideDepartmentDeletePopUp}  disabled={disabled} className="nj-flow-button bg-color-red pull-right"  style={{margin:5}}>Cancel</button></div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* creating new departmemt */}
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <div id="successSnackbar">{successSnackbarMessage}</div>
          <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{errorSnackbarMessage}</div>
          <div className="col-md-12 col-sm-12 nj-card">
            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4 className="font_family_montserrat">Create New Department</h4>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  dl-team_form" style={{marginTop:'10px'}}>
                <form onSubmit={formSubmitDepartment}>
                  <div className="row dl-team_form_row" style={{marginTop:'0px'}}>
                      <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                          <label className="idams-label pull-left">Department</label>
                          <input type="text" name="department" value={department} onChange={handleChangeCreateDepartment}  className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="ex : Computer Science" autoComplete="off" required />
                          {departmentAlreadyExists && <p style={{color: 'red',paddingTop: 5}}>Department name already exists.</p>}
                          {departmentNameValidation && <p style={{color: 'red',paddingTop: 5}}>Department name should contain alphabets, numbers, spaces, hyphens</p>}
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12" style={{marginBottom:"10px"}}>
                      {submitDepartmentLoader ?<div style={{marginTop:'10px',height:'30px',width:"30px"}}><img src={"/img/loaders/spinningwheel.gif"} height={65} width={65} alt="loader" /></div>: <button  type="submit" className="nj-flow-button pull-right" disabled={departmentNameValidation} style={{marginTop:'25px'}}>Submit</button>}
                      </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>



      <div>
    </div>
  </div>

  }

  </div>

  );
}

export default DLTeam;
