// import axios from "axios";
import * as RequestedContentActions from "./index";
import snackbar from '../../../packages/Snackbar/';
import customHttp from "../../../packages/CustomHttp";
// import RequestedContentPresentation from "../../../presentational/RequestedContent/index";

// for applications

export function getRequestedContent(){
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
     return (dispatch ,getState)=> {

         // axios.get('/api/requestedCapsule/createRequestedCapsule/email/'+userDetails.email)
         var url = '/api/requestedCapsule/createRequestedCapsule/email/'+userDetails.email ;
         customHttp.get(url)
        .then(response=>{
           var content=response.data.message;
         //   console.log("all requested content",response.data.message)
           dispatch(RequestedContentActions.getRequestedContent(content))
           dispatch(setRequestedContentElements('selectedRequestedContentRow',content[getState().RequestedContentState.selectedIndex]))
           

        })
        .catch(error => {
         dispatch(RequestedContentActions.getRequestedContent([]))
         // dispatch(RequestedContentActions.setBooleanValue("errorSnackbarMessage","Username is taken"))
         //    snackbar.errorSnackbar();

       console.log("error",error)
     });


     }
  }

  export function deleteRequestedContent(e,data){

   return (dispatch ,getState)=> {
      e.preventDefault();
      // console.log("userId,_id delete requested content",data);
      // axios.delete('/api/requestedCapsule/deleteRequestedCapsule/'+data.userId+'/'+data._id)
      var url = '/api/requestedCapsule/deleteRequestedCapsule/'+data.userId+'/'+data._id ;
      customHttp.delete(url)
     .then(response=>{
      dispatch(RequestedContentActions.setBooleanValue("successSnackbarMessage","Deleted successfully"))
         // console.log("delete",response);
         dispatch(setRequestedContentElements('selectedIndex',"0"))
         dispatch(getRequestedContent())
         snackbar.successSnackbar();

     })
     .catch(error => {
   //    dispatch(RequestedContentActions.getRequestedContent([]))
   //  console.log("error",error)
         dispatch(RequestedContentActions.setBooleanValue("errorSnackbarMessage","Request Not Deleted"))
         snackbar.errorSnackbar();
         console.log("error",error);

  });

    }
 }

 export function handleChangeComment(data,comment){
   //  var comment1 ={};
   //  comment1 = comment
   return (dispatch ,getState)=> {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      var object1={};
      object1._id=data._id;
      object1.comment=comment;
      object1.userId=userDetails._id;
      object1.belongsTo=userDetails.belongsTo;
      object1.email=userDetails.email;
      // axios.put('/api/requestedCapsule/addComment',object1)
      var url = '/api/requestedCapsule/addComment' ;
      customHttp.put(url , object1)
     .then(response=>{
      //   var content=response.data.message;
         // console.log("response",response);
         dispatch(RequestedContentActions.setBooleanValue("successSnackbarMessage","Comment Submitted successfully"))
         snackbar.successSnackbar();
         // dispatch(getRequestedContent())


     })
     .catch(error => {
   //    dispatch(RequestedContentActions.getRequestedContent([]))
   //  console.log("error",error)
      dispatch(RequestedContentActions.setBooleanValue("errorSnackbarMessage","Comment not submitted"))
      snackbar.errorSnackbar();
      console.log("error",error);

  });

   }
}

export const getRequestedComment=(data)=>{
   // console.log("en MAGA")
   //var userDetails=JSON.parse(sessionStorage.getItem("userData"))
     return (dispatch ,getState)=> {
         // axios.get('/api/requestedCapsule/fetchComments/requestedCapsuleId/' +data._id)
         var url = '/api/requestedCapsule/fetchComments/requestedCapsuleId/' +data._id ;
         customHttp.get(url)
        .then(response=>{
           var content=response.data.message;
         //   console.log(response);
           dispatch(RequestedContentActions.getRequestedComment(content))

        })
        .catch(error => {
         dispatch(RequestedContentActions.getRequestedContent([]))
       console.log("error",error)
     });

     }
  }

  export const searchRequestedContent=(data)=>{
   // console.log("array",data);
   // var userDetails=JSON.parse(sessionStorage.getItem("userData"))
     return (dispatch ,getState)=> {
     dispatch(RequestedContentActions.searchRequestedContent(data))

     }
  }

  //Post Api of Assign work flow

  export function sendAssignWorkflow(dropdownArray,data){
   return (dispatch ,getState)=> {
      const configuration = { headers: { "Content-Type": "multipart/form-data" } };
      dispatch(setRequestedContentElements('assignloader',true))
      // console.log("assign work flow",dropdownArray,data)
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      var object1={};
      object1.userId=userDetails._id;
      object1.belongsTo=userDetails.belongsTo;
      object1.requestedCapsuleId=data._id;
      object1.approve=dropdownArray.approve;
      object1.review=dropdownArray.review;
      object1.create=dropdownArray.create;
      object1.produce=dropdownArray.produce;
      if(dropdownArray.date === ""){
         var date = new Date()
         var currentDate = date.getDate();
         if(currentDate<10){
            currentDate='0'+currentDate;
         }
         var currentMonth = date.getMonth() + 1; //Months are zero based
         if(currentMonth<10){
         currentMonth='0'+currentMonth;
         }
         var currentYear = date.getFullYear();
         var fromDate = currentYear + "-" + currentMonth + "-" + currentDate; // date conversion dd/mm/yyyy to yyyy-mm-dd
         object1.scheduleDate = fromDate;
         // console.log("finaluytr date", fromDate);
      }else{
         object1.scheduleDate=dropdownArray.date;
      }

      if(dropdownArray.time === ""){
         var time = new Date()
         time = time.toString();
         time = time.substr(16,5);
         object1.scheduleTime = time;
         // console.log("timieeeeee",time)
      }else{
         object1.scheduleTime=dropdownArray.time;
      }

      // console.log("object15432345678uytrertyu765432",object1);
      // axios.post('/api/workFlow/',object1)
      var url = '/api/workFlow/' ;
      customHttp.post(url,object1,configuration)
      
      .then(response=>{
         // var content=response.data.message;
         //  console.log("response sendAssignWorkflow",response);
          dispatch(setRequestedContentElements('assignloader',false))
          document.getElementById('assignPopup').style.display='none';
          dispatch(RequestedContentActions.setBooleanValue("successSnackbarMessage","Submitted successfully"))
          snackbar.successSnackbar();
          dispatch(getRequestedContent())

      })
     .catch(error=> {
      dispatch(RequestedContentActions.setBooleanValue("errorSnackbarMessage","Not submitted successfully"))
      snackbar.errorSnackbar();
      dispatch(setRequestedContentElements('assignloader',false))
      console.log("error",error);
     
  });

   }
}

//put Api of Assign work flow

export function sendUpdatedAssignWorkflow(updateDropdownArray,data){
//   console.log("hello update",updateDropdownArray,data);
 return (dispatch ,getState)=> {
    dispatch(setRequestedContentElements('assignloader',true))
    // console.log("assign work flow",dropdownArray,data)
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    var obj={};
    obj._id = data.assignWorkFlow[0]._id;
    obj.userId=userDetails._id;
    obj.belongsTo=userDetails.belongsTo;
    obj.requestedCapsuleId=data._id;
    obj.approve=updateDropdownArray.approve;
    obj.review=updateDropdownArray.review;
    obj.create=updateDropdownArray.create;
    obj.produce=updateDropdownArray.produce;

    if(updateDropdownArray.date === undefined){
      // console.log("msg",data.assignWorkFlow[0].scheduleDate);
       obj.scheduleDate = data.assignWorkFlow[0].scheduleDate;
       // console.log("finaluytr date", fromDate);
    }else{
       obj.scheduleDate=updateDropdownArray.date;
    }

    if(updateDropdownArray.time === undefined){
      // console.log("msg",data.assignWorkFlow[0].scheduleTime);
       obj.scheduleTime = data.assignWorkFlow[0].scheduleTime;
       // console.log("timieeeeee",time)
    }else{
       obj.scheduleTime=updateDropdownArray.time;
    }

   //  console.log("object1trertyuy",obj);
   //  axios.put('/api/workFlow/',obj)
    var url = '/api/workFlow/' ;
    customHttp.put(url ,obj )
    .then(response=>{
      //  var content=response.data.message;
      //  console.log("response sendUpdatedAssignWorkflow",response);
        dispatch(setRequestedContentElements('assignloader',false))
        document.getElementById('assignPopup').style.display='none';
        dispatch(RequestedContentActions.setBooleanValue("successSnackbarMessage","Submitted successfully"))
        snackbar.successSnackbar();
        dispatch(getRequestedContent())

    })
   .catch(error=> {
    dispatch(RequestedContentActions.setBooleanValue("errorSnackbarMessage","Not submitted successfully"))
    snackbar.errorSnackbar();
    dispatch(setRequestedContentElements('assignloader',false))
    console.log("error",error);

});

 }
}

//for Approve and review dropdown

export const getAssignApproveReview=()=>{
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
   //console.log("approve and review",userDetails.type)
     return (dispatch ,getState)=> {

         // axios.get('/api/adminUser/getUser/type/'+2+'/belongsTo/'+userDetails.belongsTo)
         var url = '/api/adminUser/getUser/type/'+2+'/belongsTo/'+userDetails.belongsTo ;
         customHttp.get(url)
        .then(response=>{
           var content=response.data.message;
         //   console.log("belongsto",userDetails.belongsTo)
           var approveProduce = [];
           for(var i=0; i<content.length;i++){
              approveProduce.push(content[i].email);
           }
           dispatch(RequestedContentActions.getAssignData(approveProduce))
        })
        .catch(error => {
         dispatch(RequestedContentActions.getAssignData([]))
       console.log("error",error)
     });

     }
  }

  //for create and produce

  export const getAssignCreateProduce=()=>{
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
   // console.log("type3",userDetails.type)
     return (dispatch ,getState)=> {

         // axios.get('/api/adminUser/getUser/type/'+3+'/belongsTo/'+userDetails.belongsTo)
         var url = '/api/adminUser/getUser/type/'+3+'/belongsTo/'+userDetails.belongsTo ;
         customHttp.get(url)
        .then(response=>{
           var content=response.data.message;
         //   console.log("belongsto",userDetails.belongsTo)
           var createProduce = [];
           for(var i=0; i<content.length;i++){
            createProduce.push(content[i].email);
           }
           dispatch(RequestedContentActions.getcreateProduceData(createProduce))
        })
        .catch(error => {
         dispatch(RequestedContentActions.getcreateProduceData([]))
         console.log("error",error)
     });

     }
  }

  export function sendNotification(e,data1,notify,popId,props){
   return (dispatch ,getState)=> {
      e.preventDefault();
      dispatch(setRequestedContentElements('sendNotificationLoader',true))
      const configuration = { headers: { "Content-Type": "multipart/form-data" } };
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      const data = new FormData();
        data.append('userId', userDetails._id);
        data.append('belongsTo', userDetails.belongsTo);
        data.append('email', userDetails.email);
        data.append('requestedCapsuleId', data1._id);
        data.append('subject', notify.subject);
        data.append('recipients', notify.recipients);
        data.append('body', notify.body);
        for(var i=0; i<notify.attachments.length; i++){
         data.append('files', notify.attachments[i]);
        }
         // axios.post('/api/notifyRequestedCapsule/notify',data,configuration)
         var url = '/api/notifyRequestedCapsule/notify' ;
         customHttp.post(url,data,configuration)

     .then(response=>{
      // var loaderBoolean = true;
      // dispatch(RequestedContentActions.setSendNotify(loaderBoolean))
      dispatch(setRequestedContentElements('sendNotificationLoader',false))
      document.getElementById(popId).style.display="none";
      dispatch(RequestedContentActions.setBooleanValue("successSnackbarMessage","Notification Sent successfully"))
      snackbar.successSnackbar();

     })
     .catch(error=> {
      dispatch(setRequestedContentElements('sendNotificationLoader',false))
      dispatch(RequestedContentActions.setBooleanValue("errorSnackbarMessage","Notification Not Sent"))
      snackbar.errorSnackbar();
      dispatch(setRequestedContentElements('sendNotificationLoader',false))
      console.log("error",error);

      });

    }
 }

 export const getRecepients=()=>{
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
     return (dispatch ,getState)=> {
         // dispatch(setRequestedContentElements('sendNotification',true))
         // axios.get('/api/adminUser/getUser/type/'+3+'/belongsTo/'+userDetails.belongsTo)
         var url = '/api/adminUser/getUser/type/'+3+'/belongsTo/'+userDetails.belongsTo ;
         customHttp.get(url)
        
        .then(response=>{
           var content=response.data.message;
           var recepients = [];
         //   console.log("belongsto",userDetails.belongsTo)
           for(var i=0; i<content.length;i++){
              var obj={};
            //   obj = content[i].email.concat("::").concat(content[i].email);
            obj.name=content[i].email;
            obj.value=content[i].email;
            //   obj.concat(:)
              recepients.push(obj);

           }

         dispatch(RequestedContentActions.getRecepients(recepients))

        })
        .catch(error => {
         dispatch(RequestedContentActions.getRecepients([]))
       console.log("error",error)
     });


     }
  }

//for upload api

export function getUploadRequestedContent(e,data,requestUpload,popId){
   // console.log("request upload",requestUpload);
   e.preventDefault();
   return (dispatch ,getState)=> {
      dispatch(setRequestedContentElements('uploadFileLoader',true))
      // console.log("upload file",data)
      const configuration = { headers: { "Content-Type": "multipart/form-data" } };
      //var userDetails = JSON.parse(sessionStorage.getItem("userData"))
      var obj = new FormData();
      for(var i=0; i<requestUpload.length; i++){
         obj.append('files', requestUpload[i]);
        }
      obj.append('_id',data._id);
      // obj.name="rajath";
      //  const obj ={};
      // obj.files=requestUpload;
      // obj._id = data._id;
         // axios.post('/api/requestedCapsule/upload',obj,configuration)
         var url = '/api/requestedCapsule/upload' ;
         customHttp.post(url,obj,configuration)
         .then(response=>{
            // var content=response.data.message;
            // dispatch(RequestedContentActions.setUploadLoader(true))
            dispatch(setRequestedContentElements('uploadFileLoader',false))
            document.getElementById(popId).style.display="none";
            dispatch(RequestedContentActions.setBooleanValue("successSnackbarMessage","uploaded successfully"))
            snackbar.successSnackbar();
            dispatch(getRequestedContent())

         })
      .catch (err=> {
         dispatch(RequestedContentActions.setBooleanValue("errorSnackbarMessage","Not uploaded successfully"))
         snackbar.errorSnackbar();
         dispatch(setRequestedContentElements('uploadFileLoader',false))
         console.log("error",err);
      });

   }
}

export function setRequestedContentElements(name,content) {
   return (dispatch ,getState)=> {
      dispatch(RequestedContentActions.setRequestedContentElements(name,content))
   }
}

//status progress

export function statusProgressUpdate(data,obj){
      return (dispatch ,getState)=> {
         var userDetails=JSON.parse(sessionStorage.getItem("userData"))
         var object1={};
         object1._id=data._id;    
         object1.email=userDetails.email;
         object1.status=obj.status;  
         // axios.put('/api/requestedCapsule/status',object1)
         var url = '/api/requestedCapsule/status' ;
         customHttp.put(url,object1)
            .then(response=>{
            // var content=response.data.message;
            // console.log("status response",content);
            dispatch(RequestedContentActions.setBooleanValue("successSnackbarMessage","Submitted successfully"))
            snackbar.successSnackbar();
            dispatch(getRequestedContent())            
         })
         .catch(error => {
            dispatch(RequestedContentActions.setBooleanValue("errorSnackbarMessage","not submitted successfully"))
            snackbar.errorSnackbar();
            console.log("error",error);      
         });
      
      }
   }

