import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import customHttp from "../../../packages/CustomHttp";


function UrlRules() {
  var userData=JSON.parse(sessionStorage.getItem("userData"));
  const appLanguage = JSON.parse(localStorage.getItem("appLanguage"));
  const [urlRuleList, setUrlRuleList] = useState([]);
  const [successAlertMessage, setSuccessAlertMessage] = useState('')
  const [errorAlertMessage, setErrorAlertMessage] = useState('')
  const urlRuleObj = {url:''}
  const [urlRule, setUrlRule] = useState(urlRuleObj);
  const [submitRequestLoader, setSubmitRequestLoader] = useState(false)
  const [deleteUrlLoader, setDeleteUrlLoader] = useState(false)
  const [selectedUrlToDelete, setSelectedUrlToDelete] = useState({});
  const [invalidDomain, setInvalidDomain] = useState(false);
  const [urlExists, setUrlExists] = useState(false);

  useEffect( () => {
    getAllUrlRules()
  }, []);

  var getAllUrlRules = async () => {
    try {
      var getAllUrlRulesObj = {};
      getAllUrlRulesObj.url = '/api/dmz/url/fetchAll';
      var response = await customHttp.get(getAllUrlRulesObj.url)
      if(response.data.message!==undefined && response.data.message.length>0){
        let temp=response.data.message
        setUrlRuleList(temp.reverse())
      } else {
        setUrlRuleList([])
      }
    } catch (err){
      console.log("failed",err.response)
    }
  }

  var checkIsUnique = async (url) => {
    try {
      var checkIsUniqueObj = {};
      checkIsUniqueObj.url = '/api/dmz/url/checkRoleExist/'+url;
      var response = await customHttp.get(checkIsUniqueObj.url)
      if(response.data.message){
        setUrlExists(response.data.message.dataExist)
      } else {
        setUrlExists(false)
      }
    } catch (err){
      console.log("failed",err.response)
    }
  }

  var handleChange = (e) => {
    if(e.target.name==='url') {
      setInvalidDomain(false);
      checkIsUnique(e.target.value)
    }
    setUrlRule({...urlRule,[e.target.name]:e.target.value})
  }

  var checkIsValidDomain = (domain) => {
    var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
    return domain.match(re);
  }
  var handleBlur = (e) => {
    if(e.target.name==='url' && e.target.value.length>0){
      setInvalidDomain(!checkIsValidDomain(e.target.value))
    }
  }

  var handleSubmit = async (e) => {
    e.preventDefault();
    setInvalidDomain(!checkIsValidDomain(urlRule.url))
    var handleSubmitObj = {};
    handleSubmitObj.url = '/api/dmz/url/add/';
    handleSubmitObj.data = {};
    handleSubmitObj.data.data = urlRule.url;
    handleSubmitObj.data.userId = userData._id;
    console.log("data to add url",handleSubmitObj.data)
    if(!urlExists && handleSubmitObj.data.data!=='' && handleSubmitObj.data.data!==undefined && checkIsValidDomain(handleSubmitObj.data.data) && handleSubmitObj.data.userId!=='' && handleSubmitObj.data.userId!==undefined){
      setSubmitRequestLoader(true)
      try {
        var response = await customHttp.post(handleSubmitObj.url,handleSubmitObj.data)
        setSubmitRequestLoader(false)
        setSuccessAlertMessage("URL Updated Sucessfully");
        myFunction();
        setUrlRule(urlRuleObj)
        getAllUrlRules()
      } catch (err){
        setErrorAlertMessage("Error try again!");
        myFunction1()
        setSubmitRequestLoader(false);
        console.log("failed",err.response)
      }
    }
  }

  var deleteUrlRule = async (url) => {
    setSelectedUrlToDelete(url)
    try {
      var deleteUrlRuleObj = {};
      deleteUrlRuleObj.url = '/api/dmz/url/remove/';
      deleteUrlRuleObj.data = {};
      deleteUrlRuleObj.data.data = url.data;
      console.log("url to delete",url.data)
      setDeleteUrlLoader(true)
      var response = await customHttp.delete(deleteUrlRuleObj.url,deleteUrlRuleObj.data)
      console.log("delete url res",response);
      setDeleteUrlLoader(false)
      setSuccessAlertMessage("URL Deleted Successfully!");
      myFunction();
      getAllUrlRules()
    } catch (err){
      setErrorAlertMessage("Error try again!");
      myFunction1()
      setDeleteUrlLoader(false);
      console.log("failed",err.response)
    }
  }

  var myFunction=()=> {
    var x = document.getElementById("snackbar")
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", "")}, 3000);
  }

  var myFunction1=()=> {
    var x = document.getElementById("snackbar1")
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }

  return (
    <div>
      <div id="snackbar">{successAlertMessage}</div>
      <div id="snackbar1"><i className="fa fa-exclamation"></i>{errorAlertMessage}</div>
      <div className="col-md-8" style={{overflow:'auto',margin:'10px 0',maxHeight:'78vh'}}>
      <div className="col-md-12 nj-card" >
        <div className="row">
          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4>Blocked URLs</h4>
            </div>
          </div>
          {urlRuleList.length>0 ? <div className="nj-table--tbody">
            <table className="nj-table nj-table--tbody-table font_family_montserrat">
              <thead className="nj-table--thead">
                <tr>
                  <th><div className="nj-table-text">SL.NO</div></th>
                  <th><div className="nj-table-text">URL</div></th>
                  <th><div className="nj-table-text">DELETE</div></th>
                </tr>
              </thead>
              <tbody>
                {urlRuleList.map((url,index)=>{
                  return (<tr key={index} className={"tr-values nj-table--tr tr-table-state-null"}>
                    <td className="td nj-table--td">{index+1}</td>
                    <td className="td nj-table--td">{url.data}</td>
                    <td className="td nj-table--td">
                      {(selectedUrlToDelete._id===url._id) && deleteUrlLoader ? <img src={"./img/loaders/spinningwheel.gif"} height={60} width={65} alt="loader" />
                      :<button type="button" className="nj-flow-button bg-color-red" disabled={deleteUrlLoader} onClick={()=>deleteUrlRule(url)}>Delete</button>}
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>
          :
          <div className="col-md-12" style={{marginTop:"15vh",height:"20vh"}}>
            <p style={{float:"none",textAlign:"center",fontSize:"18px",marginTop:"0px"}} className="no-user-found-text font_family_montserrat">No rule found</p>
          </div>
          }
        </div>
      </div>
      </div>
      <div className="col-md-4">
        <div className="row nj-card nj-cp-request-app-card" style={{marginTop:10}}>
          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4>Block URLs</h4>
            </div>
          </div>
          <form name="requestForm" onSubmit={handleSubmit}>
            <div className="nj-popup-form-wrap">
              <p className="popup-content-label">URL</p>
              <input className="vs-input--input" type="text" name="url" value={urlRule.url} onChange={handleChange} autoComplete="off" placeholder="Ex: www.example.com" onBlur={handleBlur} required />
              {invalidDomain && <span className="color-red" style={{color:"red"}}>Invalid URL</span>}
              {urlExists && <span className="color-red" style={{color:"red"}}>URL already exists</span>}
            </div>
            <div style={{margin:'0 10px 10px 10px',display:'flex',justifyContent:'flex-end'}}>
              {!submitRequestLoader ? <button type="submit" className="nj-flow-button  pull-right"  style={{margin:5}}>Submit</button>:<img  className="pull-right" src={"./img/loaders/spinningwheel.gif"} height={60} width={65} alt="loader" />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UrlRules;
