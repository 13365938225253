import React, { useState, useEffect } from 'react';
import Ratings from '../../packages/Ratings';
import './UserRatings.scss';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
// import Store from "../../container/reducers/Store";
function UserRatings (props) {
  const [averageRatings, setAverageRatings] = useState([]);
  const [ratings, setRatings] = useState([]);
  // const [totalRatings,setTotalRatings] = useState(0)
  const [reviews,setReviews] = useState([])
  
  useEffect( () => {   
    // console.log("hello",props);
    if(props.userRatingsState.userRatings && Object.keys(props.userRatingsState.userRatings).length>0){
      setAverageRatings(props.userRatingsState.userRatings.total.averageRating);
      setRatings(props.userRatingsState.userRatings.ratings)
      // setTotalRatings(props.userRatingsState.userRatings.total.totalCount)
    } else {
      setAverageRatings(0)
    }
  },[props.userRatingsState.userRatings]);

  useEffect( () => {
    if(props.userRatingsState.userReviews && props.userRatingsState.userReviews.length>0){
      setReviews(props.userRatingsState.userReviews);
      // console.log("reviews",reviews);
    } else {
      setReviews([])
    }
  },[props.userRatingsState.userReviews]);
  // onload function end
  var getRatings = () => {

  }
  
  // var reviewsArray = [{a:1},{a:1},{a:1},{a:1},{a:1},{a:1},{a:1}]
  return (
    <div>
      <div className="add-on-load-animation">
        <div className="col-md-12">
          <div className="row">
            <div className="user-ratings-header">
                <h4>Ratings & Reviews</h4>
              <span onClick={()=>{document.getElementById('userRatingsPopup').style.display='none'}} style={{cursor:'pointer',color:'rgb(99, 112, 173)'}}><i className="popup-close material-icons" style={{fontSize:20,marginTop:3}}>close</i></span>
            </div>
          </div>
          <div className="row" style={{overflow:'auto',maxHeight:'60vh'}}>
            <div className="col-md-12">
          <div className="row ratings-progress-wrapper-box">
            <div className="ratings-progress-wrapper">
              <div className="ratings-progress" data-percentage={(averageRatings && Math.round(averageRatings))*20}>
                <span className="ratings-progress-left">
                  <span className="ratings-progress-bar" />
                </span>
                <span className="ratings-progress-right">
                  <span className="ratings-progress-bar" />
                </span>
                <div className="ratings-progress-value">
                  {averageRatings && (parseFloat((parseFloat(averageRatings)).toFixed(1)) || 0)}
                </div>
              </div>
              <Ratings color="rgb(99, 112, 173)" getRatings={getRatings} height="15" width="15" rating={averageRatings && (Math.round(averageRatings) || 0)} key={averageRatings} disable={true} />
            </div>
            <table>
              <tbody className="bar-progress-bar-wrapper">
                <tr>
                  <td style={{padding:'0 10px'}}>Excellent</td>
                  <td style={{width:'100%'}}>
                    <div className="bar-progress-bar">
                      <div className="bar-progress-bar-loaded" data-percent={ratings[4] && ratings[4].percentage} style={{width: ratings[4] && ratings[4].percentage+"%"}} key={ratings[4] && ratings[4].percentage}/>
                    </div>
                  </td>
                  <td style={{minWidth:0}}><span>{ratings[4] && (ratings[4].count || 0)}</span></td>
                </tr>
                <tr>
                  <td>Good</td>
                  <td style={{width:'100%'}}>
                    <div className="bar-progress-bar">
                      <div className="bar-progress-bar-loaded" data-percent={ratings[3] && ratings[3].percentage} style={{width:ratings[3] && ratings[3].percentage+"%"}} key={ratings[3] && ratings[3].percentage}/>
                    </div>
                  </td>
                  <td style={{minWidth:0}}><span>{ratings[3] && (ratings[3].count || 0)}</span></td>
                </tr>
                <tr>
                  <td>Average</td>
                  <td style={{width:'100%'}}>
                    <div className="bar-progress-bar">
                      <div className="bar-progress-bar-loaded" data-percent={ratings[2] && ratings[2].percentage} style={{width: ratings[2] && ratings[2].percentage+"%"}} key={ratings[2] && ratings[2].percentage}/>
                    </div>
                  </td>
                  <td style={{minWidth:0}}><span>{ratings[2] && (ratings[2].count || 0)}</span></td>
                </tr>
                <tr>
                  <td>Below Average</td>
                  <td style={{width:'100%'}}>
                    <div className="bar-progress-bar">
                      <div className="bar-progress-bar-loaded" data-percent={ratings[1] && ratings[1].percentage} style={{width: ratings[1] && ratings[1].percentage+"%"}} key={ratings[1] && ratings[1].percentage}/>
                    </div>
                  </td>
                  <td style={{minWidth:0}}><span>{ratings[1] && (ratings[1].count || 0)}</span></td>
                </tr>
                <tr>
                  <td>Poor</td>
                  <td style={{width:'100%'}}>
                    <div className="bar-progress-bar">
                      <div className="bar-progress-bar-loaded" data-percent={ratings[0] && ratings[0].percentage} style={{width: ratings[0] && ratings[0].percentage+"%"}} key={ratings[0] && ratings[0].percentage}/>
                    </div>
                  </td>
                  <td style={{minWidth:0}}><span>{ratings[0] && (ratings[0].count || 0)}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            {reviews.length>0 &&  <div className="col-md-12" style={{marginTop:10}}>
              <h4 style={{color:'#555',padding:'10px 0'}}>All Reviews ({reviews.length || 0})</h4>
              <div className="review-card-wrapper">
                {reviews.length>0 && reviews.map((item,i)=>{
                  return (
                    <div className="review-card" key={i}>
                      <div className="review-card-info">
                        <p>{item.username}</p>
                        <p>{item.createdAt.substring(0,10)}</p>
                      </div>
                      <div className="review-card-content">
                        <div>
                          <Ratings color="rgb(99, 112, 173)" getRatings={getRatings} height="10" width="10" rating={item.ratings} key={item.ratings} disable={true} />
                        </div>
                        <p>
                          {item.reviews}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>}
          </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => ({
  userRatingsState: state.userRatingsState
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(UserRatings);