import React from 'react';
import {Route , Redirect} from "react-router-dom";


 const  ProtectedRoute=({ component: Component ,...rest})=>{
    var userData=JSON.parse(sessionStorage.getItem("userData"));
    return(
        <Route
        {...rest}
        render={props =>{
            if(userData !== null ){
                return <Component {...props} />
            }else{
                return <Redirect to={
                    {
                        pathname:"/",
                        state:{
                            from:props.location
                        }
                    } 
                } />
            }
        }}
            
        />
    )
}
export default ProtectedRoute;