
// import axios from "axios";
import * as userCourseActions from "./index";
import customHttpUserside from '../../../packages/CustomHttpUserside/';
import snackbar from '../../../packages/Snackbar/';


export  function getAllCourses (){
     return async (dispatch ,getState)=> {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      // console.log("userdetails",userDetails)
         try {
            let response = await customHttpUserside.get('/api/user/getUserDetailsUserSide/email/'+userDetails.email);
            //  console.log("course response",response.data.message);
             if(response.data.message.enabledCourses.length>0){
               for(var i=0;i<response.data.message.enabledCourses.length;i++){
                 var count=0
                 if(response.data.message.enabledCourses[i].resources.length>0){
                  for(var j=0;j<response.data.message.enabledCourses[i].resources.length;j++){
                    if(response.data.message.enabledCourses[i].resources[j].type !==4){
                      for(var k=0;k<response.data.message.enabledCourses[i].resources[j].file.length;k++){
                        if(response.data.message.enabledCourses[i].resources[j].file[k].published===true){
                          count=count+parseInt(response.data.message.enabledCourses[i].resources[j].file[k].timeInvestment)
                         }
                      }
                       if(response.data.message.enabledCourses[i].resources.length-1===j){
                        response.data.message.enabledCourses[i].resourceTimeInvestment=count;
                       }

                    }else{
                        count=count+parseInt(response.data.message.enabledCourses[i].resources[j].timeInvestment)
                        response.data.message.enabledCourses[i].resourceTimeInvestment=count;
                    }
                  }
                 }else{
                  response.data.message.enabledCourses[i].resourceTimeInvestment=response.data.message.enabledCourses[i].timeInvestment;
                 }
               }
             }
             dispatch(userCourseActions.getAllEnabledCourses(response.data.message.enabledCourses));
             dispatch(userCourseActions.getAllRequestedCourses(response.data.message.requestedCourses));
          } catch (err) {
            dispatch(userCourseActions.getAllEnabledCourses([]));
            dispatch(userCourseActions.getAllRequestedCourses([]));
            console.log("error",err)
          }

     }
  }

  export  function submitRatings (){
    return async (dispatch ,getState)=> {
     var userDetails=JSON.parse(sessionStorage.getItem("userData"))
     var ratingObject=JSON.parse(JSON.stringify(getState().userCoursesState))
      var object={};
      if(Object.keys(ratingObject.selectedObject).length>0 && ratingObject.rating){
        dispatch(userCourseActions.setDynamicValues("showRatingLoader",true));
        object.resourceId=ratingObject.selectedObject._id;
        object.userId=userDetails._id;
        object.username=userDetails.username;
        object.reviews=ratingObject.ratingDescription;
        object.ratings=ratingObject.rating;
        object.adminId=userDetails.adminId;
        object.belongsTo=userDetails.belongsTo;
        try {
          let response = await customHttpUserside.post('/api/ratings/create',object);
          dispatch(userCourseActions.setDynamicValues("showRatingLoader",false));
          dispatch(userCourseActions.setDynamicValues("successSnackbarMessage","Rating added successfully"))
          dispatch(userCourseActions.setDynamicValues("rating",""))
          dispatch(userCourseActions.setDynamicValues("ratingDescription",""))
          document.getElementById('resourceRatingPopup').style.display='none';

          snackbar.successSnackbar();
        } catch (err) {
          dispatch(userCourseActions.setDynamicValues("errorSnackbarMessage"," Error try again"))
          dispatch(userCourseActions.setDynamicValues("showRatingLoader",false));
          snackbar.errorSnackbar();
          console.log("error",err)
        }
      }else{
        dispatch(userCourseActions.setDynamicValues("errorSnackbarMessage"," Please rate"))
        snackbar.errorSnackbar();
      }

    }
 }

 export  function submitRatingsForCourse (){
  return async (dispatch ,getState)=> {
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
   var ratingObject=JSON.parse(JSON.stringify(getState().userCoursesState))
    var object={};
    if(Object.keys(ratingObject.selectedObject).length>0 && ratingObject.rating){
      dispatch(userCourseActions.setDynamicValues("showRatingLoader",true));
      object.courseId=ratingObject.selectedObject._id;
      object.userId=userDetails._id;
      object.username=userDetails.username;
      object.reviews=ratingObject.ratingDescription;
      object.ratings=ratingObject.rating;
      object.adminId=userDetails.adminId;
      object.belongsTo=userDetails.belongsTo;
      try {
        let response = await customHttpUserside.post('/api/ratings/create',object);
        dispatch(userCourseActions.setDynamicValues("showRatingLoader",false));
        dispatch(userCourseActions.setDynamicValues("successSnackbarMessage","Rating added successfully"))
        dispatch(userCourseActions.setDynamicValues("rating",""))
        dispatch(userCourseActions.setDynamicValues("ratingDescription",""))
        document.getElementById('ratingPopup').style.display='none';
        snackbar.successSnackbar();
      } catch (err) {
        dispatch(userCourseActions.setDynamicValues("errorSnackbarMessage"," Error try again"))
        dispatch(userCourseActions.setDynamicValues("showRatingLoader",false));
        snackbar.errorSnackbar();
        console.log("error",err)
      }
    }else{
      dispatch(userCourseActions.setDynamicValues("errorSnackbarMessage"," Please rate"))
      snackbar.errorSnackbar();
    }

  }
}

export  function checkCourseRatings (){
  return async (dispatch ,getState)=> {
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
   var courseObject=JSON.parse(JSON.stringify(getState().userCoursesState))
  //  console.log("msg",courseObject);
    if(Object.keys(courseObject.selectedObject).length>0){
      try {
        let response = await customHttpUserside.get('/api/ratings/getRatingsByUserIdUserside/'+userDetails._id+'/courseId/'+courseObject.selectedObject._id);
        // console.log("course ratings",response.data.message)
        dispatch(userCourseActions.setDynamicValues("editCourseRatingObject",response.data.message));
        if(Object.keys(response.data.message).length>0){
          document.getElementById('editRatingPopup').style.display='block';
          document.getElementById('ratingPopup').style.display='none';
        }else{
          document.getElementById('ratingPopup').style.display='block';
          document.getElementById('editRatingPopup').style.display='none';
        }
      } catch (err) {
        document.getElementById('ratingPopup').style.display='block';
        document.getElementById('editRatingPopup').style.display='none';
        console.log("error",err)
      }
    }

  }
}

export  function checkResourceRatings (){
  return async (dispatch ,getState)=> {
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
   var resourceObject=JSON.parse(JSON.stringify(getState().userCoursesState))
    if(Object.keys(resourceObject.selectedObject).length>0){
      try {
        let response = await customHttpUserside.get('/api/ratings/getRatingsByUserIdUserside/'+userDetails._id+'/resourceId/'+resourceObject.selectedObject._id);
        dispatch(userCourseActions.setDynamicValues("editResourceRatingObject",response.data.message));
        if(Object.keys(response.data.message).length>0){
          document.getElementById('editResourcePopup').style.display='block';
          document.getElementById('resourceRatingPopup').style.display='none';
        }else{
          document.getElementById('resourceRatingPopup').style.display='block';
          document.getElementById('editResourcePopup').style.display='none';
        }
      } catch (err) {
        document.getElementById('resourceRatingPopup').style.display='block';
        document.getElementById('editResourcePopup').style.display='none';
        console.log("error",err)
      }
    }

  }
}

export  function UpdateCourseRatings (){
  return async (dispatch ,getState)=> {
    dispatch(userCourseActions.setDynamicValues("showEditCourseRatingLoader",true));
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
   var editCourseRatingObject=JSON.parse(JSON.stringify(getState().userCoursesState.editCourseRatingObject))
    var object={};
    object.userId=userDetails._id;
    object.courseId=editCourseRatingObject.courseId;
    object.reviews=editCourseRatingObject.reviews;
    object.ratings=editCourseRatingObject.ratings;
      try {
        let response = await customHttpUserside.put('/api/ratings/updateRatingsByUserId',object);
        dispatch(userCourseActions.setDynamicValues("successSnackbarMessage","Rating updated successfully"))
        dispatch(userCourseActions.setDynamicValues("showEditCourseRatingLoader",false));
        snackbar.successSnackbar();
        document.getElementById('editRatingPopup').style.display='none';
      } catch (err) {
        dispatch(userCourseActions.setDynamicValues("errorSnackbarMessage"," Error try again"))
        dispatch(userCourseActions.setDynamicValues("showEditCourseRatingLoader",false));
        snackbar.errorSnackbar();
        console.log("error",err)
      }
  }
}

export  function UpdateResourceRatings (){
  return async (dispatch ,getState)=> {
    dispatch(userCourseActions.setDynamicValues("showEditResourceRatingLoader",true));
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
   var editResourceRatingObject=JSON.parse(JSON.stringify(getState().userCoursesState.editResourceRatingObject))
    var object={};
    object.userId=userDetails._id;
    object.resourceId=editResourceRatingObject.resourceId;
    object.reviews=editResourceRatingObject.reviews;
    object.ratings=editResourceRatingObject.ratings;
      try {
        let response = await customHttpUserside.put('/api/ratings/updateRatingsByUserId',object);
        dispatch(userCourseActions.setDynamicValues("successSnackbarMessage","Rating updated successfully"))
        dispatch(userCourseActions.setDynamicValues("showEditResourceRatingLoader",false));
        snackbar.successSnackbar();
        document.getElementById('editResourcePopup').style.display='none';
      } catch (err) {
        dispatch(userCourseActions.setDynamicValues("errorSnackbarMessage"," Error try again"))
        dispatch(userCourseActions.setDynamicValues("showEditResourceRatingLoader",false));
        snackbar.errorSnackbar();
        console.log("error",err)
      }
  }
}

//click on course to get courseId and userId

export  function onClickSendCourseAndUserId (item){
  return async (dispatch ,getState)=> {
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    var object={};
    object.courseId=item._id;
    object.userId=item.userId;
    object.adminId=userDetails.adminId;
    object.belongsTo=userDetails.belongsTo;
    try {
      let response = await customHttpUserside.post('/api/courseViewCount/save ',object);
      // console.log("response",response)
    } catch (err) {
      console.log("error",err)
    }
  }
}

//click on resource to get resourceId and userId

export  function onClickSendResourceAndUserId (item){
  return async (dispatch ,getState)=> {
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    var object={};
    // var userId = sessionStorage.getItem("userId");
    object.resourceId=item._id;
    object.userId = userDetails._id;
    object.belongsTo=userDetails.belongsTo;
    object.adminId=userDetails.adminId;
    object.email=userDetails.email;
    // console.log("object",object)
    try {
      let response = await customHttpUserside.post('/api/resourceViewCount/save ',object);
      // console.log("response",response)
    } catch (err) {
      console.log("error",err)
    }
  }
}
