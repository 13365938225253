import * as requestResourceActions from "./index";
import customHttp from '../../../packages/CustomHttp/';
import snackbar from '../../../packages/Snackbar/';
import * as resourceActions from "../Resources/";
import * as requestedContent from "../RequestedContent/";
import * as resourceContainer from "../../actions/Resources/action";
// const userData=JSON.parse(sessionStorage.getItem('userData'));
const resourceObj = {description:'',discipline:'',contentType:''};

export function handleRequestResourceChange(e) {
   return async (dispatch ,getState)=> {
      const temp = {...getState().requestResourceState.resourceObject, [e.target.name]:e.target.value};
      dispatch(setResourceElements('resourceObject',temp))
   }
}

export function handleCreateResourceSubmit(e,props) {
//   const userData=JSON.parse(sessionStorage.getItem('userData'));
   return async (dispatch ,getState)=> {
      e.preventDefault();
      //console.log("object to submit",getState().requestResourceState,JSON.parse(stateObj.resourceObject.contentType));
      const userData=JSON.parse(sessionStorage.getItem('userData'));
      const stateObj = JSON.parse(JSON.stringify(getState().requestResourceState))
      // console.log("object to submit",getState().requestResourceState,JSON.parse(stateObj.resourceObject.discipline));
      var data = {};
      data.published = true;
      data.category = JSON.parse(stateObj.resourceObject.contentType)._id;
      data.branch = JSON.parse(stateObj.resourceObject.discipline)._id;
      data.categoryName = JSON.parse(stateObj.resourceObject.contentType).name;
      data.branchName = JSON.parse(stateObj.resourceObject.discipline).name;
      data.description = stateObj.resourceObject.description;
      data.userId = userData._id;
      data.belongsTo = userData.belongsTo;
      data.email = userData.email;
      //setShowResourceRequestSubmitLoader(true);
      dispatch(setResourceElements('showResourceRequestSubmitLoader',true))
      try {
         let response = await customHttp.put('/api/requestedCapsule/createRequestedCapsule',data);
         dispatch(resourceActions.setResourceElements('successAlertMessage','Content requested successfully'));
         dispatch(requestedContent.setBooleanValue('successSnackbarMessage','Content requested successfully'));

         dispatch(setResourceElements('resourceObject',resourceObj))
         // console.log("success",response,"state",getState().requestResourceState);
         dispatch(setResourceElements('showResourceRequestSubmitLoader',false))

          var userDetails=JSON.parse(sessionStorage.getItem("userData"))
            if(userDetails.type===1){

               if(props.history.location.pathname==="/requested-content"){
                  dispatch(resourceContainer.toggleRequestResource());
               }
              props.history.push('/requested-content');
              
             }else if(userDetails.type===2){

               if(props.history.location.pathname==="/dlt/requested-content"){
                  dispatch(resourceContainer.toggleRequestResource());
               }
              props.history.push('/dlt/requested-content');

             }else{

               if(props.history.location.pathname==="/sub-admin/requested-content"){
                  dispatch(resourceContainer.toggleRequestResource());
               }
              props.history.push('/sub-admin/requested-content');
            }
         snackbar.successSnackbar();
      } catch (err) {
         dispatch(resourceActions.setResourceElements('errorAlertMessage','Content created successfully'));
         dispatch(requestedContent.setBooleanValue('errorSnackbarMessage','Content created successfully'));
         snackbar.errorSnackbar();
         console.log("error",err);
         dispatch(setResourceElements('showResourceRequestSubmitLoader',false))
      }
   }
}

export function setResourceElements(name,content) {
   return (dispatch ,getState)=> {
      dispatch(requestResourceActions.setRequestResourceElements(name,content))
   }
}
