import * as resourceActionTypes from "../../../actionTypes";
const initialState = {
  errorAlertMessage:'',
  selectedApplications:[],
  showSlotBooking:false,
  successAlertMessage:'',
  userApplications:[],
  userCourse:{},
  selected:[]
 
}

export default function(state=initialState, action) { 
  switch (action.type) {
    case resourceActionTypes.BOOK_SLOT_COMPONENT_ELEMENTS: {
      const {name,content } = action.payload; 
      return {
        ...state,[name]:content
      }
    }
    default:
      return state;
  }
}