import React, { useState, useEffect } from 'react';
import './LiveCourse.scss';


function UserLiveCourses (props) {
  const [resourceArray,setResourceArray] = useState([{displayName:"CS Classroom"},{displayName:"Mechanical Classroom"}]);

  useEffect( () => {
  },[]);
  // onload function end
  var goToLive=()=>{
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      if(userDetails.type===1){
      props.history.push("/live-stream")
        }else if(userDetails.type===2){
        props.history.push("/dlt/live-stream")
        }else if(userDetails.type===0){
        props.history.push("/user/live-stream")
      }else{
      props.history.push("/sub-admin/live-stream")
      } 
  }
  return(
    <div className="add-on-load-animation">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
            <div className="col-md-12 col-sm-12 nj-card" >
              <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                <div className="nj-card__header">
                  <div className="nj-card__title">
                    <h4 className="font_family_montserrat">Live Classrooms</h4>
                  </div>
                  {/* <div style={{paddingBottom:'9px'}}>
                    <button className="nj-flow-button pull-right" type="submit" >Back</button>
                  </div> */}
                </div>
              </div>
              <div style={{height:"78vh",marginTop:"5px"}}>
                {resourceArray.length>0 ?
                  <div className="resources-wrapper">
                    {resourceArray.map((item, i) => {
                      return (
                        <div className="resource_card add-rebound-animation" key={i} onClick={goToLive}>
                          <div className="resource_card-content">
                            <div className="resource_card-content-img-wrapper">
                              <img className="resource_card-content-img live-course-img" src={"/img/courses23.png"} alt="resource" />
                            </div>
                          </div>                   
                          <div className="resource_card-footer">
                            <p className="font_family_montserrat" style={{fontSize:"14px"}}>{item.displayName}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                :
                <div>
                  <div className="col-md-12 font_family_montserrat idams_noDataFound live-course-nodatafound" >No data found</div>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default UserLiveCourses;