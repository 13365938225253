import * as resourceActionTypes from "../../../actionTypes";

export const setDisciplines = content => ({
  type: resourceActionTypes.RESOURCE_DISCIPLINES,
  payload: {
    content
  }
});

export const setContentTypes = content => ({
  type: resourceActionTypes.RESOURCE_CONTENT_TYPES,
  payload: {
    content
  }
});

export const setResourceArray = content => ({
  type: resourceActionTypes.RESOURCE_RESOURCE_ARRAY,
  payload: {
    content
  }
});

export const setResourceOriginalArray = content => ({
  type: resourceActionTypes.RESOURCE_RESOURCE_ORIGINAL_ARRAY,
  payload: {
    content
  }
});

export const setSelectedContentType = content => ({
  type: resourceActionTypes.RESOURCE_SELECTED_CONTENT_TYPE,
  payload: {
    content
  }
});

export const setSelectedDiscipline = content => ({
  type: resourceActionTypes.RESOURCE_SELECTED_DISCIPLINE,
  payload: {
    content
  }
});

export const toggleRequestResource = content => ({
  type: resourceActionTypes.RESOURCE_SHOW_REQUEST_RESOURCE,
  payload: {
    content
  }
});

export const setFilteredArray = content => ({
  type: resourceActionTypes.RESOURCE_FILTERED_ARRAY,
  payload: {
    content
  }
});

export const setResourceElements = (name,content )=> ({
  type: resourceActionTypes.RESOURCE_COMPONENT_ELEMENTS,
  payload: {
    name,
    content
  }
})