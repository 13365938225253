import React, {useEffect, useState } from 'react';
import { connect } from "react-redux";
// import * as DashboardContainer from "../../../container/actions/Dashboard/action";
// import Store from "../../container/reducers/Store";
import { bindActionCreators } from 'redux';
// import ReactApexChart from 'react-apexcharts';
import './TopApps.scss';

function TopApps (props) {
  const [series,setSeries] = useState([]);
  const [options,setOptions] = useState({});

  useEffect( () => {   
    setChartProps() 
  },[]);
  // onload function end
  const setChartProps=()=>{
    const series= [80,70,50,35,20];
    setSeries(series);
    const options= {
      chart: {
        width: '100%',
        type: 'radialBar',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
              enabled: true,
              delay: 150
          },
          dynamicAnimation: {
              enabled: true,
              speed: 350
          }
        }
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: '22px',
            },
            value: {
              fontSize: '16px',
            },
            total: {
              show: true,
              label: 'Average',
              formatter: function (w) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return '70%'
              }
            }
          },
          hollow: {
            margin: 10,
            size: '30%',
            background: 'transparent',
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 40,
              left: 30,
              blur: 2,
              opacity: 0.9
            }
          },
          track: {
            show: true,
            startAngle: undefined,
            endAngle: undefined,
            background: '#eeeeee',
            strokeWidth: '97%',
            opacity: 1,
            margin: 10, 
            dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.1
            }
          },
        }
      },
      legend:{show:false},
      labels: ['Chrome', 'Solid Works', 'Matlab', 'AutoCAD', 'Autodesk Inventor'],
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'round',
        colors: undefined,
        width: 0,
        dashArray: 0,      
      },
      theme: {
        mode: 'light', 
        palette: 'palette11',
        monochrome:{
          enabled:true
        }
      },
    }
    setOptions(options)
  };
  return (
    <div>
      {/* <ReactApexChart options={options} series={series} type="donut" height={270} />
      <div className="top-courses-label">
        <ul>
          <li>
            <div><div style={{borderColor:'#008FFB'}}></div><span>Chrome</span></div>
            <span>80 Mins</span>
            <div>
              <div className="progress">
                <div className="bar" style={{width: '35%'}}>
                  <p className="percent">35%</p>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div><div style={{borderColor:'#00E396'}}></div><span>Solid Works</span></div>
            <span>70 Mins</span>
          </li>
          <li>
            <div><div style={{borderColor:'#FEB019'}}></div><span>Matlab</span></div>
            <span>50 Mins</span>
          </li>
          <li>
            <div><div style={{borderColor:'#FF4560'}}></div><span>AutoCAD</span></div>
            <span>35 Mins</span>
          </li>
          <li>
            <div><div style={{borderColor:'#775DD0'}}></div><span>Autodesk Inventor</span></div>
            <span>20 Mins</span>
          </li>
        </ul>
      </div> */}
      {props.dashboardState.topApps.length>0?<div style={{minHeight:455}}>
        {props.dashboardState.topApps.map((item,i)=>{
          return (<div className="top-apps01-wrapper" key={i} style={{marginTop:10}}>
            <div className="top-apps01">
              <ul>
                <li>
                  <p>{item._id}</p>
                  <span></span>
                </li>
                <li>
                  <h4>{parseInt(item.percentage)+'%'}</h4>
                  <span>{item.sum} Mins</span>
                </li>
              </ul>
            </div>
            <div className="nj-progress01">
              <div className="bar" style={{width: parseInt(item.percentage)+'%'}}>
                {/* <p className="percent">35%</p> */}
              </div>
            </div>
          </div>)
        })}
      </div>:
      <div className="top-apps-no-data">
      {!props.dashboardState.topAppsLoader?<p>No data found..</p>:
        <img src="/img/loaders/spinningwheel.gif" alt="" height="50" width="50" />}
      </div>}
    </div>
  );
}


const mapStateToProps = state => ({
  dashboardState: state.dashboardState
});

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(TopApps);
