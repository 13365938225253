import React, {useEffect } from 'react';
import DatePicker from 'react-date-picker';

function DatePickerComponent (props) {

 var handleChangeDate=(a)=>{
  props.onChange(a)
 }
 useEffect( () => {  

},[]);
// onload function end
var datePickerValue=()=>{
  if(props.value){
    return new Date(props.value)
  } else return ''
}
var datePickerMinDate=()=>{
  if(props.minDate){
    return new Date(props.minDate)
  } else return new Date()
}

var datePickerMaxDate=()=>{
  if(props.maxDate){
    return new Date(props.maxDate)
  } else return props.maxDate
}
  return (
    <div>     
      <DatePicker className={props.dataPicker}
        calendarClassName={props.calendarClassName}
        maxDate={datePickerMaxDate()}
        minDate={datePickerMinDate()}
        clearIcon={null}
        calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
        showNeighboringMonth={false}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        format={'dd/MM/yyyy'}
        value={datePickerValue()}
        onChange={handleChangeDate}
        required={props.required} 
        disabled={props.disabled}        
    />
  </div>     
  );
}

export default DatePickerComponent;
