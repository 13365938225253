import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Subadmins.scss';
// import { handleChangeCreateUser } from '../../container/actions/Users';
import snackbar from '../../packages/Snackbar/';
import customHttp from '../../packages/CustomHttp';

function Subadmins (props) {
const obj = {"email":""}
const [createArray,setcreateArray]=useState(obj);
const [getUserDetails,setGetUserDetails] = useState([]);
const [emailExist,setEmailExist]=useState(false);
const[userData , setUserData] = useState({});
const[departmentData , setDepartmentData] = useState({});
const[showLoader , setShowLoader] = useState(false);
// const[showLoaderForDepartment , setShowLoaderForDepartment] = useState(false);
const[submitLoader,setSubmitLoader]=useState(false);
const[departmentSubmitLoader,setDepartmentSubmitLoader]=useState(false);
const[confirmLoader,setConfirmLoader]=useState(true);
const[successSnackbarMessage,setSuccessSnackbarMessage]=useState();
const deleteSnackbarMessage="";
const errorSnackbarMessage="";
// const[deleteSnackbarMessage,setDeleteSnackbarMessage]=useState();
const[hideCancelButton,setHideCancelButton]=useState(true);
const[errorEmail,setErrorEmail]=useState(false);
const[valueCheck,setValueCheck]=useState();
const[disabled,setDisabled]=useState(false);
const[departmentArray,setDepartmentArray]=useState([]);
const[department,setDepartment]=useState("");
const[departmentName,setDepartmentName]=useState("");
const[departmentAlreadyExists,setDepartmentAlreadyExists]=useState(false);
const[createdUsersideDepartment,setCreatedUsersideDepartment]=useState("");
const[showCreateUser,setShowCreateUser]=useState(true);
const[showCreateDepartment,setShowCreateDepartment]=useState(true);
const[showUserList,setShowUserList]=useState(true);
const[expanded, setExpanded] = useState(false);
const[selectedDepartmentArray, setSelectedDepartmentArray] = useState([]);
const[noDepartment,setNoDepartment] = useState(false);
const[noDepartmentwhileEditing,setNoDepartmentwhileEditing] = useState(false);
const[loadDepartment,setLoadDepartment] = useState("");
const node = useRef();
const[departmentNameValidation,setDepartmentNameValidation]=useState(false);

// Edit variables
const[errorSnackbarMessageForEdit,setErrorSnackbarMessageForEdit]=useState();
const[hideEditUserCancelButton,setHideEditUserCancelButton] = useState(true);
const[editUserConfirmLoader,setEditUserConfirmLoader]=useState(true);
const[editUserData,setEditUserData]=useState({});
const[expandedEdit, setExpandedEdit] = useState(false);
const[selectedDepartmentArrayForEdit, setSelectedDepartmentArrayForEdit] = useState([]);
const[tempArray, setTempArray] = useState([]);
const editSubNode = useRef();
const[displayDept, setDisplayDept] = useState([]);

useEffect(() => {
  // document.addEventListener('mousedown',handleClick);

  getDepartment();
  // return () => {
  //   document.removeEventListener("mousedown", handleClick);
  // };
},[])

// var handleClick = (e) => {
//   if(node.current!== null){
//     if (node.current.contains(e.target)) {
//     return;
//
//     }
//     else{
//
//       setExpanded(false);
//       document.getElementById('checkboxes').style.display="none";
//     }
//   }
//
// }

var loadUserDetails=(nameOfDepartment)=>{
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    //getting data from db
    // axios.get('/api/adminUser/getUser/type/3')
    var url = '/api/adminUser/getUser/type/'+3+'/belongsTo/'+userDetails.belongsTo+'/department/'+nameOfDepartment;
    // api/adminUser/getUser/type/:type/belongsTo/:belongsTo/department/:department
    customHttp.get(url)
    .then(res => {
    setGetUserDetails(res.data.message);
    setShowLoader(true);
    // console.log("api getting", getUserDetails)
    }).catch((error)=>{
      console.log("error",error);
    });
}


var handleChangeCreateUser =(event)=>{
//set value in variable once click input field
setcreateArray({...createArray,[event.target.name]:event.target.value});
setErrorEmail(false)
setValueCheck(event.target.value);
// axios.get("/api/user/validateUser/email/" + event.target.value .toLowerCase())
var url = "/api/user/validateUser/email/" + event.target.value .toLowerCase();
customHttp.get(url)
.then(response => {
setEmailExist(response.data.message.userExists);
// let checkEmail = response.data;
// console.log("checkEmail", checkEmail,response.data.message.userExists);
let temp = response.data.message.userExists;
if(!temp){
console.log("email not registered")
}else{
console.log("email already exist")
}
}).catch(error => {
console.log("failed", error);
});

}

// var handleChangeCreateUserDropdown=(event)=>{
//   //set value in variable once click input field
//   setDepartmentName(event.target.value);
//   // console.log("event.target.value",event.target.value);
// }

var formSubmit =(event)=>{

event.preventDefault();
var adminId=JSON.parse(sessionStorage.getItem("userData"))
var userDetails={};
userDetails.department = selectedDepartmentArray
userDetails.email = createArray.email;
userDetails.type = 3;
userDetails.adminId = adminId._id;
userDetails.belongsTo = adminId.belongsTo;
// userDetails.department = departmentName;
console.log("userDetailsuserDetails",userDetails)
// console.log("userDetails",userDetails);
//check email validation
var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (reg.test(valueCheck)=== false)
  {
    console.log("incorrect email");
    setErrorEmail(true);
  }else if(emailExist===true){
    console.log("this email exist");
  }
  else if(selectedDepartmentArray.length === 0){
    setNoDepartment(true);
   }
  else{
    setSubmitLoader(true);
    //post data to db
    // axios.post('/api/adminUser/createUser',userDetails)
    var url = '/api/adminUser/createUser' ;
    // console.log("userdetails",userDetails)
    customHttp.post(url,userDetails)
    .then(res => {
        setSubmitLoader(false);
        clearUserInputFields();
        setDepartmentArray([]);
        getDepartment();
		    document.getElementById('checkboxes').style.display="none";
        setSelectedDepartmentArray([]);
        // showCheckboxes();
        unCheckAllCheckbox();
        loadUserDetails(createdUsersideDepartment);
        snackbar.successSnackbar();
        setSuccessSnackbarMessage("User Created Successfully!");
    })
    .catch((error)=>{
		//snackbar.errorSnackbar();
        //setErrorSnackbarMessage("Error try again.");
        console.log("There is an API error");
    });
  }
}

//onclick delete button popup will appear
var getConfirmDeletePopup= async(data)=>{
// console.log("delete data",data);
setUserData(data);
setConfirmLoader(false);
document.getElementById("deleteUserPopup").style.display="block";
}

var getConfirmDeletePopupForDepartment = async(data)=>{
  // console.log("delete data",data);
  setDepartmentData(data);
  setConfirmLoader(false);
  document.getElementById("deleteDepartmentPopup").style.display="block";
}

//onclick confirm button table row will delete
var deleteRequestedContent=(e,data)=>{
e.preventDefault();
setConfirmLoader(true);
setHideCancelButton(false);
setDisabled(true);

// axios.delete('api/adminUser/createUser',{data:data})
var url = '/api/adminUser/createUser' ;
customHttp.delete(url,data )
.then(response=>{
setDisabled(false);
loadUserDetails(createdUsersideDepartment);
//hide the popup box after confirm button clicked
document.getElementById("deleteUserPopup").style.display='none';
loadUserDetails(createdUsersideDepartment);
snackbar.successSnackbar();
setSuccessSnackbarMessage("User Deleted Successfully!");
})
.catch(error => {
setDisabled(false);
console.log("error",error)
});
}

var clearUserInputFields=()=>{
  setcreateArray({"email":""});
}

var hideDeletePopUp=(e)=>{
  e.preventDefault();
  setUserData("");
  // setDisabled(!disabled);
  document.getElementById("deleteUserPopup").style.display="none";
}

var hideDepartmentDeletePopUp=(e)=>{
  e.preventDefault();
  setDepartmentData("");
  document.getElementById("deleteDepartmentPopup").style.display="none";
}

var handleChangeDepartment=(e)=>{
  setCreatedUsersideDepartment(e.target.value)
  loadUserDetails(e.target.value);
  setLoadDepartment(e.target.value)

  // console.log("handleChangeDepartment",e.target.value);
}

var handleChangeCreateDepartment=(e)=>{
  if (/^[0-9A-Za-z- ]*$/.test(e.target.value)){
    setDepartmentNameValidation(false)
  }else{
    setDepartmentNameValidation(true)
  }
  setDepartmentAlreadyExists(false)
  setDepartment(e.target.value)
  getDepartment();
}

var saveDepartment=(e)=>{
  e.preventDefault();
    var userDetails = JSON.parse(sessionStorage.getItem("userData"))
    setDepartmentSubmitLoader(true)
    //getting data from db
    // axios.get('/api/adminUser/getUser/type/3')
    var url = '/api/department/createDepartment'
    var departmentCreate = {}
        departmentCreate.name = department;
        departmentCreate.type = 3;
        departmentCreate.adminId = userDetails._id;
        departmentCreate.belongsTo = userDetails.belongsTo;
    customHttp.post(url,departmentCreate)
    .then(response => {
      // console.log("saveDepartment", response.data.message)
      if(response.data.message === "department name already exists"){
        setDepartmentAlreadyExists(true)
        setDepartmentSubmitLoader(false)
      }else {
        setDepartment("")
        setDepartmentSubmitLoader(false)
        snackbar.successSnackbar();
        setSuccessSnackbarMessage("Department Created Successfully!");
        getDepartment();
      }
    }).catch((error)=>{
      setDepartmentSubmitLoader(false)
      console.log("error",error);
    });
}

var getDepartment=()=>{
    var userDetails = JSON.parse(sessionStorage.getItem("userData"))
    //getting data from db
    // axios.get('/api/adminUser/getUser/type/3')
    var url = '/api/department/getDepartment/belongsTo/'+userDetails.belongsTo;
    // console.log("url",url,userDetails.belongsTo);
    customHttp.get(url)
    .then(response => {
      //console.log("getDepartment", response.data.message)
      // console.log("getDepartment1234567890", response.data.message[0].departmentName)
      setDepartmentArray(response.data.message)
      setShowLoader(true);
      if(response.data.message.length > 0){
        if(response.data.message[0].name !== undefined || response.data.message[0].name !== null || response.data.message[0].name !== ""){
          loadUserDetails(response.data.message[0].name);
          setCreatedUsersideDepartment(response.data.message[0].name)
        }
      }

    }).catch((error)=>{
      setShowLoader(true);
      console.log("error",error);
    });
}

var deleteDepartmentContent=(e,data1)=>{
  // console.log("deleteDepartmentContent",data1);
e.preventDefault();
setConfirmLoader(true);
let data = {}
data.name = data1.name;
data.belongsTo = data1.belongsTo;
// axios.delete('api/adminUser/createUser',{data:data})
var url = ' /api/department/createDepartment' ;
customHttp.delete(url,data)
.then(response=>{
//hide the popup box after confirm button clicked
document.getElementById("deleteDepartmentPopup").style.display='none';
getDepartment();
snackbar.successSnackbar();
setSuccessSnackbarMessage("Department Deleted Successfully!");
})
.catch(error => {
console.log("error",error)
});
}

var showCreateUserTab=()=>{
  setShowCreateDepartment(true)
  setShowCreateUser(true)
  setShowUserList(true)
  getDepartment();
}

var showCreateDepartmentTab=()=>{
  setShowCreateUser(false)
  setShowCreateDepartment(false)
  setShowUserList(false)
  getDepartment();
}

var showCheckboxes=()=>{
  var checkboxes = document.getElementById("checkboxes");
  if (!expanded) {
    checkboxes.style.display= "block";
    setExpanded(true)
  } else {
    checkboxes.style.display= "none";
    setExpanded(false);
  }
}

// Edit sub admin start //

var editSubadminOpenPopup = (data)=>{
  setEditUserData(data);
  setNoDepartmentwhileEditing(false);
  setSelectedDepartmentArrayForEdit(data.departmentArrayForEdit)
  setDisplayDept(data.departmentArrayForEdit);
  for(var i=0; i<departmentArray.length; i++){
	for(var j=0; j<data.departmentArrayForEdit.length; j++){
		if(departmentArray[i].name == data.departmentArrayForEdit[j].name){
			departmentArray[i].checked=true
		}
	}
  }
  setEditUserConfirmLoader(false)
  setHideEditUserCancelButton(false);
  document.getElementById("editSubadminPopup").style.display="block";
}

var hideEditPopUp=(e)=>{
  e.preventDefault();
  unCheckAllCheckboxEditPopup();
  // setDisabled(!disabled);
  document.getElementById("editCheckboxes").style.display="none";
  document.getElementById("editSubadminPopup").style.display="none";
  getDepartment()
}

// var editSubadminSubmitButton = async()=>{
//   console.log("cancel popup");
//   setEditUserConfirmLoader(true)
//   document.getElementById("editSubadminPopup").style.display="block";
// }

var showEditCheckboxes=()=>{
  var editCheckboxes = document.getElementById("editCheckboxes");
  if (!expandedEdit) {
    editCheckboxes.style.display= "block";
    setExpandedEdit(true)
  } else {
    editCheckboxes.style.display= "none";
    setExpandedEdit(false);
  }
}

var handleChangeEditCheckbox=(e,data)=>{
  if(e.target.checked){
  	let obj = {};
  	obj._id = data._id;
  	obj.name = data.name;
    selectedDepartmentArrayForEdit.push(obj);
    setNoDepartmentwhileEditing(false);
  for(var i=0; i<departmentArray.length; i++){
    if(departmentArray[i].name ===data.name){
    
      departmentArray[i].checked=true;
      let temp=[];
      temp = JSON.stringify(departmentArray)
      setDepartmentArray( JSON.parse(temp) )
    }
  }
  }
  else{
  	let obj = {};
  	obj._id = data._id;
    obj.name = data.name;
  
    for(var i=0; i<selectedDepartmentArrayForEdit.length; i++){
      if(selectedDepartmentArrayForEdit[i]._id ===  obj._id){  
        selectedDepartmentArrayForEdit.splice(i,1);
      }
    }

    for(var i=0; i<departmentArray.length; i++){
      if(departmentArray[i].name ===data.name){
        departmentArray[i].checked=false;
        let temp=[];
        temp = JSON.stringify(departmentArray)
        setDepartmentArray( JSON.parse(temp) )
      }
    }
  }
}

var editUserSubmit=(e)=>{
  e.preventDefault();

  var data = {};
  data._id = editUserData._id;
  data.department = selectedDepartmentArrayForEdit;

  if(selectedDepartmentArrayForEdit.length === 0){
    setNoDepartmentwhileEditing(true);
   }

  else{
  setNoDepartmentwhileEditing(false);
  var url = '/api/adminUser/createUser';
  customHttp.put(url,data)
  .then(response=>{
	    console.log("success",response.data.message)
		loadUserDetails(createdUsersideDepartment);
		document.getElementById("editSubadminPopup").style.display="none";
		snackbar.successSnackbar();
		setSuccessSnackbarMessage("Sub admin Updated Successfully!");
		unCheckAllCheckboxEditPopup();
		document.getElementById("editCheckboxes").style.display="none";
  })
  .catch(error=>{
	  console.log("failed",error)
	  snackbar.errorSnackbar();
	  setErrorSnackbarMessageForEdit("Error try again!");
  })
  }

}

var unCheckAllCheckboxEditPopup=()=>{
  var subNodes = document.getElementsByTagName('input');
  for (var i = 0; i < subNodes.length; i++) {
    var node1 = subNodes[i];

    if (node1.getAttribute('type') == 'checkbox') {
      node1.checked=false;
    }
  }
}

// Edit sub admin end //

var handleChangeCheckbox=(e,data)=>{
  setNoDepartment(false);
  if(e.target.checked){
	  let obj = {}
		obj._id = data._id;
		obj.name = data.name;
    selectedDepartmentArray.push(obj);
	//setTempArray(obj)
    //console.log("selectedDepartmentArray",selectedDepartmentArray,tempArray);
  }
  else{
	  let obj = {};
	  obj._id = data._id;
	  obj.name = data.name;
  
    for(var i=0; i<selectedDepartmentArray.length; i++){
      if(selectedDepartmentArray[i]._id ===  obj._id){
        selectedDepartmentArray.splice(i,1);
      }
    }
    console.log("msg",selectedDepartmentArray);
  }
}

var unCheckAllCheckbox=()=>{
  var nodes = document.getElementsByTagName('input');
  for (var i = 0; i < nodes.length; i++) {
    var node = nodes[i];

    if (node.getAttribute('type') == 'checkbox') {
      node.checked=false;
    }
  }
}


return (
  <div>
  {/*console.log("dept array",selectedDepartmentArray,displayDept)*/}
  <div className="row">
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      {showCreateUser ?
        <button type="button" className="nj-flow-button-subadmin-selected" style={{outline: 'none'}}>Create User</button>
         :
        <button type="button" onClick={showCreateUserTab} className="nj-flow-button-subadmin-unselected" style={{outline: 'none'}}>Create User</button>
      }

      {showCreateDepartment ?
        <button type="button" onClick={showCreateDepartmentTab} className="nj-flow-button-subadmin-unselected" style={{outline: 'none'}}>Create Department</button>
         :
        <button type="button" className="nj-flow-button-subadmin-selected" style={{outline: 'none'}}>Create Department</button>
      }
    </div>
  </div>

{showUserList ?
<div className="row">
<div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
<div id="errorSnackbar">{deleteSnackbarMessage}</div>

  <div className="col-md-12 col-sm-12 nj-card">

  <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
    <div className="nj-card__header">
      <div className="nj-card__title">
        <h4 className="font_family_montserrat">Created Users</h4>
      </div>
    </div>
    {departmentArray.length > 0 &&
      <div className="col-md-4 nj-popup-form-wrap" style={{margin: '10px 0px'}}>
          <p className="popup-content-label" style={{fontSize: 14}}>Select Department</p>
          <div className="nj-form-select">
              <select name="createdUsersideDepartment" style={{textTransform: 'capitalize'}} value={createdUsersideDepartment} onChange={handleChangeDepartment} autoComplete="off" required>
              {departmentArray.map((data,i)=>{
                  return (<option key={i} value={data.name}>{data.name}</option>)
                })}
              </select>
          </div>
      </div>
    }
  </div>

  <div className="col-md-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
  {showLoader ?
  <div className="course-table-margin-top">
  {getUserDetails.length>0?
    <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"66.3vh"}}>
      <table className="nj-table nj-table--tbody-table font_family_montserrat">
        <thead className="nj-table--thead">
          <tr>
            <th>
              <div className="nj-table-text">SL NO.</div>
            </th>
            <th>
              <div className="nj-table-text">Email</div>
            </th>
            <th>
              <div className="nj-table-text">Department</div>
            </th>
            <th style={{textAlign:"center"}}>
              <div className="nj-table-text">ACTIONS</div>
            </th>
          </tr>
        </thead>
        <tbody className="sub_admin_form_row_tbody">
          {getUserDetails.map((data,i) => {
            return(
              <tr key={i} className="tr-values nj-table--tr tr-table-state-null ">
                <td className="sub_admin--td">{i+1}</td>
                <td className="sub_admin--td">{data.email}</td>
                <td className="sub_admin--td" style={{textTransform: 'capitalize'}}>{data.department}</td>
                <td className="" style={{textAlign:"center",minWidth: '210px'}}>
                  <button type="button" onClick={()=>editSubadminOpenPopup(data)} className="nj-flow-button">Edit</button>
                  <button type="button" onClick={()=>getConfirmDeletePopup(data)} className="nj-flow-button bg-color-red addRoles_button" style={{float:"none",marginTop:"5px",marginLeft:"5px"}}>Delete</button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  :
  <div className="col-md-12 idams_noDataFound" style={{height:"64.3vh"}}>
  <span className="font_family_montserrat">No data found</span>
  </div>
  }
  </div> :
  <div className="row" style={{height:"66.3vh"}}>
  <div className="col-md-12 aligh-loader-image-center" >
  <img src={"/img/loaders/spinningwheel.gif"} height={80} width={80} alt="loader" />
  </div>
  </div>
  }
  </div>

  </div>
  </div>


  {/* creating a new user form start*/}
    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
    <div id="successSnackbar">{successSnackbarMessage}</div>
    <div id="errorSnackbar">{errorSnackbarMessage}</div>
      <div className="col-md-12 col-sm-12 nj-card">
        <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>

          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4 className="font_family_montserrat">Create New User</h4>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sub_admin_form">
            <form onSubmit={formSubmit}>
              {/* <div className="row sub_admin_form_row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{margin: '15px 0'}}>
                    <label className="idams-label pull-left">Select Department</label>
                    <div className="nj-form-select">
                        <select style={{textTransform: 'capitalize'}} name="departmentName" value={departmentName} onChange={handleChangeCreateUserDropdown} autoComplete="off" required>
                          <option value=''>Select Department</option>
                          {departmentArray.map((data,i)=>{
                              return (
                                <option key={i} value={data.name}>{data.name}</option>
                              )
                            })
                          }
                        </select>
                    </div>

                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: '20px'}}>
                  <label className="idams-label pull-left">Email</label>
                  <input type="email" name="email" value={createArray.email} onChange={handleChangeCreateUser} className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="ex : john@email.com" autoComplete="off" required />
                  {errorEmail && <p style={{marginTop:'9px',color:'red'}}>Invalid email </p>}
                  {emailExist && <p style={{marginTop:'9px',color:'red'}}>Email is already taken!</p>}
                </div>
                <div className="col-lg-3 col-md-2 col-sm-12 col-xs-12 pull-right" style={{paddingBottom:"15px"}}>
                  {submitLoader ?<div style={{height:'42',width:"30px"}}><img src={"/img/loaders/spinningwheel.gif"} height={55} width={65} alt="loader" /></div>: <button type="submit" className="nj-flow-button pull-right" >Submit</button>}
                </div>
              </div> */}

                   <div className="row dl-team_form_row" style={{marginTop:'10px'}}>
                      <div className="col-lg-12 col-md-12 col-sm-8 col-xs-8">
                          <label className="idams-label pull-left">Select Department</label>
                          {/* <div className="nj-form-select">
                            <select className="vs-input--input" onChange={handleChangeCreateUserDepartment} required>
                              <option value="">Select a Department</option>
                              {getDepartment.map((data,i) =>{
                                return <option key={i} value={data.name}>{data.name}</option>
                              })}
                            </select>
                          </div> */}


                          <div className="multiselect" style={{cursor: 'pointer'}} ref={node}>
                            <div className="nj-form-select" onClick={showCheckboxes}>
                              <select className="vs-input--input" required>
                                <option>Select a Department</option>
                              </select>
                              <div className="overSelect"></div>
                            </div>
                            {departmentArray.length>0 ?
                            <div id="checkboxes" style={{maxHeight:"150px"}}>
                                {departmentArray.map((data,i) =>{
                                  return (
                                  //   <label style={{paddingLeft:"5px"}} key={i}>
                                  // <input type="checkbox" key={i} onChange={e => handleChangeCheckbox(e)} value={data.name}/> {data.name} </label>
                                  <label className="checkbox coloured" key={i}>
                                    <input type="checkbox"  key={i} onChange={e => handleChangeCheckbox(e,data)} style={{paddingLeft:"5px"}} value={data.name}/><span className="checkbox-material"><span className="check"></span></span>  {data.name}
                                </label>
                                  )
                              })}

                            </div>
                            :
                            <div id="checkboxes">
                              <p style={{textAlign:"center",marginTop:"2%",fontFamily:"Montserrat',Sans-serif"}}> No Department found</p>
                            </div>
                          }
                          </div>

                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom:"20px",marginTop:"10px"}}>
                          <label className="idams-label pull-left">Email</label>
                          <input type="email" name="email" value={createArray.email} onChange={handleChangeCreateUser}  className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="ex : john@email.com" autoComplete="off" required />
                          {errorEmail && <p style={{marginTop:'9px',color:'red'}}>Invalid email </p>}
                          {emailExist && <p style={{marginTop:'9px',color:'red',marginBottom:'0px'}}>Email is already taken!</p>}
                          {noDepartment && <p style={{marginTop:'9px',color:'red',marginBottom:'0px'}}>Select atleast one Department!</p>}
                      </div>
                      <div className="col-lg-3 col-md-2 col-sm-4 col-xs-4 pull-right" style={{paddingBottom:"15px"}}>
                      {submitLoader ?<div style={{height:'42',width:"30px"}}><img src={"/img/loaders/spinningwheel.gif"} height={50} width={70} alt="loader" /></div>: <button  type="submit" className="nj-flow-button pull-right" >Submit</button>}
                      </div>
                  </div>




            </form>
          </div>

        </div>
      </div>
    </div>
  {/* creating a new user form ends*/}

  {/* onclick edit button in user table this popup will appear*/}
  <div id="editSubadminPopup" className={"nj-overly add-rebound-animation"}>
	<div id="successSnackbar">{successSnackbarMessage}</div>
    <div id="errorSnackbar">{errorSnackbarMessageForEdit}</div>
    <div className="popup-nj my-popup">
      <header className="popup-header">
        <span>Edit User</span>
        <span onClick={hideEditPopUp} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
      </header>
      <div className="popup-content">
        <div className="col-md-12">
          <form name="roleForm" onSubmit={editUserSubmit}>
            <div className="nj-popup-form-wrap">
              <label className="idams-label pull-left" style={{paddingBottom: '10px'}}>Email : {editUserData.email}</label>
            </div><br/>

            <div className="nj-popup-form-wrap">
            <label className="idams-label pull-left">Select Department</label>
            <div className="editMultiselect" style={{cursor: 'pointer'}} ref={editSubNode}>
              <div className="nj-form-select" onClick={showEditCheckboxes}>
                <select className="vs-input--input" required>
                  <option>Select a Department</option>
                </select>
                <div className="editOverSelect"></div>
              </div>

              <div id="editCheckboxes">
                  {departmentArray.map((data,i) =>{
                    return (
                    //   <label style={{paddingLeft:"5px"}} key={i}>
                    // <input type="checkbox" key={i} onChange={e => handleChangeCheckbox(e)} value={data.name}/> {data.name} </label>
                    <label className="checkbox coloured" key={data._id}>
                      <input type="checkbox"  key={data._id} checked={data.checked } onChange={e => handleChangeEditCheckbox(e, data)} style={{paddingLeft:"5px"}} value={data.name}/>
					  <span className="checkbox-material">
						<span className="check"></span>
					  </span>  {data.name}
                    </label>

                    
                    )
                })}
              
              </div>
              {noDepartmentwhileEditing && <p style={{marginTop:'9px',color:'red',marginBottom:'0px'}}>Select atleast one Department!</p>}
              </div>
            </div>
            {editUserConfirmLoader ? <div className={"pull-right"}> <img src={"/img/loaders/spinningwheel.gif"} height={50} width={70} alt="loader" /></div> :<button type="submit" className="nj-flow-button pull-right" style={{margin:5}}>Submit</button>}
            {hideEditUserCancelButton ? <div><button type="button" onClick={hideEditPopUp} className="nj-flow-button bg-color-red pull-right" style={{margin:5}}>Cancel</button></div>:<div><button type="button" onClick={hideEditPopUp} className="nj-flow-button bg-color-red pull-right" style={{margin:5}}>Cancel</button></div>}
          </form>
        </div>
      </div>
    </div>
  </div>
  {/* onclick edit button in user table this popup will appear end*/}

  {/* onclick delete button in user table this popup will appear*/}
  <div id="deleteUserPopup" className={"nj-overly add-rebound-animation"}>
  <div className="popup-nj my-popup">
  <header className="popup-header">
  <span>Delete User</span>
  <span onClick={hideDeletePopUp} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
  </header>
  <div className="popup-content">
  <div className="col-md-12">
  <form name="roleForm">
  <div className="nj-popup-form-wrap">
  <p className="popup-content-label font_family_montserrat" style={{fontSize:"16px"}}>Do you really want to delete?</p>
  </div>
  {confirmLoader ? <div className={"pull-right"}> <img src={"/img/loaders/spinningwheel.gif"} height={50} width={70} alt="loader" /></div> :<button type="submit" onClick={(e)=>deleteRequestedContent(e,userData)} className="nj-flow-button pull-right" style={{margin:5}}> Confirm</button>}
  {hideCancelButton ? <div><button type="button" onClick={hideDeletePopUp} className="nj-flow-button bg-color-red pull-right" style={{margin:5}}>Cancel</button></div>:<div><button type="button" onClick={hideDeletePopUp} disabled={disabled} className="nj-flow-button bg-color-red pull-right" style={{margin:5}}>Cancel</button></div>}
  </form>
  </div>
  </div>
  </div>
  </div>
  {/* onclick delete button in user table this popup will appear end*/}

</div>

  :

  <div className="row">
  <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
  <div id="errorSnackbar">{deleteSnackbarMessage}</div>

    <div className="col-md-12 col-sm-12 nj-card">

    <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
      <div className="nj-card__header">
        <div className="nj-card__title">
          <h4 className="font_family_montserrat">Created Departments</h4>
        </div>
      </div>
    </div>

    <div className="col-md-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
    {showLoader ?
    <div className="course-table-margin-top">
    {departmentArray.length>0?
      <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"70.3vh"}}>
        <table className="nj-table nj-table--tbody-table font_family_montserrat">
          <thead className="nj-table--thead">
            <tr>
              <th>
                <div className="nj-table-text">SL NO.</div>
              </th>
              <th>
                <div className="nj-table-text">Departments</div>
              </th>
              <th style={{textAlign:"center"}}>
                <div className="nj-table-text">ACTIONS</div>
              </th>
            </tr>
          </thead>
          <tbody className="sub_admin_form_row_tbody">
          {departmentArray.map((data,i) => {
            return(
              <tr key={i} className="tr-values nj-table--tr tr-table-state-null ">
                <td className="sub_admin--td">{i+1}</td>
                <td className="sub_admin--td" style={{textTransform: 'capitalize'}}>{data.name}</td>
                <td className="sub_admin--td" style={{textAlign:"center",minWidth: '210px'}}>
                  <button type="button" onClick={()=>getConfirmDeletePopupForDepartment(data)} className="nj-flow-button bg-color-red addRoles_button" style={{float:"none",marginTop:"5px",marginLeft:"5px"}}>Delete</button>
                </td>
              </tr>
            )
            })}
          </tbody>
        </table>
      </div>
    :
    <div className="col-md-12 idams_noDataFound" style={{height:"70.3vh"}}>
    <span className="font_family_montserrat">No data found</span>
    </div>
    }
    </div> :
    <div className="row" style={{height:"70.3vh"}}>
    <div className="col-md-12 aligh-loader-image-center" >
    <img src={"/img/loaders/spinningwheel.gif"} height={80} width={80} alt="loader" />
    </div>
    </div>
    }
    </div>

    </div>
    </div>


    {/* creating a departments form start*/}
      <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12" style={{marginBottom: 10}}>
      <div id="successSnackbar">{successSnackbarMessage}</div>
      <div id="errorSnackbar">{errorSnackbarMessage}</div>
        <div className="col-md-12 col-sm-12 nj-card">
          <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>

            <div className="nj-card__header">
              <div className="nj-card__title">
                <h4 className="font_family_montserrat">Create Department</h4>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sub_admin_form" style={{marginTop:'10px'}}>
              <form onSubmit={saveDepartment}>
                <div className="row sub_admin_form_row" style={{marginTop:'0px',marginBottom:'40px'}}>
                  <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <label className="idams-label pull-left">Department Name</label>
                    <input type="text" name="department" value={department} onChange={handleChangeCreateDepartment} className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="ex : computer science" autoComplete="off" required />
                    {departmentAlreadyExists && <p style={{color: 'red',paddingTop: 5}}>Department name already exists.</p>}
                    {departmentNameValidation && <p style={{color: 'red',paddingTop: 5}}>Department name should contain alphabets, numbers, spaces, hyphens</p>}
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12" style={{marginBottom:"10px"}}>
                    {departmentSubmitLoader ?<div style={{marginTop:'10px',height:'30px',width:"30px"}}><img src={"/img/loaders/spinningwheel.gif"} height={65} width={65} alt="loader" /></div>: <button type="submit" className="nj-flow-button pull-right" disabled={departmentNameValidation} style={{marginTop:'25px'}}>Submit</button>}
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    {/* creating a departments form ends*/}

    {/* onclick delete button in department table this popup will appear*/}
    <div id="deleteDepartmentPopup" className={"nj-overly add-rebound-animation"}>
    <div className="popup-nj my-popup">
    <header className="popup-header">
    <span>Delete User</span>
    <span onClick={hideDepartmentDeletePopUp} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
    </header>
    <div className="popup-content">
    <div className="col-md-12">
    <form name="roleForm">
    <div className="nj-popup-form-wrap">
    <p className="popup-content-label font_family_montserrat" style={{fontSize:"16px"}}>Do you really want to delete?</p>
    </div>
    {confirmLoader ? <div className={"pull-right"}> <img src={"/img/loaders/spinningwheel.gif"} height={50} width={70} alt="loader" /></div> :<button type="submit" onClick={(e)=>deleteDepartmentContent(e,departmentData)} className="nj-flow-button pull-right" style={{margin:5}}> Confirm</button>}
    {hideCancelButton ? <div><button type="button" onClick={hideDepartmentDeletePopUp} className="nj-flow-button bg-color-red pull-right" style={{margin:5}}>Cancel</button></div>:<div><button type="button" onClick={hideDepartmentDeletePopUp} disabled={disabled} className="nj-flow-button bg-color-red pull-right" style={{margin:5}}>Cancel</button></div>}
    </form>
    </div>
    </div>
    </div>
    </div>
    {/* onclick delete button in department table this popup will appear end*/}

  </div>
}

</div>

);
}

export default Subadmins;
