
import * as SharedResourceActionTypes from "../../../actionTypes";

const initialState = {
  sharedResources:[],
  initialLoader:true,
  recentlySharedResources:[],
  resourceVideo:{}

}

export default function(state=initialState, action) {
  switch (action.type) {
    case SharedResourceActionTypes.SHARED_RESOURCE_ASSIGN_VALUE: {
      const {name,value} = action.payload; 
      return {
        ...state,[name]:value
      }
    }
    default:
      return state;
  }
}
