
import * as UserActionTypes from "../../../actionTypes";

const initialState = {
  allApplications:[],
  showSelectedApplications:true,
  showSelectedResources:true,
  selectedApplications:[],
  selectedResources:[],
  popupArray:[],
  courseName:"",
  displayName:"",
  fromDate:"",
  toDate:"",
  availableCourseLoader:false,
  availableCourses:[],
  shouldPopupAnimate:false,
  selectedCourse:{},
  disableRoleDelete:true,
  showUserList:true,
  userCreate_loader:true,
  // selectCourse:'',
  availableUsers:[],
  allCourses:[],
  successSnackbarMessage:"",
  errorSnackbarMessage:"",
  selectedCourseUsers:[],
  totalUsrerList:[],
  usernameMustbeAlphnumeric:false,
  userNameExist:false,
  userEmailExist:false,
  dupTotalUsrerList:[],
  editShowSelectedCourses:true,
  selectedUser:{},
  originalSelectedUser:{},
  EditUserEmailExist:false,
  showUserUpdateLoader:false,
  AvailableAllCourses:[],
  searchByCourses:[],
  enteredDomainName:"",
  searchUsername:"",
  openUpload:false,
  userUpload_loader:true,
 
 }

export default function(state=initialState, action) {
  switch (action.type) {
    case UserActionTypes.COURSE_GET_ALL_APPLICATION: {
      const {content } = action.payload; 
      return {
        ...state,allApplications:content
      }
    }
    case UserActionTypes.COURSE_SET_BOOLEAN_VALUE: {
      const {name,value} = action.payload; 
      return {
        ...state,[name]:value
      }
    }
    case UserActionTypes.COURSE_SELECT_APPLICATION: {
      const {apps} = action.payload; 
      return {
        ...state,allApplications:apps
      }
    }
    case UserActionTypes.COURSE_DELETE_SELECTED_APPLICATION: {
      const {apps} = action.payload; 
      return {
        ...state,selectedApplications:apps
      }
    }
    case UserActionTypes.COURSE_SAVE_SELECTED_APPLICATIONS: {
      const {apps} = action.payload; 
      return {
        ...state,selectedApplications:apps
      }
    }

    case UserActionTypes.COURSE_SELECT_RESOURCE: {
      const {resource} = action.payload; 
      return {
        ...state,allApplications:resource
      }
    }
    case UserActionTypes.COURSE_DELETE_SELECTED_RESOURCE: {
      const {resource} = action.payload; 
      return {
        ...state,selectedResources:resource
      }
    }
    case UserActionTypes.COURSE_SAVE_SELECTED_RESOURCES: {
      const {resource} = action.payload; 
      return {
        ...state,selectedResources:resource
      }
    }
    case UserActionTypes.COURSE_SAVE_COURSE_CREATION_INPUT_FIELDS: {
      const {name,value} = action.payload; 
      return {
        ...state,[name]:value
      }
    }
    case UserActionTypes.COURSE_GET_CREATED_COURSES: {
      const {content } = action.payload; 
      return {
        ...state,availableCourses:content
      }
    }
    case UserActionTypes.COURSE_GET_SELECTED_COURSEOBJECT: {
      const {content } = action.payload; 
      return {
        ...state,selectedCourse:content
      }
    }
    case UserActionTypes.CREATE_UPDATE: {
      const {content } = action.payload; 
      return {
        ...state,popupArray:content
      }
    }
    case UserActionTypes.GET_ALL_COURSES: {
      const {content } = action.payload; 
      // console.log("ssgss",content)
      return {
        ...state,allCourses:content,searchByCourses:content,
      }
    }

    case UserActionTypes.GET_SELECTED_USER_COURSES: {
      const {content } = action.payload; 
      // console.log("",content)
      return {
        ...state,totalUsrerList:content,dupTotalUsrerList:content
      }
    }
    case UserActionTypes.SEARCH_USERS_NAME: {
      const {searchRequestedArray} = action.payload; 
      return {
        ...state,totalUsrerList:searchRequestedArray
      }
    }
    case UserActionTypes.USER_GET_SEARCH_COURSES: {
      const {content } = action.payload; 
      return {
        ...state,searchByCourses:content
      }
    }

    case UserActionTypes.USER_SETSEARCHUSERNAME: {
      const {content } = action.payload; 
     
      return {
        ...state,searchUsername:content
      }
    }
    
    default:
      return state;
  }
}