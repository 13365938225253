
import * as SettingsActionTypes from "../../../actionTypes";

const initialState = {
    availableSubscriptionLoader : false,
    shouldPopupAnimate:true,
    showConcurrentUserUpdateLoader:true,
    showEditButton:true,
    editOpen:false,
    subscriptionList:[],
    concurrentUserList:[],
    successSnackbarMessage:"",
    errorSnackbarMessage:"",
    fromDate:"",
    toDate:"",
    applicationLimit:"",
    selectedapplicationData:"",
    slots:"",

}

export default function(state=initialState, action) {
  switch (action.type) {
    case SettingsActionTypes.SETTINGS_GET_ALL_SUBSCRIPTIONS: {
      const {content } = action.payload; 
      return {
        ...state,subscriptionList:content
      }
    }
   
    case SettingsActionTypes.SETTINGS_GET_ALL_CONCURRENT_USERS: {
        const {content } = action.payload; 
        return {
          ...state,concurrentUserList:content
        }
      }

      case SettingsActionTypes.SETTINGS_SET_BOOLEAN_VALUE: {
        const {name,value} = action.payload; 
        return {
          ...state,[name]:value
        }
      }

      case SettingsActionTypes.SETTINGS_SET_ARRAY_TO_VARIABLE: {
        const {name,content } = action.payload; 
        return {
          ...state,[name]:content
        }
      }

    default:
      return state;
  }
}
