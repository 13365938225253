
//import { TransitionGroup, CSSTransition } from "react-transition-group";
import React, { Component } from "react";
import "../App.css";
import { BrowserRouter as Router, Route,Switch} from "react-router-dom";
import Sidebar from "react-sidebar";
import SidebarContent from './sharedSidebar';
import SharedResources from "../presentational/sharedResources";
import viewPlaylist from "../presentational/ViewPlaylist";
const mql = window.matchMedia(`(min-width: 800px)`);


class sharedResourceDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: false,
      isSidebarOpen: true,
      openLogout:false,
      showBot:false,
      userData:{},
      translateDropdown:false
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
    this.onSetSidebarOpen(mql.matches)
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
    // console.log("url")
  }
 
  componentDidMount=()=>{
    var userData=JSON.parse(sessionStorage.getItem("userData"));
    this.setState({userData:userData})
    // window.googleTranslateElementInit =this.googleTranslateElementInit()
//   }
//   googleTranslateElementInit=()=> {
//       new window.google.translate.TranslateElement({pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT}, 'google_translate_element')  
//     /* eslint-disable no-new */
   }
  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open});
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }
  toggleSidebar = () =>{
    var open = this.state.sidebarOpen
    if(mql.matches){
      this.setState({ sidebarOpen: !open,sidebarDocked:!this.state.sidebarDocked,isSidebarOpen:!open },()=>{
        // console.log("sidebar state",this.state.sidebarOpen)
      });
    } else {
      this.setState({ sidebarOpen: !open,isSidebarOpen:false},()=>{
        // console.log("sidebar state",this.state.sidebarOpen)
      });
    }

  }
  triggerRolesPage=()=>{
    sessionStorage.setItem("fromAppStore",false);
  }
  onLogout=(data)=>{
    this.setState({openLogout:data})
      // if(this.state.mouseOverLogout){
      //   //console.log('lul')
      //   this.setState({openLogout:data})
      // }
      // else{
      //   this.setState({openLogout:data})
      // }
  }

  backToProfile = () =>{
    // console.log("back to profile")
    //this.props.history.push('/edit-profile')
    this.setState({openLogout:false})
    this.setState({mouseOverLogout:false})
  }
  logOut = () =>{
    this.props.history.push('/')
    sessionStorage.removeItem("userData");
    this.setState({openLogout:false})
    this.setState({mouseOverLogout:false})
  }

   //  gargi code starts
   messageIcon=()=>{
    var gargiWindow = document.getElementById("gargiWindow");
    var gargiChatArea = document.getElementById("gargiChatArea");
       if (gargiWindow.classList.contains('slide-down-fade-out')) {
           gargiWindow.classList.remove("slide-down-fade-out");
       }
       gargiChatArea.classList.add("expand-up");
       gargiWindow.classList.add("slide-up-fade-in");
       this.setState({showBot:true})
   }

   hideBot =()=>{
    var gargiWindow = document.getElementById("gargiWindow");
       gargiWindow.classList.remove("slide-up-fade-in");
       gargiWindow.classList.add("slide-down-fade-out");
       this.setState({showBot:false})
   }

  //  gargi code end

  render() {
    return (
      <Router>
        <Sidebar sidebar={<SidebarContent />} open={this.state.sidebarOpen} docked={this.state.sidebarDocked} onSetOpen={this.onSetSidebarOpen} styles={{ sidebar: { background: "white" } }} shadow={false} defaultSidebarWidth={230}>
          <div className="col-md-12">
            <header className={"row vx-navbar " + (this.state.isSidebarOpen ? "" : "vx-navbar-without-sidebar")}>
              <div className="vs-con-items">
                <span className="sm:inline-flex xl:hidden cursor-pointer mr-1 feather-icon select-none relative" onClick={this.toggleSidebar}>
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu">
                    <line x1={3} y1={12} x2={21} y2={12} />
                    <line x1={3} y1={6} x2={21} y2={6} />
                    <line x1={3} y1={18} x2={21} y2={18} />
                  </svg>
                </span>
                {/* <NavLink to="/dashboard">
                  <span className="sm:inline-flex xl:hidden cursor-pointer mr-1 feather-icon select-none relative purple-feather-icon" tooltip="Business Info" flow="down" style={{ paddingLeft: "15px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-edit "
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                    </svg>
                  </span>
                </NavLink>
                <NavLink to="/dashboard">
                  <span className="sm:inline-flex xl:hidden cursor-pointer mr-1 feather-icon select-none relative purple-feather-icon" tooltip="Locations" flow="down">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-map-pin"
                    >
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                      <circle cx={12} cy={10} r={3} />
                    </svg>
                  </span>
                </NavLink>
                <NavLink to="/dashboard">
                  <span className="sm:inline-flex xl:hidden cursor-pointer mr-1 feather-icon select-none relative purple-feather-icon" tooltip="App Store" flow="down">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-copy"
                    >
                      <rect x={9} y={9} width={13} height={13} rx={2} ry={2} />
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                    </svg>
                  </span>
                </NavLink>
                <NavLink to="/dashboard">
                  <span className="sm:inline-flex xl:hidden cursor-pointer mr-1 feather-icon select-none relative purple-feather-icon" tooltip="Roles" flow="down">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-layout"
                      onClick={this.triggerRolesPage}
                    >
                      <rect x="3" y="3" width="18" height="18" rx="2" />
                      <line x1="3" y1="9" x2="21" y2="9" />
                      <line x1="9" y1="21" x2="9" y2="9" />
                    </svg>
                  </span>
                </NavLink>
                <NavLink to="/dashboard">
                  <span className="sm:inline-flex xl:hidden cursor-pointer mr-1 feather-icon select-none relative purple-feather-icon" tooltip="Users" flow="down">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-user-plus"
                    >
                      <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                      <circle cx="8.5" cy={7} r={4} />
                      <line x1={20} y1={8} x2={20} y2={14} />
                      <line x1={23} y1={11} x2={17} y2={11} />
                    </svg>
                  </span>
                </NavLink>*/}            
              </div> 
              <div className=" navbar-avatar visible-xs pull-right">
                <a href="/" onClick={()=>{this.logOut()}}>
                  <span className="sm:inline-flex xl:hidden cursor-pointer mr-1 feather-icon select-none relative purple-feather-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out">
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                      <polyline points="16 17 21 12 16 7"></polyline>
                      <line x1="21" y1="12" x2="9" y2="12"></line>
                    </svg>
                  </span>
                </a>
              </div>
              {/* <div className=" navbar-avatar visible-xs pull-right">
                <a onClick={()=>{this.setState({translateDropdown:!this.state.translateDropdown})}}>
                  <span className="sm:inline-flex xl:hidden cursor-pointer mr-1 feather-icon select-none relative purple-feather-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out">
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                      <polyline points="16 17 21 12 16 7"></polyline>
                      <line x1="21" y1="12" x2="9" y2="12"></line>
                    </svg>
                  </span>
                </a>
              </div> */}
              {/* <div className="dropdown hidden-sm hidden--md col-xs-12" style={this.state.translateDropdown ? {display:"block"}:{display:"none"}}>
                <div className="dropdown-content row">
                <div className="translate-div" id="google_translate_element"></div> 
                </div>
              </div> */}
              
              <div className="navbar-avatar" onMouseOver={()=>this.onLogout(true)} onMouseLeave={()=>this.onLogout(false)}>
                <div tabIndex="100" className="" style={{ display: "flex", alignItems: "center",cursor:"pointer",outline:"none"}}
                >                  
                  <span className="profile-email">{this.state.userData.email}</span>
                  
                  <img src="/img/profile-icon.png" alt="user-img" width={40} height={40} className="rounded-full shadow-md cursor-pointer block" />
                </div>
                {this.state.openLogout===true ?
              <div className="con-vs-dropdown--menu fadeIn" style={{right:'-122px'}} /*style={{left: '1304.69px' ,top:'-228.625px'}}*/>
                <div className="vs-dropdown--menu--after"></div>
                <div className="vs-dropdown--custom vs-dropdown--menu"
                onMouseOver={()=>{this.setState({mouseOverLogout:true})}}
                onMouseOut={()=>{this.setState({mouseOverLogout:false})}}
                >
                  <ul style={{minWidth: '8rem',marginLeft:'-40px',maxHeight:"20px"}}>
                  {/* <NavLink to="#" style={{textDecoration:"none"}}>
                    <li className="flex py-2 px-4 cursor-pointer onHoverBG text-color-black">
                      <span className="feather-icon select-none relative " style={{color:'inherit'}}>
                        <i className="fa fa-user" style={{margin:'10px',fontSize:'14px',color:'inherit'}} ></i>
                          <span style={{fontSize:'14px',fontFamily:'Montserrat',color:'inherit'}}>Profile</span>
                      </span>
                    </li>
                    </NavLink> */}
                    {/* <div className="vs-component vs-divider m-1">
                      <span className="vs-divider-border after" style={{width: '100%',borderTop:' 1px solid rgba(0, 0, 0, 0.1)'}}></span> */}
                      {/*<span className="vs-divider-border before" style={{width: '100%',borderTop:' 1px solid rgba(0, 0, 0, 0.1)'}}></span>*/}
                    {/* </div> */}
                    <li className="flex py-2 px-4 cursor-pointer onHoverBG" onClick={()=>{this.logOut()}}>
                      <span className="feather-icon select-none relative" style={{color:'inherit'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out">
                         <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                         <polyline points="16 17 21 12 16 7"></polyline>
                         <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>
                        {/* <i className="fa fa-sign-out" style={{margin:'10px',fontSize:'14px',color:'inherit'}}></i> */}
                          <span   style={{fontSize:'14px',fontFamily:'Montserrat',color:'inherit',paddingLeft:5}}>Logout</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              :null
              }
              </div>
              <div id="google_translate_element" className="hidden-sm hidden-xs"></div>
              </header>
          </div>

          <div className="wrap-content">
            <Route
              render={({ location }) => {
                return (
                    /*<TransitionGroup component={null}>
                      <CSSTransition timeout={500} classNames="page" key={location.key}>*/
                        <Switch>
                          <Route exact path="/shared/resources" component={SharedResources} /> 
                          <Route exact path="/shared/view-playlist" component={viewPlaylist} />                      
                        </Switch>
                     /* </CSSTransition>
                    </TransitionGroup>*/
                );
              }}
            />

          {/* gargi code starts here */}
          {/* <div className="messageIcon">
            <div  style={{outline: 'none', padding: 6}} onClick={this.messageIcon}>
              <div className="circle-green" />
              <div className="gargi-img" style={{width: 54, height: 54}} />
              {/*<img ng-src="./img/message2.png" class="img-circle" height="60" width="60" ng-click="messageIcon()">*/}
            {/* </div>  */}
            {/* -bot */}
            {/* <div className="chatbox chatbox--empty" id="gargiWindow" style={(this.state.showBot ? {position: 'fixed', bottom: 0, right: 0, zIndex: 9999,display:"block"}:{display:"none"})}>
              <div className="chatbox__title" id="botWidth" style={{position: 'fixed', zIndex: 9999}}>
                <h5 style={{textAlign: 'left !important'}}><a href="# " onClick={this.hideBot}>Gargi</a></h5>
                <button className="chatbox__title__tray" style={{marginTop: '-10px'}}>
                </button>
                <button className="chatbox__title__close" onClick={this.hideBot}>
                  <span>
                    <svg viewBox="0 0 12 12" width="12px" height="12px">
                      <line stroke="#FFFFFF" x1="11.75" y1="0.25" x2="0.25" y2="11.75" />
                      <line stroke="#FFFFFF" x1="11.75" y1="11.75" x2="0.25" y2="0.25" />
                    </svg>
                  </span>
                </button>
              </div>
              <div className="chatbox__body chatbox__credentials gargi-box-shadow" style={{marginBottom: '-10px', backgroundColor: 'white'}}>
                <embed className="frame abcd" name="content" src="https://webchat.botframework.com/embed/gargi?s=qlyy7W4Apf8.cwA.y-4.IIcNDu6s5EGM4iQ9hIGH5IUTGihJMxFyBr0p852INBU" id="gargiChatArea" />
              </div>
            </div> */}
            {/*<div class="" style="position:fixed;bottom:0;right:0;z-index: 99999;" ng-hide="hide">
            <a href="" ng-click="showBot=true;hide=true;a=false" style="text-decoration:none;color:white">
              <div class="chatbox__title">
              <h5 style="text-align: left !important;">Gargi</h5>
              <button  class="chatbox__title__tray" ng-click="showBot=true;hide=true;a=false"> 
                <!--<span></span>--
                </button>           
                  </div>
                </a>
              </div> */}
          {/* </div> */}
           {/* gargi code end here */}

          </div>
        </Sidebar>
      </Router>
    );
  }
}

export default sharedResourceDashboard;
