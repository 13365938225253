import * as adminSlotBookingActionTypes from "../../../actionTypes";
const initialState = {
  applications:[],
  concurrentUserLimit:0,
  errorAlertMessage:'',
  selectedApplications:[],
  selectedUserList:[],
  successAlertMessage:'',
  totalUsers:[],
  userList:[],
  userListLoader:false,
}

export default function(state=initialState, action) { 
  switch (action.type) {
    case adminSlotBookingActionTypes.ADMIN_SLOT_BOOKING_COMPONENT_ELEMENTS: {
      const {name,content } = action.payload; 
      return {
        ...state,[name]:content
      }
    }
    default:
      return state;
  }
}