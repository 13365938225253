import * as accessLabActionTypes from "../../../actionTypes";
const initialState = {
  accessLabLoader:false,
  accessLabSlotsLoader:false,
  bookedSlots:[],
  errorAlertMessage:'',
  popupLoader:false,
  ratingValue:'',
  reviewValue:'',
  selectedAccessLabSlot:{},
  selectedSlot:{},
  selectedSlotToCancel:{},
  successAlertMessage:'',
  userDomainName:'',
  webRdpButtonLoader:false,
  webcamCapturedImage:{},
  showWebCam:true,
  webcamVideoLoader:false,
  webCamStart:true,
  bookedSlotId:{}

}

export default function(state=initialState, action) { 
  switch (action.type) {
    case accessLabActionTypes.ACCESS_LAB_COMPONENT_ELEMENTS: {
      const {name,content } = action.payload; 
      return {
        ...state,[name]:content
      }
    }
    default:
      return state;
  }
}