
import * as requestResourceActions from "../../../actionTypes";
const initialState = {
  resourceObject:{description:'',discipline:'',contentType:''},
  showResourceRequestSubmitLoader: false,
  successAlertMessage:'',
  errorAlertMessage:''
}

export default function(state=initialState, action) { 
  switch (action.type) {
    case requestResourceActions.REQUEST_RESOURCE_RESOURCE_OBJECT: {
      const {content } = action.payload; 
      return {
        ...state,resourceObject:content
      }
    }
    case requestResourceActions.REQUEST_RESOURCE_COMPONENT_ELEMENTS: {
      const {name,content } = action.payload; 
      return {
        ...state,[name]:content
      }
    }
    default:
      return state;
  }
}