
import * as SharedResourceActions from "./index";
import customHttp from '../../../packages/CustomHttp/';
// import snackbar from '../../../packages/Snackbar/';

function uniqueApps(array) {
   var processed = [];
   for (var i=array.length-1; i>=0; i--){
   if (processed.indexOf(array[i].resourceId[0].name)<0) {
      processed.push(array[i].resourceId[0].name);
   } else {
      array.splice(i, 1);
   }
   }
   return array;
}

export  function getSharedResourcesById (){  
   return async (dispatch ,getState)=> { 
      try {
         var resourceId=localStorage.getItem("sharedResourceId");
         // var userDetails=JSON.parse(sessionStorage.getItem("smeUser"))        
         dispatch(SharedResourceActions.AssignValue("initialLoader",true));
         
         let response = await customHttp.get("/api/sharedResource/resourceId/"+resourceId);
         if(response.data.message && Array.isArray(response.data.message)){
            var uniqueResources= uniqueApps(response.data.message);
            // console.log("resourcessssss Id",uniqueResources);
            dispatch(SharedResourceActions.AssignValue("sharedResources",uniqueResources));
         }else{ 
            dispatch(SharedResourceActions.AssignValue("sharedResources",[]));      
         }
         dispatch(SharedResourceActions.AssignValue("initialLoader",false));           
         } catch (err) {
            dispatch(SharedResourceActions.AssignValue("sharedResources",[]));
            dispatch(SharedResourceActions.AssignValue("initialLoader",false));
            console.log("error",err)
         }
   }
}

export  function  getSharedResources(){ 
    
   return async (dispatch ,getState)=> { 
      try {
         var userDetails=JSON.parse(sessionStorage.getItem("smeUser"))        
         dispatch(SharedResourceActions.AssignValue("initialLoader",true));
         let response = await customHttp.get("/api/sharedResource/email/"+userDetails.email);
         if(response.data.message && Array.isArray(response.data.message)){
            var uniqueResources= uniqueApps(response.data.message);
            var resourceId=localStorage.getItem("sharedResourceId");
            // console.log("resourcessssss all",uniqueResources,resourceId);
            dispatch(SharedResourceActions.AssignValue("recentlySharedResources",uniqueResources));
         }else{ 
            dispatch(SharedResourceActions.AssignValue("recentlySharedResources",[]));          
         }
         dispatch(SharedResourceActions.AssignValue("initialLoader",false));           
         } catch (err) {
            dispatch(SharedResourceActions.AssignValue("recentlySharedResources",[]));
            dispatch(SharedResourceActions.AssignValue("initialLoader",false));
            console.log("error",err)
         }
   }
}

export  function initiateOnloadFunction (){  
   return async (dispatch ,getState)=> {
      // console.log("onload triggerd")
      dispatch(getSharedResources()) 
      dispatch(getSharedResourcesById()) 
   }
}
export function openResourceLink(item,window,history) {
   return async (dispatch ,getState)=> {
      // console.log("item  details",item)
         if(item.type===2){
            dispatch(SharedResourceActions.AssignValue('resourceVideo',item))          
            document.getElementById('playResourceVideoPopup').style.display='block';
         } else if(item.type===0 && item.file && item.file.length>0) {
            const itemLink = '/'+item.file[0].path || '/'
            window.open(itemLink,'_blank')
         } else if(item.type===4){
            localStorage.setItem('playlistResource',JSON.stringify(item))
               history.push('/shared/view-playlist');
         }

   }
}

export function closeVideoPopup(e,props) {
   return async (dispatch ,getState)=> {
      var x = document.getElementById("playVideo");
      x.pause()
      dispatch(SharedResourceActions.AssignValue('resourceVideo',{}));
      document.getElementById('playResourceVideoPopup').style.display='none'   
   }
}

  
  

