import React, {useEffect,useState} from 'react';
import { connect } from "react-redux";
import courseAnalyticsContainer from "../../container/actions/CourseAnalytics/action";
import { bindActionCreators } from 'redux';
import UserBookedVsUsedSlots from './UserBookedVsUsedSlots/UserBookedVsUsedSlots';
import CustomStorage from '../../packages/CustomStorage/';

function UserUsage (props) {
  const [selectedType, setSelectedType] = useState('demo');
  
  useEffect( () => { 
    const selectedDemoOrActual =CustomStorage.localStorageGet('selectedDemoOrActual') 
    if(selectedDemoOrActual !== null){
      setSelectedType(selectedDemoOrActual)
    }
    // console.log("hello",props) 
  },[]);
  // onload function end

  // const handleChange=(e)=>{
  //   setSelectedType(e.target.value);
  //   getAllComponents();

  // }
 
  const goToCourseAnalytics=()=>{
    const selectedDemoOrActual =CustomStorage.localStorageGet('selectedDemoOrActual') 
    CustomStorage.localStorageSet('backFromUserAnalytics',{selectedType:selectedDemoOrActual,back:true})
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    if(userDetails.type===1){
      props.history.push("/course-analytics")
    }else if(userDetails.type===2){
      props.history.push("/dlt/course-analytics")
    }else{
      props.history.push("/sub-admin/course-analytics")
    }
  }
  var getAllComponents=()=>{
      return(
        <div>
          <UserBookedVsUsedSlots  demoAndActualValue={selectedType} key={selectedType} props={props}/>
        </div>
      )
    }
  return (
    <div className="add-on-load-animation">
      {/* <div className="analytics-data-dropdown-button">
        <div className="demo-mode-div">
          <div className="demo-mode-value">{selectedType}</div>
          {/* <select value={selectedType}>
            <option value="demo">Demo</option>
            <option value="actual">Actual</option>
            <svg className="c-input__icon" viewBox="0 0 20 20" width={20} height={20}>
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
            </svg>
          </select> */}
        {/* </div>
      </div> */} 
      {/* <div className="col-md-12 col-sm-12" style={{paddingLeft:'0px',paddingRight:'0px',paddingBottom: 15}}>
        <div className="nj-card col-md-12">
        <div className="nj-card__header">
          <div className="nj-card__title">
            <h4>User Details</h4>
          </div>
          <button type="button" className="nj-flow-button" style={{marginTop:"-14px"}} onClick={goToCourseAnalytics}>Back</button>
        </div>
      </div>
    </div>       */}
    <div>
      {getAllComponents()}
    </div>
  </div>

    );
  }


const mapStateToProps = state => ({
  courseAnalyticsState: state.courseAnalyticsdState
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(UserUsage);
