
import * as ProfileActionTypes from "../../../actionTypes";

const initialState = {
  profile:{},
  disable:true,
  disableDomain:true,
  disableOrgName:true,
  formSubmitted:false,
  successSnackbarMessage:"",
  errorSnackbarMessage:"",
  orgValidation:false,
  orgNameExist:false,
  domainNameValidation:false,
  domainNameExist:false


}

export default function(state=initialState, action) {
  switch (action.type) {
    case ProfileActionTypes.PROFILE_SAVE_COMPONENT_ELEMENTS: {
      const {name,value} = action.payload; 
      return {
        ...state,[name]:value
      }   
    }
    default:
      return state;
  }
}