import React, { useEffect} from 'react';
import { connect } from "react-redux";
import * as DashboardContainer from "../../../container/actions/Dashboard/action";
import ReactApexChart from 'react-apexcharts';
// import Store from "../../container/reducers/Store";
import "./BookedVsUsedSlots.scss";
import { bindActionCreators } from 'redux';


function BookedVsUsedSlots (props) {
  
  useEffect( () => {
},[]);
  // onload function end
    return (
    <div className="add-on-load-animation">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
            <div className="col-md-12 col-sm-12 nj-card" >
              {!props.dashboardState.initialLoaderForBookedAndUsedSlots ?
                <div>
                  <div className="row" style={{textAlign:"left",marginTop:"18px"}}>
                    <div className="col-md-6">
                      <div className="nj-card__title" style={{marginLeft:"7px"}}>
                        <h4 className="font_family_montserrat" style={{fontWeight:600}}>Slot Usage</h4>
                      </div>                 
                    </div>
                    <div className="col-md-6">
                      <div className="pull-right">
                        <span className="font_family_montserrat slot-last-seven-days" style={{boxShadow: 'rgb(217, 214, 243) 0px 4px 12px 0px'}}>Last 7 days</span>
                      </div>                 
                    </div>
                  </div>
                    {Object.keys(props.dashboardState.bookedAndUsedSlots).length>0 ?
                    <div>
                      <div className="row" style={{textAlign:"left"}}>
                        <div className="col-md-12 col-sm-12 slot-heading-div">
                          <span className="slot-heading-div_span">
                            <span className="font_family_montserrat" >Total Booked</span><br></br>
                            <span className="font_family_montserrat slot-value" style={{color:"rgb(13, 138, 255)"}}>{props.dashboardState.bookedAndUsedSlots.totalBookCount}</span>
                          </span> 
                          <span className="slot-heading-span">
                            <span className="font_family_montserrat">Total Used</span><br></br>
                            <span className="font_family_montserrat slot-value" style={{color:"rgb(10, 223, 171)"}}>{props.dashboardState.bookedAndUsedSlots.totalUsedCount}</span>
                          </span>                 
                        </div>
                      </div>
                      <div style={{margin:"0px 10px"}}>
                        <div id="chart">
                          <div id="chart-timeline" className="icon-zindex">
                            <ReactApexChart options={props.dashboardState.bookedAndUsedSlotOptions} series={props.dashboardState.bookedAndUsedSlotSeries}  type='area' height={350} />
                          </div>
                        </div>
                      </div>
                    </div>
                  :
                    <div className="col-md-12 font_family_montserrat idams_noDataFound" style={{height:"58vh"}}>No data found</div>
                  }
                </div> 
              :
                <div className="row" style={{height:"58vh"}}>
                  <div className="col-md-12 aligh-bookedslot-loader-image-center" >
                    <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                  </div>
                </div>
              }            
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }


const mapStateToProps = state => ({
  dashboardState: state.dashboardState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  initialFunction:DashboardContainer.initialFunction
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(BookedVsUsedSlots);
