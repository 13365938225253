import * as createPlaylistActionTypes from "../../../actionTypes";

const initialState = {
  playlistName:'',
  publishedVersion:'',
  timeInvestment:"",
  successAlertMessage:'',
  errorAlertMessage:'',
  playlistArray:[],
  disciplines: [],
  contentTypes: [],
  selectedDiscipline:{},
  selectedContentType:{},
  showSubmitLoader:false,
  selectedAddResourceIndex:"",
  selectedPlaylistIndex:"",
}

export default function(state=initialState, action) { 
  switch (action.type) {
    case createPlaylistActionTypes.CREATE_PLAYLIST_COMPONENT_ELEMENTS: {
      const {name,content } = action.payload; 
      // console.log("version",action.payload)
      return {
        ...state,[name]:content
      }
    }
    case createPlaylistActionTypes.CREATE_PLAYLIST_DISCIPLINES: {
      const {content } = action.payload; 
      return {
        ...state,disciplines:content
      }
    }
    case createPlaylistActionTypes.CREATE_PLAYLIST_CONTENT_TYPES: {
      const {content } = action.payload; 
      return {
        ...state,contentTypes:content
      }
    }
    default:
    return state;
  }
}