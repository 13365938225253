import {compareDesc,isSameDay} from "date-fns";
import * as accessLabActions from "./index";
import customHttp from '../../../packages/CustomHttp/';
import snackbar from '../../../packages/Snackbar/';
import * as faceapi from 'face-api.js'
import customHttpUserside from '../../../packages/CustomHttpUserside/';

export function getBookedSlots() {
   return async (dispatch)=> {
      dispatch(getDomainName());
      var user = JSON.parse(sessionStorage.getItem('userData'));
      dispatch(setAccessLabElements('accessLabSlotsLoader',true));
      try {
         var response = await customHttp.get('/api/user/'+ user._id +'/currentSlots');
         if(response.data.message && response.data.message.length>0){
            var slots = JSON.parse(JSON.stringify(response.data.message));
            var sortSlotsByDate = async() => {
               return slots.sort((a,b)=>{
                  if (compareDesc(new Date(b.date),new Date(a.date))>0){
                     return 1;
                  } else {
                     return -1;
                  }
               })
            }
            var sortedSlots = await sortSlotsByDate();
            var finalSlots = await sortedSlots.sort((a,b)=>{
               if(isSameDay(new Date(b.date),new Date(a.date)) && (a.bookingTime.substring(0,2)<b.bookingTime.substring(0,2))){
                  // console.log("matched",b.date,"sec",a.date)
                  return 1
               } else {
                  return -1
               }
            });
            dispatch(setAccessLabElements('bookedSlots',finalSlots));
            dispatch(setAccessLabElements('accessLabSlotsLoader',false));
         } else {
            dispatch(setAccessLabElements('bookedSlots',[]));
            dispatch(setAccessLabElements('accessLabSlotsLoader',false));
         }
         // console.log("booked slots",response.data.message);
      } catch (err){
         dispatch(setAccessLabElements('accessLabSlotsLoader',false));
         console.log("error",err)
      }
   }
}


export function openCancelPopup(slot) {
   return async (dispatch)=> {
      dispatch(setAccessLabElements('selectedSlot',slot));
      document.getElementById('slotCancelPopup').style.display='block';
   }
}

export function confirmCancel() {
   return async (dispatch,getState)=> {
      var data = {};
      const slot = getState().accessLabState.selectedSlot;
      const user = JSON.parse(sessionStorage.getItem('userData'));
      data.adminId = user.adminId;
      data.username = user.username;
      data.email = user.email;
      data.phoneNumber = user.phoneNumber;
      data.belongsTo = user.belongsTo;
      data.userId = user._id;
      data.date = slot.date;
      data.bookingTime = slot.bookingTime;
      data.subscription = slot.subscription;
      data.jobId = slot.jobId;
      try {
         dispatch(setAccessLabElements('popupLoader',true))
         var response = await customHttp.put(`/api/slot/cancelSlot`,data);
         dispatch(displayAlert('success','Slot cancelled successfully'))
         dispatch(setAccessLabElements('selectedSlot',{}));
         dispatch(setAccessLabElements('popupLoader',false))
         // console.log("cancel slots",response.data.message);
         document.getElementById('slotCancelPopup').style.display='none';
         dispatch(getBookedSlots())
      } catch (err){
         dispatch(displayAlert('error','Error! Try again..'));
         dispatch(setAccessLabElements('popupLoader',false))
         console.log("error",err)
      }
   }
}

export function handleAccessLab(loaderName,window,slot) {
   return async (dispatch,getState)=> {
    console.log("slot",slot)
      dispatch(setAccessLabElements('selectedAccessLabSlot',slot));
      // dispatch(setAccessLabElements('accessLabLoader',true));
      dispatch(setAccessLabElements(loaderName,true));
      var userDomainName=getState().accessLabState.userDomainName;
      const user = JSON.parse(sessionStorage.getItem('userData'));
      var tempWindow =  Math.random();
      // console.log("window",window)
      var popup = window.open("",tempWindow,'width=800,height=600,menubar=0,toolbar=0');
      popup.document.write('<center><i><b>Loading! please wait...</b></i></center>');
      try{
         var response = await customHttp.get('/api/resourceUri/username/'+user.username+'/date/'+slot.date+'/bookingTime/'+slot.bookingTime)
         //popup.location.href = "https://elfcloud.platifi.com/rdweb/webclient/index.html?email="+user.username+"@"+user.domainName+"&username="+user.username;
         popup.location.href = "https://iitbgw.psap.ai/RDWeb/webclient/index.html?email="+user.username+"@"+userDomainName+"&username="+user.username+"&courseId="+slot.courseId+"&time="+slot.bookingTime+"&date="+slot.date;
         //console.log("web rdp password changed",response.data.message)
         dispatch(setAccessLabElements(loaderName,false));
      } catch(error){
         dispatch(setAccessLabElements(loaderName,false));
         console.log(error)
      }
   }
}

export function selectedSlotForRating(slot) {
   return async (dispatch)=> {
      dispatch(setAccessLabElements('selectedSlot',slot));
      document.getElementById('slotRatingPopup').style.display='block';
   }
}

export function getRatings(rating) {
   return async (dispatch)=> {
      // console.log("ratingssssss",rating);
      dispatch(setAccessLabElements('ratingValue',rating))
   }
}

export function getReview(e) {
   return async (dispatch)=> {
      dispatch(setAccessLabElements('reviewValue',e.target.value || ''))
   }
}

export function closeRatings() {
   return async (dispatch)=> {
      document.getElementById('slotRatingPopup').style.display='none';
      dispatch(setAccessLabElements('selectedSlot',{}));
      dispatch(setAccessLabElements('ratingValue',''));
      dispatch(setAccessLabElements('reviewValue',''));
   }
}

export function submitReview() {
   return async (dispatch,getState)=> {
      // console.log("slot selected",getState().accessLabState);
      const {ratingValue,reviewValue,selectedSlot} = getState().accessLabState;
      if(ratingValue){
         dispatch(setAccessLabElements('popupLoader',true))
         try{
            var data = {};
            data.rating = ratingValue;
            data.comment = reviewValue;
            data.adminId= selectedSlot.adminId;
            data.belongsTo= selectedSlot.belongsTo;
            data.courseId= selectedSlot.courseId;
            data.date=selectedSlot.date;
            data.email=selectedSlot.email;
            data.jobId=selectedSlot.jobId;
            data.subscription=selectedSlot.subscription;
            data.time=selectedSlot.bookingTime;
            data.userId=selectedSlot.userId;
            data.username=selectedSlot.username;
            var response = await customHttp.put('/api/starRating/userFeedBack/saveStarRating',data);
            dispatch(setAccessLabElements('popupLoader',false))
            dispatch(displayAlert('success','Rated Successfully'));
            dispatch(closeRatings());
            dispatch(getBookedSlots());
            dispatch(setAccessLabElements('selectedSlot',{}));
            dispatch(setAccessLabElements('ratingValue',''));
            dispatch(setAccessLabElements('reviewValue',''));
         } catch(err){
            dispatch(displayAlert('error','Error! Try again..'));
            dispatch(setAccessLabElements('popupLoader',false))
            console.log("error",err)
         }
      } else {
         dispatch(displayAlert('error','Please rate'));
      }
   }
}

export function displayAlert(type,msg) {
   return async (dispatch)=> {
      // console.log("msg",msg)
      if(type==='success'){
         dispatch(setAccessLabElements('successAlertMessage',msg));
         snackbar.successSnackbar();
       } else if(type==='error'){
         dispatch(setAccessLabElements('errorAlertMessage',msg));
         snackbar.errorSnackbar();
      }
   }
}

export function setAccessLabElements(name,content) {
   return (dispatch)=> {
      dispatch(accessLabActions.setAccessLabElements(name,content))
   }
}
/**
 * Function to get user admin domainName.
 * @author Narasappa H
 * @return {void} - Nothing.
 */

export function getDomainName (){
   return async(dispatch ,getState)=> {
      try {
         var userDetails=JSON.parse(sessionStorage.getItem("userData"))
          let response = await customHttp.get('/api/confidentialDetails/user/'+userDetails.belongsTo);
         //  console.log("domainName",response.data.message)
          if(response.data.message.length>0){
            if(Object.keys(response.data.message[0].organizationDomainName).length>0){
               dispatch(setAccessLabElements("userDomainName",response.data.message[0].organizationDomainName))
            }else{
               dispatch(setAccessLabElements("userDomainName",'platifi.com'))
            }
          }else{
            dispatch(setAccessLabElements("userDomainName",'platifi.com'))
          }
       } catch (err) {
         dispatch(setAccessLabElements("userDomainName",'platifi.com'))
         console.log("error",err)
       }
   }
}

export function getRemoteProcteringStatus(window,data) {
   return async (dispatch)=> {
      dispatch(setAccessLabElements("bookedSlotId",data))
      try {
         var response = await customHttp.get('/api/course/enableRemoteProctoring/courseId/'+data.courseId );
         // dispatch(setAccessLabElements("enableRemoteProctoring",response.data.message))
         if(response.data.message.enableRemoteProctoring===true){
            dispatch(setAccessLabElements("webcamVideoLoader",false))
            document.getElementById('specialInstructionPopup').style.display='block';
            dispatch(setAccessLabElements("webCamStart",true))
            dispatch(streamCamVideo());
            
         }
         else{
            dispatch(handleAccessLab("accessLabLoader",window,data))
         }
       
      } catch (err){
          dispatch(handleAccessLab("accessLabLoader",window,data))
         console.log("error",err)
      }
   }
}
// Remote  Proctoring
var mediaStreamObject;
export function streamCamVideo() {
   return async (dispatch)=> {
      

      dispatch(setAccessLabElements("showWebCam",true))
      
        // var videoEl = document.getElementById("videoElement")
         const canvas = document.getElementById("canvasElement")
         
         var constraints = { audio: false, video: true };
         navigator.mediaDevices
           .getUserMedia(constraints)
           .then((mediaStream)=> {
            mediaStreamObject=mediaStream;
            dispatch(setAccessLabElements("webcamVideoLoader",true))
            dispatch(setAccessLabElements("webcamCapturedImage", mediaStream))
             var videoEl = document.getElementById("videoElement");
     
             videoEl.srcObject = mediaStream;
             videoEl.onloadedmetadata = async function(e) {
                 videoEl.play();
                 
               await faceapi.nets.ssdMobilenetv1.loadFromUri('/models')
               await faceapi.loadTinyFaceDetectorModel('/models')
               await faceapi.loadFaceLandmarkTinyModel('/models')
               await faceapi.loadFaceLandmarkModel('/models')
               dispatch(displayLandmark(mediaStream))
               
             };
           })
           .catch(function(err) {
             console.log(err.name + ": " + err.message);
           }); // always check for errors at the end.
           
     
   }
}

var makeid=(length)=> {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
       for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
}

var dataURLtoFile=(dataurl, filename)=> {
   var arr = dataurl.split(','),
   mime = arr[0].match(/:(.*?);/)[1],
   bstr = window.atob(arr[1]),
   n = bstr.length,
   u8arr = new Uint8Array(n);
   
   while(n--){
   u8arr[n] = bstr.charCodeAt(n);
   }
   
   return new File([u8arr], filename, {type:mime});
} 
 export function displayLandmark(mediaStream){
    return async(dispatch,getState)=>{
      var videoEl = document.getElementById("videoElement")
      const canvas = document.getElementById("canvasElement")
      const displaySize = { width: canvas.width, height: canvas.height }
      faceapi.matchDimensions(canvas, displaySize)
      const detectionsWithLandmarks = await faceapi
      .detectSingleFace(videoEl)
      .withFaceLandmarks()
      

      if(detectionsWithLandmarks){
         console.log("detection", detectionsWithLandmarks.detection._score)
         const resizedResults = faceapi.resizeResults(detectionsWithLandmarks, displaySize)
         faceapi.draw.drawDetections(canvas, resizedResults)
         faceapi.draw.drawFaceLandmarks(canvas, resizedResults)
         console.log("resizedResults", resizedResults)
      }
      if(detectionsWithLandmarks && detectionsWithLandmarks.detection._score>=.99){
         dispatch(captureImage(mediaStream))
      }else{
         if(getState().accessLabState.webCamStart){
            setTimeout(() => dispatch(displayLandmark(mediaStream)))
         }
         
      }
      
   }
}

export function captureImage(mediaStream) {
   return async (dispatch)=> {
         const canvas = document.getElementById("canvasElement")
         var videoEl = document.getElementById("videoElement")
         const context = canvas.getContext("2d")
         //console.log("context", context)
         context.drawImage(videoEl, 0, 0)
         var data = canvas.toDataURL('image/png');
         //console.log("imagedata", data)
         var imageType = "image"+ makeid(5)+ '.png'
         var file = dataURLtoFile(data,imageType)
         console.log("image ",file)
         console.log("mediastream1",mediaStream)
         mediaStream.getTracks().forEach(function(track) {
            track.stop();
          });
         videoEl.pause()
         dispatch(UploadWebcamImage(file))
      }
      
}

export function UploadWebcamImage(file) {
   return async(dispatch,getState)=> {
      // console.log("slot is here",getState().accessLabState.bookedSlotId)
      const userData = JSON.parse(sessionStorage.getItem('userData'));
      const bookedSlotId=getState().accessLabState.bookedSlotId;
      if(file){      
         var data = new FormData();
         const configuration = { headers: { "Content-Type": "multipart/form-data" } };
         data.append('file',file)
         data.append('userId',userData._id)
         data.append('belongsTo',userData.belongsTo)
         data.append('bookedSlotId',bookedSlotId._id)

         try {
            const response = await customHttpUserside.post(`/api/webCam/uploadImage/`+bookedSlotId._id,data);
            console.log("response",response.userData);
            dispatch(displayAlert('success','Image captured Successfully'));
            dispatch(setAccessLabElements("showWebCam",false))
         } catch(err){
            console.log("error",err.response);
            dispatch(setAccessLabElements("showWebCam",false))
            // dispatch(setAccessLabElements('imageLoader',false));
           
         }
      } else {
         dispatch(setAccessLabElements("showWebCam",false))
         dispatch(streamCamVideo());
         // dispatch(displayAlert('error','Please upload an image'))
      }
   }
}
export function stopWebcamRecording() {
   return (dispatch)=> {
      const canvas = document.getElementById("canvasElement")
      var videoEl = document.getElementById("videoElement")
      const context = canvas.getContext("2d")
      //console.log("context", context)
      context.drawImage(videoEl, 0, 0)
      mediaStreamObject.getTracks().forEach(function(track) {
         track.stop();
       });
       videoEl.pause()
   }
}

export function closeSpecialInstructionPopup() {
   return (dispatch,getState)=> {
      if(getState().accessLabState.showWebCam && getState().accessLabState.webcamVideoLoader){        
         dispatch(setAccessLabElements("webCamStart",false))
         dispatch(stopWebcamRecording());
         var canvas = document.getElementById('canvasElement');
         var ctx = canvas.getContext("2d");
         ctx.clearRect(0, 0, canvas.width, canvas.height);
       }
       document.getElementById('specialInstructionPopup').style.display='none';
   }
}

