import React, {useEffect } from 'react';
import { connect } from "react-redux";
import * as courseAnalyticsContainer from "../../../container/actions/CourseAnalytics/action";
import * as userDashboardContainer from "../../../container/actions/UserDashboard/action";
import { bindActionCreators } from 'redux';
import './UserSlotUsage.scss';

function UserSlotUsage (props) {
 
  useEffect(()=>{
    
  },[])

  return(
    <div className="add-on-load-animation">
      <div className="col-md-12 nj-card">
        <div className="row">
          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4 className="font_family_montserrat">Slot Usage</h4>
            </div>  
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {!props.courseAnalyticsState.slotUsageLoader && !props.userDashboardState.coursesLoader ?                 
              <div className="course-table-margin-top">
                {Array.isArray(props.userDashboardState.courses) && props.userDashboardState.courses.length>0 && <div className="row">
                  <div className="col-md-3 pull-right">
                  <p className="popup-content-label" style={{fontSize: 14}}>Select Course</p>
                  <div className="nj-form-select">
                      <select style={{textTransform: 'capitalize'}} name="selectedSlotUsageCourse" value={JSON.stringify(props.userDashboardState.selectedSlotUsageCourse)} onChange={props.handleSlotUsageCourseChange} autoComplete="off" required>
                      {props.userDashboardState.courses.map((data,i)=>{
                          return (<option key={i} value={JSON.stringify(data)}>{data.name}</option>)
                        })}
                      </select>
                  </div>
                  </div>
                </div>}
                {props.courseAnalyticsState.slotUsageList.length>0 ?  
                  <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"75vh",marginTop:10}}>
                    <table className="nj-table nj-table--tbody-table font_family_montserrat">
                      <thead className="nj-table--thead">
                        <tr>
                          <th>
                            <div className="nj-table-text">SL NO.</div>
                          </th>                                                   
                          <th>
                            <div className="nj-table-text">Booking Date</div>
                          </th>
                          <th>
                            <div className="nj-table-text">Booking Time</div>
                          </th>
                          <th>
                            <div className="nj-table-text">Usage (in mins)</div>
                          </th>
                          <th>
                            <div className="nj-table-text">Usage History</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="dl-team_tbody">    
                        {props.courseAnalyticsState.slotUsageList.map((data,i) => {
                          return(                            
                            <tr key={i} className="tr-values nj-table--tr tr-table-state-null ">
                              <td className="td nj-table--td">{i+1}</td>
                              <td className="td nj-table--td">{data.date.substring(0,10)}</td>
                              <td className="td nj-table--td">{data.bookingTime}</td>
                              <td className="td nj-table--td">{data.usage}</td>
                              <td className="td nj-table--td">
                                <button type="button" className="nj-flow-button" onClick={()=>props.showUserSlotUsage(data)}>View</button>
                              </td>
                            </tr>
                          )
                        })}                        
                      </tbody>
                    </table>
                  </div>   
                  :
                  <div className="col-md-12 idams_noDataFound" style={{height:"60.3vh"}}>
                    <span className="font_family_montserrat">No data found</span>
                  </div>                                        
                }                                                             
                </div> :
                <div className="row" style={{height:"60.3vh",display:'flex',alignItems:'center',justifyContent:'center',textAlign:'center'}}>
                  <div className="col-md-12">
                    <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                  </div>
                </div>
              } 
          </div>
        </div>
        {/* popup slots */}
        <div id="userSlotUsagePopup" className="nj-overly add-rebound-animation slot-popup" >
          <div className="popup-nj my-popup" style={{maxWidth:300}}>
            <header className="popup-header slot-popup-header" style={{borderBottom:'1px solid #e6e6e6'}}>
              <span>Slot Usage Details</span>
              <span onClick={()=>document.getElementById('userSlotUsagePopup').style.display='none'} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
            </header>
            <div className="popup-content" style={{padding:'10px'}}>
              {!props.userDashboardState.userSlotUsageHistoryLoader?<div>
                {props.userDashboardState.userSlotUsageHistory.sessionData && props.userDashboardState.userSlotUsageHistory.sessionData.length>0 ? <div className="col-md-12 slot-usage-box">
                  {props.userDashboardState.userSlotUsageHistory.sessionData.map((item,i)=>{
                    return (
                      <div className="elements">
                        {item.status==='logonTime' && <div className="icon" style={{backgroundColor:'rgb(222, 246, 233)',color:'rgb(92, 200, 112)'}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} viewBox="0 0 24 24" fill="none" 
                            stroke="currentColor" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" 
                            className="feather feather-monitor">
                            <rect x={2} y={3} width={20} height={14} rx={2} ry={2} />
                            <line x1={8} y1={21} x2={16} y2={21} />
                            <line x1={12} y1={17} x2={12} y2={21} />
                          </svg>
                          </div>}
                          {item.status==='startTime' && <div className="icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" 
                              className="feather feather-copy">
                              <rect x={9} y={9} width={13} height={13} rx={2} ry={2} />
                              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                            </svg>
                          </div>}
                          {item.status==='endTime' && <div className="icon" style={{backgroundColor:'rgb(253, 239, 221)',color:'rgb(244, 158, 65)'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} viewBox="0 0 24 24" fill="none" 
                              stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" 
                              className="feather feather-crop">
                              <path d="M6.13 1L6 16a2 2 0 0 0 2 2h15" /><path d="M1 6.13L16 6a2 2 0 0 1 2 2v15" />
                            </svg>
                          </div>}
                          {item.status==='logoutTime' && <div className="icon" style={{backgroundColor:'rgb(250, 228, 228)',color:'rgb(234, 84, 85)'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} viewBox="0 0 24 24" fill="none" 
                              stroke="currentColor" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" 
                              className="feather feather-log-out">
                              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                              <polyline points="16 17 21 12 16 7" />
                              <line x1={21} y1={12} x2={9} y2={12} />
                            </svg>
                          </div>}
                          {item.status!=='logonTime' && item.status!=='startTime' && item.status!=='logoutTime' && item.status!=='endTime' && <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" 
                              className="feather feather-copy">
                              <rect x={9} y={9} width={13} height={13} rx={2} ry={2} />
                              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                            </svg>
                          </div>}
                        <div className="title-box">
                          <p> {item.message} </p>
                          <span>at {item.time}</span>
                        </div>
                      </div>
                    )
                  })}
                </div>:
                <div className="col-md-12 slot-usage-no-data">
                  <p>No data available..</p>
                </div>}
              </div>:
              <div className="col-md-12 slot-usage-no-data">
                <img src={"/img/loaders/spinningwheel.gif"}  height={60} width={60} alt="loader" />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  courseAnalyticsState: state.courseAnalyticsState,
  userDashboardState: state.userDashboardState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setSlotUsageListActual:courseAnalyticsContainer.setSlotUsageListActual,
  showUserSlotUsage:userDashboardContainer.showUserSlotUsage,
  handleSlotUsageCourseChange:userDashboardContainer.handleSlotUsageCourseChange
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(UserSlotUsage);