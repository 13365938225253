import * as dashboardActionTypes from "../../../actionTypes";

const initialState = {
  elementsCount:{},
  elementsCountLoader:false,
  topCoursesLoader:false,
  topCourses:[],
  topApps:[],
  topAppsLoader:false,
  userRatings:[],
  allCourses:[],
  initialLoaderForBookedAndUsedSlots:true,
  bookedAndUsedSlots:{},
  bookedAndUsedSlotSeries:[{name: 'Booked Slots',data:[]},{name: 'Used Slots',data:[]}],
  bookedAndUsedSlotOptions :{
    chart: {
      type: 'area',
      height: 450,
      stacked: false,
      fontFamily: 'Montserrat,sans-serif',
      foreColor: '#626262'
    },
    colors: ['#008FFB','#00E396'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.4,
        opacityTo: 0.5,
      },
      colors: ['#008FFB','#00E396'],
    },
    legend: {
      show:false,
      position: 'top',
      horizontalAlign: 'right'
    },
    xaxis: {
      type: 'category',
      categories:[]
    },
    stroke: {
      show: true,
      curve: 'smooth',
      colors: ['#008FFB','#00E396'],
      width: 2,
      dashArray: 0,      
    }
  },
  topCoursesChartType:'radialBar',
  topCoursesSeries:[],
  topCoursesColors:[],
  topCoursesOptions:{
    chart: {
      width: '100%',
      type: 'radialBar',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true
        }
      },
      title: {
        text: 'Top Courses',
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '14px',
          fontWeight:  'bold',
          fontFamily:  undefined,
          color:  '#263238'
        },
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
      }
    },
    legend:{show:false},
    dataLabels:{
      enabled:false
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show:true,
            fontSize: '22px',
            fontFamily:'Montserrat,sans-serif',
            offsetY: -5
          },
          value: {
            show:true,
            fontSize: '16px',
            offsetY: 0,
            fontFamily:'Montserrat,sans-serif',
            fontWeight:500,
            formatter: function (val) {
              return val
            }
          },
          total: {
            show: true,
            color: 'rgb(143,130,242)',
            label: 'Total Booked',
            offsetY: -10,
            fontWeight:600,
            fontSize:14,
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return 0
            }
          }
        },
        hollow: {
          margin: 10,
          size: '30%',
          background: 'transparent',
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 40,
            left: 30,
            blur: 2,
            opacity: 0.9
          }
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: '#eeeeee',
          strokeWidth: '97%',
          opacity: 1,
          margin: 10, 
          dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.1
          }
        },
      },
    },
    labels: [],
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'round',
      colors: undefined,
      width: 0,
      dashArray: 0,      
    },
    theme: {
      mode: 'light', 
      palette: 'palette11',
      monochrome:{
        enabled:false,
        color:'#7367F0',
        shadeTo: 'light',
        shadeIntensity: 0.65
      }
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: false,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: true,
      theme: true,
      style: {
        fontSize: '14px',
        fontWeight:600,
        fontFamily: undefined,
        color: '#fff'
      },
      onDatasetHover: {
          highlightDataSeries: false,
      },
      marker: {
        show: true,
      },
      items: {
        display: 'flex',
      },
    }
  }
}

export default function(state=initialState, action) {
  switch (action.type) {
    case dashboardActionTypes.DASHBOARD_COMPONENT_ELEMENTS: {
      const {name,value} = action.payload; 
      return {
        ...state,[name]:value
      }   
    }
    default:
      return state;
  }
}
/*
tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
        fontFamily: undefined
      },
      onDatasetHover: {
          highlightDataSeries: false,
      },
    }
*/
/*
pie: {
        customScale: 1,
        offsetX: 0,
        offsetY: 0,
        expandOnClick: true,
        dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10
        }, 
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              fontSize: '22px',
              fontFamily:'Montserrat,sans-serif',
              offsetY: -5
            },
            value: {
              fontSize: '16px',
              offsetY: 0,
              fontFamily:'Montserrat,sans-serif',
              fontWeight:500,
              formatter: function (val) {
                return val
              }
            },
            total: {
              show: true,
              color: 'rgb(143,130,242)',
              label: 'Total Booked',
              offsetY: -10,
              fontWeight:600,
              fontSize:14,
              formatter: function (w) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return 0
              }
            }
          }
        },      
      }
*/