
import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route,Switch} from "react-router-dom";
import Login from "./presentational/Login";
import sharedResourceLogin from "./presentational/sharedResourceLogin";
import Dashboard from "./dashboard";
import userDashboard from "./userDashboard";
import subadminDashboard from "./SubAdminDashboard";
import dltDashboard from "./DLTDashboard";
import ProtectedRouter from "./ProtectedRoutes";
import sharedResourceDashboard from "./sharedResourceDashboard";
import sharedCourseLogin from "./sharedCourseLogin/index";
import RedirectToUserDashboard from "./presentational/Auth";



class App extends Component {
  render() {
    return (
      <Router>
        <Route>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <ProtectedRouter exact path="/:section" component={Dashboard} />
            <ProtectedRouter exact path="/user/:section" component={userDashboard} />          
            <ProtectedRouter exact path="/sub-admin/:section" component={subadminDashboard} />          
            <ProtectedRouter exact path="/dlt/:section" component={dltDashboard} /> 
            <ProtectedRouter exact path="/shared/:section" component={sharedResourceDashboard} />  
            <Route exact path="/shared/resource/:id" component={sharedResourceLogin} />  
            <Route exact path="/shared/course/:id/:userId/:adminId/:belongsto/:currentTime" component={sharedCourseLogin} />
            <Route exact path="/authenticate/:token/:id/:email" component={RedirectToUserDashboard} />
                  
          </Switch>
        </Route>
      </Router>
    );
  }
}

export default App;
