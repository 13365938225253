import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import * as resourceContainer from "../../container/actions/Resources/action";
import * as Actions from "../../container/actions/UserCourses";
import * as UserCoursesContainer from "../../container/actions/UserCourses/action";
import { bindActionCreators } from 'redux';
import customHttpUserside from '../../packages/CustomHttpUserside/';
import VideoPlayer from '../../packages/VideoPlayer/';
import Rating from "../../packages/Ratings";
import customHttp from '../../packages/CustomHttp';
// import { getSelectedResources } from '../../container/actions/Courses/action';

function UserResources (props) {

  // var resourceObj = {name:'',discipline:'',contentType:'',file:{}};
  // const [resource, setResource] = useState(resourceObj);
  const [resourceArray, setResourceArray] = useState([]);
  const [showLoadResourceLoader, setshowLoadResourceLoader] = useState(true)
  const [resourceVideo, setSetResourceVideo] = useState({})
  const [videoCurrentTime, setVideoCurrentTime] = useState(0)
  const [userCourse, setUserCourse] = useState({})
  const [htmlFileDetails, setHtmlFileDetails] = useState({})

  useEffect( () => {
    // console.log("hello",props);
    getAllResources();
    document.getElementById('resourceRatingPopup').style.display='none';
    document.getElementById('editResourcePopup').style.display='none';

    document.getElementById('ratingPopup').style.display='none';
  },[]);
  // onload function end`


  var getAllResources = async () => {
   var obj=JSON.parse(sessionStorage.getItem('userCourse'));
   setshowLoadResourceLoader(true)
    try {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))

      let response = await customHttpUserside.get("/api/course/"+obj._id+"/"+userDetails._id);
      //let response = await customHttp.get("/api/course/"+obj._id);
      // console.log("resppppponsnsn",response.data.message[0].resources)
      setUserCourse(response.data.message[0] || [])
      if(response.data.message && Array.isArray(response.data.message)){
        var arr = response.data.message[0].resources;
        arr = arr.map(obj=> ({ ...obj, isChecked: false }));
        // var arr1 = JSON.parse(JSON.stringify(arr));
        setResourceArray(arr);
        setshowLoadResourceLoader(false)
      } else {
        setResourceArray([]);
        setshowLoadResourceLoader(false)
      }
    } catch (err) {
      setResourceArray([]);
      setshowLoadResourceLoader(false)
      console.log("error",err)
    }
  }

  var routeToUserCourses=()=>{
    props.showResourcesList(true)
  }
  var routeToApplications=()=>{
    sessionStorage.setItem('selectedUserCourse',JSON.stringify(userCourse))
    props.history.push('/user/applications')
    props.showResourcesList(true)
  }

  var openResourceLink = (item) => {
    setSetResourceVideo(item);
    if(item.type===2){
      if(item.videoData.length>0){
        setVideoCurrentTime(item.videoData[0].currentTime)
      }else{
        setVideoCurrentTime(0)
      }
      document.getElementById('playResourceVideoPopup').style.display='block';
    } else if(item.file.length>0 && item.file && item.file.length>0) {
      const itemLink = window.location.origin+"/"+item.file[0].path || '/'
      var splitItem = itemLink.split(".") || [];
      if(splitItem && splitItem.length>0 && splitItem[splitItem.length-1]==='pdf'){
          document.getElementById('userPdfPopup').style.display='block';
          document.getElementById('userPdfPopupTitle').innerText = item.displayName;
          props.togglePdf(true,itemLink);
      } else {
          window.open(itemLink,'_blank');
      }
    } else if(item.type===4){
      localStorage.setItem('playlistResource',JSON.stringify(item));
      // console.log('props',props)
      props.history.push('view-playlist');
      props.showResourcesList(true)
    }
    props.onClickSendResourceAndUserId(item);
  }

  var openHtmlFilePopup = (item) => {
    setHtmlFileDetails(item)
      document.getElementById('playResourceHtmlPopupType6').style.display='block';
  }

  const closeVideoPopup = () => {
    setTimeout(() => {
      getAllResources()
    },1000);

    var x = document.getElementById("playVideo");
    x.pause();
    setSetResourceVideo({})
    document.getElementById('playResourceVideoPopup').style.display='none'
  }

  const closeHtmlVideoPopup = () => {
    setHtmlFileDetails({})
    document.getElementById('playResourceHtmlPopupType6').style.display='none'
  }

  var getRatings=(ratings)=>{
    props.setDynamicValues("rating",ratings)
  }
  var getResourceRatings=(ratings)=>{
    props.setEditResourceRatings("ratings",ratings)
  }

  var clrearSelectedRatings=()=>{
    props.setDynamicValues("rating","")
    props.setDynamicValues("ratingDescription","")
    props.setDynamicValues("showRatingLoader",false)
    document.getElementById('resourceRatingPopup').style.display='none';
  }
  var clrearSelectedResourceRatings=()=>{
    document.getElementById('editResourcePopup').style.display='none';
  }
  var getSelectedResourceObject=(item)=>{
    props.setDynamicValues("selectedObject",item)
    props.checkResourceRatings();
    //document.getElementById('resourceRatingPopup').style.display='block';
  }

  var goToLiveClassroom=async()=>{
    // const obj=JSON.parse(sessionStorage.getItem('userCourse'));
    // localStorage.setItem('liveCourse',JSON.stringify(obj))
    // var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    // if(userDetails.type===1){
    //     props.history.push("/live-stream")
    //   }else if(userDetails.type===2){
    //     props.history.push("/dlt/live-stream")
    //   }else if(userDetails.type===0){
    //     props.history.push("/user/live-stream")
    // }else{
    //   props.history.push("/sub-admin/live-stream")
    // }
    //zoom stuff
    //to avoid popups getting blocked since we need to open url on ajax call..
    var importantStuff = window.open('', '_blank'); //imediately create a blank page
    importantStuff.document.write('Connecting...'); // add some text to the window opened
    var userDetails=JSON.parse(sessionStorage.getItem("userData"));
    var obj=JSON.parse(sessionStorage.getItem('userCourse'));
    try {
      var response = await customHttp.get('/api/liveStream/meeting/courseId/'+obj._id);
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      if(response.data.message){
        var meetingId = response.data.message.meetingId;
        var password = response.data.message.meetingPassword;
        console.log("meeting id",meetingId)
        importantStuff.location.href = `https://platifivccloud.platifi.com/client/${userDetails.username}/${meetingId}/${password}`; //filling it with content when ready
      }
    } catch(err){
      console.log("error",err)
    }
  }
  return (

      <div className="add-on-load-animation">
        <div className="col-md-12 nj-card">
          <div className="row">
            <div className="nj-card__header">
              <div className="nj-card__title">
                <h4>Current Resources</h4>
              </div>
              <div>
              {JSON.parse(sessionStorage.getItem('userCourse')).isLive && JSON.parse(sessionStorage.getItem('userCourse')).isLiveClassRoom && <button  type="button"  className="nj-flow-button addRoles_button" onClick={goToLiveClassroom} style={{float:"none",marginTop:"5px",marginRight:"5px",fontWeight:'600',backgroundColor:'rgba(92, 200, 112,1)',boxShadow:'0 0 0px rgba(255, 64, 66,.1), 0px 1px 10px rgba(92, 200, 112,.5)'}}>Watch Live Stream</button>}
                {userCourse.applications && userCourse.applications.length>0 && userCourse.isUserSlotBooking && <button className="nj-flow-button " type="button" onClick={routeToApplications} style={{margin:"0px 10px 10px 0px"}}>Practice Course</button>}
                <button className="nj-flow-button " type="button" onClick={routeToUserCourses} style={{marginBottom:"10px"}}>Back</button>
              </div>
            </div>
          </div>

          {resourceArray.length>0 ? <div className="row">
            <div className="tbl-content course-table-margin-top" style={{maxHeight:'70vh',overflow:'auto'}}>
              <div className="resources-wrapper">
                {resourceArray.map((item, i) => {
                  return (
                    <div className="resource_card add-rebound-animation" key={i}>
                      <div className="resource_card-content">
                        {/* {item.type===2?<div className="resource_card-content-img-wrapper"  onClick={()=>openResourceLink(item)}>
                           <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                            </div>
                                 :<div className="resource_card-content-img-wrapper"  onClick={()=>openResourceLink(item)}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource" />
                        </div>} */}
                         {item.type===2 && <div className="resource_card-content-img-wrapper"  onClick={()=>openResourceLink(item)}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                        </div>}
                        {item.type===0 && <div className="resource_card-content-img-wrapper"  onClick={()=>openResourceLink(item)} >
                          <img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource" />
                        </div>}
                        {item.type===4 && <div className="resource_card-content-img-wrapper" onClick={()=>openResourceLink(item)}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/file.svg"} alt="resource" />
                        </div>}
                        {item.type===6 && <div className="resource_card-content-img-wrapper"  onClick={()=>openHtmlFilePopup(item)}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                        </div>}

                        <div className="resource-card-float" style={{marginTop:'-25px',paddingRight:5}}>
                          <div className="resource-card-float-element" style={{backgroundColor:'rgb(99, 112, 173)',color:'#fff',fontWeight:'600',padding:'1px 6px',borderRadius:"50%",fontSize:14}} title="Rate Here" onClick={(e)=>{getSelectedResourceObject(item)}}>
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 24 20"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-star"
                                style={{fill:'#fff'}}
                              >
                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                              </svg>
                            </span>
                          </div>

                          {item.type===4 && <div className="pull-left" style={{marginLeft:"5px"}}>
                            <span style={{color:"rgb(99, 112, 173)",verticalAlign:"middle"}} title="Time investment in minutes" >
                              <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock">
                                <circle cx={12} cy={12} r={10} />
                                <polyline points="12 6 12 12 16 14" />
                             </svg>
                            <span style={{verticalAlign:"top",fontSize:11,paddingLeft:2}}>{parseFloat(parseFloat(parseInt(item.timeInvestment)/60).toPrecision(2))+" Min"}</span>
                            </span>
                      </div> }

                      {item.type!==4 && <div className="pull-left" style={{marginLeft:"5px"}}>
                            <span style={{color:"rgb(99, 112, 173)",verticalAlign:"middle"}} title="Time investment in minutes" >
                              <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock">
                                <circle cx={12} cy={12} r={10} />
                                <polyline points="12 6 12 12 16 14" />
                             </svg>
                            <span style={{verticalAlign:"top",fontSize:11,paddingLeft:2}}>{parseFloat(parseFloat(parseInt(item.file[0].timeInvestment)/60).toPrecision(2))+" Min"}</span>
                            </span>
                      </div>}

                        </div>
                       </div>
                      {item.type===2 && <div className="" style={{backgroundColor:"red",height:3,width:(item.videoData.length && parseFloat(parseFloat(item.videoData[0].currentTime/item.videoData[0].duration)*100).toPrecision(2)+"%")}} key={item._id}></div>}
                      <div>
                        {item.type===6 ?
                        <div className="resource_card-footer"  onClick={()=>openHtmlFilePopup(item)}>
                          <p className="font_family_montserrat">{item.displayName}</p>
                        </div>
                        :
                        <div className="resource_card-footer"  onClick={()=>openResourceLink(item)}>
                          <p className="font_family_montserrat">{item.displayName}</p>
                        </div>
                      }
                    </div>
                    </div>
                  );
               })}
              </div>
            </div>
               <div className="resource-proceed pull-right">
                {/* <button className="nj-flow-button bg-color-red" type="button" onClick={routeToUserCourses}>Skip</button> */}
                {/* <button className="nj-flow-button" type="button" style={{marginLeft:10}}  onClick={submitResources}>Submit</button> */}
              </div>
          </div>:
            <div className="row">
               <div className="no-resource-text">
                 {showLoadResourceLoader ?
                    <img src="/img/loaders/spinningwheel.gif" alt="resource" height="60" width="60" />
                    :<p>No resource found.</p>
                 }
              </div>
            </div>}
          </div>

       {/*popup to play resources*/}
         <div id="playResourceVideoPopup" className={"nj-overly add-rebound-animation"}>
           <div className="popup-nj  my-popup" style={{maxWidth:"700px"}}>
             <header className="popup-header">
               <span style={{textTransform:"capitalize"}}>{resourceVideo.displayName}</span>
               <span onClick={closeVideoPopup}><i className="popup-close material-icons">close</i></span>
            </header>
             {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
             <div className="popup-content">
              <div className="col-md-12">
                 {Object.keys(resourceVideo).length>0 && <VideoPlayer link={"../"+resourceVideo.file[0].path} key={resourceVideo._id}  resourceId={resourceVideo._id} currenttime={videoCurrentTime}/>}
              </div>
            </div>
          </div>
        </div>

        {/*popup to play html resources*/}
        <div id="playResourceHtmlPopupType6" className={"nj-overly add-rebound-animation"}>
          <div className="popup-nj  my-popup" style={{maxWidth:"900px"}}>
            <header className="popup-header">
              <span style={{textTransform:"capitalize"}}>{Object.keys(htmlFileDetails).length>0 && htmlFileDetails.displayName}</span>
              <span onClick={closeHtmlVideoPopup}><i className="popup-close material-icons">close</i></span>
            </header>
            {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
            <div className="popup-content" style={{maxHeight:"500px"}}>
              <div className="col-md-12">
                <iframe  id='playHtml6'  src={"/"+(Object.keys(htmlFileDetails).length>0 && htmlFileDetails.link)+".html"}  width="512" height="450" style={{width:'100%',outline:'none'}}>
               </iframe>
              </div>
            </div>
          </div>
      </div>

        {/*popup to ratings*/}
        <div id="resourceRatingPopup" className={"nj-overly add-rebound-animation"}>
           <div className="popup-nj  my-popup" style={{maxWidth:"400px"}}>
             <header className="popup-header font_family_montserrat">
               <span className="font_family_montserrat">Write a Review</span>
               <span ><i className="popup-close material-icons" onClick={clrearSelectedRatings}>close</i></span>
            </header>
             {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
             <div className="popup-content">
              <div className="col-md-12 font_family_montserrat">
                <h4  className="font_family_montserrat rating-title-h4">Rate this resource</h4>
                <Rating getRatings={getRatings} width="36" height="36" color="rgba(115,103,240)" rating={props.userCoursesState.rating} key={props.userCoursesState.rating}  classname="rating-props-container" /> {props.userCoursesState.rating>0&&(<span className="pull-right rating-expression-span" >{(props.userCoursesState.rating|| "0")+"/5"}</span>)}
                <br/>
                <br/>
                <h4  className="font_family_montserrat rating-title-h4">Write your own review in the following:</h4>
                <textarea className="textarea-input-css font_family_montserrat" rows="5" cols="48" placeholder=" Write something..." value={props.userCoursesState.ratingDescription} onChange={(e)=>{props.setDynamicValues("ratingDescription",e.target.value)}} style={{marginTop:"10px"}}></textarea>
                <br/>
                <br/>
                <div className="">
                  {!props.userCoursesState.showRatingLoader ? <button  type="button" className="nj-flow-button font_family_montserrat" style={{color: "white",textAlign:"center",width:"100%",lineHeight:"2.2"}} onClick={props.submitRatings}>SUBMIT REVIEW</button> :<center><img src={"/img/loaders/spinningwheel.gif"}  className="" height={50} width={50} style={{color: "white",textAlign:"center"}} alt="loader" /></center>}
                </div>
                <br/>
              </div>
            </div>
          </div>
        </div>


         {/*popup to editResourceRatings*/}
         <div id="editResourcePopup" className={"nj-overly add-rebound-animation"}>
           <div className="popup-nj  my-popup" style={{maxWidth:"400px"}}>
             <header className="popup-header font_family_montserrat">
               <span className="font_family_montserrat">Edit a Review</span>
               <span ><i className="popup-close material-icons" onClick={clrearSelectedResourceRatings}>close</i></span>
            </header>
             {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
             <div className="popup-content">
              <div className="col-md-12 font_family_montserrat">
                <h4  className="font_family_montserrat rating-title-h4">Rate this resource</h4>
                <Rating getRatings={getResourceRatings} width="36" height="36" color="rgba(115,103,240)" rating={props.userCoursesState.editResourceRatingObject.ratings} key={props.userCoursesState.editResourceRatingObject._id}  classname="rating-props-container" /> {props.userCoursesState.editResourceRatingObject.ratings>0&&(<span className="pull-right rating-expression-span" >{(props.userCoursesState.editResourceRatingObject.ratings|| "0")+"/5"}</span>)}
                <br/>
                <br/>
                <h4  className="font_family_montserrat rating-title-h4">Write your own review in the following:</h4>
                <textarea className="textarea-input-css font_family_montserrat" rows="5" cols="48" placeholder=" Write something..." value={props.userCoursesState.editResourceRatingObject.reviews} onChange={(e)=>{props.setEditResourceRatings("reviews",e.target.value)}} style={{marginTop:"10px"}}></textarea>
                <br/>
                <br/>
                <div className="">
                  {!props.userCoursesState.showEditResourceRatingLoader ? <button  type="button" className="nj-flow-button font_family_montserrat" style={{color: "white",textAlign:"center",width:"100%",lineHeight:"2.2"}} onClick={props.UpdateResourceRatings}>SUBMIT REVIEW</button> :<center><img src={"/img/loaders/spinningwheel.gif"}  className="" height={50} width={50} style={{color: "white",textAlign:"center"}} alt="loader" /></center>}
                </div>
                <br/>
              </div>
            </div>
          </div>
        </div>
        {/* popup for pdf open */}
      <div id="userPdfPopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup" style={{maxWidth:750}}>
          <header className="popup-header">
            <span id="userPdfPopupTitle" style={{textTransform:'capitalize'}}></span>
            <span onClick={()=>{document.getElementById('userPdfPopup').style.display='none'}}><i className="popup-close material-icons">close</i></span>
          </header>
          <div className="popup-content">
            <div className="col-md-12">
              {!props.resourceState.pdfLoader?<>
              <div id="canvas_container">
                <canvas id="pdf_renderer"></canvas>
              </div>
              <div className="pdf-controls">
                <div id="navigation_controls">
                  <button className="nj-flow-button" id="go_previous" onClick={props.goPreviousPage} style={{padding:'0 1rem',fontSize:16}}>{'<'}</button>
                  <input className="vs-input--input" id="current_page" defaultValue={1} type="number" onKeyDown={props.currentPage}  style={{width:40,margin:'0 5px',padding:0,textAlign:'center'}} />
                  <button className="nj-flow-button" id="go_next" onClick={props.goNextPage} style={{padding:'0 1rem',fontSize:16}}>{'>'}</button>
                </div>
                <div id="zoom_controls">  
                  <span style={{marginRight:5,fontWeight:'500'}}>Zoom</span>
                  <button className="nj-flow-button" id="zoom_out" onClick={props.zoomOutPage}  style={{padding:'0 1rem',fontSize:16,marginRight:5}}>-</button>
                  <button className="nj-flow-button" id="zoom_in" onClick={props.zoomInPage}  style={{padding:'0 1rem',fontSize:16}}>+</button>
                </div>
              </div>
              </>:
              <div className="" style={{height:"42vh",textAlign:'center'}}>
                <div className="col-md-12" style={{marginTop:"15vh"}}>
                  <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  resourceState: state.resourceState,
  userCoursesState:state.userCoursesState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllResources: resourceContainer.getAllResources,
  showResourcesList:Actions.showResourcesList,
  setDynamicValues:Actions.setDynamicValues,
  submitRatings:UserCoursesContainer.submitRatings,
  checkResourceRatings:UserCoursesContainer.checkResourceRatings,
  setEditResourceRatings:Actions.setEditResourceRatings,
  UpdateResourceRatings:UserCoursesContainer.UpdateResourceRatings,
  onClickSendResourceAndUserId:UserCoursesContainer.onClickSendResourceAndUserId,
  togglePdf:resourceContainer.togglePdf,
  renderPdf:resourceContainer.renderPdf,
  goPreviousPage:resourceContainer.goPreviousPage,
  goNextPage:resourceContainer.goNextPage,
  currentPage:resourceContainer.currentPage,
  zoomInPage:resourceContainer.zoomInPage,
  zoomOutPage:resourceContainer.zoomOutPage
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(UserResources);
