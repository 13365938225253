import * as dashboardActions from "./index";
//import * as dashboardActions from "../courseAnalytics";
import customHttp from '../../../packages/CustomHttp/';
import snackbar from '../../../packages/Snackbar/';
import converDate from '../../../packages/convertDate';
import {format} from "date-fns";

export function displayAlert(type,msg) {
   return async (dispatch)=> {
      if(type==='success'){
         dispatch(setCourseAnalyticsElements('successAlertMessage',msg));
         snackbar.successSnackbar();
       } else if(type==='error'){
         dispatch(setCourseAnalyticsElements('errorAlertMessage',msg));
         snackbar.errorSnackbar(); 
      }
   }
}

export function setCourseAnalyticsElements(name,content) {
   return (dispatch)=> {
      dispatch(dashboardActions.setCourseAnalyticsElements(name,content))
   }
}

export function getAllCourses () {
 
   return (dispatch ,getState)=> {
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
   // console.log("user details",userDetails)
   dispatch(dashboardActions.setBooleanValue("showAllResourcesLoader",false))
   // axios.get('/api/course/allCourses/user/'+userDetails.belongsTo)
   var url = '/api/course/allCourses/user/'+userDetails._id ;
   customHttp.get(url)
      .then(response=>{
         dispatch(dashboardActions.setBooleanValue("showAllResourcesLoader",true))
         if(response.data.message.length>0){
            response.data.message.forEach(function(element) { element.isSelected = false});
            // console.log("get all course response",response.data.message);
            dispatch(dashboardActions.getAllCourses(response.data.message));
            dispatch(getUsersByCourse(response.data.message[0]))
         }else{
            dispatch(dashboardActions.getAllCourses([]));
         }


      })
      .catch(error => {
         dispatch(dashboardActions.setBooleanValue("showAllResourcesLoader",true))
         dispatch(dashboardActions.getAllCourses([]));
         dispatch(dashboardActions.setBooleanValue("availableCourseLoader",false))
         console.log("error",error)
      });
   }
}

export function getUsersByCourse (data) {
       return async(dispatch ,getState)=> {
         // console.log("get users by course",data)
         dispatch(dashboardActions.setBooleanValue("availableCourseLoader",true))
          try {
            let response = await customHttp.get('/api/analytics/userDetailsByCourse/courseId/'+data._id); //api/analytics/userDetailsByCourse/courseId/:courseId
            dispatch(dashboardActions.setBooleanValue("availableCourseLoader",false)) 
            if(response.data.message && Array.isArray(response.data.message)){
               dispatch(dashboardActions.getUsersByCourse1(response.data.message));
               if(getState().courseAnalyticsState.searchUsername !== ""){
                  dispatch(searchFilter(getState().courseAnalyticsState.searchUsername))}
               
               // console.log("all users",response.data.message)
            }else{
               dispatch(dashboardActions.getUsersByCourse1([]));
            }
          } catch (err) {
            dispatch(dashboardActions.setBooleanValue("availableCourseLoader",false))
            dispatch(dashboardActions.getUsersByCourse1([]));
           
   
            console.log("error",err)
          }
       }
   
   }

   
   export const setSearchUsername=(value)=>{
      return (dispatch ,getState)=> {  
        dispatch(dashboardActions.setSearchUserName(value))
      }
    }

    export const searchFilter=(data)=>{
      return (dispatch ,getState)=> {  
         var dublicateArray=getState().courseAnalyticsState.dupTotalUsrerList;
      
         if(data !==""){
         var searchRequestedArray=dublicateArray.filter(el =>{
            if(el.username!==undefined){
               return el.username.toLowerCase().indexOf(data.toString().toLowerCase()) !== -1;}
         })
         dispatch(dashboardActions.searchRequestedContent(searchRequestedArray))
      }
         else {
            dispatch(dashboardActions.searchRequestedContent(dublicateArray))}
         
       }
    }
    


//Slot Usage
export const loadSlotUsageListDemo=()=>{
   return (dispatch)=> {
      const dataType = localStorage.getItem('selectedDemoOrActual');
      // console.log("dataType",dataType)
      if(dataType && dataType==='"demo"'){
         dispatch(getUserUsageHostoryDemo());
      }
   }
}

export const setSlotUsageListDemo=()=>{
   return (dispatch)=> {  
      const slots = [
         {date:'26-03-2020',bookingTime:'12:30-14:30',usage:90},
         {date:'26-03-2020',bookingTime:'14:30-16:30',usage:113},
         {date:'26-03-2020',bookingTime:'16:30-18:30',usage:78},
         {date:'27-03-2020',bookingTime:'10:30-12:30',usage:60},
         {date:'27-03-2020',bookingTime:'12:30-14:30',usage:77},
         {date:'28-03-2020',bookingTime:'12:30-14:30',usage:80},
         {date:'28-03-2020',bookingTime:'18:30-20:30',usage:42},
         {date:'28-03-2020',bookingTime:'20:30-22:30',usage:107},
         {date:'29-03-2020',bookingTime:'12:30-14:30',usage:99},
         {date:'30-03-2020',bookingTime:'10:30-12:30',usage:65}
      ]
      dispatch(setCourseAnalyticsElements('slotUsageList',slots));
   }
}
export const setSlotUsageListActual=(startDate,endDate,course,user)=>{
   return async (dispatch)=> {  
      // console.log("setSlotUsageListActual called")
      let slots = [];
      const fromDateObj = converDate(startDate);
      const fromDate = `${fromDateObj.year}-${fromDateObj.month}-${fromDateObj.day}`;
      
      const toDateObj = converDate(endDate);
      const toDate = `${toDateObj.year}-${toDateObj.month}-${toDateObj.day}`;
      
      const courseId = course._id;
      dispatch(setCourseAnalyticsElements('slotUsageCourse',course)); 
      const userId = user._id;
      dispatch(dashboardActions.setBooleanValue("slotUsageLoader",true)) 
      try {
         let response = await customHttp.get(`/api/analytics/fromDate/${fromDate}/toDate/${toDate}/userId/${userId}/courseId/${courseId}`);
         dispatch(dashboardActions.setBooleanValue("slotUsageLoader",false)) 
         if(Array.isArray(response.data.message) && response.data.message.length>0){
            slots = response.data.message;
            dispatch(setCourseAnalyticsElements('slotUsageList',slots)); 
            // console.log("all slots",response.data.message)
         }else{
            dispatch(setCourseAnalyticsElements('slotUsageList',[])); 
         }
      } catch (err) {
         console.log("error",err);
         dispatch(dashboardActions.setBooleanValue("slotUsageLoader",false));
         dispatch(setCourseAnalyticsElements('slotUsageList',[])); 
      }
   }
}

export const showSlotUsage=(slot)=>{
   return (dispatch)=> {  
      document.getElementById('slotUsagePopup').style.display='block';
      const dataType = localStorage.getItem('selectedDemoOrActual');
      // console.log("dataType",dataType)
      if(dataType && dataType==='"demo"'){
         dispatch(getUserUsageHostoryDemo(slot));
      } else {
         dispatch(getUserUsageHostoryActual(slot));
      }
   }
}
export const getUserUsageHostoryActual=(slot)=>{
   return async(dispatch, getState)=> {
      const course = getState().courseAnalyticsState.slotUsageCourse;
      const courseId = course._id;
      const userId = slot.userId;
      const date = slot.date;
      const bookingTime = slot.bookingTime; 
      // console.log("course",course,slot)
      let userSlotUsageHistory = {};
      dispatch(dashboardActions.setBooleanValue("userSlotUsageHistoryLoader",true));
      try {
         let response = await customHttp.get(`/api/applicationsUsage/sessionHistory/date/${date}/bookingTime/${bookingTime}/courseId/${courseId}/userId/${userId}`);
         dispatch(dashboardActions.setBooleanValue("userSlotUsageHistoryLoader",false));
         if(response.data.message && response.data.message.sessionData && response.data.message.sessionData.length>0){
            userSlotUsageHistory = response.data.message;
            dispatch(setCourseAnalyticsElements('userSlotUsageHistory',userSlotUsageHistory));
            dispatch(dashboardActions.setBooleanValue("userSlotUsageHistoryLoader",false)); 
            // console.log("userSlotUsageHistory",response.data.message)
         }else{
            userSlotUsageHistory = {totalConsumption:"0 minutes",totalIdleTime:"0 minutes",quantitativeScore:0,sessionData:[]}
            dispatch(setCourseAnalyticsElements('userSlotUsageHistory',userSlotUsageHistory));
            dispatch(dashboardActions.setBooleanValue("userSlotUsageHistoryLoader",false)); 
         }
      } catch (err) {
         console.log("erroror",err);
         userSlotUsageHistory = {totalConsumption:"0 minutes",totalIdleTime:"0 minutes",quantitativeScore:0,sessionData:[]}
         dispatch(setCourseAnalyticsElements('userSlotUsageHistory',userSlotUsageHistory)); 
         dispatch(dashboardActions.setBooleanValue("userSlotUsageHistoryLoader",false));
      }
   }
}
export const getUserUsageHostoryDemo=()=>{
   return async(dispatch)=> {
      dispatch(dashboardActions.setBooleanValue("userSlotUsageHistoryLoader",true));
      const userSlotUsageHistory = {"totalConsumption":"22 minutes","sessionData":[{"message":"logged in","status":"logonTime","created_at":"2020-03-27T16:37:25.121Z","time":"22:07"},{"message":"opened dropbox","status":"startTime","created_at":"2020-03-27T16:38:11.462Z","time":"22:07"},{"message":"opened amazonkindle","status":"startTime","created_at":"2020-03-27T16:38:11.472Z","time":"22:08"},{"message":"opened chrome","status":"startTime","created_at":"2020-03-27T16:38:11.529Z","time":"22:08"},{"message":"closed chrome","status":"endTime","created_at":"2020-03-27T16:40:56.843Z","time":"22:15"},{"message":"closed amazonkindle","status":"endTime","created_at":"2020-03-27T16:40:56.880Z","time":"22:16"},{"message":"opened nodejs","status":"startTime","created_at":"2020-03-27T16:43:09.823Z","time":"22:18"},{"message":"closed chrome","status":"endTime","created_at":"2020-03-27T16:40:56.974Z","time":"22:19"},{"message":"opened chrome","status":"startTime","created_at":"2020-03-27T16:43:09.936Z","time":"22:20"},{"message":"closed dropbox","status":"endTime","created_at":"2020-03-27T16:40:57.076Z","time":"22:20"},{"message":"closed chrome","status":"endTime","created_at":"2020-03-27T16:40:57.062Z","time":"22:21"},{"message":"logged Out","status":"logoutTime","created_at":"2020-03-27T16:59:01.552Z","time":"22:29"}]}
      dispatch(setCourseAnalyticsElements('userSlotUsageHistory',userSlotUsageHistory));
      dispatch(dashboardActions.setBooleanValue("userSlotUsageHistoryLoader",false));
   }
}

// course usage

  export const fetchAllCourse=()=>{
   return async(dispatch, getState)=> {
      var endDate = new Date();
      var dateInSec = endDate.getTime();
      var d = dateInSec-6.048e+8;
      var fromDateInSec = new Date(d);
      var tempDate = fromDateInSec.getDate();
      var tempMonth = fromDateInSec.getMonth()+1;
      var tempYear = fromDateInSec.toString().substring(11,15);
      var fromDate = tempMonth + "/" + tempDate + "/" + tempYear;   // date conversion dd/mm/yyyy
      dispatch(setCourseAnalyticsElements("endDate",endDate));
      dispatch(setCourseAnalyticsElements("startDate",new Date(fromDate)));      
      const demoCoursesArray =JSON.parse(JSON.stringify(getState().courseAnalyticsState.demoCoursesArray));
      const demoOrActualValue =JSON.parse(JSON.stringify(getState().courseAnalyticsState.selectedType));
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    if(demoOrActualValue==="demo"){
      dispatch(setCourseAnalyticsElements("totalCourses",demoCoursesArray));
      dispatch(setCourseAnalyticsElements("bookedAndUsedSlotSelectedCourse",demoCoursesArray[0]));
       //call graph func
       dispatch(getCourseUsage())
    }else{
      try {
         let response = await customHttp.get(`/api/course/allCourses/user/${userDetails._id}`);
         
         if(response.data.message && response.data.message.length>0){
            var courses=response.data.message.reverse();
            dispatch(setCourseAnalyticsElements("totalCourses",courses));
            dispatch(setCourseAnalyticsElements("bookedAndUsedSlotSelectedCourse",courses[0]));
            //call graph func
            dispatch(getCourseUsage())
            dispatch(getUsersByCourse(courses[0]))
         }else{
            dispatch(setCourseAnalyticsElements("totalCourses",courses));
            dispatch(setCourseAnalyticsElements("bookedAndUsedSlotSelectedCourse",{}));
            //call no data found
            dispatch(setCourseAnalyticsElements("bookedAndUsedSlots",{}));  
            dispatch(setCourseAnalyticsElements("noDataAvailable",false));   
         }
      }catch (err) {
         console.log("erroror",err);
         var obj={};
         obj.bookedSlots=0;
         obj.usedSlots=0
         obj.details=[];
         dispatch(setCourseAnalyticsElements("totalCourses",[]));
         dispatch(setCourseAnalyticsElements("bookedAndUsedSlotSelectedCourse",{}));
         dispatch(setCourseAnalyticsElements("bookedAndUsedSlots",obj));  
         dispatch(setCourseAnalyticsElements("noDataAvailable",false)); 
         //call no data found
      }
   }

   }}
   export const handleChangeStart=(sdate)=>{
      return async(dispatch, getState)=> {
         dispatch(setCourseAnalyticsElements("startDate",sdate));
         // graph func
         dispatch(getCourseUsage())
      }
   }

    export const handleChangeCourse=(e)=>{
      return async(dispatch, getState)=> {
         var course=JSON.parse(e.target.value)
         dispatch(setCourseAnalyticsElements("bookedAndUsedSlotSelectedCourse",course));
         // graph func
         dispatch(getCourseUsage())
         dispatch(getUsersByCourse(JSON.parse(e.target.value)))
      }
   }

   export const handleChangeEnd=(edate)=>{
      return async(dispatch, getState)=> {
         dispatch(setCourseAnalyticsElements("endDate",edate));
         // graph func
         dispatch(getCourseUsage())
      }
   }

   export const changeDemoToActual=(obj)=>{
      return async(dispatch, getState)=> {
         dispatch(setCourseAnalyticsElements("selectedType",obj));
         dispatch(fetchAllCourse())
      }
   }

   //Date conversion function start.
  var convertDate=(date)=>{
      return format(new Date(date),'MMM')+" "+format(new Date(date),'d')
   }
   //Date conversion function end.

   // used for get date range
   var getDates = function(startDate, endDate) {
      var dates = [],
         currentDate = startDate,
         addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
         };
      while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
      }
      return dates;
   };
 
   export const getCourseUsage=()=>{
      return async(dispatch, getState)=> {
         // console.log("graph")
         var demoBookedAndUsedSlots=[{date: "2020-03-05",bookedSlotCount: 12,logonCount:0},
         {date: "2020-03-06",bookedSlotCount: 14,logonCount:11},
         {date: "2020-03-07",bookedSlotCount: 33,logonCount:6},
         {date: "2020-03-08",bookedSlotCount: 10,logonCount:5},
         {date: "2020-03-09",bookedSlotCount: 8,logonCount:2},
         {date: "2020-03-10",bookedSlotCount: 15,logonCount:11},
         {date: "2020-03-11",bookedSlotCount: 11,logonCount:4}]
         var n = new Date(getState().courseAnalyticsState.startDate);
         var currentDate = n.getDate();
         if(currentDate<10){
           currentDate='0'+currentDate;
         }
         var currentMonth = n.getMonth() + 1; //Months are zero based
         if(currentMonth<10){
           currentMonth='0'+currentMonth;
         }
         var currentYear = n.getFullYear();
         var fromDate = currentYear + "-" + currentMonth + "-" + currentDate;  // date conversion dd/mm/yyyy to yyyy-mm-dd
    
         var p = new Date(getState().courseAnalyticsState.endDate);
         var tempDate = p.getDate();
         if(tempDate<10){
           tempDate='0'+tempDate;
         }
         var tempMonth = p.getMonth() + 1;
         if(tempMonth<10){
           tempMonth='0'+tempMonth;
         }
         var tempYear = p.getFullYear();
         var toDate = tempYear + "-" + tempMonth + "-" + tempDate; 
         const demoOrActualValue =JSON.parse(JSON.stringify(getState().courseAnalyticsState.selectedType));
         if(demoOrActualValue==="demo"){
            var obj={};
            obj.bookedSlots=103;
            obj.usedSlots=39
            obj.details=demoBookedAndUsedSlots;
            var series=JSON.parse(JSON.stringify(getState().courseAnalyticsState.series));
            var options=JSON.parse(JSON.stringify(getState().courseAnalyticsState.options));
            var bookedData=[];
            var  usedData=[];
            var labels=[];
            var sevenDaysBack = new Date(); //dynamically get last 7 days
           sevenDaysBack.setDate(sevenDaysBack.getDate() - 6);
           var dates = getDates(new Date(sevenDaysBack),new Date());
           dates.forEach(function(date) {
              labels.push(convertDate(date))
           });
     
            for(var i=0;i<demoBookedAndUsedSlots.length;i++){
             bookedData.push(demoBookedAndUsedSlots[i].bookedSlotCount)
             usedData.push(demoBookedAndUsedSlots[i].logonCount)
             //labels.push(this.convertDate(demoBookedAndUsedSlots[i].date))
             if(i===demoBookedAndUsedSlots.length-1){
               series[0].data=bookedData;
               series[1].data=usedData;
               //options.xaxis.categories=labels;
               options.xaxis.categories=labels;
               options.xaxis.type='category';
               dispatch(setCourseAnalyticsElements("initialLoaderForBookedAndUsedSlots",false));
               dispatch(setCourseAnalyticsElements("bookedAndUsedSlots",obj));
               dispatch(setCourseAnalyticsElements("options",options));
               dispatch(setCourseAnalyticsElements("series",series));  
               dispatch(setCourseAnalyticsElements("noDataAvailable",true)); 
               
             }
           }
         }else{
            try {
               const selectedCourse =JSON.parse(JSON.stringify(getState().courseAnalyticsState.bookedAndUsedSlotSelectedCourse));            
               let response = await customHttp.get(`/api/netUtilization/courseAnalytics/fetchUsageDetails/fromDate/${fromDate}/toDate/${toDate}/courseId/${selectedCourse._id}`);
               // console.log("graph data",response.data.message)
               if(response.data.message !== "No Records Found!"){
                  var series=JSON.parse(JSON.stringify(getState().courseAnalyticsState.series));
                  var options=JSON.parse(JSON.stringify(getState().courseAnalyticsState.options));
                  dispatch(setCourseAnalyticsElements("initialLoaderForBookedAndUsedSlots",false));
                  dispatch(setCourseAnalyticsElements("bookedAndUsedSlots",response.data.message));  
                  var bookedData=[];
                   var  usedData=[];
                   var labels=[];
                   if(response.data.message.bookedSlots>0) {
                    for(var i=0;i<response.data.message.details.length;i++){
                      bookedData.push(parseFloat(response.data.message.details[i].bookedSlotCount) || 0)
                      usedData.push(parseFloat(response.data.message.details[i].logonCount) || 0)
                      labels.push(convertDate(response.data.message.details[i].date))
                      if(i===response.data.message.details.length-1){
                        // console.log(labels,usedData,bookedData,i,)
                        series[0].data=bookedData;
                        series[1].data=usedData;

                        options.xaxis.categories=labels;
                        options.xaxis.type='category';
                        dispatch(setCourseAnalyticsElements("options",options));
                        dispatch(setCourseAnalyticsElements("series",series));  
                        dispatch(setCourseAnalyticsElements("noDataAvailable",true)); 
                        //console.log("options value1",JSON.parse(JSON.stringify(getState().courseAnalyticsState.series)),JSON.parse(JSON.stringify(getState().courseAnalyticsState.options)))
                      }
                    }
                   }else{
                     // console.log("options value",JSON.parse(JSON.stringify(getState().courseAnalyticsState.series)),JSON.parse(JSON.stringify(getState().courseAnalyticsState.options)))
                     dispatch(setCourseAnalyticsElements("noDataAvailable",false)); 
                   }
               }else{
                  var obj={};
                  obj.bookedSlots=0;
                  obj.usedSlots=0
                  obj.details=[];
                  dispatch(setCourseAnalyticsElements("initialLoaderForBookedAndUsedSlots",false));
                  dispatch(setCourseAnalyticsElements("bookedAndUsedSlots",obj));  
                  dispatch(setCourseAnalyticsElements("noDataAvailable",false));                   
               }
            }catch (err) {
               console.log("erroror",err);
               var obj={};
               obj.bookedSlots=0;
               obj.usedSlots=0
               obj.details=[];
               dispatch(setCourseAnalyticsElements("initialLoaderForBookedAndUsedSlots",false));
               dispatch(setCourseAnalyticsElements("bookedAndUsedSlots",obj));  
               dispatch(setCourseAnalyticsElements("noDataAvailable",false));  
               
            }
         }

      }
   }

   export const showRatings=(slot)=>{
      return (dispatch)=> {  
         document.getElementById('ratingsPopup').style.display='block';
         const dataType = localStorage.getItem('selectedDemoOrActual');
         //console.log("dataType",dataType,slot)
         if(dataType && dataType==='"demo"'){
            dispatch(getUserRatingyDemo(slot));
         } else {
            dispatch(getUserRatingyActual(slot));
         }
      }
   }

   export const getUserRatingyDemo=()=>{
      return async(dispatch)=> {
         dispatch(dashboardActions.setBooleanValue("userSlotRatingLoader",true));
         const userSlotRating = {"ratedAt":"2020-04-07T10:40:59.457Z","rating":"3","comment":"Classes are very good.It helped a lot.","email":"example@gmail.com","username":"User"}
         dispatch(setCourseAnalyticsElements('userSlotRating',userSlotRating));
         dispatch(dashboardActions.setBooleanValue("userSlotRatingLoader",false));
      }
   }

   export const getUserRatingyActual=(slot)=>{
      return async(dispatch, getState)=> {
         const userId = slot.userId;
         const date = slot.date;
         const bookingTime = slot.bookingTime; 
         let userSlotRating = {};
         dispatch(dashboardActions.setBooleanValue("userSlotRatingLoader",true));
         try {
            //console.log("dataaaa",slot)
            let response = await customHttp.get(`/api/starRating/slotRatings/userId/${userId}/bookingDate/${date}/bookingTime/${bookingTime}`);
            //console.log("response",response.data.message)
             dispatch(dashboardActions.setBooleanValue("userSlotRatingLoader",false));
             if(response.data.message && response.data.message.rating){
               userSlotRating = response.data.message;
               dispatch(setCourseAnalyticsElements('userSlotRating',userSlotRating));
               dispatch(dashboardActions.setBooleanValue("userSlotRatingLoader",false)); 
               //console.log("userSlotRating",response.data.message)
            }else{
               var userSlotRatingHistory = {ratedAt:"",rating:0,comment:"",email:"",username:""}
               dispatch(setCourseAnalyticsElements('userSlotRating',userSlotRatingHistory));
               dispatch(dashboardActions.setBooleanValue("userSlotRatingLoader",false)); 
            }
         } catch (err) {
            //console.log("erroror",err);
            var userSlotRatingHistory = {ratedAt:"",rating:0,comment:"",email:"",username:""}
            dispatch(setCourseAnalyticsElements('userSlotRating',userSlotRatingHistory)); 
            dispatch(dashboardActions.setBooleanValue("userSlotRatingLoader",false));
         }
      }
   }

   // export const fetchAllCourse=()=>{
   //    return async(dispatch, getState)=> {

   //    }
   // }
