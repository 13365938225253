import React, { useState, useEffect } from 'react';
import "./DmzRules.css";
import PortRules from "./PortRules";
import IpRules from './IpRules';
import UrlRules from './UrlRules'
function DmzRules(){
  //var appLanguage=JSON.parse(localStorage.getItem("appLanguage"));
  const [selectedRule, setSelectedRule] = useState('port');
  // onload function
  useEffect( () => {
    
  }, []);
  
  var selectRule = (type) => {
    setSelectedRule(type);
  }
  return (
    <div className="">
      <div className="dmz-tab-button-wrapper">
        <button type="button" className={"dmz-tab-button "+(selectedRule==="port"?"dmz-tab-button-selected":"")} onClick={()=>selectRule('port')}>Port</button>
        <button type="button" className={"dmz-tab-button "+(selectedRule==="ip"?"dmz-tab-button-selected":"")} onClick={()=>selectRule('ip')}>IP</button>
        <button type="button" className={"dmz-tab-button "+(selectedRule==="url"?"dmz-tab-button-selected":"")} onClick={()=>selectRule('url')}>URL</button>
      </div>
      { selectedRule==="port" && <div className="animated-dmz-rules"><PortRules /></div>}
      { selectedRule==="ip" && <div className="animated-dmz-rules"><IpRules /></div>}
      { selectedRule==="url" && <div className="animated-dmz-rules"><UrlRules /></div>}
    </div>
  );
}
export default DmzRules;

