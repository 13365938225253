import React, {Component} from "react";
import "./dltSidebar.scss";
import {NavLink} from 'react-router-dom';
class App extends Component {
  triggerRolesPage=()=>{
    sessionStorage.setItem("fromAppStore",false);
  }
  render() {
    return (
      <div className="vs-sidebar-parent">
        <div className="vs-sidebar">
          {/**/}
          <div className="vs-sidebar--items">
            <div>
              <div
                slot="header"
                className="justify-between"
                style={{textAlign:"center",padding:"15px 19px 8px 0px",width:"100%"}}
              >
                <div className="logo  items-center">
                  <img
                    src="/img/logos/platifilogo.png"
                    alt="logo"
                    className="font_family_montserrat"
                    style={{width: "130px"}}
                  />
                  {/* <span style={{fontWeight:"500",paddingLeft:"5px",fontSize:"28px",verticalAlign:"middle"}}>  iDAMS</span> */}
                </div>
              </div>
              <section
                className="ps-container scroll-area--main-sidebar pt-2 ps ps--theme_default ps--active-y"
                data-ps-id="21dec5ac-6c85-8822-e452-d02216d027d2"
              >
                {/* <div className="vs-sidebar--item">
                  <NavLink
                    className="sidebar-item"
                    activeClassName="selected-tab"
                    to="/dlt/dashboard"
                    // target="_self"
                    style={{cursor:"pointer"}}
                  >
                    <span className="feather-icon ab select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-home"
                      >
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                        <polyline points="9 22 9 12 15 12 15 22" />
                      </svg>
                    </span>
                    <span className="truncate" style={{fontSize:13,marginRight:3}}>Dashboard </span>
                    <span style={{fontSize:9,backgroundColor:'#9082f2',color:'#fff',borderRadius:3,padding:2}}>coming soon</span> 
                    
                  </NavLink>
                </div>
                <span className="navigation-header truncate">Pages</span> */}

                <div className="vs-sidebar--item">
                  <NavLink
                    to="/dlt/resources"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-layout"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          ry="2"
                        />
                        <line x1="3" y1="9" x2="21" y2="9" />
                        <line x1="9" y1="21" x2="9" y2="9" />
                      </svg>
                    </span>
                    <span className="truncate" title="Resources">Resources</span>
                  </NavLink>
                </div>
                {/* <div className="vs-sidebar--item">
                  <NavLink
                    to="/dlt/appstore"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-copy"
                      >
                        <rect
                          x={9}
                          y={9}
                          width={13}
                          height={13}
                          rx={2}
                          ry={2}
                        />
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                      </svg>
                    </span>
                    <span className="truncate" title="App Store">App Store</span>

                  </NavLink>
                </div> */}
                {/* <div className="vs-sidebar--item">
                  <NavLink
                    to="/dlt/courses"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-book">
                      <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                      <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
                      </svg>
                    </span>
                    <span className="truncate" title="Courses">Courses</span>
                  </NavLink>
                </div> */}
                {/* <div className="vs-sidebar--item">
                  <NavLink
                    to="/dlt/users"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    </span>
                    <span className="truncate" title="Users">Users</span>
                  </NavLink>
                </div> */}

                {/* <div className="vs-sidebar--item">
                <NavLink
                  to="/dlt/sme-users"
                  className="sidebar-item"
                  activeClassName="selected-tab"
                >
                 <span className="feather-icon select-none relative">
                  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1">
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                  </span>
                  <span className="truncate" title="SMEs">SMEs</span>
                </NavLink>
              </div> */}
              {/* <div className="vs-sidebar--item">
                  <NavLink
                    to="/dlt/course-analytics"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bar-chart-2">
                      <line x1="18" y1="20" x2="18" y2="10"/>
                      <line x1="12" y1="20" x2="12" y2="4"/>
                      <line x1="6" y1="20" x2="6" y2="14"/>
                    </svg>
                    </span>
                    <span className="truncate" title="Course Analytics">Course Analytics</span>
                  </NavLink>
                </div>  */}
              {/* <div className="vs-sidebar--item">
                <NavLink
                  to="/dlt/analytics"
                  className="sidebar-item"
                  activeClassName="selected-tab"
                >
                  <span className="feather-icon select-none relative">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bar-chart-2">
                    <line x1="18" y1="20" x2="18" y2="10"/>
                    <line x1="12" y1="20" x2="12" y2="4"/>
                    <line x1="6" y1="20" x2="6" y2="14"/>
                  </svg>
                  </span>
                  <span className="truncate" title="Analytics">Analytics</span>
                </NavLink>
              </div> */}

                <div className="vs-sidebar--item">
                  <NavLink
                    to="/dlt/dltrequested-content"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                    strokeWidth="2" fill="none" strokeLinecap="round"
                    strokeLinejoin="round" className="css-i6dzq1">
                    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                    </svg></span>
                    <span className="truncate" title="Requested Content">Requested Content </span>
                  </NavLink>
                </div>
                {/* <div className="vs-sidebar--item">
                  <NavLink
                    to="/dlt/live-courses"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-video">
                        <polygon points="23 7 16 12 23 17 23 7" />
                        <rect x={1} y={5} width={15} height={14} rx={2} ry={2} />
                      </svg>
                    </span>
                    <span className="truncate" title="Live Classroom">Live Classroom</span>
                    </NavLink>
                  </div> */}

                {/*<div className="vs-sidebar--item">
                  <NavLink
                    className="sidebar-item"
                    activeClassName="selected-tab"
                    to="/dashboard"
                  >
                    <span
                      className="feather-icon select-none relative"
                      style={{ fontSize: "15px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-map-pin"
                      >
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                        <circle cx={12} cy={10} r={3} />
                      </svg>
                    </span>
                    <span className="truncate" title="Locations">Locations</span>
                    {/**/}
                  {/* </NavLink>
                </div> */}
                {/* <div className="vs-sidebar--item">
                  <NavLink
                    to="/dashboard"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-copy"
                      >
                        <rect
                          x={9}
                          y={9}
                          width={13}
                          height={13}
                          rx={2}
                          ry={2}
                        />
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                      </svg>
                    </span>
                    <span className="truncate" title="App Store">App Store</span>

                  </NavLink>
                </div> */}
                {/* <div className="vs-sidebar--item">
                  <NavLink
                    to="/dashboard"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                    onClick={this.triggerRolesPage}
                  >
                    <span className="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-layout"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          ry="2"
                        />
                        <line x1="3" y1="9" x2="21" y2="9" />
                        <line x1="9" y1="21" x2="9" y2="9" />
                      </svg>
                    </span>
                    <span className="truncate" title="Roles">Roles</span> */}
                    {/**/}
                  {/*</NavLink>
                </div>
                <div className="vs-sidebar--item">
                  <NavLink
                    to="/dashboard"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user-plus"
                      >
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                        <circle cx="8.5" cy={7} r={4} />
                        <line x1={20} y1={8} x2={20} y2={14} />
                        <line x1={23} y1={11} x2={17} y2={11} />
                      </svg>
                    </span>
                    <span className="truncate" title="Users">Users</span>
                  </NavLink>
                </div>*/}
              </section>
              {/**/}
              {/**/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
