import * as createPlaylistActionTypes from "../../../actionTypes";

export const setDisciplines = content => ({
  type: createPlaylistActionTypes.CREATE_PLAYLIST_DISCIPLINES,
  payload: {content}
});

export const setContentTypes = content => ({
  type: createPlaylistActionTypes.CREATE_PLAYLIST_CONTENT_TYPES,
  payload: {content}
});

export const setCreatePlaylistElements = (name,content )=> ({
  type: createPlaylistActionTypes.CREATE_PLAYLIST_COMPONENT_ELEMENTS,
  payload: {name,content}
})