import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import customHttp from "../../../packages/CustomHttp";

function IpRules() {
  var userData=JSON.parse(sessionStorage.getItem("userData"));
  const appLanguage = JSON.parse(localStorage.getItem("appLanguage"));
  const [ipRuleList, setIpRuleList] = useState([]);
  const [successAlertMessage, setSuccessAlertMessage] = useState('')
  const [errorAlertMessage, setErrorAlertMessage] = useState('')
  const ipRuleObj = {ip:'',priority:''}
  const [ipRule, setIpRule] = useState(ipRuleObj);
  const [submitRequestLoader, setSubmitRequestLoader] = useState(false)
  const [deleteIpLoader, setDeleteIpLoader] = useState(false)
  const [selectedIpToDelete, setSelectedUrlToDelete] = useState({});
  const [invalidPriority,setInvalidPriority] = useState(false);
  const [invalidIp, setInvalidIp] = useState(false);
  const [ipExists, setIPExists] = useState(false);
  const [priorityExists, setPriorityExists] = useState(false);

  useEffect( () => {
    getAllIpRules()
  }, []);

  var getAllIpRules = async () => {
    try {
      var getAllIpRulesObj = {};
      getAllIpRulesObj.url = '/api/dmz/ip/fetchAll';
      var response = await customHttp.get(getAllIpRulesObj.url)
      if(response.data.message!==undefined && response.data.message.length>0){
        let temp=response.data.message
        setIpRuleList(temp.reverse())
      } else {
        setIpRuleList([])
      }
    } catch (err){
      console.log("failed",err.response)
    }
  }

  var checkIsUnique = async (url) => {
    try {
      var checkIsUniqueObj = {};
      checkIsUniqueObj.url = '/api/dmz/ip/checkRoleExist/'+url;
      var response = await customHttp.get(checkIsUniqueObj.url)
      if(response.data.message){
        setIPExists(response.data.message.dataExist)
      } else {
        setIPExists(false)
      }
    } catch (err){
      console.log("failed",err.response)
    }
  }

  var checkIsPriorityUnique = async (priority) => {
    try {
      var checkIsPriorityUniqueObj = {};
      checkIsPriorityUniqueObj.url = '/api/dmz/ip/checkPriorityExist/'+priority;
      var response = await customHttp.get(checkIsPriorityUniqueObj.url)
      if(response.data.message){
        setPriorityExists(response.data.message.priorityExist)
      } else {
        setPriorityExists(false)
      }
    } catch (err){
      console.log("failed",err.response)
    }
  }

  var handleChange = (e) => {
    setIpRule({...ipRule,[e.target.name]:e.target.value})
    if(e.target.name==='ip'){
      setInvalidIp(false);
      checkIsUnique(e.target.value)
    } else if(e.target.name==='priority'){
      checkIsPriorityUnique(e.target.value);
      setInvalidPriority(false)
    }
  }

  var checkIsValidIp = (ip) => {
    const octet = '(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)';
    const regex = new RegExp(`^${octet}\\.${octet}\\.${octet}\\.${octet}$`);
    return regex.test(ip);
  }
  var handleBlur = (e) => {
    if(e.target.name==='ip' && e.target.value.length>0){
      setInvalidIp(!checkIsValidIp(e.target.value))
    } else if (e.target.name==='priority' && e.target.value.length>0 && (e.target.value<100 || e.target.value>65500)) {
      setInvalidPriority(true)
    }
  }

  var handleSubmit = async (e) => {
    e.preventDefault();
    setInvalidIp(!checkIsValidIp(ipRule.ip));
    var invalidPriorityData = false
    if ((ipRule.priority<100 || ipRule.priority>65500)) {
      invalidPriorityData = true
    }
    var handleSubmitObj = {};
    handleSubmitObj.url = '/api/dmz/ip/add/';
    handleSubmitObj.data = {};
    handleSubmitObj.data.data = ipRule.ip;
    handleSubmitObj.data.priority = ipRule.priority;
    handleSubmitObj.data.userId = userData._id;
    if(!invalidPriorityData && !invalidIp && !invalidPriority && !ipExists && !priorityExists && handleSubmitObj.data.data!=='' && handleSubmitObj.data.data!==undefined && checkIsValidIp(handleSubmitObj.data.data) && handleSubmitObj.data.priority!=='' && handleSubmitObj.data.priority!==undefined && handleSubmitObj.data.userId!=='' && handleSubmitObj.data.userId!==undefined){
      setSubmitRequestLoader(true)
      try {
        var response = await customHttp.post(handleSubmitObj.url,handleSubmitObj.data)
        setSubmitRequestLoader(false)
        myFunction();
        setSuccessAlertMessage("IP Updated Successfully!");
        setIpRule(ipRuleObj)
        getAllIpRules()
      } catch (err){
        setErrorAlertMessage("Error try again!");
        myFunction1()
        setSubmitRequestLoader(false);
        console.log("failed",err.response)
      }
    }
  }

  var deleteIpRule = async (ip) => {
    setSelectedUrlToDelete(ip)
    try {
      var deleteIpRuleObj = {};
      deleteIpRuleObj.url = '/api/dmz/ip/remove/';
      deleteIpRuleObj.data = {};
      deleteIpRuleObj.data.data = ip.data;
      deleteIpRuleObj.data.priority = ip.priority
      setDeleteIpLoader(true)
      var response = await customHttp.delete(deleteIpRuleObj.url,deleteIpRuleObj.data)
      setDeleteIpLoader(false)
      setSuccessAlertMessage("IP Deleted Successfully!");
      myFunction();
      getAllIpRules()
    } catch (err){
      setErrorAlertMessage("Error try again!");
      myFunction1()
      setDeleteIpLoader(false);
      console.log("failed",err.response)
    }
  }

  var myFunction=()=> {
    var x = document.getElementById("snackbar")
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", "")}, 3000);
  }

  var myFunction1=()=> {
    var x = document.getElementById("snackbar1")
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }

  return (
    <div>
      <div id="snackbar">{successAlertMessage}</div>
      <div id="snackbar1"><i className="fa fa-exclamation"></i>{errorAlertMessage}</div>
      <div className="col-md-8">
      <div className="col-md-12 nj-card" style={{overflow:'auto',margin:'10px 0',maxHeight:'78vh'}}>
        <div className="row">
          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4>Blocked IPs</h4>
            </div>
          </div>
          {ipRuleList.length>0 ? <div className="nj-table--tbody">
            <table className="nj-table nj-table--tbody-table font_family_montserrat">
              <thead className="nj-table--thead">
                <tr>
                  <th><div className="nj-table-text">SL.NO</div></th>
                  <th><div className="nj-table-text">IP</div></th>
                  <th><div className="nj-table-text">PRIORITY</div></th>
                  <th><div className="nj-table-text">DELETE</div></th>
                </tr>
              </thead>
              <tbody>
                {ipRuleList.map((ip,index)=>{
                  return (<tr key={index} className={"tr-values nj-table--tr tr-table-state-null"}>
                    <td className="td nj-table--td">{index+1}</td>
                    <td className="td nj-table--td">{ip.data}</td>
                    <td className="td nj-table--td">{ip.priority || '--'}</td>
                    <td className="td nj-table--td">
                      {(selectedIpToDelete._id===ip._id) && deleteIpLoader ? <img src={"./img/loaders/spinningwheel.gif"} height={60} width={65} alt="loader" />
                      :<button type="button" className="nj-flow-button bg-color-red" disabled={deleteIpLoader} onClick={()=>deleteIpRule(ip)}>Delete</button>}
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>
          :
          <div className="col-md-12" style={{marginTop:"15vh",height:"20vh"}}>
            <p className="font_family_montserrat" style={{float:"none",textAlign:"center",fontSize:"18px",marginTop:"0px"}}>No rule found</p>
          </div>
          } 
        </div>
      </div>
      </div>
      <div className="col-md-4">
        <div className="row nj-card nj-cp-request-app-card" style={{marginTop:10}}>
          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4>Block IPs</h4>
            </div>
          </div>
          <form name="requestForm" onSubmit={handleSubmit}>
            <div className="nj-popup-form-wrap">
              <p className="popup-content-label">IP</p>
              <input className="vs-input--input" type="text" name="ip" value={ipRule.ip} onChange={handleChange} autoComplete="off" placeholder="Ex: 106.51.37.53" onBlur={handleBlur} required />
              {invalidIp && <span className="color-red" style={{color:"red"}}>Invalid IP!</span>}
              {ipExists && <span className="color-red" style={{color:"red"}}>IP already exists</span>}
            </div>
            <div className="nj-popup-form-wrap">
              <p className="popup-content-label">Priority</p>
              <input className="vs-input--input" type="text" name="priority" value={ipRule.priority} onChange={handleChange} onBlur={handleBlur} autoComplete="off" placeholder="Ex: 1000" required />
              {invalidPriority && <span className="color-red" style={{color:"red"}}>Invalid priority!</span>}
              {priorityExists && <span className="color-red" style={{color:"red"}}>Priority already exists</span>}
            </div>
            <div style={{margin:'0 10px 10px 10px',display:'flex',justifyContent:'flex-end'}}>
              {!submitRequestLoader ? <button type="submit" className="nj-flow-button  pull-right"  style={{margin:5}}>Submit</button>:<img  className="pull-right" src={"./img/loaders/spinningwheel.gif"} height={60} width={65} alt="loader" />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default IpRules;
