import * as userProfileActionTypes from "../../../actionTypes";
const initialState = {
  errorAlertMessage:'',
  successAlertMessage:'',
  profileData:{firstname:'',lastname:'',username:'',phoneNumber:'',location:'',postalCode:'',domainName:''},
  imageLoader:false,
  submitLoader:false,
  profilePicture:{},
  isProfileDataAdded: false,
  isEditable:true,
  profilePictureColor:'',
  profileUsername:true,
  showErrorMessage:false,
  userProfileLoader:false,
  userNameExist:false,
  usernameMustbeAlphnumeric:false,
}

export default function(state=initialState, action) {
  switch (action.type) {
    case userProfileActionTypes.USER_PROFILE_COMPONENT_ELEMENTS: {
      const {name,content } = action.payload;
      return {
        ...state,[name]:content
      }
    }
    case userProfileActionTypes.USERPROFILE_SET_BOOLEAN_VALUE: {
      const {name,value} = action.payload; 
      return {
        ...state,[name]:value
      }
    }
    default:
      return state;
  }
}
