import axios from 'axios';
const headers = '';
var customHttpUserside = {
    get : async (url,data) => {
        const body = data || {};
        try {
            var response = await axios.get(url,body);
            return response;
        } catch (err){
            throw err
        }
    },
    post : async (url,data) => {
        const body = data || {};
        try {
            var response = await axios.post(url,body,{headers: { "Content-Type": undefined }});
            return response;
        } catch (err){
            throw err
        }
    },
    put : async (url,data) => {
        const body = data || {};
        try {
            var response = await axios.put(url,body);
            return response;
        } catch (err){
            throw err
        }
    },
    delete : async (url,data) => {
        const body = data || {};
        try {
            var response = await axios.delete(url,{data:body});
            return response;
        } catch (err){
            throw err
        }
    }
}

export default customHttpUserside;
