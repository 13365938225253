
import React, {useEffect ,useState } from 'react';
import { connect } from "react-redux";
import * as SettingsContainer from "../../container/actions/Settings/action";
import { bindActionCreators } from 'redux';
import * as Actions from "../../container/actions/Settings/index";
import DatePicker from '../DatePicker.js';
import * as CourseContainer from "../../container/actions/Courses/action";
import './Settings.scss';


function Settings (props) {

  const [indexValue, setIndexValue] = useState("");
  const [minDateForCourse, setMinDateForCourse] = useState("");
  const [selectedCourse, setSelectedCourse] = useState({});
 
  useEffect( () => {   
    props.getAllCreatedCourses();
    props.fetchAllSubscriptions();
    props.fetchAllConcurrentuser();
  },[]);

   var showCoursePopUp=(i,data)=>{
        let endDate = new Date();
        var startdate;
        for(var k=0;k<props.courseState.availableCourses.length;k++){
          if(props.courseState.availableCourses[k]._id===data.courseId){
            startdate = new Date(props.courseState.availableCourses[k] !== undefined && props.courseState.availableCourses[k].startDate); 
            setSelectedCourse(props.courseState.availableCourses[k])
          }
        }

     //console.log("all coursee",props.courseState.availableCourses,props.courseState.availableCourses,data)
            setIndexValue(i)
            //let startdate = new Date(props.courseState.availableCourses[i] !== undefined && props.courseState.availableCourses[i].startDate);           
            if (startdate > endDate){
              setMinDateForCourse(startdate)
            }
            else{
              setMinDateForCourse(endDate)
            }
            document.getElementById("editCoursePopup").style.display='block';
            props.initiateOnloadFunction("selectedapplicationData",data)
            props.initiateOnloadFunction("applicationLimit",data.subscriptionLimit)
          }
        
    var hideCoursePopUp=()=>{
            document.getElementById("editCoursePopup").style.display='none'
            props.initiateOnloadFunction("fromDate","")
            props.initiateOnloadFunction("toDate","")
      }
    
    var handleChange=(e)=>{
        props.initiateOnloadFunction(e.target.name,e.target.value)
      }
    
    var getSelectedFromDate=(fromDate)=>{
        props.initiateOnloadFunction("fromDate",fromDate)
      }
    
     var  getSelectedToDate=(toDate)=>{
       props.initiateOnloadFunction("toDate",toDate)
      }
      
     var enableenableSlotInputfield=(i,data)=>{
         if(props.settingsState.editOpen===false){
          props.setBooleanValue("editOpen",true)
          props.setBooleanValue("showEditButton",false)
          props.initiateOnloadFunction("slots",data)
          document.getElementById("edit"+i).style.display = "none"
          document.getElementById("save"+i).style.display = "inline-block"
          document.getElementById("editInputField"+i).style.display = "none"
          document.getElementById("saveInputField"+i).style.display = "inline-block"
         }
         else{
          props.setBooleanValue("editOpen",true)
         }
      }

      var showCoursesPage=()=>{
        props.history.push("/sub-admin/courses")
        props.setBooleanValue("editOpen",false)

      }

      var handleKeyPress=(i,e,data)=>{
        if (e.keyCode === 13) {
          props.submitSlots(i,e,data)
         }
      }
  
 
    return (
      <div className="add-on-load-animation">
        <div id="successSnackbar">{props.settingsState.successSnackbarMessage}</div>
        <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.settingsState.errorSnackbarMessage}</div>
      <div className="col-md-12">
        <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
        <div className="col-md-12 col-sm-12 nj-card" >
          <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
            <div className="nj-card__header">
              <div className="nj-card__title">
                <h4 className="font_family_montserrat">Settings</h4>
              </div>
                <div style={{paddingBottom:'9px'}}>
                      <button  type="button"  className="nj-flow-button pull-right" onClick={showCoursesPage} style={{float:"none",marginLeft:"5px"}}>Back</button>
               </div>
            </div>
          </div>
          <div className="col-md-12">
            {props.settingsState.availableSubscriptionLoader?
            <div className="course-table-margin-top">
              
              { props.settingsState.subscriptionList!== undefined && props.settingsState.subscriptionList.length > 0 && props.settingsState.concurrentUserList !== undefined && props.settingsState.concurrentUserList.length >0?
              <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"76.3vh"}}>
                  
                <table className="nj-table nj-table--tbody-table font_family_montserrat">
                  <thead className="nj-table--thead">
                    <tr>
                      <th>
                        <div className="nj-table-text" style={{minWidth:"60px"}}>SL NO.</div>
                      </th>
                      <th>
                        <div className="nj-table-text" style={{minWidth:"180px"}}>COURSE NAME</div>
                      </th>
                      <th>
                        <div className="nj-table-text" style={{minWidth:"146px"}}>DEFAULT LIMIT</div>
                      </th>
                      <th>
                        <div className="nj-table-text" style={{minWidth:"270px"}}>DATE RANGE</div>
                      </th> 
                      <th>
                        <div className="nj-table-text" style={{minWidth:"180px"}}>CONCURRENT USERS</div>
                      </th>
                      <th>
                        <div className="nj-table-text" style={{minWidth:"180px"}}>SLOTS / USER
                        <span title="Slots / user must be less than 100" flow="left" className="pull-right" style={{cursor:"pointer",color:"#8f82f2",fontSize:"13px"}}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-info" style={{marginRight:"2px",marginBottom:"4px"}}>
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                <line x1="12" y1="8" x2="12" y2="8"></line>
                              </svg>
                           </span>
                           </div>
                       
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                   
                            {props.settingsState.subscriptionList.map((data,i)=>{
                              return (<tr key={i}  className="tr-values nj-table--tr tr-table-state-null ">
                                <td className="td nj-table--td">{i+1}</td>
                                <td className="td nj-table--td">{data.subscription}</td>
                                <td className="td nj-table--td">{data.defaultLimit}</td>
                                {data.fromDate === undefined && data.toDate === undefined ?
                                <td className="td nj-table--td" style={{color:"red"}}>Please add the date</td>:
                                <td className="td nj-table--td" >{data.fromDate.toString().substring(0,10)} {"To"} {data.toDate.toString().substring(0,10)}</td>
                                }
                               
                                <td className="td nj-table--td">
                                
                                <input type="text" 
                                        className="vs-input--input " 
                                        placeholder="" 
                                        style={{height:"35px",width:"25%",background:"white",fontWeight:500}}
                                        autoComplete="off" 
                                        name="concurrentusers"
                                        maxLength="2"
                                        value={data.subscriptionLimit}
                                        disabled={true}
                                        required
                                        />
                                <button  type="button"  className="nj-flow-button" style={{float:"none",marginLeft:"5px"}} onClick={()=>showCoursePopUp(i,data)}>Edit</button>
                                </td>
                             
                                <td className="td nj-table--td">
                                { props.settingsState.concurrentUserList!== undefined && props.settingsState.concurrentUserList.length>0 && 
                                       <input type="text" 
                                        className="edit vs-input--input " 
                                        placeholder="" 
                                        style={{height:"35px",width:"25%",background:"white",fontWeight:500}}
                                        autoComplete="off" 
                                        name="slots"
                                        maxLength="2"
                                        value={props.settingsState.concurrentUserList[i]!==undefined && props.settingsState.concurrentUserList[i].subscriptionLimit}
                                        disabled={true}
                                        id={"editInputField"+i}
                                        onMouseDown={(e)=>props.submitSlots(i,e,data)}
                                        required
                                        />}
                                      
                                       
                                        {props.settingsState.concurrentUserList!== undefined && props.settingsState.concurrentUserList.length>0 &&
                                        <input type="text" 
                                        className="save vs-input--input " 
                                        placeholder="" 
                                        style={{height:"35px",width:"25%"}}
                                        autoComplete="off" 
                                        name="slots"
                                        maxLength="2"
                                        id={"saveInputField"+i}
                                        value={props.settingsState.slots}
                                        onChange={(e)=>{handleChange(e)}}
                                        onKeyDown={(e)=>handleKeyPress(i,e,data)}
                                        required
                                        />}
                                      
                                  <button  type="button"  className="nj-flow-button edit" id={"edit"+i} style={{float:"none",marginLeft:"5px"}}  onClick={()=>enableenableSlotInputfield(i,props.settingsState.concurrentUserList[i].subscriptionLimit)}>Edit</button> 
                                  <button  type="button"  className="nj-flow-button save" id={"save"+i} style={{float:"none",marginLeft:"5px"}}   onClick={(e)=>props.submitSlots(i,e,data)}>Save</button>  
                                </td>
                               </tr>)
                            })}
                          </tbody>
                
                </table>
            </div>
            :
            <div className="col-md-12 idams_noDataFound" style={{height:"76.3vh"}}>
              <span className="font_family_montserrat">No data found</span>
            </div>
              }
            </div>
            :
            <div className="row" style={{height:"76.3vh"}}>
              <div className="col-md-12 aligh-loader-image-center" >
                <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
              </div>
            </div>
            }
           </div>
          </div>
        </div>
       </div>
      </div>

        {/*popup to edit course*/}
        <div id="editCoursePopup" className={"nj-overly "+(props.settingsState.shouldPopupAnimate ? "add-rebound-animation" : "no-animation")}>
              <div className="popup-nj my-popup">
                <header className="popup-header">
                  <span>{props.settingsState.selectedapplicationData.subscription}</span>
                  <span style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null" onClick={hideCoursePopUp}>close</i></span>
                </header>
                <div className="popup-content">
                  <div className="col-md-12">
                    {Object.keys(selectedCourse).length>0 ?
                  <form  onSubmit={(e)=>props.submitConcurrentUserPopup(e)}>
                  <div className="row" style={{marginTop:'20px'}}>
                        <div className="col-md-12 col-sm-12 create_course_datepicker">
                          <label className="idams-label pull-left font_family_montserrat">From Date<span className="required_field_color_red">*</span></label>
                          <DatePicker dataPicker={"datepicker-calender"}
                          calendarClassName={"calender-design vs-input--input"}
                          minDate = {minDateForCourse}
                          maxDate={Object.keys(selectedCourse).length>0 && selectedCourse.endDate}
                          onChange={getSelectedFromDate}
                          value={props.settingsState.fromDate}
                          required={true}
                          />
                        </div>
                      </div>
                      <div className="row" style={{marginTop:'20px'}}>
                        <div className="col-md-12 col-sm-12 create_course_datepicker">
                          <label className="idams-label pull-left font_family_montserrat">To Date<span className="required_field_color_red">*</span></label>
                          <DatePicker dataPicker={"datepicker-calender"}
                          calendarClassName={"calender-design vs-input--input"}
                          minDate={props.settingsState.fromDate}
                          maxDate={Object.keys(selectedCourse).length>0 && selectedCourse.endDate}
                          onChange={getSelectedToDate}
                          value={props.settingsState.toDate}
                          required={true}
                          disabled={!props.settingsState.fromDate}
                          />
                        </div>
                      </div>
                        <div  className="row" style={{marginTop:'20px'}}>
                          <div className="col-md-12 col-sm-12">
                            <label className="idams-label pull-left">Application Limit </label>
                            <span title="Concurrent users must be less than 100" flow="left" className="pull-right" style={{cursor:"pointer",color:"#8f82f2",fontSize:"13px"}}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-info" style={{marginRight:"-1px",marginTop:"4px"}}>
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                <line x1="12" y1="8" x2="12" y2="8"></line>
                              </svg>
                           </span>
                           
                            <input type="text"
                              className="pull-left vs-input--input form-control"
                              name="applicationLimit"
                              value={props.settingsState.applicationLimit}
                              placeholder=""
                              autoComplete="off"
                              maxLength="2"
                              onChange={(e)=>{handleChange(e)}}
                            required />
                          </div>
                        </div>

                        <div className="row" style={{marginTop:'20px',marginBottom:'20px'}}>
                          <div className="col-md-12">
                            {props.settingsState.showConcurrentUserUpdateLoader ? <button type="submit" className="nj-flow-button pull-right" style={{margin:5}} >Save</button>:<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-13px"}} height={60} width={60} alt="loader" />}
                            <button type="button" className="nj-flow-button bg-color-red pull-right" onClick={hideCoursePopUp}  style={{margin:5}}>Cancel</button>
                          </div>
                        </div>

                      </form>
                      :''
                      }

                  </div>
                </div>
              </div>
            </div>

      </div>
    );
  }


const mapStateToProps = state => ({
  settingsState: state.settingsState,
  courseState: state.courseState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllCreatedCourses:CourseContainer.getAllCreatedCourses,
  fetchAllSubscriptions:SettingsContainer.fetchAllSubscriptions,
  fetchAllConcurrentuser:SettingsContainer.fetchAllConcurrentuser,
  submitConcurrentUserPopup:SettingsContainer.submitConcurrentUserPopup,
  submitSlots:SettingsContainer.submitSlots,
  setBooleanValue:Actions.setBooleanValue,
  initiateOnloadFunction:Actions.initiateOnloadFunction,
 
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(Settings);
