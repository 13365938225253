
// import axios from "axios";
import * as createPlaylistActions from "./index";
import snackbar from '../../../packages/Snackbar/';
import customHttp from '../../../packages/CustomHttp/';

export function getAllDisciplines(){
   return async (dispatch, getState)=> {
      try {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      //console.log("userDetails",userDetails.department)
      let response = await customHttp.get("/api/branch/");
      //let res = JSON.parse(JSON.stringify(response.data.message));
      let res = JSON.parse(JSON.stringify(userDetails.department));
      if(Array.isArray(userDetails.department)){
         dispatch(createPlaylistActions.setDisciplines(res.reverse()));
      } else {
         dispatch(createPlaylistActions.setDisciplines([]));
      }
      } catch (err) {
         dispatch(createPlaylistActions.setDisciplines([]));
         console.log("error",err);
      }
   }
}

export function getAllContentTypes(){
   return async (dispatch, getState)=> {
      try {
         let response = await customHttp.get("/api/category/");
         let res = JSON.parse(JSON.stringify(response.data.message));
         if(Array.isArray(response.data.message)){
            dispatch(createPlaylistActions.setContentTypes(res.reverse()));
         } else {
            dispatch(createPlaylistActions.setContentTypes([]));
         }
      } catch (err) {
         dispatch(createPlaylistActions.setContentTypes([]));
         console.log("error",err)
      }
   }
}

export function onLoadContent(){
   return async (dispatch, getState)=> {
      dispatch(getAllDisciplines())
      dispatch(getAllContentTypes());
      dispatch(setCreatePlaylistElements('playlistArray',[]))
      dispatch(addTitle())
   }
}

export function handleDropdownChange(e){
   return async (dispatch, getState)=> {
      dispatch(setCreatePlaylistElements(e.target.name,JSON.parse(e.target.value)))
   }
}


function uniqueId() {
   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
       var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
       return v.toString(16);
   });
}
export function addTitle() {
   return (dispatch ,getState)=> {
      var resources = JSON.parse(localStorage.getItem("selectedResources"));
      if(resources !== null){
      for(var i=0;i<resources.length;i++){
         resources[i].isChecked = false;
         if(resources[i].type===4){
            resources.splice(i,1)
            i--;
         }
      }}     
     
      if(resources === null)
      { 
         var tempArray = {id:uniqueId(),title:"",subtitle:[{id:uniqueId(),title:'',resources:[],quizquestions:[]}]}; 
      }
      else{
         var tempArray = {id:uniqueId(),title:"",subtitle:[{id:uniqueId(),title:'',resources:resources,quizquestions:[]}]};

      } 
      var arr=JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      arr.push(tempArray)
      dispatch(setCreatePlaylistElements('playlistArray',arr));
      //to scroll to that title
      const eleid = "createPlaylistTitle"+(arr.length-2).toString();
      const ele = document.getElementById(eleid);
      if(ele){
         setTimeout(()=>{ ele.scrollIntoView({behavior: "smooth"});})
      }
      //end
   }
}
export function handleTitleChange(index,value) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      arr[index].title = value;
      dispatch(setCreatePlaylistElements('playlistArray',arr))
   }
}

export function addSubtitle(index) {
   return (dispatch ,getState)=> {
      var resources = JSON.parse(localStorage.getItem("selectedResources"));
      // resources.filter(item=>{item.isChecked=!item.isChecked; if(item.type===4){
      //    return item
      // }});
      if(resources !== null){
         for(var i=0;i<resources.length;i++){
            resources[i].isChecked = false;
            if(resources[i].type===4){
               resources.splice(i,1)
               i--;
            }
         }}     

         if(resources === null)
         { 
            var subtitleTempArray = {id:uniqueId(),title:'',resources:[],quizquestions:[]};
         }
         else{
            var subtitleTempArray = {id:uniqueId(),title:'',resources:resources,quizquestions:[]};   
         } 

         var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
         arr[index].subtitle.push(subtitleTempArray);
         dispatch(setCreatePlaylistElements('playlistArray',arr))

          //to scroll to that title
       const eleid = "createPlaylistSubTitle"+index+(arr[index].subtitle.length-2).toString();
       const ele = document.getElementById(eleid);
       
       if(ele){
          setTimeout(()=>{ ele.scrollIntoView({behavior: "smooth"});})
       }
       //end
   }
}

export function addQuestion(subIndex,index) {
   return (dispatch ,getState)=> {
      var questionsTempArray = {id:uniqueId(),question:'',grade:'',options:[{id:uniqueId(),optionIndex:"",option:""}],correctAnswer:{},correctOption:""};
      var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      arr[index].subtitle[subIndex].quizquestions.push(questionsTempArray);
      dispatch(setCreatePlaylistElements('playlistArray',arr))
   }
}

export function addOption(titleIndex,subIndex,questIndex,optionIndex,value) {
   return (dispatch ,getState)=> {
      var optionsTempArray = {id:uniqueId(),option:""};
      var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      arr[titleIndex].subtitle[subIndex].quizquestions[questIndex].options.push(optionsTempArray);
      dispatch(setCreatePlaylistElements('playlistArray',arr))
   }
}

export function handleSubtitleChange(parentIndex,index,value) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      arr[parentIndex].subtitle[index].title = value;
      dispatch(setCreatePlaylistElements('playlistArray',arr))
   }
}

// export function handleGradeChange(parentIndex,index,grade) {
//    return (dispatch ,getState)=> {
//       var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
//       arr[parentIndex].subtitle[index].grade = grade;
//       dispatch(setCreatePlaylistElements('playlistArray',arr))
//    }
// }

export function handleQuestionChange(parentIndex,subIndex,index,e) {
   return (dispatch ,getState)=> {
      const dummyArray=["a","b","c","d","e","f","g","g","h","i","j","k","l","m","n"];
     
      var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      if(e.target.name==="question"){
      arr[parentIndex].subtitle[subIndex].quizquestions[index].question = e.target.value;}
      if(e.target.name==="grade"){
         arr[parentIndex].subtitle[subIndex].quizquestions[index].grade = e.target.value;}
      if(e.target.name==="selectedanswer"){
         var answer= JSON.parse(e.target.value);
        arr[parentIndex].subtitle[subIndex].quizquestions[index].correctAnswer = answer;
        arr[parentIndex].subtitle[subIndex].quizquestions[index].correctOption = dummyArray[answer.optionIndex];}
      dispatch(setCreatePlaylistElements('playlistArray',arr))
   }
}
export function handleOptionChange(titleIndex,subIndex,questIndex,optIndex,value) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      arr[titleIndex].subtitle[subIndex].quizquestions[questIndex].options[optIndex].option=value;
      arr[titleIndex].subtitle[subIndex].quizquestions[questIndex].options[optIndex].optionIndex=optIndex;
     dispatch(setCreatePlaylistElements('playlistArray',arr))
     var arraylength=arr[titleIndex].subtitle[subIndex].quizquestions[questIndex].options.length;
     if(arr[titleIndex].subtitle[subIndex].quizquestions[questIndex].options[arraylength-1].option!=="")
     {
      dispatch(addOption(titleIndex,subIndex,questIndex,optIndex,value))}
     }
}

export function handleSubtitleCheckboxChange(parentIndex,subIndex,id) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      arr[parentIndex].subtitle[subIndex].resources.filter(item=>{
         if(item._id===id){
           item.isChecked = !item.isChecked
         }
         return item;
      })
      dispatch(setCreatePlaylistElements('playlistArray',arr))
   }
}

export function deleteTitle(index) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      if(arr.length>1){
         arr.splice(index,1);
         dispatch(setCreatePlaylistElements('playlistArray',arr))
         dispatch(setCreatePlaylistElements('successAlertMessage','Title deleted successfully'));
         snackbar.successSnackbar();
      } else {
         const alertMsg = 'Atleast 1 title required';
         dispatch(displayAlert('error',alertMsg))
      }
   }
}

export function deleteSubtitle(parentIndex,index) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      if(arr[parentIndex].subtitle.length>1){
         arr[parentIndex].subtitle.splice(index,1);
         dispatch(setCreatePlaylistElements('playlistArray',arr))
         dispatch(setCreatePlaylistElements('successAlertMessage','Subitle deleted successfully'));
         snackbar.successSnackbar();
      } else {
         const alertMsg = 'Atleast 1 subtitle required';
         dispatch(displayAlert('error',alertMsg))
      }
   }
}
export function deleteQuestion(parentIndex,subIndex,index) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      arr[parentIndex].subtitle[subIndex].quizquestions.splice(index,1);
      dispatch(setCreatePlaylistElements('playlistArray',arr))
      // if(arr[parentIndex].subtitle[subIndex].quizquestions.length>1){
      //    arr[parentIndex].subtitle[subIndex].quizquestions.splice(index,1);
         
      //    dispatch(setCreatePlaylistElements('playlistArray',arr))
      // } else {
      //    const alertMsg = 'Atleast 1 question required';
      //    dispatch(displayAlert('error',alertMsg))
      // }
   }
}
export function deleteOption(parentIndex,subIndex,index,optionIndex,optionId) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      if(arr[parentIndex].subtitle[subIndex].quizquestions[index].options.length>1){
         // arr[parentIndex].subtitle[subIndex].quizquestions[index].options.splice(optionIndex,1);
         var dummy = arr[parentIndex].subtitle[subIndex].quizquestions[index].options.splice(optionIndex,1);
         if(dummy[0].option!==""){
            dispatch(setCreatePlaylistElements('playlistArray',arr))
         }
        
      } else {
         const alertMsg = 'Atleast 1 option required';
         dispatch(displayAlert('error',alertMsg))
      }
   }
}

export function handleSubmit(e,history) {
   return (dispatch ,getState)=> {
      e.preventDefault();
      var emptyValue = false;
      var arr = JSON.parse(JSON.stringify(getState().createPlaylistState.playlistArray));
      for(var i=0;i<arr.length;i++){
         var subtitle = arr[i].subtitle;
         for(var j=0;j<subtitle.length;j++){
            var subresources =  arr[i].subtitle[j].resources;
            for(var k=0;k<subresources.length;k++){
               if(!subresources[k].isChecked) {
                  subresources.splice(k,1);
                  k--;
               }
            }
         }
         if(arr.length-1===i){
            // console.log("final array1",arr);
            var validationArray = JSON.parse(JSON.stringify(arr));
            for(var a=0;a<validationArray.length;a++){
               for(var b=0;b<validationArray[a].subtitle.length;b++){
                  if(validationArray[a].subtitle[b].resources.length<=0){
                     emptyValue = true;
                     const alertMsg = 'Please add contents';
                     dispatch(displayAlert('error',alertMsg))
                  }
               } if(validationArray.length-1===a && !emptyValue){
                  createPlaylist(arr,dispatch,getState,history)
               }
            }
         }
      }
      // console.log("emptyValue",emptyValue)
   }
}

export function setCreatePlaylistElements(name,content) {
   return (dispatch ,getState)=> {
      dispatch(createPlaylistActions.setCreatePlaylistElements(name,content))
   }
}

export function displayAlert(type,msg) {
   return async (dispatch ,getState)=> {
      if(type==='success'){
         dispatch(setCreatePlaylistElements('successAlertMessage',msg));
         snackbar.successSnackbar();
       } else if(type==='error'){
         dispatch(setCreatePlaylistElements('errorAlertMessage',msg));
         snackbar.errorSnackbar(); 
      }
   }
}

export function handleReturn(history) {
   return (dispatch ,getState)=> {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      if(userDetails.type===1){
         history.push('/resources');
      } else if(userDetails.type===2){
         history.push('/dlt/resources');
      } else{
         history.push('/sub-admin/resources');
      }
   }
}

const createPlaylist = async (arr,dispatch,getState,history) => {
   var data = {};
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
   var totalTimeInvestment=0;
   for (var i=0 ; i<=arr.length-1; i++){
      for(var j=0 ; j<=arr[i].subtitle.length-1; j++){
         for(var k=0 ;k<=arr[i].subtitle[j].resources.length-1 ; k++){
            for(var l=0; l<=arr[i].subtitle[j].resources[k].file.length-1;l++){
               totalTimeInvestment=totalTimeInvestment + parseInt(arr[i].subtitle[j].resources[k].file[l].timeInvestment);
            }
         }
        
      }
   }
   data.branches = [getState().createPlaylistState.selectedDiscipline._id];
   data.categories = [getState().createPlaylistState.selectedContentType._id];
   data.displayName = getState().createPlaylistState.playlistName;
   data.publishedVersion = getState().createPlaylistState.publishedVersion;
   data.timeInvestment=totalTimeInvestment;
   data.playlists = arr;
   data.belongsTo = userDetails.belongsTo;
   // data.adminId = userDetails.adminId;
   
   // console.log("data object..",data)
   dispatch(setCreatePlaylistElements('showSubmitLoader',true))
   try {
      let response = await customHttp.post("/api/resourceuri/createPlaylists", data);
      let res = JSON.parse(JSON.stringify(response.data.message));
      dispatch(displayAlert('success','Playlist created successfully!'));
      dispatch(setCreatePlaylistElements('showSubmitLoader',false))
      var resources = JSON.parse(localStorage.getItem("selectedResources"));
      resources.filter(item=>{item.isChecked=false});
      var tempArray = {id:uniqueId(),title:"",subtitle:[{id:uniqueId(),title:'',resources:resources,quizquestions:[{id:uniqueId(),question:'',options:[{id:uniqueId(),option:""}],answer:""}]}]};
      dispatch(setCreatePlaylistElements('playlistName',''))
      dispatch(setCreatePlaylistElements('publishedVersion',''))
      dispatch(setCreatePlaylistElements('playlistArray',[tempArray]))
      dispatch(setCreatePlaylistElements('selectedDiscipline',getState().createPlaylistState.disciplines[0]))
      dispatch(setCreatePlaylistElements('selectedContentType',getState().createPlaylistState.contentTypes[0]));
      dispatch(handleReturn(history))
   } catch (err) {
      dispatch(displayAlert('error','Error try again!'));
      dispatch(setCreatePlaylistElements('showSubmitLoader',false))
      console.log("error",err);
    }
}

function uniqueApps(array) {
   var processed = [];
   for (var i=array.length-1; i>=0; i--){
     if (processed.indexOf(array[i].name)<0) {
       processed.push(array[i].name);
     } else {
       array.splice(i, 1);
     }
   }
   return array;
 }


export function addResourcesToSubtitle(arr){
   return async (dispatch, getState)=> { 
     
      if(arr.length>0){
         var selectedPlaylistIndex = JSON.parse(JSON.stringify(getState().createPlaylistState.selectedPlaylistIndex));
         var selectedAddResourceIndex = JSON.parse(JSON.stringify(getState().createPlaylistState.selectedAddResourceIndex));
         var array = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
         var resources=array[selectedPlaylistIndex].subtitle[selectedAddResourceIndex].resources;
   
         for(var i=0;i<arr.length;i++){
            if(arr[i].type!==4){
               resources.push(arr[i])
            }         
         }
         var uniqueResources= uniqueApps(resources);
         array[selectedPlaylistIndex].subtitle[selectedAddResourceIndex].resources=uniqueResources;
         dispatch(setCreatePlaylistElements('playlistArray',array))
         document.getElementById('resourcePopup').style.display='none'; 
      }else{
         const alertMsg = 'Please select a resource';
         dispatch(displayAlert('error',alertMsg))
      } 
   }
   
}