
import { combineReducers,createStore,applyMiddleware} from "redux";
import thunk from 'redux-thunk';
import dashboardState from "./Dashboard/index";
import courseState from "./Courses/index";
import userCoursesState from "./UserCourses/index"
import RequestedContentState from "./RequestedContent/index";
import appstore from "./Appstore/index"
import userState from "./Users/index";
import resourceState from './Resources/index';
import requestResourceState from './RequestResource'
import DltRequestedContentState from "./DltRequestedContent/index";
import userRatingsState from './UserRatings';
import createPlaylistState from './CreatePlaylist';
import editPlaylistState from './EditPlaylist';
import viewPlaylistState from './ViewPlaylist';
import sharedResourcesState from './SharedResources';
import bookSlotState from './BookSlot';
import accessLabState from './AccessLab';
import profileState from './Profile';
import userProfileState from './UserProfile';
import courseAnalyticsState from './CourseAnalytics';
import userDashboardState from './UserDashboard';
import gargiKnowledgeBaseState from './GargiKnowledgeBase';
import adminSlotBookingState from './AdminSlotBooking';
import settingsState from './Settings';
import proctoringState from './Proctoring';
import objectandfacerecognitionState from './ObjectandFaceRecognition';

export const Store = combineReducers({
    dashboardState,
    courseState,
    editPlaylistState,
    RequestedContentState,
    appstore,
    userCoursesState,
    userState,
    resourceState,
    requestResourceState,
    userRatingsState,
    createPlaylistState,
    viewPlaylistState,
    DltRequestedContentState,
    sharedResourcesState,
    bookSlotState,
    accessLabState,
    profileState,
    userProfileState,
    courseAnalyticsState,
    userDashboardState,
    gargiKnowledgeBaseState,
    adminSlotBookingState,
    settingsState,
    proctoringState,
    objectandfacerecognitionState
})
export default createStore(Store,applyMiddleware(thunk));
