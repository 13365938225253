import React, { useEffect,useState } from 'react';
import { connect } from "react-redux";
 import * as SharedResourceContainer from "../../container/actions/SharedResources/action";
// import Store from "../../container/reducers/Store";
//  import * as Actions from "../../container/actions/SharedResources";
import { bindActionCreators } from 'redux';
// import { Store } from '../../container/reducers/Store.js';
import VideoPlayer from '../../packages/VideoPlayer/';


function SharedResource (props) {
  //const [name, SetName] = useState("");
  useEffect( () => {  
    props.initiateOnloadFunction()
    
    
  },[]);
  // onload function end
const [htmlFileDetails, setHtmlFileDetails] = useState({})

var openHtmlFilePopup = (item) => {
    setHtmlFileDetails(item)
      document.getElementById('playResourceHtmlPopupType6').style.display='block';
  }

  const closeHtmlVideoPopup = () => {
    setHtmlFileDetails({})
    document.getElementById('playResourceHtmlPopupType6').style.display='none'
  }


    return (
      <div className="add-on-load-animation">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
              <div className="col-md-12 col-sm-12 nj-card" >
                <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                  <div className="nj-card__header">
                    <div className="nj-card__title">
                      <h4 className="font_family_montserrat">Shared Resources</h4>
                    </div>
                    {/* <div style={{paddingBottom:'9px'}}>
                      <button className="nj-flow-button pull-right" type="submit" >Back</button>
                    </div> */}
                  </div>
                </div>
                <div>
                  {!props.sharedResourcesState.initialLoader ?
                  <div className="row" style={{paddingTop:"10px"}}>
                    <div className="col-md-12 col-sm-12 col-xs-12" style={{marginBottom:"10px"}}>
                      <div className="col-md-12">
                        <div>
                          <div className="row" style={{padding:"0px 0px 10px 10px"}}>
                            <div className="col-md-7 col-sm-7 col-xs-6">
                              <h4 className="font_family_montserrat">Shared Resource</h4>
                            </div>
                          </div>
                          {props.sharedResourcesState.sharedResources.length>0 ?
                          <div className="tbl-content" style={{maxHeight:'64vh',overflowY:'scroll'}}>
                            <div className="resources-wrapper">
                              {props.sharedResourcesState.sharedResources.map((item, i) => {
                                return (
                                  <div className="resource_card add-rebound-animation" key={item._id}>
                                    <div className="resource_card-content">                                                               
                                      {item.resourceId[0].type===2 && <div className="resource_card-content-img-wrapper" onClick={()=>props.openResourceLink(item.resourceId[0],window,props.history)}>
                                      <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                                      </div>}
                                      {item.resourceId[0].type===0 && <div className="resource_card-content-img-wrapper" onClick={()=>props.openResourceLink(item.resourceId[0],window,props.history)}>
                                      <img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource" />
                                      </div>}
                                      {item.resourceId[0].type===4 && <div className="resource_card-content-img-wrapper" onClick={()=>props.openResourceLink(item.resourceId[0],window,props.history)}>
                                      <img className="resource_card-content-img" src={"/img/resource-icons/file.svg"} alt="resource" />
                                    </div>}
                                    {item.resourceId[0].type===6 && <div className="resource_card-content-img-wrapper" onClick={()=>openHtmlFilePopup(item.resourceId[0])}>
                                      <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                                      </div>}
                                    </div>
                                    <div className="resource_card-footer" style={{cursor: "auto"}} onClick={()=>props.openResourceLink(item.resourceId[0],window,props.history)}>
                                      <p className="font_family_montserrat">{item.resourceId[0].displayName}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>                 
                          </div>
                          :
                          <div>
                            <div className="col-md-12 font_family_montserrat idams_noDataFound" style={{height:"23vh"}}>No data found</div>
                          </div>                     
                          }
                        </div>
                        {/******************* recently shared resource block start *********************/}
                        <div>
                          <div className="row" style={{padding:"0px 0px 10px 10px"}}>
                            <div className="col-md-7 col-sm-7 col-xs-6">
                              <h4 className="font_family_montserrat">Recently Shared Resources</h4>
                            </div>
                          </div>
                          {props.sharedResourcesState.recentlySharedResources.length>0 ?
                          <div className="tbl-content" style={{maxHeight:'64vh',overflowY:'scroll'}}>
                            <div className="resources-wrapper">
                              {props.sharedResourcesState.recentlySharedResources.map((item, i) => {
                                return (
                                  <div className="resource_card add-rebound-animation" key={item._id}>
                                    <div className="resource_card-content">
                                      {item.resourceId[0].type===2 && <div className="resource_card-content-img-wrapper" onClick={()=>props.openResourceLink(item.resourceId[0],window,props.history)}>
                                      <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                                      </div>}
                                      {item.resourceId[0].type===0 && <div className="resource_card-content-img-wrapper" onClick={()=>props.openResourceLink(item.resourceId[0],window,props.history)}>
                                      <img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource" />
                                      </div>}
                                      {item.resourceId[0].type===4 && <div className="resource_card-content-img-wrapper" onClick={()=>props.openResourceLink(item.resourceId[0],window,props.history)}>
                                      <img className="resource_card-content-img" src={"/img/resource-icons/file.svg"} alt="resource" />
                                      </div>}
                                      {item.resourceId[0].type===6 && <div className="resource_card-content-img-wrapper" onClick={()=>openHtmlFilePopup(item.resourceId[0])}>
                                      <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                                      </div>}
                                    </div>
                                    <div className="resource_card-footer" style={{cursor: "auto"}} onClick={()=>props.openResourceLink(item.resourceId[0],window,props.history)}>
                                      <p className="font_family_montserrat">{item.resourceId[0].displayName}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>                 
                          </div>
                          :

                          <div>
                            <div className="col-md-12 font_family_montserrat idams_noDataFound">No data found</div>
                          </div>                     
                          }
                        </div>
                        {/******************* recently shared resource block end *********************/}


{/*popup to play html resources*/}
        <div id="playResourceHtmlPopupType6" className={"nj-overly add-rebound-animation"}>
          <div className="popup-nj  my-popup" style={{maxWidth:"900px"}}>
            <header className="popup-header">
              <span style={{textTransform:"capitalize"}}>{Object.keys(htmlFileDetails).length>0 && htmlFileDetails.displayName}</span>
              <span onClick={closeHtmlVideoPopup}><i className="popup-close material-icons">close</i></span>
            </header>
            {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
            <div className="popup-content" style={{maxHeight:"500px"}}>
              <div className="col-md-12">
                <iframe  id='playHtml6'  src={"/"+(Object.keys(htmlFileDetails).length>0 && htmlFileDetails.link)+".html"}  width="512" height="450" style={{width:'100%',outline:'none'}}>
               </iframe>
              </div>
            </div>
          </div>
      </div>
                        {/******************* popup to play video start *********************/}

                        <div id="playResourceVideoPopup" className={"nj-overly add-rebound-animation"}>
                          <div className="popup-nj  my-popup" style={{maxWidth:700}}>
                            <header className="popup-header">
                              <span style={{textTransform:"capitalize"}}>{props.sharedResourcesState.resourceVideo.displayName}</span>
                              <span onClick={props.closeVideoPopup}><i className="popup-close material-icons">close</i></span>
                            </header>
                            {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
                            <div className="popup-content">
                              <div className="col-md-12">
                                {Object.keys(props.sharedResourcesState.resourceVideo).length>0 && <VideoPlayer link={props.sharedResourcesState.resourceVideo.file[0].path} key={props.sharedResourcesState.resourceVideo._id} resourceId={props.sharedResourcesState.resourceVideo._id} currenttime={0}/>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="row" style={{height:"76vh"}}>
                    <div className="col-md-12 aligh-loader-image-center" >
                      <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


const mapStateToProps = state => ({
  sharedResourcesState: state.sharedResourcesState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getSharedResources:SharedResourceContainer.getSharedResources,
  initiateOnloadFunction:SharedResourceContainer.initiateOnloadFunction,
  getSharedResourcesById:SharedResourceContainer.getSharedResourcesById,
  openResourceLink:SharedResourceContainer.openResourceLink,
  closeVideoPopup:SharedResourceContainer.closeVideoPopup,
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(SharedResource);
