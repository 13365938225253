import React from 'react';
import {Route , Redirect} from "react-router-dom";

 const  CustomRoute=({ component: Component ,...rest})=>{
    var userData=JSON.parse(sessionStorage.getItem("userData"));
    var userProfileData=JSON.parse(sessionStorage.getItem("userProfileData"));
    if(userProfileData != null || userProfileData !== undefined){
        userProfileData=userProfileData;
    }else{
        userProfileData=false 
    }
    return(
        <Route
        {...rest}
        render={props =>{
            if(userData !== null && userProfileData ){
                return <Component {...props} />
            }else{
                if(props.location.pathname==="/user/profile"){
                    return <Component {...props} />
                }else{
                    return <Redirect to={
                        {
                            pathname:"/user/profile",
                            state:{
                                from:props.location
                            }
                        } 
                    } />
                }                
            }
        }}
            
        />
    )
}
export default CustomRoute;