
// import axios from "axios";
import * as UserActions from "./index";
import customHttp from '../../../packages/CustomHttp/';
import snackbar from '../../../packages/Snackbar/';


// for applications

export function getAllApplications (){
     return (dispatch ,getState)=> {
         // axios.get('/api/userRoles/allApplications')

         var url = '/api/userRoles/allApplications';
         customHttp.get(url)

         .then(response=>{
            response.data.message.forEach(function(element) { element.isSelected = false});
            // console.log("response",response.data.message);
            dispatch(UserActions.getAllApplications(response.data.message));
         })
         .catch(error => {
            dispatch(UserActions.getAllApplications([]));
            console.log("error",error)
         });
     }
  }


  export function selectApplication (app){
   return (dispatch ,getState)=> {
      var dublicateAapplicationList=JSON.parse(JSON.stringify(getState().courseState.allApplications))
      for(var k=0;k<dublicateAapplicationList.length;k++){
          if(dublicateAapplicationList[k]._id===app._id){
            dublicateAapplicationList[k].isSelected = !dublicateAapplicationList[k].isSelected;
        }
      }
      dispatch(UserActions.selectApplication(dublicateAapplicationList));

   }
}

export function deleteSelectedApplication (app){
   return (dispatch ,getState)=> {
      var dublicateAapplicationList=JSON.parse(JSON.stringify(getState().courseState.selectedApplications))
      for(var k=0;k<dublicateAapplicationList.length;k++){
          if(dublicateAapplicationList[k]._id===app._id){
            dublicateAapplicationList.splice(k,1)
        }
      }
      dispatch(UserActions.deleteSelectedApplication(dublicateAapplicationList));

   }
}

export function getSelectedApplications (){
   return (dispatch ,getState)=> {
      var dummyArray=[];
      var dublicateAapplicationList=JSON.parse(JSON.stringify(getState().courseState.allApplications))
      for(var k=0;k<dublicateAapplicationList.length;k++){
          if(dublicateAapplicationList[k].isSelected){
            dummyArray.push(dublicateAapplicationList[k])
        }
      }
      var selectedApplicationsArray=JSON.parse(JSON.stringify(getState().courseState.selectedApplications))
      for(var j=0;j<dummyArray.length;j++){
         selectedApplicationsArray.push(dummyArray[j])
      }

      function uniqueApps(array) {
         var processed = [];
         for (var i=array.length-1; i>=0; i--){
           if (processed.indexOf(array[i].name)<0) {
             processed.push(array[i].name);
           } else {
             array.splice(i, 1);
           }
         }
         return array;
       }
      var uniqueSelectedApps= uniqueApps(selectedApplicationsArray);
      dispatch(UserActions.getSelectedApplications(uniqueSelectedApps));
      dispatch(getAllApplications())
      dispatch(UserActions.setBooleanValue("showSelectedApplications",true))

   }
}


export function clearSelectApplication (){
   return (dispatch ,getState)=> {
      var dublicateAapplicationList=JSON.parse(JSON.stringify(getState().courseState.allApplications))
      dublicateAapplicationList.forEach(function(element) { element.isSelected = false});
      dispatch(UserActions.getAllApplications(dublicateAapplicationList));
      dispatch(UserActions.setBooleanValue("showSelectedApplications",true))
   }
}


// for resources
export function selectResource (resource){
   return (dispatch ,getState)=> {
      var dublicateResourcesList=JSON.parse(JSON.stringify(getState().courseState.allApplications))
      for(var k=0;k<dublicateResourcesList.length;k++){
          if(dublicateResourcesList[k]._id===resource._id){
            dublicateResourcesList[k].isSelected = !dublicateResourcesList[k].isSelected;
        }
      }
      dispatch(UserActions.selectResource(dublicateResourcesList));

   }
}

export function deleteSelectedResource (resource){
   return (dispatch ,getState)=> {
      var dublicateResourcesList=JSON.parse(JSON.stringify(getState().courseState.selectedResources))
      for(var k=0;k<dublicateResourcesList.length;k++){
          if(dublicateResourcesList[k]._id===resource._id){
            dublicateResourcesList.splice(k,1)
        }
      }
      dispatch(UserActions.deleteSelectedResource(dublicateResourcesList));

   }
}

export function getSelectedResources(){
   return (dispatch ,getState)=> {
      var dummyArray=[];
      var dublicateResourcesList=JSON.parse(JSON.stringify(getState().courseState.allApplications))
      for(var k=0;k<dublicateResourcesList.length;k++){
          if(dublicateResourcesList[k].isSelected){
            dummyArray.push(dublicateResourcesList[k])
        }
      }
      var selectedResourceArray=JSON.parse(JSON.stringify(getState().courseState.selectedResources))
      for(var j=0;j<dummyArray.length;j++){
         selectedResourceArray.push(dummyArray[j])
      }

      function uniqueApps(array) {
         var processed = [];
         for (var i=array.length-1; i>=0; i--){
           if (processed.indexOf(array[i].name)<0) {
             processed.push(array[i].name);
           } else {
             array.splice(i, 1);
           }
         }
         return array;
       }
      var uniqueSelectedResources= uniqueApps(selectedResourceArray);
      dispatch(UserActions.getSelectedResources(uniqueSelectedResources));
      dispatch(getAllApplications())
      dispatch(UserActions.setBooleanValue("showSelectedResources",true))

   }
}
export function clearSelectResource (){
   return (dispatch ,getState)=> {
      var dublicateResourcesList=JSON.parse(JSON.stringify(getState().courseState.allApplications))
      dublicateResourcesList.forEach(function(element) { element.isSelected = false});
      dispatch(UserActions.getAllApplications(dublicateResourcesList));
      dispatch(UserActions.setBooleanValue("showSelectedResources",true))
   }
}

export function createCourse(e){
   e.preventDefault()
   return (dispatch ,getState)=> {
      var finalObject=getState().courseState;

      var fromDateInSec = new Date(finalObject.fromDate);
      var tempDate = fromDateInSec.getDate();
      var tempMonth = fromDateInSec.getMonth()+1;
      var tempYear = fromDateInSec.toString().substring(11,15);
      var fromDate = tempDate + "/" + tempMonth + "/" + tempYear;

      var fromDateInSec = new Date(finalObject.toDate);
      var tempDate = fromDateInSec.getDate();
      var tempMonth = fromDateInSec.getMonth()+1;
      var tempYear = fromDateInSec.toString().substring(11,15);
      var toDate = tempDate + "/" + tempMonth + "/" + tempYear;

      var object={};
      object.fromDate=fromDate;
      object.toDate=toDate;
      object.displayName=finalObject.displayName;
      object.courseName=finalObject.courseName;
      // console.log("create course ",object)
   }
}

// GET CREATED COURSES

export function getAllCreatedCourses (){
   return (dispatch ,getState)=> {
       dispatch(UserActions.setBooleanValue("availableCourseLoader",true))

      //  axios.get('/api/userRoles/allApplications')
       var url = '/api/userRoles/allApplications';
       customHttp.get(url)
       .then(response=>{

          dispatch(UserActions.getAllCreatedCourses(response.data.message));
          dispatch(UserActions.setBooleanValue("availableCourseLoader",false))
       })
       .catch(error => {
          dispatch(UserActions.getAllCreatedCourses([]));
          console.log("error",error)
       });
   }
}

 export function animationFunctionForPopup (){
   return (dispatch ,getState)=> {
       dispatch(UserActions.setBooleanValue("shouldPopupAnimate",true))
       setTimeout(function(){ dispatch(UserActions.setBooleanValue("shouldPopupAnimate",false))}, 1000);
   }
}

export function getSelectedCourse(popupId,item){
   return (dispatch ,getState)=> {
      if(popupId!=="none"){
         document.getElementById(popupId).style.display='block'
      }
      dispatch(UserActions.getSelectedCourse(item));
   }
}

export function deleteCourse(popupId,item){
   return (dispatch ,getState)=> {
    //item.splice(item,1);
    var availableUsersList=getState().userState.availableUsers
        //console.log("hgdgdggd",popupId)
        availableUsersList.splice(item,1);
        dispatch(UserActions.setBooleanValue("getSelectedCourse",true))
       dispatch(UserActions.deleteCourse(availableUsersList))
   }
}

export function getAllCourses () {

   return (dispatch ,getState)=> {
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
   // console.log("user details",userDetails)
   dispatch(UserActions.setBooleanValue("showAllResourcesLoader",false))
   // axios.get('/api/course/allCourses/user/'+userDetails.belongsTo)
   var url = '/api/course/allCourses/user/'+userDetails._id ;
   customHttp.get(url)
      .then(response=>{
         dispatch(UserActions.setBooleanValue("showAllResourcesLoader",true))
         if(response.data.message.length>0){
            response.data.message.forEach(function(element) { element.isSelected = false});
            // console.log("get all course response",response.data.message);
            dispatch(UserActions.getAllCourses(response.data.message));
            dispatch(getUsersByCourse(response.data.message[0]))
            dispatch(getUsersByType(response.data.message[0],"enrolled"));
         }else{
            dispatch(UserActions.getAllCourses([]));
         }


      })
      .catch(error => {
         dispatch(UserActions.setBooleanValue("showAllResourcesLoader",true))
         dispatch(UserActions.getAllCourses([]));
         dispatch(UserActions.setBooleanValue("availableCourseLoader",false))
         console.log("error",error)
      });
   }
}

export function selectCourseForCreateUser (course){
   return (dispatch ,getState)=> {
      var dublicateCourses=JSON.parse(JSON.stringify(getState().userState.allCourses))
      for(var k=0;k<dublicateCourses.length;k++){
          if(dublicateCourses[k]._id===course._id){
            dublicateCourses[k].isSelected = !dublicateCourses[k].isSelected;
        }
      }
      dispatch(UserActions.getAllCourses(dublicateCourses));

   }
}

export function clearselecteCourseForCreateUser (){
   return (dispatch ,getState)=> {
      var dublicateResourcesList=JSON.parse(JSON.stringify(getState().userState.allCourses))
      dublicateResourcesList.forEach(function(element) { element.isSelected = false});
      dispatch(UserActions.getAllCourses(dublicateResourcesList));
   }
}

export function createUser (obj,popId,selectCourseUser,usertype){
   return async(dispatch ,getState)=> {
      var enteredDomainName=JSON.parse(JSON.stringify(getState().userState.enteredDomainName))
      var dummyArray=[]
      var userObject={};
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      var dublicateCourses=JSON.parse(JSON.stringify(getState().userState.allCourses))
      for(var k=0;k<dublicateCourses.length;k++){
          if(dublicateCourses[k].isSelected){
            dummyArray.push(dublicateCourses[k]._id)
        }
      }
      userObject.courses=[];
      userObject.courses=dummyArray;
      userObject.phoneNumber="91"+obj.phoneNumber;
      userObject.email=obj.email.toLowerCase();
      userObject.username=obj.username.toLowerCase();
      userObject.adminId=userDetails._id;
      userObject.belongsTo=userDetails.belongsTo;
      //userObject.domainName ="platifi.com";
      if(Object.keys(enteredDomainName).length>0 ){
         userObject.domainName=enteredDomainName;
      }else{
         userObject.domainName="platifi.com";
      }
      if(dummyArray.length>0){

         try {
            dispatch(UserActions.setBooleanValue("userCreate_loader",false))
            // console.log("user created",userObject)
            let response = await customHttp.put('/api/user/createUser/',userObject);
            // console.log("response",response.data.message);
            dispatch(UserActions.setBooleanValue("userCreate_loader",true))
            dispatch(UserActions.setBooleanValue("successSnackbarMessage","User created successfully"))
            snackbar.successSnackbar();
            dispatch(getAllCourses())
            document.getElementById(popId).style.display='none';
            dispatch(getUsersByCourse(selectCourseUser));
            dispatch(getUsersByType(selectCourseUser,usertype));

          } catch (err) {
            dispatch(UserActions.setBooleanValue("userCreate_loader",true))
            dispatch(UserActions.setBooleanValue("errorSnackbarMessage","Username is taken"))
            snackbar.errorSnackbar();
            console.log("error",err)
          }
      }else{
         dispatch(UserActions.setBooleanValue("errorSnackbarMessage","Please select atleast one course"))
         snackbar.errorSnackbar();
      }
   }
}

export function getUsersByCourse (data) {
    return async(dispatch ,getState)=> {
      // console.log("get users by course",data)
      dispatch(UserActions.setBooleanValue("availableCourseLoader",true))
       try {
         let response = await customHttp.get('/api/course/'+data._id+'/users');
         // console.log("msg",response.data.message);
         dispatch(UserActions.setBooleanValue("availableCourseLoader",false))
         if(response.data.message && Array.isArray(response.data.message)){
            dispatch(UserActions.getUsersByCourse(response.data.message));
            // console.log("all users",response.data.message)
         }else{
            dispatch(UserActions.getUsersByCourse([]));
         }
       } catch (err) {
         dispatch(UserActions.setBooleanValue("availableCourseLoader",false))
         dispatch(UserActions.getUsersByCourse([]));


         console.log("error",err)
       }
    }

}

export function checkUserNameExist (obj){
   return async(dispatch ,getState)=> {
      try {
         if(obj!==undefined){
            let response = await customHttp.get('/api/user/validateUsername/username/'+obj);
            if(response.data.message.usernameExists === true){
            dispatch(UserActions.setBooleanValue("userNameExist",true))
         }else{
            dispatch(UserActions.setBooleanValue("userNameExist",false))
         }
      }
       } catch (err) {
         dispatch(UserActions.setBooleanValue("userNameExist",false))
         console.log("error",err)
       }
   }
}

export function checkEmailExist (email){
   return async(dispatch ,getState)=> {
      try {
         if(email!==undefined){
            let response = await customHttp.get('/api/user/validateUser/email/'+email.toLowerCase());
            dispatch(UserActions.setBooleanValue("userEmailExist",response.data.message.userExists))
         }else{
            dispatch(UserActions.setBooleanValue("userEmailExist",false))
         }
       } catch (err) {
         dispatch(UserActions.setBooleanValue("userEmailExist",false))
         console.log("error",err)
       }
   }
}
export function deleteUserContent (e,data,selectCourseUser){
   // console.log("select course user",selectCourseUser);
   return (dispatch ,getState)=> {
      e.preventDefault();
      //  console.log("userId,_id delete User content",data);
      // axios.delete('/api/user/delete/_id/'+data._id+'/username/'+data.username)
      var url = '/api/user/delete/_id/'+data._id+'/username/'+data.username ;
      customHttp.delete(url)
     .then(response=>{
        //var content=response.data.message;
         // console.log("response",response);
         dispatch(UserActions.setBooleanValue("successSnackbarMessage","User deleted successfully"))
         snackbar.successSnackbar();
         // dispatch(getAllCourses())
         dispatch(getUsersByCourse(selectCourseUser));

     })
     .catch(error => {
   //    dispatch(RequestedContentActions.getRequestedContent([]))
   //  console.log("error",error)
  });
   }
 }

 export function selectCourseForEditUser (course){
   return (dispatch ,getState)=> {
      var dublicateCourses=JSON.parse(JSON.stringify(getState().userState.AvailableAllCourses))
      for(var k=0;k<dublicateCourses.length;k++){
          if(dublicateCourses[k]._id===course._id){
            dublicateCourses[k].isSelected = !dublicateCourses[k].isSelected;
        }
      }
      dispatch(UserActions.setBooleanValue("AvailableAllCourses",dublicateCourses))

   }
}

export function getSelectedCoursesForEditUser(){
   return (dispatch ,getState)=> {
      var dummyArray=[];
      var dublicateCourseList=JSON.parse(JSON.stringify(getState().userState.AvailableAllCourses))
      for(var k=0;k<dublicateCourseList.length;k++){
          if(dublicateCourseList[k].isSelected){
            dummyArray.push(dublicateCourseList[k])
        }
      }
      var selectedCourseArray=JSON.parse(JSON.stringify(getState().userState.selectedUser.courses))
       var enabledCourses=JSON.parse(JSON.stringify(getState().userState.selectedUser.enabledCourses))

      var temp=JSON.parse(JSON.stringify(getState().userState.selectedUser))
      for(var j=0;j<dummyArray.length;j++){
         selectedCourseArray.push(dummyArray[j])
         enabledCourses.push(dummyArray[j])

      }

      function uniqueApps(array) {
         var processed = [];
         for (var i=array.length-1; i>=0; i--){
           if (processed.indexOf(array[i]._id)<0) {
             processed.push(array[i]._id);
           } else {
             array.splice(i, 1);
           }
         }
         return array;
       }
      var uniqueSelectedCourse= uniqueApps(selectedCourseArray);
      var uniqueEnabledCourses= uniqueApps(enabledCourses);
      temp.courses=uniqueSelectedCourse;
      temp.enabledCourses=uniqueEnabledCourses;
      dispatch(UserActions.setBooleanValue("selectedUser",temp))
      dispatch(UserActions.setBooleanValue("editShowSelectedCourses",true))

   }
}

export function deleteSelectedUserCourse(item){
   return (dispatch ,getState)=> {
      var availableCourse=JSON.parse(JSON.stringify(getState().userState.selectedUser.courses))
      var enabledCourses=JSON.parse(JSON.stringify(getState().userState.selectedUser.enabledCourses))
      var requestedCourses=JSON.parse(JSON.stringify(getState().userState.selectedUser.requestedCourses))

      // console.log("enabledCourses",requestedCourses,enabledCourses)

      var temp=JSON.parse(JSON.stringify(getState().userState.selectedUser))

      for(var i=0;i<availableCourse.length;i++){
         if(availableCourse[i]._id===item._id){
            availableCourse.splice(i,1);
         }
      }

      for(var j=0;j<enabledCourses.length;j++){
         if(enabledCourses[j]._id===item._id){
            enabledCourses.splice(j,1);
         }
      }

      for(var k=0;k<requestedCourses.length;k++){
         if(requestedCourses[k]._id===item._id){
            requestedCourses.splice(k,1);
         }
      }

      temp.courses=availableCourse;
      temp.enabledCourses=enabledCourses;
      temp.requestedCourses=requestedCourses;
      // console.log("abcdertyui",temp)
      dispatch(UserActions.setBooleanValue("selectedUser",temp))
   }
}

export function handleChangeEdituserInputFields(name,value){
   return async(dispatch ,getState)=> {
      var originalSelectedUserObject=JSON.parse(JSON.stringify(getState().userState.originalSelectedUser))
      var temp=JSON.parse(JSON.stringify(getState().userState.selectedUser))
      temp[name]=value;
      dispatch(UserActions.setBooleanValue("selectedUser",temp))
      if(name==="email"){
         try {
            if(value!==undefined && value!==originalSelectedUserObject.email){
               let response = await customHttp.get('/api/user/validateUser/email/'+value.toLowerCase());
               dispatch(UserActions.setBooleanValue("EditUserEmailExist",response.data.message.userExists))
            }else{
               dispatch(UserActions.setBooleanValue("EditUserEmailExist",false))
            }
          } catch (err) {
            dispatch(UserActions.setBooleanValue("EditUserEmailExist",false))
            console.log("error",err)
          }
      }

   }
}

export function updateEditUser(e,selectCourseUser){
   return async(dispatch ,getState)=> {
    
      e.preventDefault()
      var temp=JSON.parse(JSON.stringify(getState().userState.selectedUser))
      var dummyArray=[]
      var enabledCourses=[];
      var requestedCourses=[];
      var userObject={};
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))

      for(var k=0;k<temp.courses.length;k++){
        dummyArray.push(temp.courses[k]._id)
      }

       for(var j=0;j<temp.enabledCourses.length;j++){
        enabledCourses.push(temp.enabledCourses[j]._id)
      }

       for(var i=0;i<temp.requestedCourses.length;i++){
        requestedCourses.push(temp.requestedCourses[i]._id)
      }
      
      userObject.courses=[];
      userObject.enabledCourses=[];
      userObject.requestedCourses=[];
      userObject.courses=dummyArray;
      userObject.enabledCourses=enabledCourses;
      userObject.requestedCourses=requestedCourses;
      userObject.phoneNumber=temp.phoneNumber;
      userObject.email=temp.email.toLowerCase();
      if(temp.username !== undefined){
         userObject.username=temp.username.toLowerCase();
      }
      else{
         userObject.username="";
      }
      userObject.adminId=userDetails._id;
      userObject.belongsTo=temp.belongsTo;
      if(dummyArray.length>0){

         try {
            dispatch(UserActions.setBooleanValue("showUserUpdateLoader",true))
            // console.log("user created",userObject)
            let response = await customHttp.put('/api/user/update/userId/'+temp._id,userObject);
            // console.log("response",response.data.message);
            dispatch(UserActions.setBooleanValue("showUserUpdateLoader",false))
            dispatch(UserActions.setBooleanValue("showUserList",true))
            dispatch(UserActions.setBooleanValue("successSnackbarMessage","User updated successfully"))
            snackbar.successSnackbar();
            dispatch(getUsersByCourse(selectCourseUser));
            // dispatch(getAllCourses())
          } catch (err) {
            dispatch(UserActions.setBooleanValue("showUserUpdateLoader",false))
            dispatch(UserActions.setBooleanValue("errorSnackbarMessage","Username is taken"))
            snackbar.errorSnackbar();
            console.log("error",err)
          }
      }else{
         dispatch(UserActions.setBooleanValue("errorSnackbarMessage","Please select atleast one course"))
         snackbar.errorSnackbar();
      }
   }
}

export function getAvailableAllCourses (selectedArray) {
   return (dispatch ,getState)=> {
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))

   dispatch(UserActions.setBooleanValue("showAllCoursesLoader",false))
   // axios.get('api/course/allCourses/user/'+userDetails._id)
   var url = '/api/course/allCourses/user/'+userDetails._id ;
   customHttp.get(url)
      .then(response=>{
         dispatch(UserActions.setBooleanValue("showAllCoursesLoader",true))
         if(response.data.message && Array.isArray(response.data.message)){
            if(selectedArray.length>0){
               for(var k=0;k<selectedArray.length;k++){
                 for(var l=0;l<response.data.message.length;l++){
                  if(response.data.message[l]._id===selectedArray[k]._id){
                    response.data.message.splice(l,1);
                  }
                 }
               }
              }
            response.data.message.forEach(function(element) { element.isSelected = false});
            // console.log("get all course response",response.data.message);
            dispatch(UserActions.setBooleanValue("AvailableAllCourses",response.data.message))
         }else{
            dispatch(UserActions.setBooleanValue("AvailableAllCourses",[]))
         }

      })
      .catch(error => {
         dispatch(UserActions.setBooleanValue("showAllCoursesLoader",true))
         dispatch(UserActions.setBooleanValue("AvailableAllCourses",[]))
         console.log("error",error)
      });
   }
}

//to search courses in create popup

export function getSearchCourses (searchCourseName){

   return (dispatch ,getState)=> {

      if(searchCourseName === ""){
         // dispatch(getAllCourses())
         dispatch(UserActions.getSearchCourses(getState().userState.allCourses));
      }
      else{
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      // console.log("userdsata",userDetails.belongsTo,searchCourseName)
         //dispatch(UserActions.setBooleanValue("availableCourseLoader",true))
         // axios.get('/api/course/belongsTo/'+userDetails.belongsTo+'/courseName/'+searchCourseName)
         var url = '/api/course/belongsTo/'+userDetails.belongsTo+'/courseName/'+searchCourseName ;
         customHttp.get(url)
         .then(response=>{
         // console.log("response",response.data.message)
            dispatch(UserActions.getSearchCourses(response.data.message));
            //dispatch(UserActions.setBooleanValue("availableCourseLoader",false))
         })
         .catch(error => {
            dispatch(UserActions.getSearchCourses([]));
            console.log("error",error)
         });
      }
   }

}


//get domain name

export function getDomainName (){
   return async(dispatch ,getState)=> {
      try {
         var userDetails=JSON.parse(sessionStorage.getItem("userData"))
          let response = await customHttp.get('/api/confidentialDetails/user/'+userDetails.belongsTo);
          if(response.data.message.length>0){
            if(Object.keys(response.data.message[0].organizationDomainName).length>0){
               dispatch(UserActions.setBooleanValue("enteredDomainName",response.data.message[0].organizationDomainName))
            }else{
               dispatch(UserActions.setBooleanValue("enteredDomainName",""))
            }
          }else{
            dispatch(UserActions.setBooleanValue("enteredDomainName",""))
          }
       } catch (err) {
         dispatch(UserActions.setBooleanValue("enteredDomainName",""))
         console.log("error",err)
       }
   }
}

export function getUsersByType (data , type){
   return async(dispatch ,getState)=> {

      dispatch(UserActions.setBooleanValue("availableCourseLoader",true))
      try {

        let response = await customHttp.get('/api/user/fetchUsersByCourseId/'+data._id+'/type/'+type);
        dispatch(UserActions.setBooleanValue("availableCourseLoader",false))
        if(response.data.message && Array.isArray(response.data.message)){
           dispatch(UserActions.getUsersByCourse(response.data.message));
           if(getState().userState.searchUsername !== ""){
            dispatch(searchFilter(getState().userState.searchUsername))}
         
         //   console.log("all users",response.data.message)
        }else{
           dispatch(UserActions.getUsersByCourse([]));
        }
      } catch (err) {
        dispatch(UserActions.setBooleanValue("availableCourseLoader",false))
        dispatch(UserActions.getUsersByCourse([]));


        console.log("error",err)
      }


   }
}

export function acceptUserRequest(data,selectCourseUser,usertype){
   return async(dispatch ,getState)=> {

         try {
            let obj={};
            obj.email=data.email;
            obj.courseId=data.courseId;
            let response = await customHttp.put('/api/user/enableCourse/',obj);
            dispatch(UserActions.setBooleanValue("successSnackbarMessage","User accepted successfully"))
            snackbar.successSnackbar();
            dispatch(getUsersByType(selectCourseUser,usertype));

          } catch (err) {

            dispatch(UserActions.setBooleanValue("errorSnackbarMessage","User is not accepted"))
            snackbar.errorSnackbar();
            console.log("error",err)
          }

   }
}

export function rejectUserContent (obj,selectCourseUser,usertype){

   return (dispatch ,getState)=> {
      // console.log("rejectUserContent",obj.userId,obj.courseId);
      var url = '/api/user/courses/userId/'+obj.userId+'/courseId/'+obj.courseId ;
      customHttp.delete(url)
     .then(response=>{
         // console.log("response",response)
         dispatch(UserActions.setBooleanValue("successSnackbarMessage","User rejected successfully"))
         snackbar.successSnackbar();
         dispatch(getUsersByType(selectCourseUser,usertype));
         // dispatch(getUsersByCourse(selectCourseUser));

     })
     .catch(error => {
   //    dispatch(RequestedContentActions.getRequestedContent([]))
    console.log("error",error)
      })

 }
}

export const searchFilter=(data)=>{
      return (dispatch ,getState)=> {  
         var dublicateArray=getState().userState.dupTotalUsrerList;
      
         if(data !==""){
         var searchRequestedArray=dublicateArray.filter(el =>{
            if(el.username!==undefined){
               return el.username.toLowerCase().indexOf(data.toString().toLowerCase()) !== -1;}
         })
         dispatch(UserActions.searchRequestedContent(searchRequestedArray))
      }
         else {
            dispatch(UserActions.searchRequestedContent(dublicateArray))}
         
       }
    }

    export const setSearchUsername=(value)=>{
      return (dispatch ,getState)=> {  
        dispatch(UserActions.setSearchUserName(value))
      }
    }

    export function fileUpload(courseArray,uploadFile,popUpId,usertype){
      return async(dispatch ,getState)=> {
         const formData = new FormData();
         const configuration = { headers: { "Content-Type": "multipart/form-data" } };
         var userDetails=JSON.parse(sessionStorage.getItem("userData"))
         // var tmp = [];
         // tmp.push(courseArray)
         // var array = tmp[0]
         // console.log("tmp",tmp,array)
         var dummyArray=[]
         var dublicateCourses=getState().userState.allCourses;
         for(var k=0;k<dublicateCourses.length;k++){
            if(dublicateCourses[k].isSelected){
               dummyArray.push(dublicateCourses[k]._id)
            }
         }
         //console.log("dummyArray",dummyArray)
         formData.append('belongsTo',userDetails.belongsTo);
         formData.append('adminId',userDetails._id);
         for (var i = 0; i < dummyArray.length; i++) {
            formData.append('courses[]', dummyArray[i]);
         }
         //formData.append('courses[]',tmp[0]);
         if(getState().userState.enteredDomainName === "" || getState().userState.enteredDomainName === undefined || getState().userState.enteredDomainName === undefined ){
            formData.append('domainName',"platifi.com");
         }
         else{
            formData.append('domainName',getState().userState.enteredDomainName);
            // formData.append('domainName',"platifi.com");
         }
         formData.append('file',uploadFile);
         console.log("formData",formData.getAll("belongsTo"), formData.getAll("adminId"), formData.getAll("tmp"), formData.getAll("domainName"), formData.getAll("file"))
         if(dummyArray.length>0){
            try {
               dispatch(UserActions.setBooleanValue("userUpload_loader",false))
               let response = await customHttp.post('/api/csvToJson/csv',formData,configuration);
               // console.log("response",response.data.message);
               dispatch(UserActions.setBooleanValue("userUpload_loader",true))
               dispatch(UserActions.setBooleanValue("openUpload",false))
               dispatch(UserActions.setBooleanValue("successSnackbarMessage","File Uploaded successfully"))
               snackbar.successSnackbar();
               //dispatch(clearselecteCourseForCreateUser());
               document.getElementById(popUpId).style.display='none';
               dispatch(getUsersByCourse(courseArray));
               dispatch(getUsersByType(courseArray,usertype));
             } catch (err) {
               dispatch(UserActions.setBooleanValue("userUpload_loader",true))
               
               if(Object.keys(err.response).length === 0 || Object.keys(err.response.data).length === 0 || Object.keys(err.response.data.message).length === 0) {
                  dispatch(UserActions.setBooleanValue("errorSnackbarMessage","Error try again"))
               }else{
                  dispatch(UserActions.setBooleanValue("errorSnackbarMessage",err.response.data.message[0].error))
               }
               snackbar.errorSnackbar();
               // console.log("error",err)
             }
         }else{
               dispatch(UserActions.setBooleanValue("errorSnackbarMessage","Please select alteast one course"))
               snackbar.errorSnackbar();
         }
      }
   }
