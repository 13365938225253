
import * as AppstoreActionTypes from "../../../actionTypes";

const initialState = {
    selected:[],
    applicationArray:[],
    filterArray:[],
    iotFilterArray:[],
    applicationList:[],
    applicationName:'',
    categoryName:'All',
    application_loader:false,
    apps_filter_loader:false,
    message:"",
    // apps_filter_loader:false,
    showAllApps:true
  }

  export default function(state=initialState, action) {
    switch (action.type) {
         
    case AppstoreActionTypes.APPSTORE_GET_ALL_APPLICATIONS: {
        const { allApplications,applicationList_dummy} = action.payload;
          return {...state,applicationArray:allApplications,application_loader: true,apps_filter_loader:true,applicationList:applicationList_dummy,selected:[],categoryName:"All",showAllApps:true}}
           
    case AppstoreActionTypes.APPSTORE_FILTERED_CATEGORIES: {
        const {filterArray, iotFilterArray} = action.payload;
          return {...state,filterArray:filterArray,iotFilterArray:iotFilterArray}} 

    case AppstoreActionTypes.APPSTORE_ONCLICK_SELECT_ALL_APPS: {
        const {selected_dummy,applicationArray,applicationList} = action.payload;
          return {...state,selected:selected_dummy,applicationArray:applicationArray,applicationList:applicationList}}
            
    case AppstoreActionTypes.APPSTORE_FILTERED_APPLICATIONS_ARRAY: {
        const {applicationArray} = action.payload;
          return {...state,applicationArray:applicationArray,apps_filter_loader:true}}

    case AppstoreActionTypes.APPSTORE_SET_CATEGORY_NAME: {
        const {value} = action.payload;
          return {...state,categoryName:value,apps_filter_loader:false}}
               
    case AppstoreActionTypes.APPSTORE_SET_APPLICATION_NAME: {
        const {value} = action.payload;
          return {...state,applicationName:value,apps_filter_loader:false}}

    case AppstoreActionTypes.APPSTORE_SELECTED_APPLICATIONS: {
        const { dublicateApplicationArray,dublicateAapplicationList,dummy} = action.payload;
          return {...state,applicationArray:dublicateApplicationArray,applicationList:dublicateAapplicationList,selected:dummy}}
          
    case AppstoreActionTypes.APPSTORE_SET_MESSAGE: {
        const {msg} = action.payload;
          return {...state,message:msg}}

    case AppstoreActionTypes.APPSTORE_SELECT_TEMPLATE: {
        const {x} = action.payload;
          return {...state,showAllApps:x}}
           
    default:
          return state;
    }
  }