import * as viewPlaylistActionTypes from "../../../actionTypes";

const initialState = {
  playlistName:'',
  successAlertMessage:'',
  errorAlertMessage:'',
  playlistArray:[],
  selectedPlaylistTitle:{},
  selectedPlaylistSubtitle:{},
  resourceVideo:{},
  selectedTitle:{},
  selectedSubtitle:{},
}

export default function(state=initialState, action) { 
  switch (action.type) {
    case viewPlaylistActionTypes.VIEW_PLAYLIST_COMPONENT_ELEMENTS: {
      const {name,content } = action.payload;
      return {
        ...state,[name]:content
      }
    }
    default:
    return state;
  }
}