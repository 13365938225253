import React, {useEffect } from 'react';
import { connect } from "react-redux";
import * as userDashboardContainer from "../../container/actions/UserDashboard/action";
// import Store from "../../container/reducers/Store";
import { bindActionCreators } from 'redux';
import UserSlotUsage from './UserSlotUsage';
import UserCourseUsage from './UserCourseUsage/BookedVsUsedSlots'

function UserDashboard (props) {
  
  useEffect( () => {
    // console.log("hello",props);
    props.getAllCourses() 
  },[]);
  // onload function end

 
    return (
      <div className="row">
        <div className="col-md-12" style={{marginBottom:'20px'}}>
          <UserCourseUsage/>
        </div>
        <div className="col-md-12">
          <UserSlotUsage />
        </div>
      </div>
      
    );
}

const mapStateToProps = state => ({
  userDashboardState: state.userDashboardState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllCourses:userDashboardContainer.getAllCourses,
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(UserDashboard);
