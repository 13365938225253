import React, { useState, useEffect } from 'react';
import './UserProfile.scss';
import { connect } from "react-redux";
import * as userProfileContainer from "../../container/actions/UserProfile/action";
import * as Actions from "../../container/actions/UserProfile";
import { bindActionCreators } from 'redux';
import { Upload } from "./Upload";

function UserProfile (props) {
  const [profileDp,setProfileDp] = useState([]);
  const [clear,setClear] = useState({image:()=>{}})
  useEffect(()=>{
    //sessionStorage.setItem("userProfileData",true);
    props.setBooleanValue("userNameExist",false)
    props.fetchUserDetails();

  },[])

  const clearImage=(clear)=>{
    setClear({image:clear})
  }

  var handleChangeNumValidation=(e)=>{
    let a = JSON.parse(JSON.stringify(e.target.value));
    let numericRegex = /^[0-9]*$/;
    let numericRegexValidation = numericRegex.test(String(a))
    if(numericRegexValidation){
      props.handleChange(e)
    }
  }

  var handleChangeNameValidation=(e)=>{
    let a = JSON.parse(JSON.stringify(e.target.value));
    let numericRegex = /^[a-zA-Z ]*$/;
    let numericRegexValidation = numericRegex.test(String(a))
    if(numericRegexValidation){
      props.handleChange(e)
    }
  }

  return (
    <div>
      <div className="add-on-load-animation">
        <div id="successSnackbar">{props.userProfileState.successAlertMessage}</div>
        <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.userProfileState.errorAlertMessage}</div>
        <div className="col-md-8 col-md-offset-2 nj-card" style={{minHeight:"73.3vh"}}>
          <div className="row">
            <div className="nj-card__header">
              <div className="nj-card__title">
                <h4>Update Profile</h4>
              </div>
            </div>
          </div>
          {props.userProfileState.userProfileLoader ?

            <div className="row">
              <div className="col-md-12 apps_loader_style" style={{minHeight:"73.3vh"}}>
                <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" className="loader_img aligh_center_middle_userprofile"/>
              </div>
            </div>

            :

            <div>
              <div className="row" style={{marginTop:20,marginBottom:10}}>
                <div className="col-md-10 col-md-offset-1">
                  <div className="profile-img-wrap">
                    <div className="profile-img">
                      {props.userProfileState.isProfilePictureAdded?<img id="profileDp" src={"/"+props.userProfileState.profilePicture.path} alt=""/>:<img id="profileDp" src={'/img/profile/dummy.jpg'} alt="" />}
                      <span className="profile-image-update" style={{backgroundColor:`rgb(${props.userProfileState.profilePictureColor.r},'${props.userProfileState.profilePictureColor.g},${props.userProfileState.profilePictureColor.b})`}} onClick={()=>{document.getElementById('profileImagePopup').style.display='block'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10}
                          viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2}
                          strokeLinecap="round" strokeLinejoin="round"
                          className="feather feather-edit-2">
                          <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                        </svg>
                      </span>
                    </div>
                    <div className="profile-img-info-wrap">
                      <h4>{props.userProfileState.profileData.username}</h4>
                      {props.userProfileState.isProfilePictureAdded ? <a href="# " onClick={()=>{document.getElementById('deleteImagePopup').style.display='block'}}>Remove avatar</a>:
                      <a href="# " onClick={()=>{document.getElementById('profileImagePopup').style.display='block'}}>Upload avatar</a>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-10 col-md-offset-1 tbl-content" style={{marginTop:20}}>
                  <form name="profileForm" onSubmit={props.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 col-xs-12 col-sm-12" style={{marginBottom:30}}>
                        <div className="col-md-12 col-sm-12" >
                          <label className="idams-label pull-left" style={{display:'block',width:'100%',fontSize:14}}>First Name</label>
                          <input type="text" className="pull-left vs-input--input form-control" name="firstname" value={props.userProfileState.profileData.firstname} disabled={!props.userProfileState.isEditable} onChange={(e)=>handleChangeNameValidation(e)} /* style={{maxWidth:270}} */ placeholder="Ex:John" autoComplete="off" required />
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-12 col-sm-12" style={{marginBottom:30}}>
                        <div className="col-md-12 col-sm-12">
                          <label className="idams-label pull-left"  style={{display:'block',width:'100%',fontSize:14}}>Last Name</label>
                          <input type="text" className="pull-left vs-input--input form-control" name="lastname" value={props.userProfileState.profileData.lastname} disabled={!props.userProfileState.isEditable} onChange={(e)=>handleChangeNameValidation(e)} /* style={{maxWidth:270}} */ placeholder="Ex:Doe" autoComplete="off" required />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-xs-12 col-sm-12" style={{marginBottom:30}}>
                        <div className="col-md-12 col-sm-12" >
                          <label className="idams-label pull-left" style={{display:'block',width:'100%',fontSize:14}}>Username</label>
                          <input type="text" className="pull-left vs-input--input form-control" name="username" maxLength="20"  value={props.userProfileState.profileData.username} disabled={props.userProfileState.profileUsername} onChange={props.handleChange} /* style={{maxWidth:270}} */ placeholder="Ex:johndoe" autoComplete="off" required />
                          {props.userProfileState.userNameExist && <span className="required_field_color_red font_family_montserrat" style={{fontSize:'12px',fontWeight:'500'}}>Username is taken!</span>}
                          {props.userProfileState.usernameMustbeAlphnumeric && <span className="required_field_color_red font_family_montserrat" style={{fontSize:'12px',fontWeight:'500'}}>lowercase alphabets, numbers</span>}
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-12 col-sm-12" style={{marginBottom:30}}>
                        <div className="col-md-12 col-sm-12" >
                          <label className="idams-label pull-left" style={{display:'block',width:'100%',fontSize:14}}>Phone Number</label>
                          <input type="text" className="pull-left vs-input--input form-control" name="phoneNumber" value={props.userProfileState.profileData.phoneNumber} disabled={!props.userProfileState.isEditable} onChange={(e)=>handleChangeNumValidation(e)} /* style={{maxWidth:270}} */ placeholder="Ex:0123456789" autoComplete="off" required />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-xs-12 col-sm-12" style={{marginBottom:30}}> 
                        <div className="col-md-12 col-sm-12" >
                          <label className="idams-label pull-left" style={{display:'block',width:'100%',fontSize:14}}>Location</label>
                          <input type="text" className="pull-left vs-input--input form-control" name="location" value={props.userProfileState.profileData.location} disabled={!props.userProfileState.isEditable} onChange={props.handleChange} /* style={{maxWidth:270}} */ placeholder="Ex:Bengaluru" autoComplete="off" required />
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-12 col-sm-12" style={{marginBottom:30}}>
                        <div className="col-md-12 col-sm-12" >
                          <label className="idams-label pull-left" style={{display:'block',width:'100%',fontSize:14}}>Postal Code</label>
                          <input type="text" className="pull-left vs-input--input form-control" name="postalCode" value={props.userProfileState.profileData.postalCode} disabled={!props.userProfileState.isEditable} onChange={(e)=>handleChangeNumValidation(e)} /* style={{maxWidth:270}} */ placeholder="Ex:560001" autoComplete="off" required />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="profile-button">
                        {props.userProfileState.isEditable?<span>{props.userProfileState.submitLoader?<img src={"/img/loaders/spinningwheel.gif"} height={50} width={50} style={{marginTop:'-10px',textAlign:'center'}} alt="loader" />:<button type="submit" className="nj-flow-button" disabled={props.userProfileState.userNameExist || props.userProfileState.usernameMustbeAlphnumeric } style={{padding:'0.75rem 3rem',marginBottom:10}}>Save Changes</button>}</span>:
                        <button type="button" className="nj-flow-button" style={{padding:'0.75rem 3rem',marginBottom:10}} onClick={props.enableEditProfile}>Edit Profile</button>}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          }
        </div>

        {/*popup to upload profile pic*/}
        <div id="profileImagePopup" className={"nj-overly add-rebound-animation"}>
          <div className="popup-nj  my-popup" style={{maxWidth:"400px"}}>
            <header className="popup-header font_family_montserrat">
              <span className="font_family_montserrat">Upload Image</span>
              <span ><i className="popup-close material-icons" onClick={()=>{clear.image();document.getElementById('profileImagePopup').style.display='none';}}>close</i></span>
            </header>
            {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
            <div className="popup-content">
              <form >
              <div className="col-md-12 font_family_montserrat">
                <Upload
                  onDrop={(files) => {
                    setProfileDp(files)
                  }}
                  clearFiles={clearImage}
                />
                {props.userProfileState.imageLoader?<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" height={50} width={50} style={{marginTop:'0px'}} alt="loader" />:
                <button type="button" className="pull-right nj-flow-button" style={{marginTop:10}} onClick={()=>props.uploadImage(profileDp,clear)}>Upload</button>}
              </div>
              </form>
            </div>
          </div>
        </div>
        {/*popup to delete profile pic */}
        <div id="deleteImagePopup" className={"nj-overly add-rebound-animation"}>
          <div className="popup-nj  my-popup" style={{maxWidth:"400px"}}>
            <header className="popup-header font_family_montserrat">
              <span className="font_family_montserrat">Remove Image</span>
              <span ><i className="popup-close material-icons" onClick={()=>{document.getElementById('deleteImagePopup').style.display='none';}}>close</i></span>
            </header>
            {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
            <div className="popup-content">
              <div className="col-md-12 font_family_montserrat">
                <p>Do you really want to delete?</p>
                {props.userProfileState.imageLoader?<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" height={50} width={50} style={{marginTop:'0px'}} alt="loader" />:
                <button type="button" className="pull-right nj-flow-button" onClick={props.deleteImage}>Delete</button>}
                <button type="button" className="pull-right nj-flow-button bg-color-red" style={{marginRight:10}} onClick={()=>{document.getElementById('deleteImagePopup').style.display='none';}}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
  userProfileState: state.userProfileState,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchUserDetails:userProfileContainer.fetchUserDetails,
  setBooleanValue:Actions.setBooleanValue,
  // getUserProfileInfo:userProfileContainer.getUserProfileInfo,
  uploadImage:userProfileContainer.uploadImage,
  handleChange:userProfileContainer.handleChange,
  // handleChangeForUsername:userProfileContainer.handleChangeForUsername,
  handleSubmit:userProfileContainer.handleSubmit,
  deleteImage:userProfileContainer.deleteImage,
  enableEditProfile:userProfileContainer.enableEditProfile,
  setUserProfileElements:Actions.setUserProfileElements
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(UserProfile);
