import React, {useEffect } from 'react';
import { connect } from "react-redux";
import * as UserCoursesContainer from "../../container/actions/UserCourses/action";
// import Store from "../../container/reducers/Store";
import * as Actions from "../../container/actions/UserCourses";
import './userCourses.scss';
import UserResources from "./userResources";
import { bindActionCreators } from 'redux';
import Rating from "../../packages/Ratings";
import customHttp from '../../packages/CustomHttp'

function UserCourses (props) {

  // const [userInfo, SetUserInfo] = useState(JSON.parse(sessionStorage.getItem("userData")));
  // const [showResourcesList,SetshowResourcesList]=useState(false)

 var showResourcesListFunction=(item)=>{
   console.log("item",item)
    props.showResourcesList(false)
    props.showRequested(false)
    //setCourseObj(item)
    sessionStorage.setItem("userId",item.userId);
    sessionStorage.setItem('userCourse',JSON.stringify(item))
    props.onClickSendCourseAndUserId(item);
  }

  useEffect( () => {
    // console.log("hello",props)
    props.getAllCourses();
    document.getElementById('ratingPopup').style.display='none';
    document.getElementById('editRatingPopup').style.display='none';
    props.showResourcesList(true);
    props.showRequested(true)

  },[]);
  // onload function end
  var getRatings=(ratings)=>{
    props.setDynamicValues("rating",ratings)
  }
  var setCourseRatings=(ratings)=>{
    props.setEditCourseRatings("ratings",ratings)
  }
  var clrearSelectedRatings=()=>{
    props.setDynamicValues("rating","")
    props.setDynamicValues("ratingDescription","")
    props.setDynamicValues("showRatingLoader",false)
    document.getElementById('editRatingPopup').style.display='none';
    document.getElementById('ratingPopup').style.display='none';
  }
  var clrearSelectedCourseRatings=()=>{
    props.setDynamicValues("editCourseRatingObject",{})
    document.getElementById('editRatingPopup').style.display='none';
    document.getElementById('ratingPopup').style.display='none';
  }
  var getSelectedCourseObject=(item)=>{
    props.setDynamicValues("selectedObject",item)

    props.checkCourseRatings();
    //document.getElementById('ratingPopup').style.display='block';

  }

  var saveCourseObj=(item)=>{
    localStorage.setItem("courseObj",JSON.stringify(item));
  }
  var goToLiveClassroom=async (obj)=>{
    var importantStuff = window.open('', '_blank'); //imediately create a blank page
    importantStuff.document.write('Connecting...'); // add some text to the window opened
    var userDetails=JSON.parse(sessionStorage.getItem("userData"));
    try {
      var response = await customHttp.get('/api/liveStream/meeting/courseId/'+obj._id);
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      if(response.data.message){
        var meetingId = response.data.message.meetingId;
        var password = response.data.message.meetingPassword;
        console.log("meeting id",meetingId)
        importantStuff.location.href = `https://platifivccloud.platifi.com/client/${userDetails.username}/${meetingId}/${password}`; //filling it with content when ready
      }
    } catch(err){
      console.log("error",err)
    }
  }

    return (

      <div className="add-on-load-animation">
        {/* {console.log("props.userCoursesState.allEnabledCourses",props.userCoursesState.allEnabledCourses,props.userCoursesState.allRequestedCourses)} */}
      <div id="successSnackbar">{props.userCoursesState.successSnackbarMessage}</div>
      <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.userCoursesState.errorSnackbarMessage}</div>

      <div className="row">
      <div className="col-md-12">

      {props.userCoursesState.showResourcesList ?
        <div className="col-md-12 nj-card">
          <div className="row">
            <div className="nj-card__header">
              <div className="nj-card__title">
                <h4>Courses</h4>
              </div>
            </div>
          </div>

         {props.userCoursesState.availableUserCoursesLoader?
             <div className="course-table-margin-top">

                {props.userCoursesState.allEnabledCourses.length>0 ? <div className="row">
                  <div>
                     <div className="tbl-content" style={{maxHeight:'36vh',overflowY:'auto',overflowX:"auto",marginRight:"10px"}}>
                       <div className="resources-wrapper">
                          {props.userCoursesState.allEnabledCourses.map((item, i) => {
                              return (
                                  <div className="resource_card add-rebound-animation"  key={i} onClick={(e)=>{saveCourseObj(item)}}>
                                    <div className="resource_card-content" style={{cursor:"pointer"}} >
                                      {item.isLiveClassRoom && item.isLive && (
                                      <div className="resource-card-float">
                                         <div className="overlay_checkbox resource-card-float-element liveClassroom-image font_family_montserrat" onClick={(e)=>{goToLiveClassroom(item)}}>
                                          <img className="" src={"/img/live-course.gif"} alt="Cource" /> Live
                                        </div>
                                      </div>
                                      )}
                                       <div className="resource_card-content-img-wrapper" onClick={(e)=>showResourcesListFunction(item)}>
                                           <img className="resource_card-content-img" src={"/img/resource-icons/folder.svg"} alt="Cource" />
                                      </div>
                                        <div className="resource-card-float" style={{marginTop:'-25px',paddingRight:5}}>
                                          <div className="resource-card-float-element" style={{backgroundColor:'rgb(99, 112, 173)',color:'#fff',fontWeight:'600',padding:'1px 6px',borderRadius:"50%",fontSize:14}} title="Rate Here" onClick={(e)=>{getSelectedCourseObject(item)}}>
                                            <span>
                                              <svg xmlns="http://www.w3.org/2000/svg"
                                                width={12}
                                                height={12}
                                                viewBox="0 0 24 20"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-star"
                                                style={{fill:'#fff'}}
                                              >
                                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                              </svg>
                                            </span>
                                          </div>

                                          <div className="pull-left" style={{marginLeft:"5px"}}>
                                            <span style={{color:"rgb(99, 112, 173)",verticalAlign:"middle"}} title="Time investment in minutes" >
                                              <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock">
                                                <circle cx={12} cy={12} r={10} />
                                                <polyline points="12 6 12 12 16 14" />
                                            </svg>
                                            <span style={{verticalAlign:"top",fontSize:11,paddingLeft:2}}>{parseFloat(parseFloat(parseInt(item.resourceTimeInvestment || 0)/60).toPrecision(3))+" Min"}</span>
                                            </span>
                                        </div>
                                      </div>
                                    </div>
                                     <div className="resource_card-footer" style={{cursor:"pointer"}} onClick={(e)=>showResourcesListFunction(item)}>
                                          <p className="font_family_montserrat">{item.displayName}</p>
                                    </div>
                                 </div>
                             )})
                           }
                      </div>
                    </div>
                  </div>
                </div>
                :
                    <div className="row">
                      <div className="no-resource-text">
                        <div>No Courses Found</div>
                      </div>
                    </div>
                 }
              </div>
            :
                <div className="row" style={{height:"36vh"}}>
                   <div className="col-md-12 aligh-loader-image-center-userCourse" >
                      <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                  </div>
               </div>
            }
             </div>
             :
             <div>
                <UserResources history={props.history}/>
             </div>
          }

          {/* Requested Courses */}
          {props.userCoursesState.showRequested ?
          <div className="col-md-12 nj-card" style={{marginTop: 10,marginBottom: 10}}>
            <div className="row">
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4>Requested Courses</h4>
                </div>
              </div>
            </div>

            {props.userCoursesState.availableUserCoursesLoader?
            <div className="course-table-margin-top">

               {props.userCoursesState.allRequestedCourses.length>0 ? <div className="row">
                 <div>
                    <div className="tbl-content" style={{maxHeight:'36vh',overflowY:'auto',overflowX:"auto",marginRight:"10px",textAlign:"center"}}>
                     <div style={{display:"flex",flexWrap:"wrap",justifyContent : "space-evenly",paddingLeft:"10px",paddingRight:"10px"}}>
                      {props.userCoursesState.allRequestedCourses.length > 0 &&
                        <div className="alert alert-success" style={{marginTop:"5px",textAlign:"center",width:"500px",padding:"5px"}}>
                          Please contact your professor to enable below course.
                        </div>
                      }
                      </div>
                      <div className="resources-wrapper">
                         {props.userCoursesState.allRequestedCourses.map((item, i) => {
                             return (
                                 <div className="resource_card add-rebound-animation"  key={i} style={{cursor: 'default'}}>
                                   <div className="resource_card-content">
                                      <div className="resource_card-content-img-wrapper">
                                          <img className="resource_card-content-img" src={"/img/resource-icons/folder.svg"} alt="Cource" />
                                     </div>
                                       <div className="resource-card-float" style={{marginTop:'-25px',paddingRight:5}}>
                                         <div className="resource-card-float-element" style={{backgroundColor:'rgb(99, 112, 173)',color:'#fff',fontWeight:'600',padding:'1px 6px',borderRadius:"50%",fontSize:14}}>
                                           <span>
                                             <svg xmlns="http://www.w3.org/2000/svg"
                                               width={12}
                                               height={12}
                                               viewBox="0 0 24 20"
                                               fill="none"
                                               stroke="currentColor"
                                               strokeWidth={2}
                                               strokeLinecap="round"
                                               strokeLinejoin="round"
                                               className="feather feather-star"
                                               style={{fill:'#fff'}}
                                             >
                                               <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                             </svg>
                                           </span>
                                         </div>

                                         <div className="pull-left" style={{marginLeft:"5px"}}>
                                           <span style={{color:"rgb(99, 112, 173)",verticalAlign:"middle"}}>
                                             <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock">
                                               <circle cx={12} cy={12} r={10} />
                                               <polyline points="12 6 12 12 16 14" />
                                           </svg>
                                           <span style={{verticalAlign:"top",fontSize:11,paddingLeft:2}}>{parseFloat(parseFloat(parseInt(item.resourceTimeInvestment || 0)/60).toPrecision(3))+" Min"}</span>
                                           </span>
                                       </div>
                                     </div>
                                   </div>
                                    <div className="resource_card-footer" style={{cursor: 'default'}}>
                                         <p className="font_family_montserrat">{item.displayName}</p>
                                   </div>
                                </div>
                            )})
                          }
                     </div>
                   </div>
                 </div>
               </div>
               :
                   <div className="row">
                     <div className="no-resource-text">
                       <div>No Courses Found</div>
                     </div>
                   </div>
                }
             </div>
             :
                 <div className="row" style={{height:"36vh"}}>
                    <div className="col-md-12 aligh-loader-image-center-userCourse" >
                       <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                   </div>
                </div>
             }

          </div>
          :
          null}

          </div>
          </div>

        {/*popup to ratings*/}
        <div id="ratingPopup" className={"nj-overly add-rebound-animation"}>
           <div className="popup-nj  my-popup" style={{maxWidth:"400px"}}>
             <header className="popup-header font_family_montserrat">
               <span className="font_family_montserrat">Write a Review</span>
               <span ><i className="popup-close material-icons" onClick={clrearSelectedRatings}>close</i></span>
            </header>
             {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
             <div className="popup-content">
              <div className="col-md-12 font_family_montserrat">
                <h4  className="font_family_montserrat rating-title-h4">Rate this course </h4>
                <Rating getRatings={getRatings} width="36" height="36" color="rgba(115,103,240)" rating={props.userCoursesState.rating} key={props.userCoursesState.rating}  classname="rating-props-container" /> {props.userCoursesState.rating>0&&(<span className="pull-right rating-expression-span" >{(props.userCoursesState.rating|| "0")+"/5"}</span>)}
                <br/>
                <br/>
                <h4  className="font_family_montserrat rating-title-h4">Write your own review in the following:</h4>
                <textarea className="textarea-input-css font_family_montserrat" rows="5" cols="48" placeholder=" Write something..." value={props.userCoursesState.ratingDescription} onChange={(e)=>{props.setDynamicValues("ratingDescription",e.target.value)}} style={{marginTop:"10px"}}></textarea>
                <br/>
                <br/>
                <div className="">
                  {!props.userCoursesState.showRatingLoader ? <button  type="button" className="nj-flow-button font_family_montserrat" style={{color: "white",textAlign:"center",width:"100%",lineHeight:"2.2"}} onClick={props.submitRatingsForCourse}>SUBMIT REVIEW</button> :<img src={"/img/loaders/spinningwheel.gif"}  className="" height={50} width={50} style={{color: "white",textAlign:"center"}} alt="loader" />}
                </div>
                <br/>
              </div>
            </div>
          </div>
        </div>

         {/*popup to EditRatings*/}
         <div id="editRatingPopup" className={"nj-overly add-rebound-animation"}>
           <div className="popup-nj  my-popup" style={{maxWidth:"400px"}}>
             <header className="popup-header font_family_montserrat">
               <span className="font_family_montserrat">Edit a Review</span>
               <span ><i className="popup-close material-icons" onClick={clrearSelectedCourseRatings}>close</i></span>
            </header>
             {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
             <div className="popup-content">
              <div className="col-md-12 font_family_montserrat">
                <h4  className="font_family_montserrat rating-title-h4">Rate this course </h4>
                <Rating getRatings={setCourseRatings} width="36" height="36" color="rgba(115,103,240)" rating={props.userCoursesState.editCourseRatingObject.ratings} key={props.userCoursesState.editCourseRatingObject._id}  classname="rating-props-container" /> {props.userCoursesState.editCourseRatingObject.ratings>0&&(<span className="pull-right rating-expression-span" >{(props.userCoursesState.editCourseRatingObject.ratings|| "0")+"/5"}</span>)}
                <br/>
                <br/>
                <h4  className="font_family_montserrat rating-title-h4">Write your own review in the following:</h4>
                <textarea className="textarea-input-css font_family_montserrat" rows="5" cols="48" placeholder=" Write something..." value={props.userCoursesState.editCourseRatingObject.reviews} onChange={(e)=>{props.setEditCourseRatings("reviews",e.target.value)}} style={{marginTop:"10px"}}></textarea>
                <br/>
                <br/>
                <div className="">
                  {!props.userCoursesState.showEditCourseRatingLoader ? <button  type="button" className="nj-flow-button font_family_montserrat" style={{color: "white",textAlign:"center",width:"100%",lineHeight:"2.2"}} onClick={props.UpdateCourseRatings}>SUBMIT REVIEW</button> :<img src={"/img/loaders/spinningwheel.gif"}  className="" height={50} width={50} style={{color: "white",textAlign:"center"}} alt="loader" />}
                </div>
                <br/>
              </div>
            </div>
          </div>
        </div>
    </div>

    );
  }


const mapStateToProps = state => ({
  userCoursesState: state.userCoursesState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllCourses:UserCoursesContainer.getAllCourses,
  showResourcesList:Actions.showResourcesList,
  setDynamicValues:Actions.setDynamicValues,
  submitRatings:UserCoursesContainer.submitRatings,
  submitRatingsForCourse:UserCoursesContainer.submitRatingsForCourse,
  checkCourseRatings:UserCoursesContainer.checkCourseRatings,
  setEditCourseRatings:Actions.setEditCourseRatings,
  UpdateCourseRatings:UserCoursesContainer.UpdateCourseRatings,
  onClickSendCourseAndUserId:UserCoursesContainer.onClickSendCourseAndUserId,
  showRequested:Actions.showRequested,
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(UserCourses);
