import * as RequestedContentActionTypes from "../../../actionTypes";

const initialState = {
  RequestedContent_loader:false,
  RequestedContent : [],
  dupRequestedContent : [],
  shouldPopupAnimate:false,
  savedComments:{},
  successSnackbarMessage:"",
  errorSnackbarMessage:"",
  disciplines: [],
  contentTypes: [],
  resource: {name:'',discipline:'',contentType:'',file:{} ,tags:"" },
  showResourceCreateLoader:false,
  showLoadResourceLoader:false,
  createResourceDisciplines:[],
  createResourceContentTypes:[],
  selectedRequestedContentRow:{},
  selectedIndex:0
    
}

export default function(state=initialState, action) {
  switch (action.type) {
    

    case RequestedContentActionTypes.DLT_REQUESTEDCONTENT_GET_REQUESTEDCONTENT: {
        const {content} = action.payload; 
        return {
          ...state,RequestedContent:content.reverse(),dupRequestedContent:content,RequestedContent_loader:true,
        }
      }
      
      case RequestedContentActionTypes.DLT_REQUESTEDCONTENT_ONCHANGE_ADDCOMMENTS: {
        const{comment,index} = action.payload;
        // console.log("comment,dateee",comment,date);
        var RequestedContent1=state.RequestedContent;
        
        RequestedContent1[index]=comment
        
        return {
          ...state,RequestedContent :RequestedContent1

        }
      }

      case RequestedContentActionTypes.DLT_REQUESTEDCONTENT_GET_REQUESTEDCOMMENT: {
        const {content} = action.payload; 
        return {
          ...state,savedComments:content
        }
      }

      case RequestedContentActionTypes.DLT_REQUESTEDCONTENT_SEARCH_REQUESTED_CONTENT: {
        const {searchRequestedArray} = action.payload; 
        return {
          ...state,RequestedContent:searchRequestedArray
        }
      }

  case RequestedContentActionTypes.DLT_REQUESTEDCONTENT_SET_BOOLEAN_VALUE: {
    // console.log("snackbar",action.payload);
    const {name,value} = action.payload; 
    return {
      ...state,[name]:value
    }
  }

  case RequestedContentActionTypes.DLT_RESOURCE_DISCIPLINES: {
    const {content } = action.payload; 
    return {
      ...state,disciplines:content
    }
  }
  case RequestedContentActionTypes.DLT_RESOURCE_CONTENT_TYPES: {
    const {content } = action.payload; 
    return {
      ...state,contentTypes:content
    }
  }

  case RequestedContentActionTypes.DLT_RESOURCE_COMPONENT_ELEMENTS: {
    const {name,content } = action.payload; 
    return {
      ...state,[name]:content
    }
  }
  case RequestedContentActionTypes.DLT_SET_REQUESTEDCONTENT_STATUS: {
    const {status } = action.payload; 
    return {
      ...state,status:status
    }
  }
    default:
      return state;
  }
}