import React, {Component} from "react";
import './RemoteProctoring.scss';
import * as faceapi from 'face-api.js'
import { connect } from "react-redux";
import * as accessLabContainer from "../../container/actions/AccessLab/action";
import { bindActionCreators } from 'redux';

class FaceRecognition extends Component{
    constructor(props){
        super(props);
        this.setState = {
             
        }
    }
    
    makeid=(length)=> {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
               result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
    }

    dataURLtoFile=(dataurl, filename)=> {
        var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
        
        while(n--){
        u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    } 
    
   
    render(){
        return(
            <div>
                <div id="container">
                <h5 className= "font_family_montserrat">The lighting in the room must be bright enough to be considered <strong>“daylight” quality.</strong>Your face should be straight towards webcam & wait untill it <strong>captures the image.</strong> </h5>
                <video autoPlay={true} id="videoElement"  width= "540" height= "auto" style={{margin:"0px", padding:"0px"}}></video>
                <canvas id="canvasElement" ref="canvas" width= "540" height= "440" style={{marginTop :"-526px", position:"relative", zIndex:"2px"}}></canvas>
                </div>
                {/* <button type="button" className="nj-flow-button"  style={{margin:5}} onClick={this.props.streamCamVideo}>Start streaming</button> */}
                {/* <button type="button" className="nj-flow-button"  style={{margin:5}} onClick={this.props.captureImage}>Capture</button> */}
                {/* <button type="button" className="nj-flow-button"  style={{margin:5}} onClick={this.props.UploadWebcamImage}>Upload</button>     */}
                
            </div>
        )
    }
}
//  export default FaceRecognition;

 const mapStateToProps = state => ({
    accessLabState: state.accessLabState,
  });
  
  const mapDispatchToProps = dispatch => bindActionCreators({
    streamCamVideo:accessLabContainer.streamCamVideo,
    captureImage:accessLabContainer.captureImage,
    UploadWebcamImage:accessLabContainer.UploadWebcamImage
    
  }, dispatch)
  
  export default connect(
    mapStateToProps,mapDispatchToProps
  )(FaceRecognition);
  

  

