
// import axios from "axios";
import * as AppstoreActions from "./index";
import LazyLoadImage from '../../../packages/LazyLoadImages/';
import customHttp from '../../../packages/CustomHttp/';

export function getAllApplications(){
  return (dispatch ,getState)=> {
   
     var url = '/api/applications/all' ;
     customHttp.get(url )
        .then(  response=>{
          response.data.message.forEach(function(element) { element.isSelected = false});
          var applicationList_dummy=JSON.parse(JSON.stringify(response.data.message));
          dispatch(AppstoreActions.getAllApplications( response.data.message,applicationList_dummy))
          var filterArray = [{"name":"All","group":""},{"name":"AI IoT CoE Tools","group":"AI IoT CoE Tools"}];
          var iotFilterArray=[];
          for(var i=0;i<response.data.message.length;i++){
            if((response.data.message[i].group === "Languages") || (response.data.message[i].group ==="Development Tools") || (response.data.message[i].group ==="ML & AI Tools")
            || (response.data.message[i].group ==="Data Exploration & Visualization") || (response.data.message[i].group ==="Data Platform and Ingestion Tools") ){
              iotFilterArray.push({"name":response.data.message[i].group,"group":response.data.message[i].group})
            }else{
              filterArray.push({"name":response.data.message[i].group,"group":response.data.message[i].group})
            }
          }
          // function to filter unique array
          function uniqueCity(array) {
            var processed = [];
            for (var i=array.length-1; i>=0; i--){
              if (processed.indexOf(array[i].name)<0) {
                processed.push(array[i].name);
              } else {
                array.splice(i, 1);
              }
            }
            return array;
          }
            
          filterArray = uniqueCity(filterArray);
          iotFilterArray=uniqueCity( iotFilterArray);
          // console.log("iottt",iotFilterArray);
          dispatch(AppstoreActions.filteredCategories(filterArray,iotFilterArray))
          var className="lazyImageTag"    // clsasName is img tag className
          var thresholdValue=0.3    // thresholdValue must be b/w 0 or 1
          LazyLoadImage.loadImages(className,thresholdValue) // LazyLoadImage function 
               
     })
     .catch(error => {
      dispatch(AppstoreActions.getAllApplications([],[]))
      console.log("error",error)    
     });
  
  }
}


export const selectApplication=(id)=>{
  return (dispatch ,getState)=> { 
    var dublicateAapplicationList=JSON.parse(JSON.stringify(getState().appstore.applicationList))
    for(var k=0;k<dublicateAapplicationList.length;k++){
      if(dublicateAapplicationList[k]._id===id._id){
        dublicateAapplicationList[k].isSelected = !dublicateAapplicationList[k].isSelected;
      }
    }
    var dublicateApplicationArray=JSON.parse(JSON.stringify(getState().appstore.applicationArray))
    for(var l=0;l<dublicateApplicationArray.length;l++){
      if(dublicateApplicationArray[l]._id===id._id){
        dublicateApplicationArray[l].isSelected= !dublicateApplicationArray[l].isSelected;
      }
    }
    var dummy=[];
    for(var m=0;m<dublicateApplicationArray.length;m++){
      if(dublicateApplicationArray[m].isSelected){
        dummy.push(dublicateApplicationArray[m]);
      }
    }
    dispatch(AppstoreActions.selected_Applications(dublicateApplicationArray,dublicateAapplicationList,dummy))
  }
}
     
export const  handleSelectAllClick=(event)=>{
  return (dispatch ,getState)=> {  
    // console.log("hh",getState())
    if (event.target.checked) {
      getState().appstore.applicationArray.forEach(element => {
        element.isSelected=true;
      });
      getState().appstore.applicationList.forEach(element => {
        element.isSelected=true;
      });
      var selected_dummy= getState().appstore.applicationArray.map(n => n);
      dispatch(AppstoreActions.selectAllApplications( selected_dummy, getState().appstore.applicationArray, getState().appstore.applicationList))
      return;
    }
    dispatch(AppstoreActions.selectAllApplications( [], getState().appstore.applicationArray, getState().appstore.applicationList))
    getState().appstore.applicationArray.forEach(element => {
      element.isSelected=false;
    });
    getState().appstore.applicationList.forEach(element => {
      element.isSelected=false;
    });
  }
}

export const getAllSelectedApplications=(props)=>{
  return (dispatch ,getState)=> {  
    var uniqueNames = [];
    var uniqueObj = [];
    let selectedApplications=[]
    for(var m=0;m<getState().appstore.applicationList.length;m++){
      if(getState().appstore.applicationList[m].isSelected===true){
        selectedApplications.push(getState().appstore.applicationList[m])
      }
    }
    if(selectedApplications.length>0){
      var selectedAppsFromAppsStore =JSON.parse(localStorage.getItem("appsSelected"));
      if(selectedAppsFromAppsStore !== undefined && selectedAppsFromAppsStore !== null){
        for(var k=0;k<selectedApplications.length;k++){
          selectedAppsFromAppsStore.push(selectedApplications[k])
        }
        for(var i=0; i< selectedAppsFromAppsStore.length; i++){
          if(uniqueNames.indexOf(selectedAppsFromAppsStore[i]._id) === -1){
              uniqueObj.push(selectedAppsFromAppsStore[i])
              uniqueNames.push(selectedAppsFromAppsStore[i]._id);
          }
        }
      } else{
        for(var j=0; j< selectedApplications.length; j++){
          if(uniqueNames.indexOf(selectedApplications[j]._id) === -1){
              uniqueObj.push(selectedApplications[j])
              uniqueNames.push(selectedApplications[j]._id);
          }
        } 
      } 
      // console.log("apps selected",uniqueObj)
      localStorage.setItem("appsSelected", JSON.stringify(uniqueObj));
      localStorage.setItem("fromAppStore",true);
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      if(userDetails.type===1){
        props.history.push("/create-course");
      } else if(userDetails.type===2){
        props.history.push("/dlt/create-course")
      } else{
        props.history.push("/sub-admin/create-course")
      }
    } else{
      dispatch(set_Snackbar_Message("error_Snackbar","Please select at least one application"))
    }
  }
}

export const filterApplications=()=>{
  return (dispatch ,getState)=> { 
    var className="lazyImageTag"    // clsasName is img tag className
    var thresholdValue=0.3    // thresholdValue must be b/w 0 or 1 
    // console.log("categoryName",getState().appstore.categoryName)
    if(getState().appstore.categoryName==="All"){
      dispatch(AppstoreActions.selecttemplate(true))
      var dublicateArray=JSON.parse(JSON.stringify(getState().appstore.applicationList))
      var applicationArray1=dublicateArray.filter(el =>{
        return el.name.toLowerCase().indexOf(getState().appstore.applicationName.toString().toLowerCase()) !== -1;
      })
      dispatch(AppstoreActions.filtered_ApplicationsArray(applicationArray1))
      setTimeout(function(){
        LazyLoadImage.loadImages(className,thresholdValue) // LazyLoadImage function  
      });
    }
    else if((getState().appstore.categoryName==="AI IoT CoE Tools") ||(getState().appstore.categoryName === "Languages") || (getState().appstore.categoryName ==="Development Tools") || (getState().appstore.categoryName ==="ML & AI Tools")
    || (getState().appstore.categoryName ==="Data Exploration & Visualization")||(getState().appstore.categoryName ==="Data Platform and Ingestion Tools")){
      var categoryName;
      if((getState().appstore.categoryName==="AI IoT CoE Tools")?categoryName="Development Tools":categoryName=getState().appstore.categoryName)
      {dispatch(AppstoreActions.selecttemplate(false))
      var dublicateArray11=JSON.parse(JSON.stringify(getState().appstore.applicationList))
      var applicationArray22=dublicateArray11.filter(el =>{
        return el.group.toLowerCase().indexOf(categoryName.toString().toLowerCase()) !== -1 && el.name.toLowerCase().indexOf(getState().appstore.applicationName.toString().toLowerCase()) !== -1;
      })
      dispatch(AppstoreActions.filtered_ApplicationsArray(applicationArray22))}
      setTimeout(function(){       
        LazyLoadImage.loadImages(className,thresholdValue) // LazyLoadImage function  
      });
    } else{
      dispatch(AppstoreActions.selecttemplate(true))
      var dublicateArray1=JSON.parse(JSON.stringify(getState().appstore.applicationList))
      var applicationArray2=dublicateArray1.filter(el =>{
        return el.group.toLowerCase().indexOf(getState().appstore.categoryName.toString().toLowerCase()) !== -1 && el.name.toLowerCase().indexOf(getState().appstore.applicationName.toString().toLowerCase()) !== -1;
      })
      dispatch(AppstoreActions.filtered_ApplicationsArray(applicationArray2))
      setTimeout(function(){
        LazyLoadImage.loadImages(className,thresholdValue) // LazyLoadImage function  
      });
   
    } 
    
    
  }
}

export const skipAppStore=(history)=>{
  return (dispatch ,getState)=> {  
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    if(userDetails.type===1){
      history.push("/create-course")
    }else if(userDetails.type===2){
      history.push("/dlt/create-course")
    }else{
      history.push("/sub-admin/create-course")
    }  
  }
}

export const handleChangeApplicationName=(event)=>{
  return (dispatch ,getState)=> {  
    dispatch(AppstoreActions.setApplicationName(event.target.value))
    dispatch(filterApplications())
  }
}

export const handleChangeCategoryName =(event)=>{
  return (dispatch ,getState)=> {  
    dispatch (AppstoreActions.setCategoryName(event.target.value))
  //   if((event.target.value==="AI IoT CoE Tools")||(event.target.value === "Languages") || (event.target.value ==="Data Exploration & Visualization") || (event.target.value ==="Data Platform and Ingestion Tools")|| (event.target.value ==="Development Tools") 
  //   || (event.target.value ==="Design & Simulation") ){
  //     dispatch(AppstoreActions.selecttemplate(false))
  //     if(event.target.value==="AI IoT CoE Tools"){
  //       dispatch(filterApplications())}
  //     else{
  //       dispatch(filterApplications())
  //      }
  //   }
  //   else{
  //     dispatch(AppstoreActions.selecttemplate(true))
  //     dispatch(filterApplications())
  //   }
    dispatch(filterApplications())
  }
}
 
export const set_Snackbar_Message =(snackbar,msg)=>{
  return (dispatch ,getState)=> {  
    var x = document.getElementById(snackbar)
    x.className = "show";
    dispatch(AppstoreActions.set_Message(msg))
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }
}