import React, { Component } from 'react';
import './Login.css';
import axios from "axios";
import customHttp from '../../packages/CustomHttp';
var timerValue="";
class Authenticate extends Component {
  constructor(props){
    super(props);
    this.state = {
        loginEmail : "",
        loginOtp:"",
        registerEmail:"",
        registerOtp:"",
        emailExist : false,
        onSubmit : false,
        onSubmitRegistration : false,
        signUp : false,
        snackbar_message:"",
        otpIsInvalid:false,
        otpTimeExpired:false,
        disableResendButton:false,
        submitEmailWhileLogin:false,
        submitEmailWhileLogin1:false,
        loginPage:true,
        otpSuccessAlert:false,
        organizationName:"",
        orgNameExist:false,
        domainNameExist:false,
        domainName:"",
        orgValidation:false,
        domainNameValidation : false
    }
  }

  handleChangeLoginEmail = event =>{
    this.setState({ [event.target.name]: event.target.value},() => {
      // console.log("login email",this.state.loginEmail)
      this.setState({emailExist:false})
    });
  }

  handleChangeRegisterEmail = event =>{
    this.setState({ [event.target.name]: event.target.value},() => {
      // console.log("Email registered", this.state.registerEmail);
    });
    this.emailExists(event.target.value);
  }

  emailExists = (obj) => {
    axios
        .get("api/user/validateUser/email/" + obj)
        // var url = "/api/user/validateUser/email/" + obj;
        // customHttp.get(url)
        .then(response => {
          // console.log("email exist checking", response);
          if(response.data.success === true){
            this.setState({emailExist : false})
          }
          else{
            this.setState({emailExist : true})
          }
        })
        .catch(error => {
          console.log("failed", error.response);
        });
    }

  handleChangeLoginOtp = event =>{
    this.setState({ [event.target.name]: event.target.value,otpIsInvalid:false},() => {
      // console.log("otp entered", this.state.loginOtp);
    });
  }

  handleChangeRegisterOtp = event =>{
    this.setState({ [event.target.name]: event.target.value,otpIsInvalid:false},() => {
      // console.log("otp entered", this.state.registerOtp);
    });
  }


  loginWithEmail = (e) => {
    e.preventDefault();
    let object = {};
    object.email = this.state.loginEmail;
    this.setState({email:this.state.loginEmail,submitEmailWhileLogin:true})
    axios
      .get("api/user/validateUser/email/" + this.state.loginEmail)
      // var url = "/api/user/validateUser/email/" + this.state.loginEmail ;
      // customHttp.get(url)
      .then(response => {
        let validationStatus = response.data.message;
        // console.log("otp", validationStatus);
        if(validationStatus.userExists===true){
          // console.log("otp1", validationStatus);
          this.setState({emailExist:false})
          axios
          .put("/api/user/login/email/sendOtp", object)
          // var url = "/api/user/login/email/sendOtp" ;
          // customHttp.put(url,object)
          .then(response => {
            this.setState({emailExist:false,onSubmit:true,submitEmailWhileLogin:false})
            // console.log("otp sent", response);
            this.startTimer1();
            // setTimeout(function(){
            //   //this.$apply();
            // }, 119000);
            timerValue=setTimeout(function(){
            window.location.reload()
          }, 122000);

          })
          .catch(error => {
            this.setState({submitEmailWhileLogin:false})
            console.log("failed", error.response);
          });
        }else{
          this.setState({emailExist:true,submitEmailWhileLogin:false})
        }
      })
      .catch(error => {
        this.setState({submitEmailWhileLogin:false})
        console.log("failed", error.response);
      });
  }

    onSubmitRegistration = (e) => {
      e.preventDefault();
      this.setState({submitEmailWhileLogin:true})
      axios
        .get("/api/user/validateUser/email/" + this.state.registerEmail)
        // var url = "/api/user/validateUser/email/" + this.state.registerEmail ;
        // customHttp.get(url)
        .then(response => {
          let checkEmail = response.data;
          // console.log("checkEmail", checkEmail,response.data.message.userExists);
          let temp = response.data.message.userExists;
          if(!temp){
            this.sendEmailOtp();
          }
          else{
            console.log("email exist")
            this.setState({emailExist : true,submitEmailWhileLogin:false})
          }
        })
        .catch(error => {
          this.setState({submitEmailWhileLogin:false})
          console.log("failed", error);
        });
    }

    sendEmailOtp = () => {
      // console.log("email registration",this.state.registerEmail)
      let data1 = {};
      data1.email = this.state.registerEmail;
      axios
      .put('api/user/login/email/sendOtp',data1)
       // var url = '/api/user/login/email/sendOtp';
       // customHttp.put(url, data1)
      .then(response => {
        // console.log("registration otp sent", response.data.message);
        this.setState({onSubmitRegistration :true,submitEmailWhileLogin:false})
        this.startTimer();
        // setTimeout(function(){
        //   //this.$apply();
        // }, 119000);
        timerValue=setTimeout(function(){
        window.location.reload()
      }, 122000);
      })
      .catch(error => {
        this.setState({submitEmailWhileLogin:false})
        console.log("failed", error);
      });
    }
    alertFunc=()=>{
      this.setState({otpSuccessAlert:false})
     }
    onResend = () => {
      this.setState({disableResendButton:true,submitEmailWhileLogin1:true})
      let object = {};
      object.email = this.state.loginEmail;
      axios
        .put('api/user/login/email/sendOtp',object)
        // var url = '/api/user/login/email/sendOtp';
        // customHttp.put(url , object )
        .then(response => {
          this.setState({disableResendButton:false,submitEmailWhileLogin1:false,otpSuccessAlert:true})
          setTimeout(this.alertFunc,6000);
          setTimeout(()=>{
            document.getElementById('signInOtp').classList.remove("fadeInLeft");
            document.getElementById('signInOtp').classList.add("fadeOutLeft");
          },5500);
        })
        .catch(error => {
          this.setState({disableResendButton:false,submitEmailWhileLogin1:false,otpSuccessAlert:false})
          console.log("failed", error.response);
        });
    }

    onResendRegistration = () => {
      this.setState({disableResendButton:true,submitEmailWhileLogin1:true})
      let object = {};
      object.email = this.state.registerEmail;
      axios
        .put('api/user/login/email/resendOtp',object)
        // var url = '/api/user/login/email/resendOtp' ;
        // customHttp.put(url , object)
        .then(response => {
          this.setState({disableResendButton:false,submitEmailWhileLogin1:false,otpSuccessAlert:true})
          setTimeout(this.alertFunc,6000);
          // console.log("resent registration otp", response.data.message);
          setTimeout(()=>{
            document.getElementById('signUpOtp').classList.remove("fadeInLeft");
            document.getElementById('signUpOtp').classList.add("fadeOutLeft");
          },5500);
        })
        .catch(error => {
          this.setState({disableResendButton:false,submitEmailWhileLogin1:false,otpSuccessAlert:false})
          console.log("failed", error.response);
        });
    }

    verifyOtpForLogin = (e) =>{
      e.preventDefault();
      this.setState({submitEmailWhileLogin:true})
      let data = {};
      data.email = this.state.loginEmail;
      data.otp = this.state.loginOtp;
      axios
        .put("/api/user/login/email/verifyOtp",data)
        // var url = "/api/user/login/email/verifyOtp" ;
        // customHttp.put(url , data)

        .then(response => {
          console.log("verify otp", response);
          //this.setState({submitEmailWhileLogin:false})
            this.setState({otpIsInvalid:false})
            axios
            .get("/api/user/getUserDetails/email/" + this.state.loginEmail)
            // var url = "/api/user/getUserDetails/email/" + this.state.loginEmail ;
            // customHttp.get(url)
            .then(final => {
              // console.log("final",final.data.message);
              //this.setState({submitEmailWhileLogin:false})
              var object=final.data.message;
              sessionStorage.setItem("userData",JSON.stringify(object));
              // console.log("object",object)
              if(final.data.message && (final.data.message.type===1 || object.type==='1')){
                console.log("admin")
                this.setState({submitEmailWhileLogin:false})
                this.props.history.push('/dashboard')
              } else if(final.data.message && (final.data.message.type===0 || object.type==='0')){
                console.log("user")
                this.getConfidentialDetails(object)

                //this.props.history.push('/user/profile')
              }else if(final.data.message && (final.data.message.type===2 || object.type==='2')){
                console.log("dlt")
                this.setState({submitEmailWhileLogin:false})
                this.props.history.push('/dlt/dashboard')
              }else if(final.data.message && (final.data.message.type===3 || object.type==='3')){
                console.log("sub-admin")
                this.setState({submitEmailWhileLogin:false})
                this.props.history.push('/sub-admin/dashboard')
              }
              //window.location.reload();
              clearTimeout(timerValue);
              // axios
              // .get("/api/userConfidentialDetails/confidentialdetails/userId/"+userId)
              // .then(response => {
              //   console.log("success conf",response)
              //   sessionStorage.setItem("userData",response.data.message[0]);
              //   if(response.data.message[0].businessDomainName != null || response.data.message[0].businessDomainName>0 ){
              //     this.props.history.push('/edit-profile')
              //   }
              //   else{
              //     this.props.history.push('/profile')
              //   }
              // })
              // .catch(error => {
              //   console.log("conf errr failed", error.response);
              // });
            })
            .catch(error => {
              this.setState({submitEmailWhileLogin:false})
              console.log("failed", error.response);
            });

        })
        .catch(error => {
          this.setState({otpIsInvalid : true,submitEmailWhileLogin:false})
          console.log("failed", error.response);
        });
    }

    onCompleteRegistration = (e) =>{
      e.preventDefault();
      this.setState({submitEmailWhileLogin:true})
      let data = {};
      data.email = this.state.registerEmail.toLowerCase();
      data.otp = this.state.registerOtp;
      data.organizationName = this.state.organizationName.toLowerCase();
      data.organizationDomainName = this.state.domainName.toLowerCase();
      axios
        .put("api/user/email/register", data)
      // var url = "/api/user/email/register" ;
      // customHttp.put(url,data)
        .then(response => {
          // console.log("verify register otp", response);
            this.setState({otpIsInvalid:false,submitEmailWhileLogin:false})
          if(this.state.registerEmail!==null && this.state.registerEmail!==undefined){
            axios
            .get("/api/user/getUserDetails/email/" + this.state.registerEmail)
            // var url = "/api/user/getUserDetails/email/" + this.state.registerEmail ;
            // customHttp.get(url)
            .then(final => {
              this.setState({submitEmailWhileLogin:false})
              // console.log("final",final.data.message);
              var object=JSON.parse(JSON.stringify(final.data.message));
              sessionStorage.setItem("userData",JSON.stringify(object));
              if(final.data.message && (final.data.message.type===1 || object.type==='1')){
                this.props.history.push('/dashboard')
              } else if(final.data.message && (final.data.message.type===0 || object.type==='0')){
                this.props.history.push('/user/courses')
              }
              window.location.reload()
              clearTimeout(timerValue);
              // axios
              // .get("/api/userConfidentialDetails/confidentialdetails/userId/"+userId)
              // .then(response => {
              //   this.setState({submitEmailWhileLogin:false})
              //   sessionStorage.setItem("userData",response.data.message[0]);
              //   if(response.data.message[0].businessDomainName != null || response.data.message[0].businessDomainName>0 ){
              //     this.props.history.push('/edit-profile')
              //   }
              //   else{
              //     this.props.history.push('/profile')
              //   }
              // })
              // .catch(error => {
              //   this.setState({submitEmailWhileLogin:false})
              //   console.log("failed", error.response);
              // });
            })
            .catch(error => {
              this.setState({submitEmailWhileLogin:false})
              console.log("failed", error.response);
            });
          }
          else{
          this.setState({emailExist : true})
          }
        })
        .catch(error => {
          this.setState({submitEmailWhileLogin:false})
          this.setState({otpIsInvalid : true})
          console.log("failed", error.response);
        });
    }

    componentDidMount=()=>{

    //startTimer();

    }

     startTimer1=()=>{
       if(document.getElementById('timer1') !== null){
        // document.getElementById('timer1').innerHTML =  parseInt("02") + ":" + parseInt("00");
        document.getElementById('timerStroke').classList.add("ticking");
        var presentTime = document.getElementById('timer1').innerHTML;
        var timeArray = presentTime.split(/[:]+/);
        var m = timeArray[0];
        var s = this.checkSecondForTimer1((timeArray[1] - 1));
        if(s==59){m=m-1}
        //if(m<0){alert('timer completed')}

        document.getElementById('timer1').innerHTML =
          m + ":" + s;
          if(m>=0 && s>=0){
            setTimeout(this.startTimer1, 1000);
           }
       }

    }
    checkSecondForTimer1=(sec)=>{
      if (sec < 10 && sec >= 0) {sec = "0" + sec}; // add zero in front of numbers < 10
      if (sec < 0) {sec = "59"};
      return sec;
    }

     startTimer=()=>{
       if(document.getElementById('timer2')!==null){
        // document.getElementById('timer2').innerHTML =  parseInt("02") + ":" + parseInt("00");
        document.getElementById('timerStroke').classList.add("ticking");
        var presentTime = document.getElementById('timer2').innerHTML;
        var timeArray = presentTime.split(/[:]+/);
        var m = timeArray[0];
        var s = this.checkSecondForTimer((timeArray[1] - 1));
        if(s==59){m=m-1}
        //if(m<0){alert('timer completed')}

        document.getElementById('timer2').innerHTML =
          m + ":" + s;
          if(m>=0 && s>=0){
            setTimeout(this.startTimer, 1000);
           }
       }else{

       }

    }

    checkSecondForTimer(sec) {
      if (sec < 10 && sec >= 0) {sec = "0" + sec}; // add zero in front of numbers < 10
      if (sec < 0) {sec = "59"};
      return sec;
    }
    signUp = () =>{
      // console.log("sign up")
       this.setState({signUp:true,disableResendButton:false,otpTimeExpired:false,otpIsInvalid:false,emailExist : false,loginPage:false})
    }
    backLogin = () =>{
      // console.log("login")
       this.setState({signUp:false,disableResendButton:false,otpTimeExpired:false,otpIsInvalid:false,emailExist : false,loginPage:true})
    }

    myFunction=()=> {
      var x = document.getElementById("snackbar")
      x.className = "show";
      setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    }

    myFunction1=()=> {
      var x = document.getElementById("snackbar1")
      x.className = "show";
      setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    }

    // checking organizatin name
    handleChangeCheckOrganizationName = (e) => {
      this.setState({[e.target.name]:e.target.value,orgValidation : false})
      if(Object.keys(e.target.value).length>0) {
        var value = e.target.value.toLowerCase()
        axios
        .get("/api/confidentialDetails/loginPage/organizationName/"+value)
        // var url = "/api/confidentialDetails/organizationName/"+value
        // customHttp.get(url)
        .then(response => {
          let temp = response.data.message.isAvailable;
          if(!temp){
            this.setState({orgNameExist : true})
          }else{
            this.setState({orgNameExist : false})
          }
        })
        .catch(error => {
          this.setState({orgNameExist : false})
          console.log("failed", error);
        });
      }else{
        this.setState({orgNameExist : false})
      }
    }

    handleChangeValidateOrganizationName=(e)=>{
      let orgRegex = /^[a-zA-Z0-9- ]*$/;
        let orgValid = orgRegex.test(String(e.target.value))
        if(Object.keys(e.target.value).length>0 && !orgValid){
          this.setState({orgValidation : true})
        }
        else{
          this.setState({orgValidation : false})
        }
    }
    // checking domain name
    handleChangeCheckDomainName = (e) => {
      this.setState({[e.target.name]:e.target.value,domainNameValidation : false})
      if(Object.keys(e.target.value).length>0) {
        var value = e.target.value.toLowerCase()
        axios
        .get("/api/confidentialDetails/loginPage/domain/"+value)
        // var url = "/api/confidentialDetails/domain/"+value
        // customHttp.get(url)
        .then(response => {
          // console.log("response",response.data.message)
          let temp = response.data.message.isAvailable;
          if(!temp){
            this.setState({domainNameExist : true})
          }else{
            this.setState({domainNameExist : false})
          }
        })
        .catch(error => {
          this.setState({domainNameExist : false})
          console.log("failed", error);
        });
      }else{
        this.setState({domainNameExist : false})
      }
    }

    handleChangeValidateDomainName=(e)=>{
      let domainRegex = /^[a-zA-Z]+[-]*[a-zA-Z]*(\.[a-zA-Z-]{2,}){1,4}$/;
        let domainValid = domainRegex.test(String(e.target.value))
        if(Object.keys(e.target.value).length>0 && !domainValid){
          this.setState({domainNameValidation : true})
        }
        else{
          this.setState({domainNameValidation : false})
        }
    }
    handleKeyPress=(e)=>{
      if (e.keyCode === 13) {
        e.target.blur()
       }
    }

     getConfidentialDetails = (obj) => {
       axios
       .get('/api/userProfile/getProfileDetails/'+obj._id)
       // var url = '/api/userProfile/getProfileDetails/'+obj._id;
       // customHttp.get(url)
      .then(response => {
        this.setState({submitEmailWhileLogin:false})
        if(response.data.message != null || response.data.message != undefined){
          sessionStorage.setItem("userProfileData",true);
          if(Object.keys(response.data.message).length>0 && response.data.message.firstname!=null){
            this.props.history.push('/user/courses')
          }else{
            this.props.history.push('/user/profile')
          }

        }else{
          sessionStorage.setItem("userProfileData",false);
          this.props.history.push('/user/profile')
        }
        //return response.data.message;
      })
      .catch(error => {
        //return response.data.message;
        this.setState({submitEmailWhileLogin:false})
        sessionStorage.setItem("userProfileData",false);
        this.props.history.push('/user/profile')
        console.log("failed", error);
      });
    }
    render() {
        return (
          <div className="col-md-12">
            <div id="snackbar">{this.state.snackbar_message}</div>
            <div id="snackbar1"><i className="fa fa-exclamation"></i>{this.state.snackbar_message}</div>
            <div className="row">
              <div className="nj-login-wrap">
                <div className="nj-login-wrapper">
                  <div className="col-md-5 hidden-sm hidden-xs">
                    <div className="row">
                      <div className="nj-left-login-panel">
                        <div className="nj-left-login-content">
                          <div className="nj-left-login-content-top-element">
                            <span style={{margin:0,position:'absolute'}}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-log-in nj-left-login-content-top-element-svg"
                              >
                                <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
                                <polyline points="10 17 15 12 10 7" />
                                <line x1={15} y1={12} x2={3} y2={12} />
                              </svg>
                            </span>
                            {this.state.signUp === false ?
                            <span className="nj-left-login-content-top-element-label">
                              Sign in - PSAP.Ai
                            </span>
                            :
                            <span className="nj-left-login-content-top-element-label">
                              Sign up - PSAP.Ai
                            </span>
                            }
                          </div>
                          <div className="" style={this.state.loginPage ===true ?{display:"block"}:{display:"none"}}>
                            <div className="nj-left-login-content-top-element">
                              {this.state.onSubmit === true ?
                              <span className="nj-left-login-content-element-num" style={{backgroundColor:'#eeeffa',boxShadow:'none'}}>1</span>
                              :
                              <span className="nj-left-login-content-element-num">1</span>
                              }
                              <span className="nj-left-login-content-top-element-label">Enter your email</span>
                            </div>
                            <br />
                            <br />
                            <div className="nj-left-login-content-top-element">
                            {this.state.onSubmit === false ?
                            <span className="nj-left-login-content-element-num" style={{backgroundColor:'#eeeffa',boxShadow:'none'}}>2</span>
                            :
                            <span className="nj-left-login-content-element-num">2</span>
                            }
                              <span className="nj-left-login-content-top-element-label">Submit OTP</span>
                            </div>
                          </div>

                          <div className="" style={this.state.loginPage ===false ?{display:"block"}:{display:"none"}}>
                            <div className="nj-left-login-content-top-element">
                              {this.state.onSubmitRegistration === true ?
                              <span className="nj-left-login-content-element-num" style={{backgroundColor:'#eeeffa',boxShadow:'none'}}>1</span>
                              :
                              <span className="nj-left-login-content-element-num" >1</span>
                              }
                              <span className="nj-left-login-content-top-element-label">Enter your email</span>
                            </div>
                            <br />
                            <br />
                            <div className="nj-left-login-content-top-element">
                            {this.state.onSubmitRegistration === false ?
                            <span className="nj-left-login-content-element-num" style={{backgroundColor:'#eeeffa',boxShadow:'none'}}>2</span>
                            :
                            <span className="nj-left-login-content-element-num">2</span>
                            }
                              <span className="nj-left-login-content-top-element-label">Submit OTP</span>
                            </div>
                          </div>

                          <div className="">
                            <div className="col-md-1" style={{width:'20px',padding:0,marginTop:'24px'}}>
                                <span className="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-info"
                                    >
                                        <circle cx={12} cy={12} r={10} />
                                        <line x1={12} y1={16} x2={12} y2={12} />
                                        <line x1={12} y1={8} x2={12} y2={8} />
                                    </svg>
                                </span>
                            </div>
                            <div className="col-md-11" style={{fontFamily:'Montserrat',fontSize:'12px',color:'rgba(0,0,0,.5)'}}>
                                <span className="row">
                                    But why? Don't worry! we won't share it with anyone and we will never send you spam
                                    emails.
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.signUp === false ?
                  <div className="col-md-7" >
                    { this.state.onSubmit === false ?
                    <div className="row" >

                        <div className="login-padding responsive-logo-smalldiveces" >
                          <div className="justify-between" style={{textAlign:"center",padding:"0px 19px 8px 0px",width:"100%"}}>
                            <div className="logo  items-center">
                              <img
                                src="/img/logos/platifilogo.png"
                                alt="logo"
                                className="font_family_montserrat"
                                style={{width: "150px"}}
                              />
                              {/* <span style={{fontWeight:"500",paddingLeft:"5px",fontSize:"28px",verticalAlign:"middle"}}>  iDAMS</span> */}
                            </div>
                          </div>
                            <div className="col-md-8 col-sm-12 col-xs-12">
                                <h3 style={{fontFamily:'Montserrat',marginTop:'20%',fontSize:'28px',color:'#404e67'}}>
                                    What is your Email?
                                </h3>
                                <div className="nj-popup-form-wrap" style={{margin:0,marginTop:'20px'}}>
                                  <form onSubmit={this.loginWithEmail}>
                                    <p className="popup-content-label" style={{marginLeft:0}}>Enter your email</p>
                                    <input className="vs-input--input form-control"
                                      type="email"
                                      name="loginEmail"
                                      value={this.state.loginEmail}
                                      onChange={this.handleChangeLoginEmail}
                                      style={{padding:'20px',paddingLeft:'10px',border:'1px solid #7367f0',borderRadius:'3px'}}
                                      placeholder="jondoe@email.com"
                                      autoComplete="off"
                                      required />
                                      {this.state.emailExist && <p style={{marginTop:'9px',color:'red'}}>
                                          Email is not registered!
                                      </p>}
                                    <br />
                                    {this.state.submitEmailWhileLogin === false ? <button type="submit" className="nj-flow-button pull-right">Submit</button> :<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                                  </form>
                                </div>
                                <div className="col-sm-12 col-xs-12" style={{marginTop:'10px',paddingLeft:'0px'}}>
                                    <span style={{fontFamily:'Montserrat'}}>Not registered yet?</span><span onClick={this.signUp} style={{fontWeight:'500',marginLeft:'10px',fontFamily:'Montserrat',color:'blue',cursor:'pointer'}}>Sign up</span>
                                </div>
                            </div>
                        </div>
                    <div />
                  </div>
                  :
                  <div className="row" >
                        <div className="responsive-logo-smalldiveces" style={{paddingTop:"20px"}} >
                          <div className="justify-between" style={{textAlign:"center",padding:"0px 19px 8px 0px",width:"100%"}}>
                            <div className="logo  items-center">
                              <img
                                src="/img/logos/platifilogo.png"
                                alt="logo"
                                className="font_family_montserrat"
                                style={{width: "150px"}}
                              />
                               {/* <span style={{fontWeight:"500",paddingLeft:"5px",fontSize:"28px",verticalAlign:"middle"}}>  iDAMS</span> */}
                            </div>
                          </div>
                            <div className="col-md-8">
                                <h4 style={{fontFamily:'Montserrat',marginTop:'5%'}}>
                                    Please check your email for validation
                                </h4>
                                <button type="submit" className="nj-flow-button" disabled={this.state.disableResendButton} onClick={this.onResend} style={{marginBottom:'30px',marginTop:'10px'}}>Resend</button>
                                {this.state.otpSuccessAlert ?
                                <span id="signInOtp" className="fadeInLeft font_family_montserrat" style={{color:"green"}}>OTP sent successfully!</span>
                                :""}
                                <div ng-hide="otpExpired5">
                                  <p className="otp-timer">Your OTP will expire in</p>
                                  <div className="col-md-12 col-xs-12">
                                    {/* <div className="main col-md-4 col-xs-3 timer-clock">
                                      <div className="timer-clock">
                                      <div className="svg">
                                        <svg  viewBox="0 0 120 120" className="chart-gauge" fill="none" strokeWidth="2">
                                          <circle className="timer-backdrop" strokeDasharray="360" r="57.32" cx="60" cy="60"></circle>
                                          <circle id="timerStroke" className="timer-gauge" strokeDasharray="360" r="57.32" cx="60" cy="60"></circle>
                                          <text y="50%" x="50%" id="timer1" className="timer-time">2:00</text>
                                        </svg>
                                        </div>
                                      </div><br/>
                                    </div> */}
                                    <div className="main col-md-3 col-xs-3 timer-clock">
                                      <div className="timer-clock">
                                        <div className="svg">
                                          <svg viewBox="0 0 120 120" className="chart-gauge" fill="none" strokeWidth={2}>
                                            <circle className="timer-backdrop" strokeDasharray={360} r="57.32" cx={60} cy={60} />
                                            <circle id="timerStroke" className="timer-gauge" strokeDasharray={360} r="57.32" cx={60} cy={60} />
                                            <text y="50%" x="50%" id="timer1" className="timer-time">2:00</text>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="nj-popup-form-wrap" style={{margin:0,marginTop:'20px'}}>
                                  <form onSubmit={this.verifyOtpForLogin}>
                                    <p className="popup-content-label" style={{marginLeft:0}}>Enter Code</p>
                                    <input className="vs-input--input form-control"
                                      type="text"
                                      name="loginOtp"
                                      value={this.state.loginOtp}
                                      onChange={this.handleChangeLoginOtp}
                                      style={{padding:'20px',paddingLeft:'10px',border:'1px solid #7367f0',borderRadius:'3px'}}
                                      placeholder="Enter Code"
                                      autoComplete="off"
                                      required />
                                      {this.state.otpIsInvalid && <p style={{marginTop:'9px',marginBottom:'0px', color:'red'}}>
                                          Entered OTP is invalid
                                      </p>}

                                    {this.state.submitEmailWhileLogin === false ? <button type="submit" className="nj-flow-button pull-right" style={{marginTop:"5%"}}>Complete</button>:<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                                    </form>
                                </div>
                                {/* <div className="col-md-12 col-sm-12 col-xs-12" style={{marginTop:'10px',paddingLeft:'0px'}}>
                                    <span style={{fontFamily:'Montserrat'}}>Not registered yet?</span><span style={{fontWeight:'500',marginLeft:'10px',fontFamily:'Montserrat',color:'blue'}}>Sign up</span>
                                </div> */}
                            </div>
                        </div>
                    <div />
                  </div>
                    }
                  </div>
                  :
                  /* Registration Page */
                  <div className="col-md-7" >
                    { this.state.onSubmitRegistration === false ?
                    <div className="row" >
                        <div className="login-padding responsive-logo-smalldiveces" >
                          <div className="justify-between" style={{textAlign:"center",padding:"0px 19px 8px 0px",width:"100%"}}>
                              <div className="logo  items-center">
                                <img
                                  src="/img/logos/platifilogo.png"
                                  alt="logo"
                                  className="font_family_montserrat"
                                  style={{width: "150px"}}
                                />
                                {/* <span style={{fontWeight:"500",paddingLeft:"5px",fontSize:"28px",verticalAlign:"middle"}}>  iDAMS</span> */}
                              </div>
                            </div>
                            <div className="col-md-8 col-sm-12 col-xs-12">
                                {/* <h3 style={{fontFamily:'Montserrat',marginTop:'20%',fontSize:'28px',color:'#404e67'}}>
                                    What is your Email?
                                </h3> */}
                                <div className="nj-popup-form-wrap" style={{margin:0,marginTop:'20px'}}>
                                  <form onSubmit={ !this.state.domainNameValidation && !this.state.domainNameExist && !this.state.orgNameExist && !this.state.orgValidation && !this.state.emailExist && this.onSubmitRegistration}>
                                    <div  className="nj-login-margin-bottom">
                                      <p className="popup-content-label font_family_montserrat" style={{marginLeft:0,fontSize:14}}>Enter your email</p>
                                      <input className="vs-input--input form-control"
                                        type="email"
                                        name="registerEmail"
                                        value={this.state.registerEmail}
                                        onChange={this.handleChangeRegisterEmail}
                                        style={{padding:'20px',paddingLeft:'10px',border:'1px solid #7367f0',borderRadius:'3px'}}
                                        placeholder="jondoe@email.com"
                                        autoComplete="off"
                                        required />
                                        {this.state.emailExist && <p style={{marginTop:'9px',color:'red'}}>
                                            Email already registered!
                                        </p>}
                                    </div>
                                    <div className="nj-login-margin-bottom">
                                      <p className="popup-content-label" style={{marginLeft:0,fontSize:14}}>Enter organization name</p>
                                      <input className="vs-input--input form-control"
                                        type="text"
                                        name="organizationName"
                                        value={this.state.organizationName}
                                        onKeyDown={this.handleKeyPress}
                                        onChange={this.handleChangeCheckOrganizationName}
                                        onBlur={this.handleChangeValidateOrganizationName}
                                        style={{padding:'20px',paddingLeft:'10px',border:'1px solid #7367f0',borderRadius:'3px'}}
                                        placeholder="Organization Name"
                                        autoComplete="off"
                                        required />
                                        {this.state.orgValidation && <p style={{marginTop:'9px',color:'red',fontSize:12}}>
                                          Organization name can only contain alphabets, numbers and hyphen(-).
                                        </p>}
                                        {this.state.orgNameExist && <p style={{marginTop:'9px',color:'red',fontSize:12}}>
                                          Organization Name Already Exists.
                                        </p>}
                                    </div>
                                    <div className="nj-login-margin-bottom">
                                      <p className="popup-content-label" style={{marginLeft:0,fontSize:14}}>Enter domain name</p>
                                      <input className="vs-input--input form-control"
                                        type="text"
                                        name="domainName"
                                        value={this.state.domainName}
                                        onKeyDown={this.handleKeyPress}
                                        onChange={this.handleChangeCheckDomainName}
                                        onBlur={this.handleChangeValidateDomainName}
                                        style={{padding:'20px',paddingLeft:'10px',border:'1px solid #7367f0',borderRadius:'3px'}}
                                        placeholder="Domain Name"
                                        autoComplete="off"
                                        required />
                                        {this.state.domainNameValidation && <p style={{marginTop:'9px',color:'red',fontSize:12}}>
                                          Domain name can only contain alphabets, dot(.) and hyphen(-).
                                        </p>}
                                        {this.state.domainNameExist && <p style={{marginTop:'9px',color:'red',fontSize:12}}>
                                          Domain Name Already Exists.
                                        </p>}
                                        <br/>
                                      </div>
                                    {this.state.submitEmailWhileLogin === false ? <button type="submit" className="nj-flow-button pull-right" disabled={this.state.domainNameValidation || this.state.domainNameExist || this.state.orgNameExist || this.state.orgValidation || this.state.emailExist}>Submit</button> :<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                                  </form>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12" style={{marginTop:'10px',paddingLeft:'0px'}}>
                                  <span style={{fontFamily:'Montserrat'}}>Already registered ?</span> <span onClick={this.backLogin} style={{fontWeight:'500',marginLeft:'10px',fontFamily:'Montserrat',color:'blue',cursor:'pointer'}}>Sign in</span>
                                </div>
                            </div>
                        </div>
                    <div />
                  </div>
                  :
                  <div className="row" >
                        <div className="login-padding responsive-logo-smalldiveces" style={{paddingTop:"20px"}}>
                          <div className="justify-between" style={{textAlign:"center",padding:"0px 19px 8px 0px",width:"100%"}}>
                              <div className="logo  items-center">
                                <img
                                  src="/img/logos/platifilogo.png"
                                  alt="logo"
                                  className="font_family_montserrat"
                                  style={{width: "150px"}}
                                />
                                {/* <span style={{fontWeight:"500",paddingLeft:"5px",fontSize:"28px",verticalAlign:"middle"}}>  iDAMS</span> */}
                              </div>
                            </div>
                            <div className="col-md-8 col-sm-12 col-xs-12">
                                <h4 style={{fontFamily:'Montserrat',marginTop:'5%'}}>
                                    Please check your email for validation
                                </h4>
                                <button type="submit" className="nj-flow-button"  disabled={this.state.disableResendButton}  onClick={this.onResendRegistration} style={{marginBottom:'30px',marginTop:'10px'}}>Resend</button>
                                {this.state.otpSuccessAlert ?
                                <span id='signUpOtp' className="fadeInLeft font_family_montserrat" style={{color:"green"}}>OTP sent successfully!</span>
                                :""}
                                <div ng-hide="otpExpired5">
                                  <p className="otp-timer">Your OTP will expire in</p>
                                  <div className="col-md-12 col-xs-12">
                                    <div className="main col-md-4 col-xs-3 timer-clock" style={{marginBottom:"0px"}}>
                                      <div className="svg timer-clock">
                                        <svg  viewBox="0 0 120 120" className="chart-gauge" fill="none" strokeWidth="2">
                                          <circle className="timer-backdrop" strokeDasharray="360" r="57.32" cx="60" cy="60"></circle>
                                          <circle id="timerStroke" className="timer-gauge" strokeDasharray="360" r="57.32" cx="60" cy="60"></circle>
                                          <text y="50%" x="50%" id="timer2" className="timer-time">2:00</text>
                                        </svg>
                                      </div><br/>
                                    </div>
                                  </div>
                                </div>
                                <div className="nj-popup-form-wrap" style={{margin:0,marginTop:'20px'}}>

                                  <form onSubmit={this.onCompleteRegistration} >
                                    <p className="popup-content-label" style={{marginLeft:0}}>Enter Code</p>
                                    <input className="vs-input--input form-control"
                                      type="text"
                                      name="registerOtp"
                                      value={this.state.registerOtp}
                                      onChange={this.handleChangeRegisterOtp}
                                      style={{padding:'20px',paddingLeft:'10px',border:'1px solid #7367f0',borderRadius:'3px'}}
                                      placeholder="Enter Code"
                                      autoComplete="off"
                                      required />
                                       {this.state.otpIsInvalid && <p style={{marginTop:'9px',marginBottom:'0px', color:'red'}}>
                                          Entered OTP is invalid
                                      </p>}

                                    {this.state.submitEmailWhileLogin === false ? <button type="submit" className="nj-flow-button pull-right" style={{marginTop:"5%"}}>Complete</button> :<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                                    </form>

                                </div>
                                {/* <div className="col-sm-12 col-xs-12" style={{marginTop:'10px'}}>
                                    <span style={{fontFamily:'Montserrat'}}>Not registered yet?</span><span style={{fontWeight:'500',marginLeft:'10px',fontFamily:'Montserrat',color:'blue'}}>Sign up</span>
                                </div> */}
                            </div>
                        </div>
                    <div />
                  </div>
                    }
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default Authenticate;
