
import React, { Component} from 'react';
import './Analytics.scss';
// import { connect } from "react-redux";
// import * as AnalyticsContainer from "../../container/actions/Analytics/action";
// import Store from "../../container/reducers/Store";
// import DatePicker from 'react-date-picker';
import 'react-datepicker/dist/react-datepicker.css';
// import { bindActionCreators } from 'redux';
import { Line } from 'react-chartjs-2';
// import axios from "axios";
import customHttp from '../../packages/CustomHttp';

class ResourceUsageByCourse extends Component {
  constructor(props){
    super(props);
    this.state = {
      courseListOfResourceUsage:{},
      coursesArray:[],
      usersArray:[],
      resourcesPdfArray:[],
      resourcesListObj:{},
      usersListObj:{},
      coursesListObj:{},
      noDataFound:"",
      resourceViewCountGraphDate:[],
      show_Analytics_Loader:true,

      graphDataLine:{
        labels: [],
        datasets: [
          {
            label: 'Hours Spent By Users',
            color: "transparent",
            borderColor: "#eceff1",
            borderWidth: 1,
            fill: false,
            backgroundColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
            data: [],
            // lineTension: 0.1,
            borderColor: "black",
            // borderCapStyle: 'butt',
            // borderDash: [],
            // borderDashOffset: 0.0,
            // borderJoinStyle: 'miter',
            pointBorderColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
            // pointBackgroundColor: '#fff',
            pointBorderWidth: 8,
            pointHoverBorderWidth: 8,
            pointRadius: 1,
            pointHitRadius: 1,
            pointHoverRadius: 1,
            pointHoverBackgroundColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
            pointHoverBorderColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"]

          }
        ]
      },
      options :{
        legend: {
            "display": false,
              "position": "top",
              "fullWidth": true,
              "reverse": false,
              "responsive": true,
              "maintainAspectRatio": true,
              "hoverBackgroundColor": false,
            },
            tooltips: {
              "enabled": true,
              callbacks: {
                  label: function (tooltipItems, data) {
                      return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
                  }
              }
            },
            scales: {
              //barValueSpacing: 20,
              yAxes: [{
                        ticks: {
                            min: 0
                        }
                    }],
                xAxes: [{
                  barPercentage: .6,
                  categoryPercentage: 0.35,
                }]
            }
        }
    }
  }

  componentDidMount() {
    this.fetchAllCourses();
  }

  handleChangeCourses=(e)=>{
    e.preventDefault();
    const value = JSON.parse(e.target.value)
    // console.log("course e.target.value",e.target.value);
    this.setState({coursesListObj:value},()=>{this.fetchAllUsersAndResourcesByCourse();})
  }

  handleChangeUsers=(e)=>{
    e.preventDefault();
    const value = JSON.parse(e.target.value)
    this.setState({usersListObj:value},()=>{this.resourceViewCountGraphDate()})
  }

  handleChangeResources=(e)=>{
    e.preventDefault();
    const value = JSON.parse(e.target.value)
    this.setState({resourcesListObj:value},()=>{this.resourceViewCountGraphDate()})
  }

  fetchAllCourses=()=>{
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))

    var demoCoursesArray=[{displayName: "Fluid Mechanics",_id: "5c15f3907257166d93c74bb2"},
    {displayName: "Manufacturing Technology",_id: "5c15f4677257166d93c74cb3"},
    {displayName: "Applied Thermodynamics",_id: "5c15f40c7257166d93c74c4f"},
    {displayName: "Dynamics of Machinery",_id: "5c15f3907257166d93c74r4"},
    {displayName: "Design of Machine Elements",_id: "5c15f3907257166d453c74bb2"}]
    if(this.props.demoAndActualValue==="Demo"){
      this.setState({coursesArray:demoCoursesArray,show_Analytics_Loader:false})
      this.fetchAllUsersAndResourcesByCourse()
    }else{
      // axios.get('/api/course/allCourses/user/'+userDetails.belongsTo)
      var url = '/api/course/allCourses/user/'+userDetails._id ;
      customHttp.get(url)
          .then(response => {
            // console.log("fetchAllCourses", response.data.message);
            if(response.data.message && Array.isArray(response.data.message)){
              var coursesArray = response.data.message.reverse();
              // console.log("courseName",response.data.message);
              this.setState({coursesArray:coursesArray,coursesListObj:response.data.message[0],show_Analytics_Loader:false})
              this.fetchAllUsersAndResourcesByCourse()
            }else{
              this.setState({coursesArray:[],coursesListObj:{},show_Analytics_Loader:false})
            }
          })
          .catch(error => {
            this.setState({coursesArray:[],coursesListObj:{},show_Analytics_Loader:false})
            console.log("failed", error.response);
          });
        }
    }

  fetchAllUsersAndResourcesByCourse=()=>{
      // var courseId = "5e3125d62833e90e385a9371";
      // console.log("courseId wertyuiuytrertyuiuytr",this.state.coursesListObj);
      var demoUsersArray=[{username: "Branden",_id: "5c15f3907257166d93c74bb2"},
      {username: "Cyril",_id: "5c15f4677257166d93c74cb3"},
      {username: "Franklyn",_id: "5c15f40c7257166d93c74c4f"},
      {username: "Trevor",_id: "5c15f3907257166d93c74r4"},
      {username: "Graham",_id: "5c15f3907257166d453c74bb2"}]

      var demoResourcesPdfArray=[{displayName: "lean supply chain management",resourceId: "5c15f3907257166d93c74bb2",resourceViewCount: '14'},
      {displayName: "slot cancellation",resourceId: "5c15f4677257166d93c74cb3",resourceViewCount: '11'},
      {displayName: "how to book a slot",resourceId: "5c15f40c7257166d93c74c4f",resourceViewCount: '9'},
      {displayName: "The Management Of Operations",resourceId: "5c15f3907257166d93c74r4",resourceViewCount: '7'},
      {displayName: "how to access lab",resourceId: "5c15f3907257166d453c74bb2",resourceViewCount: '5'}]

    if(this.props.demoAndActualValue==="Demo"){
      this.setState({usersArray:demoUsersArray,resourcesPdfArray:demoResourcesPdfArray})
      this.resourceViewCountGraphDate()
    }else{
      // axios.get('/api/course/userAndResourceList/'+this.state.coursesListObj._id)
      var url = '/api/course/userAndResourceList/'+this.state.coursesListObj._id ;
      customHttp.get(url)
          .then(response => {

             //var fetchAllUsersAndResourcesByCourse = JSON.parse(JSON.stringify(response.data.message));
             //console.log("users n resources doc",fetchAllUsersAndResourcesByCourse);
            if(Object.keys(response.data.message).length>0){
                 var userList = response.data.message.userList
                  //console.log("fetchAllUsersAndResourcesByCourse userList",response.data.message.userList);
                 if(userList.length>0){
                   this.setState({usersArray:userList,usersListObj:response.data.message.userList[0]},()=>{this.resourceViewCountGraphDate()})
                 }else {
                   this.setState({usersArray:[],usersListObj:{}})
                 }

                 var resourceList = response.data.message.resourceList
                 // console.log("fetchAllUsersAndResourcesByCourse resourcesPdfArray",resourceList.length,response.data.message.resourceList);
                 if(resourceList.length>0){
                   this.setState({resourcesPdfArray:resourceList,resourcesListObj:response.data.message.resourceList[0]},()=>{this.resourceViewCountGraphDate()})
                 }else {
                   this.setState({resourcesPdfArray:[],resourcesListObj:{}})
                 }

            }else {
              this.setState({resourcesPdfArray:[],resourcesListObj:{}})
              this.setState({usersArray:[],usersListObj:{}})
            }

          })
          .catch(error => {
            this.setState({usersArray:[],usersListObj:{}})
            this.setState({resourcesPdfArray:[],resourcesListObj:{}})
            console.log("failed", error.response);
          });
        }
     }

     resourceViewCountGraphDate=()=>{
      var demoResourceViewCountGraphDate=[{date: "2020-03-05",resourceCount: "14"},
      {date: "2020-03-06",resourceCount: "18"},
      {date: "2020-03-07",resourceCount: "9"},
      {date: "2020-03-08",resourceCount: "11"},
      {date: "2020-03-09",resourceCount: "5"},
      {date: "2020-03-10",resourceCount: "19"},
      {date: "2020-03-11",resourceCount: "17"}]

       // console.log("this.props ertyuiuytrtyuioiuytrtyuiopoiuytrtyuiuytyuiuytyuiuyyuiuyyuiuytyuiuyt",this.props.fromDate,this.props.toDate);
        var n = new Date(this.props.fromDate);
        var currentDate = n.getDate();
        if(currentDate<10){
          currentDate='0'+currentDate;
        }
        var currentMonth = n.getMonth() + 1; //Months are zero based
        if(currentMonth<10){
          currentMonth='0'+currentMonth;
        }
        var currentYear = n.getFullYear();
        var fromDate = currentYear + "-" + currentMonth + "-" + currentDate;  // date conversion dd/mm/yyyy to yyyy-mm-dd

        var p = new Date(this.props.toDate);
        var tempDate = p.getDate();
        if(tempDate<10){
          tempDate='0'+tempDate;
        }
        var tempMonth = p.getMonth() + 1;
        if(tempMonth<10){
          tempMonth='0'+tempMonth;
        }
        var tempYear = p.getFullYear();
        var toDate = tempYear + "-" + tempMonth + "-" + tempDate;   // date conversion dd/mm/yyyy

        if(this.props.demoAndActualValue==="Demo"){
          this.setState({resourceViewCountGraphDate:demoResourceViewCountGraphDate})
         var temp = this.state.graphDataLine
         var data=[];
         var labels=[]
         for(var i=0;i<demoResourceViewCountGraphDate.length;i++){
            // console.log("overAll usage",response.data.message._id,response.data.message.totalUsage)
            labels.push(demoResourceViewCountGraphDate[i].date)
            data.push(demoResourceViewCountGraphDate[i].resourceCount)
          }
          // console.log("data,labels",data,labels);
          temp.labels = labels;
          temp.datasets[0].data = data;
          this.setState({graphDataLine:temp,noDataFound:demoResourceViewCountGraphDate})
        }else{
       // console.log("resourceViewCountByUserIdAndResourceId",this.state.usersListObj._id,this.state.resourcesListObj._id,this.props.fromDate,this.props.toDate);
       if(Object.keys(this.state.resourcesListObj).length>0){
       var resourceid = this.state.resourcesListObj._id
        //  axios.get('/api/resourceViewCount/resourceViewCountByUserIdAndResourceId/userId/'+this.state.usersListObj._id+'/resourceId/'+resourceid+'/fromDate/'+fromDate+'/toDate/'+toDate)
         var url = '/api/resourceViewCount/resourceViewCountByUserIdAndResourceId/userId/'+this.state.usersListObj._id+'/resourceId/'+resourceid+'/fromDate/'+fromDate+'/toDate/'+toDate ;
         customHttp.get(url)
             .then(response => {
               var resourceViewCountGraphDate = response.data.message
               this.setState({resourceViewCountGraphDate:resourceViewCountGraphDate})
               // console.log("resourceViewCountGraphDate tyuytrtyu",resourceViewCountGraphDate);
               var temp = this.state.graphDataLine
               var data=[];
               var labels=[]
               for(var i=0;i<response.data.message.length;i++){
                  // console.log("overAll usage",response.data.message._id,response.data.message.totalUsage)
                  labels.push(response.data.message[i].date)
                  data.push(response.data.message[i].resourceCount)
                }
                // console.log("data,labels",data,labels);
                temp.labels = labels;
                temp.datasets[0].data = data;
                this.setState({graphDataLine:temp,noDataFound:response.data.message})

             })
             .catch(error => {
               this.setState({resourceViewCountGraphDate:[],noDataFound:[]})
               console.log("failed", error.response);
             });
           }
          }
        }

      demoResourceViewCountGraphDate=()=>{
        this.fetchAllCourses();
      }
  render(){
    // console.log("msg234567",this.state.coursesArray,this.state.usersArray,this.state.resourcesPdfArray,this.state.resourcesPdfArray.length)
    return(
      <div>


          <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px',paddingTop: 10}}>
            <div className="col-md-12 col-sm-12 nj-card" style={{paddingTop: 20,paddingBottom: 10}}>
              {this.state.show_Analytics_Loader !== true ?
              <div>
              {this.state.coursesArray.length > 0 ?
                <div>
                  <div className="col-md-3">
                    <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Courses:</label>
                    <div className="nj-form-select">
                      <select className="vs-input--input" value={JSON.stringify(this.state.coursesListObj)} onChange={this.handleChangeCourses}>
                        {/*<option hidden='true' value=''></option>*/}
                        {this.state.coursesArray.map((courses,i) =>{
                          return <option key={i} value={JSON.stringify(courses)}>{courses.displayName}</option>
                        })}
                      </select>
                    </div>
                  </div>

                {this.state.usersArray.length > 0 ?

                  <div>
                    <div className="col-md-3">
                      <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Users:</label>
                      <div className="nj-form-select">
                        <select className="vs-input--input" value={JSON.stringify(this.state.usersListObj)} onChange={this.handleChangeUsers}>
                          {/*<option hidden='true' value=''></option>*/}
                          {this.state.usersArray.map((users,i) =>{
                            return <option key={i} value={JSON.stringify(users)}>{users.username || users.email}</option>
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Resources:</label>
                      <div className="nj-form-select">
                        <select className="vs-input--input" value={JSON.stringify(this.state.resourcesListObj)} onChange={this.handleChangeResources}>
                          {/*<option hidden='true' value=''></option>*/}
                          {this.state.resourcesPdfArray.map((resources,i) =>{
                            return <option key={i} value={JSON.stringify(resources)}>{resources.displayName}</option>
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3"></div>

                    {this.state.resourceViewCountGraphDate.length >0 ?

                        <div className="col-md-12" style={{marginTop: 15}}>
                          <p style={{fontFamily:'Montserrat'}}><strong>No. Of Views</strong></p>
                          <Line data={this.state.graphDataLine} options={this.state.options} height={130} key={this.state.graphDataLine}/>
                          <p style={{fontFamily:'Montserrat',textAlign: 'center'}}><strong>Dates</strong></p>
                        </div>
                      :

                      <div className="col-md-12">
                        <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                      </div>

                    }

                  </div>
                  :
                  <div className="col-md-12">
                    <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                  </div>
                }

            </div>

          :
          <div className="col-md-12">
            <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
          </div>

        }</div>
        :

             <div className="row" style={{minHeight:"54.3vh"}}>
                <div className="col-md-12 aligh-user-loader-image-center" >
                  <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                </div>
            </div>
        }

          </div>
        </div>
      </div>
    );
  }
}
export default ResourceUsageByCourse;
