import React, {useEffect } from 'react';
import './AdminSlotBookingUsers.scss';
import { connect } from "react-redux";
import {getCourse, handleSelect, handleSelectAll, handleSearch, handleBack, handleSubmit} from "../../container/actions/AdminSlotBooking/action";
import { bindActionCreators } from 'redux';

function AdminSlotBookingUsers (props) {
  useEffect(()=>{
    const course = JSON.parse(sessionStorage.getItem('adminSlotBookingCourse'));
    props.getCourse(course._id,props.history);
  },[]);

  return (
    <div>
      <div className="add-on-load-animation">
        <div id="successSnackbar">{props.adminSlotBookingState.successAlertMessage}</div>
        <div id="errorSnackbar">{props.adminSlotBookingState.errorAlertMessage}</div>
        <div className="col-md-12 nj-card">
          <div className="row">
            <div className="nj-card__header">
              <div className="nj-card__title">
                <h4>Select Users</h4>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="row">
                  <input type="text" className="vs-input--input form-control" name="userSearch" defaultValue="" placeholder="Search Username" autoComplete="off" onChange={props.handleSearch} />
                </div>
              </div>
            </div>
          </div>
          {!props.adminSlotBookingState.userListLoader?<div className="row">
            {Array.isArray(props.adminSlotBookingState.userList) && props.adminSlotBookingState.userList.length>0 ? <div>
              <div className="tbl-content" style={{overflowY:"auto",overflowX:"auto",maxHeight:"71vh"}}>
                <table className="nj-table nj-table--tbody-table font_family_montserrat">
                  <thead className="nj-table--thead">
                    <tr style={{cursor:'default'}}>
                      <th>
                        <div className="nj-table-text">SL NO.</div>
                      </th>
                      <th>
                        <div className="nj-table-text">EMAIL</div>
                      </th>
                      <th>
                        <div className="nj-table-text">USERNAME</div>
                      </th>
                      <th>
                        <div className="nj-table-text">PHONE NUMBER</div>
                      </th>
                      <th>
                        <div className="nj-table-text" style={{minWidth:180}}>
                          SELECT 
                          {props.adminSlotBookingState.userList.length>1 && <span style={{display:'inline-block',textTransform:'capitalize',fontWeight:'500',marginLeft:10,color:'#444'}}>
                            Select All
                            <span className="overlay_checkbox" style={{marginLeft:5}}>
                              <label className="checkbox coloured" style={{padding:0}}>
                                <input type="checkbox" checked={props.adminSlotBookingState.userList.length===props.adminSlotBookingState.selectedUserList.length} onChange={props.handleSelectAll}/><span className="checkbox-material" style={{top:0}}><span className="check"></span></span> 
                              </label>
                            </span>
                          </span>}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.adminSlotBookingState.userList.map((data,i)=>{
                      return (<tr key={data._id}  className={"tr-values nj-table--tr tr-table-state-null "+(data.isChecked?'asb-selected-row':'')} onClick={() => props.handleSelect(data._id)}>
                        <td className="td nj-table--td">{i+1}</td>
                        <td className="td nj-table--td">{data.email}</td>
                        <td className="td nj-table--td">{data.username}</td>
                        <td className="td nj-table--td">{data.phoneNumber}</td>
                        <td className="td nj-table--td" style={{display: 'flex'}}>
                          <span className="overlay_checkbox ">
                            <label className="checkbox coloured" >
                              <input type="checkbox" checked={data.isChecked} onChange={() => props.handleSelect(data._id)}/><span className="checkbox-material"><span className="check"></span></span> 
                            </label>
                          </span>
                        </td>
                      </tr>)
                    })}
                  </tbody>
                </table>
              </div>
              <div className="col-md-12" style={{padding:'10px 5% 10px 10px'}}>
                <button type="button" className="nj-flow-button pull-right" style={{marginLeft:10}} onClick={()=>{props.handleSubmit(props.history)}}>Submit</button>
                <button type="button" className="nj-flow-button bg-color-red pull-right" onClick={()=>{props.handleBack(props.history)}}>Back</button>
              </div>
            </div>
            :
            <div className="col-md-12 idams_noDataFound" style={{height:"76.3vh"}}>
              <span className="font_family_montserrat">No data found</span>
            </div>
            }
          </div>
          :<div className="row">
            <div className="col-md-12" style={{height:"76.3vh",display:'flex',alignItems:'center',justifyContent:'center'}}>
              <img src={"/img/loaders/spinningwheel.gif"} height={50} width={50} alt="loader" />
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
  adminSlotBookingState: state.adminSlotBookingState,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  handleSelect,
  getCourse,
  handleSelectAll,
  handleSearch,
  handleBack,
  handleSubmit
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(AdminSlotBookingUsers);
