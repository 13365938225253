import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import * as UserContainer from "../../container/actions/Users/action";
// import Store from "../../container/reducers/Store";
import * as Actions from "../../container/actions/Users";
import EditUser from "./EditUser";
import { bindActionCreators } from 'redux';
import "./Users.scss"

function UserCourse (props) {
  // const [name, SetName, ] = useState("");
  // const [availableUsers ,setUsersArray] = useState([]);
  const [searchUser , setSearchValue] = useState("");
  // const [username , setusername] = useState("");
  // const [email , setemail] = useState("");
  // const [phoneNumber , setphoneNumber] = useState("");
  // const [selectCourse , setselectCourse] = useState("");
  const [selectCourseCsv , setselectCourseCsv] = useState("Select File");
  // const [course1 , setcourse1] = useState("");
  const obj = {"username":"","email":"","phoneNumber":""}
  const [createArray , setcreateArray] = useState(obj);
  // const [uploadcourse1 , setuploadcourse1] = useState("");
  const [selectCourseUser , setselectCourseUser] = useState({});
  const[data , setData] = useState({});
  const [searchCourse , setsearchCourse] = useState("");
  const[userDropdownValue , setUserDropdownValue] = useState("enrolled");
  // const[expanded, setExpanded] = useState(false);
  // const[courseArray, setCourseArray] = useState([]);
  const obj1 = {courseArray:[],file:{}}
  const [uploadFile, setUploadFile] = useState(obj1);
  // const[openUpload, setOpenUpload] = useState(false);



  useEffect( () => {
    //console.log("hello",props)
    props.getAllCreatedCourses();
    props.getAllCourses();
    props.setBooleanValue("showUserList",true);
    props.getDomainName()
    setselectCourseUser(props.userState.allCourses['0'])
    props.setSearchUsername("")
    
  },[]);

  useEffect( () => {
    setselectCourseUser(props.userState.allCourses['0'])
  },[props.userState.allCourses]);

  // onload function end
  var hideDeletePopUp=(e)=>{
    e.preventDefault();
    setData("");
    document.getElementById("deleteCoursePopup").style.display='none'
  }

  var handleSearchChange = (e) => {
    // console.log("qwertyuio",e.target.value)
    // var search = e.target.value;
    // console.log(e.target.value)
    setSearchValue(e.target.value)
    props.setSearchUsername(e.target.value)
    props.searchFilter(e.target.value)
    
  
};
// var searchFilter=(data)=>{
//   // props.getRequestedContent()
//   // console.log("search data",data);
//   var dublicateArray=props.userState.dupTotalUsrerList;
//   var searchRequestedArray=dublicateArray.filter(el =>{
//     if( el.username !== undefined){
//    return el.username.toLowerCase().indexOf(data.toString().toLowerCase()) !== -1;}
//   })
  
//   props.searchRequestedContent(searchRequestedArray);
//   // console.log("applicationArray1",searchRequestedArray);
// }

  var handleChangeCreateUser =(event) =>{
    if(event.target.name==="username"){
      props.checkUserNameExist(event.target.value);
      if (/^[A-Za-z0-9]*$/.test(event.target.value)){
        props.setBooleanValue("usernameMustbeAlphnumeric",false)
      }else{
        props.setBooleanValue("usernameMustbeAlphnumeric",true)
      }
    }
    if(event.target.name==="email"){
      props.checkEmailExist(event.target.value);
    }

    //console.log("hey")
    // setcreateArray(event.target.value)
    setcreateArray({...createArray,[event.target.name]:event.target.value});

  }


  var createSubmit = (event) =>{
    event.preventDefault();
    //console.log("submit data",createArray)
    // console.log("aithal",createArray,"createUser",selectCourseUser,userDropdownValue)
    props.createUser(createArray,"createUser",selectCourseUser,userDropdownValue)
    props.getUsersByType(selectCourseUser,userDropdownValue)
    //setselectCourseUser(props.userState.allCourses[0])
    setUserDropdownValue("enrolled")
    //clearUserInputFields();
  }

  var uploadSubmit = (event) =>{
    event.preventDefault();
    props.fileUpload(selectCourseUser,uploadFile,"createUser",userDropdownValue)
    //props.clearselecteCourseForCreateUser();
    //clearFileUploadFields();
    //setOpenUpload(false)
    //document.getElementById('createUser').style.display='none';

  }

  var handleChange=(e)=>{
    if(e.target.files.length>0){
      if(e.target.name==='file'){
        // console.log("file",e.target.files);
        setUploadFile(e.target.files[0]);
        setselectCourseCsv(e.target.files[0].name)
        //console.log("files",uploadFile)
      } else{
        setUploadFile(e.target.value);
      }
  }
  else{
    setselectCourseCsv("Select File");

  }
    
  }

  // var handleChangeCheckbox=(e)=>{
  //   console.log("checkbox")
  //   //setNoDepartment(false);
  //   if(e.target.checked){
  //     courseArray.push(e.target.value);
  //     //setselectCourseCsv(courseArray)
  //     console.log("array",courseArray)
  //   }
  //   else{
  //     let index = courseArray.indexOf(e.target.value)
  //     courseArray.splice(index,1);
  //     //setselectCourseCsv(courseArray)
  //   }

  // }

  // var showCheckboxes=()=>{
  //   var checkboxes = document.getElementById("checkboxes");
  //   if (!expanded) {
  //     checkboxes.style.display= "block";
  //     setExpanded(true)
  //   } else {
  //     checkboxes.style.display= "none";
  //     setExpanded(false);
  //   }
  // }


  var clearUserInputFields=()=>{
    setcreateArray({"username":"","email":"","phoneNumber":""});
    setsearchCourse("")
    props.setBooleanValue("userNameExist",false);
    props.setBooleanValue("userEmailExist",false)

  }

  var clearFileUploadFields=()=>{
    props.clearselecteCourseForCreateUser();
    //document.getElementById("createUser").style.display='none';
    //document.getElementById("uploadFile").value="";
    setselectCourseCsv("Select File");
    //setOpenUpload(false)
  }

  var handleChangeCourses =(event) =>{
    var selectedCourse= JSON.parse(event.target.value)
     setUserDropdownValue(userDropdownValue)
     setselectCourseUser(selectedCourse);
     props.getUsersByType(selectedCourse,userDropdownValue)
     // props.getUsersByCourse(selectedCourse);
  }

  
 
  var getConfirmDeletePopup= async(data)=>{
    //console.log("delete data",data);
    setData(data);
    document.getElementById("deleteCoursePopup").style.display="block"
    // console.log("userDropdownValue,selectCourseUser",userDropdownValue,selectCourseUser)
  }

  var getSelectedUserObject=(userObject)=>{
    // console.log("msg",JSON.parse(JSON.stringify(userObject)));
    // console.log("msg",userObject);
    props.setBooleanValue("selectedUser",userObject)
    props.setBooleanValue("showUserList",false)
    props.setBooleanValue("originalSelectedUser",userObject)
}

  var deleteRequestedContent=(e,data)=>{
    e.preventDefault();
    if(selectCourseUser===undefined){
      let obj = {};
      obj.userId=data._id;
      obj.courseId=props.userState.allCourses[0]._id;
      props.rejectUserContent(obj,selectCourseUser,userDropdownValue)
    }else{
      let obj = {};
      obj.userId=data._id;
      obj.courseId=selectCourseUser._id;
      props.rejectUserContent(obj,selectCourseUser,userDropdownValue)
    }
    document.getElementById("deleteCoursePopup").style.display="none";
  }


  var acceptRequestedCourse=(userObject)=>{

    if(selectCourseUser===undefined){
      let obj = {};
      obj.email=userObject.email;
      obj.courseId=props.userState.allCourses[0]._id;
      props.acceptUserRequest(obj,selectCourseUser,userDropdownValue)

    }else{
      let obj = {};
      obj.email=userObject.email;
      obj.courseId=selectCourseUser._id;
      props.acceptUserRequest(obj,selectCourseUser,userDropdownValue)

    }
  }

   var handleSearchCourse = (e) =>{
    setsearchCourse(e.target.value)
    props.getSearchCourses(e.target.value)
   }

   var handleChangeUsersDropdown =(event) =>{
    setUserDropdownValue(event.target.value)
    if(selectCourseUser===undefined){
      props.getUsersByType(props.userState.allCourses[0],event.target.value)
    }else{
      props.getUsersByType(selectCourseUser,event.target.value)
    }
  }

  var handlePopUp=()=>{
    //document.getElementById('uploadPopUp').style.display='block';
    props.setBooleanValue("openUpload",true)
    clearFileUploadFields();
    //setOpenUpload(true);
    //console.log("Upload popup",openUpload)
  }

  var handlePopUpClose=()=>{
    props.setBooleanValue("openUpload",false)
    props.clearselecteCourseForCreateUser();
    //clearFileUploadFields();
    //setOpenUpload(false);
  }

    return (
      <div className="add-on-load-animation">
        <div id="successSnackbar">{props.userState.successSnackbarMessage}</div>
        <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.userState.errorSnackbarMessage}</div>
        <div className="col-md-12">
          <div className="row">
            {props.userState.showUserList ?
            <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
              <div className="col-md-12 col-sm-12 nj-card" >
                <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                  <div className="nj-card__header">
                    <div className="nj-card__title">
                      <h4 className="font_family_montserrat">Available Users</h4>
                    </div>
                    <div style={{paddingBottom:'9px'}}>
                    <button className="nj-flow-button pull-right" onClick={()=>{document.getElementById('createUser').style.display='block';props.clearselecteCourseForCreateUser();clearUserInputFields();props.getUsersByType(selectCourseUser,userDropdownValue)}}>Create User</button>
                    </div>
                  </div>
                </div>
                {props.userState.allCourses.length>0 ?<div>
                <div className="col-md-3 nj-popup-form-wrap" style={{margin: '21px 0px'}}>
                    <p className="popup-content-label">Select Course</p>
                    <div className="nj-form-select">
                        <select name="bulkUploadDesktopLanguage" value={JSON.stringify(selectCourseUser)} onChange={handleChangeCourses} autoComplete="off" required>
                        {props.userState.allCourses.map((data,i)=>{
                            return (<option key={i} value={JSON.stringify(data)}>{data.displayName}</option>)
                          })}
                        </select>
                    </div>
                </div>
                <div className="col-md-3 nj-popup-form-wrap" style={{margin: '21px 0px'}}>
                    <p className="popup-content-label">Users type</p>
                    <div className="nj-form-select">
                        <select name="bulkUploadDesktopLanguage" value={(userDropdownValue)} onChange={handleChangeUsersDropdown} autoComplete="off" required>
                          <option value="enrolled">Enrolled</option>
                          <option value="requested">Requested</option>
                        </select>
                    </div>
                </div><br/>
                <div className="col-md-4 col-xs-12 pull-right nj-user-search-wrap ">
                    <p className="popup-content-label">Search Username</p>
                    <input className="vs-input--input nj-user-search-bar" type="text" name="searchUser" value={props.userState.searchUsername} onChange={handleSearchChange} placeholder="search" autoComplete="off"/>
                </div>
                </div>: null }
                <div className="col-md-12"><br/>
                {/* {console.log("",props.userState.availableCourseLoader)} */}
                  {!props.userState.availableCourseLoader?
                  <div className="course-table-margin-top">
                    {props.userState.totalUsrerList.length>0 && props.userState.allCourses.length>0  ?
                    <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"76.3vh"}}>
                      <table className="nj-table nj-table--tbody-table font_family_montserrat">
                        <thead className="nj-table--thead">
                          <tr>
                            <th>
                              <div className="nj-table-text">Username</div>
                            </th>
                            <th>
                              <div className="nj-table-text">E-mail</div>
                            </th>
                            <th>
                              <div className="nj-table-text" style={{minWidth:"150px"}}>Phone Number</div>
                            </th>
                            <th>
                              <div className="nj-table-text">Courses</div>
                            </th>
                            <th>
                              <div className="nj-table-text" style={{textAlign:"center"}}>ACTIONS</div>
                            </th>
                          </tr>
                        </thead>
                          {props.userState.totalUsrerList.length>0 ?
                           <tbody>
                             {/* {console.log("props.userState.totalUsrerList",props.userState.totalUsrerList)} */}
                          {props.userState.totalUsrerList.map((data,i)=>{
                            return (<tr key={i}  className="tr-values nj-table--tr tr-table-state-null">

                              {data.username !==undefined ? <td className="td nj-table--td">{data.username}</td> :  <td className="td nj-table--td">---</td> }
                              <td className="td nj-table--td">{data.email}</td>
                              {data.username !==undefined ? <td className="td nj-table--td">{data.phoneNumber}</td>:  <td className="td nj-table--td">---</td> }
                              {data.enabledCourses !== undefined &&
                              <td className="td nj-table--td">{data.enabledCourses.length}</td>}
                              <td className="td nj-table--td" style={{textAlign:"center",minWidth:"220px"}}>
                                {userDropdownValue==="requested" &&
                                 <button  type="button"  className="nj-flow-button addRoles_button" style={{float:"none"}} onClick={(e)=>{acceptRequestedCourse(data)}}>Accept</button>}
                                {userDropdownValue==="enrolled" && /* data.username !== undefined && data.username !== null && */
                                 <button  type="button"  className="nj-flow-button addRoles_button" style={{float:"none"}} onClick={(e)=>{getSelectedUserObject(data)}}>Edit</button>} {/* getSelectedUserObject(JSON.parse(JSON.stringify(data))) */}
                                 <button  type="button"  className="nj-flow-button bg-color-red addRoles_button" onClick={()=>getConfirmDeletePopup(data)} style={{float:"none",marginTop:"5px",marginLeft:"5px"}}>Reject</button>
                              </td>
                            </tr>)
                          })}
                          </tbody>
                          :
                          <tbody>
                            <td colSpan="5" className="td nj-table--td" style={{maxHeight:"76.3vh",textAlign:'center'}}>No Users Found</td>
                          </tbody>
                          }
                      </table>
                   </div>
                    :
                   <div className="col-md-12 idams_noDataFound" style={{height:"54vh"}}>
                     <span className="font_family_montserrat">No data found</span>
                   </div>
                    }
                  </div>
                  :
                  <div className="row" style={{height:"76.3vh"}}>
                    <div className="col-md-12 aligh-loader-image-center" >
                      <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
            :
            <EditUser selectCourseUser={selectCourseUser}/>
            }
          </div>
        </div>

         {/*popup to delete course*/}
         <div id="deleteCoursePopup" className={"nj-overly "+(props.userState.shouldPopupAnimate ? "add-rebound-animation" : "no-animation")}>
            <div className="popup-nj my-popup">
              <header className="popup-header">
                <span>Reject Users</span>
                <span onClick={hideDeletePopUp} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
              </header>
              <div className="popup-content">
                <div className="col-md-12">
                  <form name="roleForm">
                    <div className="nj-popup-form-wrap">
                      <p className="popup-content-label font_family_montserrat" style={{fontSize:"16px"}}>Do you really want to Reject?</p>
                    </div>
                      {props.userState.disableRoleDelete ? <button type="submit" className="nj-flow-button pull-right" style={{margin:5}} onClick={(e)=>deleteRequestedContent(e,data)}>Confirm</button>:<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                    <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={hideDeletePopUp}  style={{margin:5}}>Cancel</button></div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/**create user popup */}
          <div id="createUser" className={"nj-overly "+(props.userState.shouldPopupAnimate ? "add-rebound-animation" : "no-animation")}>
          <div className="popup-nj  my-popup" style={{maxWidth:"500px",marginTop:"3%"}}>
            {props.userState.openUpload === false ?
            <header className="popup-header">
              <span>Create User</span>
              <span onClick={()=>{document.getElementById('createUser').style.display='none';props.clearselecteCourseForCreateUser();clearUserInputFields();props.getUsersByType(selectCourseUser,userDropdownValue)}} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
            </header>
            :
            <header className="popup-header">
              <span>Upload CSV</span>
              <span onClick={handlePopUpClose} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
            </header>
            }
            <div className="popup-content">
              <div className="col-md-12">
                {props.userState.openUpload === false ? 
              <form name="createUser" onSubmit={createSubmit}>
              <div className="nj-popup-form-wrap">
                  <p className="terms-form">Username</p>
                  <input type="text" name="username" value={createArray.username} onChange={handleChangeCreateUser} maxLength="20" style={{textTransform: "lowercase"}} className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="username" autoComplete="off" required/>
                  {props.userState.userNameExist && <span className="required_field_color_red font_family_montserrat" style={{fontSize:'12px',fontWeight:'500'}}>Username is taken!</span>}
                  {props.userState.usernameMustbeAlphnumeric && <span className="required_field_color_red font_family_montserrat" style={{fontWeight:'500',fontSize:'12px'}}>Username can only be alphanumeric!</span>}
                </div>
                <div className="nj-popup-form-wrap">
                  <p className="terms-form">Email</p>
                  <input type="email" name="email" value={createArray.email} onChange={handleChangeCreateUser}  className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="email" autoComplete="off" required/>
                  {props.userState.userEmailExist && <span className="required_field_color_red font_family_montserrat" style={{fontSize:'12px',fontWeight:'500'}}>Email is taken!</span>}
                </div>
                <div className="nj-popup-form-wrap">
                  <p className="terms-form">Phone Number</p>
                  <input type="text" name="phoneNumber"  value={createArray.phoneNumber} onChange={handleChangeCreateUser} className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="phone number" autoComplete="off" required />
                </div>
                <div className="nj-popup-form-wrap">
                    <div>
                      <p className="terms-form">Available Courses
                      <input className="vs-input--search-input pull-right" type="text" name="searchCourse" value={searchCourse} onChange={handleSearchCourse} placeholder="search courses" autoComplete="off"  /></p>
                      {/* <p className="popup-content-label col-md-6" >Search Username</p>
                      <input className="vs-input--input nj-user-search-bar" type="text" name="searchUser"  onChange={handleSearchCourse} placeholder="search" autoComplete="off"/> */}
                    </div>
                    {props.userState.searchByCourses.length>0 ?
                      <div className="tbl-content" style={{maxHeight:'22vh',overflowY:'scroll',marginTop:"5%"}}>
                        <div className="resources-wrapper">
                          {props.userState.searchByCourses.map((item, i) => {
                            return (
                              <div className="resource_card add-rebound-animation" key={item._id}>
                                <div className="resource_card-content">
                                  <div className="resource-card-float">
                                    <div className="overlay_checkbox resource-card-float-element">
                                      <label className="checkbox coloured">
                                        <input type="checkbox" checked={item.isSelected} onChange={(e) => props.selectCourseForCreateUser(item)}/><span className="checkbox-material"><span className="check"></span></span>
                                      </label>
                                    </div>
                                  </div>
                                 <div className="resource_card-content-img-wrapper" onClick={(e) => props.selectCourseForCreateUser(item)}>
                                    <img className="resource_card-content-img" src={"/img/resource-icons/folder.svg"} alt="course" />
                                  </div>
                                </div>
                                <div className="resource_card-footer" onClick={(e) => props.selectCourseForCreateUser(item)}>
                                  <p className="font_family_montserrat">{item.displayName}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      :
                        <div className="col-md-12 font_family_montserrat idams_noDataFound" style={{height:'25vh'}}>No data found</div>
                      }
                  </div>
                  <div className="nj-users-upload-csv col-md-4" style={{fontWeight:"600",color:"#464db4",padding:"12px",cursor:"pointer"}} onClick={handlePopUp}><a href="# ">Upload CSV</a></div>
                  {props.userState.userCreate_loader ===true ? <button type="submit" className="nj-flow-button pull-right" style={{margin:5}} disabled={props.userState.usernameMustbeAlphnumeric || props.userState.userNameExist || props.userState.userEmailExist}>Submit</button>:<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                  <button type="button" className="nj-flow-button bg-color-red pull-right" disabled={!props.userState.userCreate_loader} onClick={(e)=>{document.getElementById('createUser').style.display='none';props.clearselecteCourseForCreateUser();clearUserInputFields();props.getUsersByType(selectCourseUser,userDropdownValue);}} style={{margin:5}}>Cancel</button>

              </form>
              :
              <form name="uploadFilePopup" onSubmit={uploadSubmit}>
                <div className="nj-popup-form-wrap">
                  <p className="terms-form">Upload CSV</p>
                  <div className="file-upload-wrapper" id="file-upload-wrapper1" data-text={selectCourseCsv}>
                    <input id="uploadFile" className="vs-input--input" type="file" name="file" accept=".csv" onChange={handleChange} placeholder="Select File" autoComplete="off" required />
                    <p><a style={{fontSize: 12, color: "rgba(115,103,240)",fontWeight: '500'}} href="https://platifistorage.blob.core.windows.net/elf/sample.csv" download>Download CSV file Sample</a><br/></p>
                  </div><br/>
                </div><br/>
                <div className="nj-popup-form-wrap">
                    <div>
                      <p className="terms-form">Available Courses
                      <input className="vs-input--search-input pull-right" type="text" name="searchCourse" value={searchCourse} onChange={handleSearchCourse} placeholder="search courses" autoComplete="off"  /></p>
                    </div>
                    {props.userState.searchByCourses.length>0 ?
                      <div className="tbl-content" style={{maxHeight:'24vh',overflowY:'scroll',marginTop:"5%"}}>
                        <div className="resources-wrapper">
                          {props.userState.searchByCourses.map((item, i) => {
                            return (
                              <div className="resource_card add-rebound-animation" key={item._id}>
                                <div className="resource_card-content">
                                  <div className="resource-card-float">
                                    <div className="overlay_checkbox resource-card-float-element">
                                      <label className="checkbox coloured">
                                        <input  id="uploadCheckbox" type="checkbox" checked={item.isSelected} onChange={(e) => props.selectCourseForCreateUser(item)}/><span className="checkbox-material"><span className="check"></span></span>
                                      </label>
                                    </div>
                                  </div>
                                 <div className="resource_card-content-img-wrapper" onClick={(e) => props.selectCourseForCreateUser(item)}>
                                    <img className="resource_card-content-img" src={"/img/resource-icons/folder.svg"} alt="course" />
                                  </div>
                                </div>
                                <div className="resource_card-footer" onClick={(e) => props.selectCourseForCreateUser(item)}>
                                  <p className="font_family_montserrat">{item.displayName}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      :
                        <div className="col-md-12 font_family_montserrat idams_noDataFound" style={{height:'25vh'}}>No data found</div>
                      }
                  </div>
                  {props.userState.userUpload_loader ===true ? <button type="submit" className="nj-flow-button pull-right" style={{margin:5}} disabled={props.userState.usernameMustbeAlphnumeric || props.userState.userNameExist || props.userState.userEmailExist}>Submit</button>:<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                  <button type="button" className="nj-flow-button bg-color-red pull-right" disabled={!props.userState.userUpload_loader} onClick={handlePopUpClose} style={{margin:5}}>Cancel</button>

              </form>
              }
              </div>
            </div>
          </div>
          </div>

          {/**Upload Csv pop up */}

          {/* <div id="uploadPopUp" className={"nj-overly "+(props.userState.shouldPopupAnimate ? "add-rebound-animation" : "no-animation")}>
          <div className="popup-nj  my-popup">
            <header className="popup-header">
              <span>Create User</span>
              <span onClick={()=>{document.getElementById('uploadPopUp').style.display='none';clearFileUploadFields();/*this.setState({userCreate_loader:true,bulkUpload:false})}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null" style={{cursor:'pointer'}}>close</i></span>
            </header>
            <div className="popup-content">
              <div className="col-md-12"><br/>
              <form name="uploadPopUp" onSubmit={uploadSubmit}>
              <p className="terms-form">Upload CSV</p>
              <div className="file-upload-wrapper" id="file-upload-wrapper1" data-text={selectCourseCsv}>
                  <input id="uploadFile" className="vs-input--input" type="file" name="file" accept=".csv" onChange={handleChange} placeholder="Select File" autoComplete="off" required />
                  <p><a style={{fontSize: 12, color: "rgba(115,103,240)",fontWeight: '500'}} href="https://platifistorage.blob.core.windows.net/elf/sample.csv" download>Download CSV file Sample</a><br/></p>
              </div>
              <p className="terms-form"></p><br/>
                      
                    <div className="multiselect"><br/>
                            <div className="nj-form-select" onClick={showCheckboxes}>
                              <select className="vs-input--input" required> 
                                <option>Select Course</option>
                              </select>
                              <div className="overSelect"></div>
                            </div>
                            {props.userState.allCourses.length>0 ?
                            <div id="checkboxes">
                                {props.userState.allCourses.map((data,i) =>{
                                  return ( 
                                    <label style={{paddingLeft:"5px"}} key={i}>
                                  <input type="checkbox" id="uploadCheckbox" key={i} onChange={e => handleChangeCheckbox(e)} value={data._id}/> {data.displayName} </label>)
                              })}

                            </div>
                            :
                            <div id="checkboxes">
                              <p style={{textAlign:"center",marginTop:"2%",fontFamily:"Montserrat',Sans-serif"}}> No Courses</p>
                            </div>
                          }
                    </div>

                  {/* <div className="nj-popup-form-wrap">
                    <p className="popup-content-label">Available Courses</p>
                    <div className="nj-form-select">
                      <select name="selectCourseCsv"  value={selectCourseCsv} onChange={handleChange} autoComplete="off" required>
                        {/* <option value="uploadcourse1">Course1</option> 
                        {/*{props.userState.allCourses.map((data,i)=>{
                            return (<option value="uploadcourse1" key={i}>{data.displayName}</option>)
                          })}
                      </select>
                    </div>
                  </div> 
                  {props.userState.userCreate_loader ===true ? <button type="submit" className="nj-flow-button pull-right" style={{margin:5}}>Submit</button>:<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                  <button type="button" className="nj-flow-button bg-color-red pull-right" disabled={!props.userState.userCreate_loader} onClick={(e)=>{document.getElementById('uploadPopUp').style.display='none'}} style={{margin:5}}>Cancel</button>

              </form>
              </div>
            </div>
          </div>
          </div> */}

      </div>
    );
  }


const mapStateToProps = state => ({
    userState: state.userState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllApplications:UserContainer.getAllApplications,
  setBooleanValue:Actions.setBooleanValue,
  getAllCreatedCourses:UserContainer.getAllCreatedCourses,
  animationFunctionForPopup:UserContainer.animationFunctionForPopup,
  getAllCourses:UserContainer.getAllCourses,
  selectCourseForCreateUser:UserContainer.selectCourseForCreateUser,
  clearselecteCourseForCreateUser:UserContainer.clearselecteCourseForCreateUser,
  createUser:UserContainer.createUser,
  // getUsersByCourse:UserContainer.getUsersByCourse,
  checkUserNameExist:UserContainer.checkUserNameExist,
  checkEmailExist:UserContainer.checkEmailExist,
  deleteUserContent:UserContainer.deleteUserContent,
  searchRequestedContent:Actions.searchRequestedContent,
  getSearchCourses:UserContainer.getSearchCourses,
  getDomainName:UserContainer.getDomainName,
  getUsersByType:UserContainer.getUsersByType,
  acceptUserRequest:UserContainer.acceptUserRequest,
  rejectUserContent:UserContainer.rejectUserContent,
  searchFilter:UserContainer.searchFilter,
  setSearchUsername:UserContainer.setSearchUsername,
  fileUpload:UserContainer.fileUpload,
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(UserCourse);
