import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import * as UserContainer from "../../container/actions/Users/action";
// import Store from "../../container/reducers/Store";
import * as Actions from "../../container/actions/Users";
import { bindActionCreators } from 'redux';

function EditUser (props) {

  const[selectCourseUser] = useState(props.selectCourseUser);

  useEffect( () => {
    // console.log("hello",props)
    props.setBooleanValue("editShowSelectedCourses",true)
  },[]);

  // onload function end

    return (
      <div className="add-on-load-animation">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
            <div className="col-md-12 col-sm-12 nj-card" >
              <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                <div className="nj-card__header">
                  <div className="nj-card__title">
                    <h4 className="font_family_montserrat">Edit User</h4>
                  </div>
                  <div style={{paddingBottom:'9px'}}>
                    <button className="nj-flow-button pull-right" type="button" onClick={()=>{props.setBooleanValue("showUserList",true)}}>Back</button>
                  </div>
                </div>
              </div>
              <div className="row" style={{paddingTop:"10px"}}>

                <div className="col-md-8 col-sm-12 col-xs-12" style={{marginBottom:"10px"}}>
                  {/* displaying course block */}
                  <div className="col-md-12">
                  {props.userState.editShowSelectedCourses ?
                    <div>
                    <div className="row" style={{padding:"0px 0px 10px 10px"}}>
                      <div className="col-md-7 col-sm-7 col-xs-6">
                        <h4 className="font_family_montserrat">Available Courses</h4>
                      </div>
                      <div className="col-md-5 col-sm-5 col-xs-6" style={{textAlign:"right"}}>
                        <button  type="button" className="nj-flow-button" style={{color: "white"}} onClick={(e)=>{props.setBooleanValue("editShowSelectedCourses",false);props.getAvailableAllCourses(props.userState.selectedUser.enabledCourses)}}><span className="glyphicon glyphicon-plus"></span> Add</button>
                      </div>
                    </div>
                    {Object.keys(props.userState.selectedUser).length>0 && props.userState.selectedUser.enabledCourses.length>0 ?
                    <div className="tbl-content" style={{maxHeight:'64vh',overflowY:'scroll'}}>
                      <div className="resources-wrapper">
                        {props.userState.selectedUser.enabledCourses.map((item, i) => {
                          return (
                            <div className="resource_card add-rebound-animation" key={item._id}>
                              <div className="resource_card-content">
                                <div className="resource-card-float">
                                  <div className="overlay_checkbox resource-card-float-element">
                                    <label className="checkbox coloured">
                                      <span style={{outline:"none",color:"rgb(238, 94, 94)"}} onClick={(e)=>{props.deleteSelectedUserCourse(item)}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline>
                                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                          <line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
                                        </svg>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="resource_card-content-img-wrapper" style={{cursor:"auto"}}>
                                  <img className="resource_card-content-img" src={"/img/resource-icons/folder.svg"} alt="course" />
                                </div>
                              </div>
                              <div className="resource_card-footer" style={{cursor:"auto"}}>
                                <p className="font_family_montserrat">{item.displayName}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    :
                    <div className="col-md-12 font_family_montserrat idams_noDataFound">No data found</div>
                    }
                    </div>
                    :
                    <div>
                    <div className="row" style={{padding:"0px 0px 10px 10px"}}>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <h4 className="font_family_montserrat">Select Courses</h4>
                      </div>
                    </div>
                    {props.userState.showAllCoursesLoader?
                    <div>
                    {props.userState.AvailableAllCourses.length>0 ?
                    <div >
                      <div className="tbl-content" style={{maxHeight:'62vh',overflowY:'scroll'}}>
                        <div className="resources-wrapper">
                          {props.userState.AvailableAllCourses.map((item, i) => {
                            return (
                              <div className="resource_card add-rebound-animation" key={item._id}>
                                <div className="resource_card-content">
                                  <div className="resource-card-float">
                                    <div className="overlay_checkbox resource-card-float-element">
                                      <label className="checkbox coloured">
                                        <input type="checkbox" checked={item.isSelected} onChange={(e) => props.selectCourseForEditUser(item)}/><span className="checkbox-material"><span className="check"></span></span>
                                      </label>
                                    </div>
                                  </div>
                                   <div className="resource_card-content-img-wrapper" onClick={(e) => props.selectCourseForEditUser(item)}>
                                    <img className="resource_card-content-img" src={"/img/resource-icons/folder.svg"} alt="course" />
                                  </div>
                                </div>
                                <div className="resource_card-footer" onClick={(e) => props.selectCourseForEditUser(item)}>
                                  <p className="font_family_montserrat">{item.displayName}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12" style={{textAlign:"right",marginTop:"10px"}}>
                        <button  type="button" className="nj-flow-button bg-color-red" style={{color: "white",marginRight:"5px"}} onClick={(e)=>{props.setBooleanValue("editShowSelectedCourses",true)}}>Cancel</button>
                        <button  type="button" className="nj-flow-button" style={{color: "white"}} onClick={(e)=>{props.getSelectedCoursesForEditUser()}}>Save</button>
                      </div>
                    </div>
                    :<div>
                      <div className="col-md-12 font_family_montserrat idams_noDataFound" style={{height:"24vh"}}>No data found</div>
                      <div className="col-md-12 col-sm-12 col-xs-12" style={{textAlign:"right"}}>
                          <button  type="button" className="nj-flow-button" style={{color: "white",marginRight:"5px"}} onClick={(e)=>{props.setBooleanValue("editShowSelectedCourses",true)}}>Back</button>
                      </div>
                    </div>
                    }
                    </div>
                    :
                    <div className="row" style={{maxHeight:"62.3vh"}}>
                      <div className="col-md-12 aligh-user-loader-image-center">
                        <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />

                      </div>
                    </div>
                  }
                    </div>
                  }
                  </div>
                  {/* displaying course block end */}
                </div>

                <div className="col-md-4 col-sm-12 col-xs-12">
                {/* {props.courseState.selectedCourse} */}
                  <form onSubmit={(e)=>{props.updateEditUser(e,selectCourseUser)}}>
                    <div className="row" style={{marginTop:'0px'}}>
                        <div className="col-md-12 col-sm-12">
                            <label className="idams-label pull-left">Username</label>
                            <input type="text"
                              className="pull-left vs-input--input form-control"
                              defaultValue={props.userState.selectedUser.username}
                              autoComplete="off"
                              name="username"
                              onChange={(e)=>{props.handleChangeEdituserInputFields(e.target.name,e.target.value)}}
                              disabled={true}
                              required />
                        </div>
                    </div>
                    <div className="row" style={{marginTop:'20px'}}>
                        <div className="col-md-12 col-sm-12">
                            <label className="idams-label pull-left">Email </label>
                            <input type="email" className="pull-left vs-input--input form-control" defaultValue={props.userState.selectedUser.email} autoComplete="off" name="email" onChange={(e)=>{props.handleChangeEdituserInputFields(e.target.name,e.target.value)}} required /><br/>
                            {props.userState.EditUserEmailExist && <div className="required_field_color_red font_family_montserrat" style={{fontSize:'14px',fontWeight:'500'}}>Email is taken!</div>}
                        </div>
                    </div>
                    <div className="row" style={{marginTop:'20px'}}>
                        <div className="col-md-12 col-sm-12">
                            <label className="idams-label pull-left">Phone Number</label>
                            <input type="text"
                              className="pull-left vs-input--input form-control"
                              name="phoneNumber"
                              defaultValue={props.userState.selectedUser.phoneNumber}
                              autoComplete="off"
                              onChange={(e)=>{props.handleChangeEdituserInputFields(e.target.name,e.target.value)}}
                              required />
                        </div>
                    </div>
                    <div className="row" style={{marginTop:'20px',marginBottom:'30px'}}>
                      <div className="col-md-12">
                        {!props.userState.showUserUpdateLoader ? <button  type="submit" className="nj-flow-button pull-right" style={{color: "white"}} disabled={props.userState.EditUserEmailExist}>Submit</button> :<img src={"/img/loaders/spinningwheel.gif"}  className="pull-right" height={80} width={80} alt="loader" />}
                      </div>
                    </div>

                  </form>
                </div>
             </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }


const mapStateToProps = state => ({
    userState: state.userState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllApplications:UserContainer.getAllApplications,
  setBooleanValue:Actions.setBooleanValue,
  selectApplication:UserContainer.selectApplication,
  deleteSelectedApplication:UserContainer.deleteSelectedApplication,
  getSelectedApplications:UserContainer.getSelectedApplications,
  clearSelectApplication:UserContainer.clearSelectApplication,
  selectResource:UserContainer.selectResource,
  deleteSelectedResource:UserContainer.deleteSelectedResource,
  getSelectedResources:UserContainer.getSelectedResources,
  clearSelectResource:UserContainer.clearSelectResource,
  handleChangeCourseCreationInputFields:Actions.handleChangeCourseCreationInputFields,
  createCourse:UserContainer.createCourse ,
  getAllCreatedCourses:UserContainer.getAllCreatedCourses,
  animationFunctionForPopup:UserContainer.animationFunctionForPopup,
  getSelectedCourse:UserContainer.getSelectedCourse,
  getAllCourses:UserContainer.getAllCourses,
  selectCourseForCreateUser:UserContainer.selectCourseForCreateUser,
  clearselecteCourseForCreateUser:UserContainer.clearselecteCourseForCreateUser,
  createUser:UserContainer.createUser,
  getUsersByCourse:UserContainer.getUsersByCourse,
  checkUserNameExist:UserContainer.checkUserNameExist,
  checkEmailExist:UserContainer.checkEmailExist,
  deleteUserContent:UserContainer.deleteUserContent,
  searchRequestedContent:Actions.searchRequestedContent,
  selectCourseForEditUser:UserContainer.selectCourseForEditUser,
  getSelectedCoursesForEditUser:UserContainer.getSelectedCoursesForEditUser,
  deleteSelectedUserCourse:UserContainer.deleteSelectedUserCourse,
  handleChangeEdituserInputFields:UserContainer.handleChangeEdituserInputFields,
  updateEditUser:UserContainer.updateEditUser,
  getAvailableAllCourses:UserContainer.getAvailableAllCourses
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(EditUser);
