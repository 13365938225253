import * as courseAnalyticsActionTypes from "../../../actionTypes";

const slotUsageInitialState = {
  slotUsageList:[],
  slotUsageLoader:false,
  slotUsageCourse:{},
  userSlotUsageHistory:{totalConsumption:"0 minutes",totalIdleTime:"0 minutes",quantitativeScore:0,sessionData:[]},
  userSlotUsageHistoryLoader:false,
  userSlotRating:{ratedAt:"",rating:0,comment:"",email:"",username:""},
  userSlotRatingLoader:false,
}

const initialState = {
  allApplications:[],
  showSelectedApplications:true,
  showSelectedResources:true,
  selectedApplications:[],
  selectedResources:[],
  popupArray:[],
  courseName:"",
  displayName:"",
  fromDate:"",
  toDate:"",
  availableCourseLoader:false,
  availableCourses:[],
  shouldPopupAnimate:false,
  selectedCourse:{},
  disableRoleDelete:true,
  showUserList:true,
  userCreate_loader:true,
  // selectCourse:'',
  availableUsers:[],
  allCourses:[],
  successSnackbarMessage:"",
  errorSnackbarMessage:"",
  selectedCourseUsers:[],
  totalUsrerList:[],
  usernameMustbeAlphnumeric:false,
  userNameExist:false,
  userEmailExist:false,
  dupTotalUsrerList:[],
  editShowSelectedCourses:true,
  selectedUser:{},
  originalSelectedUser:{},
  EditUserEmailExist:false,
  showUserUpdateLoader:false,
  AvailableAllCourses:[],
  searchByCourses:[],
  enteredDomainName:"",
  searchUsername:"",
  ...slotUsageInitialState,
  //course usage
  options :{
    chart: {
      type: 'area',
      height: 450,
      stacked: false,
      fontFamily: 'Montserrat,sans-serif',
      foreColor: '#626262'
    },
    colors: ['#008FFB','#00E396'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.4,
        opacityTo: 0.5,
      },
      colors: ['#008FFB','#00E396'],
    },
    legend: {
      show:false,
      position: 'top',
      horizontalAlign: 'right'
    },
    xaxis: {
      type: 'category',
      categories:[]
    },
    stroke: {
      show: true,
      curve: 'smooth',
      colors: ['#008FFB','#00E396'],
      width: 2,
      dashArray: 0,
    }
  },
  totalCourses:[],
  initialLoaderForBookedAndUsedSlots:true,
  bookedAndUsedSlots:{},
  series:[{name: 'Booked Slots',data:[]},{name: 'Used Slots',data:[]}],
  noDataAvailable:false,
  startDate:'',
  endDate:'',
  selectedCourseId:'',
  bookedAndUsedSlotSelectedCourse:{},
  selectedType:'demo',
  demoCoursesArray:[{name: "Fluid Mechanics",_id: "5c15f3907257166d93c74bb2"},
  {name: "Manufacturing Technology",_id: "5c15f4677257166d93c74bb2"},
  {name: "Applied Thermodynamics",_id: "5c15f40c7257166d93c74bb2"},
  {name: "Dynamics of Machinery",_id: "5c15f3907257166d93c7bb2"},
  {name: "Design of Machine Elements",_id: "5c15f3907257166d453c74bb2"}] 

}

export default function(state=initialState, action) {
  switch (action.type) {
    case courseAnalyticsActionTypes.COURSE_ANALYTICS_COMPONENT_ELEMENTS: {
      const {name,value} = action.payload; 
      return {
        ...state,[name]:value
      }   
    }
    case courseAnalyticsActionTypes.COURSE_SET_BOOLEAN_VALUE: {
      const {name,value} = action.payload; 
      return {
        ...state,[name]:value
      }
    }
    case courseAnalyticsActionTypes.COURSE_GET_CREATED_COURSES: {
      const {content } = action.payload; 
      return {
        ...state,availableCourses:content
      }
    }
    case courseAnalyticsActionTypes.GET_ALL_COURSES: {
      const {content } = action.payload; 
      // console.log("ssgss",content)
      return {
        ...state,allCourses:content,searchByCourses:content,
      }
    }
    case courseAnalyticsActionTypes.SEARCH_USERS_NAME: {
      const {searchRequestedArray} = action.payload; 
      return {
        ...state,totalUsrerList:searchRequestedArray
      }
    }
    case courseAnalyticsActionTypes.GET_SELECTED_USER_COURSES: {
      const {content } = action.payload; 
      // console.log("",content)
      return {
        ...state,totalUsrerList:content,dupTotalUsrerList:content
      }
    }
    case courseAnalyticsActionTypes.COURSE_ANALYTICS_SETSEARCHUSERNAME: {
      const {content } = action.payload; 
     
      return {
        ...state,searchUsername:content
      }
    }
  
    default:
      return state;
  }
}