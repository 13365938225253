export default function(dateObj){
  const date = new Date(dateObj);

  var day = date.getDate();
  if(day<10){
    day = '0'+day;
  }

  var month = date.getMonth() + 1; //Months are zero based
  if(month<10){
    month = '0'+month;
  }

  const year = date.getFullYear();

  return {year,month,day}
}