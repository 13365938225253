import * as objectandfacerecognitionTypes from "../../../actionTypes";
const initialState = {
  rpAllCourses:[],
  rpDatesArray:[],
  selectedRPCourse:{},
  selectedRPDate:{},
  bookedSlots:[],
  selectedSlot:{},
  statusObj:[{name: 'Attended',value:"Attended"},{name: 'Not Attended',value:"Not Attended"}],
  selectedStatus:{name: 'Attended',value:"Attended"},
  rpInitialLoader:false,
  webcamVideoLoader:false,
  videoArray:[],
  selectedVideoObject:{},
  videoUrlPath:"https://mediaservers.platifi.com/uploads/",
  videoUrlpathApi:"https://mediaservers.platifi.com",
  pdfUrlPath:"https://iitb2disks244.blob.core.windows.net/pdf/",
  videoFileType:'webm',
  selectedBookedSlot:"",
  showPdf:false,
  pdfState:{pdf: null,
    currentPage: 1,
    zoom: 1
  },
  pdfLoader:false,
  successSnackbarMessage:'',
  errorSnackbarMessage:''
}

export default function(state=initialState, action) { 
  switch (action.type) {
    case objectandfacerecognitionTypes.OBJECTANDFACERECOGNITION_COMPONENT_ELEMENTS: {
      const {name,content } = action.payload; 
      return {
        ...state,[name]:content
      }
    }
    default:
      return state;
  }
}