import React, { Component, Fragment } from 'react';
import './Analytics.scss';
// import { connect } from "react-redux";
// import * as AnalyticsContainer from "../../container/actions/Analytics/action";
// import Store from "../../container/reducers/Store";
// import { bindActionCreators } from 'redux';
import DatePicker from 'react-date-picker';
import 'react-datepicker/dist/react-datepicker.css';
import { Doughnut } from 'react-chartjs-2';
import {Chart } from 'react-chartjs-2';
// import axios from "axios";
import customHttp from '../../packages/CustomHttp';

class CourseRatings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      demoCoursesArray:[{displayName: "Fluid Mechanics",_id: "5c15f3907257166d93c74bb2"},
      {displayName: "Manufacturing Technology",_id: "5c15f4677257166d93c74cb3"},
      {displayName: "Applied Thermodynamics",_id: "5c15f40c7257166d93c74c4f"},
      {displayName: "Dynamics of Machinery",_id: "5c15f3907257166d93c74r4"},
      {displayName: "Design of Machine Elements",_id: "5c15f3907257166d453c74bb2"}],
      courseStartDate:"",
      courseEndDate:"",
      courseObject:{},
      coursesArrayRatings:[],
      courseRatings:[],
      totalRating: 0,
      show_Analytics_Loader:true,
      options1: {
        cutoutPercentage: 80,
        legend: {
            "display": false,
              "position": "top",
              "fullWidth": true,
              "reverse": false,
              "responsive": true,
              "maintainAspectRatio": true,
              "hoverBackgroundColor": false,
            },
            tooltips: {
                mode: 'label',
                callbacks: {
                    label: function(tooltipItem, data) {
                        var indice = tooltipItem.index;
                        return  data.labels[indice] +' : '+data.datasets[0].data[indice] // + 'hi';
                    }
                }
            }
        },
      graphDataPieCourse:{
        labels: ['1 Star', '2 Star', '3 Star', '4 Star', '5 Star'],
        datasets: [
          {
            label: "Ratings",
            data: [],
            color: "transparent",
            borderWidth: 8,
            fill: false,
            lineTension: 0.1,
            backgroundColor: ["#c96734", "#945049", "#5e3d5f", "#6f5d7d", "#8da1bc"],
            borderColor: "transparent",
            borderCapStyle: 'butt',
            borderDash: ["#c96734", "#945049", "#5e3d5f", "#6f5d7d", "#8da1bc"],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: [],
            pointBackgroundColor: '#fff',
            pointBorderWidth: 4,
            pointHoverRadius: 6,
            pointHoverBorderWidth: 1,
            pointRadius: 5,
            pointHitRadius: 1,
            pointHoverBackgroundColor: ["#c96734", "#945049", "#5e3d5f", "#6f5d7d", "#8da1bc"],
            pointHoverBorderColor: ["#c96734", "#945049", "#5e3d5f", "#6f5d7d", "#8da1bc"],

          }
        ],
        text:0
      },
    }
  }

  componentDidMount(){
    var t = new Date();
    this.setState({courseEndDate:t})
    var p = new Date();
    var dateInSec = p.getTime();
    var d = dateInSec-6.048e+8;
    var fromDateInSec = new Date(d);
    var tempDate = fromDateInSec.getDate();
    var tempMonth = fromDateInSec.getMonth()+1;
    var tempYear = fromDateInSec.toString().substring(11,15);
    var fromDate = tempMonth + "/" + tempDate + "/" + tempYear;   // date conversion dd/mm/yyyy

    this.setState({courseStartDate:new Date(fromDate)})
    this.fetchAllCoursesForCourseRatings();
    // this.fetchAllCourses();
    // getCourseRatings(courseStartDate,courseEndDate,courseList._id);
  
  
  }

  ratingsPercentage = () =>{
    var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
    Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
      draw: function() {
        originalDoughnutDraw.apply(this, arguments);
        
        var chart = this.chart.chart;
        var ctx = chart.ctx;
        var width = chart.width;
        var height = chart.height;

        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em Verdana";
        ctx.textBaseline = "middle";
      
        var text = chart.config.data.text,
        // console.log("courseRatings.averageRating",courseRatings.averageRating)
          //  var text = courseRatings.averageRating,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;

        ctx.fillText(text, textX, textY);
      }
   });
  }
 

  handleChangeStartCourse =(sdate)=>{
      const startValue = sdate
      this.setState({courseStartDate:startValue},()=>{this.courseRatingsGraphData()})
    }
  handleChangeEndCourse =(edate)=>{
    const endValue = edate
    this.setState({courseEndDate:endValue},()=>{this.courseRatingsGraphData()})
  }

  handleChangeRatingsCourse =(e) => {
      //console.log("dropdownValue",this.state.dropdownValue,"target value",e.target.value)
    e.preventDefault();
    const value = JSON.parse(e.target.value)
    // console.log("e.target.value",value._id);
    this.setState({courseObject:value},()=>{this.courseRatingsGraphData()})

  }

  fetchAllCoursesForCourseRatings=()=>{
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      // axios.get('/api/course/allCourses/user/'+userDetails.belongsTo)
      var url = '/api/course/allCourses/user/'+userDetails._id ;
      customHttp.get(url)
          .then(response => {
            if(response.data.message && Array.isArray(response.data.message)){
              var coursesArrayRatings = response.data.message.reverse();
              // console.log("fetchAllCoursesForCourseRatings",response.data.message);
              this.setState({coursesArrayRatings:coursesArrayRatings,courseObject:response.data.message[0],show_Analytics_Loader:false})
              this.courseRatingsGraphData()

            }else{
              this.setState({coursesArrayRatings:[],show_Analytics_Loader:false})
            }
          })
          .catch(error => {
            this.setState({coursesArrayRatings:[],show_Analytics_Loader:false})
            console.log("failed", error);
          });
    }

    courseRatingsGraphData=()=>{
      var demoCourseRatings={averageRating:"3.36",ratingsArray:[{ratings:"1",count:10,percentage:9.34},{ratings:"2",count:12,percentage:11.21},{ratings:"3",count:36,percentage:33.64},{ratings:"4",count:27,percentage:25.23},{ratings:"5",count:22,percentage:20.56}],totalCount:107,totalPercentage:"0"}
      var n = new Date(this.state.courseStartDate);
      var currentDate = n.getDate();
      if(currentDate<10){
        currentDate='0'+currentDate;
      }
      var currentMonth = n.getMonth() + 1; //Months are zero based
      if(currentMonth<10){
        currentMonth='0'+currentMonth;
      }
      var currentYear = n.getFullYear();
      var fromDate = currentYear + "-" + currentMonth + "-" + currentDate;  // date conversion dd/mm/yyyy to yyyy-mm-dd

      var p = new Date(this.state.courseEndDate);
      var tempDate = p.getDate();
      if(tempDate<10){
        tempDate='0'+tempDate;
      }
      var tempMonth = p.getMonth() + 1;
      if(tempMonth<10){
        tempMonth='0'+tempMonth;
      }
      var tempYear = p.getFullYear();
      var toDate = tempYear + "-" + tempMonth + "-" + tempDate;   // date conversion dd/mm/yyyy
      if(this.props.demoAndActualValue==="Demo"){
        this.setState({courseRatings:demoCourseRatings.ratingsArray,totalRating:demoCourseRatings.totalCount})
        var temp = this.state.graphDataPieCourse
        var data=[];
        for(var i=0;i<demoCourseRatings.ratingsArray.length;i++){
           data.push(demoCourseRatings.ratingsArray[i].count)
         }
         temp.datasets[0].data = data;
         temp.text = demoCourseRatings.averageRating;
         this.setState({graphDataPieCourse:temp,noDataFound:demoCourseRatings},()=> this.ratingsPercentage())
      }else{
      // console.log("courseRatings id data",this.state.courseObject._id,fromDate,toDate);
      // axios.get('/api/ratings/getRatingsByCourseIdFromDateAndToDate/'+this.state.courseObject._id+'/fromDate/'+fromDate+'/toDate/'+toDate)
      var url = '/api/ratings/getRatingsByCourseIdFromDateAndToDate/'+this.state.courseObject._id+'/fromDate/'+fromDate+'/toDate/'+toDate ;
      customHttp.get(url)
            .then(response => {
              console.log("courseRatings",response.data.message);
              this.setState({courseRatings:response.data.message.ratingsArray,totalRating:response.data.message.totalCount})

                if(response.data.message.ratingsArray.length>0){
                  if(response.data.message.ratingsArray[0].count===0 && response.data.message.ratingsArray[1].count===0 && response.data.message.ratingsArray[2].count===0 && response.data.message.ratingsArray[3].count===0 && response.data.message.ratingsArray[4].count===0){
                    this.setState({courseRatings:[]})
                  }else {
                    var temp = this.state.graphDataPieCourse
                    // console.log("courseRatings temp",temp);
                    var data=[];
                    for(var i=0;i<response.data.message.ratingsArray.length;i++){
                       // console.log("overAll usage",response.data.message._id,response.data.message.totalUsage)
                       data.push(response.data.message.ratingsArray[i].count)
                      
                     }
                     
                     temp.datasets[0].data = data;
                     temp.text = response.data.message.averageRating;
                     this.setState({graphDataPieCourse:temp,noDataFound:response.data.message.ratingsArray},()=> this.ratingsPercentage())
                  }

                }
            })
            .catch(error => {
              this.setState({courseRatings:[]})
                console.log("failed", error);
            });
          }
     }

  render(){
    return(
      <div>
      <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px',paddingTop: 20}}>
        <div className="col-md-12 col-sm-12 nj-card">

          <div className="col-md-12" style={(this.props.demoAndActualValue==="Actual" ? {paddingTop: 15,paddingBottom: 15,paddingLeft:"0px",display:"bolck"}:{display:"none"})}>
            <div className="col-md-3">
              <Fragment>
                <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>From Date:</label>
                <DatePicker className="datepicker-calender"
                    calendarClassName={"calender-design"}
                    format="dd/MM/yyyy"
                    clearIcon={null}
                    calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
                    showNeighboringMonth={false}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    value={this.state.courseStartDate}
                    onChange={this.handleChangeStartCourse}
                />
              </Fragment>
            </div>

            <div className="col-md-3">
              <Fragment>
                <label  className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>To Date:</label>
                <DatePicker className="datepicker-calender"
                    calendarClassName={"calender-design"}
                    format="dd/MM/yyyy"
                    clearIcon={null}
                    calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
                    showNeighboringMonth={false}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    value={this.state.courseEndDate}
                    onChange={this.handleChangeEndCourse}
                />
              </Fragment>
            </div>

              {this.state.coursesArrayRatings.length > 0 ?
                <div className="col-md-3">
                  <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Courses:</label>
                  <div className="nj-form-select">
                    <select className="vs-input--input" value={JSON.stringify(this.state.courseObject)} onChange={this.handleChangeRatingsCourse}>
                      {/*<option hidden='true' value=''>Pick a Course</option>*/}
                      {this.state.coursesArrayRatings.map((course,i) =>{
                        return <option key={i} value={JSON.stringify(course)}>{course.displayName}</option>
                      })}
                    </select>
                  </div>
                </div>
                :
                <div></div>
              }
              <div className="col-md-3"></div>
            </div>

            {/* for demo purpose */}
            <div className="col-md-12" style={(this.props.demoAndActualValue==="Demo" ? {paddingTop: 15,paddingBottom: 15,paddingLeft:"0px",display:"bolck"}:{display:"none"})}>
            <div className="col-md-3">
              <Fragment>
                <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>From Date:</label>
                <DatePicker className="datepicker-calender"
                    calendarClassName={"calender-design"}
                    format="dd/MM/yyyy"
                    clearIcon={null}
                    calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
                    showNeighboringMonth={false}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    value={this.state.courseStartDate}
                    onChange={this.handleChangeStartCourse}
                />
              </Fragment>
            </div>
            <div className="col-md-3">
              <Fragment>
                <label  className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>To Date:</label>
                <DatePicker className="datepicker-calender"
                    calendarClassName={"calender-design"}
                    format="dd/MM/yyyy"
                    clearIcon={null}
                    calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
                    showNeighboringMonth={false}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    value={this.state.courseEndDate}
                    onChange={this.handleChangeEndCourse}
                />
              </Fragment>
            </div>
              {this.state.demoCoursesArray.length > 0 ?
                <div className="col-md-3">
                  <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Courses:</label>
                  <div className="nj-form-select">
                    <select className="vs-input--input"  >
                      {/*<option hidden='true' value=''>Pick a Course</option>*/}
                      {this.state.demoCoursesArray.map((course,i) =>{
                        return <option key={i} value={JSON.stringify(course)}>{course.displayName}</option>
                      })}
                    </select>
                  </div>
                </div>
                :
                <div></div>
              }
              <div className="col-md-3"></div>
            </div>
            {/* end for demo purpose */}


          </div>
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px',paddingTop: 10}}>
          <div className="col-md-12 col-sm-12 nj-card">

            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4 className="font_family_montserrat">Course Ratings</h4>
                </div>
              </div>
            </div>

          { this.state.show_Analytics_Loader !== true ?
           <div>
                {this.state.courseRatings.length >0 ?
                  <div className="col-md-6" style={{paddingTop: 10}}>
                    <Doughnut data={this.state.graphDataPieCourse} options={this.state.options1} height={140} width={340} key={this.state.graphDataPieCourse}/>
                  
                  </div>
                  :
                  <div className="col-md-6">
                    <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                  </div>
                }


          <div className="col-md-6" style={{paddingBottom: 20,paddingTop: 10}}>
            <table>
              <tbody className="bar-progress-bar-wrapper">
                <tr>
                  <td style={{padding: 10,fontSize: 14}}>Excellent</td>
                  <td style={{width:'70%'}}>
                    <div className="progress" style={{height: 8,margin: 0}}>
                      <div className="progress-bar-1" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:this.state.courseRatings[4] && this.state.courseRatings[4].percentage+"%" || "0%",backgroundColor: "#8da1bc",height: 8,top: 5,borderRadius: 4}}>
                        <span className="sr-only">70% Complete</span>
                      </div>
                    </div>
                  </td>
                  <td>{this.state.courseRatings.length>0 && this.state.courseRatings[4].count || 0}</td>
                </tr>

                <tr>
                  <td style={{padding: 10,fontSize: 14}}>Good</td>
                  <td style={{width:'70%'}}>
                    <div className="progress" style={{height: 8,margin: 0}}>
                      <div className="progress-bar-2" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:this.state.courseRatings[3] && this.state.courseRatings[3].percentage+"%" || "0%",backgroundColor: "#6f5d7d",height: 8,top: 5,borderRadius: 4}}>
                        <span className="sr-only">70% Complete</span>
                      </div>
                    </div>
                  </td>
                  <td>{this.state.courseRatings.length>0 && this.state.courseRatings[3].count || 0}</td>
                </tr>

                <tr>
                  <td style={{padding: 10,fontSize: 14}}>Average</td>
                  <td style={{width:'70%'}}>
                    <div className="progress" style={{height: 8,margin: 0}}>
                      <div className="progress-bar-3" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:this.state.courseRatings[2] && this.state.courseRatings[2].percentage+"%" || "0%",backgroundColor: "#5e3d5f",height: 8,top: 5,borderRadius: 4}}>
                        <span className="sr-only">70% Complete</span>
                      </div>
                    </div>
                  </td>
                  <td>{this.state.courseRatings.length>0 && this.state.courseRatings[2].count || 0}</td>
                </tr>

                <tr>
                  <td style={{padding: 10,fontSize: 14}}>Below Average</td>
                  <td style={{width:'70%'}}>
                    <div className="progress" style={{height: 8,margin: 0}}>
                      <div className="progress-bar-4" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:this.state.courseRatings[1] && this.state.courseRatings[1].percentage+"%" || "0%",backgroundColor: "#945049",height: 8,top: 5,borderRadius: 4}}>
                        <span className="sr-only">70% Complete</span>
                      </div>
                    </div>
                  </td>
                  <td>{this.state.courseRatings.length>0 && this.state.courseRatings[1].count || 0}</td>
                </tr>

                <tr>
                  <td style={{padding: 10,fontSize: 14}}>Poor</td>
                  <td style={{width:'70%'}}>
                    <div className="progress" style={{height: 8,margin: 0}}>
                      <div className="progress-bar-5" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:this.state.courseRatings[0] && this.state.courseRatings[0].percentage+"%" || "0%",backgroundColor: "#c96734",height: 8,top: 5,borderRadius: 4}}>
                        <span className="sr-only">70% Complete</span>
                      </div>
                    </div>
                  </td>
                  <td>{this.state.courseRatings.length>0 && this.state.courseRatings[0].count || 0}</td>
                </tr>
              </tbody>
            </table>
            <p className="bar-progress-bar-wrapper" style={{padding: 10,fontSize: 14}}>Total number of ratings : {this.state.totalRating}</p>
          </div>
          </div>
              :
              <div className="row" style={{minHeight:"33.3vh"}}>
                <div className="col-md-12 " style={{top:"10vh",textAlign:"center"}}>
                  <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                </div>
              </div>
          }

        </div>
      </div>
    </div>
    );
  }
}

export default CourseRatings;
