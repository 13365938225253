
// import axios from "axios";
import * as EditPlaylistActions from "./index";
import snackbar from '../../../packages/Snackbar/';
import customHttp from '../../../packages/CustomHttp/';
import * as CreatePlaylistContainer from '../CreatePlaylist/action'

// export function getAllDisciplines(){
//    return async (dispatch, getState)=> {
//       try {
//       let response = await customHttp.get("/api/branch/");
//       let res = JSON.parse(JSON.stringify(response.data.message));
//       if(Array.isArray(response.data.message)){
//          dispatch(EditPlaylistActions.setDisciplines(res.reverse()));
//       } else {
//          dispatch(EditPlaylistActions.setDisciplines([]));
//       }
//       } catch (err) {
//          dispatch(EditPlaylistActions.setDisciplines([]));
//          console.log("error",err);
//       }
//    }
// }

// export function getAllContentTypes(){
//    return async (dispatch, getState)=> {
//       try {
//          let response = await customHttp.get("/api/category/");
//          let res = JSON.parse(JSON.stringify(response.data.message));
//          if(Array.isArray(response.data.message)){
//             dispatch(EditPlaylistActions.setContentTypes(res.reverse()));
//          } else {
//             dispatch(EditPlaylistActions.setContentTypes([]));
//          }
//       } catch (err) {
//          dispatch(EditPlaylistActions.setContentTypes([]));
//          console.log("error",err)
//       }
//    }
// }

function uniqueApps(array) {
   var processed = [];
   for (var i=array.length-1; i>=0; i--){
     if (processed.indexOf(array[i].name)<0) {
       processed.push(array[i].name);
     } else {
       array.splice(i, 1);
     }
   }
   return array;
 }


export function addResourcesToSubtitle(arr){
   return async (dispatch, getState)=> { 
     
      if(arr.length>0){
         var selectedPlaylistIndex = JSON.parse(JSON.stringify(getState().editPlaylistState.selectedPlaylistIndex));
         var selectedAddResourceIndex = JSON.parse(JSON.stringify(getState().editPlaylistState.selectedAddResourceIndex));
         var array = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
         var resources=array[selectedPlaylistIndex].subtitle[selectedAddResourceIndex].resources;
   
         for(var i=0;i<arr.length;i++){
            if(arr[i].type!==4){
               resources.push(arr[i])
            }         
         }
         var uniqueResources= uniqueApps(resources);
         array[selectedPlaylistIndex].subtitle[selectedAddResourceIndex].resources=uniqueResources;
         dispatch(setEditPlaylistElements('playlistArray',array))
         document.getElementById('resourcePopup').style.display='none'; 
      }else{
         const alertMsg = 'Please select a resource';
         dispatch(displayAlert('error',alertMsg))
      } 
   }
   
}

export function onLoadContent(){
   return async (dispatch, getState)=> {
      //dispatch(getAllDisciplines())
     
      dispatch(CreatePlaylistContainer.getAllDisciplines());
      var disciplines = getState().createPlaylistState.disciplines;
      dispatch(EditPlaylistActions.setDisciplines(disciplines.reverse()));

      dispatch(CreatePlaylistContainer.getAllContentTypes());
      var contentTypes = getState().createPlaylistState.contentTypes;
      dispatch(EditPlaylistActions.setDisciplines(contentTypes.reverse()));
      //dispatch(getAllContentTypes());

      dispatch(setEditPlaylistElements('resourcePlaylistObject',JSON.parse(localStorage.getItem("playlist"))))
      //dispatch(addTitle())
      if(JSON.parse(localStorage.getItem("playlist")) === undefined || JSON.parse(localStorage.getItem("playlist")) == null){
         dispatch(setEditPlaylistElements('playlistArray',[]))  
      }else{
         dispatch(setEditPlaylistElements('playlistArray',JSON.parse(localStorage.getItem("playlist")).playlists))  
      }
      // console.log("total state",getState())
   }
}

export function handleDropdownChange(e){
   return async (dispatch, getState)=> {
      var resourcePlaylistObject = JSON.parse(JSON.stringify(getState().editPlaylistState.resourcePlaylistObject));
      resourcePlaylistObject[e.target.name][0]=JSON.parse(e.target.value);
      dispatch(setEditPlaylistElements("resourcePlaylistObject",resourcePlaylistObject))
   }
}
function uniqueId() {
   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
       var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
       return v.toString(16);
   });
}
export function addTitle() {
   return (dispatch ,getState)=> {
      var tempArray = {id:uniqueId(),title:"",subtitle:[{id:uniqueId(),title:'',resources:[],quizquestions:[]/* grade:"",quizquestions:[{id:uniqueId(),question:'',options:[{id:uniqueId(),option:""}],correctAnswer:{},correctOption:""}] */}]};
      var arr=JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      arr.push(tempArray)
      dispatch(setEditPlaylistElements('playlistArray',arr));
       //to scroll to that title
       const eleid = "editPlaylistTitle"+(arr.length-2).toString();
       const ele = document.getElementById(eleid);
       if(ele){
          setTimeout(()=>{ ele.scrollIntoView({behavior: "smooth"});})
       }
       //end
   }
}
export function handleTitleChange(index,value) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      arr[index].title = value;
      dispatch(setEditPlaylistElements('playlistArray',arr))
   }
}

export function addSubtitle(index) {
   return (dispatch ,getState)=> {
      var subtitleTempArray = {id:uniqueId(),title:'',resources:[],quizquestions:[]/* grade:"", *//* quizquestions:[{id:uniqueId(),question:'',options:[{id:uniqueId(),option:""}],correctAnswer:{},correctOption:""}] */};

      var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      arr[index].subtitle.push(subtitleTempArray);
      dispatch(setEditPlaylistElements('playlistArray',arr))

       //to scroll to that title
       const eleid = "editPlaylistSubTitle"+index+(arr[index].subtitle.length-2).toString();
       const ele = document.getElementById(eleid);
       
       if(ele){
          setTimeout(()=>{ ele.scrollIntoView({behavior: "smooth"});})
       }
       //end
      
   }
}
export function addQuestion(subIndex,index) {
   return (dispatch ,getState)=> {
      var questionsTempArray = {id:uniqueId(),question:'',options:[{id:uniqueId(),option:""}],correctAnswer:{},correctOption:""};
      var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      arr[index].subtitle[subIndex].quizquestions.push(questionsTempArray);
      dispatch(setEditPlaylistElements('playlistArray',arr))
   }
}

// export function handleSubtitleChange(parentIndex,index,value) {
//    return (dispatch ,getState)=> {
//       var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
//       arr[parentIndex].subtitle[index].title = value;
//       dispatch(setEditPlaylistElements('playlistArray',arr))
//    }
// }
export function handleSubtitleChange(parentIndex,index,value) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      arr[parentIndex].subtitle[index].title = value;
      dispatch(setEditPlaylistElements('playlistArray',arr))
   }
}
// export function handleGradeChange(parentIndex,index,grade) {
//    return (dispatch ,getState)=> {
//       var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
//       arr[parentIndex].subtitle[index].grade = grade;
//       dispatch(setEditPlaylistElements('playlistArray',arr))
//    }
// }

export function handleQuestionChange(parentIndex,subIndex,index,e) {
   return (dispatch ,getState)=> {
   
      const dummyArray=["a","b","c","d","e","f","g","g","h","i","j","k","l","m","n"];
      var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      if(e.target.name==="question"){
      arr[parentIndex].subtitle[subIndex].quizquestions[index].question = e.target.value;}
      if(e.target.name==="grade"){
         arr[parentIndex].subtitle[subIndex].quizquestions[index].grade = e.target.value;}
      if(e.target.name==="selectedanswer"){
         var answer= JSON.parse(e.target.value);
         arr[parentIndex].subtitle[subIndex].quizquestions[index].correctAnswer = answer;
         arr[parentIndex].subtitle[subIndex].quizquestions[index].correctOption = dummyArray[answer.optionIndex];
        
      }
      
      dispatch(setEditPlaylistElements('playlistArray',arr))}
   }

export function addOption(titleIndex,subIndex,questIndex,optionIndex,value) {
   return (dispatch ,getState)=> {
      var optionsTempArray = {id:uniqueId(),option:""};
      var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      arr[titleIndex].subtitle[subIndex].quizquestions[questIndex].options.push(optionsTempArray);
      dispatch(setEditPlaylistElements('playlistArray',arr))
   }
}

export function handleOptionChange(titleIndex,subIndex,questIndex,optIndex,e) {
   return (dispatch ,getState)=> {
    
      var value=e.target.value;
      var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
     
      arr[titleIndex].subtitle[subIndex].quizquestions[questIndex].options[optIndex].option=value;
      arr[titleIndex].subtitle[subIndex].quizquestions[questIndex].options[optIndex].optionIndex=optIndex;
         dispatch(setEditPlaylistElements('playlistArray',arr))
         var arraylength=arr[titleIndex].subtitle[subIndex].quizquestions[questIndex].options.length;
         if(arr[titleIndex].subtitle[subIndex].quizquestions[questIndex].options[arraylength-1].option!=="")
         {
           dispatch(addOption(titleIndex,subIndex,questIndex,optIndex,value))}
         }
}

export function handleSubtitleDeleteIcon(parentIndex,subIndex,id) {
   return (dispatch ,getState)=> { 
      var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      arr[parentIndex].subtitle[subIndex].resources.forEach((item,i)=>{
         if(item._id===id){
            arr[parentIndex].subtitle[subIndex].resources.splice(i,1) 
         }      
      })
      dispatch(setEditPlaylistElements('playlistArray',arr))
      
   }
}

export function deleteTitle(index) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      if(arr.length>1){
         arr.splice(index,1);
         dispatch(setEditPlaylistElements('playlistArray',arr))
         dispatch(setEditPlaylistElements('successAlertMessage',"Title deleted successfully"));
         snackbar.successSnackbar();
      } else {
         const alertMsg = 'Atleast 1 title required';
         dispatch(displayAlert('error',alertMsg))
      }
   }
}

export function deleteSubtitle(parentIndex,index) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      if(arr[parentIndex].subtitle.length>1){
         arr[parentIndex].subtitle.splice(index,1);
         dispatch(setEditPlaylistElements('playlistArray',arr))
         dispatch(setEditPlaylistElements('successAlertMessage','Subtitle deleted successfully'));
         snackbar.successSnackbar();
      } else {
         const alertMsg = 'Atleast 1 subtitle required';
         dispatch(displayAlert('error',alertMsg))
      }
   }
}
export function deleteQuestion(parentIndex,subIndex,index) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      arr[parentIndex].subtitle[subIndex].quizquestions.splice(index,1);
      dispatch(setEditPlaylistElements('playlistArray',arr))
      // if(arr[parentIndex].subtitle[subIndex].quizquestions.length>1){
      //    arr[parentIndex].subtitle[subIndex].quizquestions.splice(index,1);
      //    dispatch(setEditPlaylistElements('playlistArray',arr))
      // } else {
      //    const alertMsg = 'Atleast 1 question required';
      //    dispatch(displayAlert('error',alertMsg))
      // }
   }
}
export function deleteOption(parentIndex,subIndex,index,optionIndex,optionId,e) {
   return (dispatch ,getState)=> {
      var arr = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      if(arr[parentIndex].subtitle[subIndex].quizquestions[index].options.length>1){
         // arr[parentIndex].subtitle[subIndex].quizquestions[index].options.splice(optionIndex,1);
         var dummy = arr[parentIndex].subtitle[subIndex].quizquestions[index].options.splice(optionIndex,1);
         if(dummy[0].option!==""){
            dispatch(setEditPlaylistElements('playlistArray',arr))
         }

      } else {
         const alertMsg = 'Atleast 1 option required';
         dispatch(displayAlert('error',alertMsg))
      }
   }
}
export function handleSubmit(e,history) {
   return (dispatch ,getState)=> {
      e.preventDefault();
      var emptyValue = false;
      var validationArray = JSON.parse(JSON.stringify(getState().editPlaylistState.playlistArray));
      for(var a=0;a<validationArray.length;a++){
         for(var b=0;b<validationArray[a].subtitle.length;b++){
            if(validationArray[a].subtitle[b].resources.length<=0){
               emptyValue = true;
               const alertMsg = 'Please add contents';
               dispatch(displayAlert('error',alertMsg))
            }
         } if(validationArray.length-1===a && !emptyValue){
            updatePlaylist(validationArray,dispatch,getState,history)
         }
      }
      // console.log("emptyValue",validationArray)
   }
}

export function setEditPlaylistElements(name,content) {
   return (dispatch ,getState)=> {
      dispatch(EditPlaylistActions.setEditPlaylistElements(name,content))
   }
}

export function displayAlert(type,msg) {
   return async (dispatch ,getState)=> {
      if(type==='success'){
         dispatch(setEditPlaylistElements('successAlertMessage',msg));
         snackbar.successSnackbar();
       } else if(type==='error'){
         dispatch(setEditPlaylistElements('errorAlertMessage',msg));
         snackbar.errorSnackbar(); 
      }
   }
}

export function handleReturn(history) {
   return (dispatch ,getState)=> {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      if(userDetails.type===1){
         history.push('/resources');
      } else if(userDetails.type===2){
         history.push('/dlt/resources');
      } else{
         history.push('/sub-admin/resources');
      }
   }
}

const updatePlaylist = async (arr,dispatch,getState,history) => {
   var totalTimeInvestment=0;
   for (var i=0 ; i<=arr.length-1; i++){
      for(var j=0 ; j<=arr[i].subtitle.length-1; j++){
         for(var k=0 ;k<=arr[i].subtitle[j].resources.length-1 ; k++){
            for(var l=0; l<=arr[i].subtitle[j].resources[k].file.length-1;l++){
              
               totalTimeInvestment=totalTimeInvestment + parseInt(arr[i].subtitle[j].resources[k].file[l].timeInvestment);
               
            }
         }
        
      }
   }
   var data = {};
   data.branches = [getState().editPlaylistState.resourcePlaylistObject.branches[0]._id];
   data.categories = [getState().editPlaylistState.resourcePlaylistObject.categories[0]._id];
   data.displayName = getState().editPlaylistState.resourcePlaylistObject.displayName;
  
   data.timeInvestment=totalTimeInvestment;
   data.playlists = arr;
   data._id=getState().editPlaylistState.resourcePlaylistObject._id;
   dispatch(setEditPlaylistElements('showSubmitLoader',true))
   try {
      let response = await customHttp.put("/api/resourceuri/createPlaylists", data);
      // let res = JSON.parse(JSON.stringify(response.data.message));
      dispatch(displayAlert('success','Playlist updated successfully!'));
      dispatch(setEditPlaylistElements('showSubmitLoader',false))
      dispatch(handleReturn(history))
   } catch (err) {
      dispatch(displayAlert('error','Error try again!'));
      dispatch(setEditPlaylistElements('showSubmitLoader',false))
      console.log("error",err);
   }
}