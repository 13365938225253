import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import snackbar from  '../../packages/Snackbar/';
import customHttp from '../../packages/CustomHttp';

  function SMEUsers (props) {
  const [smeEmail,setSmeEmail]=useState("");
  const [getUserDetails,setGetUserDetails] = useState([]);
  const [emailExist,setEmailExist]=useState(false);
  const[userData , setUserData] = useState({});
  const[showLoader , setShowLoader] = useState(false);
  const[submitLoader,setSubmitLoader]=useState(false);
  const[confirmLoader,setConfirmLoader]=useState(false);
  const[successSnackbarMessage,setSuccessSnackbarMessage]=useState("");
  const[errorSnackbarMessage,setErrorSnackbarMessage]=useState("");
  const[errorEmail,setErrorEmail]=useState(false);
  const[valueCheck,setValueCheck]=useState();
  

  useEffect(() => {
    loadUserDetails();
  },[])
  
  var loadUserDetails=()=>{
    var adminId=JSON.parse(sessionStorage.getItem("userData"))
    // console.log("hi",adminId._id)
    // axios.get('/api/smeUser/belongsTo/'+adminId.belongsTo)
    var url =  '/api/smeUser/belongsTo/'+adminId.belongsTo ;
    customHttp.get(url)
    .then(response => {
      setGetUserDetails(response.data.message);
      setShowLoader(true);
    }).catch((error)=>{
      setGetUserDetails([]);
    });
  }


  var handleChangeCreateUser =(event)=>{  
    setSmeEmail(event.target.value)  
    setErrorEmail(false); 
    setValueCheck(event.target.value);
    // axios.get("/api/smeUser/validate/email/"+ event.target.value.toLowerCase())
    var url = "/api/smeUser/validate/email/"+ event.target.value.toLowerCase();
    customHttp.get(url)
      .then(response => {
        if(response.data.message.exists !== undefined){
          setEmailExist(response.data.message.exists);
        }else{
          setEmailExist(false);
        }
       
      }).catch(error => {      
        console.log("failed", error);
        setEmailExist(false);
      });    
        
  }

  var formSubmit =(event)=>{
    event.preventDefault();  
    var adminId=JSON.parse(sessionStorage.getItem("userData"))
    var userDetails={};
    userDetails.email = smeEmail;
    userDetails.belongsTo = adminId.belongsTo;
    userDetails.adminId = adminId._id; 
    userDetails.isEnabled = true;    
    //check email validation using regex it shows invalid error
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(valueCheck)=== false) 
    {    
      setErrorEmail(true); 
    }else{
      setSubmitLoader(true);
      //post data to db
      // axios.post('/api/smeUser/',userDetails)
      var url = '/api/smeUser/' ;
      customHttp.post(url,userDetails)
        .then(res => {
          setSubmitLoader(false);
          clearUserInputFields();
          loadUserDetails();  
          snackbar.successSnackbar();
          setSuccessSnackbarMessage("User Created Successfully!");    
        }).catch((error)=>{
          snackbar.errorSnackbar();
          setErrorSnackbarMessage("Error try again."); 
      });
    }
  }

  //onclick delete button popup will appear 
  var getConfirmDeletePopup= async(data)=>{
    // console.log("delete data",data);
    setUserData(data);
    document.getElementById("deleteUserPopup").style.display="block";
  }

  //onclick confirm button table row will delete
  var deleteRequestedContent=(e,data)=>{
    e.preventDefault();
    setConfirmLoader(true);
    // axios.delete('/api/smeUser/',{data:data})
    var url = '/api/smeUser/';
    customHttp.delete(url,data)
    .then(response=>{     
      setConfirmLoader(false);
      document.getElementById("deleteUserPopup").style.display='none';
      loadUserDetails();
      snackbar.successSnackbar();
      setSuccessSnackbarMessage("User Deleted Successfully!");     
    })
    .catch(error => {
      setConfirmLoader(false);
      snackbar.errorSnackbar();
      setErrorSnackbarMessage("Error try again.");   
    });
    //hide the popup box after confirm button clicked
    
  }
  
  var clearUserInputFields=()=>{
    setSmeEmail("");
  } 
  
  var hideDeletePopUp=(e)=>{    
    e.preventDefault();
    setUserData("");
    setConfirmLoader(false);
    document.getElementById("deleteUserPopup").style.display="none";
  }

  
  return (
      <div className="row">
        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12"> 
          <div className="col-md-12 col-sm-12 nj-card">
            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4 className="font_family_montserrat">Created SME's</h4>
                </div>  
              </div>
            </div>
            <div className="col-md-12" style={{paddingLeft:'0px',paddingRight:'0px'}}> 
            {showLoader ?                 
              <div className="course-table-margin-top">
                {getUserDetails.length>0?  
                <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"69vh"}}>
                  <table className="nj-table nj-table--tbody-table font_family_montserrat">
                    <thead className="nj-table--thead">
                      <tr>
                        <th>
                          <div className="nj-table-text">SL NO.</div>
                        </th>
                        <th>
                          <div className="nj-table-text">Email</div>
                        </th>                                                    
                        <th style={{textAlign:"center"}}>
                          <div className="nj-table-text">ACTIONS</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="dl-team_tbody">    
                        {getUserDetails.map((data,i) => {
                          return(                            
                        <tr key={i} className="tr-values nj-table--tr tr-table-state-null ">
                        <td className="td nj-table--td">{i+1}</td>
                        <td className="td nj-table--td">{data.email}</td>                                                                                         
                        <td className="td dl-team--td">
                          <button  type="button"  onClick={()=>getConfirmDeletePopup(data)} className="nj-flow-button bg-color-red addRoles_button"  style={{float:"none",marginTop:"5px",marginLeft:"5px"}}>Delete</button>
                        </td>
                          </tr>
                          )
                        })}                        
                    </tbody>
                  </table>
                </div>   
                :
                <div className="col-md-12 idams_noDataFound" style={{height:"76.3vh"}}>
                  <span className="font_family_montserrat">No data found</span>
                </div>                                        
              }                                                             
              </div> :
                 <div className="row" style={{height:"76.3vh"}}>
                 <div className="col-md-12 aligh-loader-image-center" >
                   <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                 </div>
               </div>
               }                                                
            </div>
          </div>          
        </div>

        {/* onclick delete button in table this popup will appear*/}
        <div id="deleteUserPopup" className={"nj-overly add-rebound-animation"}>
          <div className="popup-nj my-popup">
            <header className="popup-header">
              <span>Delete User</span>
              <span onClick={hideDeletePopUp} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
            </header>
            <div className="popup-content">
              <div className="col-md-12">
                <form name="roleForm">
                  <div className="nj-popup-form-wrap">
                    <p className="popup-content-label font_family_montserrat" style={{fontSize:"16px"}}>Do you really want to delete?</p>
                  </div>
                  {confirmLoader ? 
                  <div className={"pull-right"}> 
                  <img src={"/img/loaders/spinningwheel.gif"} style={{marginTop:"0px"}} height={50} width={50} alt="loader" /></div> :<button type="submit" onClick={(e)=>deleteRequestedContent(e,userData)} className="nj-flow-button pull-right" style={{margin:5}}> Confirm</button>}                               
                  <div><button type="button" onClick={hideDeletePopUp}  disabled={confirmLoader} className="nj-flow-button bg-color-red pull-right"  style={{margin:5}}>Cancel</button></div>
                </form>
              </div>
            </div>
          </div>
        </div>  

        {/* creating a new user form start*/}                
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <div id="successSnackbar">{successSnackbarMessage}</div> 
          <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{errorSnackbarMessage}</div> 
          <div className="col-md-12 col-sm-12 nj-card">
            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4 className="font_family_montserrat">Create SME User</h4>
                </div>  
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  dl-team_form" style={{marginTop:'10px'}}>             
                <form onSubmit={formSubmit}>
                  <div className="row dl-team_form_row" style={{marginTop:'0px'}}>
                      <div className="col-lg-9 col-md-8 col-sm-8 col-xs-8">
                          <label className="idams-label pull-left">Email</label>
                          <input type="email" name="email" value={smeEmail} onChange={handleChangeCreateUser}  className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="ex : john@email.com" autoComplete="off" required /> 
                          {errorEmail && <p style={{marginTop:'9px',color:'red'}}>Invalid email </p>}  
                          {emailExist && <p style={{marginTop:'9px',color:'red',marginBottom:'0px'}}>Email is already taken!</p>}                                                        
                      </div>
                      <div className="col-lg-3 col-md-2 col-sm-4 col-xs-4">
                      {submitLoader ?<div style={{marginTop:'10px',height:'30px',width:"30px"}}><img src={"/img/loaders/spinningwheel.gif"} height={65} width={65} alt="loader" /></div>: <button  type="submit" className="nj-flow-button pull-right" style={{marginTop:'25px'}} disabled={emailExist || errorEmail}>Submit</button>}
                      </div> 
                  </div>                                                               
                </form>                
              </div>
            </div>
          </div>
        </div>
        {/* creating a new user form ends*/}                
      <div>                                    
    </div>     
  </div>

  );
}

export default SMEUsers;