import React, { useEffect} from 'react';
import { connect } from "react-redux";
import * as UserDashboardContainer from "../../../container/actions/UserDashboard/action";
import ReactApexChart from 'react-apexcharts';
// import Store from "../../container/reducers/Store";
import "./BookedVsUsedSlots.scss";
import { bindActionCreators } from 'redux';


function UserBookedVsUsedSlots (props) {
  
  useEffect( () => {

},[]);
  // onload function end
    return (
    <div className="add-on-load-animation">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
            <div className="col-md-12 col-sm-12 nj-card" >
              {!props.userDashboardState.initialLoaderForBookedAndUsedSlots ?
                <div>       
                  <div className="row" style={{textAlign:"left"}}>
                    <div className="nj-card__header">
                      <div className="nj-card__title">
                        <h4 className="font_family_montserrat" style={{fontWeight:500}}>Course Usage</h4>
                      </div>                 
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12" style={{marginTop:'10px',marginBottom:'10px'}}>
                      <div className="col-md-3 col-sm-12 col-xs-12 pull-right">
                        <p className="popup-content-label font_family_montserrat" style={{fontSize: 14,marginLeft:'0px'}}>Select Course</p>
                        <div className="nj-form-select">
                            <select style={{textTransform: 'capitalize'}} autoComplete="off" value={JSON.stringify(props.userDashboardState.selectedCourse)} onChange={props.handleCourseUsageCourseChange}>
                            {props.userDashboardState.courses.map((data,i)=>{
                                return (<option key={i} value={JSON.stringify(data)}>{data.name}</option>)
                              })}
                            </select>
                            </div>
                        </div>
                    </div>
                  </div>
                  {Object.keys(props.userDashboardState.bookedAndUsedSlots).length>0 ?
                    <div>
                      {!props.userDashboardState.graphLoaderForBookedAndUsedSlots ?
                      <div>
                        <div className="row" style={{textAlign:"left"}}>
                          <div className="col-md-12 col-sm-12 slot-heading-div">
                            <div className="col-md-2 col-sm-6 col-xs-12">
                              <span className="slot-heading-div_span">
                                <p className="font_family_montserrat" style={{marginBottom:0}}>Total Opened Slots</p>
                                <span className="font_family_montserrat slot-value" style={{color:'#4fc0e1',fontSize:'22px'}}>{props.userDashboardState.bookedAndUsedSlots.openSlots}</span>
                              </span> 
                            </div>
                            <div className="col-md-2 col-sm-6 col-xs-12">
                              <span className="slot-heading-div_span">
                                <p className="font_family_montserrat" style={{marginBottom:0}}>Total Booked Slots</p>
                                <span className="font_family_montserrat slot-value" style={{color:'#008FFB',fontSize:'22px'}}>{props.userDashboardState.bookedAndUsedSlots.bookedSlots}</span>
                              </span>  
                            </div>
                            <div className="col-md-2 col-sm-6 col-xs-12">
                              <span className="slot-heading-div_span">
                                <p className="font_family_montserrat" style={{marginBottom:0}}>Total Used Slots</p>
                                <span className="font_family_montserrat slot-value" style={{color:'#00E396',fontSize:'22px'}}>{props.userDashboardState.bookedAndUsedSlots.usedSlots}</span>
                              </span> 
                            </div>
                            <div className="col-md-2 col-sm-6 col-xs-12">
                              <span className="slot-heading-div_span">
                                <p className="font_family_montserrat" style={{marginBottom:0}}>Course Completion</p>
                                <span className="font_family_montserrat slot-value" style={{color:'rgb(244, 158, 65)',fontSize:'22px'}}>{props.userDashboardState.bookedAndUsedSlots.percentage}%</span>
                              </span>  
                            </div>                                      
                          </div>
                        </div>
                        <div style={{margin:"0px 10px"}}>
                          <div id="chart">
                            <div id="chart-timeline" className="icon-zindex">
                              <ReactApexChart options={props.userDashboardState.bookedAndUsedSlotOptions} series={props.userDashboardState.bookedAndUsedSlotSeries}  type='area' height={360} />
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="row" style={{height:"50vh"}}>
                        <div className="col-md-12 aligh-bookedslot-loader-center" >
                          <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                        </div>
                      </div>
                    }
                    </div>
                  :
                    <div className="col-md-12 font_family_montserrat idams_noDataFound" style={{height:"50vh"}}>No data found</div>
                  }
                </div> 
              :
                <div className="row" style={{height:"68vh"}}>
                  <div className="col-md-12 aligh-bookedslot-loader-image-center" >
                    <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                  </div>
                </div>
              }            
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }


const mapStateToProps = state => ({
  userDashboardState: state.userDashboardState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  handleCourseUsageCourseChange:UserDashboardContainer.handleCourseUsageCourseChange
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(UserBookedVsUsedSlots);
