import * as accessLabActions from "./index";
import customHttpUserside from '../../../packages/CustomHttpUserside/';
// import axios from 'axios';
import snackbar from '../../../packages/Snackbar/';

export function fetchUserDetails(){
  return async (dispatch)=> {
    var userDetails = JSON.parse(sessionStorage.getItem("userData"))
    dispatch(setUserProfileElements('userProfileLoader',true));
   //  console.log("userDetails",JSON.parse(sessionStorage.getItem("userData")));
    try {
       const response = await customHttpUserside.get('/api/user/getUserDetailsUserSide/email/'+userDetails.email);
       dispatch(fetchDomainName(response.data.message));
     
       sessionStorage.setItem("userData",JSON.stringify(response.data.message))
       dispatch(setUserProfileElements('userProfileLoader',false));
       // var userDetails1 = JSON.parse(sessionStorage.getItem("userData"))
       // console.log("msg",userDetails1);
     } catch(err){
        console.log("error",err)
        dispatch(setUserProfileElements('userProfileLoader',false));
     }
  }
}

export function fetchDomainName(user){
  return async (dispatch)=> {
   //  console.log("user",user);
    var userDetails = JSON.parse(sessionStorage.getItem("userData"))
    try {
       const response = await customHttpUserside.get(`/api/confidentialDetails/user/${userDetails.belongsTo}`);
       var domainName = response.data.message[0].organizationDomainName
       if(domainName === undefined || domainName === ""){
         var domain = "platifi.com"
         dispatch(getUserProfileInfo(domain,user));
       }else {
         var domain = response.data.message[0].organizationDomainName
         dispatch(getUserProfileInfo(domain,user));
       }

     } catch(err){
        console.log("error",err)
     }
  }
}

export function getUserProfileInfo(domain,user) {
   return async (dispatch)=> {
     dispatch(setUserProfileElements('userProfileLoader',true));
      try {
         const userData = JSON.parse(sessionStorage.getItem('userData'));
         // console.log("userData",user.username,user.phoneNumber,domain,user);
         const response = await customHttpUserside.get(`/api/userProfile/getProfileDetails/${userData._id}`);
         dispatch(setUserProfileElements('userProfileLoader',false));
         if(response.data.message && response.data.message.firstname && response.data.message.updatedImage.length<=0){
            sessionStorage.setItem("userProfileData",true);
            dispatch(setUserProfileElements('isProfilePictureAdded',false));
            dispatch(setUserProfileElements('isEditable',false));
            if(user.username === null || user.username === undefined || user.username === "" ){
              dispatch(setUserProfileElements('profileUsername',false));
            }else {
              dispatch(setUserProfileElements('profileData',{username:response.data.message.username || user.username,phoneNumber:response.data.message.phoneNumber || user.phoneNumber,domainName:domain,firstname:response.data.message.firstname,lastname:response.data.message.lastname,location:response.data.message.location,postalCode:response.data.message.postalCode}));
            }
         } else if(response.data.message && !response.data.message.firstname && response.data.message.updatedImage.length>0){
            sessionStorage.setItem("userProfileData",false);
            dispatch(setUserProfileElements('isProfilePictureAdded',true));
            dispatch(setUserProfileElements('profilePicture',response.data.message.updatedImage[0]));
            dispatch(setUserProfileElements('isEditable',true));
            if(user.username === null || user.username === undefined || user.username === ""){
              dispatch(setUserProfileElements('profileUsername',false));
            }else{
               dispatch(setUserProfileElements('profileData',{username:user.username,phoneNumber:user.phoneNumber,domainName:domain,firstname:"",lastname:"",location:"",postalCode:""}));
            }
         } else if(response.data.message && response.data.message.firstname && response.data.message.updatedImage.length>0) {
            sessionStorage.setItem("userProfileData",true);
            dispatch(setUserProfileElements('isProfilePictureAdded',true));
            dispatch(setUserProfileElements('isEditable',false));
            if(user.username === null || user.username === undefined || user.username === ""){
              dispatch(setUserProfileElements('profileUsername',false));
            }else {
              dispatch(setUserProfileElements('profileData',{username:response.data.message.username,phoneNumber:response.data.message.phoneNumber,domainName:domain,firstname:response.data.message.firstname,lastname:response.data.message.lastname,location:response.data.message.location,postalCode:response.data.message.postalCode}));
            }
            dispatch(setUserProfileElements('profilePicture',response.data.message.updatedImage[0]));
         }else {
           sessionStorage.setItem("userProfileData",false);
           dispatch(setUserProfileElements('isProfilePictureAdded',false));
           dispatch(setUserProfileElements('isEditable',true));
           if(user.username === null || user.username === undefined || user.username === ""){
             dispatch(setUserProfileElements('profileUsername',false));
             dispatch(setUserProfileElements('profileData',{username:'',phoneNumber:'',domainName:domain,firstname:'',lastname:'',location:'',postalCode:''}));
           }else {
             dispatch(setUserProfileElements('profileData',{username:user.username,phoneNumber:user.phoneNumber,domainName:domain,firstname:'',lastname:'',location:'',postalCode:''}));
           }
         }
      } catch(err){
        dispatch(setUserProfileElements('userProfileLoader',false));
         console.log("error",err)
      }
   }
}

export function uploadImage(image,clear) {
   return async (dispatch)=> {
      if(image && image.length>0){
         const userData = JSON.parse(sessionStorage.getItem('userData'));
         // console.log("image",image)
         var data = new FormData();
         const configuration = { headers: { "Content-Type": "multipart/form-data" } };
         data.append('userId',userData._id)
         data.append('file',image[0])
         // console.log("image to upload",image);
         dispatch(setUserProfileElements('imageLoader',true));
         try {
            dispatch(setUserProfileElements('imageUploadLoader',true));
            const response = await customHttpUserside.put(`/api/userProfile/uploadProfileImage`,data,configuration);
            // console.log("profile image upload",response.data.message);
            clear.image();
            dispatch(displayAlert('success','Profile picture updated successfully'));
            document.getElementById('profileImagePopup').style.display='none';
            dispatch(setUserProfileElements('imageLoader',false));
            dispatch(fetchUserDetails());
         } catch(err){
            console.log("error",err.response);
            dispatch(setUserProfileElements('imageLoader',false));
            if(err.response && err.response.data && err.response.data.message==='File is too large'){
               dispatch(displayAlert('error','Max size 2.5MB exceeded!'));
            } else {
               dispatch(displayAlert('error','Error! try again..'))
            }
         }
      } else {
         dispatch(displayAlert('error','Please upload an image'))
      }
   }
}

export function handleChange(e) {
   return (dispatch,getState)=> {
      // dispatch(checkUserNameExist(e.target.value));
      if(e.target.name==="username"){
         if (/^[A-Za-z0-9]*$/.test(e.target.value)){
            dispatch(accessLabActions.setBooleanValue("usernameMustbeAlphnumeric",false))
         }else{
            dispatch(accessLabActions.setBooleanValue("usernameMustbeAlphnumeric",true))
         }
         dispatch(checkUserNameExist(e.target.value));
       }

      dispatch(setUserProfileElements('showErrorMessage',false))
      let profileData = JSON.parse(JSON.stringify(getState().userProfileState.profileData));
      const name = e.target.name;
      const value = e.target.value;
      const updatedProfileData = {...profileData,[name]:value};
      dispatch(setUserProfileElements('profileData',updatedProfileData));
   }
}

export function enableEditProfile(e) {
   return (dispatch,getState)=> {
      e.preventDefault()
      dispatch(setUserProfileElements('isEditable',true));
   }
}

export function handleSubmit(e) {
   return async (dispatch,getState)=> {
      e.preventDefault();
      let profileData = JSON.parse(JSON.stringify(getState().userProfileState.profileData));
      if(profileData.username.match("^[a-z0-9 ._]{1,20}$")){
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        var data = profileData;
        data.userId = userData._id;
        data.email = userData.email;
      //   console.log("data",data,"\n",profileData);
        dispatch(setUserProfileElements('submitLoader',true));
        try {
           const response = await customHttpUserside.put('/api/userProfile/create',data);
           sessionStorage.setItem("userProfileData",true);
         //   console.log("profile info update",response.data.message);
           dispatch(displayAlert('success','Profile info updated successfully'));
           document.getElementById('profileImagePopup').style.display='none';
           dispatch(fetchUserDetails());
           // dispatch(getUserProfileInfo());
           dispatch(setUserProfileElements('isEditable',false));
           dispatch(setUserProfileElements('submitLoader',false));
           dispatch(setUserProfileElements('showErrorMessage',false))
           dispatch(setUserProfileElements('profileUsername',true));
        } catch(err){
           console.log("error",err,err.response);
           if("username "+data.username+" already exists" === err.response.data.message){
             dispatch(setUserProfileElements('submitLoader',false));
             dispatch(displayAlert('error','User name already exists.'))
           }else {
             dispatch(setUserProfileElements('submitLoader',false));
             dispatch(displayAlert('error','Error! try again..'))
           }
        }
      }else {
        dispatch(setUserProfileElements('showErrorMessage',true))
      }
   }
}

export function deleteImage() {
   return async (dispatch,getState)=> {
      let profileData = JSON.parse(JSON.stringify(getState().userProfileState.profileData));
      const userData = JSON.parse(sessionStorage.getItem('userData'));
      var data = profileData;
      data.userId = userData._id;
      dispatch(setUserProfileElements('imageLoader',true));
      try {
         const response = await customHttpUserside.delete('/api/userProfile/image',data);
         // console.log("profile info update",response.data.message);
         dispatch(displayAlert('success','Profile image deleted successfully'));
         document.getElementById('deleteImagePopup').style.display='none';
         dispatch(fetchUserDetails())
         dispatch(setUserProfileElements('imageLoader',false));
      } catch(err){
         console.log("error",err);
         dispatch(setUserProfileElements('imageLoader',false));
         dispatch(displayAlert('error','Error! try again..'))
      }
   }
}

export function displayAlert(type,msg) {
   return async (dispatch)=> {
      if(type==='success'){
         dispatch(setUserProfileElements('successAlertMessage',msg));
         snackbar.successSnackbar();
       } else if(type==='error'){
         dispatch(setUserProfileElements('errorAlertMessage',msg));
         snackbar.errorSnackbar();
      }
   }
}

export function setUserProfileElements(name,content) {
   return (dispatch)=> {
      dispatch(accessLabActions.setUserProfileElements(name,content))
   }
}

export function checkUserNameExist (obj){
   return async(dispatch ,getState)=> {
      try {
         if(obj!==undefined){
            let response = await customHttpUserside.get('/api/user/validateUsernameUserside/username/'+obj);
            if(response.data.message.usernameExists === true){
            dispatch(accessLabActions.setBooleanValue("userNameExist",true))
            }
            else{
               dispatch(accessLabActions.setBooleanValue("userNameExist",false))
            }
         }
       } catch (err) {
         dispatch(accessLabActions.setBooleanValue("userNameExist",false))
         console.log("error",err)
       }
   }
}
