import * as gargiKnowledgeBaseTypes from "../../../actionTypes";
const initialState = {
  initialLoader:true,
  addGargiQuestionAndAnswer:[],
  changedQnaArray:[],
  listOfQnaArray:[],
  showAddNewQna:false,
  showListOfQna:false,
  successSnackbarMessage:'',
  errorSnackbarMessage:'',
  deleteConfirmLoader:false,
  selectedQnaForDelete:{},
  kbFileUploadConfirmLoader:false,
  kbFileObj:{},
  originalListOfQnaArray:[],
  kbSearchValueEnable:false,
  countPerPage:10,
  currentPage:0,
  viewArray:[10,50,100,200,300,500,1000],
  gargiDomain:'https://gargidemo.platifi.com',
  saveAndTrainLoader:false,
  showUnansweredQuestion:false,
  unansweredQuestion:{},
  unansweredQuestionArray:[],
  showNodataFound:false,
  editUnansweredPopLoader:false,
  deleteUnansweredQuestionObj:{},
  deleteUnansweredQuestionConfirmLoader:false,
}

export default function(state=initialState, action) { 
  switch (action.type) {
    case gargiKnowledgeBaseTypes.GARGI_KNOWLEDGE_BASE_COMPONENT_ELEMENTS: {
      const {name,content } = action.payload; 
      return {
        ...state,[name]:content
      }
    }
    default:
      return state;
  }
}