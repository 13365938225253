import * as userCoursesActionTypes from "../../../actionTypes";


export const getAllEnabledCourses = (content) => ({
    type: userCoursesActionTypes.USER_COURSES_GET_ALLENABLEDCOURSES,
    payload: {
      content
    }
});

export const getAllRequestedCourses = (content) => ({
    type: userCoursesActionTypes.USER_COURSES_GET_ALLREQUESTEDCOURSES,
    payload: {
      content
    }
});

export const showResourcesList = (booleanValue) => ({
    type: userCoursesActionTypes.USER_SHOW_RESOURCES_LIST,
    payload: {
        booleanValue
    }
});

export const setDynamicValues = (name,value) => ({
    type: userCoursesActionTypes.SET_DYNAMIC_VALUES,
    payload: {
        name,value
    }
});

export const setEditCourseRatings = (name,value) => ({
    type: userCoursesActionTypes.SET_DYNAMIC_VALUES_FOR_COURSERATINGS,
    payload: {
        name,value
    }
});
export const setEditResourceRatings = (name,value) => ({
    type: userCoursesActionTypes.SET_DYNAMIC_VALUES_FOR_RESOURCERATINGS,
    payload: {
        name,value
    }
});

export const showRequested = (booleanValue) => ({
    type: userCoursesActionTypes.USER_SHOW_RESOURCES_LIST,
    payload: {
        booleanValue
    }
});
