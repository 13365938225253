import React, { useState, useEffect, Fragment } from 'react';
import { connect } from "react-redux";
import {handleBookSlotBack} from "../../container/actions/AdminSlotBooking/action";
import { bindActionCreators } from 'redux';
import DatePicker from '../userCourses/BookSlot/DatePicker';
import customHttp from '../../packages/CustomHttp';
import {format} from "date-fns";
import snackbar from '../../packages/Snackbar';
import './AdminSlotBooking.scss';
var selectedSlotsArr = [];
var currentDate = {};
function SlotBooking (props) {
  const [setDay,setSetDay] = useState({toggle:function(){}});
  const [blockDaysBefore,setBlockDaysBefore] = useState({});
  const [blockDaysAfter,setBlockDaysAfter] = useState({});
  const [slots,setSlots] = useState([]);
  const [valdatedSlots,setValdatedSlots] = useState([]);
  const [slotsAllowed,setSlotsAllowed] = useState(0);
  const [selectedDays,setSelectedDays] = useState([]);
  const [totalBookedSlots,setTotalBookedSlots] = useState(0);
  const [alertMessage,setAlertMessage] = useState('')
  const [bookSlotLoader,setBookSlotLoader] = useState(false);
  const [slotsLoader,setSlotsLoader] = useState(false);
  const [randomNumber,setRandomNumber] = useState(0);
  const [currentMonth,setCurrentMonth] = useState(new Date())
  const [bookSlotError,setBookSlotError] = useState([]);
  const [showUserList, setShowUserList] = useState(false);
  const [unAvailableUserList,setUnAvailableUserList] = useState([])

  useEffect(() => {
    blockDate();
    //getSubscriptionLimit();
    var userList = JSON.parse(sessionStorage.getItem('adminSlotBookingUsers'));
    if(userList.length<=0){
      alert('You have not selected any user to book slot.',props.handleBookSlotBack(props.history))
    }
  },[]);
  useEffect(() => { 
    console.log("slots",slots)
  },[slots]);

  useEffect(() => { 
    setRandomNumber(Math.random());
  },[blockDaysBefore,blockDaysAfter,selectedDays,currentMonth]);

  const getSlotTimingsFromDb=async(date)=>{
    try {
      var course=JSON.parse(sessionStorage.getItem('adminSlotBookingCourse'));
      var slotHours;
      if(course.slotHours === null){
        slotHours='2';
      } else{
        slotHours=course.slotHours;
      }
      const dateObj = format(new Date(date), "yyyy-MM-dd")
      var response = await customHttp.get(`/api/slot/faculty/slotBookingTime/date/${dateObj}/courseId/${course._id}`);
      const temp = JSON.parse(JSON.stringify(response.data.message));
      let slotTimes = []
      for(var i=0;i<temp.length;i++){
        slotTimes.push({_id:i,time:temp[i].time,color:temp[i].color,disabled:temp[i].block,selected:false})
      }
      return slotTimes;
    } catch(err){
      console.log("error",err)
      return [];
    }
  }

  const validateUsers=async(date,slotTimes)=>{
    try {
      var user = JSON.parse(sessionStorage.getItem('userData'));
      var course=JSON.parse(sessionStorage.getItem('adminSlotBookingCourse'));
      var userList = JSON.parse(sessionStorage.getItem('adminSlotBookingUsers'));
      const timesArr = []
      for(var i=0;i<slotTimes.length>0;i++){
        timesArr.push(slotTimes[i].time)
      }
      let obj = {}
      obj.date = format(new Date(date), "yyyy-MM-dd");
      obj.bookingTime = timesArr;
      obj.courseId = course._id;
      obj.belongsTo = user.belongsTo;
      obj.users = userList;
      var response = await customHttp.post('/api/slot/faculty/validateBookSlots',obj);
      if(Array.isArray(response.data.message) && response.data.message.length>0){
        return response.data.message || [];
      } else {
        return [];
      }
    } catch(err){
      console.log("error",err)
      return [];
    }
  }

  const getSlotTimings=async(date)=>{
    setSlotsLoader(true)
    let slotTimings = [];
    slotTimings = await getSlotTimingsFromDb(date);
    const tempSlots = await validateUsers(date,slotTimings)
    console.log("valdatedSlots",tempSlots);
    setValdatedSlots(tempSlots || [])
    for(var i=0;i<tempSlots.length>0;i++){
      if(slotTimings[i].time===tempSlots[i].bookingTime && (tempSlots[i].slotPerUserData.length>0 || tempSlots[i].userBookedSlotData.length>0 || tempSlots[i].courseConcurrencyErrors.length>0)) {
        slotTimings[i].color = 'red';
      }
    }
    setSlots(slotTimings || []);
    setSlotsLoader(false)
  }

  const getSelectedDays = (selectedDaysArr) =>{
    console.log('selectedDays',selectedDaysArr)
  }
  
  const toggleDay = (toggle) =>{ 
    setSetDay({toggle})
  }

  const openSlotsPopup = (date) =>{                                        //this is called on date click in datepicker
    console.log('open slots popup called',date);
    //getSlots(date);
    currentDate = date.date;
    //setCurrentSlotDate(date.date);
    selectedSlotsArr = [];
    document.getElementById('slotPopup').style.display='flex';            //opens slot popup
    getSlotTimings(date.date)
    //toggleSlots([{...slots[2],selected:true},{...slots[5],selected:true}]);
    //disableSlots([{...slots[1],disabled:true},{...slots[8],disabled:true}]);  
  }

  const blockDate = function(){
    var course = JSON.parse(sessionStorage.getItem('adminSlotBookingCourse'));
    if(course && (course.length>0 || Object.keys(course).length>0)){
      var user = JSON.parse(sessionStorage.getItem('userData'));
      setBlockDaysAfter(new Date(course.endDate));
      console.log("end date",new Date(course.endDate));
      if(new Date(course.startDate) > new Date()){
        setBlockDaysBefore(new Date(course.startDate));
        setCurrentMonth(new Date(course.startDate));
        console.log("start date",new Date(course.startDate))
      } else if(new Date(course.startDate) < new Date()) {
        setBlockDaysBefore(new Date());
        setCurrentMonth(new Date(course.startDate));
        console.log("start date",new Date(course.startDate))
      } else {
        setBlockDaysBefore(new Date(course.startDate));
        setCurrentMonth(new Date(course.startDate));
        console.log("start date",new Date(course.startDate))
      }
    } else {
      alert('Something went wrong..',props.history.push('courses'))
    }
  }
  
  //function to set max slots allowed for a user - for a course timespan
  // const getSubscriptionLimit = async function(){
  //   var user = JSON.parse(sessionStorage.getItem('userData'));
  //   var course = JSON.parse(sessionStorage.getItem('adminSlotBookingCourse'));
  //   try {
  //     var response  = await customHttp.get('/api/concurrentUser/userId/'+user.belongsTo);
  //     console.log(response);
  //     var getSubLimit = response.data.message; //all user subscriptions with limit.
  //     //set limit for selected course/subscription.
  //     for(var i=0;i<getSubLimit.length;i++){
  //       if(getSubLimit[i].subscription == course.name){
  //         var subLimit = getSubLimit[i].subscriptionLimit;
  //         setSlotsAllowed(subLimit)
  //         console.log("sub limit",subLimit)
  //       }
  //     }
  //   } catch(error){
  //     console.log(error)
  //   }
  // }

  // const getSlotsFromDb = async function(){
  //   var user = JSON.parse(sessionStorage.getItem('userData'));
  //   var course = JSON.parse(sessionStorage.getItem('adminSlotBookingCourse'));
  //   try {
  //     var response = await customHttp.get('/api/user/'+user._id+'/subscription/'+course.name+'/currentSlots');
  //     if(response.data){
  //       console.log("getSlotsFromDb", response.data.message)
  //       //fetchSlotsFromDb(response.data.message);
  //       if(response.data.message && response.data.message.length>0){
  //         setTotalBookedSlots(response.data.message.length || 0);
  //         console.log("slots booked",totalBookedSlots)
  //         selectDates(response.data.message);
  //       }
  //     }
  //   } catch(error){
  //     console.log(error)
  //   }
  // }

  //function to select booked days on datepicker
  // const selectDates=(dbDates)=>{
  //   var bookedDates = [];
  //   for(var i=0;i<dbDates.length;i++){
  //     const temp = new Date(dbDates[i].date)
  //     bookedDates.push(temp);
  //   }
  //   console.log("bookedDates",dbDates);
  //   setSelectedDays(bookedDates);
  // }
  //function to get already booked slots
  // var dbSlots=[];
  // const fetchSlotsFromDb = function(bookedSlots){
  //   var getSlotTimes = [];
  //   var responseFromDb = bookedSlots;
  //   for(var i in responseFromDb){
  //     if(responseFromDb[i].date !=undefined){
  //       var obj = {};
  //       var holdDate = responseFromDb[i].date.substr(0,10);
  //       var dateObj = new Date(holdDate);
  //       var getDate = dateObj;
  //       //var getDate = date.format(dateObj, "MM-DD-YYYY")
  //       obj.time = responseFromDb[i].bookingTime;
  //       obj.date = getDate;
  //       dbSlots.push(obj);
  //       getSlotTimes.push(obj);	
  //     }
  //   }
  // }

  //function to show db slot timings as selected
  // const getSlots = async (date,slotsArr)=>{
  //   var user = JSON.parse(sessionStorage.getItem('userData'));
  //   var course = JSON.parse(sessionStorage.getItem('adminSlotBookingCourse'));
  //   console.log("dateeee",date)
  //   try {
  //     var slotHours;
  //     if(course.slotHours === null){
  //       slotHours='2';
  //     }else{
  //       slotHours=course.slotHours;
  //     }
  //     var formattedDate = format(new Date(date.date), "yyyy-MM-dd")
  //     var response = await customHttp.get(`/api/slot/fetchSlot/userId/${user._id}/adminId/${user.belongsTo}/courseId/${course._id}/date/${formattedDate}/slotHours/${slotHours}`);
  //     // var response = await customHttp.get('/api/slot/fetchSlot/userId/'+user._id+'/adminId/'+user.belongsTo+'/subscription/'+course.name+'/date/'+formattedDate);
  //     console.log("get slots response",response,"slots array",slotsArr);
  //     addColorToSlots(response.data.message,slotsArr)
  //   } catch(error){
  //     console.log("error",error)
  //   }
  // }

  // const addColorToSlots=(dbSlots,slotsArray)=>{
  //   var slotsArr = JSON.parse(JSON.stringify(slotsArray));
  //   for(var i=0;i<slotsArr.length;i++){
  //     for(var j=0;j<dbSlots.length;j++){
  //       if(slotsArr[i].time===dbSlots[j].time && dbSlots[j].color==='orange'){
  //         slotsArr[i].color = 'orange';
  //       } else if(slotsArr[i].time===dbSlots[j].time && dbSlots[j].color==='red'){
  //         slotsArr[i].color = 'red';
  //       } else if(slotsArr[i].time===dbSlots[j].time && dbSlots[j].color==='blue'){
  //         slotsArr[i].selected = true;
  //       }
  //     }
  //   }
  //   setSlots(slotsArr);
  //   blockSlots(slotsArr,dbSlots)
  // }  
  ///function to block slot timings of a date
  // const blockSlots=(slotsOrg,dbSlots)=>{
  //   var slotsArr = [];
  //   for(var j=0;j<dbSlots.length;j++){
  //     if(dbSlots[j].block){
  //       slotsArr.push({time:dbSlots[j].time,disabled:dbSlots[j].block})
  //     }
  //   }
  //   // send only block true slots to disbale slots function 
  //   disableSlots(slotsOrg,slotsArr)
  // }

  //function to disable slots
  // const disableSlots=(slotsArr,toggleDisabledSlots)=>{
  //   console.log("db slots",toggleDisabledSlots)
  //   var arr = JSON.parse(JSON.stringify((slotsArr)));
  //   for(var i=0;i<arr.length;i++){
  //     for(var j=0;j<toggleDisabledSlots.length;j++){
  //       if(arr[i].time===toggleDisabledSlots[j].time){
  //         arr[i].disabled=toggleDisabledSlots[j].disabled;
  //       }
  //     }
  //   }
  //   setSlots(arr)
  // }
  const checkSlotAvailability = (item) => {
    for(var i=0;i<valdatedSlots.length>0;i++){
      if(valdatedSlots[i].bookingTime===item.time && (valdatedSlots[i].slotPerUserData.length>0 || valdatedSlots[i].userBookedSlotData.length>0 || valdatedSlots[i].courseConcurrencyErrors.length>0)){
        return {isAvailable:false,slot:valdatedSlots[i]};
        break;
      }
    }
    return {isAvailable:true,slot:{}};
  }
  const onSlotClick = async (item) => {
    const validatedItem = await checkSlotAvailability(item);
    if(validatedItem.isAvailable){
      var arr = JSON.parse(JSON.stringify((slots)));
      arr.filter(obj=>{
        if(obj._id===item._id){
          obj.selected=!obj.selected;
          toggleSelectedSlotsArr(obj)
        }
        return obj
      })
      setSlots(arr); 
    } else {
      let users = []
      if(Object.keys(validatedItem.slot).length>0){
        users = validatedItem.slot.slotPerUserData.concat(validatedItem.slot.userBookedSlotData,validatedItem.slot.courseConcurrencyErrors) || [];
      }
      setUnAvailableUserList(users);
      setShowUserList(true)
    }
  }
  const toggleSelectedSlotsArr=(item)=>{
    if(selectedSlotsArr.length>0){
      for(var i=0;i<selectedSlotsArr.length;i++){
        if(selectedSlotsArr[i]._id===item._id && !item.selected){
          selectedSlotsArr.splice(i,1);
          i--;
        } else if(i===selectedSlotsArr.length-1 && item.selected){
          selectedSlotsArr.push(item);
          break
        }
        console.log("loop")
      }
    } else {
      selectedSlotsArr.push(item);
    }
    console.log("selected slots array",selectedSlotsArr)
  }
  const submitSlots=async(arr)=>{
    var course = JSON.parse(sessionStorage.getItem('adminSlotBookingCourse'));
    var userList = JSON.parse(sessionStorage.getItem('adminSlotBookingUsers'));
    const adminSelectedApplications = JSON.parse(sessionStorage.getItem('adminSelectedApplications'));
    //var selectedApps = JSON.parse(sessionStorage.getItem('userSelectedApplications'));
    var tempArray = arr;
    var bookSlotArray = [];
		if(Array.isArray(userList) && userList.length>0){
      for (var i=0;i<tempArray.length;i++) {
        for(var j=0;j<userList.length;j++){
          var obj = {};
          obj.date = format(new Date(currentDate),"yyyy-MM-dd");
          obj.bookingTime = tempArray[i].time;
          obj.username = userList[j].username;
          obj.email = userList[j].email;
          obj.userId = userList[j]._id;
          obj.subscription = course.name;
          obj.applications = adminSelectedApplications;
          obj.courseId = course._id;
          obj.adminId = userList[j].adminId;
          obj.belongsTo = userList[j].belongsTo;
          obj.slotHours=course.slotHours;
          //var tempArray1 = [obj];
          bookSlotArray.push(obj)
        }
      } //end of for loop
    }
    if(bookSlotArray.length>0){
      setBookSlotLoader(true)
      try {
        var response  = await customHttp.put('/api/slot/faculty/bookSlot', bookSlotArray)
        console.log("book slot response",response)
        //getSlotsFromDb();
        //var temp=JSON.parse(JSON.stringify(dummySlots))
        //setSlots(temp);
        //getSlotTimings()
        selectedSlotsArr = [];
        setDay.toggle(new Date(currentDate),true);
        setCurrentMonth(new Date(obj.date) || new Date())
        document.getElementById('slotPopup').style.display='none';
        setAlertMessage('Slot booked successfully');
        snackbar.successSnackbar();
        setBookSlotLoader(false)
      } catch(error){
        setAlertMessage('Error! Try again..');
        snackbar.errorSnackbar();
        let errors = [];
        if(error.response && error.response.data && error.response.data.message){
          errors = error.response.data.message;
        } else {
          errors = ['Something went wrong! Try again'];
        }
        setBookSlotError(errors || ['Something went wrong! Try again'])
        setBookSlotLoader(false)
        console.log("error",error)
      }
    }
  }
  const handleSubmit=()=>{
    if(selectedSlotsArr.length>0){
      //var slotCount = totalBookedSlots+selectedSlotsArr.length;
      //console.log("slot count",slotCount)
      //if(slotCount<=slotsAllowed){
        submitSlots(selectedSlotsArr);
      //} else {
        //setAlertMessage(`maximum of ${slotsAllowed} allowed to book`);
        //snackbar.errorSnackbar();
      //}
    } else {
      setAlertMessage('please select atleast one slot');
      snackbar.errorSnackbar();
    }
  }
  const closePopup=async()=>{
    //setSlots(dummySlots);
    //getSlotTimings()
    // var temp = [];
    // //var arr = Array.from(slots);
    // var temp = Array.from(slots);
    // for(var i=0;i<temp.length;i++){
    //   for(var j=0;j<selectedSlotsArr.length;j++){
    //     if(temp[i]._id===selectedSlotsArr[j]._id){
    //       console.log("checkkk")
    //        temp.splice(i,1);
    //        i--;
    //        console.log("temp array",JSON.parse(JSON.stringify(temp)))
    //        break
    //     }
    //   }
    //   if(i===temp.length-1){
    //     var activateDate = false;
    //     for(var k=0;k<temp.length;k++){
    //       if(temp[k].selected){
    //         activateDate = true;
    //         setDay.toggle(new Date(currentDate),true)
    //         break;
    //       }
    //       if(k===temp.length-1){
    //         if(activateDate){
    //           setDay.toggle(new Date(currentDate),true)
    //         } else {
    //           setDay.toggle(new Date(currentDate),false)
    //         }
    //       }
    //     }
    //   }
    // }
    setDay.toggle(new Date(currentDate),false)
    setBookSlotError([])
    document.getElementById('slotPopup').style.display='none';
    setShowUserList(false)
  }
  var users = [0,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4,1,2,3,4,5,4]
  return (
    <div className="add-on-load-animation">
      <div id="successSnackbar">{alertMessage}</div>
      <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{alertMessage}</div>
      <div className="col-md-12 nj-card" >
        <div className="row">
          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4>Book Slots</h4>
            </div>
            <div>
              <button className="nj-flow-button " type="button" onClick={()=>props.handleBookSlotBack(props.history)} style={{marginBottom:"10px"}}>Back</button>
            </div>
          </div>
        </div>
        <div className="row" style={{padding:10}}>
          <DatePicker multiple={true} currentMonth={currentMonth} onDayClick={openSlotsPopup} key={randomNumber} selectedDays={selectedDays} blockDaysBeforeToday={true} blockDaysBefore={blockDaysBefore} blockDaysAfter={blockDaysAfter} getSelectedDays={getSelectedDays} toggleDay={toggleDay} />
        </div>
      </div>
      {/* popup slots */}
      <div id="slotPopup" className="nj-overly add-rebound-animation slot-popup" style={{background:'rgba(0, 0, 0, 0.08)',zIndex:'2'}}>
        <div className="popup-nj my-popup" style={{minWidth:'300px'}}>
          <header className="popup-header slot-popup-header" style={{borderBottom:'1px solid #dcdcdcb8'}}>
            <span style={{padding:'8px 20px',fontWeight:'500',fontSize:15}}>Book Timings </span>
            <span onClick={closePopup} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null" style={{fontSize:20}}>close</i></span>
          </header>
          {!showUserList?<div className="popup-content" style={{paddingTop:10}}>
            {!slotsLoader?<div className="col-md-12">
              {slots.length>0?<div className="slot-button-group-wrapper text-center">
                {bookSlotError.length<=0 ?<Fragment>
                {slots.map((item,i)=>{
                  return(        
                    <button type="button" className={"slot-button-group-btn"+(item.selected?' active':'')+(item.disabled?' disabled':'')+(' '+item.color)} key={item._id} onClick={()=>onSlotClick(item)}>
                      {item.time}
                    </button>
                  )
                })}
                </Fragment>:
                <Fragment>
                {bookSlotError.map((item,i)=>{
                  return(        
                    <p key={i} style={{color:'red',textAlign:'left',padding:'0 5px'}}>{i+1+'. '+item}</p>
                  )
                })}
                </Fragment>}
              </div>:<p style={{textAlign:'center'}}>No slots found</p>}
              {bookSlotLoader?<img src={"/img/loaders/spinningwheel.gif"}  className="pull-right" height={50} width={50} alt="loader" />:<span>{bookSlotError.length<=0 && <button type="button" className="nj-flow-button pull-right" style={{margin:'5px 5px 10px 5px',padding:'0.75rem 3rem'}} onClick={handleSubmit}>Submit</button>}</span>}
              <span><button type="button" className="nj-flow-button bg-color-red pull-right" style={{margin:'5px 5px 10px 5px',padding:'0.75rem 3rem'}} onClick={closePopup}>Cancel</button> </span>
            </div>:<div className="col-md-12">
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:100,width:'100%'}}><img src={"/img/loaders/spinningwheel.gif"} height={50} width={50} alt="loader" /></div>
            </div>}
          </div>:
          <div style={{animation:'asbUserListPopup 500ms ease-in'}}>
            <div className="row">
              <div className="col-md-12">
                {unAvailableUserList.length>0 ? <div className="popup-content" style={{maxHeight:450,margin:10,backgroundColor:'#f6f7fb',borderRadius:5}}>
                  {unAvailableUserList.map((item,i)=>{
                    return (<p className="err-msg" key={i}>{`${i+1}. ${item}`}</p>)
                  })}
                </div>:
                <div className="popup-content">
                  <p style={{color:'red',padding:'0 15px',textAlign:'center'}}>No data available..</p>
                </div>}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <button className="nj-flow-button pull-right" style={{margin:'0 10px 10px 10px'}} onClick={()=>setShowUserList(false)}>Ok</button>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => ({
  bookSlotState: state.bookSlotState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  handleBookSlotBack
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(SlotBooking);