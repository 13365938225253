import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import * as CourseAnalytics from "../../container/actions/CourseAnalytics/action";
// import Store from "../../container/reducers/Store";
import * as Actions from "../../container/actions/CourseAnalytics";
import { bindActionCreators } from 'redux';
import "./CourseAnalytics.scss"
import CustomStorage from '../../packages/CustomStorage/';

function CourseUsers (props) {
  const [searchUser , setSearchValue] = useState("");
  const [selectCourseUser , setselectCourseUser] = useState({}); 
  const allCourses = [{name: "Fluid Mechanics",_id: "5c15f3907257166d93c72201",displayName:"Fluid Mechanics"},
  {name: "Manufacturing Technology",_id: "5c15f4677257166d93c72202",displayName:"Manufacturing Technology"},
  {name: "Applied Thermodynamics",_id: "5c15f40c7257166d93c72203",displayName:"Applied Thermodynamics"},
  {name: "Dynamics of Machinery",_id: "5c15f3907257166d93c72204",displayName:"Dynamics of Machinery"},
  {name: "Design of Machine Elements",_id: "5c15f3907257166d453c72205",displayName:"Design of Machine Elements"}];
  const [allUsers , setAllUsers] = useState([{username:"test",email:"test@gmail.com",bookedCount:"15",usedCount:"10",lastLoggedOutDate:"10-3-2020",_id:"5c15f3907257166d453c71101"},
  {username:"user1",email:"user1@gmail.com",bookedCount:"13",usedCount:"2",lastLoggedOutDate:"22-3-2020",_id:"5c15f3907257166d453c71102"},
  {username:"user2",email:"user2@gmail.com",bookedCount:"10",usedCount:"2",lastLoggedOutDate:"17-3-2020",_id:"5c15f3907257166d453c71103"},
  {username:"user3",email:"user3@gmail.com",bookedCount:"9",usedCount:"1",lastLoggedOutDate:"10-3-2020",_id:"5c15f3907257166d453c71104"},
  {username:"user4",email:"user4@gmail.com",bookedCount:"12",usedCount:"4",lastLoggedOutDate:"1-3-2020",_id:"5c15f3907257166d453c71105"},
  {username:"user5",email:"user5@gmail.com",bookedCount:"10",usedCount:"5",lastLoggedOutDate:"8-2-2020",_id:"5c15f3907257166d453c71106"},
  {username:"user6",email:"user6@gmail.com",bookedCount:"1",usedCount:"1",lastLoggedOutDate:"11-3-2020",_id:"5c15f3907257166d453c71107"},
  {username:"user7",email:"user7@gmail.com",bookedCount:"8",usedCount:"0",lastLoggedOutDate:"12-3-2020",_id:"5c15f3907257166d453c71108"},
  {username:"user8",email:"user8@gmail.com",bookedCount:"10",usedCount:"3",lastLoggedOutDate:"13-3-2020",_id:"5c15f3907257166d453c71109"},
  {username:"user9",email:"user9@gmail.com",bookedCount:"13",usedCount:"10",lastLoggedOutDate:"14-3-2020",_id:"5c15f3907257166d453c71110"},
  {username:"user10",email:"user10@gmail.com",bookedCount:"2",usedCount:"1",lastLoggedOutDate:"15-3-2020",_id:"5c15f3907257166d453c71111"}]);   
  const [dupAllUsers , setDupAllUsers] = useState([]);

  useEffect( () => {   
    // console.log("hello",props) 
    props.getAllCourses(); 
    props.setSearchUsername("");
    setDupAllUsers(allUsers);
    
  },[]);

  useEffect( () => {  
    if(props.demoAndActualValue === "actual"){
      setselectCourseUser(props.selectedCourse);
       props.getUsersByCourse(props.selectedCourse);
    }
    
    
  },[props.selectedCourse]);

  // var handleChangeCourses =(event) =>{
  //   var selectedCourse= JSON.parse(event.target.value)
  //   // console.log("course selected",selectedCourse)
  //   setselectCourseUser(selectedCourse);
  //   if(props.demoAndActualValue === "actual"){
  //    // props.getUsersByCourse(selectedCourse);
  //     props.getUsersByCourse();
  //  }
  // }

  var handleSearchChange = (e) => {
    setSearchValue(e.target.value)
    if(props.courseAnalyticsState.selectedType === "demo"){
        searchFilterDemo(e.target.value);
    }
    else{
      props.setSearchUsername(e.target.value)
      props.searchFilter(e.target.value)
    }
};

//added by narasappa
var getUserDetails=(obj)=>{
  CustomStorage.localStorageSet('selectedDemoOrActual', props.courseAnalyticsState.selectedType)
  if(props.courseAnalyticsState.selectedType === "demo"){
    if(Object.keys(selectCourseUser).length>0){
      CustomStorage.localStorageSet('selectedUserData',obj)
      CustomStorage.localStorageSet('selectedCourseData',selectCourseUser)
    }else{
      CustomStorage.localStorageSet('selectedUserData',obj)
      CustomStorage.localStorageSet('selectedCourseData',allCourses[0])
    }
 }else{
  if(Object.keys(selectCourseUser).length>0){
    CustomStorage.localStorageSet('selectedUserData',obj)
    CustomStorage.localStorageSet('selectedCourseData',selectCourseUser)
  }else{
    CustomStorage.localStorageSet('selectedUserData',obj)
    CustomStorage.localStorageSet('selectedCourseData',props.courseAnalyticsState.allCourses[0])
  }
 }
  setTimeout(() => {
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    if(userDetails.type===1){
      props.props.history.push("/course-user-details")
    }else if(userDetails.type===2){
      props.props.history.push("/dlt/course-user-details")
    }else{
      props.props.history.push("/sub-admin/course-user-details")
    }
  });
}
//end
var searchFilterDemo=(searchValue)=>{
  var dupUsers=dupAllUsers;

  if(searchValue !==""){
    var searchRequestedArray=dupUsers.filter(el =>{
       if(el.username!==undefined){
          return el.username.toLowerCase().indexOf(searchValue.toString().toLowerCase()) !== -1;}
    })
    setAllUsers(searchRequestedArray);
 }
    else {
       setAllUsers(dupUsers);

  }
}



 
    return (
      <div className="">
        {/* <div id="successSnackbar">{props.courseAnalyticsState.successSnackbarMessage}</div>
        <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.courseAnalyticsState.errorSnackbarMessage}</div> */}
        {/* <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px',paddingBottom: 10}}>
          <div className="col-md-12 col-sm-12 nj-card">
            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header" style={{borderBottom:"none"}}>
                <div className="nj-card__title col-md-12">
                  <div className="col-md-6 pull-left" style={{marginLeft:"0px",paddingLeft:"0px"}}>
                    <h4 className="font_family_montserrat">Course Analytics</h4> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-md-12 col-sm-12 col-xs-12 nj-card" style={{marginBottom:"15px",width: '100%',}}>
          <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}} >
            <div className="nj-card__header" style={{borderBottom:"none",paddingTop: '10px',paddingBottom:'10px'}}>
              <div className="nj-card__title col-md-12 col-sm-12 col-xs-12">
              <div className="col-md-3 col-sm-12 col-xs-12">
                <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat',fontWeight:'500'}}>Select Course</label>
                <div className="nj-form-select">
                {props.demoAndActualValue === "actual" ? 
                    <select name="vs-input--input" style={{lineHeight:'25px',borderRadius:'5px'}} value={JSON.stringify(selectCourseUser)} onChange={handleChangeCourses} autoComplete="off" required>
                        {props.courseAnalyticsState.allCourses.length==0 && (<option value=''>No courses available</option>)}
                        {props.courseAnalyticsState.allCourses.map((data,i)=>{
                            return (<option key={i} value={JSON.stringify(data)}>{data.displayName}</option>)
                          })}
                    </select>
                    :null}
                  {props.demoAndActualValue === "demo" ? 
                    <select name="vs-input--input" style={{lineHeight:'25px',borderRadius:'5px'}} value={JSON.stringify(selectCourseUser)} onChange={handleChangeCourses} autoComplete="off" required>
                        {allCourses.length==0 && (<option value=''>No courses available</option>)}
                        {allCourses.map((data,i)=>{
                            return (<option key={i} value={JSON.stringify(data)}>{data.displayName}</option>)
                          })}
                    </select>
                    :null}
                </div>
              </div>
              <div className="col-md-3 col-sm-12 col-xs-12 pull-right">
                <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat',fontWeight:'500'}}>Search Username</label>
                <input className="vs-input--input nj-user-search-bar" type="text" name="searchUser" value={props.courseAnalyticsState.searchUsername} onChange={handleSearchChange} placeholder="search" autoComplete="off"/>
              </div>
              </div>
              </div>
            
          </div>
        </div><br/> */}
        
        <div className="col-md-12"><br/>
          <div className="row">        
            {props.courseAnalyticsState.showUserList ?
            <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
              <div className="col-md-12 col-sm-12 " style={{marginBottom:"40px"}}>
                {/* add nj-card className to above line */}
                {/* {props.courseAnalyticsState.allCourses.length>0 ?<div>
                <div className="col-md-3 nj-popup-form-wrap" style={{margin: '21px 0px'}}>
                    <p className="popup-content-label">Select Course</p>
                    <div className="nj-form-select">
                        <select name="bulkUploadDesktopLanguage" value={JSON.stringify(selectCourseUser)} onChange={handleChangeCourses} autoComplete="off" required>
                        {props.courseAnalyticsState.allCourses.map((data,i)=>{
                            return (<option key={i} value={JSON.stringify(data)}>{data.displayName}</option>)
                          })}
                        </select>
                    </div>
                </div><br/>
                <div className="col-md-4 col-xs-12 pull-right nj-user-search-wrap ">
                    <p className="popup-content-label">Search Username</p>
                    <input className="vs-input--input nj-user-search-bar" type="text" name="searchUser" value={searchUser} onChange={handleSearchChange} placeholder="search" autoComplete="off"/>
                </div>
                </div>: null } */}
               
                         
                <div className="col-md-6 col-xs-12 row  nj-user-search-wrap "  style={{marginLeft:"7px"}}><br/>
                            <h4 className="font_family_montserrat" style={{fontWeight:600}}>Course UserList</h4>
                                  
                </div>         
                <div className="col-md-4 col-xs-12 row pull-right nj-user-search-wrap "><br/>
                        <p className="popup-content-label">Search Username</p>
                        <input className="vs-input--input nj-user-search-bar" type="text" name="searchUser" value={searchUser} onChange={handleSearchChange} placeholder="search" autoComplete="off"/>
                </div>
                <div className="col-md-12"><br/>
                {/* {console.log("",props.courseAnalyticsState.availableCourseLoader)} */}
                  {!props.courseAnalyticsState.availableCourseLoader?
                  <div className="course-table-margin-top">
                 
                    <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"76.3vh"}}>
                     {props.courseAnalyticsState.selectedType === "actual" ? 
                     <div>
                      {  props.courseAnalyticsState.allCourses.length>0  ?
                       <div>
                       {props.courseAnalyticsState.totalUsrerList.length>0 ?
                      <table className="nj-table nj-table--tbody-table font_family_montserrat">
                        <thead className="nj-table--thead">
                          <tr>
                            <th>
                              <div className="nj-table-text">Username</div>
                            </th>
                            <th>
                              <div className="nj-table-text">E-mail</div>
                            </th>
                            <th>
                              <div className="nj-table-text" style={{minWidth:"150px"}}>Booked Count</div>
                            </th>
                            <th>
                              <div className="nj-table-text">Used Count</div>
                            </th>
                            <th>
                              <div className="nj-table-text">Last Loggedout Date</div>
                            </th>
                            <th>
                              <div className="nj-table-text">Action</div>
                          </th>
                          </tr>
                        </thead>
                        
                           <tbody>
                          {props.courseAnalyticsState.totalUsrerList.map((data,i)=>{
                            return (<tr key={i}  className="tr-values nj-table--tr tr-table-state-null">

                              {data.username !==undefined ? <td className="td nj-table--td">{data.username}</td> :  <td className="td nj-table--td">---</td> }
                              <td className="td nj-table--td">{data.email}</td>
                              {data.username !==undefined ? <td className="td nj-table--td">{data.bookedCount}</td>:  <td className="td nj-table--td">---</td> }
                              <td className="td nj-table--td">{data.usedCount}</td>
                              {data.lastLoggedOutDate !== undefined &&
                              <td className="td nj-table--td">{data.lastLoggedOutDate.slice(0,10)}</td>}
                              <td className="td nj-table--td">
                                <button type="button" className="nj-flow-button"  onClick={(e)=>{getUserDetails(data)}}>View Details</button>
                              </td>
                            </tr>)
                          })}
                          </tbody>
                         
                          
                      </table>
                       :
                       <div className="col-md-12 idams_noDataFound" style={{height:"54vh"}}>
                       <span className="font_family_montserrat">No user found</span>
                     </div>
                     }
                     </div>
                       :
                   <div className="col-md-12 idams_noDataFound" style={{height:"54vh"}}>
                     <span className="font_family_montserrat">No data found</span>
                   </div>
                    }
                       </div>
                   
                      :
                      <table className="nj-table nj-table--tbody-table font_family_montserrat">
                      <thead className="nj-table--thead">
                        <tr>
                          <th>
                            <div className="nj-table-text">Username</div>
                          </th>
                          <th>
                            <div className="nj-table-text">E-mail</div>
                          </th>
                          <th>
                            <div className="nj-table-text" style={{minWidth:"150px"}}>Booked Count</div>
                          </th>
                          <th>
                            <div className="nj-table-text">Used Count</div>
                          </th>
                          <th>
                            <div className="nj-table-text">Last Loggedout Date</div>
                          </th>
                          <th>
                            <div className="nj-table-text">Action</div>
                          </th>
                        </tr>
                      </thead>
                        {allUsers.length>0 ?
                         <tbody>
                        {allUsers.map((data,i)=>{
                          return (<tr key={i}  className="tr-values nj-table--tr tr-table-state-null">

                            {data.username !==undefined ? <td className="td nj-table--td">{data.username}</td> :  <td className="td nj-table--td">---</td> }
                            <td className="td nj-table--td">{data.email}</td>
                            {data.username !==undefined ? <td className="td nj-table--td">{data.bookedCount}</td>:  <td className="td nj-table--td">---</td> }
                            <td className="td nj-table--td">{data.usedCount}</td>
                            <td className="td nj-table--td">{data.lastLoggedOutDate.slice(0,10)}</td>
                            <td className="td nj-table--td">
                               <button type="button" className="nj-flow-button"  onClick={(e)=>{getUserDetails(data)}}>View Details</button>
                            </td>
                            
                          </tr>)
                        })}
                        </tbody>
                        :
                        <tbody>
                          <td colSpan="5" className="td nj-table--td" style={{height:"54vh",textAlign:'center'}}>No Users Found</td>
                        </tbody>
                        }
                    </table>}
                   </div>
                  
                  </div> 
                  :
                  <div className="row" style={{height:"76.3vh"}}>
                    <div className="col-md-12 aligh-loader-image-center" >
                      <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                    </div>
                  </div>
                  }            
                </div>
              </div>
            </div>
            :
            null
            }
          </div>
        </div>  

      </div>    
    );
  }


const mapStateToProps = state => ({
  courseAnalyticsState: state.courseAnalyticsState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllCourses:CourseAnalytics.getAllCourses,
  getUsersByCourse:CourseAnalytics.getUsersByCourse,
  searchRequestedContent:Actions.searchRequestedContent,
  setSearchUsername:CourseAnalytics.setSearchUsername,
  searchFilter:CourseAnalytics.searchFilter
  
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(CourseUsers);
