import React, { Component } from 'react';
import axios from "axios";
import './sharedCourseLogin.scss';
import customHttp from '../packages/CustomHttp';
var timerValue="";
class Authenticate extends Component {
  constructor(props){
    super(props);
    this.state = {
        loginEmail : "",
        loginOtp:"",

        onSubmit : false,
        onSubmitRegistration : false,
        signUp : false,
        snackbar_message:"",
        otpIsInvalid:false,
        otpTimeExpired:false,
        disableResendButton:false,
        submitEmailWhileLogin:false,
        submitEmailWhileLogin1:false,
        loginPage:true,
        otpSuccessAlert:false,
        organizationName:"",
        orgNameExist:false,
        domainNameExist:false,
        domainName:"",
        orgValidation:false,
        domainNameValidation : false,

        registerEmail:"",
        phoneNumber:"",
        userName:"",
        emailExist : false,
        userNameExist:false,
        disableUserName:false,
        userNameValidation:false,
        registerOtp:"",
        usersExceeded:false,
        userRegisteredSucc:true,
        currentDate : new Date(),
        urlExpired:true,
        createdTime:this.props.match.params.currentTime

    }
  }



  handleChangeRegisterEmail = event =>{
    this.setState({ [event.target.name]: event.target.value,emailExist:false},() => {
      // console.log("Email registered", this.state.registerEmail);
    });
    this.emailExists(event.target.value);
  }

  emailExists = (obj) => {
      axios
        .get("/api/user/checkUserEmailExists/" + obj)
        // var url = "/api/user/checkUserEmailExists/" + obj;
        // customHttp.get(url)
        .then(response => {
          // console.log("email exist checking ", response.data.message);
          if(response.data.message.success === true){
            this.setState({emailExist:false})
          }
          else{
            if(response.data.message.message === "Email already exists"){
              this.setState({emailExist : true })
            }
            else{
              this.setState({emailExist : false })
            }
          }
        })
        .catch(error => {
          console.log("failed", error.response);
        });
    }

  handleChangeRegisterOtp = event =>{
    this.setState({ [event.target.name]: event.target.value,otpIsInvalid:false},() => {
      // console.log("otp entered", this.state.registerOtp);
    });
  }

    onSubmitRegistration = (e) => {
      e.preventDefault();

      this.setState({submitEmailWhileLogin:true })
      axios
        .get("/api/user/validateUser/email/" + this.state.registerEmail)
        // var url = "/api/user/validateUser/email/" + this.state.registerEmail ;
        // customHttp.get(url)
        .then(response => {
                this.sendEmailOtp();

        })
        .catch(error => {
          this.setState({submitEmailWhileLogin:false})
          console.log("failed", error);
        });
    }

    sendEmailOtp = () => {
      // console.log("email registration",this.state.registerEmail)
      let data1 = {};
      data1.email = this.state.registerEmail;
      axios
        .put('/api/user/login/email/sendOtp',data1)
       // var url = '/api/user/login/email/sendOtp';
       // customHttp.put(url, data1)
      .then(response => {
        // console.log("registration otp sent", response.data.message);
        this.setState({onSubmitRegistration :true,submitEmailWhileLogin:false})
        this.startTimer();
        // setTimeout(function(){
        //   //this.$apply();
        // }, 119000);
        timerValue=setTimeout(function(){
        window.location.reload()
      }, 122000);
      })
      .catch(error => {
        this.setState({submitEmailWhileLogin:false})
        console.log("failed", error);
      });
    }
    alertFunc=()=>{
      this.setState({otpSuccessAlert:false})
     }


    onResendRegistration = () => {
      this.setState({disableResendButton:true,submitEmailWhileLogin1:true})
      let object = {};
      object.email = this.state.registerEmail;
      axios
        .put('/api/user/login/email/resendOtp',object)
        // var url = '/api/user/login/email/resendOtp' ;
        // customHttp.put(url , object)
        .then(response => {
          this.setState({disableResendButton:false,submitEmailWhileLogin1:false,otpSuccessAlert:true})
          setTimeout(this.alertFunc,6000);
          // console.log("resent registration otp", response.data.message);
          setTimeout(()=>{
            document.getElementById('signUpOtp').classList.remove("fadeInLeft");
            document.getElementById('signUpOtp').classList.add("fadeOutLeft");
          },5500);
        })
        .catch(error => {
          this.setState({disableResendButton:false,submitEmailWhileLogin1:false,otpSuccessAlert:false})
          console.log("failed", error.response);
        });
    }

    onCompleteRegistration = (e) =>{
      e.preventDefault();
      this.setState({submitEmailWhileLogin:true})
      let data = {};
      data.email = this.state.registerEmail;
      data.otp = this.state.registerOtp;
      axios
        .put("/api/user/login/email/verifyOtp",data)
      // var url = "/api/user/login/email/verifyOtp" ;
      // customHttp.put(url,data)
        .then(response => {
          // console.log("verify register otp", response);
          let data = {};
          let obj=this.props.match.params;

          data.courses = obj.id ;
          data.username="" ;
          data.phonenumber="";
          data.email = this.state.registerEmail.toLowerCase() ;
          data.adminId = obj.adminId;
          data.belongsTo = obj.belongsto;

          axios
            .put("/api/user/registerNewUser",data)
          // var url = "/api/user/registerNewUser" ;
          // customHttp.put(url,data)
          .then(response => {
          // console.log("user created successfully", response);
          this.setState({userRegisteredSucc:false})
          // window.location.reload()
          // clearTimeout(timerValue);
        })
            .catch(error => {
              console.log("error", error.response);
              this.myFunction1();
              this.setState({submitEmailWhileLogin:false,snackbar_message:"Error..try again"})
            });

        })
        .catch(error => {
          this.setState({submitEmailWhileLogin:false})
          this.setState({otpIsInvalid : true})
          console.log("failed", error.response);
        });
    }


    componentDidMount=()=>{
      this.checkUrlExpire()
      let obj=this.props.match.params;
      // console.log("hai oy",obj.currentTime)
      this.countDown()
    }



     startTimer1=()=>{
       if(document.getElementById('timer1') !== null){
        // document.getElementById('timer1').innerHTML =  parseInt("02") + ":" + parseInt("00");
        document.getElementById('timerStroke').classList.add("ticking");
        var presentTime = document.getElementById('timer1').innerHTML;
        var timeArray = presentTime.split(/[:]+/);
        var m = timeArray[0];
        var s = this.checkSecondForTimer1((timeArray[1] - 1));
        if(s==59){m=m-1}
        //if(m<0){alert('timer completed')}

        document.getElementById('timer1').innerHTML =
          m + ":" + s;
          if(m>=0 && s>=0){
            setTimeout(this.startTimer1, 1000);
           }
       }

    }
    checkSecondForTimer1=(sec)=>{
      if (sec < 10 && sec >= 0) {sec = "0" + sec}; // add zero in front of numbers < 10
      if (sec < 0) {sec = "59"};
      return sec;
    }

     startTimer=()=>{
       if(document.getElementById('timer2')!==null){
        // document.getElementById('timer2').innerHTML =  parseInt("02") + ":" + parseInt("00");
        document.getElementById('timerStroke').classList.add("ticking");
        var presentTime = document.getElementById('timer2').innerHTML;
        var timeArray = presentTime.split(/[:]+/);
        var m = timeArray[0];
        var s = this.checkSecondForTimer((timeArray[1] - 1));
        if(s==59){m=m-1}
        //if(m<0){alert('timer completed')}

        document.getElementById('timer2').innerHTML =
          m + ":" + s;
          if(m>=0 && s>=0){
            setTimeout(this.startTimer, 1000);
           }
       }else{

       }

    }

    checkSecondForTimer(sec) {
      if (sec < 10 && sec >= 0) {sec = "0" + sec}; // add zero in front of numbers < 10
      if (sec < 0) {sec = "59"};
      return sec;
    }
    signUp = () =>{
      console.log("sign up")
       this.setState({signUp:true,disableResendButton:false,otpTimeExpired:false,otpIsInvalid:false,emailExist : false,loginPage:false})
    }
    backLogin = () =>{
      console.log("login")
       this.setState({signUp:false,disableResendButton:false,otpTimeExpired:false,otpIsInvalid:false,emailExist : false,loginPage:true})
    }

    myFunction=()=> {
      var x = document.getElementById("snackbar")
      x.className = "show";
      setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    }

    myFunction1=()=> {
      var x = document.getElementById("snackbar1")
      x.className = "show";
      setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    }

    // checking username

    // handleChangeUsername = (e) => {
    //   this.setState({[e.target.name]:e.target.value.toLowerCase(),userNameValidation : false})
    //   if(Object.keys(e.target.value).length>0) {
    //     var value = e.target.value.toLowerCase()
    //     var url = "/api/user/checkUsernameExists/"+value

    //     customHttp.get(url)
    //     .then(response => {

    //       let temp = response.data.message.success;
    //       if(temp){
    //         this.setState({userNameExist : true})
    //       }else{
    //         this.setState({userNameExist : false})
    //       }
    //     })
    //     .catch(error => {
    //       this.setState({userNameExist : false})
    //       console.log("failed", error);
    //     });
    //   }else{
    //     this.setState({userNameExist : false})
    //   }
    // }

    // handleChangeValidateUsername=(e)=>{
    //   let userRegex = /^[a-zA-Z0-9 ]*$/;
    //     let userValid = userRegex.test(String(e.target.value))
    //     if(Object.keys(e.target.value).length>0 && !userValid){
    //       this.setState({userNameValidation : true})
    //     }
    //     else{
    //       this.setState({userNameValidation : false})
    //     }
    // }

    // handleChangePhonenumber=(e)=>{
    //   this.setState({[e.target.name]:e.target.value})
    // }


    handleKeyPress=(e)=>{
      if (e.keyCode === 13) {
        e.target.blur()
       }
    }

    checkUrlExpire=(e)=>{
      let totalTime = JSON.parse(this.state.createdTime) + 24*60*60000 ;
    //For testing purpose expiring time has been set to 2min. 1 min = 60000
        if(this.state.currentDate.getTime() >= totalTime){
          this.setState({urlExpired:false});
        }
    }

    countDown = () =>{

        if(this.state.urlExpired !== false){
        var now = new Date().getTime();
        let totalTime =   JSON.parse(this.state.createdTime) + 24*60*60000  - now;
        var distance =totalTime;
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        document.getElementById("countdowntimer").innerHTML ="The shared url will expire in " + hours + "h "
        + minutes + "m " + seconds + "s ";

        if(distance > 0){
          setTimeout(this.countDown, 1000);
         }
         else{
          document.getElementById("countdowntimer").innerHTML ="The shared url expired." ;
        }
       }

      }


    render() {
        return (
          <div className="col-md-12">
            <div id="snackbar">{this.state.snackbar_message}</div>
            <div id="snackbar1"><i className="fa fa-exclamation"></i>{this.state.snackbar_message}</div>

            <div className="row">
              <div className="nj-login-wrap">
                {this.state.urlExpired ?
                <div>
                <p id="countdowntimer" className="required_field_color_red font_family_montserrat" style={{fontWeight:'500',fontSize:'14px',marginLeft:"10px"}}></p>
                {this.state.userRegisteredSucc ?
                <div className="nj-login-wrapper">
                  <div className="col-md-5 hidden-sm hidden-xs">
                    <div className="row">
                      <div className="nj-left-login-panel">
                        <div className="nj-left-login-content">
                          <div className="nj-left-login-content-top-element">
                            <span style={{margin:0,position:'absolute'}}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-log-in nj-left-login-content-top-element-svg"
                              >
                                <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
                                <polyline points="10 17 15 12 10 7" />
                                <line x1={15} y1={12} x2={3} y2={12} />
                              </svg>
                            </span>

                            <span className="nj-left-login-content-top-element-label">
                              Sign up - PLATiFi
                            </span>
                          </div>

                          <div className="" style={{display:"block"}}>
                            <div className="nj-left-login-content-top-element">
                              {this.state.onSubmitRegistration === true ?
                              <span className="nj-left-login-content-element-num" style={{backgroundColor:'#eeeffa',boxShadow:'none'}}>1</span>
                              :
                              <span className="nj-left-login-content-element-num" >1</span>
                              }
                              <span className="nj-left-login-content-top-element-label">Enter your email</span>
                            </div>
                            <br />
                            <br />
                            <div className="nj-left-login-content-top-element">
                            {this.state.onSubmitRegistration === false ?
                            <span className="nj-left-login-content-element-num" style={{backgroundColor:'#eeeffa',boxShadow:'none'}}>2</span>
                            :
                            <span className="nj-left-login-content-element-num">2</span>
                            }
                              <span className="nj-left-login-content-top-element-label">Submit OTP</span>
                            </div>
                          </div>

                          <div className="">
                            <div className="col-md-1" style={{width:'20px',padding:0,marginTop:'24px'}}>
                                <span className="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-info"
                                    >
                                        <circle cx={12} cy={12} r={10} />
                                        <line x1={12} y1={16} x2={12} y2={12} />
                                        <line x1={12} y1={8} x2={12} y2={8} />
                                    </svg>
                                </span>
                            </div>
                            <div className="col-md-11" style={{fontFamily:'Montserrat',fontSize:'12px',color:'rgba(0,0,0,.5)'}}>
                                <span className="row">
                                    But why? Don't worry! we won't share it with anyone and we will never send you spam
                                    emails.
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                   {/* Registration Page  */}
                  <div className="col-md-7" >

                    { this.state.onSubmitRegistration === false ?
                    <div className="row" >
                        <div className="login-padding responsive-logo-smalldiveces" >
                          <div className="justify-between" style={{textAlign:"center",padding:"0px 19px 8px 0px",width:"100%"}}>
                              <div className="logo  items-center">
                                <img
                                  src="/img/logos/platifilogo.png"
                                  alt="logo"
                                  className="font_family_montserrat"
                                  style={{width: "150px"}}
                                />
                              </div>
                            </div>
                            <div className="col-md-8 col-sm-12 col-xs-12">
                                <h3 style={{fontFamily:'Montserrat',marginTop:'20%',fontSize:'28px',color:'#404e67'}}>
                                    What is your Email?
                                </h3>
                                <div className="nj-popup-form-wrap" style={{margin:0,marginTop:'20px'}}>
                                  <form onSubmit={this.onSubmitRegistration}>
                                    <div  className="nj-login-margin-bottom">
                                      <p className="popup-content-label font_family_montserrat" style={{marginLeft:0,fontSize:14}}>Enter your email</p>
                                      <input className="vs-input--input form-control"
                                        type="email"
                                        name="registerEmail"
                                        value={this.state.registerEmail}
                                        onChange={this.handleChangeRegisterEmail}
                                        style={{padding:'20px',paddingLeft:'10px',border:'1px solid #7367f0',borderRadius:'3px'}}
                                        placeholder="jondoe@email.com"
                                        autoComplete="off"
                                        required />
                                        {this.state.emailExist && <p style={{marginTop:'9px',color:'red',fontSize:14}}>
                                            Email already registered!
                                        </p>}
                                    </div>

                                    {/* <div  className="nj-login-margin-bottom">
                                      <p className="popup-content-label font_family_montserrat" style={{marginLeft:0,fontSize:14}}>Enter username</p>
                                      <input className="vs-input--input form-control"
                                        type="text"
                                        name="userName"
                                        value={this.state.userName}
                                        onKeyDown={this.handleKeyPress}
                                        onChange={this.handleChangeUsername}
                                        onBlur={this.handleChangeValidateUsername}
                                        style={{padding:'20px',paddingLeft:'10px',border:'1px solid #7367f0',borderRadius:'3px'}}
                                        placeholder="jondoe123"
                                        autoComplete="off"
                                        disabled={this.state.disableUserName}
                                        maxLength="20"
                                        required />
                                         {this.state.userNameValidation && <p style={{marginTop:'9px',color:'red',fontSize:12}}>
                                          Username can only contain alphabets, numbers.
                                        </p>}
                                        {this.state.userNameExist && <p style={{marginTop:'9px',color:'red',fontSize:12}}>
                                           Username Already Exists.
                                        </p>}
                                    </div> */}

                                    {/* <div  className="nj-login-margin-bottom">
                                      <p className="popup-content-label font_family_montserrat" style={{marginLeft:0,fontSize:14}}>Enter phonenumber</p>
                                      <input className="vs-input--input form-control"
                                        type="text"
                                        name="phoneNumber"
                                        value={this.state.phoneNumber}
                                        onChange={this.handleChangePhonenumber}
                                        style={{padding:'20px',paddingLeft:'10px',border:'1px solid #7367f0',borderRadius:'3px'}}
                                        placeholder="Phonenumber"
                                        autoComplete="off"
                                        required />
                                    </div> */}

                                    {/* {this.state.usersExceeded && <p style={{marginTop:'9px',color:'red',fontSize:12}}>
                                       No of users exceeded for this course please contact your professor.
                                    </p>} */}
                                    {this.state.submitEmailWhileLogin === false ? <button type="submit" className="nj-flow-button pull-right" disabled={this.state.emailExist }>Submit</button> :<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                                  </form>
                                </div>
                            </div>
                        </div>
                    <div />
                  </div>
                  :
                  <div className="row" >
                        <div className="login-padding responsive-logo-smalldiveces" style={{paddingTop:"20px"}}>
                          <div className="justify-between" style={{textAlign:"center",padding:"0px 19px 8px 0px",width:"100%"}}>
                              <div className="logo  items-center">
                                <img
                                  src="/img/logos/platifilogo.png"
                                  alt="logo"
                                  className="font_family_montserrat"
                                  style={{width: "150px"}}
                                />
                              </div>
                            </div>
                            <div className="col-md-8 col-sm-12 col-xs-12">
                                <h4 style={{fontFamily:'Montserrat',marginTop:'5%'}}>
                                    Please check your email for validation
                                </h4>
                                <button type="submit" className="nj-flow-button"  disabled={this.state.disableResendButton}  onClick={this.onResendRegistration} style={{marginBottom:'30px',marginTop:'10px'}}>Resend</button>
                                {this.state.otpSuccessAlert ?
                                <span id='signUpOtp' className="fadeInLeft font_family_montserrat" style={{color:"green"}}>OTP sent successfully!</span>
                                :""}
                                <div ng-hide="otpExpired5">
                                  <p className="otp-timer">Your OTP will expire in</p>
                                  <div className="col-md-12 col-xs-12">
                                    <div className="main col-md-4 col-xs-3 timer-clock" style={{marginBottom:"0px"}}>
                                      <div className="svg timer-clock">
                                        <svg  viewBox="0 0 120 120" className="chart-gauge" fill="none" strokeWidth="2">
                                          <circle className="timer-backdrop" strokeDasharray="360" r="57.32" cx="60" cy="60"></circle>
                                          <circle id="timerStroke" className="timer-gauge" strokeDasharray="360" r="57.32" cx="60" cy="60"></circle>
                                          <text y="50%" x="50%" id="timer2" className="timer-time">2:00</text>
                                        </svg>
                                      </div><br/>
                                    </div>
                                  </div>
                                </div>
                                <div className="nj-popup-form-wrap" style={{margin:0,marginTop:'20px'}}>

                                  <form onSubmit={this.onCompleteRegistration} >
                                    <p className="popup-content-label" style={{marginLeft:0}}>Enter Code</p>
                                    <input className="vs-input--input form-control"
                                      type="text"
                                      name="registerOtp"
                                      value={this.state.registerOtp}
                                      onChange={this.handleChangeRegisterOtp}
                                      style={{padding:'20px',paddingLeft:'10px',border:'1px solid #7367f0',borderRadius:'3px'}}
                                      placeholder="Enter Code"
                                      autoComplete="off"
                                      required />
                                       {this.state.otpIsInvalid && <p style={{marginTop:'9px',marginBottom:'0px', color:'red'}}>
                                          Entered OTP is invalid
                                      </p>}

                                    {this.state.submitEmailWhileLogin === false ? <button type="submit" className="nj-flow-button pull-right" style={{marginTop:"5%"}}>Complete</button> :<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                                    </form>

                                </div>
                            </div>
                        </div>
                    <div />
                  </div>
                    }
                  </div>

                </div>
                :

                 <div className="nj-login-wrapper">
                  <div className="col-md-12 hidden-sm hidden-xs" style={{top:"30%"}}>
                    <div className="row">
                      <div className="success-checkmark">
                       <div className="check-icon">
                            <span className="icon-line line-tip" />
                            <span className="icon-line line-long" />
                            <div className="icon-circle" />
                            <div className="icon-fix" />
                       </div>
                      </div>
                      <p className="popup-content-label" style={{fontSize:18,textAlign:"center",color:"green"}}>Registered successfully..! please contact your professor to enable Courses. </p>
                    </div>
                  </div>
                </div>
                }
              </div>
               :
               <div className="nj-login-wrapper">
                  <div className="col-md-12 hidden-sm hidden-xs" style={{top:"30%"}}>
                    <div className="row">
                      {/* <div className="success-checkmark">
                       <div className="check-icon">
                            <span className="icon-line line-tip" />
                            <span className="icon-line line-long" />
                            <div className="icon-circle" />
                            <div className="icon-fix" />
                       </div>
                      </div> */}
                      <div class="error-banmark">
                          <div class="ban-icon">
                            <span class="icon-line line-long-invert"></span>
                            <span class="icon-line line-long"></span>
                            <div class="icon-circle"></div>
                            <div class="icon-fix"></div>
                          </div>
                        </div>
                      <p className="popup-content-label" style={{fontSize:20,textAlign:"center",color:"rgb(221, 13, 13)"}}>Url has been expired..! please contact your professor . </p>
                    </div>
                  </div>
                </div>
                  }


              </div>
            </div>
          </div>
        );
    }
}

export default Authenticate;
