
import * as CourseActionTypes from "../../../actionTypes";

const initialState = {
  allApplications:[],
  allResources:[],
  showSelectedApplications:true,
  showSelectedResources:true,
  editShowSelectedResources:true,
  selectedApplications:[],
  selectedResources:[],
  courseName:"",
  displayName:"",
  fromDate:"",
  toDate:"",
  courseCode:"",
  numberOfUsers:"",
  slotHours:"",
  availableCourseLoader:false,
  availableCourses:[],
  shouldPopupAnimate:false,
  selectedCourse:{},
  disableCourseDelete:true,
  showCourseList:true,
  showCourseSaveLoader:false,
  successSnackbarMessage:"",
  errorSnackbarMessage:"",
  showCourseUpdateLoader:false,
  showAllResourcesLoader:true,
  selectedDiscipline:{_id:'all'},
  selectedContentType:{_id:'all'},
  searchValue:'',
  disciplines: [],
  contentTypes: [],
  createCourseDisciplines:[],
  createCourseContentTypes:[],
  showLoadCourseLoader:false,
  resourceVideo:{},
  resourceOriginalArray: [],
  selectedArchiveType:{name:"Unarchived",_id:false},
  filteredArray:[],
  playListArrayForDownload:{},
  selected:[],
  appsSelected:[],
  enableLiveClassroom:false,
  enableRemoteProctoring:false,
  slotHoursDropdown : [1,2,3,4,6,8,12],
  isUserSlotBooking: true,
  courseNameValidation:false,
  displayNameValidation:false,
  courseNameEditValidation:false,
  displayNameEditValidation:false,
  courseCodeValidation:false,
  courseCodeEditValidation:false,
  adminConfidentialDetails:[],
  createdCourseArrayFirstObject:{},
  showOnApiResponse:false
}

export default function(state=initialState, action) {
  switch (action.type) {
    case CourseActionTypes.COURSE_GET_ALL_APPLICATION: {
      const {content } = action.payload; 
      return {
        ...state,allApplications:content
      }
    }
    case CourseActionTypes.COURSE_GET_ALL_RESOURCES: {
      const {content } = action.payload; 
      // console.log("get all resources",content);
      return {
        ...state,allResources:content
      }
    }
    case CourseActionTypes.COURSE_SET_BOOLEAN_VALUE: {
      const {name,value} = action.payload; 
      return {
        ...state,[name]:value
      }
    }
    case CourseActionTypes.COURSE_SELECT_APPLICATION: {
      const {apps} = action.payload; 
      return {
        ...state,allApplications:apps
      }
    }
    case CourseActionTypes.COURSE_DELETE_SELECTED_APPLICATION: {
      const {apps} = action.payload; 
      return {
        ...state,selectedApplications:apps
      }
    }
    case CourseActionTypes.COURSE_SAVE_SELECTED_APPLICATIONS: {
      const {apps} = action.payload; 
      return {
        ...state,selectedApplications:apps
      }
    }

    case CourseActionTypes.COURSE_SELECT_RESOURCE: {
      const {resource} = action.payload; 
      return {
        ...state,allResources:resource
      }
    }
    case CourseActionTypes.COURSE_DELETE_SELECTED_RESOURCE: {
      const {resource} = action.payload; 
      return {
        ...state,selectedResources:resource
      }
    }
    case CourseActionTypes.COURSE_SAVE_SELECTED_RESOURCES: {
      const {resource} = action.payload; 
      return {
        ...state,selectedResources:resource
      }
    }
    case CourseActionTypes.COURSE_SAVE_COURSE_CREATION_INPUT_FIELDS: {
      const {name,value} = action.payload; 
      return {
        ...state,[name]:value
      }
    }
    case CourseActionTypes.COURSE_GET_CREATED_COURSES: {
      const {content } = action.payload;
      return {
        ...state,availableCourses:content
      }
    }
    case CourseActionTypes.COURSE_GET_SELECTED_COURSEOBJECT: {
      const {content } = action.payload; 
      return {
        ...state,selectedCourse:content
      }
    }
    case CourseActionTypes.COURSE_SET_ARRAY_TO_VARIABLE: {
      const {name,content } = action.payload; 
      return {
        ...state,[name]:content
      }
    }
    case CourseActionTypes.COURSE_EDIT_COURSE_INPUT_FIELDS: {
      const {name,content } = action.payload; 
      return {
        ...state,selectedCourse:{...state.selectedCourse,[name]:content}
      }
    }
    case CourseActionTypes.COURSE_COMPONENT_ELEMENTS: {
      const {name,content } = action.payload; 
      return {
        ...state,[name]:content
      }
    }
    case CourseActionTypes.COURSE_DISCIPLINES: {
      const {content } = action.payload; 
      return {
        ...state,disciplines:content,selectedDiscipline:{_id:'all'},
      }
    }
    case CourseActionTypes.COURSE_CONTENT_TYPES: {
      const {content } = action.payload; 
      return {
        ...state,contentTypes:content,selectedContentType:{_id:'all'},
      }
    }
    case CourseActionTypes.COURSE_FILTERED_ARRAY: {
      const {content } = action.payload; 
      return {
        ...state,filteredArray:content
      }
    }
    case CourseActionTypes.COURSE_RESOURCE_ARRAY: {
      const {content } = action.payload;
      // console.log("content",content)
      return {
        ...state,resourceArray:content
      }
    }
    case CourseActionTypes.COURSE_RESOURCE_ORIGINAL_ARRAY: {
      const {content } = action.payload;
      return {
        ...state,resourceOriginalArray:content
      }
    }
    
    case CourseActionTypes.COURSE_SET_COURSE_ELEMENTS: {
      const {name,value} = action.payload; 
      // console.log("utdrtyuiopouyutyryty",name,value)
      return {
        ...state,[name]:value
      }
    }
    default:
      return state;
  }
}
