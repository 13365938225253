import React, {useState,useEffect } from 'react';
import Ratings from '../../packages/Ratings';
import { connect } from "react-redux";
import * as CourseContainer from "../../container/actions/Courses/action";
import * as userRatingsContainer from "../../container/actions/UserRatings/action";
// import Store from "../../container/reducers/Store";
import * as Actions from "../../container/actions/Courses";
import EditCourse from "./EditCourse";
import { bindActionCreators } from 'redux';
import UserRatings from '../UserRatings/';
import customHttp from '../../packages/CustomHttp';
import snackbar from '../../packages/Snackbar';
import {displayAlert} from "../../container/actions/Courses/action";

function CreateCourse (props) {
  // const [name, SetName] = useState("");
  const [courseData, SetcourseData] = useState({});
  const [userInfo, SetUserInfo] = useState(JSON.parse(sessionStorage.getItem("userData")));
  const [currentTime, setCurrenttime] = useState("");
  const [linkExpMsg, setLinkExpMsg] = useState(false);

  useEffect( () => {
    props.getAllCreatedCourses();
    props.setBooleanValue("showCourseList",true)
  },[]);

  // onload function end
  var hideDeletePopUp=()=>{
    document.getElementById("deleteCoursePopup").style.display='none'
  }
  var handleChangeCreateCourse=()=>{
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    if(userDetails.type===1){
      props.history.push("/resources")
    }else if(userDetails.type===2){
      props.history.push("/dlt/resources")
    }else{
      props.history.push("/sub-admin/resources")
    }

  }
   var getConvertedDate=(date)=>{
     var date = new Date(date)
     var tempDate = date.getDate();
     var tempMonth = date.getMonth()+1;
     var tempYear = date.toString().substring(11,15);
     var fromDate = tempDate + "/" + tempMonth + "/" + tempYear;
     return fromDate;
     // console.log("fromDateInSec",fromDate);

    // var dateArray=date.split("/")
    // const temp = dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2];
    // const dummy = new Date(temp)
    //   return dummy.toString();
  }
  var handleChangeDateFormatinObject=(name,data)=>{
    // console.log("name,data",name,data);
    props.setBooleanValue("editShowSelectedResources",true)
    // var startDate=validateDateToFormat(data.startDate);
    // var endDate=validateDateToFormat(data.endDate);
    // data.startDate=startDate;
    // data.endDate=endDate;
    props.saveSelectedCourseObject(name,data)
  }
   var getRatings=()=>{

   }
   var openRatingsPopup = (data) =>{
    props.showUserRatings(data,"course");
   }
   var downloadQuizeFile=(obj)=>{
     for(var i=0;i<obj.resources.length;i++){
       if(obj.resources[i].type !==4){
        obj.resources.splice(i,1);
        i--;
       }

       if(obj.resources.length-1===i){
        props.setBooleanValue("playListArrayForDownload",obj)
        document.getElementById("downloadPopup").style.display='block'
       }
     }
     if(obj.resources.length<=0){
      props.setBooleanValue("playListArrayForDownload",{})
      document.getElementById("downloadPopup").style.display='block'
     }
   }

   var openURLPopUP = (data) => {
     const currentDate = new Date();
     setLinkExpMsg(false)
     setCurrenttime(currentDate.getTime())
     var coursedataForUrl = JSON.parse(JSON.stringify(data))
     SetcourseData(coursedataForUrl);
    document.getElementById('urlPopUp').style.display="block";
    // console.log("courseDataaa",courseData)
   }

   var goToGargiKnowledgeBase = (data) => {
    sessionStorage.setItem('kbCourse',JSON.stringify(data));
    props.history.push('/sub-admin/gargi-knowledge-base')
  }

   var copyLink=()=>{
    setLinkExpMsg(true)
    var copyText = document.getElementById("copyText");
    copyText.select();
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy");
  }
  var goToLiveClassroom=async(obj)=>{
    //localStorage.setItem('liveCourse',JSON.stringify(obj))
    // var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    //   if(userDetails.type===1){
    //       props.history.push("/live-stream")
    //     }else if(userDetails.type===2){
    //       props.history.push("/dlt/live-stream")
    //     }else if(userDetails.type===0){
    //       props.history.push("/user/live-stream")
    //   }else{
    //     props.history.push("/sub-admin/live-stream")
    //   } 
    //zoom
    //to avoid popups getting blocked since we need to open url on ajax call..
    var importantStuff = window.open('', '_blank'); //imediately create a blank page
    importantStuff.document.write('Connecting...'); // add some text to the window opened
    var userDetails=JSON.parse(sessionStorage.getItem("userData"));
    try {
      var response = await customHttp.put('/api/liveStream/meeting',{userId:userDetails._id,courseId:obj._id});
      if(response.data.message){
        var meetingId = response.data.message.id;
        var password = response.data.message.password;
        importantStuff.location.href = `${'https://platifivccloud.platifi.com'}/host/${meetingId}/${password}`; //filling it with content when ready
      }
    } catch(err){
      console.log("props",props);
      importantStuff.close();
      props.displayAlert("error","All classrooms are occupied. Please try after sometime..");
    }
  }
  var showSettingsPage=()=>{
    props.history.push("/sub-admin/settings")
  }

    return (
      
      <div className="add-on-load-animation">
        <div id="successSnackbar">{props.courseState.successSnackbarMessage}</div>
        <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.courseState.errorSnackbarMessage}</div>
        {props.courseState.showCourseList ?
        <div>

          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 nj-card course-available-column">

            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4 className="font_family_montserrat">Available Courses</h4>
                </div>
                <div style={{paddingBottom:'9px'}}>
                  <button className="nj-flow-button " type="button" onClick={handleChangeCreateCourse}>Create</button>
                  <button  type="button"  className="nj-flow-button pull-right" onClick={showSettingsPage} style={{float:"none",marginLeft:"5px"}}>Settings</button>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              {!props.courseState.availableCourseLoader?
              <div className="course-table-margin-top">
                { props.courseState.availableCourses!== undefined && props.courseState.availableCourses.length>0 ?
                <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"76.3vh"}}>
                  <table className="nj-table nj-table--tbody-table font_family_montserrat">
                    <thead className="nj-table--thead">
                      <tr>
                        <th>
                          <div className="nj-table-text" style={{minWidth:"82px"}}>SL NO.</div>
                        </th>
                        <th>
                          <div className="nj-table-text" style={{minWidth:"135px"}}>COURSE NAME</div>
                        </th>
                        <th>
                          <div className="nj-table-text" style={{minWidth:"116px"}}>FROM DATE</div>
                        </th> 
                        <th>
                          <div className="nj-table-text">TO DATE</div>
                        </th>
                        <th>
                          <div className="nj-table-text" style={{minWidth:"133px"}}>COURSE CODE</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.courseState.availableCourses.map((data,i)=>{
                        return (<tr key={i}  className="tr-values nj-table--tr tr-table-state-null" onClick={(e)=>props.getFirstValue(data)} style={(props.courseState.createdCourseArrayFirstObject._id === data._id ? {backgroundColor: "#f9f9f9"}:{backgroundColor: ""})}>
                          
                            <td className="td nj-table--td">{i+1}</td>
                            <td className="td nj-table--td">{data.name}</td>
                            <td className="td nj-table--td">{getConvertedDate(data.startDate)}</td>
                            <td className="td nj-table--td">{getConvertedDate(data.endDate)}</td>
                            <td className="td nj-table--td">{data.courseCode}</td>
                          
                        </tr>)
                      })}
                    </tbody>
                  </table>
                </div>
                :
                <div className="col-md-12 idams_noDataFound" style={{height:"76.3vh"}}>
                  <span className="font_family_montserrat">No data found</span>
                </div>
                }
              </div>
              :
              <div className="row" style={{height:"76.3vh"}}>
                <div className="col-md-12 aligh-loader-image-center" >
                  <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                </div>
              </div>
              }
            </div>

          </div>

          <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 nj-card action-column" style={{overflowY:"auto",overflowX:"auto",maxHeight:"91vh"}}>
            {props.courseState.showOnApiResponse ? 
            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4 className="font_family_montserrat" style={{textTransform:'capitalize'}}>{props.courseState.createdCourseArrayFirstObject.name}</h4>
                </div>
              </div>
            </div>
            : 
            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4 className="font_family_montserrat" style={{textTransform:'capitalize'}}>Course name</h4>
                </div>
              </div>
            </div>
            }
            

            <div className="col-md-12">
              {!props.courseState.availableCourseLoader?
              <div className="course-table-margin-top">
                { props.courseState.availableCourses!== undefined && props.courseState.availableCourses.length>0 ?
                <div className="row roles-table-height">

                  <div className="usersHoursRatings">
                    <div>
                      <span style={{fontWeight:700}}>No. OF USERS:</span>
                      <div style={{padding:'0px 5px',textAlign:'center'}}>{props.courseState.createdCourseArrayFirstObject.numberOfUsers}</div>
                    </div>
                    <div>
                      <span style={{fontWeight:700}}>SLOT HOURS:</span>
                      <div style={{padding:'0px 5px',textAlign:'center'}}>{props.courseState.createdCourseArrayFirstObject.slotHours}</div>
                    </div>
                    <div>
                      <span style={{fontWeight:700}}>RATINGS:</span>
                      <span>
                        <div style={{marginTop:'-3px',textAlign:'center'}} onClick={()=>openRatingsPopup(props.courseState.createdCourseArrayFirstObject)}>
                          <p style={{margin:'0 0 -10px 0',fontWeight:'500',color:'#555'}}>{parseFloat(parseFloat(props.courseState.createdCourseArrayFirstObject.average).toPrecision(2))}</p>
                          <Ratings classname="user-ratings" color="rgba(115,103,240)" getRatings={getRatings} height="11" width="11" rating={props.courseState.createdCourseArrayFirstObject.average>0? Math.round(props.courseState.createdCourseArrayFirstObject.average):props.courseState.createdCourseArrayFirstObject.average} disable={true} />
                        </div>
                      </span>
                    </div>
                  </div>
                  
                  <div className="row course_actions">
                  <h4 className="font_family_montserrat" style={{marginLeft:20,fontWeight:600}}>Actions:</h4>
                  <div className="course_actions_cards">
                    {props.courseState.createdCourseArrayFirstObject.isLiveClassRoom &&
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 0px 10px 2px',cursor: 'pointer'}} onClick={(e)=>{goToLiveClassroom(props.courseState.createdCourseArrayFirstObject)}}>
                        <img className="" src="/img/live.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                        <p className="app_displayname font_family_montserrat">Go Live</p>
                      </div>
                    </div>}
                    {props.courseState.createdCourseArrayFirstObject.isLiveClassRoom ? 
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 0px 10px 2px',cursor: 'pointer'}} onClick={()=>goToGargiKnowledgeBase(props.courseState.createdCourseArrayFirstObject)}>
                        <img className="" src="/img/gargikb.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5,padding:2}} />
                        <p className="app_displayname font_family_montserrat">Gargi KB</p>
                      </div>
                    </div>
                    : 
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 0px 10px 2px',cursor: 'pointer'}} onClick={()=>goToGargiKnowledgeBase(props.courseState.createdCourseArrayFirstObject)}>
                        <img className="" src="/img/gargikb.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5,padding:2}} />
                        <p className="app_displayname font_family_montserrat">Gargi KB</p>
                      </div>
                    </div>
                    }
                    {!props.courseState.createdCourseArrayFirstObject.isUserSlotBooking && 
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 0px 10px 2px',cursor: 'pointer'}} onClick={()=>{sessionStorage.setItem('adminSlotBookingCourse',JSON.stringify(props.courseState.createdCourseArrayFirstObject));props.history.push('book-slot-users')}}>
                        <img className="" src="/img/booking.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}} />
                        <p className="app_displayname font_family_montserrat">Book Slot</p>
                      </div>
                    </div>}
                    {!props.courseState.createdCourseArrayFirstObject.isUserSlotBooking ? 
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 0px 10px 2px',cursor: 'pointer'}} onClick={()=>openURLPopUP(props.courseState.createdCourseArrayFirstObject)}>
                        <img className="" src="/img/url.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5,padding:2}} />
                        <p className="app_displayname font_family_montserrat">Url</p>
                      </div>
                    </div>
                    : 
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 0px 10px 2px',cursor: 'pointer'}} onClick={()=>openURLPopUP(props.courseState.createdCourseArrayFirstObject)}>
                        <img className="" src="/img/url.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5,padding:2}} />
                        <p className="app_displayname font_family_montserrat">Url</p>
                      </div>
                    </div>
                    }
                    {!props.courseState.createdCourseArrayFirstObject.isUserSlotBooking ? 
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 0px 10px 2px',cursor: 'pointer'}} onClick={(e)=>{props.setBooleanValue("showCourseList",false);handleChangeDateFormatinObject("selectedCourse",props.courseState.createdCourseArrayFirstObject)}}>
                        <img className="" src="/img/edit.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5,padding:2}} />
                        <p className="app_displayname font_family_montserrat">Edit</p>
                      </div>
                    </div>
                    : 
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 0px 10px 2px',cursor: 'pointer'}} onClick={(e)=>{props.setBooleanValue("showCourseList",false);handleChangeDateFormatinObject("selectedCourse",props.courseState.createdCourseArrayFirstObject)}}>
                        <img className="" src="/img/edit.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5,padding:2}} />
                        <p className="app_displayname font_family_montserrat">Edit</p>
                      </div>
                    </div>
                    }
                    {!props.courseState.createdCourseArrayFirstObject.isUserSlotBooking && props.courseState.createdCourseArrayFirstObject.isLiveClassRoom ? 
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 0px 10px 2px',cursor: 'pointer'}} onClick={(e)=>{downloadQuizeFile(props.courseState.createdCourseArrayFirstObject)}}>
                        <img className="" src="/img/quizreport.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}} />
                        <p className="app_displayname font_family_montserrat">Quiz Report</p>
                      </div>
                    </div>
                    : 
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 0px 10px 2px',cursor: 'pointer'}} onClick={(e)=>{downloadQuizeFile(props.courseState.createdCourseArrayFirstObject)}}>
                        <img className="" src="/img/quizreport.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}} />
                        <p className="app_displayname font_family_montserrat">Quiz Report</p>
                      </div>
                    </div>
                    }
                    {!props.courseState.createdCourseArrayFirstObject.isUserSlotBooking && props.courseState.createdCourseArrayFirstObject.isLiveClassRoom ? 
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 0px 10px 2px',cursor: 'pointer'}} onClick={()=>{props.getSelectedCourse("deleteCoursePopup",props.courseState.createdCourseArrayFirstObject);props.animationFunctionForPopup()}}>
                        <img className="" src="/img/delete.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}} />
                        <p className="app_displayname font_family_montserrat">Delete</p>
                      </div>
                    </div>
                    : 
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 0px 10px 2px',cursor: 'pointer'}} onClick={()=>{props.getSelectedCourse("deleteCoursePopup",props.courseState.createdCourseArrayFirstObject);props.animationFunctionForPopup()}}>
                        <img className="" src="/img/delete.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}} />
                        <p className="app_displayname font_family_montserrat">Delete</p>
                      </div>
                    </div>
                    }
                  </div>
                  </div>

                </div>
                :
                <div className="col-md-12 idams_noDataFound" style={{height:"76.3vh"}}>
                  <span className="font_family_montserrat">No data found</span>
                </div>
                }
              </div>
              :
              <div className="row" style={{height:"76.3vh"}}>
                <div className="col-md-12 aligh-loader-image-center" >
                  <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                </div>
              </div>
              }
            </div>

          </div>
          
        </div>
        :
          <EditCourse />
        }

        {/*<div className="col-md-12">
          <div className="row">
            {props.courseState.showCourseList ?
            <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
              <div className="col-md-12 col-sm-12 nj-card" >
                <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                  <div className="nj-card__header">
                    <div className="nj-card__title">
                      <h4 className="font_family_montserrat">Available Courses</h4>
                    </div>
                    <div style={{paddingBottom:'9px'}}>
                      <button className="nj-flow-button " type="button" onClick={handleChangeCreateCourse}>Create</button>
                      <button  type="button"  className="nj-flow-button pull-right" onClick={showSettingsPage} style={{float:"none",marginLeft:"5px"}}>Settings</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  {!props.courseState.availableCourseLoader?
                  <div className="course-table-margin-top">
                    { props.courseState.availableCourses!== undefined && props.courseState.availableCourses.length>0 ?
                    <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"76.3vh"}}>
                      <table className="nj-table nj-table--tbody-table font_family_montserrat">
                        <thead className="nj-table--thead">
                          <tr>
                            <th>
                              <div className="nj-table-text" style={{minWidth:"82px"}}>SL NO.</div>
                            </th>
                            <th>
                              <div className="nj-table-text" style={{minWidth:"135px"}}>COURSE NAME</div>
                            </th>
                            <th>
                              <div className="nj-table-text" style={{minWidth:"116px"}}>FROM DATE</div>
                            </th> 
                            <th>
                              <div className="nj-table-text">TO DATE</div>
                            </th>
                            <th>
                              <div className="nj-table-text" style={{minWidth:"133px"}}>COURSE CODE</div>
                            </th>
                            {/* <th>
                              <div className="nj-table-text" style={{minWidth:"122px"}}>SLOT HOURS</div>
                          </th> *
                            <th>
                              <div className="nj-table-text" style={{minWidth:"129px"}}>No. OF USERS</div>
                            </th>
                            <th style={{textAlign:"center"}}>
                              <div className="nj-table-text" style={{minWidth:"115px"}}>RATINGS</div>
                            </th>
                            <th style={{textAlign:"center",minWidth:"331px"}}>
                              <div className="nj-table-text">ACTIONS</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.courseState.availableCourses.map((data,i)=>{
                            return (<tr key={i}  className="tr-values nj-table--tr tr-table-state-null ">
                              <td className="td nj-table--td">{i+1}</td>
                              <td className="td nj-table--td">{data.name}</td>
                              <td className="td nj-table--td">{getConvertedDate(data.startDate)}</td>
                              <td className="td nj-table--td">{getConvertedDate(data.endDate)}</td>
                              <td className="td nj-table--td">{data.courseCode}</td>
                              {/* <td className="td nj-table--td">{data.slotHours}</td> *
                              <td className="td nj-table--td">{data.numberOfUsers}</td>
                              <td className="td nj-table--td">
                                <div style={{marginTop:'-5px',textAlign:'center'}} onClick={()=>openRatingsPopup(data)}>
                                  <p style={{margin:'0 0 -10px 0',fontWeight:'500',color:'#555'}}>{parseFloat(parseFloat(data.average).toPrecision(2))}</p>
                                  <Ratings classname="user-ratings" color="rgba(115,103,240)" getRatings={getRatings} height="11" width="11" rating={data.average>0? Math.round(data.average):data.average} disable={true} />
                                </div>
                              </td>
                              {/* <td className="td nj-table--td">{data.name}</td>  *
                              <td className="td nj-table--td nj-padding-10" style={{minWidth:'730px'}}>
                                {data.isLiveClassRoom && (<button  type="button"  className="nj-flow-button addRoles_button" onClick={(e)=>{goToLiveClassroom(data)}} style={{float:"none",marginTop:"5px",marginRight:"5px",fontWeight:'600',backgroundColor:'rgba(92, 200, 112,1)',boxShadow:'0 0 0px rgba(255, 64, 66,.1), 0px 1px 10px rgba(92, 200, 112,.5)'}}>Go Live</button>)}
                                  <button  type="button"  className="nj-flow-button" style={{float:"none"}} title="Knowledge Based" onClick={()=>goToGargiKnowledgeBase(data)}>Gargi KB</button>
                                  {!data.isUserSlotBooking && <button  type="button"  className="nj-flow-button" style={{float:"none",marginLeft:5}} title="Book Slot" onClick={()=>{sessionStorage.setItem('adminSlotBookingCourse',JSON.stringify(data));props.history.push('book-slot-users')}}>Book Slot</button>}
                                  <button  type="button"  className="nj-flow-button" style={{float:"none",marginLeft:"5px"}} onClick={()=>openURLPopUP(data)}>URL</button>
                                  <button  type="button"  className="nj-flow-button" style={{float:"none",marginLeft:"5px"}} onClick={(e)=>{props.setBooleanValue("showCourseList",false);handleChangeDateFormatinObject("selectedCourse",data)}}>Edit</button>
                                  <button  type="button"  className="nj-flow-button" onClick={(e)=>{downloadQuizeFile(data)}} style={{float:"none",marginLeft:"5px"}}>Quiz Report</button>
                                  <button  type="button"  className="nj-flow-button bg-color-red addRoles_button" onClick={()=>{props.getSelectedCourse("deleteCoursePopup",data);props.animationFunctionForPopup()}} style={{float:"none",marginTop:"5px",marginLeft:"5px"}}>Delete</button>
                              </td>
                            </tr>)
                          })}
                        </tbody>
                      </table>
                   </div>
                   :
                   <div className="col-md-12 idams_noDataFound" style={{height:"76.3vh"}}>
                     <span className="font_family_montserrat">No data found</span>
                   </div>
                    }
                  </div>
                  :
                  <div className="row" style={{height:"76.3vh"}}>
                    <div className="col-md-12 aligh-loader-image-center" >
                      <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
            :
            <EditCourse />
            }
          </div>
        </div>*/}
        {/*popup of user ratings */}
        <div id="userRatingsPopup" className={"nj-overly add-rebound-animation"}>
          <div className="popup-nj  my-popup" style={{maxWidth:450}}>
            <div className="popup-content" style={{padding:0}}>
              <UserRatings />
            </div>
          </div>
        </div>
         {/*popup to delete course*/}
         <div id="deleteCoursePopup" className={"nj-overly "+(props.courseState.shouldPopupAnimate ? "add-rebound-animation" : "no-animation")}>
            <div className="popup-nj my-popup">
              <header className="popup-header">
                <span>Delete Course</span>
                <span onClick={hideDeletePopUp} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
              </header>
              <div className="popup-content">
                <div className="col-md-12">
                  <form name="roleForm">
                    <div className="nj-popup-form-wrap">
                      <p className="popup-content-label font_family_montserrat" style={{fontSize:"16px"}}>Do you really want to delete?</p>
                    </div>
                      {props.courseState.disableCourseDelete ? <button type="submit" className="nj-flow-button pull-right" style={{margin:5}} onClick={()=>{props.deleteCourse("deleteCoursePopup")}}>Confirm</button>:<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                    <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={hideDeletePopUp}  style={{margin:5}}>Cancel</button></div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* pop for download */}
          <div id="downloadPopup" className={"nj-overly add-rebound-animation"} >
        <div className="popup-nj my-popup">
          <header className="popup-header">
          <span>Quiz Report</span>
            <span onClick={()=>{document.getElementById('downloadPopup').style.display='none'}}><i className="popup-close material-icons">close</i></span>
            </header>
            {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}

              <div className="popup-content-requested-content-view" style={{maxHeight:"400px",maxWidth:"500px"}}>
                  <div className="col-md-12">
                    {Object.keys(props.courseState.playListArrayForDownload).length <= 0 ?
                      <p className="no-data-found" >No Data Found</p>
                      :
                    <div>
                      {props.courseState.playListArrayForDownload.resources.length>0 ?
                  <div>
                    {props.courseState.playListArrayForDownload.resources.map((item,index)=>{
                      //  console.log("item",item)
                    return (
                    
                    <div className="file " key={index} style={{marginBottom:"10px"}} onClick={()=>{props.downloadFile(item)}}>
                      <div className="col-md-2 col-xs-2 col-sm-2">
                          <div className="resource_card-content-img-wrapper" style={{display:"inline"}} >
                              <span style={{color:"#675cd7"}}>
                               {/* <span><img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource" style={{paddingRight:"10px",paddingTop:"5px"}}/></span> */}
                               <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-download" style={{marginTop:7}}>
                                 <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="7 10 12 15 17 10" />
                                 <line x1={12} y1={15} x2={12} y2={3} />
                              </svg>
                            </span>
                          </div>
                        </div>
                         <div className=" col-md-10  col-xs-10 col-sm-10 text" style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}} >
                        <span >{item.displayName}</span>
                           </div>
                         </div>
                        )})}
                    </div>
                    : <p className="no-data-found" >No Data Found</p>
                    }
                    </div>
                    }
                    </div>
                  </div>
                </div>
              </div>

              {/**URL pop up */}

              
      <div id="urlPopUp" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup" style={{maxWidth:"400px",marginTop:"100px"}}>
          <header className="popup-header">
            <span>URL</span>
            <span onClick={()=>{document.getElementById('urlPopUp').style.display='none'}}><i className="popup-close material-icons">close</i></span>
          </header>          
            <div className="popup-content" style={{overflow:"hidden"}}>         
            {(Object.keys(courseData).length>0 && Object.keys(userInfo).length>0) && (
            <div className="nj-popup-form-wrap input-containerForCopyText" /* key={courseData._id} */  key={currentTime}>   
                 <input className="vs-input--input" type="text" name="name" id="copyText" style={{borderBottomRightRadius:0,borderTopRightRadius:0}} defaultValue={window.location.origin+'/shared/course/'+courseData._id+'/'+userInfo._id+'/'+userInfo._id+'/'+userInfo.belongsTo+'/'+ currentTime}  autoComplete="off"/>
                  <span className="copyButton" title="Copy to clipboard" onClick={copyLink}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-copy" style={{marginTop:8,cursor:"pointer"}}>
                      <rect x={9} y={9} width={13} height={13} rx={2} ry={2} />
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                    </svg>                  
                    </span>   
              </div> )}
              {linkExpMsg && <span className="required_field_color_red font_family_montserrat" style={{fontWeight:'500',fontSize:'12px',marginLeft:"20px"}}>Note: copied link will expire in 24 hours.</span>}
              <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={()=>{document.getElementById('urlPopUp').style.display='none'}} style={{margin:5,marginRight:10}}>Cancel</button></div>                      
           </div>
        </div>
      </div>

      </div>
    );
  }


const mapStateToProps = state => ({
  courseState: state.courseState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllApplications:CourseContainer.getAllApplications,
  setBooleanValue:Actions.setBooleanValue,
  selectApplication:CourseContainer.selectApplication,
  deleteSelectedApplication:CourseContainer.deleteSelectedApplication,
  getSelectedApplications:CourseContainer.getSelectedApplications,
  clearSelectApplication:CourseContainer.clearSelectApplication,
  selectResource:CourseContainer.selectResource,
  deleteSelectedResource:CourseContainer.deleteSelectedResource,
  getSelectedResources:CourseContainer.getSelectedResources,
  clearSelectResource:CourseContainer.clearSelectResource,
  handleChangeCourseCreationInputFields:Actions.handleChangeCourseCreationInputFields,
  createCourse:CourseContainer.createCourse ,
  getAllCreatedCourses:CourseContainer.getAllCreatedCourses,
  animationFunctionForPopup:CourseContainer.animationFunctionForPopup,
  getSelectedCourse:CourseContainer.getSelectedCourse,
  deleteCourse:CourseContainer.deleteCourse,
  saveSelectedCourseObject:Actions.initiateOnloadFunction,
  showUserRatings: userRatingsContainer.showUserRatings,
  showUserReviews: userRatingsContainer.showUserReviews,
  downloadFile:CourseContainer.downloadFile,
  getFirstValue:CourseContainer.getFirstValue,
  displayAlert
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(CreateCourse);
