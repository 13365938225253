import React, { useEffect } from 'react';
import { connect } from "react-redux";
import "./Appstore.scss";
import * as AppStoreContainer from "../../container/actions/Appstore/action";
// import Store from "../../container/reducers/Store";
import { bindActionCreators } from 'redux';
// import axios from "axios";

function AppStore (props) {
    useEffect( () => {   
    props.getAllApplications()
    },[]);
  
        return (
          <div className="add-on-load-animation">
            <div id="success_Snackbar">{props.AppStore.message}</div>
            <div id="error_Snackbar"><i className="fa fa-exclamation"></i>{props.AppStore.message}</div>
            {/* {console.log("my Appstore",props.AppStore)} */}
            {
            props.AppStore.application_loader===true?
          <div className="col-md-12 nj-card app-margin">        
            <div className="row margin_top_10">
              <div className="col-md-12">
               
                  <div>{props.AppStore.showAllApps?
                     <div className="col-md-4 selectall-margin">
                     <label className="checkbox coloured font_family_montserrat font_wight_500" style={props.AppStore.categoryName=== "All" &&  props.AppStore.applicationName.length===0 &&  props.AppStore.applicationArray.length>0 ? {display:"block"} :{display:"none"}}>
                       <input type="checkbox" indeterminate={(props.AppStore.selected.length > 0 && props.AppStore.selected.length < props.AppStore.applicationArray.length).toString()} checked={props.AppStore.selected.length === props.AppStore.applicationArray.length}  onChange={(event)=>props.handleSelectAllClick(event)} />
                        <span className="checkbox-material"><span className="check"></span></span> Select All
                     </label>
                     </div>
                     :
                     <div>
                        <div className="col-md-4 dropdown-top" >
                          <div className="nj-form-select dropdown-bottom">
                            <select className="form-control pull-left font_family_montserrat vs-input--input padding_5" onChange={(event)=>{props.handleChangeCategoryName(event,props.AppStore.categoryName,props.AppStore.applicationName,props.AppStore.applicationArray,props.AppStore.applicationList)}} style={{border:'1px solid rgb(204, 204, 204)'}}>
                            {
                              props.AppStore.iotFilterArray.map((msgTemplate,i) => {
                              return (
                             <option key={i} value={msgTemplate.name}>
                             {msgTemplate.name}
                            </option>
                            )
                           })
                            }
                          </select>
                        </div>
                     </div>
                  </div>}
                </div>
                
                <div className="col-md-4">
                  <div className="nj-form-select dropdown-bottom">
                  <select className="form-control pull-left font_family_montserrat vs-input--input padding_5" onChange={(event)=>{props.handleChangeCategoryName(event)}} style={{border:'1px solid rgb(204, 204, 204)'}}>
                  {
                    props.AppStore.filterArray.map((msgTemplate,i) => {
                      return (
                        <option key={i} value={msgTemplate.name}>
                          {msgTemplate.name}
                        </option>
                      )
                    })
                  }
                  </select>
                  </div>
                </div>
                <div className="col-md-4">
                    <input type="text" 
                      className="pull-left font_family_montserrat vs-input--input padding_5" 
                      name="applicationName" 
                      value={props.AppStore.applicationName} 
                      onChange={(event)=>props.handleChangeApplicationName(event)} 
                      placeholder="Search by name"    
                      required />
                </div>
              </div>
            </div><br />
          
            <div>
              {
              props.AppStore.applicationArray.length>0? 
                <div className="tbl-content col-md-12 col-sm-12 apps_div_height">
                  { props.AppStore.apps_filter_loader===true ?
                  <div className="table apps_alignment">
                    {props.AppStore.applicationArray.map((item, i) => {
                      // const isSelected = this.isSelected(item);
                      return (
                        <div className="col-lg-2 col-sm-3 app_container padding_top_10 add-rebound-animation" key={i}>
                          <div className="row apps_card margin_left_0 app-card-width" style={{position:"relative",overflow:"hidden"}}>
                            <div className="apps_card-content" >
                            {item.license==="premium"&&
                            <div className="border-ribbon"  >Premium</div>} 
                              <span className="overlay_checkbox ">
                                <label className="checkbox coloured" >
                                  <input type="checkbox" checked={item.isSelected} onChange={event => props.selectApplication(item)}/><span className="checkbox-material"><span className="check"></span></span> 
                                </label>
                              </span>
                             
                              <div className="col-md-12 col-xs-12 col-sm-12 catalogimage" onClick={event => props.selectApplication(item)}>
                                <img className="lazyImageTag" data-src={"/img/application-icons/"+item.name+".png"}  src="/img/logos/platifilogo.ico" height={50} width={50} alt="loader" />
                              </div>                        
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12 apps_card-footer" onClick={event => props.selectApplication(item)} >
                              <p className="app_displayname font_family_montserrat">{item.displayName}</p>
                            </div>
                          </div><br/>
                        </div>
                      );
                    })}
                  </div> 
                  : 
                  <div className="col-md-12  apps_loader_style">
                    <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" className="loader_img aligh_center_middle"/> 
                  </div>
                  }
                </div> 
                :
                <div className="row">
                  <div className="col-md-12 vertically_middle font_family_montserrat no_data_availbale_div">
                    <div className="aligh_center_middle">No application available</div>
                  </div> 
                </div>
                }           
              </div>
         
            
              
                <div className="row">
                  {
                    props.AppStore.applicationArray.length>0? 
                  <div className="col-md-12 apps_button_center">
                    {/*<button  type="submit" className="btn add-button addApplication_button pull-right" style={{color: "white"}} onClick={this.getAllSelectedApplications}>Submit </button>*/}
                    <button className="nj-flow-button pull-right addApplication_button app-store-submit" onClick={()=>props.getAllSelectedApplications(props)}>Submit</button>
                    <button className="nj-flow-button pull-right bg-color-red" style={{marginRight:"10px"}} type="button" onClick={()=>props.skipAppStore(props.history)}>Skip</button>
                  </div> 
                  :""
                  }
                </div>          
          </div>
          :
            <div className="col-md-12 apps_loader_style_maindiv font_family_montserrat">
              <div className="row">
                <div className="col-md-12 nj-card apps_loader_style loader_div_height">
                  <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" className="loader_img aligh_center_middle"/> 
                </div>
              </div>
            </div> 
            }
          </div>
     
    
      
      );
    }
    
  const mapStateToProps = state => ({
    AppStore: state.appstore
  });
  
  const mapDispatchToProps = dispatch => bindActionCreators({
    getAllApplications:AppStoreContainer.getAllApplications,
    handleSelectAllClick:AppStoreContainer.handleSelectAllClick,
    handleChangeCategoryName:AppStoreContainer.handleChangeCategoryName,
    handleChangeApplicationName:AppStoreContainer.handleChangeApplicationName,
    selectApplication:AppStoreContainer.selectApplication,
    getAllSelectedApplications:AppStoreContainer.getAllSelectedApplications,
    skipAppStore:AppStoreContainer.skipAppStore
  }, dispatch)
  
  export default connect(
    mapStateToProps,mapDispatchToProps
  )(AppStore);
  