import React, { Component, Fragment } from 'react';
import './Analytics.scss';
// import { connect } from "react-redux";
// import * as AnalyticsContainer from "../../container/actions/Analytics/action";
// import Store from "../../container/reducers/Store";
// import { bindActionCreators } from 'redux';
import DatePicker from 'react-date-picker';
import 'react-datepicker/dist/react-datepicker.css';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import ResourceUsageByCourse from './resourceUsageByCourse';
// import ResourceTotalviewCount from './resourceTotalviewCount';
// import ResourceTotalHourseSpent from './resourceTotalHoursSpent';
import CourseRatings from './courseRatings';
import VideoResourceUsage from './videoUsage';
import CourseAnalytics from './CourseAnalytics/BookedVsUsedSlots';
// import ResourceRatings from './resourceRatings';
import ResourceUsageByCourseVideo from './resourceUsageByCourseVideo'
// import axios from "axios";
import customHttp from '../../packages/CustomHttp';
import {Chart } from 'react-chartjs-2';

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.childDoc = React.createRef();
    this.childVideo = React.createRef();
    this.childCourseUsage = React.createRef();
    this.videoViewCountRef = React.createRef();
    this.state = {
      allCourses:[],
      selectedCourseId:"",
      totalRating: 0,
      demoResourceArray:[{displayName: "lean supply chain management",resourceId: "5c15f3907257166d93c74bb2",resourceViewCount: '14'},
      {displayName: "slot cancellation",resourceId: "5c15f4677257166d93c74cb3",resourceViewCount: '11'},
      {displayName: "how to book a slot",resourceId: "5c15f40c7257166d93c74c4f",resourceViewCount: '9'},
      {displayName: "The Management Of Operations",resourceId: "5c15f3907257166d93c74r4",resourceViewCount: '7'},
      {displayName: "how to access lab",resourceId: "5c15f3907257166d453c74bb2",resourceViewCount: '5'}],
      demoAndActualArray:["Demo","Actual"],
      demoAndActualValue:"Demo",
      dropdownArray: ["Top Five Resource Usage", "Top Five Video Resource Usage", "Resources Usage By Course", "Resources Usage By Course Videos","Video Resource Usage","Resource Ratings"],
      startDate:"",
      endDate:"",
      dropdownValue:"",
      resourceObject:{},
      resourcesArrayIndex:[],
      resourceRatings:[],
      tempooraryVar:"",
      topFiveResourcesViewCount:[],
      topFiveVideoCount:[],
      show_Analytics_Loader:true,
      options1: {
        cutoutPercentage: 80,
        centertext: "123",
        legend: {
            "display": false,
              "position": "top",
              "fullWidth": true,
              "reverse": false,
              "responsive": true,
              "maintainAspectRatio": true,
              "hoverBackgroundColor": false,
            },
            tooltips: {
                mode: 'label',
                callbacks: {
                    label: function(tooltipItem, data) {
                        var indice = tooltipItem.index;
                        return  data.labels[indice] +' : '+data.datasets[0].data[indice] // + 'hi';
                    }
                }
            }
        },
        graphDataPieResource:{
          labels: ['1 Star', '2 Star', '3 Star', '4 Star', '5 Star'],
          datasets: [
            {
              label: "Ratings",
              backgroundColor: ["#c96734", "#945049", "#5e3d5f", "#6f5d7d", "#8da1bc"],
              data: [],
              color: "transparent",
              borderWidth: 8,
              fill: false,
              lineTension: 0.1,
              borderColor: "transparent",
              borderCapStyle: 'butt',
              borderDash: ["#c96734", "#945049", "#5e3d5f", "#6f5d7d", "#8da1bc"],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: [],
              pointBackgroundColor: '#fff',
              pointBorderWidth: 4,
              pointHoverRadius: 6,
              pointHoverBorderWidth: 1,
              pointRadius: 5,
              pointHitRadius: 1,
              pointHoverBackgroundColor: ["#c96734", "#945049", "#5e3d5f", "#6f5d7d", "#8da1bc"],
              pointHoverBorderColor: ["#c96734", "#945049", "#5e3d5f", "#6f5d7d", "#8da1bc"],
            }
          ],  
          text: 0
        },
        graphDataLineTopFiveVideo:{
          labels: [],
          datasets: [
            {
              label: 'Time Spent On Resource By Users',
              data: [],
              color: "transparent",
              borderColor: "#eceff1",
              borderWidth: 1,
              fill: false,
              backgroundColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],

              // lineTension: 0.1,
              borderColor: "black",
              // borderCapStyle: 'butt',
              // borderDash: [],
              // borderDashOffset: 0.0,
              // borderJoinStyle: 'miter',
              pointBorderColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
              // pointBackgroundColor: '#fff',
              pointBorderWidth: 7,
              pointHoverBorderWidth: 7,
              pointRadius: 1,
              pointHitRadius: 1,
              pointHoverRadius: 1,
              pointHoverBackgroundColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
              pointHoverBorderColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"]

            }
          ]
        },
        graphDataBar:{
          labels: [],
          datasets: [
            {
              label: "Resource Count",
              color: "transparent",
              fill: false,
              backgroundColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
              data: []

            }
          ]
        },
        graphData:{
          labels: [],
          datasets: [
            {
              label: 'Video Views By Users',
              color: "transparent",
              borderWidth: 1,
              fill: false,
              backgroundColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
              data: [],
              pointBorderWidth: 4,
              pointHoverRadius: 6,
              pointHoverBorderWidth: 1,
              pointRadius: 5,
              pointHitRadius: 10,
              pointBorderColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
              pointHoverBackgroundColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
              pointHoverBorderColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
              lineTension: 0.1,
              borderColor: "black",
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBackgroundColor: '#fff'

            }
          ]
        
        },
        graphOptions:{},
        options: {
          legend: {
            "display": false,
              "position": "top",
              "fullWidth": true,
              "reverse": false,
              "responsive": true,
              "maintainAspectRatio": true,
              "hoverBackgroundColor": false,
            },
            tooltips: {
              "enabled": true,
              callbacks: {
                  label: function (tooltipItems, data) {
                      return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
                  }
              }
            },
            scales: {
              //barValueSpacing: 20,
              yAxes: [{
                        ticks: {
                            min: 0
                        }
                    }],
                xAxes: [{
                  barPercentage: .6,
                  categoryPercentage: 0.35,
                }]
            }
        },
       

    }
  }

  componentDidMount() {
    // "Course Analytics"
    var t = new Date();
    this.setState({endDate:t})
    // console.log("to date",t);
    var p = new Date();
    var dateInSec = p.getTime();
    var d = dateInSec-6.048e+8;
    var fromDateInSec = new Date(d);
    var tempDate = fromDateInSec.getDate();
    var tempMonth = fromDateInSec.getMonth()+1;
    var tempYear = fromDateInSec.toString().substring(11,15);
    var fromDate = tempMonth + "/" + tempDate + "/" + tempYear;   // date conversion dd/mm/yyyy
    // console.log("from date",fromDate);
    this.setState({startDate:new Date(fromDate),dropdownValue:this.state.dropdownArray[0]},()=>{this.fetchAllResources();this.onHandleChangeFunction();this.graph();this.fetchAllCourse()})
    if(this.videoViewCountRef && this.videoViewCountRef.current){
      this.videoViewCountRef.current.setDataType(this.state.demoAndActualValue);
    }
  
  }


  handleChangeDropdown =(e) => {
     //console.log("dropdownValue",this.state.dropdownValue,"target value",e.target.value)
     e.preventDefault();
     const value = e.target.value
     this.setState({dropdownValue:value},()=>{this.onHandleChangeFunction()})

     // var t = new Date();
     // this.setState({endDate:t})
     // console.log("to date",t);
     // var p = new Date();
     // var dateInSec = p.getTime();
     // var d = dateInSec-6.048e+8;
     // var fromDateInSec = new Date(d);
     // var tempDate = fromDateInSec.getDate();
     // var tempMonth = fromDateInSec.getMonth()+1;
     // var tempYear = fromDateInSec.toString().substring(11,15);
     // var fromDate = tempMonth + "/" + tempDate + "/" + tempYear;   // date conversion dd/mm/yyyy
     // this.setState({startDate:new Date(fromDate)})
   }

   ratingsPercentage = () =>{
    var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
    Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
      draw: function() {
        originalDoughnutDraw.apply(this, arguments);
        
        var chart = this.chart.chart;
        var ctx = chart.ctx;
        var width = chart.width;
        var height = chart.height;

        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em Verdana";
        ctx.textBaseline = "middle";

        var text = chart.config.data.text,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;

        ctx.fillText(text, textX, textY);
      }
   });
  }

   handleChangeDemoAndActualValue =(e) => {
    const value = e.target.value;
    this.setState({demoAndActualValue:value},()=>{this.onHandleChangeFunction();
      if(this.state.dropdownValue === "Resources Usage By Course"){
        this.childDoc.current.demoResourceViewCountGraphDate();
      }
      if(this.state.dropdownValue === "Resources Usage By Course Videos"){
        this.childVideo.current.demoVideoResourceCountGraphDate();
      }
      if(this.state.dropdownValue === "Course Analytics"){
        this.fetchAllCourse();
      } 
    })
  }

  handleChangeResourceDropdown =(e) => {
      //console.log("dropdownValue",this.state.dropdownValue,"target value",e.target.value)
      e.preventDefault();
      const value = JSON.parse(e.target.value)
      // console.log("e.target.value._id",e.target.value._id);
      this.setState({resourceObject:value},()=>{this.onHandleChangeFunction()})
    }
    handleChangeCourseDropdown =(e) => {
      e.preventDefault();
      const value = JSON.parse(e.target.value)
      this.setState({selectedCourseId:value._id},()=>{
        this.onHandleChangeFunction()
      })
    }
   handleChangeStart =(sdate)=>{
     const startValue = sdate
     this.setState({startDate:startValue},()=>{
       this.onHandleChangeFunction()
      })
   }

  handleChangeEnd=(edate)=>{
     const endValue = edate
     this.setState({endDate:endValue},()=>{this.onHandleChangeFunction()})
   }

   onHandleChangeFunction=()=>{
     if(this.state.dropdownValue === "Top Five Resource Usage"){
        this.topFiveResourcesViewsGraphData(this.state.startDate,this.state.endDate);
      } else if(this.state.dropdownValue === "Top Five Video Resource Usage"){
        this.topFiveVideoGraphData(this.state.startDate,this.state.endDate)
      }else if(this.state.dropdownValue === "Resources Usage By Course"){
        this.childDoc.current.resourceViewCountGraphDate();
      }else if(this.state.dropdownValue === "Resources Usage By Course Videos"){
        this.childVideo.current.videoResourceCountGraphDate();
      }else if(this.state.dropdownValue === "Course Analytics"){
        this.childCourseUsage.current.getCourseUsage();
      }else if(this.state.dropdownValue==='Video Resource Usage'){
        this.videoViewCountRef.current.setDataType(this.state.demoAndActualValue);
      }else {
        this.resourceRatingsGraphData(this.state.startDate,this.state.endDate)
      }
   }

   fetchAllResources=()=>{
    //  axios.get('/api/resourceUri/fetchAllResource')
     var url = "/api/resourceUri/fetchAllResource";
     customHttp.get(url)
          .then(response => {
            var resourcesArrayIndex = response.data.message;
            // console.log("resourcesArrayIndex",response.data.message);

            if(response.data.message && Array.isArray(response.data.message)){
              // console.log("resourcesArrayIndex",response.data.message,response.data.message[0],response.data.message[0]._id);
              this.setState({resourcesArrayIndex:resourcesArrayIndex,resourceObject:response.data.message[0],show_Analytics_Loader:false})
            }else{
               this.setState({resourcesArrayIndex:[],resourceObject:{},show_Analytics_Loader:false})
            }
          })
          .catch(error => {
            this.setState({resourcesArrayIndex:[],resourceObject:{},show_Analytics_Loader:false})
            console.log("failed", error.response);
          });
   }

   fetchAllCourse=()=>{
    var demoCoursesArray=[{name: "Fluid Mechanics",_id: "5c15f3907257166d93c74bb2"},
   {name: "Manufacturing Technology",_id: "5c15f4677257166d93c74cb3"},
   {name: "Applied Thermodynamics",_id: "5c15f40c7257166d93c74c4f"},
   {name: "Dynamics of Machinery",_id: "5c15f3907257166d93c74r4"},
   {name: "Design of Machine Elements",_id: "5c15f3907257166d453c74bb2"}]
     var userDetails=JSON.parse(sessionStorage.getItem("userData"))
     if(this.state.demoAndActualValue==="Demo"){
      this.setState({allCourses:demoCoursesArray,selectedCourseId:demoCoursesArray[0]._id},()=>{
        this.onHandleChangeFunction()
      })
     }else{
    var url = '/api/course/allCourses/user/'+userDetails._id
     customHttp.get(url)
          .then(response => {
            if(response.data.message.length>0){
              var courses=response.data.message.reverse();
              this.setState({allCourses:courses,selectedCourseId:courses[0]._id},()=>{
                this.onHandleChangeFunction()
              })
            }else{
               this.setState({selectedCourseId:"no courses available",allCourses:[]},()=>{
                this.onHandleChangeFunction()
               })
            }
          })
          .catch(error => {
            this.setState({selectedCourseId:"no courses available",allCourses:[]},()=>{
              this.onHandleChangeFunction()
             })
            console.log("failed", error.response);
          });
        }
   }

   topFiveResourcesViewsGraphData=(sdate,edate)=>{
    var demoArrayForTopFiveResources=[{displayName: "lean supply chain management",resourceId: "5c15f3907257166d93c74bb2",resourceViewCount: '14'},
    {displayName: "slot cancellation",resourceId: "5c15f4677257166d93c74cb3",resourceViewCount: '11'},
    {displayName: "how to book a slot",resourceId: "5c15f40c7257166d93c74c4f",resourceViewCount: '9'},
    {displayName: "The Management Of Operations",resourceId: "5c15f3907257166d93c74r4",resourceViewCount: '7'},
    {displayName: "how to access lab",resourceId: "5c15f3907257166d453c74bb2",resourceViewCount: '5'}]

     var n = new Date(sdate);
     var currentDate = n.getDate();
     if(currentDate<10){
       currentDate='0'+currentDate;
     }
     var currentMonth = n.getMonth() + 1; //Months are zero based
     if(currentMonth<10){
       currentMonth='0'+currentMonth;
     }
     var currentYear = n.getFullYear();
     var fromDate = currentYear + "-" + currentMonth + "-" + currentDate;  // date conversion dd/mm/yyyy to yyyy-mm-dd

     var p = new Date(edate);
     var tempDate = p.getDate();
     if(tempDate<10){
       tempDate='0'+tempDate;
     }
     var tempMonth = p.getMonth() + 1;
     if(tempMonth<10){
       tempMonth='0'+tempMonth;
     }
     var tempYear = p.getFullYear();
     var toDate = tempYear + "-" + tempMonth + "-" + tempDate;   // date conversion dd/mm/yyyy

    //  console.log("topFiveResourcesViewsGraphData fromDate toDate",fromDate,toDate);
     if(this.state.demoAndActualValue==="Demo"){
       //demo purpose
      this.setState({topFiveResourcesViewCount:demoArrayForTopFiveResources})
      var temp = this.state.graphDataBar
      var labels=[];
      var data=[];
      for(var i=0;i<demoArrayForTopFiveResources.length;i++){
         labels.push(demoArrayForTopFiveResources[i].displayName)
         data.push(demoArrayForTopFiveResources[i].resourceViewCount)
       }

       temp.labels = labels;
       temp.datasets[0].data = data;
       this.setState({graphDataBar:temp,noDataFound:demoArrayForTopFiveResources})
     }else{
    //  axios.get('/api/resourceViewCount/topFiveResourceViewCount/fromDate/'+fromDate+'/toDate/'+toDate)
     var url = '/api/resourceViewCount/topFiveResourceViewCount/fromDate/'+fromDate+'/toDate/'+toDate ;
     customHttp.get(url)
           .then(response => {
             var topFiveResourcesViewCount = response.data.message;
             this.setState({topFiveResourcesViewCount:topFiveResourcesViewCount})
            //  console.log("topFiveResourcesViewCount response",topFiveResourcesViewCount);
             var temp = this.state.graphDataBar
             var labels=[];
             var data=[];
             for(var i=0;i<response.data.message.length;i++){
                labels.push(response.data.message[i].displayName)
                data.push(response.data.message[i].resourceViewCount)
              }

              temp.labels = labels;
              temp.datasets[0].data = data;
              this.setState({graphDataBar:temp,noDataFound:response.data.message})
           })
           .catch(error => {
             this.setState({topFiveResourcesViewCount:[]})
           console.log("failed", error.response);
         });
        }
    }

    topFiveVideoGraphData=(sdate,edate)=>{
      var demoArrayForTopFiveVideoUsage=[{displayName: "lean supply chain management",resourceId: "5c15f3907257166d93c74bb2",totalVideoPlayTimeInMinute: '10'},
      {displayName: "slot cancellation",resourceId: "5c15f4677257166d93c74cb3",totalVideoPlayTimeInMinute: '8'},
      {displayName: "how to book a slot",resourceId: "5c15f40c7257166d93c74c4f",totalVideoPlayTimeInMinute: '5'},
      {displayName: "The Management Of Operations",resourceId: "5c15f3907257166d93c74r4",totalVideoPlayTimeInMinute: '3'},
      {displayName: "how to access lab",resourceId: "5c15f3907257166d453c74bb2",totalVideoPlayTimeInMinute: '1'}]
      var n = new Date(sdate);
      var currentDate = n.getDate();
      if(currentDate<10){
        currentDate='0'+currentDate;
      }
      var currentMonth = n.getMonth() + 1; //Months are zero based
      if(currentMonth<10){
        currentMonth='0'+currentMonth;
      }
      var currentYear = n.getFullYear();
      var fromDate = currentYear + "-" + currentMonth + "-" + currentDate;  // date conversion dd/mm/yyyy to yyyy-mm-dd

      var p = new Date(edate);
      var tempDate = p.getDate();
      if(tempDate<10){
        tempDate='0'+tempDate;
      }
      var tempMonth = p.getMonth() + 1;
      if(tempMonth<10){
        tempMonth='0'+tempMonth;
      }
      var tempYear = p.getFullYear();
      var toDate = tempYear + "-" + tempMonth + "-" + tempDate;   // date conversion dd/mm/yyyy

      // console.log("topFiveVideoGraphData fromDate toDate",fromDate,toDate);
      if(this.state.demoAndActualValue==="Demo"){
        //demo purpose
       this.setState({topFiveVideoCount:demoArrayForTopFiveVideoUsage})
       var temp = this.state.graphData
       var data=[];
       var labels=[]
       for(var i=0;i<demoArrayForTopFiveVideoUsage.length;i++){
          labels.push(demoArrayForTopFiveVideoUsage[i].displayName)
          data.push(demoArrayForTopFiveVideoUsage[i].totalVideoPlayTimeInMinute)
        }
        temp.labels = labels;
        temp.datasets[0].data = data;
        this.setState({graphData:temp,noDataFound:demoArrayForTopFiveVideoUsage})
      }else{
      // axios.get('/api/videoViewCount/topFiveVideo/fromDate/'+fromDate+'/toDate/'+toDate)
      var url = '/api/videoViewCount/topFiveVideo/fromDate/'+fromDate+'/toDate/'+toDate ;
      customHttp.get(url)
            .then(response => {
              var topFiveVideoCount = response.data.message;
              this.setState({topFiveVideoCount:topFiveVideoCount})
              // console.log("topFiveVideoGraphData response",topFiveVideoCount);

              // var temp = JSON.parse(JSON.stringify(this.state.graphDataLine))
              var temp = this.state.graphData
              var data=[];
              var labels=[]
              for(var i=0;i<response.data.message.length;i++){
                 // console.log("overAll usage",response.data.message._id,response.data.message.totalUsage)
                 labels.push(response.data.message[i].displayName)
                 data.push(response.data.message[i].totalVideoPlayTimeInMinute)
               }
              //  console.log("labels data",labels,data);
               temp.labels = labels;
               temp.datasets[0].data = data;
               this.setState({graphData:temp,noDataFound:response.data.message})
            })
            .catch(error => {
              this.setState({topFiveVideoCount:[]})
            console.log("failed", error.response);
          });
        }
     }

   resourceRatingsGraphData=(sdate,edate)=>{
     var demoResourceRatings={totalCount: 121, averageRating: "3.26", totalPercentage: "80", ratingsArray:[{ratings:"1",count:20,percentage: 16.52},{ratings:"2",count:16,percentage: 13.22},{ratings:"3",count:26,percentage: 21.48},{ratings:"4",count:30,percentage: 24.79},{ratings:"5",count:29,percentage: 23.96}]}
     var n = new Date(sdate);
     var currentDate = n.getDate();
     if(currentDate<10){
       currentDate='0'+currentDate;
     }
     var currentMonth = n.getMonth() + 1; //Months are zero based
     if(currentMonth<10){
       currentMonth='0'+currentMonth;
     }
     var currentYear = n.getFullYear();
     var fromDate = currentYear + "-" + currentMonth + "-" + currentDate;  // date conversion dd/mm/yyyy to yyyy-mm-dd

     var p = new Date(edate);
     var tempDate = p.getDate();
     if(tempDate<10){
       tempDate='0'+tempDate;
     }
     var tempMonth = p.getMonth() + 1;
     if(tempMonth<10){
       tempMonth='0'+tempMonth;
     }
     var tempYear = p.getFullYear();
     var toDate = tempYear + "-" + tempMonth + "-" + tempDate;   // date conversion dd/mm/yyyy
     if(this.state.demoAndActualValue==="Demo"){
      this.setState({resourceRatings:demoResourceRatings.ratingsArray,totalRating:demoResourceRatings.totalCount})
      var temp = this.state.graphDataPieResource
      var data=[];
      // var labels=[]
      for(var i=0;i<demoResourceRatings.ratingsArray.length;i++){
        // console.log("overAll usage",response.data.message._id,response.data.message.totalUsage)
        data.push(demoResourceRatings.ratingsArray[i].count)
      }
      temp.datasets[0].data = data;
      temp.text = demoResourceRatings.averageRating;
      this.setState({graphDataPieResource:temp,noDataFound:demoResourceRatings},()=> this.ratingsPercentage())
    }else{
    //  axios.get('/api/ratings/getRatingsByResourceIdFromDateAndToDate/'+this.state.resourceObject._id+'/fromDate/'+fromDate+'/toDate/'+toDate)
      var url = '/api/ratings/getRatingsByResourceIdFromDateAndToDate/'+this.state.resourceObject._id+'/fromDate/'+fromDate+'/toDate/'+toDate ;
      customHttp.get(url)
     .then(response => {
             var resourceRatings = response.data.message.ratingsArray;
             this.setState({resourceRatings:resourceRatings,totalRating:response.data.message.totalCount})
             if(response.data.message.ratingsArray.length>0){
               if(response.data.message.ratingsArray[0].count===0 && response.data.message.ratingsArray[1].count===0 && response.data.message.ratingsArray[2].count===0 && response.data.message.ratingsArray[3].count===0 && response.data.message.ratingsArray[4].count===0){
                 this.setState({resourceRatings:[]})
               }else {
                 var temp = this.state.graphDataPieResource
                 var data=[];
                //  var labels=[]
                 for(var i=0;i<response.data.message.ratingsArray.length;i++){
                    // console.log("overAll usage",response.data.message._id,response.data.message.totalUsage)
                    data.push(response.data.message.ratingsArray[i].count)
                    // console.log("data",data);
                 }
                  temp.datasets[0].data = data;
                  temp.text = response.data.message.averageRating;
                  this.setState({graphDataPieResource:temp,noDataFound:response.data.message.ratingsArray},()=> this.ratingsPercentage())
               }

             }

           })
           .catch(error => {
             this.setState({resourceRatings:[]})
           console.log("failed", error.response);
         });
        }
    }

  graph=()=>{
    const { dropdownValue } = this.state

     if(dropdownValue === "Top Five Resource Usage"){

       return (
            <div>
              <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px',paddingTop: 10}}>
                 <div className="col-md-12 col-sm-12 nj-card" style={{paddingTop: 20,paddingBottom: 10}}>
                  {this.state.show_Analytics_Loader !== true ?
                    <div>
                      {this.state.topFiveResourcesViewCount.length > 0 ?
                        <div>
                          <p style={{fontFamily:'Montserrat'}}><strong>No. Of Views</strong></p>
                          <Bar data={this.state.graphDataBar} options={this.state.options} height={130} key={this.state.graphDataBar} />
                          <p style={{fontFamily:'Montserrat',textAlign: 'center'}}><strong>Resources</strong></p>
                        </div>
                        :
                        <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                      }
                    </div>:
                    <div className="row" style={{minHeight:"54.3vh"}}>
                      <div className="col-md-12 aligh-user-loader-image-center" >
                        <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                      </div>
                    </div>
                  }
                 </div>
              </div>
            </div>
       )
     }else if (dropdownValue === "Top Five Video Resource Usage") {
       return (
             <div>
               <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px',paddingTop: 10}}>
                  <div className="col-md-12 col-sm-12 nj-card" style={{paddingTop: 20,paddingBottom: 10}}>
                  {this.state.show_Analytics_Loader !== true ?
                    <div>
                            {this.state.topFiveVideoCount.length >0 ?
                              <div>
                                <p style={{fontFamily:'Montserrat'}}><strong>No. Of Minutes</strong></p>
                                <Line data={this.state.graphData} options={this.state.options} height={130} key={this.state.graphData}/>
                                <p style={{fontFamily:'Montserrat',textAlign: 'center'}}><strong>Resources</strong></p>
                              </div>
                              :
                              <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                            }
                             </div>:

                      <div className="row" style={{minHeight:"54.3vh"}}>
                              <div className="col-md-12 aligh-user-loader-image-center" >
                                <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                              </div>
                      </div>
                        }
                  </div>
                </div>
             </div>
       )
     }else if (dropdownValue === "Resources Usage By Course") {
       return (
               <div>
                 <ResourceUsageByCourse ref={this.childDoc} fromDate={this.state.startDate} toDate={this.state.endDate} demoAndActualValue={this.state.demoAndActualValue}/>
               </div>
       )
     }else if (dropdownValue === "Resources Usage By Course Videos") {
       return (
               <div>
                 <ResourceUsageByCourseVideo ref={this.childVideo} fromDate={this.state.startDate} toDate={this.state.endDate} demoAndActualValue={this.state.demoAndActualValue} />
               </div>
       )
     }else if (dropdownValue === "Video Resource Usage") {
      return (
              <div>
                <VideoResourceUsage ref={this.videoViewCountRef} fromDate={this.state.startDate} toDate={this.state.endDate}/>
              </div>
      )
    }else if(dropdownValue === "Course Analytics"){
      return ( <div>
          <CourseAnalytics  ref={this.childCourseUsage} fromDate={this.state.startDate} toDate={this.state.endDate} courseId={this.state.selectedCourseId} key={this.state.selectedCourseId} demoAndActualValue={this.state.demoAndActualValue}/>
      </div>)
     }else if (dropdownValue === "Resource Ratings") {
       return (
               <div>
                 <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px',paddingTop: 10}}>
                   <div className="col-md-12 col-sm-12 nj-card" style={{paddingBottom: 10}}>

                     <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                       <div className="nj-card__header">
                         <div className="nj-card__title">
                           <h4 className="font_family_montserrat">Resource Ratings</h4>
                         </div>
                       </div>
                     </div>
                     {this.state.show_Analytics_Loader !== true ?
                       <div>
                              {this.state.resourceRatings.length >0 ?
                                <div className="col-md-6" style={{paddingTop: 10}}>
                                  <Doughnut data={this.state.graphDataPieResource} options={this.state.options1} height={140} width={340} key={this.state.graphDataPieResource}/>
                              
                                </div>
                                :
                                <div className="col-md-6">
                                  <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                                </div>
                              }


                     <div className="col-md-6" style={{paddingBottom: 20,paddingTop: 10}}>
                       <table>
                         <tbody className="bar-progress-bar-wrapper">
                           <tr>
                             <td style={{padding:10,fontSize: 14}}>Excellent</td>
                             <td style={{width:'70%'}}>
                               <div className="progress" style={{height: 8,margin: 0}}>
                                 <div className="progress-bar-1" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:this.state.resourceRatings[4] && this.state.resourceRatings[4].percentage+"%" || "0%",backgroundColor: "#8da1bc",height: 8,top: 5,borderRadius: 4}}>
                                   <span className="sr-only">70% Complete</span>
                                 </div>
                               </div>
                             </td>
                             <td>{this.state.resourceRatings.length>0 && this.state.resourceRatings[4].count || 0}</td>
                           </tr>

                           <tr>
                             <td style={{padding:10,fontSize: 14}}>Good</td>
                             <td style={{width:'70%'}}>
                               <div className="progress" style={{height: 8,margin: 0}}>
                                 <div className="progress-bar-2" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:this.state.resourceRatings[3] && this.state.resourceRatings[3].percentage+"%" || "0%",backgroundColor: "#6f5d7d",height: 8,top: 5,borderRadius: 4}}>
                                   <span className="sr-only">70% Complete</span>
                                 </div>
                               </div>
                             </td>
                             <td>{this.state.resourceRatings.length>0 && this.state.resourceRatings[3].count || 0}</td>
                           </tr>

                           <tr>
                             <td style={{padding:10,fontSize: 14}}>Average</td>
                             <td style={{width:'70%'}}>
                               <div className="progress" style={{height: 8,margin: 0}}>
                                 <div className="progress-bar-3" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:this.state.resourceRatings[2] && this.state.resourceRatings[2].percentage+"%" || "0%",backgroundColor: "#5e3d5f",height: 8,top: 5,borderRadius: 4}}>
                                   <span className="sr-only">70% Complete</span>
                                 </div>
                               </div>
                             </td>
                             <td>{this.state.resourceRatings.length>0 && this.state.resourceRatings[2].count || 0}</td>
                           </tr>

                           <tr>
                             <td style={{padding:10,fontSize: 14}}>Below Average</td>
                             <td style={{width:'70%'}}>
                               <div className="progress" style={{height: 8,margin: 0}}>
                                 <div className="progress-bar-4" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:this.state.resourceRatings[1] && this.state.resourceRatings[1].percentage+"%" || "0%",backgroundColor: "#945049",height: 8,top: 5,borderRadius: 4}}>
                                   <span className="sr-only">70% Complete</span>
                                 </div>
                               </div>
                             </td>
                             <td>{this.state.resourceRatings.length>0 && this.state.resourceRatings[1].count || 0}</td>
                           </tr>

                           <tr>
                             <td style={{padding:10,fontSize: 14}}>Poor</td>
                             <td style={{width:'70%'}}>
                               <div className="progress" style={{height: 8,margin: 0}}>
                                 <div className="progress-bar-5" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:this.state.resourceRatings[0] && this.state.resourceRatings[0].percentage+"%" || "0%",backgroundColor: "#c96734",height: 8,top: 5,borderRadius: 4}}>
                                   <span className="sr-only">70% Complete</span>
                                 </div>
                               </div>
                             </td>
                             <td>{this.state.resourceRatings.length>0 && this.state.resourceRatings[0].count || 0}</td>
                           </tr>

                         </tbody>
                       </table>
                       <p  className="bar-progress-bar-wrapper" style={{padding:10,fontSize: 14}}>Total number of ratings : {this.state.totalRating}</p>
                     </div>
                     </div>:

                    <div className="row" style={{minHeight:"33.3vh"}}>
                            <div className="col-md-12 " style={{top:"10vh",textAlign:"center"}}>
                              <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                            </div>
                     </div>
                     }

                   </div>
                 </div>

                 <CourseRatings demoAndActualValue={this.state.demoAndActualValue} key={this.state.demoAndActualValue} />
               </div>
       )
     }else {
       return (
             <div>
               <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px',paddingTop: 20}}>
                 <div className="col-md-12 col-sm-12 nj-card" style={{paddingTop: 10,paddingBottom: 10}}>
                   <Bar data={this.state.graphDataBar} options={this.state.options} height={130} key={this.state.graphDataBar} />
                 </div>
               </div>
             </div>
       )
     }
   }

  render(){
    return(
      <div>
        {/*console.log("msg",props.analyticsState)*/}
        <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px',paddingBottom: 10}}>
          <div className="col-md-12 col-sm-12 nj-card">
            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header" style={{borderBottom:"none"}}>
                <div className="nj-card__title col-md-12">
                  <div className="col-md-6 col-sm-12 col-xs-12 pull-left" style={{marginLeft:"0px",marginBottom:"5px",paddingLeft:"0px"}}>
                    <h4 className="font_family_montserrat">Analytics</h4>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12" style={{marginLeft:"0px",paddingLeft:"0px"}}>
                    <div className="col-md-5 col-sm-12 col-xs-12 pull-right" style={{marginTop:"-10px"}}>
                      <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontWeight:"500"}}>Select Type</label>
                      <div className="nj-form-select">
                        <select className="vs-input--input" value={this.state.demoAndActualValue} onChange={this.handleChangeDemoAndActualValue}>
                          {/*<option hidden='true' value=''></option>*/}
                          {this.state.demoAndActualArray.map((v,i) =>{
                            return <option key={i} value={v}>{v}</option>
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
          <div className="col-md-12 col-sm-12 nj-card" >
            {/* used for actual purpose */}
            <div className="row" style={(this.state.demoAndActualValue==="Actual" ? {padding:10,paddingBottom: 15,display:"block"}:{display:"none"})}>
              <div className="col-md-3" style={{paddingBottom:"5px"}}>
                <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Types:</label>
                <div className="nj-form-select">
                  <select className="vs-input--input" value={this.state.dropdownValue} onChange={this.handleChangeDropdown}>
                    {/*<option hidden='true' value=''></option>*/}
                    {this.state.dropdownArray.map((v,i) =>{
                      return <option key={i} value={v}>{v}</option>
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-3" style={{paddingBottom:"5px"}}>
                <Fragment>
                  <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>From Date:</label>
                  <DatePicker className="datepicker-calender"
                      calendarClassName={"calender-design"}
                      format="dd/MM/yyyy"
                      clearIcon={null}
                      calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
                      showNeighboringMonth={false}
                      dayPlaceholder="dd"
                      monthPlaceholder="mm"
                      yearPlaceholder="yyyy"
                      value={this.state.startDate}
                      onChange={this.handleChangeStart}
                  />
                </Fragment>
              </div>
              <div className="col-md-3" style={{paddingBottom:"5px"}}>
                <Fragment>
                  <label  className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>To Date:</label>
                  <DatePicker className="datepicker-calender"
                      calendarClassName={"calender-design"}
                      format="dd/MM/yyyy"
                      clearIcon={null}
                      calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
                      showNeighboringMonth={false}
                      dayPlaceholder="dd"
                      monthPlaceholder="mm"
                      yearPlaceholder="yyyy"
                      value={this.state.endDate}
                      onChange={this.handleChangeEnd}
                  />
                </Fragment>
              </div>
              {this.state.dropdownValue === "Resource Ratings" ?
                <div className="col-md-3">
                  <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Resources:</label>
                  <div className="nj-form-select">
                    <select className="vs-input--input" value={JSON.stringify(this.state.resourceObject)} onChange={this.handleChangeResourceDropdown}>
                      {/*<option hidden='true' value=''></option>*/}
                      {this.state.resourcesArrayIndex.map((resource,i) =>{
                        return <option key={i} value={JSON.stringify(resource)}>{resource.displayName}</option>
                      })}
                    </select>
                  </div>
                </div>
                :
                <div></div>
              }

              {this.state.dropdownValue === "Course Analytics" ?
                <div className="col-md-3">
                  <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Courses:</label>
                  <div className="nj-form-select">
                    <select className="vs-input--input"  onChange={this.handleChangeCourseDropdown}>
                      {this.state.allCourses.length===0 && (<option value=''>No courses available</option>)}
                      {this.state.allCourses.map((data,i) =>{
                        return <option key={i} value={JSON.stringify(data)}>{data.name}</option>
                      })}
                    </select>
                  </div>
                </div>
                :
                <div></div>
              }
            </div>
            {/* used for demo purpose */}
            <div className="row" style={(this.state.demoAndActualValue==="Demo" ? {padding:10,paddingBottom: 15,display:"block"}:{display:"none"})}>
              <div className="col-md-3" style={{paddingBottom:"5px"}}>
                <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Types:</label>
                <div className="nj-form-select">
                  <select className="vs-input--input" value={this.state.dropdownValue} onChange={this.handleChangeDropdown}>
                    {/*<option hidden='true' value=''></option>*/}
                    {this.state.dropdownArray.map((v,i) =>{
                      return <option key={i} value={v}>{v}</option>
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-3" style={{paddingBottom:"5px"}}>
                <Fragment>
                  <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>From Date:</label>
                  <DatePicker className="datepicker-calender"
                      calendarClassName={"calender-design"}
                      format="dd/MM/yyyy"
                      clearIcon={null}
                      calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
                      showNeighboringMonth={false}
                      dayPlaceholder="dd"
                      monthPlaceholder="mm"
                      yearPlaceholder="yyyy"
                      value={this.state.startDate}
                      onChange={this.handleChangeStart}
                  />
                </Fragment>
              </div>
              <div className="col-md-3" style={{paddingBottom:"5px"}}>
                <Fragment>
                  <label  className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>To Date:</label>
                  <DatePicker className="datepicker-calender"
                      calendarClassName={"calender-design"}
                      format="dd/MM/yyyy"
                      clearIcon={null}
                      calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
                      showNeighboringMonth={false}
                      dayPlaceholder="dd"
                      monthPlaceholder="mm"
                      yearPlaceholder="yyyy"
                      value={this.state.endDate}
                      onChange={this.handleChangeEnd}
                  />
                </Fragment>
              </div>
              {this.state.dropdownValue === "Resource Ratings" ?
                <div className="col-md-3">
                  <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Resources:</label>
                  <div className="nj-form-select">
                    <select className="vs-input--input">
                      {/*<option hidden='true' value=''></option>*/}
                      {this.state.demoResourceArray.map((resource,i) =>{
                        return <option key={i} value={JSON.stringify(resource)}>{resource.displayName}</option>
                      })}
                    </select>
                  </div>
                </div>
                :
                <div></div>
              }
               {this.state.dropdownValue === "Course Analytics" ?
                <div className="col-md-3">
                  <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Courses:</label>
                  <div className="nj-form-select">
                    <select className="vs-input--input">
                      {this.state.allCourses.length===0 && (<option value=''>No courses available</option>)}
                      {this.state.allCourses.map((data,i) =>{
                        return <option key={i} value={JSON.stringify(data)}>{data.name}</option>
                      })}
                    </select>
                  </div>
                </div>
                :
                <div></div>
              }
            </div>
          </div>
        </div>

        <div style={{marginBottom: 10}}>
            {this.graph()}
        </div>

      </div>

    );
  }
}
export default Analytics;
