import * as adminSlotBookingActions from "./index";
import customHttp from '../../../packages/CustomHttp/';
import snackbar from '../../../packages/Snackbar/';

/**
 * @author Sachin Kammar
 * @param {String} courseId - course _id to get user list.
 * @param {Object} history - history object from props to route user.
 * @returns {void} Nothing
 * @desc Function to get course details using it's _id.
 */
export function getCourse(courseId,history) {
   return async (dispatch)=> {
      try {
         const response = await customHttp.get(`/api/course/${courseId}`);
         if(response.data.message && Array.isArray(response.data.message) && response.data.message.length>0){
            let course = response.data.message[0];
            if(course.isUserSlotBooking){
               alert('Unauthorized access!',history.push('/login'))
            } else {
               console.log('triggered')
               dispatch(getUserList(courseId))
            }
         } else{
            alert('Something went Wrong!',history.push('courses'))
         }
      } catch(err) {
         alert('Something went Wrong!',history.push('courses'))
         console.log("error",err);
      }
   }
}
/**
 * @author Sachin Kammar
 * @param {String} courseId - course _id to get user list.
 * @returns {void} Nothing
 * @desc Function to get user list of a course using it's _id.
 */
export function getUserList(courseId) {
   return async (dispatch)=> {
      dispatch(setAdminSlotBookingElements("userListLoader",true))
      try {
         const response = await customHttp.get(`/api/user/faculty/slotBooking/courseId/${courseId}`);
         if(response.data.message && Array.isArray(response.data.message) && response.data.message.length>0){
            let users = response.data.message;
            const userList = users.filter(obj=>{
               obj.isChecked = false;
               return obj;
            })
            dispatch(setAdminSlotBookingElements('userList',userList));
            dispatch(setAdminSlotBookingElements('totalUsers',userList));
            dispatch(setAdminSlotBookingElements("userListLoader",false));
            sessionStorage.setItem('adminSlotBookingUsers',JSON.stringify([]));
         }  else{
            dispatch(setAdminSlotBookingElements('userList',[]));
            dispatch(setAdminSlotBookingElements('totalUsers',[]));
            dispatch(setAdminSlotBookingElements("userListLoader",false));
            sessionStorage.setItem('adminSlotBookingUsers',JSON.stringify([]))
         }
      } catch(err) {
         console.log("error",err);
         dispatch(setAdminSlotBookingElements('userList',[]));
         dispatch(setAdminSlotBookingElements('totalUsers',[]));
         dispatch(setAdminSlotBookingElements("userListLoader",false));
         sessionStorage.setItem('adminSlotBookingUsers',JSON.stringify([]));
      }
   }
}

/**
 * @author Sachin Kammar
 * @param {String} id - selected user _id.
 * @returns {void} Nothing
 * @desc Function to check/uncheck a user by changing user's isChecked property.
 */
export function handleSelect(id) {
   return async (dispatch, getState)=> {
      let users = getState().adminSlotBookingState.userList;
      const userList = users.filter(obj=>{
         if(obj._id === id){
            obj.isChecked = !obj.isChecked;
         }
         return obj;
      })
      var selectedUserList = users.filter(obj=>obj.isChecked);
      dispatch(setAdminSlotBookingElements('selectedUserList',selectedUserList))
      dispatch(setAdminSlotBookingElements('userList',userList));
   }
}

/**
 * @author Sachin Kammar
 * @param {Object} e - $event object.
 * @returns {void} Nothing
 * @desc Function to check/uncheck all users by changing user's isChecked property.
 */
export function handleSelectAll(e) {
   return async (dispatch, getState)=> {
      // console.log("eeveene",e.target.checked)
      let users = getState().adminSlotBookingState.userList;
      const userList = users.filter(obj=>{
         obj.isChecked = e.target.checked;
         return obj;
      })
      if(e.target.checked){
         dispatch(setAdminSlotBookingElements('selectedUserList',userList));
      } else {
         dispatch(setAdminSlotBookingElements('selectedUserList',[]));
      }
      dispatch(setAdminSlotBookingElements('userList',userList));
   }
}

/**
 * @author Sachin Kammar
 * @param {Object} e - $event object.
 * @returns {void} Nothing
 * @desc Function to search users by username.
 */
export const handleSearch=(e)=>{
   return (dispatch ,getState)=> {
      dispatch(setAdminSlotBookingElements("userListLoader",true))  
      const data = e.target.value;
      var totalUsers=getState().adminSlotBookingState.totalUsers;
      if(data && data !== ""){
         var searchRequestedArray = totalUsers.filter(el =>{
            if(el.username!==undefined){
               return el.username.toLowerCase().indexOf(data.toString().toLowerCase()) !== -1;
            }
         })
         dispatch(setAdminSlotBookingElements('userList',searchRequestedArray));
         dispatch(setAdminSlotBookingElements("userListLoader",false));
      } else {
         dispatch(setAdminSlotBookingElements('userList',totalUsers));
         dispatch(setAdminSlotBookingElements("userListLoader",false));
      } 
   }
}

/**
 * @author Sachin Kammar
 * @param {Object} history - history object from props to route.
 * @returns {void} Nothing
 * @desc Function to route user to courses.
 */
export const handleBack=(history)=>{
   return ()=> {
      history.push('courses');
   }
}

/**
 * @author Sachin Kammar
 * @param {Object} history - history object from props to route.
 * @returns {void} Nothing
 * @desc Function to extract selected users from user list and store the list in session storage.
 */
export function handleSubmit(history) {
   return (dispatch, getState)=> {
      var userList = JSON.parse(JSON.stringify(getState().adminSlotBookingState.userList));
      const selectedUsers = userList.filter(element => {
         if(element.isChecked){
            delete element.isChecked;
            return element;
         }
      });
      console.log("selectedUsers", selectedUsers);
      if(selectedUsers.length>0){
         sessionStorage.setItem('adminSlotBookingUsers',JSON.stringify(selectedUsers))
         history.push("book-slot-apps");
      } else {
         dispatch(displayAlert('error','Please select a user..'));
      }
   }
}

//book slots
/**
 * @author Sachin Kammar
 * @param {Object} history - history object from props to route.
 * @returns {void} Nothing
 * @desc Function to route user to book-slot-users route.
 */
export const handleBookSlotBack=(history)=>{
   return ()=> {
      history.push('book-slot-apps')
   }
}


//applications
export function onLoadFunction(history) {
   return (dispatch)=> {
      const course = JSON.parse(sessionStorage.getItem('adminSlotBookingCourse'));
      const users = JSON.parse(sessionStorage.getItem('adminSlotBookingUsers'));
      if(users.length<=0){
         alert('Please select users first!',history.push('book-slot-users'));
      }
      dispatch(setAdminSlotBookingElements('selectedApplications',[]));
      sessionStorage.setItem('adminSelectedApplications',JSON.stringify([]))
      if(course.applications && course.applications.length>0){
         var arr = course.applications.map(obj=> ({ ...obj, isSelected:false}));
         //dispatch(setAdminSlotBookingElements('selected', []))
         dispatch(setAdminSlotBookingElements('applications',arr));
      }
   }
}

export function selectApplication(app) {
  
   return (dispatch ,getState)=> {
      var applications = getState().adminSlotBookingState.applications;
      applications.filter(obj=>{
         if(obj._id===app._id){
            obj.isSelected = !obj.isSelected
         }
         return obj
      })
      var selectedApps = applications.filter(obj=>obj.isSelected);
      dispatch(setAdminSlotBookingElements('selectedApplications',selectedApps))
      dispatch(setAdminSlotBookingElements('applications',applications))
   }
}

export function handleAppSubmit(history) {
   return (dispatch ,getState)=> {
      const selectedApplications = getState().adminSlotBookingState.selectedApplications;
      // var applications = getState().adminSlotBookingState.applications;
      // var selectedApps = applications.filter(obj=>obj.isSelected);
      //dispatch(setAdminSlotBookingElements('selectedApplications',selectedApps));
      sessionStorage.setItem('adminSelectedApplications',JSON.stringify(selectedApplications))
      if(selectedApplications.length>0){
         history.push('book-slot')
      } else {
         var msg = "Please select atleast one application"
         dispatch(displayAlert('error',msg))
      }
   }
}
export const  handleSelectAllClick=(event)=>{
   return (dispatch ,getState)=> {  
      var apps = JSON.parse(JSON.stringify(getState().adminSlotBookingState.applications));
      if (event.target.checked) {
         var selectApps = apps.map(element => {
            element.isSelected=true;
            return element;
         });
         dispatch(setAdminSlotBookingElements('applications',selectApps || []))
         dispatch(setAdminSlotBookingElements('selectedApplications',selectApps || []));
      } else {
         var unselected = apps.map(element => {
            element.isSelected=false;
            return element;
         });
         dispatch(setAdminSlotBookingElements('applications',unselected || []));
         dispatch(setAdminSlotBookingElements('selectedApplications',[]))
      }
   }
 }

/**
 * @author Sachin Kammar
 * @param {String} type - success or error.
 * @param {String} msg - message to show it in snackbar.
 * @returns {void} Nothing
 * @desc Function to extract selected users from user list and store the list in session storage.
 */
export function displayAlert(type,msg) {
   return (dispatch)=> {
      if(type==='success'){
         dispatch(setAdminSlotBookingElements('successAlertMessage',msg));
         snackbar.successSnackbar();
      } else if(type==='error'){
         dispatch(setAdminSlotBookingElements('errorAlertMessage',msg));
         snackbar.errorSnackbar();
      }
   }
}

/**
 * @author Sachin Kammar
 * @param {String} name - state name.
 * @param {Any} content - state data.
 * @returns {void} Nothing
 * @desc Function to overwrite state's data.
 */
export function setAdminSlotBookingElements(name,content) {
   return (dispatch)=> {
      dispatch(adminSlotBookingActions.setAdminSlotBookingElements(name,content))
   }
}