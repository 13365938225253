
var customSnackbar = {
    successSnackbar: function(){
        var x = document.getElementById("successSnackbar")
        x.className = "show";
        setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    },
    errorSnackbar: function(){
        var x = document.getElementById("errorSnackbar")
        x.className = "show";
        setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    }   
}

export default customSnackbar;