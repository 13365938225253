import * as gargiKnowledgeBaseActions from "./index";
import customHttp from '../../../packages/CustomHttp/';
import snackbar from '../../../packages/Snackbar/';
import axios from 'axios';

/**
 * Function to get random number.
 * @author Narasappa H
 * @param {number} length - Number is used for generating number of times digits.
 * @return {number} - Which is return random numbers based on number.
 */
// function getRandom(length) {
//    return Math.floor(Math.pow(10, length-1) + Math.random() * 9 * Math.pow(10, length-1));
//  }

 /**
 * Function to get unique array.
 * @author Narasappa H
 * @param {array} array - Array is used for making unique.
 * @param {string} type - String is used for sorting array based on type.
 * @return {array} - Finally returns unique array.
 */
 function uniqueArray(array,type) {
   var processed = [];
   for (var i=array.length-1; i>=0; i--){
     if (processed.indexOf(array[i][type])<0) {
       processed.push(array[i][type]);
     } else {
       array.splice(i, 1);
     }
   }
   return array;
}

export function displayAlert(type,msg) {
   return async (dispatch)=> {
      // console.log("msg",msg)
      if(type==='success'){
         dispatch(setGargiKnowledgeBaseElements('successSnackbarMessage',msg));
         snackbar.successSnackbar();
       } else if(type==='error'){
         dispatch(setGargiKnowledgeBaseElements('errorSnackbarMessage',msg));
         snackbar.errorSnackbar();
      }
   }
}

export function setGargiKnowledgeBaseElements(name,content) {
   return (dispatch)=> {
      dispatch(gargiKnowledgeBaseActions.setGargiKnowledgeBaseElements(name,content))
   }
}

/**
 * Function to save particular question and answer object then display the delete confirm pop up.
 * @author Narasappa H
 * @param {object} data - Data is used for delete qna.
 * @return {void} - Nothing.
 */
export function deleteQna(data) {
   return (dispatch,getState)=> {  
      dispatch(setGargiKnowledgeBaseElements('selectedQnaForDelete',data));
      document.getElementById('deleteQnaPopup').style.display='block';
   }
}

/**
 * Function to save pagination active page.
 * @author Narasappa H
 * @param {object} activePage - ActivePage is providing particular selected page number.
 * @return {void} - Nothing.
 */
export function handlePageClick(activePage) {
   return (dispatch,getState)=> {  
      dispatch(setGargiKnowledgeBaseElements('currentPage',activePage.selected));
   }
}

/**
 * Function to change the number of qna per page.
 * @author Narasappa H
 * @param {object} e - e is a event which is used to get number of qna per page.
 * @return {void} - Nothing.
 */
export function handleChangeView(e) {
   return (dispatch,getState)=> { 
      dispatch(setGargiKnowledgeBaseElements('countPerPage',e.target.value));
      dispatch(setGargiKnowledgeBaseElements('currentPage',0));
   }
}

/**
 * Function to delete qna then call getListOfQna function and hiding delete pop up.
 * @author Narasappa H
 * @return {void} - Nothing.
 */
export function confirmDeleteQna() {
   return (dispatch,getState)=> {
      var selectedQnaForDelete= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.selectedQnaForDelete)); 
      dispatch(setGargiKnowledgeBaseElements('deleteConfirmLoader',true));
      var obj={};
      obj._id=selectedQnaForDelete._id;
      obj.adminID=selectedQnaForDelete.adminID;
      var gargiDomain =JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.gargiDomain)); 
      axios.delete(`${gargiDomain}/answer/deleteOneKBData`,{data:obj})
      .then(response=>{
         dispatch(setGargiKnowledgeBaseElements('deleteConfirmLoader',false));       
         dispatch(displayAlert('success','FAQ deleted successfully"'));
         document.getElementById('deleteQnaPopup').style.display='none';
         dispatch(setGargiKnowledgeBaseElements('selectedQnaForDelete',{}));
         dispatch(setGargiKnowledgeBaseElements('currentPage',0));
         dispatch(getListOfQna());
         console.log("response",response)
      }).catch(err=>{
         dispatch(setGargiKnowledgeBaseElements('deleteConfirmLoader',false)); 
         dispatch(displayAlert('error','Error try again.'));
         console.log("error",err)
      })

   }
}

/**
 * Function to hiding delete pop up.
 * @author Narasappa H
 * @return {void} - Nothing.
 */
export function hideDeleteQnaPopUp() {
   return (dispatch,getState)=> {
      dispatch(setGargiKnowledgeBaseElements('deleteConfirmLoader',false));
      document.getElementById('deleteQnaPopup').style.display='none';
      dispatch(setGargiKnowledgeBaseElements('selectedQnaForDelete',{}));
   }
}

/**
 * Function to hiding file upload pop up.
 * @author Narasappa H
 * @return {void} - Nothing.
 */
export function hideFileUploadQnaPopUp() {
   return (dispatch,getState)=> {
      dispatch(setGargiKnowledgeBaseElements('kbFileUploadConfirmLoader',false));
      document.getElementById('uploadQnaCsvPopup').style.display='none';
      document.getElementById('uploadKbFile').value='';
   }
}

/**
 * Function to save uploaded qna file.
 * @author Narasappa H
 * @param {object} e - e is used to save uploaded file.
 * @return {void} - Nothing.
 */
export function handleUploadFile(e) {
   return (dispatch,getState)=> {
      dispatch(setGargiKnowledgeBaseElements('kbFileObj',e.target.files[0]));
       console.log('uploaded file',e.target.files[0]);
   }
}

/**
 * Function to filter qna list then assigning to qna list array.
 * @author Narasappa H
 * @param {object} e - e.target.value is used to search in qna list.
 * @return {void} - Nothing.
 */
export function handleFilterQna(e) {
   return (dispatch,getState)=> {
   var originalListOfQnaArray= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.originalListOfQnaArray)); 
   if(originalListOfQnaArray.length>0){
      dispatch(setGargiKnowledgeBaseElements('kbSearchValueEnable',true)); 
      if(e.target.value !== null || e.target.value !== undefined || e.target.value.length>0){
         var searchedQnaList=originalListOfQnaArray.filter(el =>{
           return el.question.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) !== -1 || el.answer.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) !== -1;
         })
         dispatch(setGargiKnowledgeBaseElements('currentPage',0));
         dispatch(setGargiKnowledgeBaseElements('listOfQnaArray',searchedQnaList)) 
      }else{
         dispatch(setGargiKnowledgeBaseElements('currentPage',0));
         dispatch(setGargiKnowledgeBaseElements('kbSearchValueEnable',false)); 
         dispatch(setGargiKnowledgeBaseElements('listOfQnaArray',originalListOfQnaArray)) 
      }
   }  
     
   }
}

/**
 * Function to upload qna file then hide file upload pop up.
 * @author Narasappa H
 * @param {object} e - e is event used for preventing default method.
 * @return {void} - Nothing.
 */
export function confirmFileUploadQna(e) {
   return (dispatch,getState)=> {
      e.preventDefault()
      dispatch(setGargiKnowledgeBaseElements('kbFileUploadConfirmLoader',true));
      var selectedCourse=JSON.parse(sessionStorage.getItem('kbCourse')) 
      var kbFileObj =getState().gargiKnowledgeBaseState.kbFileObj; 
      var gargiDomain =JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.gargiDomain)); 
      const formData = new FormData();
      const configuration = { headers: { "Content-Type": "multipart/form-data" } };
      console.log('file',kbFileObj)
      formData.append('KB',kbFileObj);
      formData.append('adminID',selectedCourse._id);
      axios.post(`${gargiDomain}/answer/setKBData`,formData,configuration)
      .then(response=>{
         dispatch(setGargiKnowledgeBaseElements('kbFileUploadConfirmLoader',false)); 
         dispatch(getListOfQna());
         document.getElementById('uploadQnaCsvPopup').style.display='none';
         document.getElementById('uploadKbFile').value='';
         dispatch(displayAlert('success','File uploaded successfully'));
         console.log("response",response)
      }).catch(err=>{
         dispatch(setGargiKnowledgeBaseElements('kbFileUploadConfirmLoader',false)); 
         dispatch(displayAlert('error','Error try again.'));
         console.log("error",err)
      })
   }
}

/**
 * Function to get list of qna arrays.
 * @author Narasappa H
 * @return {void} - Nothing.
 */
export function getListOfQna() {
   return async (dispatch,getState)=> {
      dispatch(setGargiKnowledgeBaseElements('initialLoader',true)); 
      var selectedCourse=JSON.parse(sessionStorage.getItem('kbCourse')) 
      var gargiDomain =JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.gargiDomain)); 
      axios.get(`${gargiDomain}/answer/findAllKBData/${selectedCourse._id}`)
      .then(response=>{
         var data=response.data.message;      
         dispatch(setGargiKnowledgeBaseElements('showNodataFound',true));  
         if(data.length>0){
            data.reverse().filter(function(item){ return item.questionEdit=false,item.answerEdit=false});
            dispatch(setGargiKnowledgeBaseElements('listOfQnaArray',data)) 
            dispatch(setGargiKnowledgeBaseElements('originalListOfQnaArray',data)) 
         }else{
            dispatch(setGargiKnowledgeBaseElements('listOfQnaArray',[])) 
            dispatch(setGargiKnowledgeBaseElements('originalListOfQnaArray',[]))  
         }
         dispatch(setGargiKnowledgeBaseElements('initialLoader',false)); 
         dispatch(setGargiKnowledgeBaseElements('showListOfQna',true))
         dispatch(setGargiKnowledgeBaseElements('changedQnaArray',[]));
         dispatch(setGargiKnowledgeBaseElements('addGargiQuestionAndAnswer',[]));
         dispatch(setGargiKnowledgeBaseElements('showAddNewQna',false)); 
         dispatch(setGargiKnowledgeBaseElements('kbSearchValueEnable',false));      
         //console.log("response",response)
      }).catch(err=>{
         dispatch(setGargiKnowledgeBaseElements('showNodataFound',true));  
         dispatch(setGargiKnowledgeBaseElements('initialLoader',false));
         dispatch(setGargiKnowledgeBaseElements('showListOfQna',false))
         dispatch(setGargiKnowledgeBaseElements('listOfQnaArray',[]))
         dispatch(setGargiKnowledgeBaseElements('showAddNewQna',false));  
         dispatch(setGargiKnowledgeBaseElements('originalListOfQnaArray',[])) 
         dispatch(setGargiKnowledgeBaseElements('kbSearchValueEnable',false)); 

         // dispatch(displayAlert('error','Error try again.'));
         console.log("error",err)
      })
       
   }
} 

/**
 * Function to add new qna.
 * @author Narasappa H
 * @return {void} - Nothing.
 */
export function onAddNewQnA() {
   return (dispatch,getState)=> {
      dispatch(setGargiKnowledgeBaseElements('showAddNewQna',true))
      var addGargiQnaArray= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.addGargiQuestionAndAnswer)); 
      addGargiQnaArray.push({"question":undefined,"answer":undefined})
      dispatch(setGargiKnowledgeBaseElements('addGargiQuestionAndAnswer',addGargiQnaArray))     
   }
}

/**
 * Function to display qna file upload pop up.
 * @author Narasappa H
 * @return {void} - Nothing.
 */
export function uploadQnAFilePopUp() {
   return (dispatch,getState)=> {
      document.getElementById('uploadQnaCsvPopup').style.display='block';
     // dispatch(setGargiKnowledgeBaseElements('showAddNewQna',false))   
   }
}

/**
 * Function to save new question and answer value from input fields.
 * @author Narasappa H
 * @param {number} index - Particular row qna index. 
 * @param {string} name - Particular row qna name. 
 * @param {string} value - Particular row qna value. 
 * @return {void} - Nothing.
 */
export function handleChangeNewQna(index,name,value) {
   return (dispatch,getState)=> {
      var addGargiQnaArray= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.addGargiQuestionAndAnswer));    
      if(name==='question'){
         addGargiQnaArray[index][name]=value.replace(/[^a-z ]/g, "");
      }else{
         addGargiQnaArray[index][name]=value 
      }
     dispatch(setGargiKnowledgeBaseElements('addGargiQuestionAndAnswer',addGargiQnaArray))

   }
}

/**
 * Function to delete new question and answer row.
 * @author Narasappa H
 * @param {number} index - Particular row qna index. 
 * @param {string} name - Particular row qna name. 
 * @return {void} - Nothing.
 */
export function handleChangeDeleteNewQna(index,name) {
   return (dispatch,getState)=> {
      var addGargiQnaArray= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.addGargiQuestionAndAnswer)); 
      document.getElementById('question'+index).value='';
      document.getElementById('answer'+index).value='';
      addGargiQnaArray.splice(index,1);
     dispatch(setGargiKnowledgeBaseElements('addGargiQuestionAndAnswer',addGargiQnaArray))

   }
}

/**
 * Function to enable particular question input field.
 * @author Narasappa H
 * @param {number} _id - Particular question _id . 
 * @return {void} - Nothing.
 */
export function onEditQuestionEnable(_id) {
   return (dispatch,getState)=> {
      // change index to _id while integrate api
      var listOfQnaArray= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.listOfQnaArray)); 
      for(var i=0;i<listOfQnaArray.length;i++){
         if(listOfQnaArray[i]._id===_id){
            listOfQnaArray[i].questionEdit=true;
         }
      }
      
     dispatch(setGargiKnowledgeBaseElements('listOfQnaArray',listOfQnaArray))
   }
}

/**
 * Function to enable particular answer input field.
 * @author Narasappa H
 * @param {number} _id - Particular answer _id . 
 * @return {void} - Nothing.
 */
export function onEditAnswerEnable(_id) {
   return (dispatch,getState)=> {
      // change index to _id while integrate api
      var listOfQnaArray= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.listOfQnaArray)); 
      for(var i=0;i<listOfQnaArray.length;i++){
         if(listOfQnaArray[i]._id===_id){
            listOfQnaArray[i].answerEdit=true;
         }
      }
     dispatch(setGargiKnowledgeBaseElements('listOfQnaArray',listOfQnaArray))
   }
}

/**
 * Function to save edited answer or question input values into changedQnaArray variable.
 * @author Narasappa H
 * @param {object} e - e is used to get edited value. 
 * @param {object} data - Data is particular qna object. 
 * @return {void} - Nothing.
 */
export function onEditQuestionAndAnswer(e,data) {
   return (dispatch,getState)=> {
      e.preventDefault(); 
      var changedQnaArray= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.changedQnaArray)); 
      data[e.target.name]=e.target.value;
      if(e.target.name==='question'){
         data[e.target.name]=e.target.value.replace(/[^a-z ]/g, "");
      }else{
         data[e.target.name]=e.target.value;
      }
      changedQnaArray.push(data);
      changedQnaArray = uniqueArray(changedQnaArray,'_id');
      dispatch(setGargiKnowledgeBaseElements('changedQnaArray',changedQnaArray))
   }
}


/**
 * Function to validating edited answer or question input values.
 * @author Narasappa H
 * @param {number} i - i is used to get index of edited value. 
 * @param {object} e - e is used to get edited value. 
 * @return {void} - Nothing.
 */
export function onEditQuestionAndAnswerWithValidation(e,data) {
   return (dispatch,getState)=> {
      e.preventDefault();
      var listOfQnaArray= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.listOfQnaArray)); 
      for(var i=0;i<listOfQnaArray.length;i++){
         if(listOfQnaArray[i]._id===data._id){
            if(e.target.name==='question'){
               listOfQnaArray[i][e.target.name]=e.target.value.replace(/[^a-z ]/g, "");
            }else{
               listOfQnaArray[i][e.target.name]=e.target.value;
            }
         }
      }
      dispatch(setGargiKnowledgeBaseElements('listOfQnaArray',listOfQnaArray))
   }
}

/**
 * Function to save new qna array.
 * @author Narasappa H
 * @param {array} finalQnaArray - FinalQnaArray is list of newly added qna array. 
 * @return {void} - Nothing.
 */
export function onPutNewQna(finalQnaArray) {
   return async (dispatch,getState)=> {
         if(finalQnaArray.length>0){
            dispatch(setGargiKnowledgeBaseElements('saveAndTrainLoader',true));
            var selectedCourse=JSON.parse(sessionStorage.getItem('kbCourse')) 
            var obj={
               questions:finalQnaArray,
               adminID:selectedCourse._id
            }
            var gargiDomain =JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.gargiDomain)); 
            axios.put(`${gargiDomain}/answer/setKBArray`,obj)
            .then(response=>{
               dispatch(setGargiKnowledgeBaseElements('saveAndTrainLoader',false)); 
               dispatch(getListOfQna());
               dispatch(setGargiKnowledgeBaseElements('addGargiQuestionAndAnswer',[]));
               dispatch(setGargiKnowledgeBaseElements('showAddNewQna',false));
               dispatch(displayAlert('success','FAQ added successfully'));
              // console.log("response",response)
            }).catch(err=>{
               dispatch(setGargiKnowledgeBaseElements('saveAndTrainLoader',false)); 
               dispatch(displayAlert('error','Error try again.'));
               console.log("error",err)
            })
      }
   }
}

/**
 * Function to update new edited qna list.
 * @author Narasappa H
 * @return {void} - Nothing.
 */
export function onUpdateQna() {
   return async (dispatch,getState)=> {
      dispatch(setGargiKnowledgeBaseElements('saveAndTrainLoader',true)); 
      var gargiDomain =JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.gargiDomain)); 
      var changedQnaArray= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.changedQnaArray)); 
      for(var k=0;k<changedQnaArray.length;k++){
         var temp={};
         temp._id=changedQnaArray[k]._id;
         temp.update={
            question:changedQnaArray[k].question,
            answer:changedQnaArray[k].answer
         };      
       axios.put(`${gargiDomain}/answer/updateOneKBData`,temp)
      .then(response=>{
         dispatch(setGargiKnowledgeBaseElements('saveAndTrainLoader',false)); 
         dispatch(getListOfQna());
         dispatch(setGargiKnowledgeBaseElements('changedQnaArray',[]));
         dispatch(setGargiKnowledgeBaseElements('showAddNewQna',false));
         dispatch(displayAlert('success','FAQ updated successfully'));
         console.log("response",response)
      }).catch(err=>{
         dispatch(setGargiKnowledgeBaseElements('saveAndTrainLoader',false)); 
         dispatch(displayAlert('error','Error try again.'));
         console.log("error",err)
      })
   }

   }
}

/**
 * Function to save and train gargi knowledge base.
 * @author Narasappa H
 * @return {void} - Nothing.
 */
export function onSaveGargiQna() {
   return (dispatch,getState)=> {
      var qnaArrayToPut=[];
      var addGargiQnaArray= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.addGargiQuestionAndAnswer)); 
      var changedQnaArray= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.changedQnaArray)); 
      if(addGargiQnaArray.length>0){
         for(var i=0;i<addGargiQnaArray.length;i++){
            if((addGargiQnaArray[i].question===undefined || addGargiQnaArray[i].question==="") && (addGargiQnaArray[i].answer===undefined || addGargiQnaArray[i].answer==="")){
               // if(i>=addGargiQnaArray.length-1){
               //    dispatch(onPutNewQna(qnaArrayToPut));
               //    dispatch(displayAlert('error','FAQ must have both question and answer!'));
               //    break;
               // }else{
               //    continue;
               // }
               
               dispatch(displayAlert('error','FAQ must have both question and answer!'));
               break;
            }else if((addGargiQnaArray[i].question===undefined || addGargiQnaArray[i].question==="") || (addGargiQnaArray[i].answer===undefined || addGargiQnaArray[i].answer==="")){
               dispatch(displayAlert('error','FAQ must have both question and answer!'))
               break;
            }else{
               qnaArrayToPut.push(addGargiQnaArray[i]);
            }
            if(i===addGargiQnaArray.length-1){
               dispatch(onPutNewQna(qnaArrayToPut));
               if(changedQnaArray.length>0){
                  dispatch(onUpdateQna());
                }
            }
         }
      }else{
      if(changedQnaArray.length>0){
         dispatch(onUpdateQna());
       }
      }
      
   }
}

// unanswered question functions start here

export function getAllUnansweredQuestions() {
   return async(dispatch,getState)=> {
      var selectedCourse=JSON.parse(sessionStorage.getItem('kbCourse')) 
      try{
         let response = await customHttp.get('/api/faq/fetchUnansweredQuestions/'+selectedCourse._id);     
         if(response.data.message !=="Questions not found"){
            dispatch(setGargiKnowledgeBaseElements('unansweredQuestionArray',response.data.message))
         }else{
            dispatch(setGargiKnowledgeBaseElements('unansweredQuestionArray',[]))
         }
      }
      catch(err){
         dispatch(setGargiKnowledgeBaseElements('unansweredQuestionArray',[]))
         console.log("err",err)
      }
   }
}

export function toggleShowUnansweredQuestion() {
   return (dispatch,getState)=> {
      var showUnansweredQuestion= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.showUnansweredQuestion));
      if(!showUnansweredQuestion){
       dispatch(getAllUnansweredQuestions())  
      }
      dispatch(setGargiKnowledgeBaseElements('showUnansweredQuestion',!showUnansweredQuestion))
     
   }
}

export function showUnansweredQuestionPopup(data) {
   return (dispatch,getState)=> {      
      dispatch(setGargiKnowledgeBaseElements('unansweredQuestion',data))
      document.getElementById('unansweredQuestionPopup').style.display='block';
      document.getElementById('answeredQuestion').style.height='0px';
      document.getElementById('unansweredQuestion').style.height='0px';
   }
}

export function handleChangeUnansweredQuestion(e) {
   return (dispatch,getState)=> { 
      e.preventDefault(); 
      var unansweredQuestion= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.unansweredQuestion)); 
      unansweredQuestion[e.target.name]=e.target.value;
      dispatch(setGargiKnowledgeBaseElements('unansweredQuestion',unansweredQuestion))
   }
}


export function hideUnansweredPopup() {
   return (dispatch,getState)=> {    
      document.getElementById('unansweredQuestionPopup').style.display='none';
      dispatch(setGargiKnowledgeBaseElements('unansweredQuestion',{}))
   }
}

export function saveUnansweredQuestion(e) {
   return async(dispatch,getState)=> {
      e.preventDefault();
      var obj=[];
      var unansweredQuestionObj= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.unansweredQuestion)); 
      obj.push({question:unansweredQuestionObj.question,answer:unansweredQuestionObj.answer})
      dispatch(onPutNewQna(obj));
      dispatch(setGargiKnowledgeBaseElements('editUnansweredPopLoader',true))
      try{
         let response = await customHttp.put('/api/faq/updateUnansweredQuestions',unansweredQuestionObj);     
      //   console.log("res",response)
        dispatch(setGargiKnowledgeBaseElements('editUnansweredPopLoader',false)) 
        dispatch(setGargiKnowledgeBaseElements('unansweredQuestion',{})) 
        dispatch(getAllUnansweredQuestions()) 
        document.getElementById('unansweredQuestionPopup').style.display='none';
      }
      catch(err){
         dispatch(setGargiKnowledgeBaseElements('editUnansweredPopLoader',false)) 
         console.log("err",err)
      }
   }
}

export function showDeleteUnansweredQuestionPopup(data) {
   return (dispatch,getState)=> {      
      dispatch(setGargiKnowledgeBaseElements('deleteUnansweredQuestionObj',data))
      document.getElementById('deleteUnansweredQuestionPopup').style.display='block';
   }
}
export function hideDeleteUnansweredQuestionPopup() {
   return (dispatch,getState)=> {    
      document.getElementById('deleteUnansweredQuestionPopup').style.display='none';
      dispatch(setGargiKnowledgeBaseElements('deleteUnansweredQuestionObj',{}))
   }
}
export function deleteUnansweredQuestionConfirm() {
   return async(dispatch,getState)=> {
      dispatch(setGargiKnowledgeBaseElements('deleteUnansweredQuestionConfirmLoader',true))  
      var deleteUnansweredQuestionObj= JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.deleteUnansweredQuestionObj)); 
      try{
         let response = await customHttp.delete('/api/faq/delete',deleteUnansweredQuestionObj);     
      //   console.log("res",response)
        dispatch(setGargiKnowledgeBaseElements('deleteUnansweredQuestionConfirmLoader',false))
        document.getElementById('deleteUnansweredQuestionPopup').style.display='none';
        dispatch(setGargiKnowledgeBaseElements('deleteUnansweredQuestionObj',{}))
        dispatch(displayAlert('success','Question deleted successfully'));
        dispatch(getAllUnansweredQuestions());
      }
      catch(err){
         dispatch(setGargiKnowledgeBaseElements('deleteUnansweredQuestionConfirmLoader',false))
         dispatch(displayAlert('error','Error try again.'))
         console.log("err",err)
      }
   }
}

// export function allowDrop(ev) {
//    return (dispatch,getState)=> {
//       ev.preventDefault();
//    }
// }


// export function drag(ev,index)  {
//    return (dispatch,getState)=> {
//       console.log("drag index",index)
//       ev.dataTransfer.setData("index", index);
//    }
// }
// export function drop(ev,index) {
//    return (dispatch,getState)=> {
//       ev.preventDefault();
//       console.log("drag index1",index)
//       var arrayvalue=JSON.parse(JSON.stringify(getState().gargiKnowledgeBaseState.nameerui)); 
//       var newIndex=ev.dataTransfer.getData("index");
//      // arrayvalue.splice(index,1);
//       var oldIndex=arrayvalue.splice(newIndex,1)[0];
//       arrayvalue.splice(index,0,oldIndex);
//       console.log("ertyuiopoiuytr",arrayvalue,newIndex,oldIndex)
//       dispatch(setGargiKnowledgeBaseElements('nameerui',arrayvalue));
//    }
// }
