import * as SharedResourceActionTypes from "../../../actionTypes";


export const AssignValue = (name,value) => ({
  type: SharedResourceActionTypes.SHARED_RESOURCE_ASSIGN_VALUE,
  payload: {
    name:name,
    value:value
  }
});


