import * as editPlaylistActionTypes from "../../../actionTypes";

export const setDisciplines = content => ({
  type: editPlaylistActionTypes.CREATE_PLAYLIST_DISCIPLINES,
  payload: {content}
});

export const setContentTypes = content => ({
  type: editPlaylistActionTypes.CREATE_PLAYLIST_CONTENT_TYPES,
  payload: {content}
});

export const setEditPlaylistElements = (name,content )=> ({
  type: editPlaylistActionTypes.CREATE_PLAYLIST_COMPONENT_ELEMENTS,
  payload: {name,content}
})