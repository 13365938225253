import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import customHttp from "../../../packages/CustomHttp";

function PortRules() {
  var userData=JSON.parse(sessionStorage.getItem("userData"));
  const appLanguage = JSON.parse(localStorage.getItem("appLanguage"));
  const [portRuleList, setPortRuleList] = useState([]);
  const [successAlertMessage, setSuccessAlertMessage] = useState('')
  const [errorAlertMessage, setErrorAlertMessage] = useState('')
  const portRuleObj = {name:'',direction:'inbound',protocol:'tcp',action:'allow',port:''}
  const [portRule, setPortRule] = useState(portRuleObj);
  const [submitRequestLoader, setSubmitRequestLoader] = useState(false)
  const [updatePortLoader, setUpdatePortLoader] = useState(false)
  const [selectedPortToEdit, setSelectedPortToEdit] = useState({});
  const [selectedPortAction,setSelectedPortAction] = useState('');
  const [invalidPort, setInvalidPort] = useState(false);
  const [portNameExists, setPortNameExists] = useState(false);

  useEffect( () => {
    getAllPortRules()
  }, []);

  var getAllPortRules = async () => {
    try {
      var getAllPortRulesObj = {};
      getAllPortRulesObj.url = '/api/dmz/port/fetchAllDmz';
      var response = await customHttp.get(getAllPortRulesObj.url)
      if(response.data.message!==undefined && response.data.message.length>0){
        let temp=response.data.message
        setPortRuleList(temp.reverse())
      } else {
        setPortRuleList([])
      }
    } catch (err){
      console.log("failed",err.response)
    }
  }

  var checkIsUnique = async (name) => {
    try {
      var checkIsUniqueObj = {};
      checkIsUniqueObj.url = '/api/dmz/port/checkDmzName/'+name;
      var response = await customHttp.get(checkIsUniqueObj.url)
      if(response.data.message){
        setPortNameExists(response.data.message.nameExist)
      } else {
        setPortNameExists(false)
      }
    } catch (err){
      console.log("failed",err.response)
    }
  }

  var handleChange = (e) => {
    if(e.target.name==='port'){
      setInvalidPort(false);
    } else if(e.target.name==='name'){
      checkIsUnique(e.target.value)
    }
    setPortRule({...portRule,[e.target.name]:e.target.value})
  }

  var handleUpdatePortChange = (e) => {
    setSelectedPortAction(e.target.value);
  }
  var checkIsValidPort = (port) => {
    var re = new RegExp(/^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/);
    return port.match(re);
  }
  var handleBlur = (e) => {
    if(e.target.name==='port' && e.target.value.length>0){
      setInvalidPort(!checkIsValidPort(e.target.value))
    }
  }

  var handleSubmit = async (e) => {
    e.preventDefault();
    setInvalidPort(!checkIsValidPort(portRule.port))
    var handleSubmitObj = {};
    handleSubmitObj.url = '/api/dmz/port/addDmz/';
    handleSubmitObj.data = {};
    handleSubmitObj.data.name = portRule.name;
    handleSubmitObj.data.port = portRule.port;
    handleSubmitObj.data.protocol = portRule.protocol;
    handleSubmitObj.data.action = portRule.action;
    handleSubmitObj.data.direction = portRule.direction;
    handleSubmitObj.data.userId = userData._id;
    if(!portNameExists && handleSubmitObj.data.name!=='' && handleSubmitObj.data.name!==undefined && handleSubmitObj.data.port!=='' && checkIsValidPort(handleSubmitObj.data.port) && handleSubmitObj.data.port!==undefined && handleSubmitObj.data.protocol!=='' && handleSubmitObj.data.protocol!==undefined && handleSubmitObj.data.action!=='' && handleSubmitObj.data.action!==undefined && handleSubmitObj.data.direction!=='' && handleSubmitObj.data.direction!==undefined && handleSubmitObj.data.userId!=='' && handleSubmitObj.data.userId!==undefined){
      setSubmitRequestLoader(true)
      try {
        var response = await customHttp.post(handleSubmitObj.url,handleSubmitObj.data)
    
        setSubmitRequestLoader(false)
        myFunction();
        setSuccessAlertMessage("Rule Added Successfully!");
        setPortRule(portRuleObj)
        getAllPortRules()
      } catch (err){
        setErrorAlertMessage("Error try again!");
        myFunction1()
        setSubmitRequestLoader(false);
        console.log("failed",err.response)
      }
    }
  }

  var setEditPortRule = (port) => {
    setSelectedPortToEdit(port);
    setSelectedPortAction(port.action);
  }

  var UpdatePortRule = async () => {
    var object = JSON.parse(JSON.stringify(selectedPortToEdit));
    object.action = selectedPortAction;
    delete object.userId;
    delete object._id;
    //setSelectedPortToEdit(object)
    try {
      var UpdatePortRuleObj = {};
      UpdatePortRuleObj.url = "/api/dmz/port/updateDmz/";
      UpdatePortRuleObj.data = {};
      UpdatePortRuleObj.data = object;
      setUpdatePortLoader(true)
      var response = await customHttp.put(UpdatePortRuleObj.url,UpdatePortRuleObj.data)
      myFunction();
      setSuccessAlertMessage("Firewall Action Updated successfully");
      setSelectedPortToEdit({});
      setUpdatePortLoader(false)
      getAllPortRules()
    } catch (err){
      setErrorAlertMessage("Error try again!");
      myFunction1()
      console.log("failed",err.response);
      setUpdatePortLoader(false)
    }
  }

  var cancelUpdatePortRule = () => {
    setSelectedPortToEdit({})
  }
  var myFunction=()=> {
    var x = document.getElementById("snackbar")
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }

  var myFunction1=()=> {
    var x = document.getElementById("snackbar1")
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }

  return (
    <div>
      <div id="snackbar">{successAlertMessage}</div>
      <div id="snackbar1"><i className="fa fa-exclamation"></i>{errorAlertMessage}</div>
      <div className="col-md-8" style={{paddingLeft:"0px"}}>
      <div className="col-md-12 nj-card" style={{overflow:'auto',margin:'10px 0',maxHeight:'78vh'}}>
        <div className="row">
          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4>Firewall Rules</h4>
            </div>
          </div>
          {portRuleList.length>0 ? <div className="nj-table--tbody">
            <table className="nj-table nj-table--tbody-table font_family_montserrat">
              <thead className="nj-table--thead">
                <tr>
                  <th><div className="nj-table-text  nj-padding-10">NAME</div></th>
                  <th><div className="nj-table-text nj-padding-10">PORT</div></th>
                  <th><div className="nj-table-text nj-padding-10">DIRECTION</div></th>
                  <th><div className="nj-table-text nj-padding-10">PROTOCOL</div></th>
                  <th><div className="nj-table-text nj-padding-10">ACTION</div></th>
                  <th><div className="nj-table-text nj-padding-10">UPDATE</div></th>
                </tr>
              </thead>
              <tbody>
                {portRuleList.map((port,index)=>{
                  return (<tr key={index} className={"tr-values nj-table--tr tr-table-state-null"}>
                    <td className="td nj-table--td nj-padding-10">{port.name}</td>
                    <td className="td nj-table--td nj-padding-10">{port.port}</td>
                    <td className="td nj-table--td nj-padding-10" >{port.direction}</td>
                    <td className="td nj-table--td nj-padding-10" style={{textTransform:'uppercase'}}>{port.protocol}</td>
                    <td className="td nj-table--td nj-padding-10" style={{minWidth:123}}>
                      {Object.keys(selectedPortToEdit).length>0 && selectedPortToEdit._id === port._id ? <span>
                        <div className="nj-form-select">
                          <select name="action" value={selectedPortAction} onChange={handleUpdatePortChange} autoComplete="off" required>
                            <option value="allow">Allow</option>
                            <option value="block">Block</option>
                          </select>
                        </div>
                      </span>:
                      <span style={{textTransform:'capitalize'}}>{port.action+'ed'}</span>}
                    </td>
                    <td className="td nj-table--td nj-padding-10">
                      {Object.keys(selectedPortToEdit).length>0 && selectedPortToEdit._id === port._id ? <span>
                        {updatePortLoader ? <img src={"./img/loaders/spinningwheel.gif"} height={60} width={65} alt="loader"/>:
                        <div className="cpa-update-dmz-rule">
                          <button type="button" className="nj-flow-button" onClick={()=>UpdatePortRule(port)} style={{marginRight:5,padding:'0.5rem 1rem'}}>Save</button>
                          <button type="button" className="nj-flow-button bg-color-red" onClick={cancelUpdatePortRule} style={{padding:'0.5rem 1rem'}}>Cancel</button>
                        </div>}
                      </span>:
                      <div className="cpa-update-dmz-btn-wrapper">
                        <button type="button" className="nj-flow-button" disabled={updatePortLoader} onClick={()=>setEditPortRule(port)}>Update</button>
                      </div>}
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>
          :
          <div className="col-md-12" style={{marginTop:"15vh",height:"35vh"}}>
            <p style={{float:"none",textAlign:"center",fontSize:"18px",marginTop:"50px"}} className="no-user-found-text font_family_montserrat">No rule found</p>
          </div>
          }
        </div>
      </div>
      </div>
      <div className="col-md-4">
        <div className="row nj-card nj-cp-request-app-card" style={{marginTop:10}}>
          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4>Add a rule</h4>
            </div>
          </div>
          <form name="requestForm" onSubmit={handleSubmit}>
            <div className="nj-popup-form-wrap">
              <p className="popup-content-label">Name</p>
              <input className="vs-input--input" type="text" name="name" value={portRule.name} onChange={handleChange} placeholder="Ex: Port_1337" autoComplete="off" required />
                {portNameExists && <span className="color-red" style={{color:"red"}}>Port already exists</span>}
            </div>
            <div className="nj-popup-form-wrap">
              <p className="popup-content-label">Port</p>
              <input className="vs-input--input" type="text" name="port" value={portRule.port} onChange={handleChange} autoComplete="off" placeholder="Ex: 1337" onBlur={handleBlur} required />
              {invalidPort && <span className="color-red" style={{color:"red"}}>Port</span>}
            </div>
            <div className="nj-popup-form-wrap">
              <p className="popup-content-label">Direction</p>
              <div className="nj-form-select">
                <select name="direction" value={portRule.direction} onChange={handleChange} autoComplete="off" required>
                  <option value="inbound">inbound</option>
                  <option value="outbound">outbound</option>
                </select>
              </div>
            </div>
            <div className="nj-popup-form-wrap">
              <p className="popup-content-label">Protocol</p>
              <div className="nj-form-select">
                <select name="protocol" value={portRule.protocol} onChange={handleChange} autoComplete="off" required>
                  <option value="tcp">TCP</option>
                  <option value="udp">UDP</option>
                </select>
              </div>
            </div>
            <div className="nj-popup-form-wrap">
              <p className="popup-content-label">Action</p>
              <div className="nj-form-select">
                <select name="action" value={portRule.action} onChange={handleChange} autoComplete="off" required>
                  <option value="allow">Allow</option>
                  <option value="block">Block</option>
                </select>
              </div>
            </div>
            <div style={{margin:'0 10px 10px 10px',display:'flex',justifyContent:'flex-end'}}>
              {!submitRequestLoader ? <button type="submit" className="nj-flow-button  pull-right"  style={{margin:5}}>Submit</button>:<img  className="pull-right" src={"./img/loaders/spinningwheel.gif"} height={60} width={65} alt="loader" />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PortRules;



