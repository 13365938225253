import * as courseAnalyticsActionTypes from "../../../actionTypes";

export const setCourseAnalyticsElements = (name,content )=> ({
  type: courseAnalyticsActionTypes.COURSE_ANALYTICS_COMPONENT_ELEMENTS,
  payload: {
    name,value:content
  }
})

export const setSearchUserName = (content )=> ({
  type: courseAnalyticsActionTypes.COURSE_ANALYTICS_SETSEARCHUSERNAME,
  payload: {
  content
  }
})



export const getAllCourses = (data) => ({
  type: courseAnalyticsActionTypes.GET_ALL_COURSES,
  payload: {
    content:data
  }
});

export const getUsersByCourse1 = (data) => ({
  type: courseAnalyticsActionTypes.GET_SELECTED_USER_COURSES,
  payload: {
    content:data
  }
});

export const setBooleanValue = (name,value) => ({
  type: courseAnalyticsActionTypes.COURSE_SET_BOOLEAN_VALUE,
  payload: {
    name:name,
    value:value
  }
});

export const searchRequestedContent = (searchRequestedArray) => ({
  type: courseAnalyticsActionTypes.SEARCH_USERS_NAME,
  payload: {
    searchRequestedArray
  }
});
