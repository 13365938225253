import React ,{useState, useEffect} from 'react';
import { connect } from "react-redux";
import * as RequestedContentContainer from "../../container/actions/RequestedContent/action";
import * as resourceContainer from '../../container/actions/Resources/action';
import * as resourceActions from '../../container/actions/Resources/'
import RequestResource from '../Resources/RequestResource';
import Store from "../../container/reducers/Store";
import * as Actions from "../../container/actions/RequestedContent";
import "./RequestedContent.scss";
import { bindActionCreators } from 'redux';
import Datetime from 'react-datetime';

import Multiselect from './MultiSelect';

function RequestedContent (props) {
    //const [shouldPopupAnimate, SetShouldPopupAnimate] = useState(false);
    const[comment , SetComment] = useState("");
    // const[index , Setindex] = useState("");
    const[submitComment,SetSubmitComment] = useState({});
    const [searchValue , setSearchValue] = useState('');
    const [data ,setData] = useState("");
    // const [fetchComments,setFetchComments] = useState({});
    // const [alertMessage,setAlertMessage] = useState('');
    // const [dateAndTime,setdateAndTime] = useState(new Date());
    const [updateDateAndTime,setUpdateDateAndTime] = useState("")
    const obj = {"approve":"","create":"","produce":"","review":"","date":"","time":""}
    const [dropdownArray , setdropdownArray] = useState(obj);
    const updateObj = {"create":"","produce":"","review":"","approve":"","date":"","time":""}
    const [updateDropdownArray , setupdateDropdownArray] = useState(updateObj);
    // const [upload,setUpload] = useState({});
    const obj1 = {"subject":"","body":"","attachments":[],"recipients":[],};
    const [notify , SetNotify] = useState(obj1);
    const [multiSelectClearCheck, SetmultiSelectClearCheck] = useState(false);
    const [dropdownValue,setDropDownValue] = useState(false);
    const [requestUpload,setrequestUpload] = useState([]);
    // const [baseUrl,SetbaseUrl] = useState("");
    const [enableNotifySend, SetEnableNotifySend]= useState(true);
    // var StatusArray=[{"name":"In Progress"},{"name":"Done"},{"name":"Pending"}];
    const [showViewForm,setShowViewForm] = useState("");
    var DummyStatusArray=[{"name":"open"},{"name":"create"},{"name":"produce"},{"name":"review"},{"name":"approve"}];
    const [currentRequestData,setCurrentRequestData] = useState([]);
    const [currentStatus,setCurrentStatus] = useState("")
    
    // const [date,setDate] = useState("")
    var userDetails=JSON.parse(sessionStorage.getItem("userData"));
    // var animationFunctionForPopup=()=>{
    //   SetShouldPopupAnimate(true);
    // }

    var setmultiSelectClearCheck=()=>{
      SetmultiSelectClearCheck(false);
    }

    useEffect( () => {
      props.getRequestedContent();
      props.getAssignApproveReview();
      props.getAssignCreateProduce();
      Store.dispatch(resourceActions.toggleRequestResource(false));
      props.getRecepients();
      const url = window.location.href;
      const startChar = url.indexOf('/', 8);
      // const path = url.substr(0,startChar);
    // this.setState({baseUrl:path},()=>{
    //   //console.log("baseUrl",this.state.baseUrl)
    // })
    // SetbaseUrl(path);
    // console.log("baseurl",baseUrl);
      },[]);

    var handleChangeComment1=(e)=>{
      SetComment(e.target.value)
    }

    var hideDeletePopUp=(e)=>{
      e.preventDefault();
      setData("");
      document.getElementById("deleteRequestedPopup").style.display='none'
    }

    var openPopUp=(data)=>{
      SetSubmitComment(data);
      document.getElementById("addPopup").style.display="block";
    }

    var OpenViewPopUp=(data)=>{
      SetSubmitComment(data);
      props.getRequestedComment(data);
      // console.log("data",data);
      
      // var fetchComments1  = props.RequestedContentState.savedComments;
      var fromDateInSec = new Date(data.createdAt);
      var tempDate = fromDateInSec.getDate();
      var tempMonth = fromDateInSec.getMonth()+1;
      var tempYear = fromDateInSec.toString().substring(11,15);
      // var fromDate = tempYear + "-" + tempMonth + "-" + tempDate;
      // var date = new Date(data.createdAt).toString();
      // setDate(fromDate);
      document.getElementById("viewPopup").style.display="block";
    }

    var handleSubmitComment=(e,data)=>{
      e.preventDefault();
      props.handleChangeComment(submitComment,comment);
      SetComment("");
      // setAlertMessage('Comment added successfully');
      // successSnackbar();
      document.getElementById("addPopup").style.display="none";


    }

    var deleteRequestedContent=(e,data)=>{
      e.preventDefault();

      props.deleteRequestedContent(e,data);
      document.getElementById("deleteRequestedPopup").style.display="none";
  }
    var handleSearchChange = (e) => {
      // console.log("qwertyuio",e.target.value)
      // var search = e.target.value;
      setSearchValue(e.target.value)
      searchFilter(e.target.value)
  };

  var searchFilter=(data)=>{
    // props.getRequestedContent()
    var dublicateArray=props.RequestedContentState.dupRequestedContent;
    var searchRequestedArray=dublicateArray.filter(el =>{
     return el.email.toLowerCase().indexOf(data.toString().toLowerCase()) !== -1;
    })

    props.searchRequestedContent(searchRequestedArray);
    // console.log("applicationArray1",searchRequestedArray);
  }


  var getConfirmDeletePopup= async(data)=>{
    setData(data);
    document.getElementById("deleteRequestedPopup").style.display="block"
  }

  // const successSnackbar=()=> {
  //   var x = document.getElementById("snackbar")
  //   x.className = "show";
  //   setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  // }

  // const errorSnackbar=()=> {
  //   var x = document.getElementById("snackbar1")
  //   x.className = "show";
  //   setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  // }

  var openUploadPopup=(data)=>{
    // console.log("dataaaa",data)
    setData(data);
    document.getElementById('uploadPopup').style.display='block';
  }

  var openAssignPopup=(data)=>{
    // console.log("dataaaa",data)
    if(data.assignWorkFlow.length === 0){
      setData(data);
      document.getElementById('assignPopup').style.display='block';
    }else{
      setData(data);
      document.getElementById('assignPopup').style.display='block';
      setShowViewForm(true)
    }
  }

  var showDropDowns=()=>{
    var updateObj = {};
    updateObj.approve = data.assignWorkFlow[0].approve;
    updateObj.create = data.assignWorkFlow[0].create;
    updateObj.produce = data.assignWorkFlow[0].produce;
    updateObj.review = data.assignWorkFlow[0].review;

    var a = data.assignWorkFlow[0].scheduleTime
    // console.log("msg",data.assignWorkFlow[0].scheduleTime,data.assignWorkFlow[0].scheduleDate);
    var b = toDate(a, "hh:mm")
    // console.log("time date",b,new Date());

    setUpdateDateAndTime(b)
    // console.log("updateObj",updateObj,b);
    setupdateDropdownArray(updateObj)
    setShowViewForm(false)
  }

var toDate = (a, format) => {
  var now = new Date(data.assignWorkFlow[0].scheduleDate);
  if (format === "hh:mm") {
    now.setHours(a.substr(0, a.indexOf(":")));
    now.setMinutes(a.substr(a.indexOf(":") + 1));
    now.setSeconds(0);
    return now;
  } else {
    return "Invalid Format";
  }
}

  const openNotifyPopUp=(notifyData)=>{
    props.getRecepients();
    setDropDownValue(true);
    document.getElementById("file-upload-wrapper1").setAttribute("data-text","Upload File");
    setData(notifyData);
    document.getElementById("notifyPopup").style.display="block";
  }

  var handleChangeNotify=(event)=>{
    SetNotify({...notify,[event.target.name]:event.target.value});

  }

  var handleSelectRecepients=(params)=>{
    // console.log("params",params);
    SetNotify({...notify,["recipients"]:params});
    if(params.length===0){
      SetEnableNotifySend(true);
    }
    else{
      SetEnableNotifySend(false);
    }
  }

  var clearNotifyInputFields=()=>{
    SetNotify({"subject":"","body":"","attachments":[],"recipients":[]});
    document.getElementById("file-upload-wrapper1").setAttribute("data-text","Upload File");
    document.getElementById("file-upload").value="";
    SetmultiSelectClearCheck(true);
  }

  var getUploadedFile=(e)=>{
    if(e.target.files[0] !== undefined){
      document.getElementById("file-upload-wrapper1").setAttribute("data-text", e.target.files[0].name);
      SetNotify({...notify,["attachments"]:[e.target.files[0]]});
    }else{
      document.getElementById("file-upload-wrapper1").setAttribute("data-text","No file chosen");
    }
  }

  var createSubmit = (event) =>{
    var popId="notifyPopup";
    event.preventDefault();
     props.sendNotification(event,data,notify,popId,props);
    //  console.log("notify data",notify);
    clearNotifyInputFields();
    // document.getElementById("notifyPopup").style.display="none";
  }

  var handleChange=(event)=>{
    // setrequestUpload({...requestUpload,[event.target.name]:[event.target.files[0]]});
    setrequestUpload([event.target.files[0]]);
  }

  // var updateState=(date)=> {
  //   // console.log(date);
  // }

   var submitAssignForm = (event) =>{
      event.preventDefault()
      props.sendAssignWorkflow(dropdownArray,data)
     setdropdownArray({"approve":"","create":"","produce":"","review":"","date":"","time":""});
     //document.getElementById('assignPopup').style.display='none';
  }

  var submitUpdatedAssignForm = (event) =>{
     event.preventDefault();
     props.sendUpdatedAssignWorkflow(updateDropdownArray,data)
     setupdateDropdownArray({"approve":"","create":"","produce":"","review":"","date":"","time":""});
    //document.getElementById('assignPopup').style.display='none';
 }

  var handleChangeDropdown =(event) =>{
    var temp = JSON.parse(event.target.value);
    setdropdownArray({...dropdownArray,[event.target.name]:temp});
    // console.log("assign data",dropdownArray);
  }

  var handleChangeDropdownUpdate =(event) =>{
    var temp = JSON.parse(event.target.value);
    setupdateDropdownArray({...updateDropdownArray,[event.target.name]:temp});
  }

 var  handleDateTimePicker = (moment, name) =>{
    // console.log("date value", moment.toDate())
    var n = moment.toDate();
    var currentDate = n.getDate();
    if(currentDate<10){
      currentDate='0'+currentDate;
    }
    var currentMonth = n.getMonth() + 1; //Months are zero based
    if(currentMonth<10){
    currentMonth='0'+currentMonth;
    }
    var currentYear = n.getFullYear();
    var fromDate = currentYear + "-" + currentMonth + "-" + currentDate; // date conversion dd/mm/yyyy to yyyy-mm-dd
    // console.log("final date", fromDate);

    var time = moment.toDate();
    time = time.toString();
    time  = time.substr(16,5);
    // console.log("time",time);
    setdropdownArray({...dropdownArray,["date"]:fromDate,["time"]:time});
 }

 var  handleDateTimePickerUpdate = (moment, name) =>{
    // console.log("date value", moment.toDate())
    var n = moment.toDate();
    var currentDate = n.getDate();
    if(currentDate<10){
      currentDate='0'+currentDate;
    }
    var currentMonth = n.getMonth() + 1; //Months are zero based
    if(currentMonth<10){
    currentMonth='0'+currentMonth;
    }
    var currentYear = n.getFullYear();
    var fromDate = currentYear + "-" + currentMonth + "-" + currentDate; // date conversion dd/mm/yyyy to yyyy-mm-dd
    // console.log("final date", fromDate);

    var time = moment.toDate();
    time = time.toString();
    time  = time.substr(16,5);
    // console.log("time",time);
    setupdateDropdownArray({...updateDropdownArray,["date"]:fromDate,["time"]:time});
 }

 var handleUploadSumbit = (e) =>{
    e.preventDefault();
    // console.log("dataaaaa",data)
    var popId = "uploadPopup";
    props.getUploadRequestedContent(e,data,requestUpload,popId);
    // document.getElementById('uploadPopup').style.display='none'
    // console.log("files",requestUpload);
    clearPopup();
 }

 var clearPopup = ()=>{
  // props.setrequestUpload('resource',{file:{}})
  setrequestUpload("");
  document.getElementById('fileUpload').value='';
}

 var viewFiles=(data)=>{
   setData(data);
  //  console.log("baseurl",baseUrl);
   document.getElementById('downloadPopup').style.display="block";
 }

  var yesterday = Datetime.moment().subtract( 1, 'day' );
  var valid = function( current ){
    return current.isAfter( yesterday );
  };

  var openStatusProgressPopUp=(data)=>{
    // console.log("data",data);
  
    setData(data);
  
    var length = data.status.length;
    var status1 = data.status[length-1].status;
    // console.log("status1",data.status[length-1].status,data);
    // setStatus(status1);
    // if(data.assignWorkFlow!==undefined && data.assignWorkFlow.length>0){
    //   toShowStatus(status1);
    // }
    // console.log("dtatatatata",data.status[length-1])
    setCurrentRequestData(data.status[length-1])
    setCurrentStatus(status1)
    toShowStatus(status1);
    document.getElementById('statusProgress').style.display='block';
  }

  var toShowStatus=(status)=> {
    var arrlength=DummyStatusArray.length;  
   for(var k=1;k<=DummyStatusArray.length;k++){
      document.getElementById("+"+k).style.backgroundColor="#bbb";
      document.getElementById("-"+k).style.backgroundColor="#bbb";
    }

    for(var i=0;i<arrlength;i++)
    {
      if(DummyStatusArray[i].name===status){
        
        for(var j=1;j<=i+1;j++){
        
          document.getElementById("+"+j).style.backgroundColor="green";
          document.getElementById("-"+j).style.backgroundColor="green";
        }
    }
  }
 }

 var handleChangeStatusProgress = (event,data) => {
      // setData(data);
      var value= JSON.parse(event.target.value)
      props.statusProgressUpdate(data,value)
      
 }

 var convertDate=(date)=>{
  var fromDateInSec = new Date(date.createdAt);
  var tempDate = date.createdAt.toString().substring(0,10);
  // console.log("tempdate",tempDate);
  var time = fromDateInSec.toString().substring(16,21);
  // console.log("fromDateInSec",date.createdAt,tempDate,tempMonth)
  var fromDate = tempDate + " at " + time;
  return fromDate;
 }

 var setSelectedRow = (data,i) =>{
  props.setRequestedContentElements('selectedRequestedContentRow',data)
  props.setRequestedContentElements('selectedIndex',i)
 }

  return (
    <div className="add-on-load-animation">
      {/* {console.log("sttttatee",props.RequestedContentState)} */}
      <div className="col-md-12">
      {/* <div id="snackbar">{alertMessage}</div>
      <div id="snackbar1"><i className="fa fa-exclamation"></i>{alertMessage}</div> */}
      <div id="successSnackbar">{props.RequestedContentState.successSnackbarMessage}</div>
      <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.RequestedContentState.errorSnackbarMessage}</div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
          {!props.resourceState.showRequestResource ?
          <div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 nj-card course-available-column">
            {/* <div className="col-md-12 col-sm-12 nj-card"> */}
              <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                <div className="nj-card__header">
                  <div className="nj-card__title">
                    <h4 className="font_family_montserrat">Requested Content</h4>
                  </div>
                  <div style={{paddingBottom:'9px'}}>
                    <button className="nj-flow-button pull-right" type="button" onClick={props.toggleRequestResource}>Request</button>
                  </div>
                </div>

                <div className="col-md-4 pull-right nj-user-search-wrap ">
                  <input className="vs-input--input nj-user-search-bar" type="text" style={{marginTop:"2%",marginBottom:"3%",width:"100%"}} name="searchValue" placeholder="search by email" value={searchValue} onChange={handleSearchChange}  autoComplete="off"/>
                </div>

              </div>

              {props.RequestedContentState.RequestedContent_loader === true ?
              <div>
              {props.RequestedContentState.RequestedContent.length === 0 ?
                  <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                :
              <div style={{paddingBottom:"1%"}}>
              <div className="nj-table--tbody div tbl-content" style={{overflow:'auto'}}>
                  <table className="nj-table nj-table--tbody-table font_family_montserrat">
                    <thead className="nj-table--thead" >
                      <tr>
                        <th>
                          <div className="nj-table-text  nj-padding-10">Requested By</div>
                        </th>
                        <th>
                          <div className="nj-table-text nj-padding-10">Discipline</div>
                        </th>
                        <th>
                          <div className="nj-table-text nj-padding-10">Content Type</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >

                    {props.RequestedContentState.RequestedContent.map((data, i) =>{

                          return(

                          <tr key={i} style={(props.RequestedContentState.selectedRequestedContentRow !== undefined && props.RequestedContentState.selectedRequestedContentRow._id===data._id? {backgroundColor: "#f9f9f9"}:{backgroundColor: ""})} onClick={()=>setSelectedRow(data,i)}>
                            <td className="td nj-table--td nj-padding-10" style={{padding:"20px"}}>{data.email}</td>
                            <td className="td nj-table--td nj-padding-10 textOverflow" style={{textTransform:"capitalize",padding:"20px"}}>{data.branch.name}</td>
                            <td className="td nj-table--td nj-padding-10 textOverflow" style={{padding:"20px",textTransform:"capitalize"}}>{data.category.name}</td>

                          </tr>
                        )}
                      )}

                    </tbody>
                  </table>
                </div>
                </div>
              }
              </div>
              :
              <div className="" style={{marginLeft: '45%',paddingTop: '17%',paddingBottom: '17%'}}>
                <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" className=""/>
              </div>
            }

            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 nj-card action-column" style={{overflowY:"auto",overflowX:"auto",maxHeight:"95vh"}}>
            {props.RequestedContentState.RequestedContent_loader === true ?
              <div>
              {props.RequestedContentState.RequestedContent.length === 0 ?
                  <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                :
           
            <div>
            <div className="row" style={{backgroundColor:'#f9f9f9',margin:'10px -5px 10px -5px',borderRadius:5}}>
                  <h4 className="font_family_montserrat" style={{marginLeft:20 ,fontWeight:600}}>Status Progress:</h4>
                    <div >
                                {(userDetails.type!== 1) ? 
                                  <div>
                                   { props.RequestedContentState.selectedRequestedContentRow !== undefined && props.RequestedContentState.selectedRequestedContentRow.assignWorkFlow !== undefined && props.RequestedContentState.selectedRequestedContentRow.assignWorkFlow.length !== 0   ?  
                                    <div className="col-md-4 col-sm-4" style={{marginTop:"30px"}}>                         
                                        <div className="nj-form-select"  style={{marginLeft:"5px"}}>
                                          <select name="statusProgress" 
                                           value={JSON.stringify(props.RequestedContentState.selectedRequestedContentRow.userStatus)}
                                            onChange={(event) => handleChangeStatusProgress(event,props.RequestedContentState.selectedRequestedContentRow)}
                                            style={{textTransform:'capitalize'}}
                                            autoComplete="off" required>
                                            <option value="">Please Select</option>
                                            {props.RequestedContentState.selectedRequestedContentRow.assignWorkFlow[0].roles.map((role,index)=>{
                                                  return (<option key={index} value={JSON.stringify(role)}>{role.status}</option>)
                                              })}
                                          </select>
                                        </div>   
                                      </div>          
                                       :
                                        <div className="col-md-4 col-sm-4" style={{marginTop:"30px",paddingLeft:"30px"}}>
                                          No roles
                                       </div>
                                    } 
                                  </div>:
                                <div className="col-md-4 col-sm-4 course_actions_cards">
                                <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}}  onClick={()=>{openStatusProgressPopUp(props.RequestedContentState.selectedRequestedContentRow)}} >
                                  <img className="" src="/img/status.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                                  <p className="app_displayname font_family_montserrat">Status</p>
                                </div>
                              </div>
                                } 

                                {(userDetails.type === 3) && 
                                  <div className="col-md-4 col-sm-4 course_actions_cards">
                                  <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}}   onClick={()=>{openStatusProgressPopUp(props.RequestedContentState.selectedRequestedContentRow)}} >
                                    <img className="" src="/img/status.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                                    <p className="app_displayname font_family_montserrat">Status</p>
                                  </div>
                                </div>
                              }

                
                </div>
             </div>


              <div className="row" style={{backgroundColor:'#f9f9f9',margin:'10px -5px 10px -5px',borderRadius:5}}>
                  <h4 className="font_family_montserrat" style={{marginLeft:20,fontWeight:600}}>Comment:</h4>
                <div  className="course_actions_cards">
                  
                  <div className="col-md-4 col-sm-4">
                    <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}}  onClick={(e)=>{openPopUp(props.RequestedContentState.selectedRequestedContentRow)}} >
                      <img className="" src="/img/add_comment.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                      <p className="app_displayname font_family_montserrat">Add</p>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-4">
                    <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}} onClick={(e)=>{OpenViewPopUp(props.RequestedContentState.selectedRequestedContentRow)}} >
                      <img className="" src="/img/view_comments.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                      <p className="app_displayname font_family_montserrat">View</p>
                    </div>
                  </div>

                  </div>

              </div>

           
                
                <div className="row" style={{backgroundColor:'#f9f9f9',margin:'10px -5px 10px -5px',borderRadius:5}}>
                  <h4 className="font_family_montserrat" style={{marginLeft:20 ,fontWeight:600}}>Action:</h4>

                 <div  className="course_actions_cards">
                  
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}}  onClick={(e)=>openNotifyPopUp(props.RequestedContentState.selectedRequestedContentRow)}>
                        <img className="" src="/img/notify.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                        <p className="app_displayname font_family_montserrat">Notify</p>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}} onClick={()=>openUploadPopup(props.RequestedContentState.selectedRequestedContentRow)}>
                        <img className="" src="/img/upload.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                        <p className="app_displayname font_family_montserrat">Upload</p>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}} onClick={()=>openAssignPopup(props.RequestedContentState.selectedRequestedContentRow)}>
                        <img className="" src="/img/assign.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                        <p className="app_displayname font_family_montserrat">Assign</p>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}} onClick={()=>viewFiles(props.RequestedContentState.selectedRequestedContentRow)}>
                        <img className="" src="/img/view_files.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                        <p className="app_displayname font_family_montserrat">View Files</p>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}} onClick={()=>getConfirmDeletePopup(props.RequestedContentState.selectedRequestedContentRow)}>
                        <img className="" src="/img/delete.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                        <p className="app_displayname font_family_montserrat">Delete</p>
                      </div>
                    </div>

                </div>

                
              </div>

          </div>}
          </div>
          :
          
          <div className="" style={{marginLeft: '45%',paddingTop: '17%',paddingBottom: '17%'}}>
                <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" className=""/>
              </div>
            }
             </div>
            </div>
            
            :
            <RequestResource history={props.history}/>}
          </div>
        </div>
      </div>

      <div id="addPopup" style={{display:"none"}} className={"nj-overly "+(props.RequestedContentState.shouldPopupAnimate ? "add-rebound-animation" : "no-animation")}>
        <div className="popup-nj my-popup">
          <header className="popup-header">
            <span>Comment</span>
            <span /* onClick={hideDeletePopUp} */ onClick={()=>{document.getElementById("addPopup").style.display="none";SetComment("")}}  style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
          </header>
          <div className="popup-content">
            <div className="col-md-12">
              <form name="commentForm">
                <div className="nj-popup-form-wrap">
                <textarea name="comment" rows="5" placeholder="Type here..." value={comment} style={{width:"100%"}} onChange={(e)=>{handleChangeComment1(e)}}></textarea>
                </div>
                {/* <button type="button" className="nj-flow-button bg-color-red" onClick={()=>{document.getElementById("addPopup").style.display="none";SetComment("")}} style={{marginLeft:"49%"}}  >Cancel</button>
                <button type="submit" className="nj-flow-button"  onClick={(e)=>handleSubmitComment(e,data)} style={{float:"right"}}>Submit</button>      */}
                <button type="submit" className="nj-flow-button pull-right" style={{margin:5}} onClick={(e)=>handleSubmitComment(e,data)}>Submit</button>
                <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={()=>{document.getElementById("addPopup").style.display="none";SetComment("")}}  style={{margin:5}}>Cancel</button></div>

              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="deleteRequestedPopup" className={"nj-overly "+(props.RequestedContentState.shouldPopupAnimate ? "add-rebound-animation" : "no-animation")}>
        <div className="popup-nj my-popup">
          <header className="popup-header">
            <span>Delete Requested Content</span>

            <span onClick={hideDeletePopUp} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
          </header>
          <div className="popup-content">
            <div className="col-md-12">
              <form name="roleForm">
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label font_family_montserrat" style={{fontSize:"16px"}}>Do you really want to delete?</p>
                </div>
                  {/* <button type="submit" className="nj-flow-button pull-right" style={{margin:5}} onClick={(e)=>{props.deleteRequestedContent(e,data)}}>Confirm</button> */}
                  <button type="submit" className="nj-flow-button pull-right" style={{margin:5}} onClick={(e)=>deleteRequestedContent(e,data)}>Confirm</button>
                <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={hideDeletePopUp}  style={{margin:5}}>Cancel</button></div>
              </form>
            </div>
          </div>
        </div>
      </div>


      {/*View pop up*/}
    <div id="viewPopup"  className={"nj-overly "+(props.RequestedContentState.shouldPopupAnimate ? "add-rebound-animation" : "no-animation")}>
        <div className="popup-nj my-popup-requested-content-view">
          <header className="popup-header">
            <span>View Comments</span>
            <span onClick={()=>document.getElementById("viewPopup").style.display="none"} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
          </header>
          <div className="popup-content-requested-content-view">
          {props.RequestedContentState.savedComments.length === 0 || props.RequestedContentState.savedComments === undefined ?
                  <p className="no-data-found" style={{margin: '19.75%'}}>No Data Found</p>
                :
          <div>
            <div className="col-md-12">
              <h5 className="comments-head"><b>Description:</b></h5>
              {props.RequestedContentState.savedComments.description}
            </div>
            <div className="col-md-12">
              <h5 className="comments-head"><b>Comments:</b></h5>
            </div>
            <div className="col-md-12"  style={{width:"100%"}} >
                  {props.RequestedContentState.savedComments.comments === undefined || props.RequestedContentState.savedComments.comments.length === 0 ?
                      <p style={{textAlign:"center"}}> No  comment found</p>
                    :
                  <div className="row" >
                  {props.RequestedContentState.savedComments.comments.map((data) =>{

                      return(

                    <div className="row" style={{backgroundColor:"#f4f4f4",minHeight:"50px",paddingTop: "10px",paddingBottom: "0px",marginLeft:"2%",marginRight:"2%",marginBottom:"2%"}}>
                      <div className="col-md-12 col-sm-12 ng-binding" style={{marginBottom:"0",fontSize: "14px",fontFamily: "roboto"}}>
                            {data.comment}
                          </div>
                          <div className="col-md-12 col-sm-12" style={{marginTop:"5px",fontSize: "12px",fontFamily: "roboto"}}>
                          <span className="pull-right ng-binding"> {data.email}</span><br/>
                          {/* <p className="pull-right ng-binding">{date.substring(0,10)+" at "+date.substring(16,21)}</p> */}
                          <p className="pull-right ng-binding">{convertDate(data)}</p>
                          </div>

                    </div>

                    )}
                  )}
                  </div>
                  }
                </div>
                </div>
                }
              </div>
            </div>
          </div>

        {/*notify popup*/}

        <div id="notifyPopup" className={"nj-overly "+(props.RequestedContentState.shouldPopupAnimate ? "add-rebound-animation" : "no-animation")}>
          <div className="popup-nj  my-popup" style={{maxWidth:"550px"}}>
            <header className="popup-header">
              <span>Notify</span>
              <span onClick={()=>{document.getElementById('notifyPopup').style.display='none';clearNotifyInputFields()}} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
            </header>
            <div className="popup-content-requested-content-notify">
              <div className="col-md-12">
              <form name="createUser" onSubmit={createSubmit}>
              <div className="nj-popup-form-wrap">
                  <p className="terms-form">Subject</p>
                  <input type="text" name="subject" value={notify.subject} onChange={handleChangeNotify} className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="subject" autoComplete="off" required/>
                </div>
                <div className="nj-popup-form-wrap">
                  <p className="terms-form">Body</p>
                  <textarea  type="text" rows="5"  value={notify.body} name="body" onChange={handleChangeNotify}  className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="Body..." autoComplete="off" required/>
                </div>
                <div className="nj-popup-form-wrap">
                  <p className="terms-form">Attachments</p>
                  {/* <input type="file" name="phoneNumber"  value="" onChange="" className="vs-input--input padding_5 outline-none feedback-smiley-rating-suggestion" placeholder="phone number" autoComplete="off" required /> */}
                  <div className="file-upload-wrapper" id="file-upload-wrapper1" data-text="Upload File" >
                      <input name="file-upload-field" id="file-upload" type="file" accept="application/pdf" className="file-upload-field" onChange={getUploadedFile} />
                  </div>
                </div>
                <div className="nj-popup-form-wrap">
                    <p className="terms-form">Recipients</p>
                    <Multiselect id="multiSelectDropdown" options={props.RequestedContentState.Recepients} clearChecked={multiSelectClearCheck} clearMethod={setmultiSelectClearCheck} setDropDown={dropdownValue} onSelectOptions={handleSelectRecepients} required />
                </div>
                { props.RequestedContentState.sendNotificationLoader ? <img className="pull-right" src="/img/loaders/spinningwheel.gif" height="50" width="50" alt="" style={{marginTop:'-5px'}} />
                  :<button type="submit" className="nj-flow-button-requested-content pull-right" id="disableSend" style={{margin:5}} disabled={enableNotifySend} >Send</button>}

              </form>
              </div>
            </div>
          </div>
          </div>


      {/**Upload popup */}

      <div id="uploadPopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup">
          <header className="popup-header">
            <span>Upload</span>
            <span onClick={()=>{document.getElementById('uploadPopup').style.display='none';clearPopup()}}><i className="popup-close material-icons">close</i></span>
          </header>
          <div className="popup-content">
            <div className="col-md-12">
              <form name="uploadForm" onSubmit={handleUploadSumbit}>
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label"><b>Upload File</b></p>
                  <input className="vs-input--input" id="fileUpload" type="file" name="file" onChange={handleChange} placeholder="Petal Science" autoComplete="off" required />
                  <span style={{color: 'rgb(95, 82, 228)',fontSize:"11px"}}>*Max file size of 250 mb can be uploaded</span>
                </div>
                { props.RequestedContentState.uploadFileLoader ? <img className="pull-right" src="/img/loaders/spinningwheel.gif" height="50" width="50" alt="" style={{marginTop:'-5px'}} />
                  :<button type="submit" className="nj-flow-button pull-right" style={{margin:5}}>Submit</button>}
                <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={(e)=>{document.getElementById('uploadPopup').style.display='none';;clearPopup()}} style={{margin:5}}>Cancel</button></div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/**Assign popup */}

      <div id="assignPopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup" style={{maxWidth:"350px"}}>
          <header className="popup-header">
            <span>Assign Work Flow</span>
            <span onClick={()=>{document.getElementById('assignPopup').style.display='none'}}><i className="popup-close material-icons">close</i></span>
          </header>
          {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
          <div className="popup-content">
            <div className="col-md-12">
              {data.assignWorkFlow !== undefined && data.assignWorkFlow.length !== 0 ?

                <div>
                  {showViewForm === true ?
                    <div>
                      <form>
                        <div className="pull-right" style={{fontSize: 16,cursor: 'pointer',marginTop: -15,color: '#7367F0'}} onClick={showDropDowns}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit">
                            <title>Edit</title>
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/>
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>
                          </svg>
                        </div>
                        <div className="nj-popup-form-wrap">
                          <p className="popup-content-label" style={{fontSize: 15,fontWeight: '600'}}>Who Will Create the Content?</p>
                          <div className="" style={{paddingLeft: 10}}>
                            <p>{(data.assignWorkFlow !== undefined && data.assignWorkFlow[0].create)}</p>
                          </div>
                        </div>
                        <div className="nj-popup-form-wrap">
                          <p className="popup-content-label" style={{fontSize: 15,fontWeight: '600'}}>Who Will Produce the Content?</p>
                          <div className="" style={{paddingLeft: 10}}>
                            <p>{(data.assignWorkFlow !== undefined && data.assignWorkFlow[0].produce)}</p>
                          </div>
                        </div>
                        <div className="nj-popup-form-wrap">
                          <p className="popup-content-label" style={{fontSize: 15,fontWeight: '600'}}>Who Will Review the Content?</p>
                          <div className="" style={{paddingLeft: 10}}>
                            <p>{(data.assignWorkFlow !==  undefined && data.assignWorkFlow[0].review)}</p>
                          </div>
                        </div>
                        <div className="nj-popup-form-wrap">
                          <p className="popup-content-label" style={{fontSize: 15,fontWeight: '600'}}>Who Will Approve the Content?</p>
                          <div className="" style={{paddingLeft: 10}}>
                            <p>{(data.assignWorkFlow !== undefined && data.assignWorkFlow[0].approve)}</p>
                          </div>
                        </div>
                        <div className="nj-popup-form-wrap">
                          <p className="popup-content-label" style={{fontSize: 15,fontWeight: '600'}}>Date and Time:</p>
                          <div className="" style={{paddingLeft: 10}}>
                            <p>{(data.assignWorkFlow !== undefined && data.assignWorkFlow[0].scheduleDate)} and {(data.assignWorkFlow !== undefined && data.assignWorkFlow[0].scheduleTime)}</p>
                          </div>
                        </div>
                      </form>
                    </div>

                     :

                     <div>
                       <form name="assignForm" onSubmit={submitUpdatedAssignForm}>
                         <div className="nj-popup-form-wrap">
                           <p className="popup-content-label">Who Will Create the Content?</p>
                           <div className="nj-form-select">
                             <select name="create" defaultValue={JSON.stringify(updateDropdownArray.create)} onChange={handleChangeDropdownUpdate} autoComplete="off" required>

                               {props.RequestedContentState.createProduceEmail.map((create,index)=>{
                                   return (<option key={index} value={JSON.stringify(create)}>{create}</option>)
                               })}
                             </select>
                           </div>
                         </div>
                         <div className="nj-popup-form-wrap">
                           <p className="popup-content-label">Who Will Produce the Content?</p>
                           <div className="nj-form-select">
                             <select name="produce" defaultValue={JSON.stringify(updateDropdownArray.produce)} onChange={handleChangeDropdownUpdate} autoComplete="off" required>

                               {props.RequestedContentState.createProduceEmail.map((produce,index)=>{
                                   return (<option key={index} value={JSON.stringify(produce)}>{produce}</option>)
                               })}
                             </select>
                           </div>
                         </div>
                         <div className="nj-popup-form-wrap">
                           <p className="popup-content-label">Who Will Review the Content?</p>
                           <div className="nj-form-select">
                             <select name="review" defaultValue={JSON.stringify(updateDropdownArray.review)} onChange={handleChangeDropdownUpdate}  autoComplete="off" required>

                               {props.RequestedContentState.approveProduceEmail.map((review,index)=>{
                                   return (<option key={index} value={JSON.stringify(review)}>{review}</option>)
                               })}
                             </select>
                           </div>
                         </div>
                         <div className="nj-popup-form-wrap">
                           <p className="popup-content-label">Who Will Approve the Content?</p>
                           <div className="nj-form-select">
                             <select name="approve" defaultValue={JSON.stringify(updateDropdownArray.approve)} onChange={handleChangeDropdownUpdate} autoComplete="off" required>

                               {props.RequestedContentState.approveProduceEmail.map((approve,index)=>{
                                   return (<option key={index} value={JSON.stringify(approve)}>{approve}</option>)
                               })}
                             </select>
                           </div>
                         </div>

                         <div className="col-md-12">
                           <p className="popup-content-label">Date and Time</p>
                           <Datetime
                             inputProps={{ placeholder: 'dd/mm/yyyy'}}
                             dateFormat="DD-MM-YYYY"
                             closeOnSelect={true}
                             name="updateDateAndTime"
                             onChange={(moment)=> {handleDateTimePickerUpdate(moment, 'updateDateAndTime')}}
                             defaultValue={new Date(updateDateAndTime)}
                             isValidDate={ valid }
                           />
                         </div>

                           { props.RequestedContentState.assignloader ? <img className="pull-right" src="/img/loaders/spinningwheel.gif" height="50" width="50" alt="" style={{margin:'5px'}} />
                           :<button type="submit" className="nj-flow-button pull-right"  style={{margin:5,marginTop:"5%"}}>Submit</button>}
                         <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={(e)=>{document.getElementById('assignPopup').style.display='none';}} style={{margin:5,marginTop:"5%"}}>Cancel</button></div>
                       </form>
                     </div>
                   }
                </div>

                :

                <form name="assignForm" onSubmit={submitAssignForm}>
                  <div className="nj-popup-form-wrap">
                    <p className="popup-content-label">Who Will Create the Content?</p>
                    <div className="nj-form-select">
                      <select name="create" value={JSON.stringify(dropdownArray.create)} onChange={handleChangeDropdown} autoComplete="off" required>
                      <option value="">please select</option>
                        {props.RequestedContentState.createProduceEmail.map((create,index)=>{
                            return (<option key={index} value={JSON.stringify(create)}>{create}</option>)
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="nj-popup-form-wrap">
                    <p className="popup-content-label">Who Will Produce the Content?</p>
                    <div className="nj-form-select">
                      <select name="produce" value={JSON.stringify(dropdownArray.produce)} onChange={handleChangeDropdown} autoComplete="off" required>
                      <option value="">please select</option>
                        {props.RequestedContentState.createProduceEmail.map((produce,index)=>{
                            return (<option key={index} value={JSON.stringify(produce)}>{produce}</option>)
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="nj-popup-form-wrap">
                    <p className="popup-content-label">Who Will Review the Content?</p>
                    <div className="nj-form-select">
                      <select name="review" value={JSON.stringify(dropdownArray.review)} onChange={handleChangeDropdown}  autoComplete="off" required>
                      <option value="">please select</option>
                        {props.RequestedContentState.approveProduceEmail.map((review,index)=>{
                            return (<option key={index} value={JSON.stringify(review)}>{review}</option>)
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="nj-popup-form-wrap">
                    <p className="popup-content-label">Who Will Approve the Content?</p>
                    <div className="nj-form-select">
                      <select name="approve" value={JSON.stringify(dropdownArray.approve)} onChange={handleChangeDropdown} autoComplete="off" required>
                       <option value="">please select</option>
                        {props.RequestedContentState.approveProduceEmail.map((approve,index)=>{
                            return (<option key={index} value={JSON.stringify(approve)}>{approve}</option>)
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <p className="popup-content-label">Date and Time</p>
                    <Datetime
                      inputProps={{ placeholder: 'dd/mm/yyyy'}}
                      dateFormat="DD-MM-YYYY"
                      closeOnSelect={true}
                      name="dateAndTime"
                      onChange={(moment)=> {handleDateTimePicker(moment, 'dateAndTime')}}
                      defaultValue={new Date()}
                      isValidDate={ valid }
                    />
                  </div>

                    { props.RequestedContentState.assignloader ? <img className="pull-right" src="/img/loaders/spinningwheel.gif" height="50" width="50" alt="" style={{margin:'5px'}} />
                    :<button type="submit" className="nj-flow-button pull-right"  style={{margin:5,marginTop:"5%"}}>Submit</button>}
                  <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={(e)=>{document.getElementById('assignPopup').style.display='none';}} style={{margin:5,marginTop:"5%"}}>Cancel</button></div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>

      {/*********** View Uploaded files popup ****************************/}
      <div id="downloadPopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup">
          <header className="popup-header">
            <span>Uploaded Files</span>
            <span onClick={()=>{document.getElementById('downloadPopup').style.display='none'}}><i className="popup-close material-icons">close</i></span>
          </header>
          {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}

          <div className="view-files-popup" style={{maxHeight:"350px",maxWidth:"500px"}}>
            <div className="col-md-12">
            {data.documents === undefined || data.documents.length === 0 ?
              <p className="no-data-found" >No Data Found</p>
              :
              <form name="resourceForm" >


              {data.documents.map((item,index)=>{
                   return (
                  <div className="file "  key={index} style={{marginBottom:"10px"}}>
                        <div className="col-md-2 col-xs-2 col-sm-2">
                          {item.mimetype==="video/mp4" ?
                          <div className="resource_card-content-img-wrapper" style={{display:"inline"}}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource" style={{paddingRight:"10px",paddingTop:"5px"}}/>
                        </div>
                        :<div className="resource_card-content-img-wrapper" style={{display:"inline"}} >
                          <span><img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource"  style={{paddingRight:"10px",paddingTop:"5px"}}/></span>
                        </div>}</div>
                              {/* <span className="field1"><svg  xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text">
                                   <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/><polyline points="14 2 14 8 20 8"/><line x1="16" y1="13" x2="8" y2="13"/><line x1="16" y1="17" x2="8" y2="17"/><polyline points="10 9 9 9 8 9"/></svg>
                              </span>  */}

                          <div className=" col-md-10 col-sm-10 col-xs-10 text" style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}} >
                           <a href={window.location.protocol+"//"+window.location.hostname+"/"+item.path} target="_blank" /* download={"http://localhost:2828/"+item.path} */>{item.originalname}</a>
                            {/* {console.log("finalurl",baseUrl+'/'+item.path)} } */}
                            </div>

                      </div>
                      )})}

              </form>
              }
            </div>
          </div>
        </div>
      </div>

    {/* status progress */}

      <div id="statusProgress" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup" style={{maxWidth:"600px"}}>
          <header className="popup-header">
            <span>Work Flow</span>
            <span onClick={()=>{document.getElementById('statusProgress').style.display='none'}}><i className="popup-close material-icons">close</i></span>
          </header>
          
                <div className="popup-content">
                {data.assignWorkFlow!==undefined && data.assignWorkFlow.length>0 ?
                <div>
                  <div className="col-md-12" style={{width:"460px",height:"100px",marginLeft: "50px",marginTop:"60px",marginBottom:"10px"}} >
                    <div className="margin-area">
                       <div >
                        <div className="dot one " id="+1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                          <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="open"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                        <div id="-1"></div>
                        <div className="message2 message-1" > Open </div>
                    </div>
                    <div>
                        <div className="dot two " id="+2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                          <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="create"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}> Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                        <div className="progress-bar first" id="-2"></div>
                        <div className="message2 message-2">Created</div>
                    </div>
                    <div>
                        <div className="dot three" id="+3" ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                          <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="produce"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                        <div className="progress-bar second" id="-3"></div>
                        <div className="message2 message-3">Produced</div>
                    </div>
                    <div>
                        <div className="dot four" id="+4"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                          <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="review"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                        <div className="progress-bar third" id="-4"></div>
                        <div className="message2 message-4">Reviewed</div>
                    </div>
                    <div>
                        <div className="dot five" id="+5"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                          <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="approve"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                        <div className="progress-bar fourth" id="-5" ></div>
                        <div className="message2 message-5" >Approved</div>                     
                    </div>

                </div>
               
              </div>
            </div>
            :
            <div>
                <div className="col-md-12" style={{width:"460px",height:"100px",marginLeft: "50px",marginTop:"60px",marginBottom:"30px"}} >
                  <div className="margin-area">
                    <div >
                      <div className="dot one" id="+1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                        <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="open"&&<span className="popover above"><p  style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                      <div id="-1"></div>
                      <div className="message2 message-1"> Open </div>
                    </div>
                    <div>
                      <div className="dot two" id="+2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                        <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="create"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                      <div className="progress-bar first" id="-2"></div>
                      <div className="message2 message-2">Created</div>
                    </div>
                    <div>
                      <div className="dot three" id="+3"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                        <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="produce"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                      <div className="progress-bar second" id="-3"></div>
                      <div className="message2 message-3" data-toggle="popover" data-content="anything">Produced</div>
                    </div>
                    <div>
                      <div className="dot four" id="+4"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                        <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="review"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                      <div className="progress-bar third" id="-4"></div>
                      <div className="message2 message-4">Reviewed</div>
                    </div>
                    <div>
                      <div className="dot five" id="+5" ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                        <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="approve"&&<span className="popover above"><p> style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                      <div className="progress-bar fourth" id="-5"></div>
                      <div className="message2 message-5">Approved</div>
                    </div>
                </div>
              </div>
            </div>}

           
           </div>
        </div>
      </div>

    </div>
  );
}


const mapStateToProps = state => ({
  RequestedContentState: state.RequestedContentState,
  resourceState: state.resourceState
});



  const mapDispatchToProps = dispatch => bindActionCreators({
    getRequestedContent:RequestedContentContainer.getRequestedContent,
    deleteRequestedContent: RequestedContentContainer.deleteRequestedContent,
    handleChangeComment:RequestedContentContainer.handleChangeComment,
    getRequestedComment:RequestedContentContainer.getRequestedComment,
    searchRequestedContent:Actions.searchRequestedContent,
    toggleRequestResource: resourceContainer.toggleRequestResource,
    sendAssignWorkflow:RequestedContentContainer.sendAssignWorkflow,
    sendUpdatedAssignWorkflow:RequestedContentContainer.sendUpdatedAssignWorkflow,
    getAssignApproveReview:RequestedContentContainer.getAssignApproveReview,
    getAssignCreateProduce:RequestedContentContainer.getAssignCreateProduce,
    sendNotification: RequestedContentContainer.sendNotification,
    getRecepients: RequestedContentContainer.getRecepients,
    getUploadRequestedContent:RequestedContentContainer.getUploadRequestedContent,
    statusProgressUpdate:RequestedContentContainer.statusProgressUpdate,
    setRequestedContentElements:RequestedContentContainer.setRequestedContentElements
  }, dispatch)


export default connect(
  mapStateToProps,mapDispatchToProps
)(RequestedContent);
