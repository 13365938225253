import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import * as Proctoring from "../../container/actions/Proctoring/action";
// import Store from "../../container/reducers/Store";
// import * as Actions from "../../container/actions/CourseAnalytics";
import './Proctoring.scss'
import { bindActionCreators } from 'redux';
import axios from 'axios';

function RemoteProctoring (props) {
  // const [allUsers , setAllUsers] = useState();   


  useEffect( () => {   
   props.getAllCourses(); 
  },[]);

  var handleVideoDetails= (data) =>{
    props.history.push("/sub-admin/object-face-recognition");
    // console.log("*********",props.proctoringState.date)
//     axios.get('/api/slot/fetch/videoDetails/date').then(res => 
// {
// console.log("*******",res.data)

//    }); 

    // sessionStorage.setItem("videoDetails",)

  }

    return (
      <div className="">
        <div id="successSnackbar">{props.proctoringState.successSnackbarMessage}</div>
        <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.proctoringState.errorSnackbarMessage}</div>
        <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingBottom: 10}}>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
              <div className="col-md-12 col-sm-12 nj-card" >
                <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                  <div className="nj-card__header">
                    <div className="nj-card__title">
                      <h4 className="font_family_montserrat">Remote Proctoring</h4>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-12" style={{marginTop:"10px"}}>
                        <div className="col-md-4 col-xs-12" style={{marginLeft:"0px",paddingLeft:"0px"}}>
                          <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontWeight:"500"}}>Select Course</label>
                          <div className="nj-form-select">
                            <select className="vs-input--input" style={{lineHeight:'25px',borderRadius:'5px'}}   onChange={(e)=>{props.handleChangeCourse(e)}}>
                              {props.proctoringState.rpAllCourses.length===0 && (<option value=''>No courses available</option>)}
                              {props.proctoringState.rpAllCourses.map((obj,i) =>{
                                return <option key={i} value={JSON.stringify(obj)}>{obj.name}</option>
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4 col-xs-12" style={{marginLeft:"0px",paddingLeft:"0px"}}>
                          <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontWeight:"500"}}>Select Date</label>
                          <div className="nj-form-select">
                            <select className="vs-input--input" style={{lineHeight:'25px',borderRadius:'5px'}}  onChange={(e)=>{props.handleChangeDate(e)}} >
                              {props.proctoringState.rpDatesArray.length===0 && (<option value=''>No date available</option>)}
                              {props.proctoringState.rpDatesArray.map((obj,i) =>{
                                return <option key={i} value={JSON.stringify(obj)}>{obj.date}</option>
                              })}
                            </select>
                          </div>
                        </div>
                        {/* <div className="col-md-3 col-xs-12" style={{marginLeft:"0px",paddingLeft:"0px"}}>
                          <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontWeight:"500"}}> Select Status</label>
                          <div className="nj-form-select">
                            <select className="vs-input--input" style={{lineHeight:'25px',borderRadius:'5px'}}  onChange={(e)=>{props.handleChangeStatus(e)}} >
                              {props.proctoringState.statusObj.map((obj,i) =>{
                                return <option key={i} value={JSON.stringify(obj)}>{obj.name}</option>
                              })}
                            </select>
                          </div>
                        </div> */}
                    </div>
                </div>
                <div className="row" style={{marginTop:10}}>
                  {!props.proctoringState.rpInitialLoader ?
                    <div className="col-md-12">
                      {props.proctoringState.bookedSlots.length>0 ?
                      <div className="proctoring-table-margin-top">
                        <div className="tbl-content row" style={{overflowY:"auto",overflowX:"auto",maxHeight:"60vh",marginBottom:"8vh"}}>
                          <table className="nj-table nj-table--tbody-table font_family_montserrat">
                            <thead className="nj-table--thead">
                              <tr>
                                <th>
                                  <div className="nj-table-text">SL NO.</div>
                                </th>
                                <th>
                                  <div className="nj-table-text">USERNAME</div>
                                </th>
                                <th>
                                  <div className="nj-table-text" >EMAIL</div>
                                </th>
                                <th>
                                  <div className="nj-table-text">BOOKING TIME</div>
                                </th> 
                                  <th>
                                    <div className="nj-table-text">WEBCAM RECORDING</div>
                                </th>                                
                              </tr>
                            </thead>
                            <tbody>
                              {props.proctoringState.bookedSlots.map((data,i)=>{
                                return (<tr key={i}  className={"tr-values nj-table--tr tr-table-state-null "+(props.proctoringState.selectedBookedSlot==data._id ? " selectedBookedSlot":"")}>
                                  <td className="td nj-table--td">{i+1}</td>
                                  <td className="td nj-table--td">{data.username}</td>
                                  <td className="td nj-table--td">{data.email}</td>
                                  <td className="td nj-table--td">{data.bookingTime}</td> 
                                  {/* <td className="td nj-table--td"><button type="button" className="nj-flow-button" onClick={(e)=>handleVideoDetails(e,data)}>View</button></td>                                */}
                                  <td className="td nj-table--td"><button type="button" className="nj-flow-button" onClick={(e)=>{props.storeVideoDetails(data,props)}}>View</button></td>
                                  {/* <td className="td nj-table--td"><button type="button" className="nj-flow-button" onClick={(e)=>{props.showWebmVideoPopup(data,props)}}>View</button></td> */}

                                </tr>)
                              })}
                            </tbody>
                          </table>
                      </div>
                      </div>
                      :
                      <div className="row" style={{height:"70vh",textAlign:'center'}}>
                        <div style={{marginTop:'35vh'}}>
                          No data Found
                        </div>                       
                      </div>
                      }
                    </div>
                    :
                    <div className="" style={{height:"70vh"}}>
                      <div className="col-md-12 rp-aligh-loader-image-center" >
                        <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                      </div>
                    </div>
                  }
                </div> 
                {/*popup to show webm video*/}
                <div id="webmVideoPopup" className="nj-overly add-rebound-animation">
                  <div className="popup-nj my-popup" style={{maxWidth:"780px"}}>
                    <header className="popup-header">
                      <span>WebCam Details</span>
                      <span  style={{cursor:"pointer"}} onClick={props.hideWebcamVideoPopup}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
                    </header>
                    <div className="popup-content">
                      {!props.proctoringState.webcamVideoLoader ?
                      <>
                        {props.proctoringState.videoArray.length>0 ?
                        <>
                        {!props.proctoringState.showPdf ?
                        <div>
                          <div className="col-md-12">
                            <div className="col-md-8 col-sm-8 col-xs-8">
                              <video id="webm-video" className="webm-video-popup" controls src={props.proctoringState.selectedVideoObject.url+'.'+props.proctoringState.videoFileType}></video>
                              <div className="webm-fileName">File Name : {props.proctoringState.selectedVideoObject.idName+'.'+props.proctoringState.videoFileType}</div>
                            </div>               
                            <div className="col-md-4 col-sm-4 col-xs-4">
                              <div className=" col-md-12 col-sm-12" style={{maxHeight:'400px',overflowX:"scroll"}}>
                                <div className="table col-md-12" >
                                  {props.proctoringState.videoArray.map((item, i) => {
                                    return (
                                      <div className="col-md-12 add-rebound-animation" key={i}>
                                        <div className="row webm-card-height" style={(props.proctoringState.selectedVideoObject.idName === item.idName ? {position:"relative",cursor:'pointer',backgroundColor: "#f8f8f8"}:{position:"relative",cursor:'pointer'})}  onClick={()=>{props.playAnotherVideo(item)}}>
                                          <div className="apps_card-content" >
                                            <img  style={{height:"64px",width:"100%"}} src={"/img/resource-icons/video.svg"} alt="resource"  />                      
                                          </div>
                                          <div className="col-md-12 col-sm-12 col-xs-12 "  >
                                            <div className="font_family_montserrat" style={{paddingBottom:'4px'}}>{item.idName}</div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div> 
                              </div>
                            </div>
                          </div>
                          <div  className="col-md-12">
                             <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="webm-fileName">File : <p style={{cursor:"pointer"}} onClick={()=>{props.togglePdf(true)}}>{props.proctoringState.selectedSlot._id+".pdf"}</p></div>
                             </div>  
                          </div>
                        </div>:
                        <div className="col-md-12">
                          {!props.proctoringState.pdfLoader?<>
                          <div id="canvas_container">
                            <canvas id="pdf_renderer"></canvas>
                          </div>
                          <div className="pdf-controls">
                            <div id="navigation_controls">
                              <button className="nj-flow-button" id="go_previous" onClick={props.goPreviousPage} style={{padding:'0 1rem',fontSize:16}}>{'<'}</button>
                              <input className="vs-input--input" id="current_page" defaultValue={1} type="number" onKeyDown={props.currentPage}  style={{width:40,margin:'0 5px',padding:0,textAlign:'center'}} />
                              <button className="nj-flow-button" id="go_next" onClick={props.goNextPage} style={{padding:'0 1rem',fontSize:16}}>{'>'}</button>
                            </div>
                            <div id="zoom_controls">  
                              <span style={{marginRight:5,fontWeight:'500'}}>Zoom</span>
                              <button className="nj-flow-button" id="zoom_out" onClick={props.zoomOutPage}  style={{padding:'0 1rem',fontSize:16,marginRight:5}}>-</button>
                              <button className="nj-flow-button" id="zoom_in" onClick={props.zoomInPage}  style={{padding:'0 1rem',fontSize:16}}>+</button>
                            </div>
                          </div>
                          </>:
                          <div className="" style={{height:"42vh",textAlign:'center'}}>
                            <div className="col-md-12" style={{marginTop:"15vh"}}>
                              <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                            </div>
                          </div>}
                        </div>}
                        </>
                        :
                          <div className="col-md-12" style={{height:"42vh",textAlign:'center'}}>
                            <div style={{marginTop:"20vh"}}>
                              No data Found
                            </div>                       
                          </div>                          
                        }
                      </>
                      :
                      <div className="" style={{height:"42vh",textAlign:'center'}}>
                        <div className="col-md-12" style={{marginTop:"15vh"}}>
                          <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                        </div>
                      </div>
                    }
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


const mapStateToProps = state => ({
  proctoringState: state.proctoringState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllCourses:Proctoring.getAllCourses,
  handleChangeCourse:Proctoring.handleChangeCourse,
  handleChangeDate:Proctoring.handleChangeDate,
  handleChangeStatus:Proctoring.handleChangeStatus,
  showWebmVideoPopup:Proctoring.showWebmVideoPopup,
  playAnotherVideo:Proctoring.playAnotherVideo,
  hideWebcamVideoPopup:Proctoring.hideWebcamVideoPopup,
  togglePdf: Proctoring.togglePdf,
  goPreviousPage:Proctoring.goPreviousPage,
  goNextPage:Proctoring.goNextPage,
  currentPage:Proctoring.currentPage,
  zoomInPage:Proctoring.zoomInPage,
  zoomOutPage:Proctoring.zoomOutPage,
  storeVideoDetails:Proctoring.storeVideoDetails
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(RemoteProctoring);
