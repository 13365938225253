
import * as UserCoursesActionTypes from "../../../actionTypes";

const initialState = {
  allEnabledCourses:[],
  allRequestedCourses:[],
  showResourcesList:true,
  availableUserCoursesLoader:false,
  showRatingLoader:false,
  rating:"",
  selectedObject:"",
  ratingDescription:"",
  successSnackbarMessage:"",
  errorSnackbarMessage:"",
  editCourseRatingObject:{},
  editResourceRatingObject:{},
  showEditCourseRatingLoader:false,
  showEditResourceRatingLoader:false,
  showRequested:true

}

export default function(state=initialState, action) {
  switch (action.type) {
    case UserCoursesActionTypes.USER_COURSES_GET_ALLENABLEDCOURSES: {
      const {content } = action.payload;
      return {
        ...state,allEnabledCourses:content,availableUserCoursesLoader:true
      }
    }

    case UserCoursesActionTypes.USER_COURSES_GET_ALLREQUESTEDCOURSES: {
      const {content } = action.payload;
      return {
        ...state,allRequestedCourses:content,availableUserCoursesLoader:true
      }
    }

    case UserCoursesActionTypes.USER_SHOW_RESOURCES_LIST: {
      const {booleanValue} = action.payload;
      return {
        ...state,showResourcesList:booleanValue,showRequested:booleanValue
     }
  }
  case UserCoursesActionTypes.SET_DYNAMIC_VALUES: {
    const {name,value} = action.payload;
    return {
      ...state,[name]:value
   }
  }
  case UserCoursesActionTypes.SET_DYNAMIC_VALUES_FOR_COURSERATINGS: {
    const {name,value} = action.payload;
    return {
      ...state,editCourseRatingObject:{...state.editCourseRatingObject,[name]:value}
    }
  }
  case UserCoursesActionTypes.SET_DYNAMIC_VALUES_FOR_RESOURCERATINGS: {
    const {name,value} = action.payload;
    return {
      ...state,editResourceRatingObject:{...state.editResourceRatingObject,[name]:value}
    }
  }
  default:
    return state;
  }
}
