import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import * as bookSlotContainer from "../../../container/actions/BookSlot/action";
import { bindActionCreators } from 'redux';
import DatePicker from './DatePicker';
import customHttp from '../../../packages/CustomHttp';
import {format} from "date-fns";
import snackbar from '../../../packages/Snackbar';

var selectedSlotsArr = [];
var currentDate = {};
var slotTimings = []
function SlotBooking (props) {
  //const dummySlots = [{_id:uniqueId(),time:'00:30-02:30',selected:false,disabled:false,color:false},{_id:uniqueId(),time:'02:30-04:30',selected:false,disabled:false,color:false},{_id:uniqueId(),time:'04:30-06:30',selected:false,disabled:false,color:false},{_id:uniqueId(),time:'06:30-08:30',selected:false,disabled:false,color:false},{_id:uniqueId(),time:'08:30-10:30',selected:false,disabled:false,color:false},{_id:uniqueId(),time:'10:30-12:30',selected:false,disabled:false,color:false},{_id:uniqueId(),time:'12:30-14:30',selected:false,disabled:false,color:false},{_id:uniqueId(),time:'14:30-16:30',selected:false,disabled:false,color:false},{_id:uniqueId(),time:'16:30-18:30',selected:false,disabled:false,color:false},{_id:uniqueId(),time:'18:30-20:30',selected:false,disabled:false,color:false},{_id:uniqueId(),time:'20:30-22:30',selected:false,disabled:false,color:false},{_id:uniqueId(),time:'22:30-00:30',selected:false,disabled:false,color:false}];
  const [setDay,setSetDay] = useState({toggle:function(){}});
  const [blockDaysBefore,setBlockDaysBefore] = useState({});
  const [blockDaysAfter,setBlockDaysAfter] = useState({});
  const [slots,setSlots] = useState([]);
  const [slotsAllowed,setSlotsAllowed] = useState(0);
  const [selectedDays,setSelectedDays] = useState([]);
  const [totalBookedSlots,setTotalBookedSlots] = useState(0);
  const [alertMessage,setAlertMessage] = useState('')
  const [bookSlotLoader,setBookSlotLoader] = useState(false);
  const [slotsLoader,setSlotsLoader] = useState(false);
  const [randomNumber,setRandomNumber] = useState(0);
  const [currentMonth,setCurrentMonth] = useState(new Date())

  useEffect(() => { 
    blockDate();
    getSubscriptionLimit();
    getSlotsFromDb();
    slotTimings = getSlotTimingsFromDb();
    getSlotTimings()
  },[]);
  useEffect(() => { 
    // console.log("slots",slots)
  },[slots]);

  useEffect(() => { 
    setRandomNumber(Math.random());
  },[blockDaysBefore,blockDaysAfter,selectedDays,currentMonth]);

  const getSlotTimingsFromDb=async()=>{
    setSlotsLoader(true)
    try {
     var course=JSON.parse(sessionStorage.getItem('userCourse'));
     var slotHours;
     if(course.slotHours === null){
       slotHours='2';
     }else{
      slotHours=course.slotHours;
     }
      var response = await customHttp.get(`/api/slot/fetchTime/slotHours/${slotHours}`);
      slotTimings = response.data.message;
      setSlotsLoader(false)
      return slotTimings;
    } catch(err){
      setSlotsLoader(false)
      console.log("error",err)
    }
  }

  const getSlotTimings=()=>{
    // console.log("slot timings",slotTimings)
    if(slotTimings.length===0){
      slotTimings = getSlotTimingsFromDb();
    } else {
      var allSlots = JSON.parse(JSON.stringify(slotTimings))
      for(var i=0;i<allSlots.length;i++){
        allSlots[i].selected = false;
        allSlots[i].disabled = false;
        allSlots[i].color = false;
        if(i===allSlots.length-1){
          setSlots(allSlots);
          getSlots({date:currentDate},allSlots);
        }
      }
    }
  }
  const getSelectedDays = (selectedDaysArr) =>{
    // console.log('selectedDays',selectedDaysArr)
  }
  const toggleDay = (toggle) =>{ 
    setSetDay({toggle})
  }

  const openSlotsPopup = (date) =>{                                        //this is called on date click in datepicker
    // console.log('open slots popup called',date);
    //getSlots(date);
    currentDate = date.date;
    //setCurrentSlotDate(date.date);
    selectedSlotsArr = [];
    document.getElementById('slotPopup').style.display='flex';            //opens slot popup
    getSlotTimings()
    //toggleSlots([{...slots[2],selected:true},{...slots[5],selected:true}]);
    //disableSlots([{...slots[1],disabled:true},{...slots[8],disabled:true}]);  
  }

  const blockDate = function(){
    var course = JSON.parse(sessionStorage.getItem('userCourse'));
    // console.log("course",course)
    // var user = JSON.parse(sessionStorage.getItem('userData'));
    setBlockDaysAfter(new Date(course.endDate));
    // console.log("end date",new Date(course.endDate));
    if(new Date(course.startDate) > new Date()){
      setBlockDaysBefore(new Date(course.startDate));
      setCurrentMonth(new Date(course.startDate));
      // console.log("start date",new Date(course.startDate))
    } else if(new Date(course.startDate) < new Date()) {
      setBlockDaysBefore(new Date());
      setCurrentMonth(new Date(course.startDate));
      // console.log("start date",new Date(course.startDate))
    } else {
      setBlockDaysBefore(new Date(course.startDate));
      setCurrentMonth(new Date(course.startDate));
      // console.log("start date",new Date(course.startDate))
    }
  }
  
  //function to set max slots allowed for a user - for a course timespan
  const getSubscriptionLimit = async function(){
    var user = JSON.parse(sessionStorage.getItem('userData'));
    var course = JSON.parse(sessionStorage.getItem('userCourse'));
    try {
      var response  = await customHttp.get('/api/concurrentUser/userId/'+user.belongsTo);
      // console.log(response);
      var getSubLimit = response.data.message; //all user subscriptions with limit.
      //set limit for selected course/subscription.
      for(var i=0;i<getSubLimit.length;i++){
        if(getSubLimit[i].subscription === course.name){
          var subLimit = getSubLimit[i].subscriptionLimit;
          setSlotsAllowed(subLimit)
          // console.log("sub limit",subLimit)
        }
      }
    } catch(error){
      console.log(error)
    }
  }

  const getSlotsFromDb = async function(){
    var user = JSON.parse(sessionStorage.getItem('userData'));
    var course = JSON.parse(sessionStorage.getItem('userCourse'));
    try {
      var response = await customHttp.get('/api/user/'+user._id+'/subscription/'+course.name+'/currentSlots');
      if(response.data){
        // console.log("getSlotsFromDb", response.data.message)
        //fetchSlotsFromDb(response.data.message);
        if(response.data.message && response.data.message.length>0){
          setTotalBookedSlots(response.data.message.length || 0);
          // console.log("slots booked",totalBookedSlots)
          selectDates(response.data.message);
        }
      }
    } catch(error){
      console.log(error)
    }
  }

  //function to select booked days on datepicker
  const selectDates=(dbDates)=>{
    var bookedDates = [];
    for(var i=0;i<dbDates.length;i++){
      const temp = new Date(dbDates[i].date)
      bookedDates.push(temp);
    }
    // console.log("bookedDates",dbDates);
    setSelectedDays(bookedDates);
  }
  //function to get already booked slots
  // var dbSlots=[];
  // const fetchSlotsFromDb = function(bookedSlots){
  //   var getSlotTimes = [];
  //   var responseFromDb = bookedSlots;
  //   for(var i in responseFromDb){
  //     if(responseFromDb[i].date !=undefined){
  //       var obj = {};
  //       var holdDate = responseFromDb[i].date.substr(0,10);
  //       var dateObj = new Date(holdDate);
  //       var getDate = dateObj;
  //       //var getDate = date.format(dateObj, "MM-DD-YYYY")
  //       obj.time = responseFromDb[i].bookingTime;
  //       obj.date = getDate;
  //       dbSlots.push(obj);
  //       getSlotTimes.push(obj);	
  //     }
  //   }
  // }

  //function to show db slot timings as selected
  const getSlots = async (date,slotsArr)=>{
    var user = JSON.parse(sessionStorage.getItem('userData'));
    var course = JSON.parse(sessionStorage.getItem('userCourse'));
    // console.log("dateeee",date)
    try {
      var slotHours;
      if(course.slotHours === null){
        slotHours='2';
      }else{
        slotHours=course.slotHours;
      }
      var formattedDate = format(new Date(date.date), "yyyy-MM-dd")
      var response = await customHttp.get(`/api/slot/fetchSlot/userId/${user._id}/adminId/${user.belongsTo}/courseId/${course._id}/date/${formattedDate}/slotHours/${slotHours}`);
      // var response = await customHttp.get('/api/slot/fetchSlot/userId/'+user._id+'/adminId/'+user.belongsTo+'/subscription/'+course.name+'/date/'+formattedDate);
      // console.log("get slots response",response,"slots array",slotsArr);
      addColorToSlots(response.data.message,slotsArr)
    } catch(error){
      console.log("error",error)
    }
  }

  const addColorToSlots=(dbSlots,slotsArray)=>{
    var slotsArr = JSON.parse(JSON.stringify(slotsArray));
    for(var i=0;i<slotsArr.length;i++){
      for(var j=0;j<dbSlots.length;j++){
        if(slotsArr[i].time===dbSlots[j].time && dbSlots[j].color==='orange'){
          slotsArr[i].color = 'orange';
        } else if(slotsArr[i].time===dbSlots[j].time && dbSlots[j].color==='red'){
          slotsArr[i].color = 'red';
        } else if(slotsArr[i].time===dbSlots[j].time && dbSlots[j].color==='blue'){
          slotsArr[i].selected = true;
        }
      }
    }
    setSlots(slotsArr);
    blockSlots(slotsArr,dbSlots)
  }  
  ///function to block slot timings of a date
  const blockSlots=(slotsOrg,dbSlots)=>{
    var slotsArr = [];
    for(var j=0;j<dbSlots.length;j++){
      if(dbSlots[j].block){
        slotsArr.push({time:dbSlots[j].time,disabled:dbSlots[j].block})
      }
    }
    // send only block true slots to disbale slots function 
    disableSlots(slotsOrg,slotsArr)
  }

  //function to disable slots
  const disableSlots=(slotsArr,toggleDisabledSlots)=>{
    // console.log("db slots",toggleDisabledSlots)
    var arr = JSON.parse(JSON.stringify((slotsArr)));
    for(var i=0;i<arr.length;i++){
      for(var j=0;j<toggleDisabledSlots.length;j++){
        if(arr[i].time===toggleDisabledSlots[j].time){
          arr[i].disabled=toggleDisabledSlots[j].disabled;
        }
      }
    }
    setSlots(arr)
  }

  const onSlotClick=(item)=>{
    var arr = JSON.parse(JSON.stringify((slots)));
    arr.filter(obj=>{
      if(obj._id===item._id){
        obj.selected=!obj.selected;
        toggleSelectedSlotsArr(obj)
      }
      return obj
    })
    setSlots(arr)
  }
  const toggleSelectedSlotsArr=(item)=>{
    if(selectedSlotsArr.length>0){
      for(var i=0;i<selectedSlotsArr.length;i++){
        if(selectedSlotsArr[i]._id===item._id && !item.selected){
          selectedSlotsArr.splice(i,1);
          i--;
        } else if(i===selectedSlotsArr.length-1 && item.selected){
          selectedSlotsArr.push(item);
          break
        }
        // console.log("loop")
      }
    } else {
      selectedSlotsArr.push(item);
    }
    // console.log("selected slots array",selectedSlotsArr)
  }
  const submitSlots=async(arr)=>{
    var user = JSON.parse(sessionStorage.getItem('userData'));
    var course = JSON.parse(sessionStorage.getItem('userCourse'));
    var selectedApps = JSON.parse(sessionStorage.getItem('userSelectedApplications'));
    var tempArray = arr;
    var bookSlotArray = [];
    var dateTimeCombo = {}
		for (var i=0;i<tempArray.length;i++) {
			dateTimeCombo = {};
      dateTimeCombo.time = tempArray[i].time;
      dateTimeCombo.date = format(currentDate,"yyyy-MM-dd");
      var obj = {};
      obj.applications=[]; 
      obj.date = dateTimeCombo.date;
      obj.bookingTime = dateTimeCombo.time
			obj.username = user.username;
			obj.email = user.email;
			obj.userId = user._id;
			obj.subscription = course.name;
			obj.applications = selectedApps;
			obj.courseId = course._id;
			obj.adminId = user.adminId;
			obj.belongsTo = user.belongsTo;
			//var tempArray1 = [obj];
			bookSlotArray.push(obj)
			// console.log("slot booking",obj)
			if(i===tempArray.length-1){
				var tempObj1 = {}
        tempObj1.date = obj.date;
        tempObj1.time = obj.bookingTime;
        setBookSlotLoader(true)
        try {
          var response  = await customHttp.put('/api/slot/bookSlot', bookSlotArray)
          // console.log("book slot response",response)
          getSlotsFromDb();
          //var temp=JSON.parse(JSON.stringify(dummySlots))
          //setSlots(temp);
          getSlotTimings()
          selectedSlotsArr = [];
          setDay.toggle(new Date(currentDate),true);
          setCurrentMonth(new Date(obj.date) || new Date())
          document.getElementById('slotPopup').style.display='none';
          setAlertMessage('Slot booked successfully');
          snackbar.successSnackbar();
          setBookSlotLoader(false)
        } catch(error){
          setAlertMessage('Error! Try again..');
          snackbar.errorSnackbar(); 
          setBookSlotLoader(false)
          console.log("error",error)
        }
			}
		} //end of for loop
  }
  const handleSubmit=()=>{
    if(selectedSlotsArr.length>0){
      var slotCount = totalBookedSlots+selectedSlotsArr.length;
      // console.log("slot count",slotCount)
      if(slotCount<=slotsAllowed){
        submitSlots(selectedSlotsArr);
      } else {
        setAlertMessage(`maximum of ${slotsAllowed} allowed to book`);
        snackbar.errorSnackbar();
      }
    } else {
      setAlertMessage('please select atleast one slot');
      snackbar.errorSnackbar();
    }
  }
  const closePopup=async()=>{
    //setSlots(dummySlots);
    getSlotTimings()
    var temp = [];
    //var arr = Array.from(slots);
    temp = Array.from(slots);
    for(var i=0;i<temp.length;i++){
      for(var j=0;j<selectedSlotsArr.length;j++){
        if(temp[i]._id===selectedSlotsArr[j]._id){
          // console.log("checkkk")
           temp.splice(i,1);
           i--;
          //  console.log("temp array",JSON.parse(JSON.stringify(temp)))
           break
        }
      }
      if(i===temp.length-1){
        var activateDate = false;
        for(var k=0;k<temp.length;k++){
          if(temp[k].selected){
            activateDate = true;
            setDay.toggle(new Date(currentDate),true)
            break;
          }
          if(k===temp.length-1){
            if(activateDate){
              setDay.toggle(new Date(currentDate),true)
            } else {
              setDay.toggle(new Date(currentDate),false)
            }
          }
        }
      }
    }
    document.getElementById('slotPopup').style.display='none';
  }
  // function uniqueId() {
  //   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
  //     var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
  //     return v.toString(16);
  //   });
  // }
  return (
    <div className="add-on-load-animation">
      <div id="successSnackbar">{alertMessage}</div>
      <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{alertMessage}</div>
      <div className="col-md-12 nj-card" >
        <div className="row">
          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4>Book Slots</h4>
            </div>
            <div>
              <button className="nj-flow-button " type="button" onClick={()=>props.handleRouteTo('applications',props.history)} style={{marginBottom:"10px"}}>Back</button>
            </div>
          </div>
        </div>
        <div className="row" style={{padding:10}}>
          <DatePicker multiple={true} currentMonth={currentMonth} onDayClick={openSlotsPopup} key={randomNumber} selectedDays={selectedDays} blockDaysBeforeToday={true} blockDaysBefore={blockDaysBefore} blockDaysAfter={blockDaysAfter} getSelectedDays={getSelectedDays} toggleDay={toggleDay} />
        </div>
      </div>
      {/* popup slots */}
      <div id="slotPopup" className="nj-overly add-rebound-animation slot-popup" >
        <div className="popup-nj my-popup" style={{minWidth:'300px'}}>
          <header className="popup-header slot-popup-header">
            <span>Book Timings </span>
            <span onClick={closePopup} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
          </header>
          <div className="popup-content">
            {!slotsLoader?<div className="col-md-12">
              {slots.length>0?<div className="slot-button-group-wrapper text-center">
                {slots.map((item,i)=>{
                  return(        
                    <button type="button" className={"slot-button-group-btn"+(item.selected?' active':'')+(item.disabled?' disabled':'')+(' '+item.color)} key={item._id} onClick={()=>onSlotClick(item)}>
                      {item.time}
                    </button>
                  )
                })}
              </div>:<p>No slots found</p>}
              {bookSlotLoader?<img src={"/img/loaders/spinningwheel.gif"}  className="pull-right" height={50} width={50} alt="loader" />:<button type="button" className="nj-flow-button pull-right" style={{margin:'5px 5px 10px 5px',padding:'0.75rem 3rem'}} onClick={handleSubmit}>Submit</button>}
            </div>:<div className="col-md-12">
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:100,width:'100%'}}><img src={"/img/loaders/spinningwheel.gif"} height={50} width={50} alt="loader" /></div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => ({
  bookSlotState: state.bookSlotState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  handleRouteTo:bookSlotContainer.handleRouteTo
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(SlotBooking);