import React, {Component} from "react";
import "./Sidebar.css";
import {NavLink} from 'react-router-dom';
class App extends Component {
  state = {showAnalyticsMenu:false};
  triggerRolesPage=()=>{
    sessionStorage.setItem("fromAppStore",false);
  }
  togleAnalyticsMenu=(name)=>{
    if(name && name==='analytics'){
      this.setState({showAnalyticsMenu:!this.state.showAnalyticsMenu});
    } else {
      this.setState({showAnalyticsMenu:false})
    }
  }
  render() {
    return (
      <div className="vs-sidebar-parent">
        <div className="vs-sidebar">
          {/**/}
          <div className="vs-sidebar--items">
            <div>
              <div
                slot="header"
                className="justify-between"
                style={{textAlign:"center",padding:"15px 19px 8px 0px",width:"100%"}}
              >
                <div className="logo  items-center">
                  <img
                    src="/img/logos/platifilogo.png"
                    alt="logo"
                    className="font_family_montserrat"
                    style={{width: "130px"}}
                   /> 
                  {/* <span style={{fontWeight:"500",paddingLeft:"5px",fontSize:"28px",verticalAlign:"middle"}}>  iDAMS</span> */}
                </div>
              </div>
              <section
                className="ps-container scroll-area--main-sidebar pt-2 ps ps--theme_default ps--active-y"
                data-ps-id="21dec5ac-6c85-8822-e452-d02216d027d2"
              >
                <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    className="sidebar-item"
                    activeClassName="selected-tab"
                    to="/dashboard"
                    // target="_self"
                    style={{cursor:"pointer"}}
                    
                  >
                    <span className="feather-icon ab select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-home"
                      >
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                        <polyline points="9 22 9 12 15 12 15 22" />
                      </svg>
                    </span>
                    <span className="truncate" style={{marginRight:3}} title="Dashboard">Dashboard </span>
                    {/* <span style={{fontSize:9,backgroundColor:'#9082f2',color:'#fff',borderRadius:3,padding:2}}>coming soon</span> */}
                    {/**/}
                  </NavLink>
                </div>
                <span className="navigation-header truncate">Pages</span>
                <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/profile"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit">
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                      </svg>
                    </span>
                    <span className="truncate" title="Profile">Profile</span>
                  </NavLink>
                </div>
               
                {/* <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/resources"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-layout"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          ry="2"
                        />
                        <line x1="3" y1="9" x2="21" y2="9" />
                        <line x1="9" y1="21" x2="9" y2="9" />
                      </svg>
                    </span>
                    <span className="truncate" title="Resources">Resources</span>
                  </NavLink>
                </div> */}

                {/* <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/appstore"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-copy"
                      >
                        <rect
                          x={9}
                          y={9}
                          width={13}
                          height={13}
                          rx={2}
                          ry={2}
                        />
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                      </svg>
                    </span>
                    <span className="truncate" title="App Store">App Store</span>
                  </NavLink>
                </div> */}

                {/* <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/courses"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-book">
                      <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                      <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
                      </svg>
                    </span>
                    <span className="truncate" title="Courses">Courses</span>
                  </NavLink>
                </div> */}
              {/*                 
                <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/users"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    </span>
                    <span className="truncate" title="Users">Users</span>
                  </NavLink>
                </div> */}

                <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/sub-admins"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                   <span className="feather-icon select-none relative">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    </span>
                    <span className="truncate" title="Sub Admins">Sub Admins</span>
                  </NavLink>
                </div>

                
                <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/dl-team"
                    className="sidebar-item"
                    activeClassName="selected-tab">
                   <span className="feather-icon select-none relative">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    </span>
                    <span className="truncate" title="DL Team">DL Team</span>
                  </NavLink>
                </div>
                <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/sme-users"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                   <span className="feather-icon select-none relative">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    </span>
                    <span className="truncate" title="Sub Admins">SMEs</span> 
                  </NavLink>  
                </div> 
                {/* <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/course-analytics"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bar-chart-2">
                      <line x1="18" y1="20" x2="18" y2="10"/>
                      <line x1="12" y1="20" x2="12" y2="4"/>
                      <line x1="6" y1="20" x2="6" y2="14"/>
                    </svg>
                    </span>
                    <span className="truncate" title="Course Analytics">Course Analytics</span>
                  </NavLink>
                </div>   */}
                <div className="vs-sidebar--item" onClick={()=>this.togleAnalyticsMenu('analytics')}>
                  <a href="# "
                    className={"sidebar-item "+(this.state.showAnalyticsMenu?"vs-sidebar-item-analytics":'')}
                    onClick={($event)=>{$event.preventDefault()}}
                  >
                    <span className="feather-icon select-none relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bar-chart-2">
                      <line x1="18" y1="20" x2="18" y2="10"/>
                      <line x1="12" y1="20" x2="12" y2="4"/>
                      <line x1="6" y1="20" x2="6" y2="14"/>
                    </svg>
                    </span>
                    <span className="truncate" title="Analytics" style={{width:'100%'}}>
                      Analytics 
                      <span className={"pull-right "+(this.state.showAnalyticsMenu?'multi-sidemenu-chevron-rotate90':'')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 24 20" fill="none" 
                          stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" 
                          className="feather feather-chevron-right">
                          <polyline points="9 18 15 12 9 6" />
                        </svg>
                      </span>
                    </span>
                  </a>
                </div> 
                {this.state.showAnalyticsMenu && <div className="vs-multi-sidemenu">
                  <div className="vs-sidebar--item">
                    <NavLink
                      to="/course-analytics"
                      className="sidebar-item"
                      activeClassName="selected-tab"
                    >
                      <span className="feather-icon select-none relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" 
                          strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" 
                          className="feather feather-circle ">
                          <circle cx={12} cy={12} r={10} />
                        </svg>
                      </span>
                      <span className="truncate" title="Course Analytics">Course Analytics</span>
                    </NavLink>
                  </div>
                  <div className="vs-sidebar--item">
                    <NavLink
                      to="/analytics"
                      className="sidebar-item"
                      activeClassName="selected-tab"
                    >
                      <span className="feather-icon select-none relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" 
                          strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" 
                          className="feather feather-circle ">
                          <circle cx={12} cy={12} r={10} />
                        </svg>
                      </span>
                      <span className="truncate" title="Resource Analytics">Resource Analytics</span>
                    </NavLink>
                  </div>  
                </div>}

                <div className="vs-sidebar--item">
                  <NavLink
                    to="/dmz-rules"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-git-commit"
                      >
                        <circle cx={12} cy={12} r={4} />
                        <line x1="1.05" y1={12} x2={7} y2={12} />
                        <line x1="17.01" y1={12} x2="22.96" y2={12} />
                      </svg>
                    </span>
                    <span className="truncate">{"DMZ"}</span>
                    {/**/}
                  </NavLink>
                </div>
                 
                {/* <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/requested-content"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                    strokeWidth="2" fill="none" strokeLinecap="round"
                    strokeLinejoin="round" className="css-i6dzq1">
                    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                    </svg></span>
                    <span className="truncate" title="Requested Content">Requested Content</span>
                  </NavLink>
                </div>  */}
                {/* <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/live-courses"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">                 
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-video">
                        <polygon points="23 7 16 12 23 17 23 7" />
                        <rect x={1} y={5} width={15} height={14} rx={2} ry={2} />
                      </svg>
                    </span>
                    <span className="truncate" title="Live Classroom">Live Classroom</span>
                  </NavLink>
                </div>             */}
                {/*<div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    className="sidebar-item"
                    activeClassName="selected-tab"
                    to="/dashboard"
                  >
                    <span
                      className="feather-icon select-none relative"
                      style={{ fontSize: "15px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-map-pin"
                      >
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                        <circle cx={12} cy={10} r={3} />
                      </svg>
                    </span>
                    <span className="truncate" title="Locations">Locations</span>
                    {/**/}
                  {/* </NavLink>
                </div> */}
                {/* <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/dashboard"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-copy"
                      >
                        <rect
                          x={9}
                          y={9}
                          width={13}
                          height={13}
                          rx={2}
                          ry={2}
                        />
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                      </svg>
                    </span>
                    <span className="truncate" title="App Store">App Store</span>

                  </NavLink>
                </div> */}
                {/* <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/dashboard"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                    onClick={this.triggerRolesPage}
                  >
                    <span className="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-layout"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          ry="2"
                        />
                        <line x1="3" y1="9" x2="21" y2="9" />
                        <line x1="9" y1="21" x2="9" y2="9" />
                      </svg>
                    </span>
                    <span className="truncate" title="Roles">Roles</span> */}
                    {/**/}
                  {/*</NavLink>
                </div>
                <div className="vs-sidebar--item" onClick={this.togleAnalyticsMenu}>
                  <NavLink
                    to="/dashboard"
                    className="sidebar-item"
                    activeClassName="selected-tab"
                  >
                    <span className="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user-plus"
                      >
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                        <circle cx="8.5" cy={7} r={4} />
                        <line x1={20} y1={8} x2={20} y2={14} />
                        <line x1={23} y1={11} x2={17} y2={11} />
                      </svg>
                    </span>
                    <span className="truncate" title="Users">Users</span>
                  </NavLink>
                </div>*/}
              </section>
              {/**/}
              {/**/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
