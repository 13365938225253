import * as userDashboardActionTypes from "../../../actionTypes";

const initialState = {
  courses:[],
  coursesLoader:false,
  selectedSlotUsageCourse:{},
  userSlotUsageHistoryLoader: false,
  userSlotUsageHistory: {totalConsumption:"0 minutes",totalIdleTime:"0 minutes",quantitativeScore:0,sessionData:[]},
  //course usage
  selectedCourse:{},
  initialLoaderForBookedAndUsedSlots:true,
  graphLoaderForBookedAndUsedSlots:false,
  bookedAndUsedSlots:{},
  bookedAndUsedSlotSeries:[{name: 'Booked Slots',data:[]},{name: 'Used Slots',data:[]}],
  bookedAndUsedSlotOptions :{
    chart: {
      type: 'area',
      height: 450,
      stacked: false,
      fontFamily: 'Montserrat,sans-serif',
      foreColor: '#626262'
    },
    colors: ['#008FFB','#00E396'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      lineCap: 'butt'
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.4,
        opacityTo: 0.5,
      },
      colors: ['#008FFB','#00E396'],
    },
    legend: {
      show:false,
      position: 'top',
      horizontalAlign: 'right'
    },
    xaxis: {
      type: 'category',
      categories:[]
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: ['#008FFB','#00E396'],
      width: 2,
      dashArray: 0,      
    }
  }
}

export default function setUserDashboardElements(state=initialState, action) {
  switch (action.type) {
    case userDashboardActionTypes.USER_DASHBOARD_COMPONENT_ELEMENTS: {
      const {name,value} = action.payload; 
      return {
        ...state,[name]:value
      }   
    }
    default:
      return state;
  }
}