import React, {useEffect ,useState } from 'react';
import './AccessLab.scss';
import { connect } from "react-redux";
import * as accessLabContainer from "../../container/actions/AccessLab/action";
import { bindActionCreators } from 'redux';
import Rating from "../../packages/Ratings";
import RemoteProctoring from '../RemoteProctoring/index';

function AccessLab (props) {
  useEffect(()=>{
    props.getBookedSlots()
  },[])

  const [rowData, setrowData] = useState("");

  // var closeSpecialInstructionPopup=()=>{
  //   if(props.accessLabState.showWebCam && props.accessLabState.webcamVideoLoader){
  //     props.stopWebcamRecording();
  //     var canvas = document.getElementById('canvasElement');
  //     var ctx = canvas.getContext("2d");
  //     ctx.clearRect(0, 0, canvas.width, canvas.height);
  //   }
  //   document.getElementById('specialInstructionPopup').style.display='none';
  // }

   var  saveRowData = (data) =>{
    setrowData(data)
    
  } 

  return (
    <div>
      <div className="add-on-load-animation">
        <div id="successSnackbar">{props.accessLabState.successAlertMessage}</div>
        <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.accessLabState.errorAlertMessage}</div>
        <div className="col-md-12 nj-card">
          <div className="row">
            <div className="nj-card__header">
              <div className="nj-card__title">
                <h4>Booked Slots</h4>
              </div>
            </div>
          </div>
          {!props.accessLabState.accessLabSlotsLoader?<div className="row">
            {props.accessLabState.bookedSlots && props.accessLabState.bookedSlots.length>0 ?
            <div className="tbl-content" style={{overflowY:"auto",overflowX:"auto",maxHeight:"76.3vh"}}>
              <table className="nj-table nj-table--tbody-table font_family_montserrat">
                <thead className="nj-table--thead">
                  <tr>
                    <th>
                      <div className="nj-table-text">SL NO.</div>
                    </th>
                    <th>
                      <div className="nj-table-text">COURSE NAME</div>
                    </th>
                    <th>
                      <div className="nj-table-text">BOOKED DATE</div>
                    </th>
                    <th>
                      <div className="nj-table-text">BOOKED TIME</div>
                    </th>
                    <th>
                      <div className="nj-table-text">REMOVE</div>
                    </th>
                    <th>
                      <div className="nj-table-text">ACCESS LAB</div>
                    </th>
                    <th>
                      <div className="nj-table-text">REVIEW</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.accessLabState.bookedSlots.map((data,i)=>{
                    return (<tr key={data._id}  className="tr-values nj-table--tr tr-table-state-null ">
                      <td className="td nj-table--td">{i+1}</td>
                      <td className="td nj-table--td">{data.subscription}</td>
                      <td className="td nj-table--td">{data.date.substring(0,10) || 0}</td>
                      <td className="td nj-table--td">{data.bookingTime}</td>
                      <td className="td nj-table--td">
                        <button type="button" className="nj-flow-button bg-color-red" disabled={!data.cancelSlot} onClick={()=>{props.openCancelPopup(data)}}>Cancel</button>
                      </td>
                      <td className="td nj-table--td">
                        {!(props.accessLabState.accessLabLoader && (props.accessLabState.selectedAccessLabSlot && props.accessLabState.selectedAccessLabSlot._id===data._id))?<button type="button" className="nj-flow-button" disabled={!data.accessLab} onClick={()=>{props.getRemoteProcteringStatus (window,data);saveRowData(data)}}>Access Lab</button>:
                        <img src={"/img/loaders/spinningwheel.gif"} height={50} width={50} style={{marginTop:'-10px'}} alt="loader" />}
                      </td>
                      {/* <td className="td nj-table--td">{data.name}</td>  */}
                      <td className="td nj-table--td">
                        <button type="button" className="nj-flow-button" style={{height:30,width:30,padding:5,borderRadius:'50%'}} disabled={data.isRated} onClick={()=>props.selectedSlotForRating(data)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={19} viewBox="0 0 24 24" fill="#fff" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-star">
                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                          </svg>
                        </button>
                      </td>
                    </tr>)
                  })}
                </tbody>
              </table>
            </div>
            :
            <div className="col-md-12 idams_noDataFound" style={{height:"76.3vh"}}>
              <span className="font_family_montserrat">No data found</span>
            </div>
            }
          </div>
          :<div className="row">
            <div className="col-md-12" style={{height:"76.3vh",display:'flex',alignItems:'center',justifyContent:'center'}}>
              <img src={"/img/loaders/spinningwheel.gif"} height={50} width={50} alt="loader" />
            </div>
          </div>}
        </div>
      </div>
      {/*popup to cancel slot*/}
      <div id="slotCancelPopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup" style={{maxWidth:"400px"}}>
          <header className="popup-header font_family_montserrat">
            <span className="font_family_montserrat">Cancel Slot</span>
            <span ><i className="popup-close material-icons" onClick={()=>{document.getElementById('slotCancelPopup').style.display='none'; }}>close</i></span>
          </header>
          {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
          <div className="popup-content">
            <div className="col-md-12 font_family_montserrat">
              <p>Do you realy want to Cancel?</p>
              {!props.accessLabState.popupLoader ? <button  type="button" className="nj-flow-button pull-right" style={{marginLeft:10}} onClick={props.confirmCancel}>Confirm</button> :<img src={"/img/loaders/spinningwheel.gif"}  className="pull-right" height={50} width={50} style={{color: "white",textAlign:"center",marginTop:'-10px'}} alt="loader" />}          
              <button type="button" className="nj-flow-button bg-color-red pull-right" onClick={()=>{document.getElementById('slotCancelPopup').style.display='none'; }}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
      {/*popup to ratings*/}
      <div id="slotRatingPopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup" style={{maxWidth:"400px"}}>
          <header className="popup-header font_family_montserrat">
            <span className="font_family_montserrat">Write a Review</span>
            <span ><i className="popup-close material-icons" onClick={props.closeRatings}>close</i></span>
          </header>
          {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
          <div className="popup-content">
            <div className="col-md-12 font_family_montserrat">
              <h4  className="font_family_montserrat rating-title-h4">Rate this course </h4>
              <Rating getRatings={props.getRatings} width="36" height="36" color="rgba(115,103,240)" key={props.accessLabState.selectedSlot._id || 0} classname="rating-props-container" /> {props.accessLabState.ratingValue>0&&(<span className="pull-right rating-expression-span" >{(props.accessLabState.ratingValue || "0")+"/5"}</span>)}
              <br/>
              <br/>
              <h4  className="font_family_montserrat rating-title-h4">Write your review in the following:</h4>
              <textarea className="textarea-input-css font_family_montserrat" rows="5" cols="48" placeholder=" Write something..." value={props.accessLabState.reviewValue} onChange={props.getReview} style={{marginTop:"10px"}}></textarea>
              <br/>
              <br/>
              <div className="" style={{textAlign:'center'}}>
                {!props.accessLabState.popupLoader ? <button  type="button" className="nj-flow-button font_family_montserrat" style={{color: "white",textAlign:"center",width:"100%",lineHeight:"2.2"}} onClick={props.submitReview}>SUBMIT REVIEW</button> :<img src={"/img/loaders/spinningwheel.gif"}  className="" height={50} width={50} style={{marginTop:'-10px'}} alt="loader" />}
              </div>                 
              <br/>           
            </div>
          </div>
        </div>
      </div>

        {/*popup to AccessLab*/}

      <div id="specialInstructionPopup"  className={"nj-overly add-rebound-animation"}>
              <div className="popup-nj my-popup font_family_montserrat"  style={{padding:"0px",maxWidth:"580px"}}>
                <header className="popup-header">
                  <span>Special Instructions</span>
                  <span style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null" onClick={()=>props.closeSpecialInstructionPopup()}>close</i></span>
                </header>
                <div className="popup-content" >
                  {props.accessLabState.webcamVideoLoader ?
                  <div className="col-md-12"> 
                    {props.accessLabState.showWebCam ? 
                          <RemoteProctoring />
                      :
                        <form className="form col-md-12" >                         
                        <div className="row" style={{fontSize: "14px",fontWeight:"400px",color:"#52525e",textAlign:"left"}}>
                        <div>
                          <p>
                        <strong >Before Logging into WebRdp, please follow the below steps :</strong>
                        </p>
                        <p style={{lineHeight:1.4}}><strong>Step 1 :</strong> Open the link <strong>https://www.onlinemictest.com/webcam-test/</strong></p>
                        <p style={{lineHeight:1.4}}><strong>Step 2 :</strong> Check the below scenarios .</p>
                        <ul>
                        <li>Click the <strong>'play'</strong> button.</li>
                        <li>Click <strong>'allow'</strong> if you see a popup message in the browser.</li>
                        <li>Now you should <strong>see yourself (or whatever your webcam is pointed at!) and then close the website and click on WebRdp to Access the lab.</strong></li>
                        <li>If you don't see <strong>yourself or if you see a error message or a black screen follow Step 3 to enable the WebCam.</strong></li>
                        </ul>
                        </div>
                        <p style={{lineHeight:1.4}}><strong>Step 3 :</strong></p>
                        <ul>
                        <li>Go to <strong> "Device Manager" </strong> and enable camera if it is disabled.</li>
                        <li>If installed any <strong>"antivirus" </strong>, block or stop the antivirus </li>
                        <li>Follow <strong> Step 1 and 2 </strong> </li>
                        </ul>
                        
                        <p style={{color:"red"}}><strong> * Note : If you don't see yourself (or whatever your webcam is pointed at in the above url) and accessing the WebRdp, then your slot is "not validated or considered".</strong> </p>

                        </div>
                        <div className="row" style={{marginTop:'0px',marginBottom:'10px'}}>
                                <button type="button" className="nj-flow-button bg-color-red pull-right" onClick={()=>props.closeSpecialInstructionPopup()}  style={{margin:5}}>Cancel</button>
                                  {!props.accessLabState.webRdpButtonLoader ? <button type="button" className="nj-flow-button pull-right" style={{margin:5}} onClick={()=>{props.handleAccessLab("webRdpButtonLoader",window,rowData)}} >Web RDP</button>:<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-13px"}} height={60} width={60} alt="loader" />}
                        </div>    

                        </form>                  
                  }
                  </div>
                  :
                  <div className="row">
                    <div className="col-md-12" style={{height:"520px",display:'flex',alignItems:'center',justifyContent:'center'}}>
                      <img src={"/img/loaders/spinningwheel.gif"} height={50} width={50} alt="loader" />
                    </div>
                  </div>
                }
                </div>
              </div>
            </div>

    </div>
  );
}


const mapStateToProps = state => ({
  accessLabState: state.accessLabState,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  closeRatings:accessLabContainer.closeRatings,
  confirmCancel:accessLabContainer.confirmCancel,
  getBookedSlots:accessLabContainer.getBookedSlots,
  getRatings:accessLabContainer.getRatings,
  getReview:accessLabContainer.getReview,
  handleAccessLab:accessLabContainer.handleAccessLab,
  openCancelPopup:accessLabContainer.openCancelPopup,
  submitReview:accessLabContainer.submitReview,
  selectedSlotForRating:accessLabContainer.selectedSlotForRating,
  getRemoteProcteringStatus:accessLabContainer.getRemoteProcteringStatus,
  stopWebcamRecording:accessLabContainer.stopWebcamRecording,
  closeSpecialInstructionPopup:accessLabContainer.closeSpecialInstructionPopup
  
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(AccessLab);
