import * as RequestedContentActionTypes from "../../../actionTypes";

const initialState = {
  RequestedContent_loader:false,
  RequestedContent : [],
  dupRequestedContent : [],
  shouldPopupAnimate:false,
  savedComments:{},
  successSnackbarMessage:"",
  errorSnackbarMessage:"",
  dateAndTime:new Date(),
  setAssignWorkFlow:[],
  approveProduceEmail:[],
  createProduceEmail:[],
  Recepients:[],
  disableSendButton:false,
  uploadFileLoader:false,
  sendNotificationLoader:false,
  assignloader:false,
  selectedRequestedContentRow:{},
  selectedIndex:0
    
}

export default function(state=initialState, action) {
  switch (action.type) {
    

    case RequestedContentActionTypes.REQUESTEDCONTENT_GET_REQUESTEDCONTENT: {
        const {content} = action.payload; 
        return {
          ...state,RequestedContent:content.reverse(),dupRequestedContent:content,RequestedContent_loader:true,
        }
      }
      
    
    case RequestedContentActionTypes.REQUESTEDCONTENT_DELETE_REQUESTED_CONTENT: {
      const {updatedRequestedContent} = action.payload; 
      return {
        ...state,RequestedContent:updatedRequestedContent
      }
    }

    case RequestedContentActionTypes.REQUESTEDCONTENT_ONCHANGE_ADDCOMMENTS: {
      const{comment,index} = action.payload;
      var RequestedContent1=state.RequestedContent;
      
      RequestedContent1[index]=comment
      
      return {
        ...state,RequestedContent :RequestedContent1

      }
    }

    case RequestedContentActionTypes.REQUESTEDCONTENT_GET_REQUESTEDCOMMENT: {
      const {content} = action.payload; 
      return {
        ...state,savedComments:content
      }
    }

    case RequestedContentActionTypes.REQUESTEDCONTENT_SEARCH_REQUESTED_CONTENT: {
      const {searchRequestedArray} = action.payload; 
      return {
        ...state,RequestedContent:searchRequestedArray
      }
    }

    case RequestedContentActionTypes.REQUESTEDCONTENT_SET_BOOLEAN_VALUE: {
      // console.log("snackbar",action.payload);
      const {name,value} = action.payload; 
      return {
        ...state,[name]:value
      }
    }


  case RequestedContentActionTypes.REQUESTEDCONTENT_GET_RECEPIENTS: {
    const {recepients} = action.payload; 
    if(recepients.length===0){
      var disableButton = true
    }
        return {
          ...state,Recepients:recepients,disableSendButton:disableButton
    }
  }


  case RequestedContentActionTypes.GET_ASSIGN_DATA: {
    //console.log("ASDFGHGFDSDFGHC",action.payload)
    const {content} = action.payload; 
    return {
      ...state,approveProduceEmail:content
    }
  }

  case RequestedContentActionTypes.GET_CREATE_PRODUCE_DATA: {
    const {content} = action.payload; 
    return {
      ...state,createProduceEmail:content
    }
  }

  case RequestedContentActionTypes.REQUESTEDCONTENT_COMPONENT_ELEMENTS: {
    const {name,content } = action.payload; 
    return {
      ...state,[name]:content
    }
  }

    default:
      return state;
  }
}