import * as requestResourceActions from "../../../actionTypes";

export const setRequestResourceObject = content => ({
  type: requestResourceActions.REQUEST_RESOURCE_RESOURCE_OBJECT,
  payload: {
    content
  }
});

export const setRequestResourceElements = (name,content )=> ({
  type: requestResourceActions.REQUEST_RESOURCE_COMPONENT_ELEMENTS,
  payload: {
    name,
    content
  }
})
