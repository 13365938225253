import React, {useState, useEffect } from 'react';
import './CreatePlaylist.scss';
import { connect } from "react-redux";
import * as createPlaylistContainer from "../../container/actions/CreatePlaylist/action";
import { bindActionCreators } from 'redux';
import ResourcesPage from "../Resources/index.js";

// const dummyArray=["a","b","c","d","e","f","g","g","h","i","j","k","l","m","n"];

function CreatePlaylist (props) {
  const [resourceUniqueIndex, setResourceUniqueIndex] = useState('');
  const [showPopup, setshowPopup] = useState(false);

  useEffect(()=>{
    props.onLoadContent();
    props.setCreatePlaylistElements('selectedAddResourceIndex','')
    props.setCreatePlaylistElements('selectedPlaylistIndex','')
  },[])
 
  var showResourcePop=(playlistIndex,subTitleIndex)=>{
    setResourceUniqueIndex(Math.floor(Math.random() * Math.floor(100)))
    props.setCreatePlaylistElements('selectedAddResourceIndex',subTitleIndex)
    props.setCreatePlaylistElements('selectedPlaylistIndex',playlistIndex)
    document.getElementById('resourcePopup').style.display='block'; 
    setshowPopup(true) 
  }

   var getResources=(resources)=>{
    props.addResourcesToSubtitle(resources)   
   
  }
  
  var handleHideResourcePopUp=()=>{
    document.getElementById('resourcePopup').style.display='none';  
  }
  
  // var handleChangeGrade=(i,id,questIndex,e)=>{
  //     let numericRegex = /^[0-9]*$/;
  //     let numericRegexValidation = numericRegex.test(String(e.target.value))
    
  //     if(numericRegexValidation===true){
  //       props.handleQuestionChange(i,id,questIndex,e)
  //     }   
   
  // }
  return (
    <div>
      <div className="add-on-load-animation">
        <div id="successSnackbar">{props.createPlaylistState.successAlertMessage}</div>
        <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.createPlaylistState.errorAlertMessage}</div>
        <div className="col-md-12 nj-card">
          <form onSubmit={($event)=>props.handleSubmit($event,props.history)}>
            <div className="row">
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4>Create Playlist</h4>
                </div>
                <button type="button" className="nj-flow-button" style={{marginTop:"-10px"}} onClick={()=>props.handleReturn(props.history)}>Back</button>
              </div>
            </div>
            <div className="row" style={{padding:'10px 0'}}>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12 ">
                    <label className="idams-label" style={{fontSize:14}}>Playlist Name </label>
                    <input type="text" 
                      className="vs-input--input form-control " 
                      value={props.createPlaylistState.playlistName}
                      placeholder="Ex: Automation Foundation" 
                      autoComplete="off" 
                      name="name"
                      onChange={($event)=>{props.setCreatePlaylistElements('playlistName',$event.target.value)}}
                      required 
                    />
		               
                  </div>
               
                  <div className="col-md-12 " style={{marginTop:10}}>
                    <label className="idams-label" style={{fontSize:14}}>Version </label>
                    <input type="text" 
                      className="vs-input--input form-control " 
                      value={props.createPlaylistState.publishedVersion}
                      placeholder="Ex : 1" 
                      autoComplete="off" 
                      name="name"
                      onChange={($event)=>{props.setCreatePlaylistElements('publishedVersion',$event.target.value)}}
                      required 
                    />
		               
                  </div>

                  <div className="col-md-12" style={{marginTop:10}}>
                    <p className="idams-label" style={{fontSize:14}}>Select Discipline</p>
                    <div className="nj-form-select">
                      <select name="selectedDiscipline" style={{textTransform:'capitalize'}} value={JSON.stringify(props.createPlaylistState.selectedDiscipline)} onChange={props.handleDropdownChange} autoComplete="off" required>
                        <option value="">Pick a discipline</option>
                        {props.createPlaylistState.disciplines.map((discipline,index)=>{
                            return (<option key={index} value={JSON.stringify(discipline)}>{discipline.name}</option>)
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12" style={{marginTop:10}}>
                    <p className="idams-label" style={{fontSize:14}}>Select Content Type</p>
                    <div className="nj-form-select">
                      <select name="selectedContentType" style={{textTransform:'capitalize'}} value={JSON.stringify(props.createPlaylistState.selectedContentType)} onChange={props.handleDropdownChange} autoComplete="off" required>
                        <option value="">Pick a content type</option>
                        {props.createPlaylistState.contentTypes.map((contentType,index)=>{
                            return (<option key={index} value={JSON.stringify(contentType)}>{contentType.name}</option>)
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{padding:'10px 0'}}>
              <div className="col-md-12">
                <button type="button" className="playlist-add-button" onClick={props.addTitle}>+ Add Title</button>
              </div>
              {props.createPlaylistState.playlistArray.length>0 && props.createPlaylistState.playlistArray.map((item,i)=>{
                return (
                  <div className="col-md-12" key={i}>
                    <div className="col-md-6" style={{padding:'0 20px',border:'1px solid #ccc',margin:'10px 0'}}>
                      <div className="row" style={{padding:'10px 0'}}>
                        <span className="pull-right" title="Remove" onClick={()=>props.deleteTitle(i)} style={{color:'rgb(251, 104, 104)',cursor:'pointer',paddingRight:5}}>
                          <svg xmlns="http://www.w3.org/2000/svg" 
                            width={20} 
                            height={20} 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            stroke="currentColor" 
                            strokeWidth={2} 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            className="feather feather-trash">
                            <polyline points="3 6 5 6 21 6" />
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                          </svg>
                        </span>
                        <label className="idams-label pull-left">Title:</label>
                        <input type="text" 
                          className="vs-input--input form-control" 
                          value={item.title}
                          placeholder="Ex: Automation Foundation" 
                          autoComplete="off" 
                          name="name"
                          onChange={($event)=>{props.handleTitleChange(i,$event.target.value)}}
                          required 
                        />
                      </div>
                      <div>
                      <div className="row">
                        <div className="playlist-add-subtitle-button">
                          <button type="button" className="playlist-add-button" onClick={()=>props.addSubtitle(i)}>+ Add Subtitle</button>
                        </div>
                      </div>
                      {item.subtitle.map((subtitle,id)=>{
                        return (
                          <div key={id}>
                           <div id={'createPlaylistSubTitle'+i+id.toString()}></div>
                            <div>
                              <label className="idams-label pull-left" style={{fontSize:14,fontWeight:'400',paddingTop:"10px"}}>Subtitle:</label>
                              <span className="pull-right" title="Remove" onClick={()=>props.deleteSubtitle(i,id)} style={{color:'rgb(251, 104, 104)',cursor:'pointer',paddingRight:5}}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                  width={16} 
                                  height={16} 
                                  viewBox="0 0 24 24" 
                                  fill="none" 
                                  stroke="currentColor" 
                                  strokeWidth={2} 
                                  strokeLinecap="round" 
                                  strokeLinejoin="round" 
                                  className="feather feather-trash">
                                  <polyline points="3 6 5 6 21 6" />
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                </svg>
                              </span>
                              <input type="text" 
                                className="vs-input--input form-control" 
                                value={subtitle.title}
                                placeholder="Ex: Automation Foundation" 
                                autoComplete="off" 
                                name="name"
                                onChange={($event)=>{props.handleSubtitleChange(i,id,$event.target.value)}}
                                required 
                              />
                             
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <label className="idams-label pull-left" style={{padding:'10px 0',fontSize:14,fontWeight:'400'}}>Resources: </label>
                                <button type="button" className="nj-flow-button pull-right" style={{marginTop:6}} onClick={()=>{showResourcePop(i,id)}}>Add</button>
                                <span className="pull-right" title="Playlists are not allowed" style={{marginTop:12,color:'#7569f0',cursor:'pointer',margin:'12px 5px 0px 0px'}}>
                                  <svg xmlns="http://www.w3.org/2000/svg" 
                                    width={16} height={16} 
                                    viewBox="0 0 24 24" fill="none" 
                                    stroke="currentColor" strokeWidth={2} 
                                    strokeLinecap="round" strokeLinejoin="round" 
                                    className="feather feather-info">
                                    <circle cx={12} cy={12} r={10} /><line x1={12} y1={16} x2={12} y2={12} />
                                    <line x1={12} y1={8} x2="12.01" y2={8} />
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div className="tbl-content" style={{maxHeight:'200px',overflow:'auto'}}>
                              {subtitle.resources.length>0 ? <div className="resources-wrapper">
                              {subtitle.resources.length>0 && subtitle.resources.map((resource,index)=>{
                                return (
                                  <div className="resource_card add-rebound-animation" key={index}>
                                    <div className="resource_card-content">
                                      <div className="resource-card-float">
                                        <div className="overlay_checkbox resource-card-float-element"> 
                                          <label className="checkbox coloured">
                                            <input type="checkbox" checked={resource.isChecked} onChange={()=>props.handleSubtitleCheckboxChange(i,id,resource._id)} /> 
                                            <span className="checkbox-material"><span className="check"></span></span> 
                                          </label>
                                        </div>
                                      </div>
                                      {resource.type===2 && <div className="resource_card-content-img-wrapper">
                                        <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                                      </div>}
                                      {resource.type===0 && <div className="resource_card-content-img-wrapper">
                                        <img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource" />
                                      </div>}
                                      {resource.type===4 && <div className="resource_card-content-img-wrapper">
                                        <img className="resource_card-content-img" src={"/img/resource-icons/file.svg"} alt="resource" />
                                      </div>}
                                    </div>
                                    <div className="resource_card-footer">
                                      <p className="font_family_montserrat">{resource.displayName}</p>
                                    </div>
                                  </div>
                                )
                              })}
                              </div>:<p style={{textAlign:'center'}}>Please add only resources</p>}
                            </div> 
                            
                             
                           
                         <div style={{paddingTop:"10px"}} className="col-md-12">
                            <div className="row" style={{paddingLeft:"15px"}}>
                               <label className="idams-label pull-left" style={{fontSize:14,fontWeight:'500'}}>Create quiz:</label>
                               <div className="row col-md-12 col-sm-12 col-xs-12">
                                  <div className="col-md-7 col-sm-7 col-xs-7 playlist-add-subtitle-button">
                                      <button type="button" className="playlist-add-button" onClick={()=>props.addQuestion(id,i)}>+ Add new question</button>
                                </div>
                            </div>                        
                                                     
                          </div>
                           {item.subtitle[0].quizquestions.length>0 && <div>
                           {subtitle.quizquestions.map((quizquestion,questid)=>{
                          return (
                          <div key={questid}>
                            <div>
                            <label className="idams-label pull-left" style={{fontSize:14,fontWeight:'500',paddingTop:"10px"}}>Question no : {questid+1}</label>
                              <span className="pull-right" title="Remove" onClick={()=>props.deleteQuestion(i,id,questid)} style={{color:'rgb(251, 104, 104)',cursor:'pointer',paddingRight:5}}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                  width={16} 
                                  height={16} 
                                  viewBox="0 0 24 24" 
                                  fill="none" 
                                  stroke="currentColor" 
                                  strokeWidth={2} 
                                  strokeLinecap="round" 
                                  strokeLinejoin="round" 
                                  className="feather feather-trash">
                                  <polyline points="3 6 5 6 21 6" />
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                </svg>
                              </span>
                              <input type="text" 
                                className="vs-input--input form-control" 
                                value={quizquestion.question}
                                placeholder="Ex: Define Machine Learning?" 
                                autoComplete="off" 
                                name="question"
                                onChange={($event)=>{props.handleQuestionChange(i,id,questid,$event)}}
                                required
                              />
                            </div>
                           
                            <div className="col-md-12">
                              <label className="idams-label pull-left " style={{fontSize:12,fontWeight:'450',paddingTop:"5px"}}>Options :</label>
                            </div>
                           
                            {quizquestion.options.map((option,optionid)=>{
                            return (
                            <div  key={option.id}>
                              <div className="col-md-6" >
                                <div className="search" >
                                  <input type="text" 
                                      className="vs-input--input form-control content1" 
                                      placeholder="Enter options" 
                                      autoComplete="off" 
                                      name="optionName"
                                      style={{height:"30px",marginTop:"5px"}}
                                      // onKeyPress={(e)=>{saveOption(i,id,questid,optionid,e)}}
                                      onChange={(e)=>props.handleOptionChange(i,id,questid,optionid,e.target.value)}
                                    /> <span className="cancel" onClick={()=>props.deleteOption(i,id,questid,optionid,option.id)} style={{cursor:'pointer'}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null" style={{paddingTop:"3px"}}>close</i></span>
                                </div>
                              </div>
                            </div>)})}

                              <div className="row">
                              <div className="row col-md-9" style={{marginTop:0}}>
                                <label className="idams-label pull-left " style={{fontSize:12,fontWeight:'450',paddingTop:"5px",paddingRight:"5px"}}>Select Answer :</label>
                                <div>
                                  <div className="col-md-8" style={{paddingBottom:"10px",paddingLeft:"0px",paddingRight:"0px"}}>
                                      <div className="nj-form-select">
                                        <select name="selectedanswer" style={{textTransform:'capitalize'}} onChange={(e)=>props.handleQuestionChange(i,id,questid,e)} autoComplete="off " required>
                                          <option value="">Pick correct answer</option>
                                          {quizquestion.options.map((option,index)=>{
                                            {if(option.option !== ""){
                                              return (
                                              <option key={index}  value={JSON.stringify(option)}>{option.option}</option>)}
                                              }})}
                                        </select>
                                      </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3" style={{paddingRight:"0px",paddingLeft:"5px"}}>
                                    <label className="idams-label" style={{fontSize:12,fontWeight:'450',paddingTop:"5px",paddingRight:"5px"}}>Grade:</label>
                                    <input type="number" 
                                      className="vs-input--input " 
                                      placeholder="" 
                                      style={{height:"28px",width:"55%"}}
                                      autoComplete="off" 
                                      name="grade"
                                      onChange={($event)=>{ props.handleQuestionChange(i,id,questid,$event)}}
                                      value={quizquestion.grade}
                                      // onChange={(e)=>props.handleGradeChange(i,id,e.target.value)}
                                      required
                                      />
                              </div>
                              </div>

                           </div>
                        )
                      })}
                        </div>}
                       
                         
                        </div>
                      </div>
                        )
                      })}
                       
                      </div>
                    </div>
                  
                    <div id={'createPlaylistTitle'+i.toString()}></div>
                  </div>
                )
              })}
              <div className="col-md-6">
                <button type="submit" className="nj-flow-button pull-right">Submit</button>
                {props.createPlaylistState.showSubmitLoader && <img className="pull-right" src='/img/loaders/spinningwheel.gif' height="60" width="60" alt="" style={{marginTop:'-10px'}} />}
              </div>
            </div>
          </form>

           {/*popup to show resources*/}
            <div id="resourcePopup" className={"nj-overly add-rebound-animation"} style={{zIndex:"2"}}>
                      <div className="popup-nj  my-popup" style={{maxWidth:"1000px"}}>
                        <header className="popup-header font_family_montserrat">
                          <span className="font_family_montserrat">Select Resources</span>
                          <span ><i className="popup-close material-icons" onClick={handleHideResourcePopUp}>close</i></span>
                        </header>
                        {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
                        <div className="popup-content">
                          <div className="col-md-12 font_family_montserrat">
                            {showPopup &&
                               <ResourcesPage  borrowedResource={true} getResources={getResources} key={resourceUniqueIndex} /> }
                          </div>
                        </div>
                </div>
           </div>




        </div>
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
  createPlaylistState: state.createPlaylistState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onLoadContent:createPlaylistContainer.onLoadContent,
  handleDropdownChange:createPlaylistContainer.handleDropdownChange,
  addTitle: createPlaylistContainer.addTitle,
  handleTitleChange:createPlaylistContainer.handleTitleChange,
  addSubtitle:createPlaylistContainer.addSubtitle,
  addQuestion:createPlaylistContainer.addQuestion,
  addOption:createPlaylistContainer.addOption,
  handleSubtitleChange:createPlaylistContainer.handleSubtitleChange,
  handleSubtitleCheckboxChange:createPlaylistContainer.handleSubtitleCheckboxChange,
  handleQuestionChange:createPlaylistContainer.handleQuestionChange,
  handleOptionChange:createPlaylistContainer.handleOptionChange,
  deleteTitle:createPlaylistContainer.deleteTitle,
  deleteSubtitle:createPlaylistContainer.deleteSubtitle,
  deleteQuestion:createPlaylistContainer.deleteQuestion,
  deleteOption:createPlaylistContainer.deleteOption,
  handleSubmit:createPlaylistContainer.handleSubmit,  
  setCreatePlaylistElements:createPlaylistContainer.setCreatePlaylistElements,
  displayAlert:createPlaylistContainer.displayAlert,
  handleReturn:createPlaylistContainer.handleReturn,
  addResourcesToSubtitle:createPlaylistContainer.addResourcesToSubtitle
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(CreatePlaylist);
