import * as userProfileActionTypes from "../../../actionTypes";

export const setUserProfileElements = (name,content )=> ({
  type: userProfileActionTypes.USER_PROFILE_COMPONENT_ELEMENTS,
  payload: {
    name,
    content
  }
})

export const setBooleanValue = (name,value) => ({
  type: userProfileActionTypes.USERPROFILE_SET_BOOLEAN_VALUE,
  payload: {
    name:name,
    value:value
  }
});