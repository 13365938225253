
import React, {useEffect} from 'react';
import { connect } from "react-redux";
import * as courseAnalyticsContainer from "../../../container/actions/CourseAnalytics/action";
import { bindActionCreators } from 'redux';
import "./SlotUsage.scss";
import Ratings from '../../../packages/Ratings';

function SlotUsage(props) {

  useEffect( () => {
    props.loadSlotUsageListDemo()
    // console.log("props",props)
  },[]);
  // onload function end

  var convertDate=(date)=>{
    var fromDateInSec = new Date(date.ratedAt);
    var tempDate = date.ratedAt.toString().substring(0,10);
    // console.log("tempdate",tempDate);
    var time = fromDateInSec.toString().substring(16,21);
    // console.log("fromDateInSec",date.createdAt,tempDate,tempMonth)
    var fromDate = tempDate + " at " + time;
    return fromDate;
   }

   var getRatings = () => {

  }

  return(
    <div className="add-on-load-animation">
      <div className="col-md-12 nj-card">
        <div className="row">
          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4 className="font_family_montserrat">Slot Usage</h4>
            </div>  
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {!props.courseAnalyticsState.slotUsageLoader ?                 
              <div className="course-table-margin-top">
                {props.courseAnalyticsState.slotUsageList.length>0?  
                  <div className="tbl-content row roles-table-height" style={{overflowY:"auto",overflowX:"auto",maxHeight:"75vh"}}>
                    <table className="nj-table nj-table--tbody-table font_family_montserrat">
                      <thead className="nj-table--thead">
                        <tr>
                          <th>
                            <div className="nj-table-text">SL NO.</div>
                          </th>                                                   
                          <th>
                            <div className="nj-table-text">Booking Date</div>
                          </th>
                          <th>
                            <div className="nj-table-text">Booking Time</div>
                          </th>
                          <th>
                            <div className="nj-table-text">Usage (in mins)</div>
                          </th>
                          <th>
                            <div className="nj-table-text">Usage History</div>
                          </th>
                          <th>
                            <div className="nj-table-text">Slot Ratings</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="dl-team_tbody">    
                        {props.courseAnalyticsState.slotUsageList.map((data,i) => {
                          return(                            
                            <tr key={i} className="tr-values nj-table--tr tr-table-state-null ">
                              <td className="td nj-table--td">{i+1}</td>
                              <td className="td nj-table--td">{data.date.substring(0,10)}</td>
                              <td className="td nj-table--td">{data.bookingTime}</td>
                              <td className="td nj-table--td">{data.usage}</td>
                              <td className="td nj-table--td">
                                <button type="button" className="nj-flow-button" onClick={()=>props.showSlotUsage(data)}>View</button>
                              </td>
                              <td className="td nj-table--td">
                                <button type="button" className="nj-flow-button" onClick={()=>props.showRatings(data)}>View Ratings</button>
                              </td>
                            </tr>
                          )
                        })}                        
                      </tbody>
                    </table>
                  </div>   
                  :
                  <div className="col-md-12 idams_noDataFound" style={{height:"60.3vh"}}>
                    <span className="font_family_montserrat">No data found</span>
                  </div>                                        
                }                                                             
                </div> :
                <div className="row" style={{height:"60.3vh"}}>
                  <div className="col-md-12 aligh-loader-image-center" >
                    <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                  </div>
                </div>
              } 
          </div>
        </div>
        {/* popup slots */}
        <div id="slotUsagePopup" className="nj-overly add-rebound-animation slot-popup" >
          <div className="popup-nj my-popup" style={{maxWidth:300}}>
            <header className="popup-header slot-popup-header" style={{borderBottom:'1px solid #e6e6e6'}}>
              <span>Slot Usage Details</span>
              <span onClick={()=>document.getElementById('slotUsagePopup').style.display='none'} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
            </header>
            <div className="popup-content" style={{padding:'10px'}}>
              {!props.courseAnalyticsState.userSlotUsageHistoryLoader?<div>
                {props.courseAnalyticsState.userSlotUsageHistory.sessionData && props.courseAnalyticsState.userSlotUsageHistory.sessionData.length>0 ? <div className="col-md-12 slot-usage-box">
                  {props.courseAnalyticsState.userSlotUsageHistory.sessionData.map((item,i)=>{
                    return (
                      <div className="elements" key={i}>
                        {item.status==='logonTime' && <div className="icon" style={{backgroundColor:'rgb(222, 246, 233)',color:'rgb(92, 200, 112)'}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} viewBox="0 0 24 24" fill="none" 
                            stroke="currentColor" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" 
                            className="feather feather-monitor">
                            <rect x={2} y={3} width={20} height={14} rx={2} ry={2} />
                            <line x1={8} y1={21} x2={16} y2={21} />
                            <line x1={12} y1={17} x2={12} y2={21} />
                          </svg>
                          </div>}
                          {item.status==='startTime' && <div className="icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" 
                              className="feather feather-copy">
                              <rect x={9} y={9} width={13} height={13} rx={2} ry={2} />
                              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                            </svg>
                          </div>}
                          {item.status==='endTime' && <div className="icon" style={{backgroundColor:'rgb(253, 239, 221)',color:'rgb(244, 158, 65)'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} viewBox="0 0 24 24" fill="none" 
                              stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" 
                              className="feather feather-crop">
                              <path d="M6.13 1L6 16a2 2 0 0 0 2 2h15" /><path d="M1 6.13L16 6a2 2 0 0 1 2 2v15" />
                            </svg>
                          </div>}
                          {item.status==='logoutTime' && <div className="icon" style={{backgroundColor:'rgb(250, 228, 228)',color:'rgb(234, 84, 85)'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} viewBox="0 0 24 24" fill="none" 
                              stroke="currentColor" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" 
                              className="feather feather-log-out">
                              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                              <polyline points="16 17 21 12 16 7" />
                              <line x1={21} y1={12} x2={9} y2={12} />
                            </svg>
                          </div>}
                          {item.status!=='logonTime' && item.status!=='startTime' && item.status!=='logoutTime' && item.status!=='endTime' && <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" 
                              className="feather feather-copy">
                              <rect x={9} y={9} width={13} height={13} rx={2} ry={2} />
                              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                            </svg>
                          </div>}
                        <div className="title-box">
                          <p> {item.message} </p>
                          <span>at {item.time}</span>
                        </div>
                      </div>
                    )
                  })}
                </div>:
                <div className="col-md-12 slot-usage-no-data">
                  <p>No data available..</p>
                </div>}
              </div>:
              <div className="col-md-12 slot-usage-no-data">
                <img src={"/img/loaders/spinningwheel.gif"}  height={60} width={60} alt="loader" />
              </div>}
            </div>
          </div>
        </div>

        {/* Ratings Popup */}
        <div id="ratingsPopup" className="nj-overly add-rebound-animation slot-popup" >
          <div className="popup-nj my-popup" style={{maxWidth:380}}>
            <header className="popup-header slot-popup-header" style={{borderBottom:'1px solid #e6e6e6'}}>
              <span>Slot Rating & Review</span>
              <span onClick={()=>document.getElementById('ratingsPopup').style.display='none'} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
            </header>
            <div className="popup-content" style={{padding:'10px 0px'}}>
              {!props.courseAnalyticsState.userSlotRatingLoader?<div>
                {props.courseAnalyticsState.userSlotRating && props.courseAnalyticsState.userSlotRating.email.length>0 ? <div className="col-md-12 slot-usage-box">
                      {/* <div className="">
                      <div className="col-md-12" style={{paddingLeft:"0px"}}>
                        <h5 className="comments-head"><b>Username:</b><span className="ng-binding">  {props.courseAnalyticsState.userSlotRating.username}</span></h5>
                        <h5 className="comments-head"><b>Email:</b><span className="ng-binding">  {props.courseAnalyticsState.userSlotRating.email}</span></h5><br/>
                      </div>
                      <div className="row ratings-progress-wrapper-box">
                        <div className="ratings-progress-wrapper">
                          <div className="ratings-progress" data-percentage={(props.courseAnalyticsState.userSlotRating.rating && Math.round(props.courseAnalyticsState.userSlotRating.rating))*20}>
                            <span className="ratings-progress-left">
                              <span className="ratings-progress-bar" />
                            </span>
                            <span className="ratings-progress-right">
                              <span className="ratings-progress-bar" />
                            </span>
                            <div className="ratings-progress-value">
                              {props.courseAnalyticsState.userSlotRating.rating && (parseFloat((parseFloat(props.courseAnalyticsState.userSlotRating.rating)).toFixed(1)) || 0)}
                            </div>
                          </div>
                          <Ratings color="rgb(99, 112, 173)" getRatings={getRatings} height="15" width="15" rating={props.courseAnalyticsState.userSlotRating.rating && (Math.round(props.courseAnalyticsState.userSlotRating.rating) || 0)} key={props.courseAnalyticsState.userSlotRating.rating} disable={true} />
                        </div>
                      </div><br/>

                      <div className="col-md-12" style={{paddingLeft:"0px"}}><br/>
                        <h5 className="comments-head"><b>Comments:</b></h5>
                      </div>
                        <div className="col-md-12"  style={{width:"100%",backgroundColor: "rgb(244, 244, 244)",minHeight:"50px"}} >
                      {props.courseAnalyticsState.userSlotRating.comment === undefined || props.courseAnalyticsState.userSlotRating.comment.length === 0 ?
                          <p style={{textAlign:"center",paddingTop:"15px"}}> No  comment found</p>
                        :
                      <div className="row" >
    
                        <div className="row" style={{backgroundColor:"#f4f4f4",minHeight:"50px",paddingTop: "10px",paddingBottom: "0px",marginLeft:"2%",marginRight:"2%",marginBottom:"2%"}}>
                          <div className="col-md-12 col-sm-12 ng-binding" style={{marginBottom:"0",fontSize: "14px",fontFamily: "roboto",maxWidth:"500px",paddingLeft:"5px",paddingBottom: "0px"}}>
                                {props.courseAnalyticsState.userSlotRating.comment}
                              </div>
                              <div className="col-md-12 col-sm-12" style={{marginTop:"10px",fontSize: "12px",fontFamily: "roboto",paddingRight:"0px"}}>
                              <div className="col-md-12 col-sm-12" style={{paddingRight:"5px",paddingBottom:"0px"}}>
                                <span className="pull-right ng-binding"> {props.courseAnalyticsState.userSlotRating.email}</span><br/>
                                {/* <p className="pull-right ng-binding">{props.courseAnalyticsState.userSlotRating.ratedAt.substring(0,10)+" at "+props.courseAnalyticsState.userSlotRating.ratedAt.substring(11,16)}</p> 
                                <p className="pull-right ng-binding">{convertDate(props.courseAnalyticsState.userSlotRating)}</p>
                              </div>
                              </div>
    
                        </div>
                      </div>
                      }
                    </div>
                      </div> */}

                      {/**new rating popup */}

                      <div className="">
                       <div className="col-md-12" style={{paddingLeft:"0px"}}>
                          <div className="col-md-2" style={{paddingTop:"10px",paddingLeft:"0px",}}>
                            <span className="sm:inline-flex xl:hidden cursor-pointer mr-1 feather-icon select-none relative purple-feather-icon" flow="down" style={{color: '#7367f0'}}>
                              <svg viewBox="0 0 24 24" width="66" height="74" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                            </span>
                          </div>
                          <div className="col-md-10">
                            <h5 className="comments-head ratings-comment-h5"><span className="ng-binding">  {props.courseAnalyticsState.userSlotRating.username}</span></h5>
                            <h6 className="comments-head ratings-comment-h6"><span className="ng-binding">  {props.courseAnalyticsState.userSlotRating.email}</span></h6>
                            <Ratings margin-bottom="10px" color="#7367f0" getRatings={getRatings} height="20" width="20" rating={props.courseAnalyticsState.userSlotRating.rating && (Math.round(props.courseAnalyticsState.userSlotRating.rating) || 0)} key={props.courseAnalyticsState.userSlotRating.rating} disable={true} />
                          </div>
                        </div>
                        <div className="col-md-12" style={{paddingLeft:"0px"}}>
                          <h5 className="comments-head"><b>Comments:</b></h5>
                        </div>
                        <div className="col-md-12 ratings-no-comment">
                      {props.courseAnalyticsState.userSlotRating.comment === undefined || props.courseAnalyticsState.userSlotRating.comment.length === 0 ?
                          <p style={{textAlign:"center",paddingTop:"5px"}}> No  comment found</p>
                        :
                      <div className="row">

                        <div className="row ratings-comment-box">
                          <div className="col-md-12 col-sm-12 ng-binding ratings-comment-line"><br/>
                                {props.courseAnalyticsState.userSlotRating.comment}
                              </div>
                              <div className="col-md-12 col-sm-12 ratings-comment-date" >
                              <div className="col-md-12 col-sm-12" style={{paddingRight:"5px",paddingBottom:"0px"}}>
                                {/* <span className="pull-right ng-binding"> {props.courseAnalyticsState.userSlotRating.email}</span><br/> */}
                                {/* <p className="pull-right ng-binding">{props.courseAnalyticsState.userSlotRating.ratedAt.substring(0,10)+" at "+props.courseAnalyticsState.userSlotRating.ratedAt.substring(11,16)}</p> */}
                                <p className="pull-right ng-binding" style={{paddingRight:5,marginBottom: 5}}>{convertDate(props.courseAnalyticsState.userSlotRating)}</p>
                              </div>
                              </div>

                        </div>
                      </div>
                      }
                    </div>
                      </div>
                      {/**new rating popup end */}

                </div>:
                <div className="col-md-12 slot-usage-no-data">
                  <p>No data available..</p>
                </div>}
              </div>:
              <div className="col-md-12 slot-usage-no-data">
                <img src={"/img/loaders/spinningwheel.gif"}  height={60} width={60} alt="loader" />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
  courseAnalyticsState: state.courseAnalyticsState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadSlotUsageListDemo:courseAnalyticsContainer.loadSlotUsageListDemo,
  showSlotUsage:courseAnalyticsContainer.showSlotUsage,
  showRatings:courseAnalyticsContainer.showRatings,
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(SlotUsage);
