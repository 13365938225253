
import React, { Component,Fragment} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
// import ReactApexChart from 'react-apexcharts';
import customHttp from '../../../packages/CustomHttp';
import DatePicker from 'react-date-picker';
import 'react-datepicker/dist/react-datepicker.css';
import UserBookedVsUsedSlotGraph from './UserBookedVsUsedSlotGraph.js';
import UserSlotUsage from '../SlotUsage';
import {setSlotUsageListActual,setSlotUsageListDemo} from "../../../container/actions/CourseAnalytics/action";
import CustomStorage from '../../../packages/CustomStorage/';

class UserBookedvsUsedSlotUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate:'',
      endDate:'',
      allCourses:[],
      allUsers:[],
      selectedCourseId:"",
      selectedUserId:"",
      selectedUser:CustomStorage.localStorageGet('selectedUserData'),
      selectedCourse:"",
      callChildFunction:function(){},
    }
  }
 
  componentDidMount(){  
    var endDate = new Date();
    this.setState({endDate:endDate})
    var dateInSec = endDate.getTime();
    var d = dateInSec-6.048e+8;
    var fromDateInSec = new Date(d);
    var tempDate = fromDateInSec.getDate();
    var tempMonth = fromDateInSec.getMonth()+1;
    var tempYear = fromDateInSec.toString().substring(11,15);
    var fromDate = tempMonth + "/" + tempDate + "/" + tempYear;   // date conversion dd/mm/yyyy
    this.setState({startDate:new Date(fromDate),endDate:endDate},()=>{this.checkDemoOrActualFunction()})
  }

handleChangeStart =(sdate)=>{
  if(this.props.demoAndActualValue ==="demo"){
  const startValue = sdate
  this.setState({startDate:startValue},()=>{
    //this.state.callChildFunction();
  })
  this.props.setSlotUsageListDemo()
  }else{
    const startValue = sdate
    this.setState({startDate:startValue},()=>{
      this.state.callChildFunction();
    })
    this.props.setSlotUsageListActual(startValue,this.state.endDate,this.state.selectedCourse,this.state.selectedUser)
    
  }   
}
handleChangeCourse=(e)=>{
  var course=JSON.parse(e.target.value)
  if(this.props.demoAndActualValue==="demo"){
    this.setState({selectedCourseId:course._id,selectedCourse:course},()=>{
      this.fetchAllUsersByCourseForDemo();
    });
    this.props.setSlotUsageListDemo()
   }else{
    this.setState({selectedCourseId:course._id,selectedCourse:course},()=>{
      this.fetchAllUsersByCourse();
     //this.state.callChildFunction();
     this.props.setSlotUsageListActual(this.state.startDate,this.state.endDate,course,this.state.selectedUser)
    })
   }
  
}
handleChangeUsers=(e)=>{
  if(this.props.demoAndActualValue ==="demo"){
    var user=JSON.parse(e.target.value)
    this.setState({selectedUserId:user._id,selectedUser:user},()=>{
    //this.state.callChildFunction();
    //this.props.setSlotUsageListActual(this.state.startDate,this.state.endDate,this.state.selectedCourse,user)
    });
    this.props.setSlotUsageListDemo()
  }else{
    var user=JSON.parse(e.target.value)
    this.setState({selectedUserId:user._id,selectedUser:user},()=>{
    this.state.callChildFunction();
    this.props.setSlotUsageListActual(this.state.startDate,this.state.endDate,this.state.selectedCourse,user)
    })
  }
}
handleChangeEnd=(edate)=>{
  if(this.props.demoAndActualValue ==="demo"){
  const endValue = edate
  this.setState({endDate:endValue},()=>{})
  this.props.setSlotUsageListDemo()
  //this.props.setSlotUsageListActual(this.state.startDate,endValue,this.state.selectedCourse,this.state.selectedUser)
  }else{
    const endValue = edate
    this.setState({endDate:endValue},()=>{this.state.callChildFunction()})
    this.props.setSlotUsageListActual(this.state.startDate,endValue,this.state.selectedCourse,this.state.selectedUser)
  }
}
getChildFunction=(a)=>{
  this.setState({callChildFunction:a},()=>{this.state.callChildFunction()})
}
fetchAllCourse=()=>{
  var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    var url = '/api/course/allCourses/user/'+userDetails._id
   customHttp.get(url)
        .then(response => {
          if(response.data.message.length>0){
            var courses=response.data.message.reverse();
           let course_id=CustomStorage.localStorageGet('selectedCourseData')._id
            for(let i=0;i<courses.length;i++){
              if(courses[i]._id===course_id){
                this.setState({allCourses:courses,selectedCourseId:courses[i]._id,selectedCourse:courses[i]},()=>{
                  this.fetchAllUsersByCourse();
                  //this.state.callChildFunction();
                  this.props.setSlotUsageListActual(this.state.startDate,this.state.endDate,this.state.selectedCourse,this.state.selectedUser)
                })
              }
            }
          }else{
             this.setState({allCourses:[]},()=>{
             // this.state.callChildFunction();
             this.props.setSlotUsageListActual(this.state.startDate,this.state.endDate,this.state.selectedCourse,this.state.selectedUser)
             this.fetchAllUsersByCourse();
             })
          }
        })
        .catch(error => {
          this.setState({allCourses:[]},()=>{
            this.fetchAllUsersByCourse();
           })
          console.log("failed", error.response);
        });
 }

 fetchAllUsersByCourse=()=>{
   var url = '/api/analytics/userDetailsByCourse/courseId/'+this.state.selectedCourseId
   customHttp.get(url)
    .then(response => {
      if(response.data.message.length>0){
        var users=response.data.message.reverse();
        let user_id=CustomStorage.localStorageGet('selectedUserData')._id
        for(var i=0;i<users.length;i++){
          if(users[i]._id===user_id){
            this.setState({allUsers:users,selectedUserId:users[i]._id},()=>{              
              //this.state.callChildFunction();
            })
            this.state.callChildFunction();
            this.props.setSlotUsageListActual(this.state.startDate,this.state.endDate,this.state.selectedCourse,users[i])
            break;
          }else{
            this.setState({allUsers:users,selectedUserId:users[0]._id},()=>{
            }) 
          }
          if(i===users.length-1){
            this.state.callChildFunction();
            this.props.setSlotUsageListActual(this.state.startDate,this.state.endDate,this.state.selectedCourse,this.state.selectedUser)
          }
        }
      }else{
          this.setState({selectedUserId:"no users available",allUsers:[]},()=>{
            this.state.callChildFunction();
            this.props.setSlotUsageListActual(this.state.startDate,this.state.endDate,this.state.selectedCourse,this.state.selectedUser)
          })
      }
    })
    .catch(error => {
        this.setState({selectedUserId:"no users available",allUsers:[]},()=>{
          this.state.callChildFunction();
          this.props.setSlotUsageListActual(this.state.startDate,this.state.endDate,this.state.selectedCourse,this.state.selectedUser)
        })
      console.log("failed", error.response);
    });
 }
 checkDemoOrActualFunction=()=>{
  if(this.props.demoAndActualValue==="demo"){
    this.fetchAllCourseForDemo();
   }else{
    this.fetchAllCourse();
   }
 }
 fetchAllCourseForDemo=()=>{
  var demoCoursesArray=[{name: "Fluid Mechanics",_id: "5c15f3907257166d93c72201",displayName:"Fluid Mechanics"},
 {name: "Manufacturing Technology",_id: "5c15f4677257166d93c72202",displayName:"Manufacturing Technology"},
 {name: "Applied Thermodynamics",_id: "5c15f40c7257166d93c72203",displayName:"Applied Thermodynamics"},
 {name: "Dynamics of Machinery",_id: "5c15f3907257166d93c72204",displayName:"Dynamics of Machinery"},
 {name: "Design of Machine Elements",_id: "5c15f3907257166d453c72205",displayName:"Design of Machine Elements"}];
   let course_id=CustomStorage.localStorageGet('selectedCourseData')._id;
   for(let i=0;i<demoCoursesArray.length;i++){
     if(demoCoursesArray[i]._id===course_id){
       this.setState({allCourses:demoCoursesArray,selectedCourseId:demoCoursesArray[0]._id,selectedCourse:demoCoursesArray[i]},()=>{
         this.fetchAllUsersByCourseForDemo();
         //this.state.callChildFunction();
         this.props.setSlotUsageListDemo()
       })
     }
   }
 }

 fetchAllUsersByCourseForDemo=()=>{
  const allDummyUsers = [{username:"test",email:"test@gmail.com",bookedCount:"5",usedCount:"10",lastLoggedOutDate:"10-3-2020",_id:"5c15f3907257166d453c71101"},
  {username:"user1",email:"user1@gmail.com",bookedCount:"3",usedCount:"2",lastLoggedOutDate:"22-3-2020",_id:"5c15f3907257166d453c71102"},
  {username:"user2",email:"user2@gmail.com",bookedCount:"8",usedCount:"2",lastLoggedOutDate:"17-3-2020",_id:"5c15f3907257166d453c71103"},
  {username:"user3",email:"user3@gmail.com",bookedCount:"5",usedCount:"1",lastLoggedOutDate:"10-3-2020",_id:"5c15f3907257166d453c71104"},
  {username:"user4",email:"user4@gmail.com",bookedCount:"10",usedCount:"4",lastLoggedOutDate:"1-3-2020",_id:"5c15f3907257166d453c71105"},
  {username:"user5",email:"user5@gmail.com",bookedCount:"8",usedCount:"5",lastLoggedOutDate:"8-2-2020",_id:"5c15f3907257166d453c71106"},
  {username:"user6",email:"user6@gmail.com",bookedCount:"1",usedCount:"1",lastLoggedOutDate:"11-3-2020",_id:"5c15f3907257166d453c71107"},
  {username:"user7",email:"user7@gmail.com",bookedCount:"0",usedCount:"0",lastLoggedOutDate:"12-3-2020",_id:"5c15f3907257166d453c71108"},
  {username:"user8",email:"user8@gmail.com",bookedCount:"9",usedCount:"3",lastLoggedOutDate:"13-3-2020",_id:"5c15f3907257166d453c71109"},
  {username:"user9",email:"user9@gmail.com",bookedCount:"11",usedCount:"10",lastLoggedOutDate:"14-3-2020",_id:"5c15f3907257166d453c71110"},
  {username:"user10",email:"user10@gmail.com",bookedCount:"1",usedCount:"0",lastLoggedOutDate:"15-3-2020",_id:"5c15f3907257166d453c71111"}];
    let user_id=CustomStorage.localStorageGet('selectedUserData')._id
    for(var i=0;i<allDummyUsers.length;i++){
      if(allDummyUsers[i]._id===user_id){
        this.setState({allUsers:allDummyUsers,selectedUserId:allDummyUsers[i]._id},()=>{              
        this.state.callChildFunction();
        })       
      }
    }
  }
   goToCourseAnalytics=()=>{
    const selectedDemoOrActual =CustomStorage.localStorageGet('selectedDemoOrActual') 
    CustomStorage.localStorageSet('backFromUserAnalytics',{selectedType:selectedDemoOrActual,back:true})
    var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    if(userDetails.type===1){
      this.props.props.history.push("/course-analytics")
    }else if(userDetails.type===2){
      this.props.props.history.push("/dlt/course-analytics")
    }else{
      this.props.props.history.push("/sub-admin/course-analytics")
    }
  }
  render(){
    return(
      <div className="">
         <div className="col-md-12 col-sm-12 nj-card">
          <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
            <div className="nj-card__header" style={{borderBottom:"none",paddingTop: '0px'}}>
              <div className="col-md-12 col-sm-12" >
                <div className="row">
                  <div className="nj-card__header row" style={{borderBottom:"0px"}}>
                    <div className="col-md-10 col-xs-12 nj-card__title pull-left">
                     <h4>User Details <span className="user-details-demo-title">{this.props.demoAndActualValue}</span></h4>
                    </div>
                    <div className="col-md-2 col-xs-12 nj-card__title">
                    <button type="button" className="nj-flow-button pull-right" style={{marginTop:"-14px"}} onClick={this.goToCourseAnalytics}>Back</button>
                    </div>
                   
                  </div>
                </div>  
              </div>  
              <div className="nj-card__title col-md-12">
                <div className="col-md-3 col-xs-12" style={{marginLeft:"0px",paddingLeft:"0px",paddingBottom:"5px"}}>
                  <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontWeight:"500"}}>Select Course</label>
                  <div className="nj-form-select">
                    <select className="vs-input--input" style={{lineHeight:'25px',borderRadius:'5px'}}  onChange={this.handleChangeCourse} value={JSON.stringify(this.state.selectedCourse)}>
                      {this.state.allCourses.length===0 && (<option value=''>No courses available</option>)}
                      {this.state.allCourses.map((obj,i) =>{
                        return <option key={i} value={JSON.stringify(obj)}>{obj.name}</option>
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-3 col-xs-12" style={{marginLeft:"0px",paddingLeft:"0px",paddingBottom:"5px"}}>
                  <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontWeight:"500"}}>Select User</label>
                  <div className="nj-form-select">
                    <select className="vs-input--input" style={{lineHeight:'25px',borderRadius:'5px'}}  onChange={this.handleChangeUsers} value={JSON.stringify(this.state.selectedUser)}>
                      {this.state.allUsers.length===0 && (<option value=''>No users available</option>)}
                      {this.state.allUsers.map((data,i) =>{
                        return <option key={i} value={JSON.stringify(data)}>{data.username|| data.email}</option>
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-3 col-xs-12" style={{marginLeft:"0px",paddingLeft:"0px",paddingBottom:"5px"}}>
                  <Fragment>
                    <label className="nj-location-label pull-left font_family_montserrat" style={{fontWeight:500}}>From Date </label>
                    <DatePicker className="datepicker-calender"
                        calendarClassName={"calender-design"}
                        format="dd/MM/yyyy"
                        clearIcon={null}
                        calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
                        showNeighboringMonth={false}
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                        value={this.state.startDate}
                        onChange={this.handleChangeStart}
                    />
                  </Fragment>
                </div>

                <div className="col-md-3 col-xs-12" style={{marginLeft:"0px",paddingLeft:"0px",paddingBottom:"5px"}}>
                  <Fragment>
                    <label  className="nj-location-label pull-left font_family_montserrat" style={{fontWeight:500}}>To Date </label>
                    <DatePicker className="datepicker-calender"
                        calendarClassName={"calender-design"}
                        format="dd/MM/yyyy"
                        clearIcon={null}
                        calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
                        showNeighboringMonth={false}
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                        value={this.state.endDate}
                        onChange={this.handleChangeEnd}
                    />
                  </Fragment>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div>
          <UserBookedVsUsedSlotGraph  selectedCourseId={this.state.selectedCourseId} selectedUserId={this.state.selectedUserId} endDate={this.state.endDate} startDate={this.state.startDate} demoAndActualValue={this.props.demoAndActualValue} callChildFunction={this.getChildFunction} key={this.state.selectedCourseId}/>
        </div>
        <div className="row">
          <div className="col-md-12" style={{marginTop:10}}>
            <UserSlotUsage />
          </div>
        </div>
     </div>
    );
  }
}

const mapStateToProps = state => ({
  courseAnalyticsState: state.courseAnalyticsState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setSlotUsageListActual,
  setSlotUsageListDemo
}, dispatch)
export default connect(
  mapStateToProps,mapDispatchToProps
)(UserBookedvsUsedSlotUsage);