
import React, { Component } from "react";
import './Auth.scss';
import axios from 'axios';

class Auth extends Component{

  constructor(props){
    super(props);
      this.state={
        userPermission:true
      }
  }
 
  loginToPortal = () =>{
    var token=this.props.match.params.token;
    var id= this.props.match.params.id;
    var email= this.props.match.params.email;
    var tokenObj={ headers: {"Authorization" : `Bearer ${token}`,"id":id}}
    axios
      .get("/sso-authenticate",tokenObj)
      .then(response => {
        if(response !== undefined || response !== null){
          if(response.data.success){
            axios
            .get("/api/user/getUserDetails/email/" + email)
            .then(res=>{
              sessionStorage.setItem("userData",JSON.stringify(res.data.message));
              this.getConfidentialDetails(res.data.message._id)
              // sessionStorage.setItem("userProfileData",true);
              // this.props.history.push('/user/courses')            
            })
            .catch(err=>{
              console.log("err",err)
              this.gotoLoginPage();
              //this.setState({userPermission:false})
            })
          }else{
                this.gotoLoginPage();
            //this.setState({userPermission:false}) 
          }
        }else{
          this.gotoLoginPage();
          //this.setState({userPermission:false})
        }
      })
      .catch(error => {
       this.gotoLoginPage();
       // this.setState({userPermission:false})
      });
  }

  getConfidentialDetails = (objId) => {
    axios
    .get('/api/userProfile/getProfileDetails/'+objId)
   .then(response => {
     if(response.data.message != null || response.data.message != undefined){
       sessionStorage.setItem("userProfileData",true);
       if(Object.keys(response.data.message).length>0 && response.data.message.firstname!=null){
         this.props.history.push('/user/courses')
       }else{
         this.props.history.push('/user/profile')
       }

     }else{
       sessionStorage.setItem("userProfileData",false);
       this.props.history.push('/user/profile')
     }
   })
   .catch(error => {
     sessionStorage.setItem("userProfileData",false);
     this.props.history.push('/user/profile')
     console.log("failed", error);
   });
 }

  componentDidMount(){
    this.loginToPortal();
  }
  gotoLoginPage=()=>{
    this.props.history.push('/')
  }
  render(){
    return(
      <div className="col-md-12" >
        <div className="row">
          {this.state.userPermission ?
             <div className="col-md-12 auth-wrapper">
                <div  style={{fontFamily:"'Montserrat',sans-serif"}}>
                  Redirecting to ELF Portal
                </div>
                <div  style={{fontFamily:"'Montserrat',sans-serif"}}>
                  Please Wait...
                </div>
                <div>
                  <img
                    src="/img/loaders/loading.gif"
                    alt="logo" 
                    width="200px"        
                  /> 
                </div>
              </div> 
            :
            <div>
              <div className="bg-image-404">
                <div className="login-bt">
                  <button className="nj-flow-button" style={{fontFamily:"'Montserrat',sans-serif"}} onClick={this.gotoLoginPage}>GO TO HOMEPAGE</button>
                </div>
              </div>
           </div>
          }         
        </div>
      </div>
    );
  }
}

export default Auth;
