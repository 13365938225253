import * as RequestedContentActionTypes from "../../../actionTypes";




export const getRequestedContent = (content) => ({
    type: RequestedContentActionTypes.REQUESTEDCONTENT_GET_REQUESTEDCONTENT,
    payload: {
      content
    }
  });

   
export const deleteRequestedContent  = (updatedRequestedContent) => ({
    type: RequestedContentActionTypes.REQUESTEDCONTENT_DELETE_REQUESTED_CONTENT,
    payload: {
        updatedRequestedContent
    }
  });

  export const handleChangeComment= (comment,index) => ({
    type: RequestedContentActionTypes.REQUESTEDCONTENT_ONCHANGE_ADDCOMMENTS,
    payload: {
      comment,
      index
    }
  });

  export const getRequestedComment = (content) => ({
    type: RequestedContentActionTypes.REQUESTEDCONTENT_GET_REQUESTEDCOMMENT,
    payload: {
      content
    }
  });

  export const searchRequestedContent = (searchRequestedArray) => ({
    type: RequestedContentActionTypes.REQUESTEDCONTENT_SEARCH_REQUESTED_CONTENT,
    payload: {
      searchRequestedArray
      // {console.log("sdfg",applicationArray1)}
    }
  });

  export const setBooleanValue = (name,value) => ({
    type: RequestedContentActionTypes.REQUESTEDCONTENT_SET_BOOLEAN_VALUE,
    payload: {
      name:name,
      value:value
    }
  });

  export const getAssignData = (content) => ({
    type: RequestedContentActionTypes.GET_ASSIGN_DATA,
    payload: {
      content
    }
  });
  
  export const getcreateProduceData = (content) => ({
    type: RequestedContentActionTypes.GET_CREATE_PRODUCE_DATA,
    payload: {
      content
    }
  });
  
  export const getRecepients = (recepients) => ({
    type: RequestedContentActionTypes.REQUESTEDCONTENT_GET_RECEPIENTS,
    payload: {
      recepients
    }
  });

  export const setRequestedContentElements = (name,content )=> ({
    type: RequestedContentActionTypes.REQUESTEDCONTENT_COMPONENT_ELEMENTS,
    payload: {
      name,
      content
    }
  })
  
  

