
// import axios from "axios";
import * as resourceActions from "./index";
import * as RequestedContentActions from "../RequestedContent/index";
import customHttp from '../../../packages/CustomHttp/';
import snackbar from '../../../packages/Snackbar/';
import * as RequestedContentContainer from "../RequestedContent/action";
import * as requestResourceState from "../RequestResource/action";
const resourceObj = {description:'',discipline:'',contentType:''};

export function getAllResources(disc,cate,archive,searchValue,onload){
   return async (dispatch ,getState)=> {
      //console.log("disc",disc,"cate",cate,archive,searchValue,"checked array",JSON.parse(JSON.stringify(getState().resourceState.resourceVideo)));
      dispatch(setResourceElements('showLoadResourceLoader',true))
      try {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      let response = await customHttp.get("/api/resourceuri/"+disc._id+"/"+cate._id+"/"+archive._id+"/"+searchValue+"/"+userDetails._id);
      dispatch(setResourceElements('showLoadResourceLoader',false))
      if(response.data.message && Array.isArray(response.data.message)){
         var arr = response.data.message;
         // console.log("resourcessssss",arr);
         arr = arr.map(obj=> ({ ...obj, isChecked: false }));
         var resourceOrgArray=JSON.parse(JSON.stringify(getState().resourceState.resourceOriginalArray))
         if(resourceOrgArray.length>0 && arr.length>0 && onload!=="onload"){
            for(var i=0;i<resourceOrgArray.length;i++){
               for(var j=0;j<arr.length;j++){
                  if(resourceOrgArray[i]._id===arr[j]._id){
                     arr[j].isChecked=resourceOrgArray[i].isChecked;
                  }
               }
            }
         }
         // var arr1 = JSON.parse(JSON.stringify(arr));
         dispatch(resourceActions.setResourceArray(arr))
         //dispatch(resourceActions.setResourceOriginalArray(arr1))
         dispatch(resourceActions.setFilteredArray(arr));
         //dispatch(handleSearch(arr,getState().resourceState.searchValue))
      } else {
         dispatch(resourceActions.setResourceArray([]))
         //dispatch(resourceActions.setResourceOriginalArray([]));
         dispatch(resourceActions.setFilteredArray([]))
      }
      } catch (err) {
         dispatch(setResourceElements('showLoadResourceLoader',false))
         dispatch(resourceActions.setResourceArray([]))
         //dispatch(resourceActions.setResourceOriginalArray([]))
         dispatch(resourceActions.setFilteredArray([]))
         console.log("error",err)
      }
   }
}

export function getAllDisciplines(){
   return async (dispatch, getState)=> {
      try {
      /*let response = await customHttp.get("/api/branch/");
      let res = JSON.parse(JSON.stringify(response.data.message));
      if(Array.isArray(response.data.message)){
         res.push({_id:'all',name:'All'});
         dispatch(resourceActions.setDisciplines(res.reverse()));
         dispatch(setResourceElements('createResourceDisciplines',response.data.message))
      } else {
         dispatch(resourceActions.setDisciplines([{_id:'all',name:'All'}]));
         dispatch(setResourceElements('createResourceDisciplines',[]))
      }*/
         let response = await customHttp.get("/api/branch/");
         var userDetails=JSON.parse(sessionStorage.getItem("userData")) 
         let responseDepartment = await customHttp.get("/api/department/getAllDepartments");
         let res = JSON.parse(JSON.stringify(response.data.message));
         let resDepartment = JSON.parse(JSON.stringify(responseDepartment.data.message));
         let obj = resDepartment.map(function(val, index){ 
               return {
                  _id:val._id, 
                  name:val.name,
                  type:val.type,
               }; 
         }) 
         if(userDetails.type === 0){
            let tmp = []
               tmp.push(obj)
               res.push( ...tmp[0])
               if(Array.isArray(res)){
                  res.push({_id:'all',name:'All'});
                  dispatch(resourceActions.setDisciplines(res.reverse()));
                  dispatch(setResourceElements('createResourceDisciplines',response.data.message))
               } else {
                  dispatch(resourceActions.setDisciplines([{_id:'all',name:'All'}]));
                  dispatch(setResourceElements('createResourceDisciplines',[]))
               }
         }else if(userDetails.type === 3){
               let obj1 = userDetails.department.map(function(val, index){ 
                     return {
                       _id:val._id, 
                        name:val.name,
                     }; 
               }) 
               let final=[]
               let tmp = []
                  tmp.push(obj1)
                  let temp=tmp[0]
                  for(let i=0;i<temp.length;i++){
                     //if(temp[i].type == 3){
                        final.push(temp[i])
                     //}
                  }
                  if(Array.isArray(final)){
                     final.push({_id:'all',name:'All'});
                     dispatch(resourceActions.setDisciplines(final.reverse()));
                     dispatch(setResourceElements('createResourceDisciplines',final))
                  } else {
                     dispatch(resourceActions.setDisciplines([{_id:'all',name:'All'}]));
                     dispatch(setResourceElements('createResourceDisciplines',[]))
                  }
         }else if(userDetails.type === 2){
               let obj1 = userDetails.department.map(function(val, index){ 
                     return {
                        _id:val._id, 
                        name:val.name,
                     }; 
               }) 
               let final=[]
               let tmp = []
                  tmp.push(obj1)
                  let temp=tmp[0]
                  for(let i=0;i<temp.length;i++){
                     //if(temp[i].type == 2){
                        final.push(temp[i])
                     //}
                  }
                  if(Array.isArray(final)){
                     final.push({_id:'all',name:'All'});
                     dispatch(resourceActions.setDisciplines(final.reverse()));
                     dispatch(setResourceElements('createResourceDisciplines',final))
                     var arrayData = obj1.reverse()
                     dispatch(setResourceElements('uploadResourcesPopupDisciplines',arrayData))
                  } else {
                     dispatch(resourceActions.setDisciplines([{_id:'all',name:'All'}]));
                     dispatch(setResourceElements('createResourceDisciplines',[]))
                     dispatch(setResourceElements('uploadResourcesPopupDisciplines',[]))
                  }
         }
      } catch (err) {
         dispatch(resourceActions.setDisciplines([{_id:'all',name:'All'}]));
         dispatch(setResourceElements('createResourceDisciplines',[]))
         console.log("error",err);
      }
   }
 }

export function getAllContentTypes(){
   return async (dispatch, getState)=> {
      try {
         let response = await customHttp.get("/api/category/");
         let res = JSON.parse(JSON.stringify(response.data.message));
         if(Array.isArray(response.data.message)){
            res.push({_id:'all',name:'All'})
            dispatch(resourceActions.setContentTypes(res.reverse()));
            dispatch(setResourceElements('createResourceContentTypes',response.data.message))
         } else {
            dispatch(resourceActions.setContentTypes([{_id:'all',name:'All'}]));
            dispatch(setResourceElements('createResourceContentTypes',[]))
         }
      } catch (err) {
         dispatch(resourceActions.setContentTypes([{_id:'all',name:'All'}]));
         dispatch(setResourceElements('createResourceContentTypes',[]))
         console.log("error",err)
      }
   }
 }

 export function getAllSMeUsersList(){
   return async (dispatch, getState)=> {
      try {
         var userDetails=JSON.parse(sessionStorage.getItem("userData"))
         let response = await customHttp.get("/api/smeUser/belongsTo/"+userDetails.belongsTo);
         // let res = JSON.parse(JSON.stringify(response.data.message));
         if(Array.isArray(response.data.message)){
            dispatch(setResourceElements('smeUserList',response.data.message))
         } else {
            dispatch(setResourceElements('smeUserList',[]))
         }
      } catch (err) {
         dispatch(setResourceElements('smeUserList',[]))
         console.log("error",err)
      }
   }
 }

export function handleCheckboxChange(item){
   return (dispatch, getState)=> {
     // console.log("checkbox change",getState().resourceState.resourceArray)
      dispatch(resourceActions.setResourceArray(filterCheckbox(JSON.parse(JSON.stringify(getState().resourceState.resourceArray)),item)))
      dispatch(resourceActions.setResourceOriginalArray(JSON.parse(JSON.stringify(getState().resourceState.resourceArray)),item))
      
      let dummy=[];
      for(var m=0;m<getState().resourceState.resourceArray.length;m++){
       if(getState().resourceState.resourceArray[m].isChecked){
         dummy.push(getState().resourceState.resourceArray[m]);
       }
     }
     dispatch(setResourceElements('selected',dummy))
   
   }
 }


 export const  handleSelectAllClick=(event)=>{
   return (dispatch ,getState)=> {  
     if (event.target.checked) {
       getState().resourceState.resourceArray.forEach(element => {
         element.isChecked=true;
       });
      
       var selected_dummy=  getState().resourceState.resourceArray.map(n => n);
       dispatch(resourceActions.setResourceArray(selected_dummy))
       dispatch(resourceActions.setResourceOriginalArray(JSON.parse(JSON.stringify(getState().resourceState.resourceArray))))
       
       let dummy=[];
       for(var m=0;m<selected_dummy.length;m++){
         if(selected_dummy[m].isChecked){
           dummy.push(selected_dummy[m]);
         }
       }
       dispatch(setResourceElements('selected',dummy))
     
       return;
     }
   //   dispatch(AppstoreActions.selectAllApplications( [], getState().appstore.applicationArray, getState().appstore.applicationList))
     getState().resourceState.resourceArray.forEach(element => {
       element.isChecked=false;
     });
     var selected_dummy=  getState().resourceState.resourceArray.map(n => n);
     dispatch(resourceActions.setResourceArray(selected_dummy))
     dispatch(resourceActions.setResourceOriginalArray(JSON.parse(JSON.stringify(getState().resourceState.resourceArray))))
     
     let dummy=[];
     for(var m=0;m<selected_dummy.length;m++){
      if(selected_dummy[m].isChecked){
        dummy.push(selected_dummy[m]);
      }
    }
    dispatch(setResourceElements('selected',dummy))
  
   }
 }

 
export function handleFilterChange(e){
   return (dispatch, getState)=> {
      //setSearchValue('');
      // const archive = JSON.parse(e.target.value);
        // console.log("dropdown value",archive)
      const temp = JSON.parse(e.target.value);
      if(e.target.name==='selectedDiscipline'){
        // console.log("dropdown value",e.target.name)
         dispatch(setResourceElements('selectedDiscipline',temp));
         const cate=JSON.parse(JSON.stringify(getState().resourceState.selectedContentType));
         const archive=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveType))
         const searchValue=getState().resourceState.searchValue;
         if(Object.keys(searchValue).length===0){
            dispatch(getAllResources(temp,cate,archive));
         }else{
            dispatch(getAllResources(temp,cate,archive,searchValue));
         }
      } else if(e.target.name==='selectedContentType'){
         //console.log("dropdown value",e.target.name)
         dispatch(setResourceElements('selectedContentType',temp));
         const disc=JSON.parse(JSON.stringify(getState().resourceState.selectedDiscipline))
         const archive=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveType))
         const searchValue=getState().resourceState.searchValue;
         if(Object.keys(searchValue).length===0){
            dispatch(getAllResources(disc,temp,archive));
         }else{
            dispatch(getAllResources(disc,temp,archive,searchValue));
         }
      }else {
         const disc=JSON.parse(JSON.stringify(getState().resourceState.selectedDiscipline))
         const cate=JSON.parse(JSON.stringify(getState().resourceState.selectedContentType));
         const searchValue=getState().resourceState.searchValue;
         const archive = JSON.parse(e.target.value);
         dispatch(setResourceElements('selectedArchiveType',archive));
         if(Object.keys(searchValue).length===0){
            dispatch(getAllResources(disc,cate,archive));
         }else{
            dispatch(getAllResources(disc,cate,archive,searchValue));
         }
      }
   }
 }

export function toggleRequestResource(){
   return (dispatch, getState)=> {
      dispatch(RequestedContentContainer.getRequestedContent());
      dispatch(requestResourceState.setResourceElements('resourceObject',resourceObj))
      //console.log("showRequestResource",getState().resourceState.showRequestResource)
      dispatch(resourceActions.toggleRequestResource(!getState().resourceState.showRequestResource))
      dispatch(RequestedContentActions.setRequestedContentElements("selectedIndex","0"))

   }
 }
 export function handleSubmitSearch(e){
   return async (dispatch, getState)=> {
      e.preventDefault();
      const searchValue=getState().resourceState.searchValue;
      if(Object.keys(searchValue).length>0){
         const disc=JSON.parse(JSON.stringify(getState().resourceState.selectedDiscipline))
         const cate=JSON.parse(JSON.stringify(getState().resourceState.selectedContentType));
         const archive=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveType))
         dispatch(getAllResources(disc,cate,archive,searchValue));
      }
   }
}

export function handleSearch(array,search){
   return (dispatch, getState)=> {
      //console.log("dksjdksaj",array,search);
      dispatch(resourceActions.setResourceElements('searchValue',search));
      if(Object.keys(search).length===0){
         const disc=JSON.parse(JSON.stringify(getState().resourceState.selectedDiscipline))
         const cate=JSON.parse(JSON.stringify(getState().resourceState.selectedContentType));
         const archive=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveType))
         dispatch(getAllResources(disc,cate,archive));
      }

      // var arr=JSON.parse(JSON.stringify(array));
      // var arr1 = arr.filter(el =>{
      // return el.displayName.toLowerCase().indexOf(search.toString().toLowerCase()) !== -1;
      // })
      // dispatch(resourceActions.setResourceArray(arr1));
      // //console.log("search value",e.target.value)
      // if(!search){
      //   dispatch(resourceActions.setResourceArray(arr))
      //   //setResourceArray(filteredArray)
      // }
   }
 }

export function createResource(e) {
   return async (dispatch ,getState)=> {
      e.preventDefault();
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      var timeInvestment=getState().resourceState.timeInvestment;   // calculating time in seconds
      var hours=timeInvestment.hours || 0;
      var min=timeInvestment.minutes || 0;
      var sec=timeInvestment.seconds || 0;
      var time=parseInt(hours*60*60)+parseInt(min*60)+parseInt(sec);
      var data = new FormData();
      var tags=(getState().resourceState.resource.tags).split(",");
     // console.log("tags",tags)
      data.append('displayName', getState().resourceState.resource.name);     
      data.append('timeInvestment', time);
      data.append('publishedVersion', getState().resourceState.resource.version);
      data.append('branches', [JSON.parse(getState().resourceState.resource.discipline)._id]);
      data.append('categories', [JSON.parse(getState().resourceState.resource.contentType)._id]);
      data.append('file', getState().resourceState.resource.file);
      // data.userId=userDetails.userId;
      data.append('userEmail',userDetails.email);
      data.append('user_id',userDetails._id);
      for(var i=0;i<tags.length;i++){
         data.append('tags[]',tags[i]);
      }
      for (var value of data.values()) {
         console.log("formdata",value); 
      }
      dispatch(setResourceElements('showResourceCreateLoader',true))
      // console.log("data",[JSON.parse(getState().resourceState.resource.discipline)._id])
      try {
         let response = await customHttp.post("/api/resourceUri/upload/",data);
         // console.log("response",response.data.message)
         // let res = response.data.message;
         const all = {_id:'all'}
         const selectedArchiveType={name:"Unarchived",_id:false}
         dispatch(getAllResources(all,all,selectedArchiveType,undefined,"onload"));
         dispatch(setResourceElements('successAlertMessage','Content created successfully'));
         snackbar.successSnackbar();
         dispatch(setResourceElements('showResourceCreateLoader',false))
         dispatch(setResourceElements('resource',{name:'',discipline:'',contentType:'',file:{},tags:""}))
         document.getElementById('resourceFileUpload').value = ''
         document.getElementById('resourcePopup').style.display='none';
         dispatch(setResourceElements("timeInvestment",{hours:"",minutes:"",seconds:""}));
        // dispatch(getAllResources(getState().resourceState.selectedDiscipline, getState().resourceState.selectedContentType))
      } catch (err) {
         console.log("error",err)
         dispatch(setResourceElements('errorAlertMessage','Error! Try again..'));
         snackbar.errorSnackbar();
         dispatch(setResourceElements('showResourceCreateLoader',false))
         console.log("error",err)
      }
   }
}

export function submitResources(e,props,type) {
   return (dispatch ,getState)=> {
      // console.log("resourceOriginalArray",props.resourceOriginalArray);
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      if(type==='skip'){
         localStorage.setItem("selectedResources",JSON.stringify([]));
         if(userDetails.type===1){
            props.history.push("/appstore")
           }else if(userDetails.type===2){
            props.history.push("/dlt/appstore")
           }else{
           props.history.push("/sub-admin/appstore")
         }
      } else {
         var dummyResources= JSON.parse(JSON.stringify(props.resourceState.resourceOriginalArray))
      var selectedArr = dummyResources.filter(obj=>{
         if(obj.isChecked){
            return obj
         }
      })
      var selectedArray = dummyResources.filter(obj=>{
         if(obj.isChecked){
            return obj
         }
      })
      //console.log("selectedArr",selectedArr);
      // var storedResources = JSON.parse(localStorage.getItem("selectedResources"));
      // if(storedResources){
      //    selectedArr = [...selectedArr,...storedResources];
      //    //console.log("cancate",storedResources,selectedArr)
      //    localStorage.setItem("selectedResources",JSON.stringify(uniqueArray(selectedArr)));
      // } else {
      //    console.log("resourceOriginalArray",props.resourceOriginalArray);
      //    var selectedArr = props.resourceState.resourceOriginalArray.filter(obj=>{
      //       if(obj.isChecked){
      //          return obj
      //       }
      //    })
      //    var selectedArray = props.resourceState.resourceOriginalArray.filter(obj=>{
      //       if(obj.isChecked){
      //          return obj
      //       }
      //    })

         var storedResources = JSON.parse(localStorage.getItem("selectedResources"));
         if(storedResources){
            selectedArr = [...selectedArr,...storedResources];
            //console.log("cancate",storedResources,selectedArr)
            localStorage.setItem("selectedResources",JSON.stringify(uniqueArray(selectedArr)));
         } else {
            localStorage.setItem("selectedResources",JSON.stringify(selectedArr));
         }
         if(selectedArray.length>0){
            if(type==="createPlaylist"){
               if(userDetails.type===1){
                  props.history.push("/create-playlist")
               } else if(userDetails.type===2){
                  props.history.push("/dlt/create-playlist")
               } else{
                  props.history.push("/sub-admin/create-playlist")
               }
            }
            else if(type==="editPlaylist"){
               props.getResources(selectedArray);
            }  else{
                  if(userDetails.type===1){
                  props.history.push("/appstore")
                  }else if(userDetails.type===2){
                  props.history.push("/dlt/appstore")
                  }else{
                  props.history.push("/sub-admin/appstore")
               }
            }
            var dummyResourceOriginalArray = props.resourceState.resourceOriginalArray.map(obj=> ({ ...obj, isChecked: false }));
            dispatch(resourceActions.setResourceOriginalArray(dummyResourceOriginalArray))
         } else {
            if(type==="createPlaylist" || type==="submit"){
               dispatch(setResourceElements('errorAlertMessage','Please select a resource'));
               snackbar.errorSnackbar();
            }else{
               props.getResources([]);
            }

         }
      // console.log("final stored resources",JSON.parse(localStorage.getItem("selectedResources")))
      }
   }
// }
}
export function closeVideoPopup(e,props) {
   return async (dispatch ,getState)=> {

      var x = document.getElementById("playVideo");
      x.pause()
      dispatch(resourceActions.setResourceElements('resourceVideo',{}));
      document.getElementById('playResourceVideoPopup').style.display='none'  


      const disc=JSON.parse(JSON.stringify(getState().resourceState.selectedDiscipline))
      const cate=JSON.parse(JSON.stringify(getState().resourceState.selectedContentType));
      const searchValue=getState().resourceState.searchValue;
      const archive=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveType))    
      dispatch(setResourceElements('selectedArchiveType',archive));
      if(Object.keys(searchValue).length===0){
         dispatch(getAllResources(disc,cate,archive));
      }else{
         dispatch(getAllResources(disc,cate,archive,searchValue));
      } 

      
   }
}


export function openResourceLink(item,window,history,videoCurrentTime) {
   return async (dispatch ,getState)=> {
      // console.log("item  details",item,window,history,videoCurrentTime)
      if(!getState().resourceState.borrowedResources){
         if(item.type===2){
            dispatch(setResourceElements('resourceVideo',item))
            if(item.videoData.length>0){
               dispatch(setResourceElements('videoCurrentTime',item.videoData[0].currentTime))
            }else{
               dispatch(setResourceElements('videoCurrentTime',0))
            }
            
            document.getElementById('playResourceVideoPopup').style.display='block';
         } else if(item.type===0 && item.file && item.file.length>0) {
            const itemLink = window.location.origin+'/'+item.file[0].path || '/';
            var splitItem = itemLink.split(".") || [];
            if(splitItem && splitItem.length>0 && splitItem[splitItem.length-1]==='pdf'){
               document.getElementById('pdfPopup').style.display='block';
               document.getElementById('pdfPopupTitle').innerText = item.displayName;
               dispatch(togglePdf(true,itemLink))
            } else {
               window.open(itemLink,'_blank');
            }
         } else if(item.type===4){
            localStorage.setItem('playlistResource',JSON.stringify(item))
            var userDetails=JSON.parse(sessionStorage.getItem("userData"))
            if(userDetails.type===0){
               history.push('/user/view-playlist');
            } else if(userDetails.type===1){
               history.push('/view-playlist');
            } else if(userDetails.type===2){
               history.push('/dlt/view-playlist');
            } else{
               history.push('/sub-admin/view-playlist');
            }
         }
         else if(item.type===5){
            // dispatch(setResourceElements('resourceVideo',item))
            // dispatch(setResourceElements('videoCurrentTime',0))
            var frame = document.getElementById("playHtml");
            frame.parentNode.appendChild(frame);
            document.getElementById('playResourceHtmlPopup').style.display='block';
         }

         else if(item.type===6){
            dispatch(setResourceElements('htmlFileDetails',item))
            var frame = document.getElementById("playHtml6");
            frame.parentNode.appendChild(frame);
            document.getElementById('playResourceHtmlPopupType6').style.display='block';
         }

      }

   }
}

export function closeHtmlPopup(e,props) {
   return async (dispatch ,getState)=> {

      // var x = document.getElementById("playHtml");
      // x.pause()
      // dispatch(resourceActions.setResourceElements('resourceVideo',{}));

      var frame = document.getElementById("playHtml");
      if ( frame !== null ) {
         var iframeSrc = frame.src;
         frame.src = iframeSrc;
     }
      // frame.parentNode.removeChild(frame);

      document.getElementById('playResourceHtmlPopup').style.display='none'  


       var frame = document.getElementById("playHtml6");
      if ( frame !== null ) {
         var iframeSrc = frame.src;
         frame.src = iframeSrc;
     }
      // frame.parentNode.removeChild(frame);

      document.getElementById('playResourceHtmlPopupType6').style.display='none' 


      const disc=JSON.parse(JSON.stringify(getState().resourceState.selectedDiscipline))
      const cate=JSON.parse(JSON.stringify(getState().resourceState.selectedContentType));
      const searchValue=getState().resourceState.searchValue;
      const archive=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveType))    
      dispatch(setResourceElements('selectedArchiveType',archive));
      if(Object.keys(searchValue).length===0){
         dispatch(getAllResources(disc,cate,archive));
      }else{
         dispatch(getAllResources(disc,cate,archive,searchValue));
      } 

      
   }
}

export function displayAlert(type,msg) {
   return async (dispatch ,getState)=> {
      if(type==='success'){
         dispatch(setResourceElements('successAlertMessage',msg));
      snackbar.successSnackbar();
       } else if(type==='error'){
         dispatch(setResourceElements('errorAlertMessage',msg));
         snackbar.errorSnackbar();
      }
   }
}

export function setResourceElements(name,content) {
   return (dispatch ,getState)=> {
      dispatch(resourceActions.setResourceElements(name,content))
   }
}

export function showUserRatings(resource) {
   return async (dispatch ,getState)=> {
      const id = resource._id
      try {
         let response = await customHttp.get(`/api/ratings/getRatings/${id}`);
         let res = response.data.message;
         dispatch(setResourceElements('userRatings',res));
         document.getElementById('userRatingsPopup').style.display='block';
         //console.log("response",res)
      } catch (err) {
         console.log("error",err)
      }
   }
}

export function showUserReviews(resource) {
   return async (dispatch ,getState)=> {
      const id = resource._id
      try {
         let response = await customHttp.get(`/api/ratings/getAllRatingsByResourceId/${id}`);
         let res = response.data.message;
         dispatch(setResourceElements('userReviews',res));
         //console.log("response",res)
      } catch (err) {
         console.log("error",err)
      }
   }
}

var filterCheckbox=(arr,item)=>{
   var sortedArray = arr;
   return sortedArray.map(obj=>{
      if(item._id===obj._id){
      obj.isChecked = !obj.isChecked
      }
      return obj
   })
}
// function to filter unique array
function uniqueArray(array) {
   var processed = [];
   for (var i=array.length-1; i>=0; i--){
     if (processed.indexOf(array[i]._id)<0) {
       processed.push(array[i]._id);
     } else {
       array.splice(i, 1);
     }
   }
   return array;
}

export  function archiveResources() {
   return async (dispatch ,getState)=> {
      dispatch(setResourceElements('archiveSubmitLoader',false))
      // var localStorageResources = JSON.parse(localStorage.getItem("selectedResources"));
     var dummyResource=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveResource))
     var originalResource=JSON.parse(JSON.stringify(getState().resourceState.resourceOriginalArray))
     var obj={};
     obj._id=dummyResource._id
     obj.status=true;
     if(dummyResource !==undefined){
      try {
         let response = await customHttp.put("/api/resourceuri/updateArchieveStatus",obj);
         dispatch(setResourceElements('successAlertMessage','Resource archived successfully'));
         snackbar.successSnackbar();
         // for(var i=0;i<localStorageResources.length;i++){
         //   if(localStorageResources[i]._id===dummyResource._id) {
         //    localStorageResources.splice(i,1)
         //   }
         // }
         for(var j=0;j<originalResource.length;j++){
            if(originalResource[j]._id===dummyResource._id) {
               originalResource[j].isChecked=false;
            }
          }
         // localStorage.setItem("selectedResources",JSON.stringify(localStorageResources));
         dispatch(resourceActions.setResourceOriginalArray(originalResource))
         document.getElementById('archivePopup').style.display='none';
         dispatch(setResourceElements('archiveSubmitLoader',true))
         const disc=JSON.parse(JSON.stringify(getState().resourceState.selectedDiscipline))
         const archive=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveType))
         const searchValue=getState().resourceState.searchValue;
         const cate=JSON.parse(JSON.stringify(getState().resourceState.selectedContentType));

         if(Object.keys(searchValue).length===0){
            dispatch(getAllResources(disc,cate,archive));
         }else{
            dispatch(getAllResources(disc,cate,archive,searchValue));
         }
      } catch (err) {
         dispatch(setResourceElements('errorAlertMessage','Error! Try again..'));
         snackbar.errorSnackbar();
         dispatch(setResourceElements('showResourceCreateLoader',false))
         document.getElementById('archivePopup').style.display='none';
         dispatch(setResourceElements('archiveSubmitLoader',true))
         const disc=JSON.parse(JSON.stringify(getState().resourceState.selectedDiscipline))
         const archive=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveType))
         const cate=JSON.parse(JSON.stringify(getState().resourceState.selectedContentType));
         const searchValue=getState().resourceState.searchValue;

         if(Object.keys(searchValue).length===0){
            dispatch(getAllResources(disc,cate,archive));
         }else{
            dispatch(getAllResources(disc,cate,archive,searchValue));
         }
         console.log("error",err)
      }
     }
   }
}

export  function unarchiveResources() {
   return async (dispatch ,getState)=> {
      dispatch(setResourceElements('unarchiveSubmitLoader',false))
     var dummyResource=JSON.parse(JSON.stringify(getState().resourceState.selectedUnarchiveResource))
     var obj={};
     obj._id=dummyResource._id
     obj.status=false;
     if(dummyResource !==undefined){
      try {
         let response = await customHttp.put("/api/resourceuri/updateArchieveStatus",obj);
         dispatch(setResourceElements('successAlertMessage','Resource unarchived successfully'));
         snackbar.successSnackbar();
         document.getElementById('unarchivePopup').style.display='none';
         dispatch(setResourceElements('unarchiveSubmitLoader',true))
         const disc=JSON.parse(JSON.stringify(getState().resourceState.selectedDiscipline))
         const archive=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveType))
         const searchValue=getState().resourceState.searchValue;
         const cate=JSON.parse(JSON.stringify(getState().resourceState.selectedContentType));

         if(Object.keys(searchValue).length===0){
            dispatch(getAllResources(disc,cate,archive));
         }else{
            dispatch(getAllResources(disc,cate,archive,searchValue));
         }
      } catch (err) {
         dispatch(setResourceElements('errorAlertMessage','Error! Try again..'));
         snackbar.errorSnackbar();
         dispatch(setResourceElements('showResourceCreateLoader',false))        
         document.getElementById('unarchivePopup').style.display='none';
         dispatch(setResourceElements('unarchiveSubmitLoader',true))
         const disc=JSON.parse(JSON.stringify(getState().resourceState.selectedDiscipline))
         const archive=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveType))
         const cate=JSON.parse(JSON.stringify(getState().resourceState.selectedContentType));
         const searchValue=getState().resourceState.searchValue;

         if(Object.keys(searchValue).length===0){
            dispatch(getAllResources(disc,cate,archive));
         }else{
            dispatch(getAllResources(disc,cate,archive,searchValue));
         }
         console.log("error",err)
      }
     }
   }
}

export function shareResources(e){
   return async (dispatch, getState)=> {
      e.preventDefault();
      try {       
         var userDetails=JSON.parse(sessionStorage.getItem("userData"))
         const selectedResource=JSON.parse(JSON.stringify(getState().resourceState.sharingResourceObject))
         const selectedUser=JSON.parse(JSON.stringify(getState().resourceState.selectedSmeUser))
         
         dispatch(setResourceElements("sharedResourceLoader",true)) 
         var obj={};
         obj.sharedBy=userDetails.email;
         obj.sharedTo=selectedUser.email;
         obj.adminId=userDetails._id;
         obj.belongsTo=userDetails.belongsTo;
         obj.resourceId=selectedResource._id;
          obj.sharedURL=window.location.origin+"/shared/resource/"+selectedResource._id;
         // obj.sharedURL="http://localhost:3000/shared-resource/login/"+selectedResource._id;
         let response = await customHttp.post("/api/sharedResource/",obj);
         // dispatch(setResourceElements('successAlertMessage','Shared successfully'));
         // snackbar.successSnackbar();
         // document.getElementById('shareResourcePopup').style.display='none';
         dispatch(setResourceElements("selectedSmeUser",{}))
         dispatch(setResourceElements("sharedResourceLoader",false)) 
         dispatch(setResourceElements("showSharedResourceLink",true))  
      } catch (err) {
         dispatch(setResourceElements('errorAlertMessage','Error! Try again..'));
         snackbar.errorSnackbar();
         dispatch(setResourceElements("sharedResourceLoader",false))  
         dispatch(setResourceElements("showSharedResourceLink",false))  
         console.log("error",err)
      }
   }
 }

 export function  addNewFileForResource(e) {
   return async (dispatch ,getState)=> {
      e.preventDefault();
      const formData = new FormData();
      const configuration = { headers: { "Content-Type": "multipart/form-data" } };
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      var timeInvestment=getState().resourceState.timeInvestment;
      var hours=timeInvestment.hours || 0;
      var min=timeInvestment.minutes || 0;
      var sec=timeInvestment.seconds || 0;
      var time=hours*60*60+min*60+sec;
      var time=parseInt(hours*60*60)+parseInt(min*60)+parseInt(sec);
      
      formData.append('userEmail',userDetails.email);
      formData.append('user_id',userDetails._id);
      formData.append('timeInvestment',time);
      formData.append('_id',JSON.parse(JSON.stringify(getState().resourceState.selectedResourceForVersionChange))._id);
      formData.append('publishedVersion',JSON.parse(JSON.stringify(getState().resourceState.versionControlResourceObj)).version);
      formData.append('published',JSON.parse(JSON.stringify(getState().resourceState.versionControlResourceObj)).isChecked);
      formData.append('file',getState().resourceState.versionControlResourceObj.file);
      dispatch(setResourceElements("addNewVersionFileLoader",true)) 
      try {
         let response = await customHttp.post("/api/resourceuri/update/resource",formData,configuration);
         dispatch(setResourceElements('successAlertMessage','file added successfully'));
         snackbar.successSnackbar();
         document.getElementById('versionControllPopup').style.display='none';
         dispatch(setResourceElements("versionControlResourceObj",{version:"",file:{},isChecked:false}))
         dispatch(setResourceElements("timeInvestment",{hours:"",minutes:"",seconds:""})) 
         dispatch(setResourceElements("addNewVersionFileLoader",false)) 
         dispatch(setResourceElements("allowFileUpload",false));

         const disc=JSON.parse(JSON.stringify(getState().resourceState.selectedDiscipline))
         const archive=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveType))
         const searchValue=getState().resourceState.searchValue;
         const cate=JSON.parse(JSON.stringify(getState().resourceState.selectedContentType));

         if(Object.keys(searchValue).length===0){
            dispatch(getAllResources(disc,cate,archive));
         }else{
            dispatch(getAllResources(disc,cate,archive,searchValue));
         }
         
      } catch (err) {
         dispatch(setResourceElements('errorAlertMessage','Error! Try again..'));
         snackbar.errorSnackbar();
         dispatch(setResourceElements("addNewVersionFileLoader",false))  
         console.log("error",err)
      }
   }
}

export function  changeNewVersion(e) {
   return async (dispatch ,getState)=> {
      e.preventDefault();
      var obj={};
      obj._id=getState().resourceState.selectedResourceForVersionChange._id;
      obj.publishedVersion=getState().resourceState.selectedResourceForVersionChange.publishedVersion;
      dispatch(setResourceElements("changeNewVersionLoader",true)) 
      try {
         let response = await customHttp.put("/api/resourceuri/update/version",obj);
         dispatch(setResourceElements('successAlertMessage','Version changed successfully'));
         snackbar.successSnackbar();
         document.getElementById('versionControllPopup').style.display='none';
         dispatch(setResourceElements("changeNewVersionLoader",false));
         dispatch(setResourceElements("allowFileUpload",false));
        
         
         const disc=JSON.parse(JSON.stringify(getState().resourceState.selectedDiscipline))
         const archive=JSON.parse(JSON.stringify(getState().resourceState.selectedArchiveType))
         const searchValue=getState().resourceState.searchValue;
         const cate=JSON.parse(JSON.stringify(getState().resourceState.selectedContentType));

         if(Object.keys(searchValue).length===0){
            dispatch(getAllResources(disc,cate,archive));
         }else{
            dispatch(getAllResources(disc,cate,archive,searchValue));
         }

      } catch (err) {
         dispatch(setResourceElements('errorAlertMessage','Error! Try again..'));
         snackbar.errorSnackbar();
         dispatch(setResourceElements("changeNewVersionLoader",false))  
         console.log("error",err)
      }
   }
}

//added for pdf popup
//start of pdf code
/**
 * Function to get hide/show pdf.
 * @author Sachin
 * @param {Boolean} value - value to set i,e true or false.
 * @return {Void}
 */
export function togglePdf(value,link){
   return async (dispatch, getState)=> {   
     dispatch(setResourceElements('showPdf',value));
     dispatch(setResourceElements('pdfLoader',true));
     if(value){
        try{
            //var link = `${getState().proctoringState.pdfUrlPath+getState().proctoringState.selectedSlot._id}.pdf`;
            var res = await customHttp.post('/api/pdf/get',{url:link});
            window.pdfjsLib.getDocument(convertDataURIToBinary("data:application/pdf;base64,"+res.data)).then((pdf) => {
               var myState = {...getState().resourceState.pdfState,pdf,currentPage:1}
               dispatch(setResourceElements('pdfState',myState));
               dispatch(setResourceElements('pdfLoader',false));
               dispatch(renderPdf())
            })
            .catch(err=>{
               console.log("error",err);
               dispatch(setResourceElements('pdfLoader',false));
               dispatch(setResourceElements('showPdf',false));
               //dispatch(displayAlert('error','Unable to Load PDF'));
               document.getElementById('errorSnackbar').innerText = 'Unable to Load PDF';
               snackbar.errorSnackbar();
            })
        } catch(err){
            console.log("error",err);
            dispatch(setResourceElements('pdfLoader',false));
            dispatch(setResourceElements('showPdf',false));
            dispatch(displayAlert('error','Unable to Load PDF'));
            //dispatch(setResourceElements('errorAlertMessage',msg));
            document.getElementById('errorSnackbar').innerText = 'Unable to Load PDF';
            snackbar.errorSnackbar();
        }
     }
   }
}
var BASE64_MARKER = ';base64,';
function convertDataURIToBinary(dataURI) {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for(var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
export function renderPdf() {
   return (dispatch, getState)=> {
      var myState = getState().resourceState.pdfState;
      myState.pdf.getPage(myState.currentPage).then((page) => {
         var canvas = document.getElementById("pdf_renderer");
         var ctx = canvas.getContext('2d');
         var viewport = page.getViewport(myState.zoom);
         canvas.width = viewport.width;
         canvas.height = viewport.height;
         page.render({
            canvasContext: ctx,
            viewport: viewport
         });
      });
   };
}
export function goPreviousPage() {
   return (dispatch, getState)=> {
      var myState = getState().resourceState.pdfState;
      if(myState.pdf !== null && myState.currentPage > 1) {
         var pdfState = {...getState().resourceState.pdfState,currentPage:myState.currentPage-1}
         dispatch(setResourceElements('pdfState',pdfState));
         document.getElementById("current_page").value = pdfState.currentPage;
         dispatch(renderPdf());
      }
   };
}
export function goNextPage() {
   return (dispatch, getState)=> {
      var myState = getState().resourceState.pdfState;
      if(myState.pdf !== null && myState.currentPage < myState.pdf._pdfInfo.numPages){
         var pdfState = {...getState().resourceState.pdfState,currentPage:myState.currentPage+1}
         dispatch(setResourceElements('pdfState',pdfState));
         document.getElementById("current_page").value = pdfState.currentPage;
         dispatch(renderPdf());
      } 
   };
}
export function currentPage(e) {
   return (dispatch, getState)=> {
      var myState = getState().resourceState.pdfState;
      if(myState.pdf !== null){
         // Get key code
         var code = (e.keyCode ? e.keyCode : e.which);
         // If key code matches that of the Enter key
         if(code == 13) {
            var desiredPage = document.getElementById('current_page').valueAsNumber;
            if(desiredPage <= myState.pdf._pdfInfo.numPages){
               desiredPage = desiredPage >= 1? desiredPage : 1;
            } else {
               desiredPage = myState.pdf._pdfInfo.numPages;
            }
            if(desiredPage >= 1 && desiredPage <= myState.pdf._pdfInfo.numPages) {
               var pdfState = {...getState().resourceState.pdfState,currentPage:desiredPage}
               dispatch(setResourceElements('pdfState',pdfState));
               document.getElementById("current_page").value = desiredPage;
               dispatch(renderPdf());
            }
         }
      }
   };
}
export function zoomInPage() {
   return (dispatch, getState)=> {
      var myState = getState().resourceState.pdfState;
      if(myState.pdf !== null && myState.zoom<5.0) {
         console.log("zoom",myState.zoom)
         var pdfState = {...getState().resourceState.pdfState,zoom:myState.zoom+0.5}
         dispatch(setResourceElements('pdfState',pdfState));
         dispatch(renderPdf());
      }
   };
}
export function zoomOutPage() {
   return (dispatch, getState)=> {
      var myState = getState().resourceState.pdfState;
      if(myState.pdf !== null && myState.zoom>0.5) {
         console.log("zoom",myState.zoom)
         var pdfState = {...getState().resourceState.pdfState,zoom:myState.zoom-0.5}
         dispatch(setResourceElements('pdfState',pdfState));
         dispatch(renderPdf());
      }
   };
}
//end of pdf code