import React, {useEffect } from 'react';
import { connect } from "react-redux";
import * as CourseContainer from "../../container/actions/Courses/action";
// import Store from "../../container/reducers/Store";
import * as Actions from "../../container/actions/Courses";
import "./Course.scss";
import DatePicker from '../DatePicker.js';
import { bindActionCreators } from 'redux';

function Course (props) {
  //const [name, SetName] = useState("");
  // const slotHoursDropdown = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
  // const [selectSlotHours , setselectSlotHours] = useState(slotHoursDropdown);

  var userDetails1=JSON.parse(sessionStorage.getItem("userData"))
  const all = {_id:'all'}
  const selectedArchiveType={name:"Unarchived",_id:false}
  // const [searchValue, setSearchValue] = useState('');

  var getSelectedToDate=(toDate)=>{
    // console.log("toDate",toDate)
    props.handleChangeCourseCreationInputFields("toDate",toDate)
  }
  var getSelectedFromDate=(fromDate)=>{
    // console.log("fromDate",fromDate)
    props.handleChangeCourseCreationInputFields("fromDate",fromDate)
  }
  useEffect( () => {
    //props.getAllApplications();
    props.getConfidentialDetails()
    props.setBooleanValue("showSelectedApplications",true)
    props.initiateOnloadFunction()
    props.setBooleanValue("showSelectedResources",true)
    props.getAllDisciplines();
    props.getAllContentTypes();
    props.setCourseElements('selected',[]);
    props.setCourseElements('appsSelected',[]);
    const selectedArchiveType={name:"Unarchived",_id:false}
    props.getAllCourseResources(all,all,selectedArchiveType,undefined,"onload")

  },[]);
  // onload function end

 var goBackCoursePage=()=>{
  props.setBooleanValue("showSelectedApplications",true)
  props.setBooleanValue("courseName","");
  props.setBooleanValue("displayName","");
  props.setBooleanValue("fromDate","");
  props.setBooleanValue("toDate","");
  props.setBooleanValue("courseCode","");
  props.setBooleanValue("numberOfUsers","");
  
  // props.setBooleanValue("slotHours","");


  var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    if(userDetails.type===1){
      props.history.push("/courses")
    }else if(userDetails.type===2){
      props.history.push("/dlt/courses")
    }else{
      props.history.push("/sub-admin/courses")
    }

 }

 var handleSearchChange = (e) => {
  let alphnumericRegex = /^[a-zA-Z0-9() _.,&?!#@]*$/;
    let alphnumericRegexValidation = alphnumericRegex.test(String(e.target.value))
    if(alphnumericRegexValidation){
      // setSearchValue(e.target.value);
      //searchFilter(filteredArray,e.target.value);
      props.handleSearchCreateCourse(props.courseState.filteredArray,e.target.value)

    }
};

var handleChangeNumberOfUser=(e)=>{
  let a = JSON.parse(JSON.stringify(e.target.value));
  let numericRegex = /^[0-9]*$/;
  let numericRegexValidation = numericRegex.test(String(a))
  if(numericRegexValidation){
    props.handleChangeCourseCreationInputFields(e.target.name,e.target.value)
    // props.setCourseElements("noofusers",{...props.courseState.noofusers, [e.target.name]:e.target.value})
  }
}

var handleChangeSlotHours =(event) =>{
  props.handleChangeCourseCreationInputFields(event.target.name,event.target.value)
}

var handleChangeCreation = (e) =>{
  if(e.target.name === 'courseName'){
    if (/^[0-9A-Za-z- ]*$/.test(e.target.value)){
      props.setBooleanValue("courseNameValidation",false)
    }else{
      props.setBooleanValue("courseNameValidation",true)
    }
  }else if(e.target.name === 'displayName'){
    if (/^[0-9A-Za-z- ]*$/.test(e.target.value)){
      props.setBooleanValue("displayNameValidation",false)
    }else{
      props.setBooleanValue("displayNameValidation",true)
    }
  }else if(e.target.name === 'courseCode'){
    if (/^[0-9A-Za-z]*$/.test(e.target.value)){
      props.setBooleanValue("courseCodeValidation",false)
    }else{
      props.setBooleanValue("courseCodeValidation",true)
    }
  }
  props.handleChangeCourseCreationInputFields(e.target.name,e.target.value)
}

    return (
      <div className="add-on-load-animation">
        <div id="successSnackbar">{props.courseState.successSnackbarMessage}</div>
        <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.courseState.errorSnackbarMessage}</div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
              <div className="col-md-12 col-sm-12 nj-card" >
                <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                  <div className="nj-card__header">
                    <div className="nj-card__title">
                      <h4 className="font_family_montserrat">Create Course</h4>
                    </div>
                    <div style={{paddingBottom:'9px'}}>
                      <button className="nj-flow-button pull-right" type="submit" onClick={goBackCoursePage}>Back</button>
                    </div>
                  </div>
                </div>
                <div className="row" style={{paddingTop:"10px"}}>
                  <div className="col-md-8 col-sm-12 col-xs-12" style={{marginBottom:"10px"}}>
                    {/* displaying resources block */}
                    <div className="col-md-12">
                    {props.courseState.showSelectedResources ?
                      <div>
                      <div className="row" style={{padding:"0px 0px 10px 10px"}}>
                        <div className="col-md-7 col-sm-7 col-xs-6">
                          <h4 className="font_family_montserrat">Selected Resources</h4>
                        </div>
                        <div className="col-md-5 col-sm-5 col-xs-6" style={{textAlign:"right"}}>
                          <button  type="button" className="nj-flow-button" style={{color: "white"}} disabled={props.courseState.selectedResources<=0} onClick={(e)=>{props.setBooleanValue("showSelectedResources",false);props.getAllResources(props.courseState.selectedResources);props.getAllCreateCourseResources(all,all,selectedArchiveType,undefined,"onload")}}><span className="glyphicon glyphicon-plus"></span> Add</button>
                        </div>
                      </div>
                      {props.courseState.selectedResources.length>0 ?
                      <div className="tbl-content" style={{maxHeight:'40vh',overflowY:'scroll'}}>
                        <div className="resources-wrapper">
                          {props.courseState.selectedResources.map((item, i) => {
                            return (
                              <div className="resource_card add-rebound-animation" key={item._id}>
                                <div className="resource_card-content">
                                  <div className="resource-card-float">
                                    <div className="overlay_checkbox resource-card-float-element">
                                      <label className="checkbox coloured">
                                        <span style={{outline:"none",color:"rgb(238, 94, 94)"}} onClick={(e) => props.deleteSelectedResource(item)}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline>
                                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                            <line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
                                          </svg>
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                  {item.type===2 && <div className="resource_card-content-img-wrapper">
                                  <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                                  </div>}
                                  {item.type===0 && <div className="resource_card-content-img-wrapper">
                                  <img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource" />
                                  </div>}
                                  {item.type===4 && <div className="resource_card-content-img-wrapper">
                                  <img className="resource_card-content-img" src={"/img/resource-icons/file.svg"} alt="resource" />
                                </div>}
                                {item.type===6 && <div className="resource_card-content-img-wrapper">
                                  <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                                  </div>}
                                </div>
                                <div className="resource_card-footer" style={{cursor: "auto"}}>
                                  <p className="font_family_montserrat">{item.displayName}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      :
                      <div>{userDetails1.type===1?
                         <div className="col-md-12 font_family_montserrat idams_noDataFoundLink">Click <a href={"/resources"}> &nbsp;here&nbsp; </a> to add resources</div>
                        :null}
                        {userDetails1.type===2 ?
                        <div className="col-md-12 font_family_montserrat idams_noDataFoundLink">Click <a href={"/dlt/resources"}> &nbsp;here&nbsp; </a> to add resources</div>
                       :null }
                        {userDetails1.type===3 ?
                        <div className="col-md-12 font_family_montserrat idams_noDataFoundLink">Click <a href={"/sub-admin/resources"}> &nbsp;here&nbsp; </a> to add resources</div>
                       :null }
                      </div>
                      }
                      </div>
                      :
                      <div>
                      <div className="row" style={{padding:"0px 0px 10px 10px"}}>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <h4 className="font_family_montserrat">Select Resources</h4>
                        </div>
                        {props.courseState.allResources.length>0 ?<div className="col-md-5 col-sm-12 col-xs-12" style={{textAlign:"right"}}>
                          <button  type="button" className="nj-flow-button bg-color-red" style={{color: "white",marginRight:"5px"}} onClick={(e)=>{props.clearSelectResource()}}>Cancel</button>
                          <button  type="button" className="nj-flow-button" style={{color: "white"}} onClick={(e)=>{props.getSelectedResources()}}>Save</button>
                        </div>:
                        <div className="col-md-5 col-sm-12 col-xs-12" style={{textAlign:"right"}}>
                            <button  type="button" className="nj-flow-button " style={{color: "white",marginRight:"5px"}} onClick={(e)=>{props.clearSelectResource()}}>Back</button>
                            {/* <button  type="button" className="nj-flow-button" style={{color: "white"}} onClick={(e)=>{props.getSelectedResources()}}>Save</button> */}
                        </div>}
                      </div>
                      {props.courseState.showAllResourcesLoader?
                      <div>
                      {props.courseState.allResources.length>0 ?
                      <div >
                        <div className="row">
                        <div className="col-md-4">
                          {/* <div className="nj-popup-form-wrap"> */}
                            <p className="popup-content-label">Select Discipline</p>
                            <div className="nj-form-select">
                              {/* {console.log("course state",props.courseState)} */}
                              <select name="selectedDiscipline" style={{textTransform:'capitalize'}} value={JSON.stringify(props.courseState.selectedDiscipline)} onChange={props.handleFilterChangeCreateCourse} autoComplete="off" required>
                                {/* <option value="">Pick a discipline</option> */}
                                {props.courseState.disciplines.map((discipline,index)=>{
                                    return (<option key={index} value={JSON.stringify(discipline)}>{discipline.name}</option>)
                                })}
                              </select>
                            </div>
                          {/* </div> */}
                        </div>
                        <div className="col-md-4">
                          {/* <div className="nj-popup-form-wrap"> */}
                            <p className="popup-content-label">Select Content Type</p>
                            <div className="nj-form-select">
                              <select name="selectedContentType" style={{textTransform:'capitalize'}} value={JSON.stringify(props.courseState.selectedContentType)} onChange={props.handleFilterChangeCreateCourse} autoComplete="off" required>
                                {/* <option value="">Pick a content type</option> */}
                                {props.courseState.contentTypes.map((contentType,index)=>{
                                    return (<option key={index} value={JSON.stringify(contentType)}>{contentType.name}</option>)
                                })}
                              </select>
                            </div>
                          {/* </div> */}
                        </div>
                        <div className="col-md-4 pull-right">
                          {/* <div className="nj-popup-form-wrap"> */}
                            <form onSubmit={(e)=>{props.handleSubmitSearchCreateCourse(e)}}>
                              <p className="popup-content-label">Search</p>
                              <div className="input-group">
                                <input className="vs-input--input" type="text" name="searchValue" onChange={handleSearchChange} placeholder="Search by name/tag name" autoComplete="off"  style={{borderTopRightRadius:"0px",borderBottomRightRadius:"0px",padding:"5px"}} required/>
                                <span className="input-group-addon" title="Click here" type="submit" style={{cursor:"pointer"}} onClick={(e)=>{props.handleSubmitSearchCreateCourse(e)}}><i className="glyphicon glyphicon-search" /></span>
                            </div>
                            </form>
                          {/* </div> */}
                        </div>
                      </div>
                     
                      {props.courseState.filteredArray.length>0 &&
                      <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-md-4 selectall-margin">
                                  <label className="checkbox coloured font_family_montserrat font_wight_500">
                                    <input type="checkbox"  checked={props.courseState.selected.length === props.courseState.filteredArray.length}   onChange={(event)=>props.handleSelectAllClick(event)} />
                                      <span className="checkbox-material"><span className="check"></span></span> Select All
                                  </label>
                        </div>
                      </div>}
                      <div className="row" style={{marginTop:"1%"}}>
                      {props.courseState.filteredArray.length>0   ?
                        <div className="tbl-content" style={{maxHeight:'31vh',overflowY:'scroll'}}>
                          <div className="resources-wrapper">
                            {props.courseState.filteredArray.map((item, i) => {
                              return (
                              <div className="resource_card add-rebound-animation" key={item._id}>
                                <div className="resource_card-content">
                                  <div className="resource-card-float">
                                    <div className="overlay_checkbox resource-card-float-element">
                                      <label className="checkbox coloured">
                                        <input type="checkbox" checked={item.isSelected} onChange={(e) => props.selectResource(item)}/><span className="checkbox-material"><span className="check"></span></span>
                                      </label>
                                    </div>
                                  </div>
                                  {/* {item.type===2?<div className="resource_card-content-img-wrapper" onClick={(e) => props.selectResource(item)}>
                                    <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource" />
                                  </div>
                                  :<div className="resource_card-content-img-wrapper" onClick={(e) => props.selectResource(item)}>
                                    <img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource" />
                                  </div>
                                  } */}
                                  {item.type===2 && <div className="resource_card-content-img-wrapper">
                                  <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                                  </div>}
                                  {item.type===0 && <div className="resource_card-content-img-wrapper">
                                  <img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource" />
                                  </div>}
                                  {item.type===4 && <div className="resource_card-content-img-wrapper">
                                  <img className="resource_card-content-img" src={"/img/resource-icons/file.svg"} alt="resource" />
                                </div>}
                                {item.type===6 && <div className="resource_card-content-img-wrapper">
                                    <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                                  </div>}
                                </div>
                                <div className="resource_card-footer" onClick={(e) => props.selectResource(item)}>
                                  <p className="font_family_montserrat">{item.displayName}</p>
                                </div>
                              </div>
                              );
                            })}
                          </div>
                        </div>
                        :
                        <div className="col-md-12 font_family_montserrat idams_noDataFound">No data found</div> }
                        </div>
                        {/* <div className="col-md-12 col-sm-12 col-xs-12" style={{textAlign:"right",marginTop:"10px"}}>
                          <button  type="button" className="nj-flow-button bg-color-red" style={{color: "white",marginRight:"5px"}} onClick={(e)=>{props.clearSelectResource()}}>Cancel</button>
                          <button  type="button" className="nj-flow-button" style={{color: "white"}} onClick={(e)=>{props.getSelectedResources()}}>Save</button>
                        </div> */}
                      </div>
                      :
                      <div>
                        <div className="col-md-12 font_family_montserrat idams_noDataFound">No data found</div>
                      </div>
                      }
                      </div>
                      :
                      <div className="row" style={{maxHeight:"31vh"}}>
                        <div className="col-md-12 aligh-user-loader-image-center" >
                          <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                        </div>
                      </div>
                    }
                      </div>
                    }
                    </div>
                    {/* displaying resources block end */}

                    {/* displaying applications block */}
                    <div className="col-md-12">
                      {props.courseState.showSelectedApplications ?
                        <div>
                          <div className="row" style={{padding:"10px 0px 10px 10px"}}>
                            <div className="col-md-7 col-sm-7 col-xs-6">
                              <h4 className="font_family_montserrat">Selected Applications</h4>
                            </div>
                            <div className="col-md-5 col-sm-5 col-xs-6" style={{textAlign:"right"}}>
                              <button  type="button" className="nj-flow-button" disabled={props.courseState.selectedApplications.length<=0} style={{color: "white"}} onClick={()=>{props.getAllApplications();props.setBooleanValue("showSelectedApplications",false)}}><span className="glyphicon glyphicon-plus"></span> Add</button>
                            </div>
                          </div>
                          {props.courseState.selectedApplications.length>0 ?
                            <div className="tbl-content" style={{height:"42vh",overflowY:"scroll"}}>
                              <div className="table apps_alignment">
                                {props.courseState.selectedApplications.map((item, i) => {
                                  // const isSelected = this.isSelected(item);
                                  return (
                                    <div className="col-lg-3 col-sm-6 app_container padding_top_10 add-rebound-animation" key={i}>
                                      <div className="row apps_card margin_left_0 app-card-width" >
                                        <div className="apps_card-content">
                                          <span className="overlay_checkbox ">
                                            <label className="checkbox coloured" >
                                              <span style={{outline:"none",color:"rgb(238, 94, 94)"}} onClick={(e) => props.deleteSelectedApplication(item)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline>
                                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                  <line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
                                                </svg>
                                              </span>
                                            </label>
                                          </span>
                                          <div className="col-md-12 col-xs-12 col-sm-12 catalogimage">
                                            <img src={"/img/application-icons/"+item.name+".png"} height={50} width={50} alt="loader" />
                                          </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-xs-12 apps_card-footer">
                                          <p className="app_displayname font_family_montserrat">{item.displayName}</p>
                                        </div>
                                      </div><br/>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            // :
                            // <div className="col-md-12 font_family_montserrat idams_noDataFound">No data found</div>
                            //   }
                            :
                            <div className="col-md-12 font_family_montserrat idams_noDataFoundLink">Click <span onClick={()=>props.routeToAppStore(props.history)} style={{padding:'0 5px',color:'#6559ec',cursor:'pointer'}}> here </span> to add applications</div>
                            }
                          </div>
                       :
                      <div>
                        <div className="row" style={{padding:"10px 0px 10px 10px"}}>
                          <div className="col-md-7 col-sm-7 col-xs-12">
                            <h4 className="font_family_montserrat">Select Applications</h4>
                          </div>
                          <div className="col-md-5 col-sm-5 col-xs-12" style={{textAlign:"right"}}>
                            <button  type="button" className="nj-flow-button bg-color-red" style={{color: "white",marginRight:"5px"}} onClick={(e)=>{props.clearSelectApplication()}}>Cancel</button>
                            <button  type="button" className="nj-flow-button" style={{color: "white"}} onClick={(e)=>{props.getSelectedApplications()}}>Save</button>
                          </div>
                        </div>
                      
                        {props.courseState.allApplications.length>0 &&
                      <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-md-4 selectall-margin">
                                  <label className="checkbox coloured font_family_montserrat font_wight_500">
                                    <input type="checkbox"   checked={props.courseState.appsSelected.length === props.courseState.allApplications.length}    onChange={(event)=>props.handleSelectAllClickApps(event)} />
                                      <span className="checkbox-material"><span className="check"></span></span> Select All
                                  </label>
                        </div>
                      </div>}
                        {props.courseState.allApplications.length>0 ?
                          <div className="tbl-content" style={{maxHeight:"31vh",overflowY:"scroll"}}>
                            <div className="table apps_alignment">
                              {props.courseState.allApplications.map((item, i) => {
                                // const isSelected = this.isSelected(item);
                                return (
                                  <div className="col-lg-3 col-sm-6 app_container padding_top_10 add-rebound-animation" key={i}>
                                    <div className="row apps_card margin_left_0 app-card-width" >
                                      <div className="apps_card-content">
                                        <span className="overlay_checkbox ">
                                          <label className="checkbox coloured" >
                                            <input type="checkbox" checked={item.isSelected} onChange={(e) => props.selectApplication(item)}/><span className="checkbox-material"><span className="check"></span></span>
                                          </label>
                                        </span>
                                        <div className="col-md-12 col-xs-12 col-sm-12 catalogimage" onClick={(e) => props.selectApplication(item)}>
                                          <img src={"/img/application-icons/"+item.name+".png"} height={50} width={50} alt="loader" />
                                        </div>
                                      </div>
                                      <div className="col-md-12 col-sm-12 col-xs-12 apps_card-footer" onClick={(e)=> props.selectApplication(item)}>
                                        <p className="app_displayname font_family_montserrat">{item.displayName}</p>
                                      </div>
                                    </div><br/>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          :
                          <div className="col-md-12 font_family_montserrat idams_noDataFound">No data found</div>
                            }
                        </div>
                      }

                    </div>
                    {/* displaying applications block end*/}
                  </div>

                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <form onSubmit={(e)=>{props.createCourse(e,props)}}>
                      <div className="row" style={{marginTop:'0px'}}>
                        <div className="col-md-12 col-sm-12">
                          <label className="idams-label pull-left">Course Name<span className="required_field_color_red">*</span></label>
                          <input type="text"
                            className="pull-left vs-input--input form-control"
                            name="courseName"
                            value={props.courseState.courseName}
                            placeholder="EX:Automation Foundation"
                            autoComplete="off"
                            onChange={handleChangeCreation}
                            //onChange={(e)=>{props.handleChangeCourseCreationInputFields(e.target.name,e.target.value)}}
                            required />
                        </div>
                        {props.courseState.courseNameValidation && <p style={{color: 'red',paddingTop: 5,paddingLeft:'15px'}}>Course name should contain alphabets, numbers, spaces, hyphens</p>}
                      </div>
                      <div className="row" style={{marginTop:'15px'}}>
                        <div className="col-md-12 col-sm-12">
                          <label className="idams-label pull-left">Display Name<span className="required_field_color_red">*</span></label>
                          <input type="text"
                            className="pull-left vs-input--input form-control"
                            name="displayName"
                            value={props.courseState.displayName}
                            placeholder="EX:Automation Basics"
                            autoComplete="off"
                            onChange={handleChangeCreation}
                            required />
                        </div>
                        {props.courseState.displayNameValidation && <p style={{color: 'red',paddingTop: 5,paddingLeft:'15px'}}>Display name should contain alphabets, numbers, spaces, hyphens</p>}
                      </div>
                      <div className="row" style={{marginTop:'15px'}}>
                        <div className="col-md-12 col-sm-12">
                          <label className="idams-label pull-left">Course Code<span className="required_field_color_red">*</span></label>
                          <input type="text"
                            className="pull-left vs-input--input form-control"
                            name="courseCode"
                            value={props.courseState.courseCode}
                            placeholder="EX:S12345_654"
                            autoComplete="off"
                            onChange={handleChangeCreation}
                            //onChange={(e)=>{props.handleChangeCourseCreationInputFields(e.target.name,e.target.value)}}
                            required />
                        </div>
                        {props.courseState.courseCodeValidation && <p style={{color: 'red',paddingTop: 5,paddingLeft:'15px',paddingRight:'6px'}}>Course code should contain only alphabets and numbers</p>}
                      </div>
                      <div className="row" style={{marginTop:'15px'}}>
                        <div className="col-md-12 col-sm-12">
                          <label className="idams-label pull-left">Select Slot Hours<span className="required_field_color_red">*</span></label>
                          <div className="nj-form-select">
                          <select name="slotHours"
                          style={{lineHeight:1.8,borderRadius:"0.5rem"}}
                            value={props.courseState.slotHours}
                          onChange={handleChangeSlotHours}
                          autoComplete="off" required>
                          <option value="">select hours</option>
                            {props.courseState.slotHoursDropdown.map((num,index)=>{
                                return (<option key={index} value={num}>{num}</option>)
                            })}
                          </select>
                        </div>
                      </div>
                      </div>
                      <div className="row" style={{marginTop:'15px'}}>
                        <div className="col-md-12 col-sm-12">
                          <label className="idams-label pull-left">Enter Number of Users<span className="required_field_color_red">*</span></label>
                          <input type="text"
                            className="pull-left vs-input--input form-control"
                            name="numberOfUsers"
                            value={props.courseState.numberOfUsers}
                            placeholder="EX:12345.."
                            autoComplete="off"
                            onChange={(e)=>handleChangeNumberOfUser(e)}
                          required />
                        </div>
                      </div>
                      <div className="row" style={{marginTop:'15px'}}>
                        <div className="col-md-12 col-sm-12">
                          <label className="idams-label pull-left font_family_montserrat">Who can book slots for this course?<span className="required_field_color_red">*</span></label><br/>
                          <div style={{display:'inline-block'}}>
                            <label htmlFor="f-option" className="l-radio">
                              <input type="radio" id="f-option" name="radio" defaultChecked={true} value={true} onChange={props.handleBookSlotOptionChange} tabIndex={1} />
                              <span>User</span>
                            </label>
                            <label htmlFor="s-option" className="l-radio">
                              <input type="radio" id="s-option" name="radio" value={false} onChange={props.handleBookSlotOptionChange} tabIndex={2} />
                              <span>Admin</span>
                            </label>
                          </div>
                          <p style={{color:'#e80202d9',margin:0,fontWeight:'500'}}>Note: This will apply permanently</p>
                        </div>
                      </div>
                      <div className="row" style={{marginTop:'15px'}}>
                        <div className="col-md-12 col-sm-12 create_course_datepicker">
                          <label className="idams-label pull-left font_family_montserrat">From Date<span className="required_field_color_red">*</span></label>
                          <DatePicker dataPicker={"datepicker-calender"}
                          calendarClassName={"calender-design vs-input--input"}
                          minDate={new Date()}
                          onChange={getSelectedFromDate}
                          value={props.courseState.fromDate}
                          required={true}
                          />
                        </div>
                      </div>
                      <div className="row" style={{marginTop:'15px'}}>
                        <div className="col-md-12 col-sm-12 create_course_datepicker">
                          <label className="idams-label pull-left font_family_montserrat">To Date<span className="required_field_color_red">*</span></label>
                          <DatePicker dataPicker={"datepicker-calender"}
                          calendarClassName={"calender-design vs-input--input"}
                          minDate={props.courseState.fromDate}
                          onChange={getSelectedToDate}
                          value={props.courseState.toDate}
                          required={true}
                          disabled={!props.courseState.fromDate}
                          />
                        </div>
                      </div>
                     
                      {props.courseState.adminConfidentialDetails.length > 0 && props.courseState.adminConfidentialDetails[0].enableLiveClassroom &&
                    
                      <div className="row" style={{marginTop:'15px'}}>
                          <div className="col-md-12 col-sm-12">
                          <label className="checkbox coloured" style={{paddingLeft: '0px'}}>
                            <input type="checkbox" checked={props.courseState.enableLiveClassroom} onChange={props.getSelectedLiveClassroomValue} /><span className="checkbox-material"><span className="check"></span></span> 
                          </label>
                          <span className="font_family_montserrat" style={{fontSize:16,verticalAlign:"middle"}}>Enable live classroom</span>                   
                          </div>
                      </div>}
                    
                      {props.courseState.adminConfidentialDetails.length > 0 && props.courseState.adminConfidentialDetails[0].enableRemoteProctoring &&
                      <div className="row" style={{marginTop:'15px'}}>
                          <div className="col-md-12 col-sm-12">
                          <label className="checkbox coloured" style={{paddingLeft: '0px'}}>
                            <input type="checkbox" checked={props.courseState.enableRemoteProctoring} onChange={props.getSelectedRemoteProctoringValue} /><span className="checkbox-material"><span className="check"></span></span> 
                          </label>
                          <span className="font_family_montserrat" style={{fontSize:16,verticalAlign:"middle"}}>Enable remote proctoring</span>                   
                          </div>
                      </div>}

                      <div className="row" style={{marginTop:'20px',marginBottom:'20px'}}>
                        <div className="col-md-12">
                         {!props.courseState.showCourseSaveLoader ? <button  type="submit" className="nj-flow-button pull-right" disabled={props.courseState.courseNameValidation || props.courseState.displayNameValidation || props.courseState.courseCodeValidation} style={{marginRight:30}}>Submit</button> :<img src={"/img/loaders/spinningwheel.gif"}  className="pull-right" height={80} width={80} alt="loader" />}
                        </div>
                      </div>

                    </form>
                  </div>
               </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


const mapStateToProps = state => ({
  courseState: state.courseState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllApplications:CourseContainer.getAllApplications,
  setBooleanValue:Actions.setBooleanValue,
  selectApplication:CourseContainer.selectApplication,
  deleteSelectedApplication:CourseContainer.deleteSelectedApplication,
  getSelectedApplications:CourseContainer.getSelectedApplications,
  clearSelectApplication:CourseContainer.clearSelectApplication,
  selectResource:CourseContainer.selectResource,
  deleteSelectedResource:CourseContainer.deleteSelectedResource,
  getSelectedResources:CourseContainer.getSelectedResources,
  clearSelectResource:CourseContainer.clearSelectResource,
  handleChangeCourseCreationInputFields:Actions.handleChangeCourseCreationInputFields,
  createCourse:CourseContainer.createCourse,
  getAllResources:CourseContainer.getAllResources,
  initiateOnloadFunction:CourseContainer.initiateOnloadFunction,
  getAllCreateCourseResources:CourseContainer.getAllCreateCourseResources,
  handleFilterChangeCreateCourse:CourseContainer.handleFilterChangeCreateCourse,
  getAllDisciplines:CourseContainer.getAllDisciplines,
  getAllContentTypes:CourseContainer.getAllContentTypes,
  handleSearchCreateCourse:CourseContainer.handleSearchCreateCourse,
  handleSubmitSearchCreateCourse:CourseContainer.handleSubmitSearchCreateCourse,
  handleAddApps:CourseContainer.handleAddApps,
  routeToResources:CourseContainer.routeToResources,
  routeToAppStore:CourseContainer.routeToAppStore,
  setCourseElements:CourseContainer.setCourseElements,
  handleSelectAllClick:CourseContainer.handleSelectAllClick,
  getAllCourseResources:CourseContainer.getAllCourseResources,
  handleSelectAllClickApps:CourseContainer.handleSelectAllClickApps,
  getSelectedLiveClassroomValue:CourseContainer.getSelectedLiveClassroomValue,
  handleBookSlotOptionChange:CourseContainer.handleBookSlotOptionChange,
  getSelectedRemoteProctoringValue:CourseContainer.getSelectedRemoteProctoringValue,
  getConfidentialDetails:CourseContainer.getConfidentialDetails
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(Course);
