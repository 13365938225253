import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import * as DashboardContainer from "../../container/actions/Dashboard/action";
// import Store from "../../container/reducers/Store";
import BookedVsUsedSlots from './BookedVsUsedSlots/BookedVsUsedSlots'
import { bindActionCreators } from 'redux';
import './Dashboard.scss';
import TopCourses from './TopCourses/';
import TopApps from './TopApps';
// import CourseRatings from './CourseRatings';

function Dashboard (props) {
  const [selectedType, setSelectedType] = useState('demo');
  const [showCourseMoreMenu, setShowCourseMoreMenu] = useState(false);
  const [selectedCourseChartType, setSelectedCourseChartType] = useState('Radial Bar')

  useEffect( () => {   
    props.initialFunction("demo");
  },[]);
  // onload function end

  const handleChange=(e)=>{
    setSelectedType(e.target.value)
    props.initialFunction(e.target.value)
  }

  // const toggleMoreMenu=()=>{
  //   setShowCourseMoreMenu(!showCourseMoreMenu)
  // }
  var specifiedElement = document.getElementById('topCoursesChartDropdown');
  if(showCourseMoreMenu){
    document.addEventListener('click', function(event) {
      var isClickInside = specifiedElement.contains(event.target);
      if (!isClickInside) {
        //the click was outside the specifiedElement, do something
        setShowCourseMoreMenu(false)
      }
    });
  }

  const selectCourseChartMoreMenu=(type,name)=>{
    props.getTopCourseChartType(type);
    setSelectedCourseChartType(name);
    setShowCourseMoreMenu(false)
  }
  return (
    <div>
      <div className="analytics-data-dropdown-button">
        <div className="select-container">
          <select value={selectedType} onChange={handleChange}>
            <option value="demo">Demo</option>
            <option value="actual">Actual</option>
            <svg className="c-input__icon" viewBox="0 0 20 20" width={20} height={20}>
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
            </svg>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="nj-card top-element">
            <div className="top-element-text">
              <h5 className="top-element-text-title">
                Courses
              </h5>
              {props.elementsCountLoader ? <h3 className="top-element-text-count" style={{margin:0,paddingTop:10}}>
                <img src="/img/loaders/movingdots.gif" alt="" height="25" width="50" />
                <span> (Overall)</span>
              </h3>:
              <h3 className="top-element-text-count" style={{margin:0,paddingTop:10,color:"rgb(136, 93, 248)"}}>
                {props.dashboardState.elementsCount.courses || 0} <span> (Overall)</span>
              </h3>}
            </div>
            <div className="top-element-icon">
              <div style={{backgroundColor:'rgb(234,231,252)',color:'rgb(115,103,240)'}}>  
                <svg xmlns="http://www.w3.org/2000/svg" width={22} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-book">
                  <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20" />
                  <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="nj-card top-element">
            <div className="top-element-text">
              <h5 className="top-element-text-title">
                Users
              </h5>
              {props.elementsCountLoader ? <h3 className="top-element-text-count" style={{margin:0,paddingTop:10}}>
                <img src="/img/loaders/movingdots.gif" alt="" height="25" width="50" />
                <span> (Overall)</span>
              </h3>:
              <h3 className="top-element-text-count" style={{margin:0,paddingTop:10,color:"rgb(92, 200, 111)"}}>
                {props.dashboardState.elementsCount.users || 0} <span> (Overall)</span>
              </h3>}
            </div>
            <div className="top-element-icon">
              <div style={{backgroundColor:'rgb(222,246,233)'}}>               
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} style={{color:'rgb(92,200,112)'}}
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" 
                  strokeLinejoin="round" className="feather feather-users">
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                  <circle cx={9} cy={7} r={4} />
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                  <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="nj-card top-element">
            <div className="top-element-text">
              <h5 className="top-element-text-title">
                Resources
              </h5>
              {props.elementsCountLoader ? <h3 className="top-element-text-count" style={{margin:0,paddingTop:10}}>
                <img src="/img/loaders/movingdots.gif" alt="" height="25" width="50" />
                <span> (Overall)</span>
              </h3>:
              <h3 className="top-element-text-count" style={{margin:0,paddingTop:10,color:"rgb(251, 68, 74)"}}>
                {props.dashboardState.elementsCount.resources || 0} <span> (Overall)</span>
              </h3>}
            </div>
            <div className="top-element-icon">
              <div style={{backgroundColor:'#fae4e4'}}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{color:'rgb(234,84,85)'}} width={22} height={24} viewBox="0 0 24 24" fill="none" 
                  stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" 
                  className="feather feather-folder">
                  <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="nj-card top-element">
            <div className="top-element-text">
              <h5 className="top-element-text-title">
                Applications
              </h5>
              {props.elementsCountLoader ? <h3 className="top-element-text-count" style={{margin:0,paddingTop:10}}>
                <img src="/img/loaders/movingdots.gif" alt="" height="25" width="50" />
                <span> (Overall)</span>
              </h3>:
              <h3 className="top-element-text-count" style={{margin:0,paddingTop:10,color:"rgb(255, 157, 12)"}}>
                {props.dashboardState.elementsCount.applications || 0} <span> (Overall)</span>
              </h3>}
            </div>
            <div className="top-element-icon">
              <div style={{backgroundColor:'rgb(253,239,221)'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} style={{color:'rgb(244,158,65)'}}
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} 
                  strokeLinecap="round" strokeLinejoin="round" className="feather feather-copy">
                  <rect x={9} y={9} width={13} height={13} rx={2} ry={2} />
                  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />  
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12" style={{marginBottom:15}}>
        <div className="row">                    
          <BookedVsUsedSlots />
        </div>
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-4">
            <div className="">
              <div className="row nj-card" style={{marginBottom:15}}>
                <div className="col-md-12 top-courses-wrapper" style={{display:'flex',justifyContent:'space-between'}}>
                  <h4 className="top-courses-title">
                    Top Courses
                  </h4>
                  <div id="topCoursesChartDropdown" className="top-courses-chart-dropdown">
                    {/* <button id="more-btn" className="more-btn" onClick={()=>toggleMoreMenu()}>
                      {selectedCourseChartType}
                      <span style={{marginLeft:5}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 15" fill="none" stroke="currentColor" 
                          strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" 
                          className="feather feather-chevron-down">
                          <polyline points="6 9 12 15 18 9" />
                        </svg>
                      </span>
                    </button> */}
                      {showCourseMoreMenu && <div className="more-menu">
                        <div className="more-menu-caret">
                          <div className="more-menu-caret-outer" />
                          <div className="more-menu-caret-inner" />
                        </div>
                        <ul className="more-menu-items" tabIndex={-1} role="menu" aria-labelledby="more-btn" aria-hidden="true">
                          <li className="more-menu-item" role="presentation">
                            <button type="button" className="more-menu-btn" role="menuitem" onClick={()=>selectCourseChartMoreMenu('radialBar','Radial Bar')}>Radial Bar</button>
                          </li>
                          <li className="more-menu-item" role="presentation">
                            <button type="button" className="more-menu-btn" role="menuitem" onClick={()=>selectCourseChartMoreMenu('donut','Donut')}>Donut</button>
                          </li>
                          <li className="more-menu-item" role="presentation">
                            <button type="button" className="more-menu-btn" role="menuitem" onClick={()=>selectCourseChartMoreMenu('pie','Pie')}>Pie</button>
                          </li>
                        </ul>
                      </div>}
                  </div>
                </div>
                <div className="col-md-12">
                  <TopCourses />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="top-elements-center-card">
              <div className="row nj-card" style={{marginBottom:15}}>
                <div className="col-md-12">
                  <h4 className="top-courses-title" style={{marginBottom:'5px'}}>
                    Top Applications
                  </h4>
                </div>
                <div className="col-md-12">
                  <TopApps />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-8">
            <div className="top-elements-center-card">
              <div className="row nj-card" style={{marginBottom:15}}>
                <div className="col-md-12">
                  <h4 className="top-courses-title" style={{marginBottom:'5px'}}>
                    Course Ratings
                  </h4>
                </div>
                <div className="col-md-12">
                  <CourseRatings />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
  dashboardState: state.dashboardState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  initialFunction:DashboardContainer.initialFunction,
  getTopCourseChartType:DashboardContainer.getTopCourseChartType
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(Dashboard);
