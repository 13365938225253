import * as RequestedContentActionTypes from "../../../actionTypes";




export const getRequestedContent = (content) => ({
    type: RequestedContentActionTypes.DLT_REQUESTEDCONTENT_GET_REQUESTEDCONTENT,
    payload: {
      content
    }
  });

   
  export const handleChangeComment= (comment,index) => ({
    type: RequestedContentActionTypes.DLT_REQUESTEDCONTENT_ONCHANGE_ADDCOMMENTS,
    payload: {
      comment,
      index
    }
  });

  export const getRequestedComment = (content) => ({
    type: RequestedContentActionTypes.DLT_REQUESTEDCONTENT_GET_REQUESTEDCOMMENT,
    payload: {
      content
    }
  });

  export const searchRequestedContent = (searchRequestedArray) => ({
    type: RequestedContentActionTypes.DLT_REQUESTEDCONTENT_SEARCH_REQUESTED_CONTENT,
    payload: {
      searchRequestedArray
      // {console.log("sdfg",applicationArray1)}
    }
  });

  export const setBooleanValue = (name,value) => ({
    type: RequestedContentActionTypes.DLT_REQUESTEDCONTENT_SET_BOOLEAN_VALUE,
    payload: {
      name:name,
      value:value
    }
  });
  
export const setDisciplines = content => ({
    type: RequestedContentActionTypes.DLT_RESOURCE_DISCIPLINES,
    payload: {
      content
    }
  });
  
  export const setContentTypes = content => ({
    type: RequestedContentActionTypes.DLT_RESOURCE_CONTENT_TYPES,
    payload: {
      content
    }
  });

  export const setResourceElements = (name,content )=> ({
    type: RequestedContentActionTypes.DLT_RESOURCE_COMPONENT_ELEMENTS,
    payload: {
      name,
      content
    }
  })

  export const setRequestedContentStatus = (status )=> ({
    type: RequestedContentActionTypes.DLT_SET_REQUESTEDCONTENT_STATUS,
    payload: {
     status
    }
  })
  