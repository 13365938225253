
import React, { Component} from 'react';
import ReactApexChart from 'react-apexcharts';
import customHttp from '../../../packages/CustomHttp';
import {format} from "date-fns";
import 'react-datepicker/dist/react-datepicker.css';
import CustomStorage from '../../../packages/CustomStorage/';

class UserBookedvsUsedSlotUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate:this.props.startDate,
      endDate:this.props.endDate,
      allCourses:[],
      selectedCourseId:this.props.selectedCourseId,
      noDataAvailable:false,
      initialLoaderForBookedAndUsedSlots:true,
      bookedAndUsedSlots:{},
      series:[{name: 'Booked Slots',data:[]},{name: 'Used Slots',data:[]}],
      options :{
        chart: {
          type: 'area',
          height: 450,
          stacked: false,
          fontFamily: 'Montserrat,sans-serif',
          foreColor: '#626262'
        },
        colors: ['#008FFB','#00E396'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
          colors: ['#008FFB','#00E396'],
        },
        legend: {
          show:false,
          position: 'top',
          horizontalAlign: 'right'
        },
        xaxis: {
          type: 'category',
          categories:[]
        },
        stroke: {
          show: true,
          curve: 'smooth',
          colors: ['#008FFB','#00E396'],
          width: 2,
          dashArray: 0,      
        }
      }

    }
  }

  componentDidMount(){  
//  this.fetchAllCourse()
 this.props.callChildFunction(this.getCourseUsage)
  }
  //Date conversion function start.
 convertDate=(date)=>{
  return format(new Date(date),'MMM')+" "+format(new Date(date),'d')
  } 
//Date conversion function end.

// used for get date range 
 getDates = function(startDate, endDate) {
  var dates = [],
      currentDate = startDate,
      addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};
  getCourseUsage=()=>{
    var demoBookedAndUsedSlots=[{date: "2020-03-05",bookedSlotCount: 12,logonCount:0},
    {date: "2020-03-06",bookedSlotCount: 14,logonCount:11},
    {date: "2020-03-07",bookedSlotCount: 33,logonCount:6},
    {date: "2020-03-08",bookedSlotCount: 10,logonCount:5},
    {date: "2020-03-09",bookedSlotCount: 8,logonCount:2},
    {date: "2020-03-10",bookedSlotCount: 15,logonCount:11},
    {date: "2020-03-11",bookedSlotCount: 11,logonCount:4}]
    // console.log("this.props",this.props.fromDate,this.props.toDate);
     var n = new Date(this.props.startDate);
     var currentDate = n.getDate();
     if(currentDate<10){
       currentDate='0'+currentDate;
     }
     var currentMonth = n.getMonth() + 1; //Months are zero based
     if(currentMonth<10){
       currentMonth='0'+currentMonth;
     }
     var currentYear = n.getFullYear();
     var fromDate = currentYear + "-" + currentMonth + "-" + currentDate;  // date conversion dd/mm/yyyy to yyyy-mm-dd

     var p = new Date(this.props.endDate);
     var tempDate = p.getDate();
     if(tempDate<10){
       tempDate='0'+tempDate;
     }
     var tempMonth = p.getMonth() + 1;
     if(tempMonth<10){
       tempMonth='0'+tempMonth;
     }
     var tempYear = p.getFullYear();
     var toDate = tempYear + "-" + tempMonth + "-" + tempDate;   // date conversion dd/mm/yyyy
     if(this.props.demoAndActualValue==="demo"){
       var obj={};
       obj.bookedSlots=103;
       obj.usedSlots=39
       obj.details=demoBookedAndUsedSlots;
       var series=this.state.series;
      //  var options=this.state.options;
       this.setState({initialLoaderForBookedAndUsedSlots:false,bookedAndUsedSlots:obj})
       var bookedData=[];
       var  usedData=[];
       var labels=[]; 
       var sevenDaysBack = new Date(); //dynamically get last 7 days
      sevenDaysBack.setDate(sevenDaysBack.getDate() - 6);  
      var dates = this.getDates(new Date(sevenDaysBack),new Date()); 
         var that=this;                                                                                                
      dates.forEach(function(date) {
         labels.push(that.convertDate(date))
      }); 
       for(var i=0;i<demoBookedAndUsedSlots.length;i++){
        bookedData.push(demoBookedAndUsedSlots[i].bookedSlotCount)
        usedData.push(demoBookedAndUsedSlots[i].logonCount)
        //labels.push(this.convertDate(demoBookedAndUsedSlots[i].date))
        if(i===demoBookedAndUsedSlots.length-1){
          series[0].data=bookedData;
          series[1].data=usedData;
          //options.xaxis.categories=labels;
          this.setState({
            options: { ...this.state.options, xaxis: { ...this.state.options.xaxis, categories: labels,type: 'category',}},series,noDataAvailable:true})
        }
      }
    }else{
        if(this.props.selectedUserId !== "no users available"){
          var user_id='';
          if(this.props.selectedUserId.length>0){
             user_id=this.props.selectedUserId;
          }else{
             user_id= CustomStorage.localStorageGet('selectedUserData')._id;
          }
          if(this.props.selectedCourseId.length>0){ // checking courseId is empty or not
            var url = '/api/netUtilization/courseAnalytics/fetchUsageDetails/fromDate/'+fromDate+'/toDate/'+toDate+'/courseId/'+this.props.selectedCourseId+'/userId/'+user_id;
            customHttp.get(url)
              .then(response => {
                if(response.data.message !== "No Records Found!"){
                var series=JSON.parse(JSON.stringify(this.state.series));
                // var options= JSON.parse(JSON.stringify(this.state.options));
                // var videoResourceCountGraphDate = response.data.message
                this.setState({initialLoaderForBookedAndUsedSlots:false,bookedAndUsedSlots:response.data.message})
                //console.log("course usage",response.data.message);
                var bookedData=[];
                var  usedData=[];
                var labels=[]; 
                if(response.data.message.bookedSlots>0) {
                  for(var i=0;i<response.data.message.details.length;i++){
                    bookedData.push(response.data.message.details[i].bookedSlotCount)
                    usedData.push(response.data.message.details[i].logonCount)
                    labels.push(this.convertDate(response.data.message.details[i].date))
                    if(i===response.data.message.details.length-1){
                      series[0].data=bookedData;
                      series[1].data=usedData;
                      this.setState({ options: {...this.state.options,xaxis: {...this.state.options.xaxis,categories: labels,type: 'category',}},series,noDataAvailable:true})
                    }
                  }
                }else{
                  this.setState({noDataAvailable:false})
                }
                }else{
                  var obj={};
                  obj.bookedSlots=0;
                  obj.usedSlots=0
                  obj.details=[];
                  this.setState({initialLoaderForBookedAndUsedSlots:false,bookedAndUsedSlots:obj,noDataAvailable:false})
                }

              })
              .catch(error => {
                var object={};
                object.bookedSlotCount=0;
                object.logonCount=0;
                object.details=[];
                //this.setState({videoResourceCountGraphDate:[],noDataFound:[]})
                this.setState({initialLoaderForBookedAndUsedSlots:false,bookedAndUsedSlots:object,noDataAvailable:false})
                console.log("failed", error.response);
              });
            }
        }else{
          var object={};
            object.bookedSlotCount=0;
            object.logonCount=0;
            object.details=[];
            this.setState({initialLoaderForBookedAndUsedSlots:false,bookedAndUsedSlots:object,noDataAvailable:false})
        }
      }

     }
  render(){
    return(
      <div className="add-on-load-animation">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px',marginTop:"15px"}}>
              <div className="col-md-12 col-sm-12 nj-card" >
                {!this.state.initialLoaderForBookedAndUsedSlots ?
                  <div>                 
                    <div className="row" style={{textAlign:"left"}}>
                      <div className="col-md-12 col-sm-12 slot-heading-div" style={{marginTop:"10px"}}>
                        <span className="slot-heading-div_span">
                          <span className="font_family_montserrat" >Total Booked Slots</span><br></br>
                          <span className="font_family_montserrat slot-value" style={{color:"#626262",fontWeight:400}}>{this.state.bookedAndUsedSlots.bookedSlots}</span>
                        </span> 
                        <span className="slot-heading-span">
                          <span className="font_family_montserrat">Total Used Slots</span><br></br>
                          <span className="font_family_montserrat slot-value" style={{color:"#626262"}}>{this.state.bookedAndUsedSlots.usedSlots}</span>
                        </span>                 
                      </div>
                    </div>
                    {Object.keys(this.state.bookedAndUsedSlots).length>0 && this.state.noDataAvailable?
                      <div>
                        <div style={{margin:"0px 10px"}}>
                          <div id="chart">
                            <div id="chart-timeline" className="icon-zindex">
                              <ReactApexChart options={this.state.options} series={this.state.series}  type='area' height={400} />
                            </div>
                          </div>
                        </div>
                      </div>
                    :
                      <div className="col-md-12 font_family_montserrat idams_noDataFound" style={{height:"52vh"}}>No data found</div>
                    }
                  </div> 
                :
                  <div className="row" style={{height:"58vh"}}>
                    <div className="col-md-12 aligh-bookedslot-loader-image-center" >
                      <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                    </div>
                  </div>
                }            
              </div>
            </div>
          </div>
        </div>
     </div>
    );
  }
}

export default UserBookedvsUsedSlotUsage;
