import axios from "axios";
import * as ProfileActions from "./index";
import customHttp from '../../../packages/CustomHttp/';
import snackbar from '../../../packages/Snackbar/';

export  function getConfidentialDetails(){
   return async (dispatch ,getState)=> {
      var profile={
         organizationAddress: "",
         organizationCity: "",
         organizationState: "",
         organizationCountry: "",
         organizationZipCode: "",
         organizationPhoneNumber: "",
         organizationName: "",
         organizationDomainName: "",
         enableLiveClassroom:false,
         enableRemoteProctoring:false       
       }
       try {
         var userDetails=JSON.parse(sessionStorage.getItem("userData"))
          let response = await customHttp.get('/api/confidentialDetails/user/'+userDetails.belongsTo);
          if(response.data.message.length>0){
            dispatch(ProfileActions.saveComponentElements("profile",response.data.message[0]));
            
            if(Object.keys(response.data.message[0].organizationDomainName).length>0){
               dispatch(ProfileActions.saveComponentElements("disableDomain",true));
            }else{
               dispatch(ProfileActions.saveComponentElements("disableDomain",false));
            }

            if(Object.keys(response.data.message[0].organizationName).length>0){
               dispatch(ProfileActions.saveComponentElements("disableOrgName",true));
            }else{
               dispatch(ProfileActions.saveComponentElements("disableOrgName",false)); 
            }

          }else{          
             dispatch(ProfileActions.saveComponentElements("profile",profile));
             dispatch(ProfileActions.saveComponentElements("disableOrgName",false)); 
             dispatch(ProfileActions.saveComponentElements("disableDomain",false));
          }
       } catch (err) {
            dispatch(ProfileActions.saveComponentElements("disableDomain",false));
            dispatch(ProfileActions.saveComponentElements("profile",profile));
            dispatch(ProfileActions.saveComponentElements("disableOrgName",false)); 
            console.log("error",err)
       }
   }
}

export  function handleChangeInputValue(e){
   return async (dispatch ,getState)=> {
      e.preventDefault();
      var profile =JSON.parse(JSON.stringify(getState().profileState.profile))
      profile[e.target.name]=e.target.value;
      dispatch(ProfileActions.saveComponentElements("profile",profile));
   }
}

export function getSelectedLiveClassroomValue() {
   return (dispatch ,getState)=> {
      var profile =JSON.parse(JSON.stringify(getState().profileState.profile))
      var liveClassroomEnabled=JSON.parse(JSON.stringify(getState().profileState.profile.enableLiveClassroom))
      var temp=!liveClassroomEnabled;
      profile["enableLiveClassroom"]=temp;
      dispatch(ProfileActions.saveComponentElements("profile",profile));
     
   }
}
export function getSelectedRemoteProctoringValue() {
   return (dispatch ,getState)=> {
      var profile =JSON.parse(JSON.stringify(getState().profileState.profile))
      var remoteProctoringEnabled=JSON.parse(JSON.stringify(getState().profileState.profile.enableRemoteProctoring))
      var temp=!remoteProctoringEnabled;
      profile["enableRemoteProctoring"]=temp;
      dispatch(ProfileActions.saveComponentElements("profile",profile));
     
   }
}

 
export  function handleChangeEnableForm(e){
   return async (dispatch ,getState)=> {
      e.preventDefault();
      dispatch(ProfileActions.saveComponentElements("disable",false));
   }
}

export  function handleChangeDisableForm(e){
   return async (dispatch ,getState)=> {
      e.preventDefault();
      dispatch(ProfileActions.saveComponentElements("profile",{}));
      dispatch(getConfidentialDetails()) 
      dispatch(ProfileActions.saveComponentElements("disable",true));
   }
}
export  function updateConfidentialDetails(e){
   return async (dispatch ,getState)=> {
      e.preventDefault();
      var profileState =JSON.parse(JSON.stringify(getState().profileState)) 
      if(!profileState.domainNameValidation && !profileState.domainNameExist && !profileState.orgValidation && !profileState.orgNameExist){
         try {
            dispatch(ProfileActions.saveComponentElements("formSubmitted",true));
            var profile =JSON.parse(JSON.stringify(getState().profileState.profile)) 
            console.log("profileee",profile)
            var userDetails=JSON.parse(sessionStorage.getItem("userData"))
            var obj={}
            obj.userId=userDetails._id;
            obj.belongsTo=userDetails.belongsTo;
            obj.organizationName=profile.organizationName.toLowerCase();
            obj.organizationDomainName=profile.organizationDomainName.toLowerCase();
            obj.organizationAddress=profile.organizationAddress;
            obj.organizationCity=profile.organizationCity;
            obj.organizationState=profile.organizationState;
            obj.organizationCountry=profile.organizationCountry;  
            obj.organizationZipCode=profile.organizationZipCode;
            obj.organizationPhoneNumber=profile.organizationPhoneNumber;
            obj.enableLiveClassroom=profile.enableLiveClassroom;
            obj.enableRemoteProctoring=profile.enableRemoteProctoring;
            //console.log("final",obj)
            let response = await axios.patch('/api/confidentialDetails/',obj);
            //console.log("response",response.data); 
            dispatch(ProfileActions.saveComponentElements("formSubmitted",false));
            dispatch(getConfidentialDetails())  
            dispatch(ProfileActions.saveComponentElements("disable",true)); 
            dispatch(ProfileActions.saveComponentElements("successSnackbarMessage","Updated successfully"))
            snackbar.successSnackbar();            
         } catch (err) {
            dispatch(ProfileActions.saveComponentElements("errorSnackbarMessage","Error try again"))
            snackbar.errorSnackbar();
            dispatch(ProfileActions.saveComponentElements("formSubmitted",false));
            console.log("error",err)
         }
      }
   }
}

export  function handleChangeValidateOrganizationName(e){
   return async (dispatch ,getState)=> {
      e.preventDefault();
      let orgRegex = /^[a-zA-Z0-9- ]*$/;
      let orgValid = orgRegex.test(String(e.target.value))
      if(Object.keys(e.target.value).length>0 && !orgValid){
        dispatch(ProfileActions.saveComponentElements("orgValidation",true));
      }
      else{
         dispatch(ProfileActions.saveComponentElements("orgValidation",false));
      }
   }
}

  // checking organizatin name
  export  function handleChangeCheckOrganizationName(e){
   return async (dispatch ,getState)=> {
      e.preventDefault();
      var profile =JSON.parse(JSON.stringify(getState().profileState.profile))
      profile[e.target.name]=e.target.value;
      dispatch(ProfileActions.saveComponentElements("profile",profile));
      dispatch(ProfileActions.saveComponentElements("orgValidation",false));
      try{
         if(Object.keys(e.target.value).length>0) {       
            var value = e.target.value.toLowerCase()         
            let response = await customHttp.get("/api/confidentialDetails/organizationName/"+value)
            let temp = response.data.message.isAvailable;
            if(!temp){
              dispatch(ProfileActions.saveComponentElements("orgNameExist",true));
            }else{
               dispatch(ProfileActions.saveComponentElements("orgNameExist",false));
            }
         }else{
            dispatch(ProfileActions.saveComponentElements("orgNameExist",false));   
         } 
      }catch(err){
         dispatch(ProfileActions.saveComponentElements("orgNameExist",false));
      }
   }
}

export  function handleChangeValidateDomainName(e){
   return async (dispatch ,getState)=> {
      e.preventDefault();
      let domainRegex = /^[a-zA-Z]+[-]*[a-zA-Z]*(\.[a-zA-Z-]{2,}){1,4}$/;
      let domainValid = domainRegex.test(String(e.target.value))
      if(Object.keys(e.target.value).length>0 && !domainValid){
         dispatch(ProfileActions.saveComponentElements("domainNameValidation",true));
      }
      else{
         dispatch(ProfileActions.saveComponentElements("domainNameValidation",false));
      }
   }
}

 // checking domain name
 export  function handleChangeCheckDomainName(e){
   return async (dispatch ,getState)=> {
      e.preventDefault();
      var profile =JSON.parse(JSON.stringify(getState().profileState.profile))
      profile[e.target.name]=e.target.value;
      dispatch(ProfileActions.saveComponentElements("profile",profile));
      dispatch(ProfileActions.saveComponentElements("domainNameValidation",false));
      try{
         if(Object.keys(e.target.value).length>0) {       
            var value = e.target.value.toLowerCase()         
            let response = await customHttp.get("/api/confidentialDetails/domain/"+value)
            let temp = response.data.message.isAvailable;
            if(!temp){
              dispatch(ProfileActions.saveComponentElements("domainNameExist",true));
            }else{
               dispatch(ProfileActions.saveComponentElements("domainNameExist",false));
            }
         }else{
            dispatch(ProfileActions.saveComponentElements("domainNameExist",false));   
         } 
      }catch(err){
         dispatch(ProfileActions.saveComponentElements("domainNameExist",false));
      }
   }
}


export  function handleKeyPress(e){
   return async (dispatch ,getState)=> {
      if (e.keyCode === 13) {
         e.target.blur()
        }
   }
}






