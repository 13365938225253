import * as proctoringActions from "./index";
import customHttp from '../../../packages/CustomHttp/';
import snackbar from '../../../packages/Snackbar/';
import axios from 'axios';
import {format} from "date-fns";

/**
 * Function to get random number.
 * @author Narasappa H
 * @param {number} length - Number is used for generating number of times digits.
 * @return {number} - Which is return random numbers based on number.
 */
// function getRandom(length) {
//    return Math.floor(Math.pow(10, length-1) + Math.random() * 9 * Math.pow(10, length-1));
//  }


export function displayAlert(type,msg) {
   return async (dispatch)=> {
      // console.log("msg",msg)
      if(type==='success'){
         dispatch(setProctoringElements('successSnackbarMessage',msg));
         snackbar.successSnackbar();
       } else if(type==='error'){
         dispatch(setProctoringElements('errorSnackbarMessage',msg));
         snackbar.errorSnackbar();
      }
   }
}

export function setProctoringElements(name,content) {
   return (dispatch)=> {
      dispatch(proctoringActions.setProctoringElements(name,content))
   }
}
 // used for get date range
 var getDates = function(startDate, endDate) {
   var dates = [],
      currentDate = startDate,
      addDays = function(days) {
         var date = new Date(this.valueOf());
         date.setDate(date.getDate() + days);
         return date;
      };
   while (currentDate <= endDate) {
   dates.push(currentDate);
   currentDate = addDays.call(currentDate, 1);
   }
   return dates;
};
  //Date conversion function start.
  var convertDate=(date)=>{
   return format(new Date(date),'yyyy')+"-"+format(new Date(date),'MM')+"-"+format(new Date(date),'dd')
}

export function getAllCourses() {
   return async(dispatch,getState)=> { 
      var userDetails=JSON.parse(sessionStorage.getItem("userData")) 
      var datesArray=[];
      try {
         let response = await customHttp.get('/api/course/allCourses/user/'+userDetails._id);
        // console.log("response",response.data.message);
         if(response.data.message.length>0){
            dispatch(setProctoringElements('rpAllCourses',response.data.message)); 
            var dates=getDates(new Date(response.data.message[0].startDate),new Date(response.data.message[0].endDate));
            dates.forEach(function(date) {
               datesArray.push({date:convertDate(date),originalDate:date})
            });
            
            dispatch(setProctoringElements('rpDatesArray',datesArray)); 
            dispatch(setProctoringElements('selectedRPCourse',response.data.message[0])); 
            dispatch(setProctoringElements('selectedRPDate',datesArray[0])); 
            dispatch(getAllBookedSlot());
         }else{
            dispatch(setProctoringElements('rpAllCourses',[])); 
            dispatch(setProctoringElements('rpDatesArray',[]));
            dispatch(setProctoringElements('selectedRPCourse',{})); 
            dispatch(setProctoringElements('selectedRPDate',{})); 
            dispatch(setProctoringElements('bookedSlots',[]));
            dispatch(setProctoringElements('rpInitialLoader',false));
              
         }
       } catch (err) {
         dispatch(setProctoringElements('rpAllCourses',[])); 
         dispatch(setProctoringElements('rpDatesArray',[])); 
         dispatch(setProctoringElements('selectedRPCourse',{})); 
         dispatch(setProctoringElements('selectedRPDate',{}));
         dispatch(setProctoringElements('bookedSlots',[]));
         dispatch(setProctoringElements('rpInitialLoader',false));  
         console.log("err",err);
       }
   }
}

export function getAllBookedSlot() {
   return async(dispatch,getState)=> { 
        dispatch(setProctoringElements('selectedBookedSlot',"")); 
      dispatch(setProctoringElements('rpInitialLoader',true)); 
      var course= JSON.parse(JSON.stringify(getState().proctoringState.selectedRPCourse))
      var date= JSON.parse(JSON.stringify(getState().proctoringState.selectedRPDate))
      try{
         let response = await customHttp.get('/api/slot/fetchBookingDetails/date/'+date.date+'/courseId/'+course._id);
         if(response.data.message.length>0){
            dispatch(setProctoringElements('bookedSlots',response.data.message));
            dispatch(setProctoringElements('rpInitialLoader',false));  
         }else{
            dispatch(setProctoringElements('bookedSlots',[]));
            dispatch(setProctoringElements('rpInitialLoader',false));  
         }
      } 
      catch(err){
         dispatch(setProctoringElements('rpInitialLoader',false)); 
         console.log("err",err)
      } 
   }
}

export function checkWebcamVideoType(obj) {
   return async(dispatch,getState)=> { 
      var videoUrlpathApi= JSON.parse(JSON.stringify(getState().proctoringState.videoUrlpathApi))

      try{
         let response = await axios.get(videoUrlpathApi+'/api/uploads/'+obj.idName+'.mp4');
        
         if(response.data.fileExists){
             dispatch(setProctoringElements('videoFileType','mp4'));
         }else{
            dispatch(setProctoringElements('videoFileType','webm'));
         }
         dispatch(setProctoringElements('selectedVideoObject',obj));
      } 
      catch(err){
         dispatch(setProctoringElements('videoFileType','webm'));
         dispatch(setProctoringElements('selectedVideoObject',{}));
         console.log("err",err)
      } 
   }
}
export function getWebmVideos(obj,props) {
   return async(dispatch,getState)=> { 
      var videoUrlPath=JSON.parse(JSON.stringify(getState().proctoringState.videoUrlPath))
      dispatch(setProctoringElements('webcamVideoLoader',true));
      var date= JSON.parse(JSON.stringify(getState().proctoringState.selectedRPDate))
      var dummyArray=[];
      try{
      let response = await customHttp.get('/api/slot/fetch/videoDetails/date/'+date.date+'/bookingTime/'+obj.bookingTime+'/username/'+obj.username);
         if(response.data.message.length>0){
            for(var i=0;i<response.data.message.length;i++){
               dummyArray.push({"url":videoUrlPath+response.data.message[i],"idName":response.data.message[i]}) 
            }          
            dispatch(checkWebcamVideoType(dummyArray[0])); 
            dispatch(setProctoringElements('videoArray',dummyArray));
           dispatch(setProctoringElements('selectedVideoObject',dummyArray[0]));
           
            dispatch(setProctoringElements('webcamVideoLoader',false));  
         }else{
            dispatch(setProctoringElements('videoArray',[])); 
            dispatch(setProctoringElements('selectedVideoObject',{})); 
            dispatch(setProctoringElements('webcamVideoLoader',false)); 
         }
      } 
      catch(err){
         dispatch(setProctoringElements('videoArray',[])); 
         dispatch(setProctoringElements('selectedVideoObject',{})); 
         dispatch(setProctoringElements('webcamVideoLoader',false)); 
         console.log("err",err)
      }
   }
}

export function handleChangeCourse(e) {
   return (dispatch)=> {
      var course=JSON.parse(e.target.value);
      var datesArray=[];
      dispatch(setProctoringElements('selectedRPCourse',course)); 
      var dates=getDates(new Date(course.startDate),new Date(course.endDate));
      dates.forEach(function(date) {
         datesArray.push({date:convertDate(date),originalDate:date})
      });
      dispatch(setProctoringElements('rpDatesArray',datesArray)); 
      dispatch(setProctoringElements('selectedRPDate',datesArray[0])); 
      dispatch(getAllBookedSlot());
     // console.log("selected course",JSON.parse(e.target.value))

      //call get booking slots api
   }
}

export function handleChangeDate(e) {
   return (dispatch)=> {
      var date=JSON.parse(e.target.value);
      dispatch(setProctoringElements('selectedRPDate',date)); 
      dispatch(getAllBookedSlot());
   }
}
export function handleChangeStatus(e) {
   return (dispatch)=> {
      var status=JSON.parse(e.target.value);
      dispatch(setProctoringElements('selectedStatus',status));
      dispatch(getAllBookedSlot()); 
   }
}

export function showWebmVideoPopup(data){
   return (dispatch ,getState)=> {
     console.log("data",data)
     sessionStorage.setItem("userDatails",JSON.stringify(data))    
     dispatch(setProctoringElements('selectedBookedSlot',data._id)); 
      dispatch(setProctoringElements('selectedSlot',data));      
      document.getElementById("webmVideoPopup").style.display='block';
      dispatch(getWebmVideos(data))

   }
}

export function storeVideoDetails(data,props) {
   return (dispatch,getState)=>{
      console.log("data",data)
      sessionStorage.setItem("videoDetails",JSON.stringify(data));
      props.history.push("/sub-admin/object-face-recognition");
      dispatch(getWebmVideos(data))
      // props.history.push("/appstore")
   };

   // var date= JSON.parse(JSON.stringify(getState().proctoringState.selectedRPDate))
   // dispatch(setProctoringElements('date',date));  
}

//start of pdf code
/**
 * Function to get hide/show pdf.
 * @author Sachin
 * @param {Boolean} value - value to set i,e true or false.
 * @return {Void}
 */
export function togglePdf(value){
   return async (dispatch, getState)=> {   
     dispatch(setProctoringElements('showPdf',value));
     dispatch(setProctoringElements('pdfLoader',true));
     if(value){
        try{
            var link = `${getState().proctoringState.pdfUrlPath+getState().proctoringState.selectedSlot._id}.pdf`;
            var res = await customHttp.post('/api/pdf/get',{url:link});
            window.pdfjsLib.getDocument(convertDataURIToBinary("data:application/pdf;base64,"+res.data)).then((pdf) => {
            var myState = {...getState().proctoringState.pdfState,pdf}
            dispatch(setProctoringElements('pdfState',myState));
            dispatch(setProctoringElements('pdfLoader',false));
            dispatch(renderPdf())
         })
         .catch(err=>{
            console.log("error",err);
            dispatch(setProctoringElements('pdfLoader',false));
            dispatch(setProctoringElements('showPdf',false));
            dispatch(displayAlert('error','Unable to Load PDF'));
         })
        } catch(err){
            console.log("error",err);
            dispatch(setProctoringElements('pdfLoader',false));
            dispatch(setProctoringElements('showPdf',false));
            dispatch(displayAlert('error','Unable to Load PDF'));
        }
     }
   }
}
var BASE64_MARKER = ';base64,';
function convertDataURIToBinary(dataURI) {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for(var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
export function renderPdf() {
   return (dispatch, getState)=> {
      var myState = getState().proctoringState.pdfState;
      myState.pdf.getPage(myState.currentPage).then((page) => {
         var canvas = document.getElementById("pdf_renderer");
         var ctx = canvas.getContext('2d');
         var viewport = page.getViewport(myState.zoom);
         canvas.width = viewport.width;
         canvas.height = viewport.height;
         page.render({
            canvasContext: ctx,
            viewport: viewport
         });
      });
   };
}
export function goPreviousPage() {
   return (dispatch, getState)=> {
      var myState = getState().proctoringState.pdfState;
      if(myState.pdf !== null && myState.currentPage > 1) {
         var pdfState = {...getState().proctoringState.pdfState,currentPage:myState.currentPage-1}
         dispatch(setProctoringElements('pdfState',pdfState));
         document.getElementById("current_page").value = pdfState.currentPage;
         dispatch(renderPdf());
      }
   };
}
export function goNextPage() {
   return (dispatch, getState)=> {
      var myState = getState().proctoringState.pdfState;
      if(myState.pdf !== null && myState.currentPage < myState.pdf._pdfInfo.numPages){
         var pdfState = {...getState().proctoringState.pdfState,currentPage:myState.currentPage+1}
         dispatch(setProctoringElements('pdfState',pdfState));
         document.getElementById("current_page").value = pdfState.currentPage;
         dispatch(renderPdf());
      } 
   };
}
export function currentPage(e) {
   return (dispatch, getState)=> {
      var myState = getState().proctoringState.pdfState;
      if(myState.pdf !== null){
         // Get key code
         var code = (e.keyCode ? e.keyCode : e.which);
         // If key code matches that of the Enter key
         if(code == 13) {
            var desiredPage = document.getElementById('current_page').valueAsNumber;
            if(desiredPage <= myState.pdf._pdfInfo.numPages){
               desiredPage = desiredPage >= 1? desiredPage : 1;
            } else {
               desiredPage = myState.pdf._pdfInfo.numPages;
            }
            if(desiredPage >= 1 && desiredPage <= myState.pdf._pdfInfo.numPages) {
               var pdfState = {...getState().proctoringState.pdfState,currentPage:desiredPage}
               dispatch(setProctoringElements('pdfState',pdfState));
               document.getElementById("current_page").value = desiredPage;
               dispatch(renderPdf());
            }
         }
      }
   };
}
export function zoomInPage() {
   return (dispatch, getState)=> {
      var myState = getState().proctoringState.pdfState;
      if(myState.pdf !== null && myState.zoom<5.0) {
         console.log("zoom",myState.zoom)
         var pdfState = {...getState().proctoringState.pdfState,zoom:myState.zoom+0.5}
         dispatch(setProctoringElements('pdfState',pdfState));
         dispatch(renderPdf());
      }
   };
}
export function zoomOutPage() {
   return (dispatch, getState)=> {
      var myState = getState().proctoringState.pdfState;
      if(myState.pdf !== null && myState.zoom>0.5) {
         console.log("zoom",myState.zoom)
         var pdfState = {...getState().proctoringState.pdfState,zoom:myState.zoom-0.5}
         dispatch(setProctoringElements('pdfState',pdfState));
         dispatch(renderPdf());
      }
   };
}
//end of pdf code

export function playAnotherVideo(obj){
   return (dispatch ,getState)=> {
     // console.log("data",obj) 
     dispatch(checkWebcamVideoType(obj));       
     // dispatch(setProctoringElements('selectedVideoObject',obj)); 

   }
}
export function hideWebcamVideoPopup(){
   return (dispatch ,getState)=> {
      // dispatch(setProctoringElements('selectedBookedSlot',"")); 
      var videoArray= JSON.parse(JSON.stringify(getState().proctoringState.videoArray))
      if(videoArray.length>0 && document.getElementById("webm-video")){
         document.getElementById("webm-video").pause();
      }     
      dispatch(setProctoringElements('selectedVideoObject',{}));  
      dispatch(setProctoringElements('showPdf',false)); //added by sachin    
      document.getElementById("webmVideoPopup").style.display='none';

   }
}



