// import axios from "axios";
import * as RequestedContentActions from "./index";
import snackbar from '../../../packages/Snackbar/';
import customHttp from '../../../packages/CustomHttp/';

// for applications

export function getdltRequestedContent(){ 
   var userDetails=JSON.parse(sessionStorage.getItem("userData"))
     return (dispatch ,getState)=> { 
         // axios.get('/api/requestedCapsule/createRequestedCapsule/email/'+userDetails.email)
         var url = '/api/requestedCapsule/createRequestedCapsule/email/'+userDetails.email ;
         customHttp.get(url)
        .then(response=>{
           var content=response.data.message;
         //   console.log("all requested content",response.data.message)
           dispatch(RequestedContentActions.getRequestedContent(content))
           dispatch(setResourceElements('selectedRequestedContentRow',content[getState().DltRequestedContentState.selectedIndex]))
        })
        .catch(error => {
         dispatch(RequestedContentActions.getRequestedContent([]))
         // dispatch(RequestedContentActions.setBooleanValue("errorSnackbarMessage","Username is taken")) 
         //    snackbar.errorSnackbar(); 

       console.log("error",error)
     });
     }
  }


 export function handleChangeComment(data,comment){ 
   //  var comment1 ={};
   //  comment1 = comment
   return (dispatch ,getState)=> { 
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      var object1={};
      object1._id=data._id;
      object1.comment=comment;
      object1.userId=userDetails._id;
      object1.belongsTo=userDetails.belongsTo;
      object1.email=userDetails.email;
      // axios.put('/api/requestedCapsule/addComment',object1)
      var url = '/api/requestedCapsule/addComment' ;
      customHttp.put(url , object1)
     .then(response=>{
      //   var content=response.data.message;
         dispatch(RequestedContentActions.setBooleanValue("successSnackbarMessage","Comment Submitted successfully")) 
         snackbar.successSnackbar();
         
         dispatch(getdltRequestedContent())
         
      
     })
     .catch(error => {
   //    dispatch(RequestedContentActions.getRequestedContent([]))
      dispatch(RequestedContentActions.setBooleanValue("errorSnackbarMessage","Comment not submitted")) 
      snackbar.errorSnackbar(); 
      console.log("error",error);

  });
      
   }
}

export const getRequestedComment=(data)=>{ 
   // var userDetails=JSON.parse(sessionStorage.getItem("userData"))
     return (dispatch ,getState)=> { 
        
         // axios.get('/api/requestedCapsule/fetchComments/requestedCapsuleId/' +data._id)
         var url = '/api/requestedCapsule/fetchComments/requestedCapsuleId/' +data._id ;
         customHttp.get(url)
        .then(response=>{
           var content=response.data.message;
           dispatch(RequestedContentActions.getRequestedComment(content))
         
        })
        .catch(error => {
         dispatch(RequestedContentActions.getRequestedContent([]))
       console.log("error",error)
     });
        
        
     }
  }

  export const searchRequestedContent=(data)=>{ 
   // var userDetails=JSON.parse(sessionStorage.getItem("userData"))
     return (dispatch ,getState)=> { 
        
        dispatch(RequestedContentActions.searchRequestedContent(data))
        
        
     }
  }

  export function getAllDisciplines(){
    return async (dispatch, getState)=> {
       try {
       let response = await customHttp.get("/api/branch/");
       let res = JSON.parse(JSON.stringify(response.data.message));
       if(Array.isArray(response.data.message)){
          res.push({_id:'all',name:'All'});
          dispatch(RequestedContentActions.setDisciplines(res.reverse()));
          dispatch(setResourceElements('createResourceDisciplines',response.data.message))
       } else {
          dispatch(RequestedContentActions.setDisciplines([{_id:'all',name:'All'}]));
          dispatch(setResourceElements('createResourceDisciplines',[]))
       }
       } catch (err) {
          dispatch(RequestedContentActions.setDisciplines([{_id:'all',name:'All'}]));
          dispatch(setResourceElements('createResourceDisciplines',[]))
          console.log("error",err);
       }
    }
  }
 
 export function getAllContentTypes(){
    return async (dispatch, getState)=> {
       try {
          let response = await customHttp.get("/api/category/");
          let res = JSON.parse(JSON.stringify(response.data.message));
          if(Array.isArray(response.data.message)){
             res.push({_id:'all',name:'All'})
             dispatch(RequestedContentActions.setContentTypes(res.reverse()));
             dispatch(setResourceElements('createResourceContentTypes',response.data.message))
          } else {
             dispatch(RequestedContentActions.setContentTypes([{_id:'all',name:'All'}]));
             dispatch(setResourceElements('createResourceContentTypes',[]))
          }
       } catch (err) {
          dispatch(RequestedContentActions.setContentTypes([{_id:'all',name:'All'}]));
          dispatch(setResourceElements('createResourceContentTypes',[]))
          console.log("error",err)
       }
    }
  }

  export function createResource(e) {
   return async (dispatch ,getState)=> {
      e.preventDefault();
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      var data = new FormData();
      // var tagsArray=[];
      var tags=(getState().DltRequestedContentState.resource.tags).split(",");
      data.append('displayName', getState().DltRequestedContentState.resource.name);
      data.append('branches', [JSON.parse(getState().DltRequestedContentState.resource.discipline)._id]);
      data.append('categories', [JSON.parse(getState().DltRequestedContentState.resource.contentType)._id]);
      data.append('file', getState().DltRequestedContentState.resource.file);
      data.append('user_id',userDetails._id );
      data.append('userEmail',userDetails.email );
      data.append('belongsTo',userDetails.belongsTo);
      
      for(var i=0;i<tags.length;i++){
         data.append('tags[]',tags[i]);
      }
      dispatch(setResourceElements('showResourceCreateLoader',true))
      try {
         let response = await customHttp.post("/api/resourceUri/upload/",data);
         // let res = response.data.message;

         dispatch(setResourceElements('successSnackbarMessage','Content created successfully'));
         snackbar.successSnackbar();
         dispatch(setResourceElements('showResourceCreateLoader',false))
         dispatch(setResourceElements('resource',{name:'',discipline:'',contentType:'',file:{},tags:""}))
         document.getElementById('resourceFileUpload').value = ''
         document.getElementById('publishPopup').style.display='none';
         // dispatch(getAllResources(getState().DltRequestedContentState.selectedDiscipline, getState().DltRequestedContentState.selectedContentType))
      } catch (err) {
        
         dispatch(setResourceElements('errorSnackbarMessage','Error! Try again..'));
         snackbar.errorSnackbar();  
         dispatch(setResourceElements('showResourceCreateLoader',false))
         console.log("error",err)
      }
   }
}

export function requestedContentStatus(status,data) {
   // var userDetails=JSON.parse(sessionStorage.getItem("userData"))
 return async (dispatch ,getState)=> {
    try {
       var obj={};
       obj._id = data._id;
       obj.status = status;
       let response = await customHttp.put("/api/requestedCapsule/status",obj)
       dispatch(getdltRequestedContent())
      //  let res = response.data.message;
       dispatch(RequestedContentActions.setRequestedContentStatus(status))
       dispatch(setResourceElements('successSnackbarMessage','Status updated successfully'));
       snackbar.successSnackbar();
       
    } catch (err) {
       dispatch(setResourceElements('errorSnackbarMessage','Error! Try again..'));
       snackbar.errorSnackbar();  
       console.log("error",err)
    }
 }
}

export function setResourceElements(name,content) {
   return (dispatch ,getState)=> {
      dispatch(RequestedContentActions.setResourceElements(name,content))
   }
}

//status progress

export function statusProgressUpdate(data,obj){
   return (dispatch ,getState)=> {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      var object1={};
      object1._id=data._id;    
      object1.email=userDetails.email;
      object1.status=obj.status;  
      // axios.put('/api/requestedCapsule/status',object1)
      var url = '/api/requestedCapsule/status' ;
      customHttp.put(url , object1)
         .then(response=>{
         // var content=response.data.message;
         // console.log("status response",content);
         dispatch(RequestedContentActions.setBooleanValue("successSnackbarMessage","Submitted successfully"))
         snackbar.successSnackbar();
         dispatch(getdltRequestedContent())            
      })
      .catch(error => {
         dispatch(RequestedContentActions.setBooleanValue("errorSnackbarMessage","not submitted successfully"))
         snackbar.errorSnackbar();
         console.log("error",error);      
      });
   
   }
}
