import React, {useEffect } from 'react';
import './Resources.scss'
import { connect } from "react-redux";
import * as resourceContainer from "../../container/actions/Resources/action";
import * as requestResourceContainer from '../../container/actions/RequestResource/action'
import { bindActionCreators } from 'redux';
// import customHttp from '../../packages/CustomHttp/';

function RequestResource (props) {
  // const [disciplines, setDisciplines] = useState([]);
  // const [contentTypes, setContentTypes] = useState([]);
  // var resourceObj = {description:'',discipline:'',contentType:''};
  //const [resource, setResource] = useState(resourceObj);
  //const [showResourceRequestSubmitLoader, setShowResourceRequestSubmitLoader] = useState(false)
  
  useEffect( () => {   
    // console.log("hello",props);
    // getAllDisciplines();
    // getAllContentTypes();
    props.getAllDisciplines();
    props.getAllContentTypes()
  },[]);

  // onload function end
  // var getAllDisciplines = async () => {
  //   try {
  //     let response = await customHttp.get("/api/branch/");
  //     let res = response.data.message;
  //     if(Array.isArray(res)){
  //       setDisciplines(res.reverse());
  //     } else {
  //       setDisciplines([])
  //     }
  //   } catch (err) {
  //     setDisciplines([])
  //     console.log("error",err);
  //   }
  // }

  // var getAllContentTypes = async () => {
  //   try {
  //     let response = await customHttp.get("/api/category/");
  //     let res = response.data.message;
  //     if(Array.isArray(res)){
  //       setContentTypes(res.reverse());
  //     } else {
  //       setContentTypes([])
  //     }
  //   } catch (err) {
  //     setContentTypes([])
  //     console.log("error",err)
  //   }
  // }

  // var handleRequestResourceChange=(e)=>{
  //     setResource({...resource, [e.target.name]:e.target.value});
  // }
  
  // var handleCreateResourceSubmit = async (e) =>{
  //   e.preventDefault();
  //   const userData=JSON.parse(sessionStorage.getItem('userData'));
  //   console.log("object to submit",userData);
  //   var data = {};
  //   data.published = true;
  //   data.category = JSON.parse(resource.contentType)._id;
  //   data.branch = JSON.parse(resource.discipline)._id;
  //   data.description = resource.description;
  //   data.userId = userData._id;
  //   data.belongsTo = userData.belongsTo;
  //   data.email = userData.email;
  //   setShowResourceRequestSubmitLoader(true)
  //   try {
  //     let response = await customHttp.put('/api/requestedCapsule/createRequestedCapsule',data);
  //     setResource(resourceObj);
  //     props.displayAlert('success','Content requested successfully')
  //     console.log("success",response);
  //     setShowResourceRequestSubmitLoader(false)
  //   } catch (err) {
  //     props.displayAlert('error','Error! Try again..')
  //     console.log("error",err);
  //     setShowResourceRequestSubmitLoader(false)
  //   }
  // }

  var handleCreateResourceSubmit=(e,props)=>{
    e.preventDefault();
    props.handleCreateResourceSubmit(e,props);
  }
  return (
    <div>
      <div className="col-md-6 col-md-offset-3 nj-card">
        <div className="row">
          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4>Request new content</h4>
            </div>
          </div>
          <div className="col-md-12">
            <form name="resourceForm" onSubmit={(e)=>handleCreateResourceSubmit(e,props)}>
              <div className="nj-popup-form-wrap">
                <p className="popup-content-label">Select Discipline</p>
                <div className="nj-form-select">
                  <select name="discipline" value={props.requestResourceState.resourceObject.discipline} style={{textTransform:'capitalize'}} onChange={props.handleRequestResourceChange} autoComplete="off" required>
                    <option value="">Pick a discipline</option>
                    {props.resourceState.createResourceDisciplines.map((discipline,index)=>{
                        if(discipline.name !== "All"){
                        return (<option key={index} value={JSON.stringify(discipline)}>{discipline.name}</option>)
                        }
                    })}
                  </select>
                </div>
              </div>
              <div className="nj-popup-form-wrap">
                <p className="popup-content-label">Select content Type</p>
                <div className="nj-form-select">
                  <select name="contentType" value={props.requestResourceState.resourceObject.contentType} style={{textTransform:'capitalize'}} onChange={props.handleRequestResourceChange} autoComplete="off" required>
                    <option value="">Pick a content type</option>
                    {props.resourceState.createResourceContentTypes.map((contentType,index)=>{
                        return (<option key={index} value={JSON.stringify(contentType)}>{contentType.name}</option>)
                    })}
                  </select>
                </div>
              </div>
              <div className="nj-popup-form-wrap">
                <p className="popup-content-label">Description</p>
                <textarea className="vs-input--input" type="text" name="description" value={props.requestResourceState.resourceObject.description} rows="4" onChange={props.handleRequestResourceChange} placeholder="Enter description here.." autoComplete="off" required></textarea>
              </div>
              { props.requestResourceState.showResourceRequestSubmitLoader ? <img className="pull-right" src="/img/loaders/spinningwheel.gif" height="50" width="50" alt="" style={{marginTop:'-5px'}} /> 
                  :<button type="submit" className="nj-flow-button pull-right" style={{margin:5}}>Submit</button>}
              <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={props.toggleRequestResource} style={{margin:5}}>Back</button></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
  resourceState: state.resourceState,
  requestResourceState: state.requestResourceState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllContentTypes: resourceContainer.getAllContentTypes,
  getAllDisciplines: resourceContainer.getAllDisciplines,
  handleRequestResourceChange: requestResourceContainer.handleRequestResourceChange,
  handleCreateResourceSubmit: requestResourceContainer.handleCreateResourceSubmit,
  toggleRequestResource: resourceContainer.toggleRequestResource
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(RequestResource);
