/* Let CRA handle linting for sample app */
import React, { Component } from 'react';
import Spinner from 'react-spinner';
import classNames from 'classnames';

import AccCore from 'opentok-accelerator-core';
import 'opentok-solutions-css';

// import logo from './logo.svg';

import './LiveStream.css';

import customHttp from '../../packages/CustomHttp';

let otCore;
const otCoreOptions = {
  credentials: {
    apiKey: "",
    sessionId: "",
    token: "",
  },
  // A container can either be a query selector or an HTML Element
  streamContainers(pubSub, type, data, stream) {
    return {
      publisher: {
        camera: '#cameraPublisherContainer',
        screen: '#screenPublisherContainer',
      },
      subscriber: {
        camera: '#cameraSubscriberContainer',
        screen: '#screenSubscriberContainer',
      },
    }[pubSub][type];
  },
  controlsContainer: '#controls',
  packages: [ 'screenSharing', 'annotation'],
  communication: {
    callProperties: null, // Using default
  },
  textChat: {
    name: ['David', 'Paul', 'Emma', 'George', 'Amanda'][Math.random() * 5 | 0], // eslint-disable-line no-bitwise
    waitingMessage: 'Messages will be delivered when other users arrive',
    container: '#chat',
  },
  screenSharing: {
    extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
    annotation: true,
    externalWindow: false,
    dev: true,
    screenProperties: {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false,
      style: {
        buttonDisplayMode: 'off',
      },
      videoSource: 'window',
      fitMode: 'contain' // Using default
    },
  },
  annotation: {
    absoluteParent: {
      publisher: '.App-video-container',
      subscriber: '.App-video-container'
    }
  },
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;
  return {
    controlClass: classNames('App-control-container', { hidden: !active }),
    localAudioClass: classNames('ots-video-control circle audio', { hidden: !active, muted: !localAudioEnabled }),
    localVideoClass: classNames('ots-video-control circle video', { hidden: !active, muted: !localVideoEnabled }),
    localCallClass: classNames('ots-video-control circle end-call', { hidden: !active }),
    cameraPublisherClass: classNames('video-container', { hidden: !active, small: !!activeCameraSubscribers || screenshareActive, left: screenshareActive }),
    screenPublisherClass: classNames('video-container', { hidden: !active || !sharingScreen }),
    cameraSubscriberClass: classNames('video-container', { hidden: !active || !activeCameraSubscribers },
      { 'active-gt2': activeCameraSubscribersGt2 && !screenshareActive },
      { 'active-odd': activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames('video-container', { hidden: !viewingSharedScreen || !active }),
  };
};

const connectingMask = () =>
  <div className="App-mask" style={{textAlign:"center"}}>
    <Spinner />
    <div className="message with-spinner" style={{marginTop:'-70px',fontWeight:'500'}}>
      <span><img src="/img/loaders/spinningwheel.gif" alt="" height="60" width="60" /></span>
      Connecting...</div>
  </div>;

const startCallMask = start =>
  <div className="App-mask">
    <div className="hidden-xs">
      <img src="/img/liveclassroom.png" width="300" alt="" style={{marginTop:'-70px'}} />
    </div>
    <button className="nj-flow-button clickable" style={{marginTop:10}} onClick={()=>start(true)}>Click to Start Call </button>
  </div>;

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
    };
    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
  }

  async componentDidMount() {
    await this.getSessionDeatails();
    this.enableLiveClassRoom(false);
    otCore = new AccCore(otCoreOptions);
    otCore.connect().then(() => this.setState({ connected: true }));
    const events = [
      'subscribeToCamera',
      'unsubscribeFromCamera',
      'subscribeToScreen',
      'unsubscribeFromScreen',
      'startScreenShare',
      'endScreenShare',
    ];

    events.forEach(event => otCore.on(event, ({ publishers, subscribers, meta }) => {
      this.setState({ publishers, subscribers, meta });
    }));
  }

  startCall() {
    otCore.startCall()
      .then(({ publishers, subscribers, meta, streamId }) => {
        // console.log("stream on startCall",streamId)
        this.setState({ publishers, subscribers, meta, active: true });
      }).catch(error => console.log(error));
  }

  endCall() {
    otCore.endCall();
    this.setState({ active: false });
    this.enableLiveClassRoom(false);
  }

  toggleLocalAudio() {
    otCore.toggleLocalAudio(!this.state.localAudioEnabled);
    this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
  }

  toggleLocalVideo() {
    otCore.toggleLocalVideo(!this.state.localVideoEnabled);
    this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
  }

  getSessionDeatails= async () => {
    var liveCourse=JSON.parse(localStorage.getItem('liveCourse'));
    var url = '/api/open-tok/courseId/'+liveCourse._id;
    return customHttp.get(url)
        .then(response => {
          // console.log("res",response)
          if(response.data.message){
            let res=response.data.message;
            otCoreOptions.credentials.apiKey=res.apiKey;
            otCoreOptions.credentials.sessionId=res.sessionId;
            otCoreOptions.credentials.token=res.token;

          }

        }).catch(error => {
          console.log("err",error)

        })
    
  }

  //function to make isLive in course true.
  enableLiveClassRoom=async(isEnabled)=>{
    // const liveCourse = JSON.parse(localStorage.getItem('liveCourse'));
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    try {
      if(userData && userData.type!=='0' && userData.type!==0){
        //const response = await customHttp.put('/api/');
      }
      if(isEnabled){
        this.startCall()
        //prevent tab switching
        const navbarElement = document.getElementsByClassName('navbar-avatar');
        for(var i=0;i<navbarElement.length;i++){
          navbarElement[i].style.pointerEvents = 'none';
        }
        document.getElementsByClassName('vs-sidebar-parent')[0].style.pointerEvents = 'none';  
      } else {
        // console.log("called with",isEnabled)
        //prevent tab switching
        const navbarElement = document.getElementsByClassName('navbar-avatar');
        for(var i=0;i<navbarElement.length;i++){
          navbarElement[i].style.pointerEvents = 'all';
        }
        document.getElementsByClassName('vs-sidebar-parent')[0].style.pointerEvents = 'all';
      }
    } catch(err) {
      console.log('error',err)
    }
  }

  render() {
    const { connected, active } = this.state;
    const {
      localAudioClass,
      localVideoClass,
      localCallClass,
      controlClass,
      cameraPublisherClass,
      screenPublisherClass,
      cameraSubscriberClass,
      screenSubscriberClass,
    } = containerClasses(this.state);

    return (
      <div className="App">
        {/* <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>OpenTok Accelerator Core</h1>
        </div> */}
        <div className="App-main nj-card">
          <div className="App-video-container">
            { !connected && connectingMask() }
            { connected && !active && startCallMask(this.enableLiveClassRoom)}
            <div id="cameraPublisherContainer" className={cameraPublisherClass} />
            <div id="screenPublisherContainer" className={screenPublisherClass} />
            <div id="cameraSubscriberContainer" className={cameraSubscriberClass} />
            <div id="screenSubscriberContainer" className={screenSubscriberClass} />
          </div>
          <div id="controls" className={controlClass}>
            <div className={localAudioClass} onClick={this.toggleLocalAudio} />
            <div className={localVideoClass} onClick={this.toggleLocalVideo} />
            <div className={localCallClass} onClick={this.endCall} />
          </div>
          <div id="chat" className="App-chat-container" />
        </div>
      </div>
    );
  }
}

export default App;
