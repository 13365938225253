import React, { Component} from 'react';
import './Analytics.scss';
import ReactApexChart from 'react-apexcharts'
import customHttp from '../../packages/CustomHttp';
// import customStorage from '../../packages/CustomStorage';

class VideoUsage extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      dataType:'Demo',
      selectedResource:{}, 
      series: [{
        data: [
        ]
      }],
      options: {
        annotations: {
          yaxis: [{
            y: 30,
            borderColor: '#999',
            label: {
              show: true,
              text: 'Seconds',
              style: {
                color: "#fff",
                background: '#00E396'
              }
            }
          }],
          xaxis: [{
            borderColor: '#999',
            yAxisIndex: 0,
            label: {
              show: true,
              text: 'Users',
              style: {
                color: "#fff",
                background: '#775DD0'
              }
            }
          }]
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'numeric',
          min: 1,
          tickAmount: 30,
          tooltip: {
            enabled: true,
            formatter: function(val, opts) {
              return val + " Seconds"
            },
            offsetY: 0,
            style: {
              fontSize: '14px',
              fontFamily: 0,
            },
        }
        },
        yaxis:{
          show: true,
          type: 'numeric',
          decimalsInFloat: 10,
        },
        fill: {
          type: 'gradient',
          // colors: ['#7367f0'],
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.8,
            stops: [0, 100]
          },
          pattern: {
            width: 2,
            height: 2,
            strokeWidth: 9,
        }       
        },
        title: {
          //text: "Seconds",
         
      },
      tooltip: {
        x: {
            show: true,
            formatter: function(val, opts) {
              return val + " seconds"
            },
        }
     },noData: {
      text: 'Loading...'
    },stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      // colors: ['#7367f0'],
      width: 2,
      dashArray: 0,      
  }
    },
    resourseArray:[],
    initialLoader:false,
    resourceUsageLoader:false,   
    showGraph:true
  }
}

  componentDidMount(){
    this.setDataType('Demo');
    // console.log("props",this.props)
  }

  setDataType=(type)=>{
    // console.log("called");
    this.setState({dataType:type})
    if(type==='Demo'){
      const resourseDemoArray = [
        {_id:1,displayName:'Machine Learning',name:'Machine Learning'},
        {_id:2,displayName:'Design of Machine Elements',name:'Design of Machine Elements'},
        {_id:3,displayName:'Applied Thermodynamics',name:'Applied Thermodynamics'},
        {_id:4,displayName:'Design of Machine Elements',name:'Design of Machine Elements'}
      ]
      this.setState({resourseArray:resourseDemoArray},()=>this.setDemoResourceGraphData())
    } else {
      // console.log("setDataType called")
      this.fetchAllResources();
    }
  }

  updateData (timeline) {
    this.setState({
      selection: timeline
    })
  }

  handleChangeResources=(e)=>{
    this.setState({selectedResource:JSON.parse(e.target.value)},()=>{console.log("this.state.selectedResource",this.state.selectedResource)})
    if(this.state.dataType==='Demo'){
      this.setDemoResourceGraphData();
    } else {
      this.getResourceGraphData(JSON.parse(e.target.value));
    }
  }

  fetchAllResources=()=>{
    var url = '/api/resourceUri/fetchOnlyResourceVideos';
    customHttp.get(url)
    .then(response => {
      if(response.data.message && response.data.message.length>0){
        if(Object.keys(this.state.selectedResource).length<=0){
          this.setState({selectedResource:response.data.message[0]});
          this.getResourceGraphData(response.data.message[0])
        } else {
          this.getResourceGraphData(this.state.selectedResource)
        }
        this.setState({resourseArray:response.data.message,showGraph:true})
      }else{
        this.setState({resourseArray:[],showGraph:false})
      }
      
    })
    .catch(error => {
      this.setState({resourseArray:[],showGraph:false})
      console.log("failed", error);
    });
  }

  getResourceGraphData=(obj)=>{
    // console.log("resource",obj);
    // console.log("this.props",this.props.fromDate,this.props.toDate, "obj",obj);
    var n = new Date(this.props.fromDate);
    var currentDate = n.getDate();
    if(currentDate<10){
      currentDate='0'+currentDate;
    }
    var currentMonth = n.getMonth() + 1; //Months are zero based
    if(currentMonth<10){
      currentMonth='0'+currentMonth;
    }
    var currentYear = n.getFullYear();
    var fromDate = currentYear + "-" + currentMonth + "-" + currentDate;  // date conversion dd/mm/yyyy to yyyy-mm-dd

    var p = new Date(this.props.toDate);
    var tempDate = p.getDate();
    if(tempDate<10){
      tempDate='0'+tempDate;
    }
    var tempMonth = p.getMonth() + 1;
    if(tempMonth<10){
      tempMonth='0'+tempMonth;
    }
    var tempYear = p.getFullYear();
    var toDate = tempYear + "-" + tempMonth + "-" + tempDate;   // date conversion dd/mm/yyyy
    //this.setState({resourceUsageLoader:true})
    var url = `/api/videoViewCount/resourceId/${obj._id}/fromDate/${fromDate}/toDate/${toDate}`;
    customHttp.get(url)
      .then(response => {
        var series=[]
        var data=[]
        var options=JSON.parse(JSON.stringify(this.state.options))
        if(response.data.message.length>0){ 
          // console.log("lenth",response.data.message.length)      
          for (var i = 0; i < response.data.message.length; i++) {
            // y += parseInt(Math.round(Math.random() * 100 + 5));
            data.push([response.data.message[i]._id,response.data.message[i].count]); 
          }
          series.push({name:'Users',data})
          this.setState({resourceUsageLoader:false,series:series,showGraph:true})

          if(response.data.message.length<30){
           options.xaxis.tickAmount=response.data.message.length-1;
           this.setState({options})
          }else if(response.data.message.length>1500){
            options.xaxis.tickAmount=20;
            this.setState({options})
          }else if(response.data.message.length<1500){
            options.xaxis.tickAmount=30;
            this.setState({options})
          }
        }else{
          series.push({name:'Users',data})
         options.xaxis.tickAmount=30;
         this.setState({resourceUsageLoader:false,series:series,showGraph:false,options})
        }
        
      })
      .catch(error => {
        var series=[]
        var data=[]
        series.push({name:'Users',data})
        this.setState({resourceUsageLoader:false,series:series,showGraph:false})
        console.log("failed", error);
      });
  }

  setDemoResourceGraphData=()=>{
    const demoData = [[1,20],[2,17],[3,14],[4,16],[5,18]];
    const demoSeries = [{name:'Users',data:demoData}];
    let options=JSON.parse(JSON.stringify(this.state.options));
    options.xaxis.tickAmount = 4;
    this.setState({series:demoSeries,options,showGraph:true})
  }
  render(){
    return(
      <div className="add-on-load-animation">
      <div className="col-md-12" style={{marginTop:"10px"}}>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
            <div className="col-md-12 col-sm-12 nj-card" >
              {!this.state.initialLoader ?
                <div>
                  {this.state.resourseArray.length>0 ?
                    <div>
                      <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                        <div className="col-md-12 col-sm-12">
                          <div className="col-md-3 col-xs-12" style={{marginTop:"20px"}}>
                          <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Videos:</label>
                            <div className="nj-form-select">
                              <select className="vs-input--input" onChange={this.handleChangeResources} style={{textTransform:'capitalize'}}>
                                {/*<option hidden='true' value=''></option>*/}
                                {this.state.resourseArray.map((obj,i) =>{
                                  return <option key={obj._id} value={JSON.stringify(obj)}>{obj.displayName}</option>
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {!this.state.resourceUsageLoader ?
                          <div>
                            {this.state.showGraph ?
                              <div id="chart">
                                {this.state.series[0].data.length>0 &&
                                <div id="chart-timeline">
                                  <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={450} />
                                  <p style={{textAlign:'center',marginBottom: '20px'}}>Seconds</p>
                                </div>}
                              </div>
                            :
                              <div className="col-md-12">
                                <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                              </div>
                            }
                          </div>
                        :
                          <div className="row" style={{minHeight:"54.3vh"}}>
                              <div className="col-md-12 aligh-user-loader-image-center" >
                                <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                              </div>
                          </div>
                        }
                      </div>
                    </div>
                  :
                    <div className="col-md-12">
                      <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                    </div>
                  }
                </div>
              :
                  <div className="row" style={{minHeight:"54.3vh"}}>
                    <div className="col-md-12 aligh-user-loader-image-center" >
                      <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
export default VideoUsage;
