import React, {Component} from 'react';
import customHttp from '../CustomHttp/';
// function videoPlayer (props) {
//   //const [link, setLink] = useState(props.link);


// // var onpause=()=>{
// //   var vid = document.getElementById("playVideo");
// //   // console.log("video paused",vid.played.length,vid.duration,vid.currentTime,"Start: " + vid.played.start(0) + " End: " + vid.played.end(),vid.buffered)
// //   for(var i=0;i<vid.played.length;i++){
// //     console.log("video paused played",vid.duration,vid.currentTime,"Start: " + vid.played.start(i) + " End: " + vid.played.end(i),vid.buffered)
// //   }

// // }
//   return (
//     <div>
//         <video id="playVideo" width="512" height="380" controls controlsList="nodownload" style={{width:'100%',outline:'none'}} autoPlay onPause={onpause}>
//             <source src={"/"+props.link} type="video/mp4" />
//         </video>
//     </div>
//   );
// }
//  export default videoPlayer;


 class VideoPlayer extends Component {
  constructor(props){
    super(props);
    this.state = {
    }   
  }
  componentDidMount(){
    var userDetails =JSON.parse(sessionStorage.getItem("userData"));
    document.getElementById("playVideo").currentTime = this.props.currenttime;
    window.addEventListener('beforeunload', (event) => {   
      event.preventDefault();
      // event.returnValue = ''; 
      var vid = document.getElementById("playVideo");
      var object={};
      object.played=[];
      object.buffered=[];
      object.currentTime=vid.currentTime;
      object.duration=vid.duration;
      object.resourceId=this.props.resourceId;
      object.userId=userDetails._id;
      object.adminId=userDetails.adminId;
      object.belongsTo=userDetails.belongsTo;

    for(var i=0;i<vid.played.length;i++){
      object.played.push({"start":vid.played.start(i),"end":vid.played.end(i)})
    }
    for(var k=0;k<vid.buffered.length;k++){
      object.buffered.push({"start":vid.buffered.start(k),"end":vid.buffered.end(k)})
    }
    if(userDetails.type===0){
      if(vid.duration !== null || vid.duration !== undefined ){
        this.saveVideoDetails(object);
      }
      
    }  
    });
  };

  saveVideoDetails=(obj)=>{
    
     if(!isNaN(obj.duration)){
      customHttp.put('/api/videoViewCount/save',obj)
      .then(res => { 
        // console.log("res", res)  
      })
      .catch((error)=>{
        console.log("error", error)
      });
     }
   
  }
  componentWillUnmount(){
    var userDetails =JSON.parse(sessionStorage.getItem("userData"));
    var vid = document.getElementById("playVideo");
    var object={};
    object.played=[];
    object.buffered=[];
    object.currentTime=vid.currentTime;
    object.duration=vid.duration;
    object.resourceId=this.props.resourceId;
    object.userId=userDetails._id;
    object.adminId=userDetails.adminId;
    object.belongsTo=userDetails.belongsTo;
    
   for(var i=0;i<vid.played.length;i++){
    object.played.push({"start":vid.played.start(i),"end":vid.played.end(i)})
  }
  for(var k=0;k<vid.buffered.length;k++){
    object.buffered.push({"start":vid.buffered.start(k),"end":vid.buffered.end(k)})
  }
  if(userDetails.type===0){
    if(vid.duration !== null || vid.duration !== undefined ){
      this.saveVideoDetails(object);
    }
  }

  }
  
   
  
  render() {
    return (
      <div>
        <video id="playVideo" width="512" height="380" controls controlsList="nodownload" loop={true} autoPlay style={{width:'100%',outline:'none'}} >
            <source src={"/"+this.props.link} type="video/mp4" />
        </video>
     </div>
    )
  }
}

export default VideoPlayer;