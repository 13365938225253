var customStorage = {
  localStorageSet:(label,value)=>{
  return localStorage.setItem(label,JSON.stringify(value))
  },
  localStorageGet:(label)=>{
  return JSON.parse(localStorage.getItem(label))
  },
  sessionStorageSet:(label,value)=>{
  return sessionStorage.setItem(label,JSON.stringify(value))
  },
  sessionStorageGet:(label)=>{
  return JSON.parse(sessionStorage.getItem(label))
  }
  }
  
  export default customStorage;