import * as SettingsActions from "./index";
import customHttp from '../../../packages/CustomHttp/';
import snackbar from '../../../packages/Snackbar/';


export  function fetchAllSubscriptions (){
     return async (dispatch ,getState)=> {
        var userDetails=JSON.parse(sessionStorage.getItem("userData"))
         try {
            let response = await customHttp.get('/api/subscription/userId/'+userDetails.belongsTo);
            dispatch(SettingsActions.getAllSubscriptions(response.data.message.reverse()));
            dispatch(SettingsActions.setBooleanValue("availableSubscriptionLoader",true))

         } catch (err) {
            dispatch(SettingsActions.getAllSubscriptions([]));
            dispatch(SettingsActions.setBooleanValue("availableSubscriptionLoader",true))
            console.log("error",err)
         }
     }
  }


  export  function fetchAllConcurrentuser (){
    return async (dispatch ,getState)=> {
       var userDetails=JSON.parse(sessionStorage.getItem("userData"))
        try {
           let response = await customHttp.get('/api/concurrentUser/userId/'+userDetails.belongsTo);
           dispatch(SettingsActions.getAllConcurrentUsers(response.data.message.reverse()));

        } catch (err) {
           dispatch(SettingsActions.getAllConcurrentUsers([]));
           console.log("error",err)
        }
    }
 }


 export function submitConcurrentUserPopup(e){
    e.preventDefault()
    return async (dispatch ,getState)=> {
       var userDetails=JSON.parse(sessionStorage.getItem("userData"))
       var finalObject=getState().settingsState;

       var fromDateInSec = new Date(finalObject.fromDate);
       var tempDate = fromDateInSec.getDate();
       var tempMonth = fromDateInSec.getMonth()+1;
       var tempYear = fromDateInSec.toString().substring(11,15);
       if(tempMonth < 10){
            tempMonth = "0"+tempMonth
       }
       else{
            tempMonth = tempMonth
       }

       if(tempDate < 10){
         tempDate = "0"+tempDate
         }
         else{
            tempDate = tempDate
         }
       var fromDate = tempYear + "-" + tempMonth + "-" + tempDate;
 
       var toDateInSec = new Date(finalObject.toDate);
       var tempToDate = toDateInSec.getDate();
       var tempToMonth = toDateInSec.getMonth()+1;
       var tempToYear = toDateInSec.toString().substring(11,15);

       if(tempToMonth < 10){
         tempToMonth = "0"+tempToMonth
         }
         else{
            tempToMonth = tempToMonth
         }

         if(tempToDate < 10){
            tempToDate = "0"+tempToDate
         }
         else{
            tempToDate = tempToDate
         }
       var toDate = tempToYear + "-" + tempToMonth + "-" + tempToDate;

     
 
       var object={};
       object.subscriptionLimit=finalObject.applicationLimit;
       object.defaultLimit=finalObject.selectedapplicationData.defaultLimit;
       object.fromDate=fromDate;
       object.toDate=toDate ;
       object.belongsTo=userDetails.belongsTo
       object.subscription=finalObject.selectedapplicationData.subscription;
       object.courseId = finalObject.selectedapplicationData.courseId;
        
 
          dispatch(SettingsActions.setBooleanValue("showConcurrentUserUpdateLoader",false))
          try {
             let response = await customHttp.put('/api/subscription/setLimit',object);
             dispatch(SettingsActions.setBooleanValue("showConcurrentUserUpdateLoader",true))
             document.getElementById("editCoursePopup").style.display='none'
             dispatch(fetchAllSubscriptions())
             dispatch(fetchAllConcurrentuser())
             dispatch(SettingsActions.setBooleanValue("successSnackbarMessage","Concurrent users updated successfully"))
             snackbar.successSnackbar();
            
             dispatch(SettingsActions.initiateOnloadFunction("selectedapplicationData",""))
             dispatch(SettingsActions.initiateOnloadFunction("applicationLimit",""))
             dispatch(SettingsActions.initiateOnloadFunction("fromDate",""))
             dispatch(SettingsActions.initiateOnloadFunction("toDate",""))
             
           } catch (err) {
             console.log("error",err)
             dispatch(SettingsActions.setBooleanValue("showConcurrentUserUpdateLoader",true))
             dispatch(SettingsActions.setBooleanValue("errorSnackbarMessage","Error try again"))
              snackbar.errorSnackbar();
            //   dispatch(SettingsActions.initiateOnloadFunction("selectedapplicationData",""))
            //   dispatch(SettingsActions.initiateOnloadFunction("applicationLimit",""))
            //   dispatch(SettingsActions.initiateOnloadFunction("fromDate",""))
            //   dispatch(SettingsActions.initiateOnloadFunction("toDate",""))
            
           }
      
    }
 }

 export function submitSlots(i,e,data){
    e.preventDefault()
    return async (dispatch ,getState)=> {
       var userDetails=JSON.parse(sessionStorage.getItem("userData"))
       var finalObject=getState().settingsState;

       dispatch(SettingsActions.setBooleanValue("showEditButton",true))
        document.getElementById("edit"+i).style.display = "inline-block"
        document.getElementById("save"+i).style.display = "none"
        document.getElementById("editInputField"+i).style.display = "inline-block"
        document.getElementById("saveInputField"+i).style.display = "none"

       var obj = {};
         obj.subscriptionName = data.subscription;
         obj.subscriptionLimit = finalObject.slots;
         obj.belongsTo=userDetails.belongsTo;
         obj.courseId=data.courseId;
        
        console.log("obavvava",obj)
         
        if( obj.subscriptionLimit !== "" && obj.subscriptionLimit !== null){
          try {
             let response = await customHttp.put('/api/concurrentUser/setLimit',obj);
           
             dispatch(fetchAllSubscriptions())
             dispatch(fetchAllConcurrentuser())
             dispatch(SettingsActions.setBooleanValue("successSnackbarMessage","Slots/users users updated successfully"))
             snackbar.successSnackbar();
             dispatch(SettingsActions.setBooleanValue("editOpen",false))
             dispatch(SettingsActions.initiateOnloadFunction("slots",""))
             
           } catch (err) {
             console.log("error",err)
             dispatch(SettingsActions.setBooleanValue("editOpen",false))
             dispatch(SettingsActions.setBooleanValue("errorSnackbarMessage","Error try again"))
              snackbar.errorSnackbar();
           }
        }
        else{
            dispatch(SettingsActions.setBooleanValue("editOpen",false))
            dispatch(SettingsActions.setBooleanValue("errorSnackbarMessage","Error try again"))
            snackbar.errorSnackbar();
            }
    }
 }