// import axios from "axios";
import * as userRatingsActions from "./index";
import customHttpUserside from '../../../packages/CustomHttpUserside/';
// import snackbar from '../../../packages/Snackbar/';

export function setUserRatingsElements(name,content) {
   return (dispatch ,getState)=> {
      dispatch(userRatingsActions.setUserRatingsElements(name,content))
   }
}

export function showUserRatings(data,type) {
   return async (dispatch ,getState)=> {
      const id = data._id;
      var url = `/api/ratings/getRatingsByResourceIdUserside/${id}`;
      if(type==='course'){
         url = `/api/ratings/getRatingsByCourseIdUserside/${id}`;
      }
      try {
         // let response = await customHttp.get(`/api/ratings/getRatings/${id}`);
         let response = await customHttpUserside.get(url);
         let res = response.data.message;
         dispatch(setUserRatingsElements('userRatings',res));
         dispatch(showUserReviews(data,type))
         // console.log("rating response",res)
      } catch (err) {
         console.log("error",err)
      }
   }
}

export function showUserReviews(data,type) {
   return async (dispatch ,getState)=> {
      const id = data._id;
      var url = `/api/ratings/getAllRatingsByResourceIdUserside/${id}`;
      if(type==='course'){
         url = `/api/ratings/getAllRatingsByCourseIdUserside/${id}`;
      }

      try {
         let response = await customHttpUserside.get(url);
         let res = response.data.message;
         dispatch(setUserRatingsElements('userReviews',res));
         document.getElementById('userRatingsPopup').style.display='block';
         // console.log("review response",res)
      } catch (err) {
         console.log("error",err)
      }
   }
}
