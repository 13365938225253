import React, {useEffect } from 'react';
import { connect } from "react-redux";
import * as bookSlotContainer from "../../container/actions/BookSlot/action";
import { bindActionCreators } from 'redux';

function Applications (props) {
  useEffect( () => {   
    // console.log("hello",props);
    props.onLoadFunction()
    // console.log('course',props.bookSlotState.userCourse)
  },[]);
  // onload function end
 
  return (
    <div className="add-on-load-animation">
      <div id="successSnackbar">{props.bookSlotState.successAlertMessage}</div>
      <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.bookSlotState.errorAlertMessage}</div>
      <div className="col-md-12 nj-card" >
        <div className="row">
          <div className="nj-card__header">
            <div className="nj-card__title">
              <h4>Applications</h4>
            </div>
            <div>
              <button className="nj-flow-button " type="button" onClick={()=>props.handleRouteTo('courses',props.history)} style={{marginBottom:"10px"}}>Back</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4 selectall-margin" style={{marginTop:"10px"}}>
                <label className="checkbox coloured font_family_montserrat font_wight_500" /* style={props.AppStore.categoryName=== "All" &&  props.AppStore.applicationName.length===0 &&  props.AppStore.applicationArray.length>0 ? {display:"block"} :{display:"none"}} */>
                  <input type="checkbox"/*  indeterminate={(props.AppStore.selected.length > 0 && props.AppStore.selected.length < props.AppStore.applicationArray.length).toString()} */  checked={props.bookSlotState.userApplications.length === props.bookSlotState.selected.length}  onChange={(event)=>props.handleSelectAllClick(event)} />
                  <span className="checkbox-material"><span className="check"></span></span> Select All
                </label>
              </div>
            <div className="table apps_alignment">
              {props.bookSlotState.userApplications && props.bookSlotState.userApplications.length>0 && /* props.bookSlotState.userCourse.applications */props.bookSlotState.userApplications.map((item, i) => {
                // const isSelected = this.isSelected(item);
                return (
                  <div className="col-lg-2 col-sm-3 app_container padding_top_10 add-rebound-animation" key={i}>
                     
                    <div className="row apps_card margin_left_0 app-card-width" style={{position:"relative",overflow:"hidden"}}>
                      <div className="apps_card-content" >
                      {item.license==="premium" &&
                      <div className="border-ribbon">Premium</div>} 
                        <span className="overlay_checkbox ">
                          <label className="checkbox coloured" >
                            <input type="checkbox" checked={item.isSelected} onChange={(event) => props.selectApplication(item)}/><span className="checkbox-material"><span className="check"></span></span> 
                          </label>
                        </span>
                        <div className="col-md-12 col-xs-12 col-sm-12 catalogimage" onClick={event => props.selectApplication(item)}>
                          <img src={"/img/application-icons/"+item.name+".png"} height={50} width={50} alt="loader" />
                        </div>                        
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12 apps_card-footer" onClick={event => props.selectApplication(item)} >
                        <p className="app_displayname font_family_montserrat">{item.displayName}</p>
                      </div>
                    </div><br/>
                  </div>
                );
              })}
            </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{paddingBottom:10}}>
                <button className="nj-flow-button pull-right" type="button" onClick={()=>{props.handleAppSubmit(props.history)}} style={{marginRight:"30px"}}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
const mapStateToProps = state => ({
  bookSlotState: state.bookSlotState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onLoadFunction:bookSlotContainer.onLoadFunction,
  selectApplication:bookSlotContainer.selectApplication,
  handleRouteTo:bookSlotContainer.handleRouteTo,
  handleAppSubmit:bookSlotContainer.handleAppSubmit,
  handleSelectAllClick:bookSlotContainer.handleSelectAllClick
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(Applications);