// import axios from "axios";
import * as bookSlotActions from "./index";
// import customHttp from '../../../packages/CustomHttp/';
import snackbar from '../../../packages/Snackbar/';
// import * as bookSlotState from "../BookSlot/action";

export function onLoadFunction() {
   return (dispatch ,getState)=> {
      const course = JSON.parse(sessionStorage.getItem('selectedUserCourse'));
      // console.log("user course in actions",course)
      dispatch(bookSlotActions.setBookSlotElements('userCourse',course));
      if(course.applications && course.applications.length>0){
         var arr = course.applications.map(obj=> ({ ...obj, isChecked: false ,isSelected : false}));
         dispatch(bookSlotActions.setBookSlotElements('selected', []))
         dispatch(bookSlotActions.setBookSlotElements('userApplications',arr));
      }
   }
}

export function selectApplication(app) {
  
   return (dispatch ,getState)=> {
      var applications = getState().bookSlotState.userApplications;
      applications.filter(obj=>{
         if(obj._id===app._id){
            obj.isSelected = !obj.isSelected
         }
         return obj
      })
      var selectedApps = applications.filter(obj=>obj.isSelected);
      dispatch(bookSlotActions.setBookSlotElements('selected',selectedApps))
      dispatch(bookSlotActions.setBookSlotElements('userApplications',applications))
   }
}

export function handleAppSubmit(history) {
   return (dispatch ,getState)=> {
      var applications = getState().bookSlotState.userApplications;
      var selectedApps = applications.filter(obj=>obj.isSelected);
      dispatch(bookSlotActions.setBookSlotElements('selected', []))
      dispatch(bookSlotActions.setBookSlotElements('selectedApplications',selectedApps));
      sessionStorage.setItem('userSelectedApplications',JSON.stringify(selectedApps))
      if(selectedApps.length>0){
         dispatch(handleRouteTo('bookSlot',history))
      } else {
         var msg = "Please select atleast one application"
         dispatch(displayAlert('error',msg))
      }
   }
}

export function handleRouteTo(type,history) {
   return (dispatch ,getState)=> {
      // console.log("history",history)
      if(type==='applications'){
         history.push('/user/applications')
      } else if(type==='bookSlot'){
         history.push('/user/book-slot')
      } else {
         history.push('/user/courses')
      }
      
   }
}

export function displayAlert(type,msg) {
   return async (dispatch ,getState)=> {
      // console.log("msg",msg)
      if(type==='success'){
         dispatch(setBookSlotElements('successAlertMessage',msg));
         snackbar.successSnackbar();
       } else if(type==='error'){
         dispatch(setBookSlotElements('errorAlertMessage',msg));
         snackbar.errorSnackbar(); 
      }
   }
}

export function setBookSlotElements(name,content) {
   return (dispatch ,getState)=> {
      dispatch(bookSlotActions.setBookSlotElements(name,content))
   }
}

export const  handleSelectAllClick=(event)=>{
   return (dispatch ,getState)=> {  
     if (event.target.checked) {
      //  getState().appstore.applicationArray.forEach(element => {
      //    element.isSelected=true;
      //  });
     
       getState().bookSlotState.userCourse.applications.forEach(element => {
         element.isSelected=true;
       });

       getState().bookSlotState.userCourse.applications.forEach(element => {
         element.isSelected=true;
       });
      
       var selected_dummy= getState().bookSlotState.userCourse.applications.map(n =>n);
       dispatch(bookSlotActions.setBookSlotElements('selected', selected_dummy))
       dispatch(bookSlotActions.setBookSlotElements('userApplications',selected_dummy))
      //  dispatch(AppstoreActions.selectAllApplications( selected_dummy, getState().appstore.applicationArray, getState().appstore.applicationList))
       return;
     }
   
      getState().bookSlotState.userCourse.applications.forEach(element => {
         element.isSelected=false;
      });
      selected_dummy= getState().bookSlotState.userCourse.applications.map(n =>n);
      dispatch(bookSlotActions.setBookSlotElements('userApplications', selected_dummy))
      dispatch(bookSlotActions.setBookSlotElements('selected', []))
      
   }
 }

