import * as SettingsActionTypes from "../../../actionTypes";


export const getAllSubscriptions = (content) => ({
    type: SettingsActionTypes.SETTINGS_GET_ALL_SUBSCRIPTIONS,
    payload: {
      content
    }
});

export const getAllConcurrentUsers = (content) => ({
    type: SettingsActionTypes.SETTINGS_GET_ALL_CONCURRENT_USERS,
    payload: {
      content
    }
});

export const setBooleanValue = (name,value) => ({
    type: SettingsActionTypes.SETTINGS_SET_BOOLEAN_VALUE,
    payload: {
      name:name,
      value:value
    }
  });

  export const initiateOnloadFunction= (name,data) => ({
    type: SettingsActionTypes.SETTINGS_SET_ARRAY_TO_VARIABLE,
    payload: {
      name,
      content:data
    }
  });