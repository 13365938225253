import * as userDashboardActions from "./index";
import customHttpUserside from '../../../packages/CustomHttpUserside/';
// import snackbar from '../../../packages/Snackbar/';
import * as courseAnalyticsContainer from '../CourseAnalytics/action'
import {format} from "date-fns";

/**
 * @author Sachin Kammar
 * @returns {void} Nothing
 * @desc Function to get all courses assigned to user. It contains api integration.
 */
export function getAllCourses() {
   return async(dispatch)=> {
      const user = JSON.parse(sessionStorage.getItem('userData'));
      if(user && user.email) {
         dispatch(setUserDashboardElements('coursesLoader',true));
         try {
            const response = customHttpUserside.get(`/api/user/getUserDetailsUserside/userId/${user._id}`);
            const userData = (await response).data.message;
            if(userData && Array.isArray(userData.courses) && userData.courses.length>0) {
               dispatch(setUserDashboardElements('courses',userData.courses));
               dispatch(setUserDashboardElements('coursesLoader',false));
               dispatch(setUserDashboardElements('selectedSlotUsageCourse',userData.courses[0]));
               dispatch(getUserSlots(userData.courses[0]));
               // course usage
               dispatch(fetchBookedAndUsedSlots(userData.courses[0]));

            } else {
               dispatch(setUserDashboardElements('courses',[]));
               // course usage
               dispatch(setUserDashboardElements('bookedAndUsedSlots',{}));
            }
         } catch(err){
            dispatch(setUserDashboardElements('coursesLoader',false));
            // course usage
            dispatch(setUserDashboardElements('bookedAndUsedSlots',{}));
            console.log('error',err)
         }
      }
   }
}

/**
 * @author Sachin Kammar
 * @returns {void} Nothing
 * @desc Function to select course on dropdown change.
 */
export const handleSlotUsageCourseChange=(e)=>{
   return (dispatch)=> {
      dispatch(setUserDashboardElements('selectedSlotUsageCourse',JSON.parse(e.target.value)));
      dispatch(getUserSlots(JSON.parse(e.target.value)));
   }
}

/**
 * @author Sachin Kammar
 * @param {Object} course - Selcted course object
 * @returns {void} Nothing
 * @desc Function to get user booked slots with usage in minutes.
 */
export function getUserSlots(course) {
   return  (dispatch)=> {
      // console.log("course",course);
      const userData = JSON.parse(sessionStorage.getItem('userData'));
      dispatch(courseAnalyticsContainer.setSlotUsageListActual(course.startDate,course.endDate,course,userData))
   }
}

/**
 * @author Sachin Kammar
 * @param {Object} slot - selected slot for getUserUsageHostory() function argument.
 * @returns {void} Nothing
 * @desc Function to show slot usage history popup and trigger getUserUsageHostory() function.
 */
export const showUserSlotUsage=(slot)=>{
   return (dispatch)=> {
      document.getElementById('userSlotUsagePopup').style.display='block';
      dispatch(getUserUsageHostory(slot))
   }
}

/**
 * @author Sachin Kammar
 * @param {Object} slot - selected slot to get usage history.
 * @returns {void} Nothing
 * @desc Function to get slot usage/logon history by the user.
 */
export const getUserUsageHostory=(slot)=>{
   return async(dispatch, getState)=> {
      const course = getState().userDashboardState.selectedSlotUsageCourse;
      const courseId = course._id;
      const userId = slot.userId;
      const date = slot.date;
      const bookingTime = slot.bookingTime;
      let userSlotUsageHistory = {};
      dispatch(setUserDashboardElements("userSlotUsageHistoryLoader",true));
      try {
         let response = await customHttpUserside.get(`/api/applicationsUsage/sessionHistoryUserside/date/${date}/bookingTime/${bookingTime}/courseId/${courseId}/userId/${userId}`);
         dispatch(setUserDashboardElements("userSlotUsageHistoryLoader",false));
         if(response.data.message && response.data.message.sessionData && response.data.message.sessionData.length>0){
            userSlotUsageHistory = response.data.message;
            dispatch(setUserDashboardElements('userSlotUsageHistory',userSlotUsageHistory));
            dispatch(setUserDashboardElements("userSlotUsageHistoryLoader",false));
         }else{
            userSlotUsageHistory = {totalConsumption:"0 minutes",totalIdleTime:"0 minutes",quantitativeScore:0,sessionData:[]}
            dispatch(setUserDashboardElements('userSlotUsageHistory',userSlotUsageHistory));
            dispatch(setUserDashboardElements("userSlotUsageHistoryLoader",false));
         }
      } catch (err) {
         console.log("error",err);
         userSlotUsageHistory = {totalConsumption:"0 minutes",totalIdleTime:"0 minutes",quantitativeScore:0,sessionData:[]}
         dispatch(setUserDashboardElements('userSlotUsageHistory',userSlotUsageHistory));
         dispatch(setUserDashboardElements("userSlotUsageHistoryLoader",false));
      }
   }
}

/**
 * @author Sachin Kammar
 * @param {string} name - state name.
 * @param {any} content - state's data to be assigned.
 * @returns {void} Nothing
 * @desc Function to update a state.
 */
export function setUserDashboardElements(name,content) {
   return (dispatch)=> {
      dispatch(userDashboardActions.setUserDashboardElements(name,content))
   }
}



/**
 * @author Narasappa H
 * @param {string} date - date is string which is used to convert 'MMM' 'd' formate.
 * @return {string} - The string formate 'Mar 23'.
 * @desc Function to convert date formate ex:Mar 23.
 */
export function convertDate(date){
   return format(new Date(date),'MMM')+" "+format(new Date(date),'d')
 }


/**
 * @author Narasappa H
 * @param {Object} e - To get selected course using event in course dropdown.
 * @return {void} - Nothing.
 * @desc Function to change course dropdown in course usage page.
 */
export const handleCourseUsageCourseChange=(e)=>{
   return (dispatch)=> {
      dispatch(setUserDashboardElements('selectedCourse',JSON.parse(e.target.value)));
      dispatch(fetchBookedAndUsedSlots(JSON.parse(e.target.value)));
   }
}


/**
 * @author Narasappa H
 * @param {object} course - Selected course in dropdown.
 * @return {void} - Nothing.
 * @desc Function to get total booked,total used,total opened,course completion percentage and details of bookedSlot count and logon count.
 */

export function  fetchBookedAndUsedSlots(course){
   return async (dispatch ,getState)=> {
      // console.log("user data",course)
      var bookedAndUsedSlotSeries=JSON.parse(JSON.stringify(getState().userDashboardState.bookedAndUsedSlotSeries))
      var bookedAndUsedSlotOptions=JSON.parse(JSON.stringify(getState().userDashboardState.bookedAndUsedSlotOptions))

      try {
         dispatch(setUserDashboardElements('initialLoaderForBookedAndUsedSlots',false));
         dispatch(setUserDashboardElements('graphLoaderForBookedAndUsedSlots',true));

          var userData=JSON.parse(sessionStorage.getItem("userData"))
         var bookedData=[];
         var usedData=[];
         var labels=[];
         let response = await customHttpUserside.get('/api/netUtilization/courseAnalyticsUserside/fetchUsageDetails/courseId/'+course._id+'/userId/'+userData._id);
         dispatch(setUserDashboardElements('initialLoaderForBookedAndUsedSlots',false));
         dispatch(setUserDashboardElements('graphLoaderForBookedAndUsedSlots',false));
         // console.log("user data",response.data.message)
         if(response.data.message.bookedSlots>0){
            for(var i=0;i<response.data.message.details.length;i++){
              bookedData.push(response.data.message.details[i].bookedSlotCount)
              usedData.push(response.data.message.details[i].logonCount)
              labels.push(convertDate(response.data.message.details[i].date))
              if(i===response.data.message.details.length-1){
               //  console.log(labels,usedData,bookedData,i,)
                bookedAndUsedSlotSeries[0].data=bookedData;
                bookedAndUsedSlotSeries[1].data=usedData;
                bookedAndUsedSlotOptions.xaxis.categories=labels;
                bookedAndUsedSlotOptions.xaxis.type='category';
               //  console.log("reducer",bookedAndUsedSlotOptions,bookedAndUsedSlotSeries)
                dispatch(setUserDashboardElements('bookedAndUsedSlotOptions',bookedAndUsedSlotOptions));
                dispatch(setUserDashboardElements('bookedAndUsedSlotSeries',bookedAndUsedSlotSeries));
              }
            }
           dispatch(setUserDashboardElements('bookedAndUsedSlots',response.data.message));
          }else{
           dispatch(setUserDashboardElements('bookedAndUsedSlots',{}));
          }
         } catch (err) {
          dispatch(setUserDashboardElements('errorAlertMessage','Error try again'));
          dispatch(setUserDashboardElements('initialLoaderForBookedAndUsedSlots',false));
          dispatch(setUserDashboardElements('graphLoaderForBookedAndUsedSlots',false));
          dispatch(setUserDashboardElements('bookedAndUsedSlots',{}));
          console.log("error",err)
         }
   }
}
