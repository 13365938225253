
import React, { Component} from 'react';
import './Analytics.scss';
// import { connect } from "react-redux";
// import * as AnalyticsContainer from "../../container/actions/Analytics/action";
// import Store from "../../container/reducers/Store";
// import DatePicker from 'react-date-picker';
import 'react-datepicker/dist/react-datepicker.css';
// import { bindActionCreators } from 'redux';
import { Line } from 'react-chartjs-2';
// import axios from "axios";
import customHttp from '../../packages/CustomHttp';

class ResourceUsageByCourseVideo extends Component {
  constructor(props){
    super(props);
    this.state = {
      courseListOfResourceUsage:{},
      coursesArray:[],
      usersArray:[],
      resourcesVideoArray:[],
      resourceObject:{},
      userObject:{},
      courseObject:{},
      noDataFound:"",
      videoResourceCountGraphDate:[],
      show_Analytics_Loader: true,

      graphDataLine:{
        labels: [],
        datasets: [
          {
            label: 'Hours Spent By Users',
            color: "transparent",
            borderColor: "#eceff1",
            borderWidth: 1,
            fill: false,
            backgroundColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
            data: [],
            // lineTension: 0.1,
            borderColor: "black",
            // borderCapStyle: 'butt',
            // borderDash: [],
            // borderDashOffset: 0.0,
            // borderJoinStyle: 'miter',
            pointBorderColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
            // pointBackgroundColor: '#fff',
            pointBorderWidth: 8,
            pointHoverBorderWidth: 8,
            pointRadius: 1,
            pointHitRadius: 1,
            pointHoverRadius: 1,
            pointHoverBackgroundColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"],
            pointHoverBorderColor: ["#7FDA9D", "#ff78ab", "#ffa87d", "#fce26c", "#00CED1", "#c16fc5", "#978ee2"]

          }
        ]
      },
      options :{
        legend: {
            "display": false,
              "position": "top",
              "fullWidth": true,
              "reverse": false,
              "responsive": true,
              "maintainAspectRatio": true,
              "hoverBackgroundColor": false,
            },
            tooltips: {
              "enabled": true,
              callbacks: {
                  label: function (tooltipItems, data) {
                      return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
                  }
              }
            },
            scales: {
              //barValueSpacing: 20,
              yAxes: [{
                        ticks: {
                            min: 0
                        }
                    }],
                xAxes: [{
                  barPercentage: .6,
                  categoryPercentage: 0.35,
                }]
            }
        }
    }
  }

  componentDidMount() {
    this.fetchAllCourses();
  }

  demoVideoResourceCountGraphDate=()=>{
    this.fetchAllCourses();
  }

  handleChangeCourses=(e)=>{
    e.preventDefault();
    const value = JSON.parse(e.target.value)
    this.setState({courseObject:value},()=>{this.fetchAllUsersAndResourcesByCourse();})
  }

  handleChangeUsers=(e)=>{
    e.preventDefault();
    const value =  JSON.parse(e.target.value)
    this.setState({userObject:value},()=>{this.videoResourceCountGraphDate();})
  }

  handleChangeResources=(e)=>{
    e.preventDefault();
    const value =  JSON.parse(e.target.value)
    this.setState({resourceObject:value},()=>{this.videoResourceCountGraphDate()})
  }

  fetchAllCourses=()=>{
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      var demoCoursesArray=[{displayName: "Fluid Mechanics",_id: "5c15f3907257166d93c74bb2"},
      {displayName: "Manufacturing Technology",_id: "5c15f4677257166d93c74cb3"},
      {displayName: "Applied Thermodynamics",_id: "5c15f40c7257166d93c74c4f"},
      {displayName: "Dynamics of Machinery",_id: "5c15f3907257166d93c74r4"},
      {displayName: "Design of Machine Elements",_id: "5c15f3907257166d453c74bb2"}]
      if(this.props.demoAndActualValue==="Demo"){
        this.setState({coursesArray:demoCoursesArray,show_Analytics_Loader:false})
        this.fetchAllUsersAndResourcesByCourse()
      }else{
      // axios.get('/api/course/allCourses/user/'+userDetails.belongsTo)
      var url = '/api/course/allCourses/user/'+userDetails._id ;
      customHttp.get(url)
          .then(response => {
            // console.log("email exist checking", response.data.message);
            if(response.data.message && Array.isArray(response.data.message)){
              var coursesArray = response.data.message.reverse();
              // console.log("courseName video",response.data.message.length);
              this.setState({coursesArray:coursesArray,courseObject:coursesArray[0], show_Analytics_Loader:false})
              this.fetchAllUsersAndResourcesByCourse()

            }else{
              this.setState({coursesArray:[],courseObject:{},show_Analytics_Loader:false})
            }
          })
          .catch(error => {
            this.setState({coursesArray:[],courseObject:{},show_Analytics_Loader:false})
            console.log("failed", error.response);
          });
        }
    }

  fetchAllUsersAndResourcesByCourse=()=>{
    var demoUsersArray=[{username: "Branden",_id: "5c15f3907257166d93c74bb2"},
    {username: "Cyril",_id: "5c15f4677257166d93c74cb3"},
    {username: "Franklyn",_id: "5c15f40c7257166d93c74c4f"},
    {username: "Trevor",_id: "5c15f3907257166d93c74r4"},
    {username: "Graham",_id: "5c15f3907257166d453c74bb2"}]

    var demoResourcesVideoArray=[{displayName: "lean supply chain management",resourceId: "5c15f3907257166d93c74bb2",resourceViewCount: '14'},
    {displayName: "slot cancellation",resourceId: "5c15f4677257166d93c74cb3",resourceViewCount: '11'},
    {displayName: "how to book a slot",resourceId: "5c15f40c7257166d93c74c4f",resourceViewCount: '9'},
    {displayName: "The Management Of Operations",resourceId: "5c15f3907257166d93c74r4",resourceViewCount: '7'},
    {displayName: "how to access lab",resourceId: "5c15f3907257166d453c74bb2",resourceViewCount: '5'}]
    if(this.props.demoAndActualValue==="Demo"){
      this.setState({usersArray:demoUsersArray,resourcesVideoArray:demoResourcesVideoArray})
      this.videoResourceCountGraphDate()
    }else{
      // var courseId = "5e3125d62833e90e385a9371";
      // console.log("this.state.courseObject._id",this.state.courseObject);
      // axios.get('/api/course/userAndResourceList/'+this.state.courseObject._id)
      var url = '/api/course/userAndResourceList/'+this.state.courseObject._id ;
      customHttp.get(url)
          .then(response => {

            // var fetchAllUsersAndResourcesByCourse = JSON.parse(JSON.stringify(response.data.message));
            // console.log("users n resources",fetchAllUsersAndResourcesByCourse);
            if(Object.keys(response.data.message).length>0){

                 var usersArray = response.data.message.userList
                 if(usersArray.length>0){
                   // console.log("usersArray",usersArray.length);
                   this.setState({usersArray:usersArray,userObject:response.data.message.userList[0]},()=>{this.videoResourceCountGraphDate();})
                 }else {
                   // console.log("hello hi bolo jai");
                   this.setState({usersArray:[],userObject:{}})
                 }

                 var resourceVideoList = response.data.message.resourceVideoList
                 if(resourceVideoList.length>0){
                   // console.log("resourceVideoList",resourceVideoList.length);
                   this.setState({resourcesVideoArray:resourceVideoList,resourceObject:response.data.message.resourceVideoList[0]},()=>{this.videoResourceCountGraphDate();})
                 }else {
                   this.setState({resourcesVideoArray:[],resourceObject:{}})
                 }
            }else {
              this.setState({resourcesVideoArray:[],resourceObject:{}})
              this.setState({usersArray:[],userObject:{}})
            }

          })
          .catch(error => {
            this.setState({resourcesVideoArray:[],resourceObject:{}})
            this.setState({usersArray:[],userObject:{}})
            console.log("failed", error.response);
          });
        }
     }

        videoResourceCountGraphDate=()=>{
          var demoVideoResourceCountGraphDate=[{date: "2020-03-05",totalVideoPlayTimeInMinute: "18"},
          {date: "2020-03-06",totalVideoPlayTimeInMinute: "17"},
          {date: "2020-03-07",totalVideoPlayTimeInMinute: "24"},
          {date: "2020-03-08",totalVideoPlayTimeInMinute: "10"},
          {date: "2020-03-09",totalVideoPlayTimeInMinute: "8"},
          {date: "2020-03-10",totalVideoPlayTimeInMinute: "19"},
          {date: "2020-03-11",totalVideoPlayTimeInMinute: "15"}]
          // console.log("this.props",this.props.fromDate,this.props.toDate);
           var n = new Date(this.props.fromDate);
           var currentDate = n.getDate();
           if(currentDate<10){
             currentDate='0'+currentDate;
           }
           var currentMonth = n.getMonth() + 1; //Months are zero based
           if(currentMonth<10){
             currentMonth='0'+currentMonth;
           }
           var currentYear = n.getFullYear();
           var fromDate = currentYear + "-" + currentMonth + "-" + currentDate;  // date conversion dd/mm/yyyy to yyyy-mm-dd

           var p = new Date(this.props.toDate);
           var tempDate = p.getDate();
           if(tempDate<10){
             tempDate='0'+tempDate;
           }
           var tempMonth = p.getMonth() + 1;
           if(tempMonth<10){
             tempMonth='0'+tempMonth;
           }
           var tempYear = p.getFullYear();
           var toDate = tempYear + "-" + tempMonth + "-" + tempDate;   // date conversion dd/mm/yyyy
           if(this.props.demoAndActualValue==="Demo"){
            this.setState({videoResourceCountGraphDate:demoVideoResourceCountGraphDate})
           var temp = this.state.graphDataLine
           var data=[];
           var labels=[]
           for(var i=0;i<demoVideoResourceCountGraphDate.length;i++){
              labels.push(demoVideoResourceCountGraphDate[i].date)
              data.push(demoVideoResourceCountGraphDate[i].totalVideoPlayTimeInMinute)
            }
            temp.labels = labels;
            temp.datasets[0].data = data;
            this.setState({graphDataLine:temp,noDataFound:demoVideoResourceCountGraphDate})
          }else{
          // console.log("videoResourceCountGraphDate",this.state.userObject._id,this.state.resourceObject._id,this.props.fromDate,this.props.toDate);
              if(Object.keys(this.state.resourceObject).length>0){
                // axios.get('/api/videoViewCount/videoViewTimeByUserIdAndResourceId/userId/'+this.state.userObject._id+'/resourceId/'+this.state.resourceObject._id+'/fromDate/'+fromDate+'/toDate/'+toDate)
                var url = '/api/videoViewCount/videoViewTimeByUserIdAndResourceId/userId/'+this.state.userObject._id+'/resourceId/'+this.state.resourceObject._id+'/fromDate/'+fromDate+'/toDate/'+toDate ;
                customHttp.get(url)
                    .then(response => {
                      var videoResourceCountGraphDate = response.data.message
                      this.setState({videoResourceCountGraphDate:videoResourceCountGraphDate})
                      //  console.log("videoResourceCountGraphDate",response.data.message);
                      var temp = this.state.graphDataLine
                       // console.log("hello data hi labels 567890987654567890",temp);
                      var data=[];
                      var labels=[]
                      for(var i=0;i<response.data.message.length;i++){
                         // console.log("overAll usage",response.data.message._id,response.data.message.totalUsage)
                         labels.push(response.data.message[i].date)
                         data.push(response.data.message[i].totalVideoPlayTimeInMinute)
                       }
                      //  console.log("hello data hi labels",data,labels);
                       temp.labels = labels;
                       temp.datasets[0].data = data;
                      //  console.log("hello data hi labels   567890",data,labels, temp.datasets[0].data);
                       this.setState({graphDataLine:temp,noDataFound:response.data.message})

                    })
                    .catch(error => {
                      this.setState({videoResourceCountGraphDate:[],noDataFound:[]})
                      console.log("failed", error.response);
                    });
              }
            }

           }

  render(){
    // console.log("msg2uytrewertyuytrewertyu34567",this.state.coursesArray,this.state.usersArray,this.state.resourcesVideoArray.length)
    return(
      <div>

          <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px',paddingTop: 10}}>
            <div className="col-md-12 col-sm-12 nj-card" style={{paddingTop: 20,paddingBottom: 10}}>
            {this.state.show_Analytics_Loader !== true ?
              <div>
              {this.state.coursesArray.length > 0 ?
              <div>
              <div className="col-md-3">
                <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Courses:</label>
                <div className="nj-form-select">
                  <select className="vs-input--input" value={JSON.stringify(this.state.courseObject)} onChange={this.handleChangeCourses}>
                    {/*<option hidden='true' value=''></option>*/}
                    {this.state.coursesArray.map((courses,i) =>{
                      return <option key={i} value={JSON.stringify(courses)}>{courses.displayName}</option>
                    })}
                  </select>
                </div>
              </div>

              {this.state.usersArray.length > 0 && this.state.resourcesVideoArray.length > 0 ?

                  <div>
                    <div className="col-md-3">
                      <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Users:</label>
                      <div className="nj-form-select">
                        <select className="vs-input--input" value={JSON.stringify(this.state.userObject)} onChange={this.handleChangeUsers}>
                          {/*<option hidden='true' value=''></option>*/}
                          {this.state.usersArray.map((users,i) =>{
                            return <option key={i} value={JSON.stringify(users)}>{users.username || users.email}</option>
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontFamily: 'Montserrat'}}>Resources:</label>
                      <div className="nj-form-select">
                        <select className="vs-input--input" value={JSON.stringify(this.state.resourceObject)} onChange={this.handleChangeResources}>
                          {/*<option hidden='true' value=''></option>*/}
                          {this.state.resourcesVideoArray.map((resources,i) =>{
                            return <option key={i} value={JSON.stringify(resources)}>{resources.displayName}</option>
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3"></div>

                    {this.state.videoResourceCountGraphDate.length >0 ?

                      <div className="col-md-12" style={{marginTop: 15}}>
                        <p style={{fontFamily:'Montserrat'}}><strong>No. Of Minutes</strong></p>
                        <Line data={this.state.graphDataLine} options={this.state.options} height={130} key={this.state.graphDataLine}/>
                        <p style={{fontFamily:'Montserrat',textAlign: 'center'}}><strong>Dates</strong></p>
                      </div>
                      :

                      <div className="col-md-12">
                        <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                      </div>

                    }

                  </div>
                :

                <div className="col-md-12">
                  <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                </div>

              }
            </div>
          :

          <div className="col-md-12">
            <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
          </div>

        }</div>
        :
      <div className="row" style={{minHeight:"54.3vh"}}>
          <div className="col-md-12 aligh-user-loader-image-center" >
              <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
          </div>
    </div>}
          </div>
        </div>
      </div>
    );
  }
}
export default ResourceUsageByCourseVideo;
