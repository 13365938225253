import React, {useEffect} from 'react';
import { connect } from "react-redux";
import * as courseAnalyticsContainer from "../../container/actions/CourseAnalytics/action";
import { bindActionCreators } from 'redux';
import './CourseAnalytics.scss';
import BookedVsUsedSlots from './BookedVsUsedSlots/BookedVsUsedSlots';
// import CourseUserList from './courseUserList';
import CustomStorage from '../../packages/CustomStorage/';

function CourseAnalytics (props) {

  useEffect( () => { 
    props.setCourseAnalyticsElements("selectedType",'demo')
    var backFromUserAnalytics= CustomStorage.localStorageGet('backFromUserAnalytics')
    if(backFromUserAnalytics !== null){
      // console.log("rtyuiouytrtyuio",backFromUserAnalytics.selectedType)
      props.setCourseAnalyticsElements("selectedType",backFromUserAnalytics.selectedType)
    }
    CustomStorage.localStorageSet('backFromUserAnalytics',{selectedType:'demo',back:false});
  },[]);
  // onload function end

  const handleChange=(e)=>{
    props.changeDemoToActual(e.target.value)
    
  }

  return (
    <div className="add-on-load-animation">
      <div className="analytics-data-dropdown-button">
        <div className="select-container">
          <select value={props.courseAnalyticsState.selectedType} onChange={handleChange}>
            <option value="demo">Demo</option>
            <option value="actual">Actual</option>
            <svg className="c-input__icon" viewBox="0 0 20 20" width={20} height={20}>
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
            </svg>
          </select>
        </div>
      </div>     
      <div>
         <BookedVsUsedSlots  props={props}/>
      </div>
    </div>

    );
  }


const mapStateToProps = state => ({
  courseAnalyticsState: state.courseAnalyticsState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setCourseAnalyticsElements:courseAnalyticsContainer.setCourseAnalyticsElements,
  changeDemoToActual:courseAnalyticsContainer.changeDemoToActual
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(CourseAnalytics);
