import * as dashboardActions from "./index";
import customHttp from '../../../packages/CustomHttp/';
// import axios from 'axios';
import snackbar from '../../../packages/Snackbar/';
import {format} from "date-fns";

export function displayAlert(type,msg) {
   return  (dispatch)=> {
      // console.log("msg",msg)
      if(type==='success'){
         dispatch(setDashboardElements('successAlertMessage',msg));
         snackbar.successSnackbar();
      }  else if(type==='error'){
         dispatch(setDashboardElements('errorAlertMessage',msg));
         snackbar.errorSnackbar(); 
      }
   }
}

export function setDashboardElements(name,content) {
   return (dispatch)=> {
      dispatch(dashboardActions.setDashboardElements(name,content))
   }
}

//Onload function start.
export function initialFunction(type) {
   return (dispatch)=> {
      if(type==="demo"){
         dispatch(demoBookedAndUsedSlots());
         dispatch(setCountsDemo());
         dispatch(setTopCoursesDemo());
         dispatch(setTopAppsDemo())
      }else{
         dispatch(fetchBookedAndUsedSlots());
         dispatch(setCountsActual());
         dispatch(setTopCoursesActual());
         dispatch(setTopAppsActual())
         //dispatch(getAllCourses())
      }
   }
}
//Onload function end.

//Date conversion function start.
export function convertDate(date){
   return format(new Date(date),'MMM')+" "+format(new Date(date),'d')
 } 
//Date conversion function end.

//BookedAndUsedSlots function start.
export function  fetchBookedAndUsedSlots(){
   return async (dispatch ,getState)=> {
      var bookedAndUsedSlotSeries=JSON.parse(JSON.stringify(getState().dashboardState.bookedAndUsedSlotSeries))
      var bookedAndUsedSlotOptions=JSON.parse(JSON.stringify(getState().dashboardState.bookedAndUsedSlotOptions))
     
      try {
         dispatch(setDashboardElements('initialLoaderForBookedAndUsedSlots',true));
         var userData=JSON.parse(sessionStorage.getItem("userData"))
         var bookedData=[];
         var  usedData=[];
         var labels=[];
         let response = await customHttp.get('/api/analytics/logonAndSlotCount/'+userData.belongsTo);
         dispatch(setDashboardElements('initialLoaderForBookedAndUsedSlots',false));
         if(response.data.message.totalBookCount>0){    
            for(var i=0;i<response.data.message.details.length;i++){
              bookedData.push(response.data.message.details[i].bookedCount)
              usedData.push(response.data.message.details[i].usedCount)
              labels.push(convertDate(response.data.message.details[i].date))
              if(i===response.data.message.details.length-1){
               //  console.log(labels,usedData,bookedData,i,)
                bookedAndUsedSlotSeries[0].data=bookedData;
                bookedAndUsedSlotSeries[1].data=usedData;
                bookedAndUsedSlotOptions.xaxis.categories=labels;
               //  console.log("reducer",bookedAndUsedSlotOptions,bookedAndUsedSlotSeries)
                dispatch(setDashboardElements('bookedAndUsedSlotOptions',bookedAndUsedSlotOptions));
                dispatch(setDashboardElements('bookedAndUsedSlotSeries',bookedAndUsedSlotSeries));
              }
            }
           dispatch(setDashboardElements('bookedAndUsedSlots',response.data.message));     
          }else{
           dispatch(setDashboardElements('bookedAndUsedSlots',{}));
          }
         } catch (err) {
          dispatch(setDashboardElements('errorAlertMessage','Error try again'));  
          dispatch(setDashboardElements('initialLoaderForBookedAndUsedSlots',false));
          dispatch(setDashboardElements('bookedAndUsedSlots',{}));
          console.log("error",err)
         }
   }
}
//BookedAndUsedSlots function end.

// used for get date range 
var getDates = function(startDate, endDate) {
   var dates = [],
       currentDate = startDate,
       addDays = function(days) {
         var date = new Date(this.valueOf());
         date.setDate(date.getDate() + days);
         return date;
       };
   while (currentDate <= endDate) {
     dates.push(currentDate);
     currentDate = addDays.call(currentDate, 1);
   }
   return dates;
 };
 



//Demo BookedAndUsedSlots function start.
export function demoBookedAndUsedSlots(){
   return (dispatch ,getState)=> {
      var bookedAndUsedSlotSeries=JSON.parse(JSON.stringify(getState().dashboardState.bookedAndUsedSlotSeries))
      var bookedAndUsedSlotOptions=JSON.parse(JSON.stringify(getState().dashboardState.bookedAndUsedSlotOptions))
      dispatch(setDashboardElements('initialLoaderForBookedAndUsedSlots',true));
      var bookedData=[18,15,20,30,20,33,18];
      var  usedData=[13,5,10,14,8,19,8];
      var labels=[];

      var sevenDaysBack = new Date();
      sevenDaysBack.setDate(sevenDaysBack.getDate() - 6);  
      var dates = getDates(new Date(sevenDaysBack),new Date());                                                                                                          
      dates.forEach(function(date) {
         labels.push(convertDate(date))
      });      
     // var labels=[convertDate("2020-03-04"),convertDate("2020-03-05"),convertDate("2020-03-06"),convertDate("2020-03-07"),convertDate("2020-03-08"),convertDate("2020-03-09"),convertDate("2020-03-010")];
      bookedAndUsedSlotSeries[0].data=bookedData;
      bookedAndUsedSlotSeries[1].data=usedData;
      bookedAndUsedSlotOptions.xaxis.categories=labels;
      var obj={};
      obj.totalBookCount=154;
      obj.totalUsedCount=77;
      dispatch(setDashboardElements('bookedAndUsedSlotOptions',bookedAndUsedSlotOptions));
      dispatch(setDashboardElements('bookedAndUsedSlotSeries',bookedAndUsedSlotSeries));
      dispatch(setDashboardElements('bookedAndUsedSlots',obj));
      dispatch(setDashboardElements('initialLoaderForBookedAndUsedSlots',false));
       
   }
}
//Demo BookedAndUsedSlots function end.

//elements counts start
export function setCountsDemo(){
   return (dispatch, getState)=> {
      const counts = {courses:134,users:3745,resources:874,applications:164};
      dispatch(setDashboardElements('elementsCount',counts));
   }
}
export function setCountsActual(){
   return async (dispatch, getState)=> {
      var counts = {courses:0,users:0,resources:0,applications:0};
      dispatch(setDashboardElements('elementsCountLoader',true));
      const userData=JSON.parse(sessionStorage.getItem("userData"))
      try {
         const response = await customHttp.get(`/api/analytics/overAll/belongsTo/${userData.belongsTo}`);
         counts = {courses:response.data.message.courseCount,users:response.data.message.userCount,resources:response.data.message.resourceCount,applications:response.data.message.applicationCount}
         dispatch(setDashboardElements('elementsCount',counts));
         dispatch(setDashboardElements('elementsCountLoader',false));
      } catch(err){
         console.log("error",err);
         dispatch(setDashboardElements('elementsCountLoader',false));
      }
   }
}
//end of elements count

//top courses start
export function getTopCourseChartType(type){
   return (dispatch, getState)=> {
      let topCoursesOptions = JSON.parse(JSON.stringify(getState().dashboardState.topCoursesOptions));
      topCoursesOptions.chart.type = type;
      dispatch(setDashboardElements('topCoursesOptions',topCoursesOptions));
   }
}
export function setTopCoursesDemo(){
   return (dispatch, getState)=> {
      let borderColors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'];
      const topCourses = [
         {displayName:'Fluid Mechanics',count:80},
         {displayName:'Manufacturing Technology',count:70},
         {displayName:'Dynamics of Machinery',count:50},
         {displayName:'Design of Machine Elements',count:35},
         {displayName:'Applied Thermodynamics',count:20}
      ];
      for(let i=0;i<topCourses.length;i++){
         topCourses[i].borderColor = borderColors[i];
      }
      let topCoursesOptions = JSON.parse(JSON.stringify(getState().dashboardState.topCoursesOptions));
      topCoursesOptions.labels = ['Fluid Mechanics', 'Manufacturing Technology', 'Dynamics of Machinery', 'Design of Machine Elements', 'Applied Thermodynamics'];
      topCoursesOptions.plotOptions.radialBar.dataLabels.total.formatter = function () {return 255}
      topCoursesOptions.plotOptions.radialBar.dataLabels.value.formatter = function (val) {return val}
      let topCoursesColors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0']
      const topCoursesSeries = [80,70,50,35,20];
      // console.log('options cours',topCoursesOptions)
      dispatch(setDashboardElements('topCoursesOptions',topCoursesOptions));
      dispatch(setDashboardElements('topCourses',topCourses));
      dispatch(setDashboardElements('topCoursesSeries',topCoursesSeries));
      dispatch(setDashboardElements('topCoursesColors',topCoursesColors));
   }
}
export function setTopCoursesActual(){
   return async (dispatch, getState)=> {
      dispatch(setDashboardElements('topCoursesLoader',true));
      const userData=JSON.parse(sessionStorage.getItem("userData"))
      try {
         const response = await customHttp.get(`/api/analytics/topFiveCorses/belongsTo/${userData.belongsTo}`);
         let topCourses = JSON.parse(JSON.stringify(response.data.message.topFiveCourses));
         const bookedSlotsCount = JSON.parse(JSON.stringify(response.data.message.totalBookedCount));
         let borderColors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'];
         let topCoursesSeries = [];
         let topCoursesColors = [];
         let topCoursesLabels = [];
         for(let i=0;i<topCourses.length;i++){
            topCourses[i].borderColor = borderColors[i];
            topCoursesColors.push(borderColors[i]);
            topCoursesSeries.push(topCourses[i].count);
            topCoursesLabels.push(topCourses[i].displayName);
         }
         let topCoursesOptions = JSON.parse(JSON.stringify(getState().dashboardState.topCoursesOptions));
         topCoursesOptions.labels = topCoursesLabels;
         topCoursesOptions.plotOptions.radialBar.dataLabels.total.formatter = function () {return bookedSlotsCount};
         topCoursesOptions.plotOptions.radialBar.dataLabels.value.formatter = function (val) {return val}
         dispatch(setDashboardElements('topCourses',topCourses));
         dispatch(setDashboardElements('topCoursesSeries',topCoursesSeries));
         dispatch(setDashboardElements('topCoursesColors',topCoursesColors));
         dispatch(setDashboardElements('topCoursesOptions',topCoursesOptions));
         dispatch(setDashboardElements('topCoursesLoader',false));
      } catch(err){
         console.log("error",err);
         dispatch(setDashboardElements('topCoursesLoader',false));
         dispatch(setDashboardElements('topCourses',[]));
      }
   }
}
//end of top courses

//top apps end
export function setTopAppsDemo(){
   return (dispatch)=> {
      const topApps = [{_id:'Google Chrome',sum:280},
         {_id:'Solid Works',sum:230},
         {_id:'Matlab',sum:170},
         {_id:'AutoCAD',sum:140},
         {_id:'Autodesk Inventor',sum:90}
      ];
      const totalCount = 910;
      let topAppsData = setTopAppsActualData(topApps,totalCount)
      dispatch(setDashboardElements('topApps',topAppsData));
   }
}
export function setTopAppsActual(){
   return async (dispatch)=> {
      dispatch(setDashboardElements('topAppsLoader',true));
      const userData=JSON.parse(sessionStorage.getItem("userData"));
      try {
         const response = await customHttp.get(`/api/applicationsUsage/mostPopular/belongsTo/${userData.belongsTo}`);
         if(response.data.message && Array.isArray(response.data.message.topfiveAppDetails) && response.data.message.totalCount && response.data.message.topfiveAppDetails.length>0){
            let topAppsData = setTopAppsActualData(response.data.message.topfiveAppDetails,response.data.message.totalCount);
            dispatch(setDashboardElements('topApps',topAppsData));
         } else {
            dispatch(setDashboardElements('topApps',[]));
         }
         dispatch(setDashboardElements('topAppsLoader',false));
      } catch(err){
         console.log("error",err);
         dispatch(setDashboardElements('topApps',[]));
         dispatch(setDashboardElements('topAppsLoader',false));
      }
   }
}
function setTopAppsActualData(arr,totalCount){
   let topApps = arr || [];
   for(var i=0;i<topApps.length>0;i++){
      topApps[i].percentage = (topApps[i].sum/totalCount)*100;
   }
   return topApps;
}
//top apps end

//top ratings start
export function setCourseRatingsDemo() {
   return async (dispatch ,getState)=> {
      // const userData = JSON.parse(sessionStorage.getItem("userData"))
      const courses = [{_id:'1',displayName:'Fluid Mechanics'},{_id:'2',displayName:'Manufacturing Technology'},{_id:'3',displayName:'Dynamics of Machinery'},{_id:'4',displayName:'Design of Machine Elements'},{_id:'5',displayName:'Applied Thermodynamics'}]
      dispatch(setDashboardElements('allCourses',courses));
   }
}

export function getAllCourses() {
   return async (dispatch ,getState)=> {
      const userData = JSON.parse(sessionStorage.getItem("userData"))
      var url = `/api/ratings/getRatingsByCourseId/${userData._id}`;
      try {
         let response = await customHttp.get(url);
         let res = response.data.message;
         dispatch(setDashboardElements('allCourses',res));
         dispatch(courseRatings(res[0]._id))
         // console.log("rating response",res)
      } catch (err) {
         console.log("error",err)
      }
   }
}

export function courseRatings(courseId) {
   return async (dispatch ,getState)=> {
      const id = courseId._id;
      var url = `/api/course/allCourses/user/${id}`;
      try {
         let response = await customHttp.get(url);
         let res = response.data.message;
         dispatch(setDashboardElements('allCourses',res));
      } catch (err) {
         console.log("error",err)
      }
   }
}
//top ratings end