import React, { useState, useEffect } from 'react';
import './Resources.scss';
import RequestResource from './RequestResource';
import { connect } from "react-redux";
import * as resourceContainer from "../../container/actions/Resources/action";
import * as userRatingsContainer from "../../container/actions/UserRatings/action";
import * as resourceActions from '../../container/actions/Resources/';
import Store from "../../container/reducers/Store";
import { bindActionCreators } from 'redux';
import VideoPlayer from '../../packages/VideoPlayer/';
import UserRatings from '../UserRatings/'
function Resource (props) {
  const [searchValue, setSearchValue] = useState('');
  var userDetails=JSON.parse(sessionStorage.getItem("userData"))

  useEffect( () => {   
    if(props.borrowedResource !== undefined || props.borrowedResource !== null){
      Store.dispatch(resourceActions.setResourceElements('borrowedResources',props.borrowedResource))
    }else{
      Store.dispatch(resourceActions.setResourceElements('borrowedResources',false))
    }
    const all = {_id:'all'}
    const selectedArchiveType={name:"Unarchived",_id:false}
    props.getAllResources(all,all,selectedArchiveType,undefined,"onload");
    props.getAllDisciplines();
    props.getAllContentTypes(); 
    props.getAllSMeUsersList();  
    Store.dispatch(resourceActions.toggleRequestResource(false))
    Store.dispatch(resourceActions.setResourceElements("resourceVideo",{}))
    Store.dispatch(resourceActions.setResourceElements('selectedDiscipline',all))
    Store.dispatch(resourceActions.setResourceElements('selectedContentType',all))
    Store.dispatch(resourceActions.setResourceElements('selectedArchiveType',selectedArchiveType)) 
    Store.dispatch(resourceActions.setResourceElements('searchValue',""))
    Store.dispatch(resourceActions.setResourceElements("selected",[]))
    
    document.getElementById('shareResourcePopup').style.display='none'; 
    localStorage.removeItem("selectedResources");
  },[]);
  // onload function end 

  var openPopup=()=>{
    props.setResourceElements('resource',{name:'',discipline:'',contentType:'',file:{},tags:""})
    //setResource(resourceObj)
    document.getElementById('resourcePopup').style.display='block';
  }

  var handleChange=(e)=>{
    if(e.target.name==='file'){
      var temp = {...props.resourceState.resource, [e.target.name]:e.target.files[0]};
      props.setResourceElements("resource",temp)
    } 
    else{
      //setResource({...resource, [e.target.name]:e.target.value});
      props.setResourceElements("resource",{...props.resourceState.resource, [e.target.name]:e.target.value})
    }
  }
  var handleChangeGetVersion=(e)=>{
    props.setResourceElements("selectedResourceForVersionChange",{...props.resourceState.selectedResourceForVersionChange, publishedVersion:e.target.value})
  }
  var clearPopup = ()=>{
    //setResource(resourceObj);
    props.setResourceElements('resource',{name:'',discipline:'',contentType:'',file:{},tags:""})
    document.getElementById('resourceFileUpload').value = '';
    props.setResourceElements("timeInvestment",{hours:"",minutes:"",seconds:""});
  }

  var handleSearchChange = (e) => {
    let alphnumericRegex = /^[a-zA-Z0-9() _.,&?!#@]*$/;
      let alphnumericRegexValidation = alphnumericRegex.test(String(e.target.value))
      if(alphnumericRegexValidation){
        setSearchValue(e.target.value);
        //searchFilter(filteredArray,e.target.value);
        props.handleSearch(props.resourceState.filteredArray,e.target.value)   
      }   
  };
  var openUserRatingsPopup = (resource) => {
    props.showUserRatings(resource,"resource");
  }
  var getArchiveResource=(item)=>{
    props.setResourceElements("selectedArchiveResource",item)    
    document.getElementById('archivePopup').style.display='block'; 
  }
  var getUnarchiveResource=(item)=>{
    props.setResourceElements("selectedUnarchiveResource",item)    
    document.getElementById('unarchivePopup').style.display='block'; 
  }
  var hiddenArchivePopup=()=>{
    document.getElementById('archivePopup').style.display='none';  
  }
  var hiddenUnarchivePopup=()=>{
    document.getElementById('unarchivePopup').style.display='none';  
  }
  var getPlaylistResource=(item,resourceType)=>{
    if(resourceType === 4){
      localStorage.setItem("playlist",JSON.stringify(item));
      if(userDetails.type===1){
        setTimeout(function(){ props.history.push("/edit-playlist") }, 10);
      }else if(userDetails.type===2){
        setTimeout(function(){ props.history.push("/dlt/edit-playlist") }, 10);
      }else{
        setTimeout(function(){ props.history.push("/sub-admin/edit-playlist") }, 10);
      }   
    }else{
      props.setResourceElements("selectedResourceForVersionChange",item)
      document.getElementById('versionControllPopup').style.display='block'; 
    }
  }

  var getResourceDetails=(item)=>{
    props.setResourceElements("showSharedResourceLink",false)
    props.setResourceElements("sharingResourceObject",item)
    document.getElementById('shareResourcePopup').style.display='block'; 
  }
  var copyLink=()=>{
    var copyText = document.getElementById("copyText");
    copyText.select();
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy");
  }
  var handleChangeShareResourcePopup=()=>{
    document.getElementById('shareResourcePopup').style.display='none';
    props.setResourceElements("selectedSmeUser",{}) 
  }
  var handleChangeAddNewFile=()=>{
    var value=Store.getState().resourceState.allowFileUpload;
    if(!value){
      props.setResourceElements("versionControlResourceObj",{version:"",file:{},isChecked:false}) 
      props.setResourceElements("timeInvestment",{hours:"",minutes:"",seconds:""}) 
    }
    props.setResourceElements("allowFileUpload",!value)  
  }
  var handleChangeSaveFile=(e)=>{
    if(e.target.name==='file'){
      props.setResourceElements("versionControlResourceObj",{...props.resourceState.versionControlResourceObj,[e.target.name]:e.target.files[0]})
    }else if(e.target.name==='hours' || e.target.name==='minutes' || e.target.name==='seconds'){
      let numericRegex = /^[0-9]*$/;
      let numericRegexValidation = numericRegex.test(String(e.target.value))
      if(numericRegexValidation){
        props.setResourceElements("timeInvestment",{...props.resourceState.timeInvestment, [e.target.name]:e.target.value})
      }   
    } else{
      props.setResourceElements("versionControlResourceObj",{...props.resourceState.versionControlResourceObj, [e.target.name]:e.target.value.toLowerCase()})
    }
  }
  var handleChangeTimeInvestment=(e)=>{
    let numericRegex = /^[0-9]*$/;
    let numericRegexValidation = numericRegex.test(String(e.target.value))
    if(numericRegexValidation){
      props.setResourceElements("timeInvestment",{...props.resourceState.timeInvestment, [e.target.name]:e.target.value})
    } 
  }
  var getSelectedValue=(e)=>{
    var obj=JSON.parse(JSON.stringify(Store.getState().resourceState.versionControlResourceObj))
    var val=!obj.isChecked;
    //props.setResourceElements("versionControlResourceObj",obj)
    props.setResourceElements("versionControlResourceObj",{...props.resourceState.versionControlResourceObj, isChecked:val})
  }
  var cleareEditResourcePopupFields=()=>{
    document.getElementById('versionControllPopup').style.display='none';
    props.setResourceElements("versionControlResourceObj",{version:"",file:{},isChecked:false});
    props.setResourceElements("timeInvestment",{hours:"",minutes:"",seconds:""});
    props.setResourceElements("allowFileUpload",false)
  }

  return (
    <div>
    
      <div className="add-on-load-animation">
        <div id="successSnackbar">{props.resourceState.successAlertMessage}</div>
        <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.resourceState.errorAlertMessage}</div>
        {!props.resourceState.showRequestResource ? <div className="col-md-12 nj-card">
          <div className="row">
            <div className="nj-card__header">
              <div className="nj-card__title">
                <h4>Current Resources</h4>
              </div>
              
          {userDetails.type===2 &&
           <div>
                <button type="button" className="nj-flow-button pull-right" onClick={openPopup}>Upload</button>
             </div>}
             
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="nj-popup-form-wrap">
                <p className="popup-content-label">Select Discipline</p>
                <div className="nj-form-select">
                  <select name="selectedDiscipline" style={{textTransform:'capitalize'}} value={JSON.stringify(props.resourceState.selectedDiscipline)} onChange={props.handleFilterChange} autoComplete="off" required>
                    {/* <option value="">Pick a discipline</option> */}
                    {props.resourceState.disciplines.map((discipline,index)=>{
                        return (<option key={index} value={JSON.stringify(discipline)}>{discipline.name}</option>)
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="nj-popup-form-wrap">
                <p className="popup-content-label">Select Content Type</p>
                <div className="nj-form-select">
                  <select name="selectedContentType" style={{textTransform:'capitalize'}} value={JSON.stringify(props.resourceState.selectedContentType)} onChange={props.handleFilterChange} autoComplete="off" required>
                    {/* <option value="">Pick a content type</option> */}
                    {props.resourceState.contentTypes.map((contentType,index)=>{
                        return (<option key={index} value={JSON.stringify(contentType)}>{contentType.name}</option>)
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-3" style={(props.resourceState.borrowedResources?{display:"none"}:{display:"block"})}>
              <div className="nj-popup-form-wrap">
                <p className="popup-content-label">Select Archive Type</p>
                <div className="nj-form-select">
                  <select name="archiveType" style={{textTransform:'capitalize'}} value={JSON.stringify(props.resourceState.selectedArchiveType)} onChange={props.handleFilterChange}  autoComplete="off" required>
                    {/* <option value="">Pick a content type</option> */}
                    {props.resourceState.archiveArray.map((archivedType,index)=>{
                        return (<option key={index} value={JSON.stringify(archivedType)}>{archivedType.name}</option>)
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className={'col-md-3 '+( props.resourceState.borrowedResources?'':'pull-right')}>
              <div className="nj-popup-form-wrap">
                <form onSubmit={(e)=>{props.handleSubmitSearch(e)}}>
                  <p className="popup-content-label">Search</p>
                  <div className="input-group">
                    <input className="vs-input--input" type="text" name="searchValue" value={searchValue} onChange={handleSearchChange} placeholder="Search by name/tag name" autoComplete="off"  style={{borderTopRightRadius:"0px",borderBottomRightRadius:"0px",padding:"5px"}} required/>
                    <span className="input-group-addon" title="Click here" type="submit" style={{cursor:"pointer"}} onClick={(e)=>{props.handleSubmitSearch(e)}}><i className="glyphicon glyphicon-search" /></span>                
                </div>
                </form>               
              </div>
            </div>            
          </div>
          {props.resourceState.resourceArray.length>0 &&
          <div className="row" style={{marginBottom:"10px"}}>
            <div className="col-md-4 selectall-margin">
            {userDetails.type !== 2 && 
              <label className="checkbox coloured font_family_montserrat font_wight_500">
                <input type="checkbox"/*  indeterminate={(props.AppStore.selected.length > 0 && props.AppStore.selected.length < props.AppStore.applicationArray.length).toString()}*/ checked={props.resourceState.selected.length === props.resourceState.resourceArray.length}  onChange={(event)=>props.handleSelectAllClick(event)} />
                  <span className="checkbox-material"><span className="check"></span></span> Select All
              </label>}
            </div>
          </div>}
          {props.resourceState.resourceArray.length>0 ? <div className="row" >
            <div className="tbl-content" style={{maxHeight:'62vh',overflow:'auto',paddingBottom:"10px"}}>
              <div className="resources-wrapper">
                {props.resourceState.resourceArray.map((item, i) => {
                  return (
                    <div className="resource_card add-rebound-animation" key={i}>
                      <div className="resource_card-content">
                        <div className="resource-card-float">                       
                            <div className="overlay_checkbox resource-card-float-element" style={(item.archive===true?{display:"none"}:{display:"block",marginRight:"5px"})}> 
                            {userDetails.type !== 2 &&  <label className="checkbox coloured">
                              <input type="checkbox" checked={item.isChecked} onChange={()=>{props.handleCheckboxChange(item)}} /><span className="checkbox-material"><span className="check"></span></span> 
                            </label>}
                          </div>
                          <div className="resource-card-float-element pull-left" style={(props.resourceState.borrowedResources?{display:"none"}:{display:"block",marginRight:"5px",color:"#6370ad",cursor:"none"})}> 
                            <label className="checkbox coloured">
                              <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye">
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                <circle cx={12} cy={12} r={3} />
                              </svg>
                              {/* {console.log("item",item)} */}
                              <span style={{fontSize:14,verticalAlign:"top",marginLeft:"3px"}}>{item.resourceViewCount}</span>
                            </label>
                          </div>
                          <div className="pull-left" style={{marginLeft:"5px"}}>
                            <span style={{color:"rgb(99, 112, 173)",verticalAlign:"middle"}} title="Time investment in minutes" >
                            <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop:"5px",marginLeft:"2px"}} width={16} height={16} viewBox="0 0 25 25" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock">
                            <circle cx={12} cy={12} r={10} />
                            <polyline points="12 6 12 12 16 14" />
                            </svg>

                          {item.file!==undefined &&<span>
                            {item.type===2 && <span style={{verticalAlign:"text-top",fontSize:11,paddingLeft:3}}>{parseFloat(parseFloat(parseInt(item.file[0].timeInvestment)/60).toPrecision(3))+" Min"}</span>}
                            {item.type===0 && <span style={{verticalAlign:"text-top",fontSize:11,paddingLeft:3}}>{parseFloat(parseFloat(parseInt(item.file[0].timeInvestment)/60).toPrecision(3))+" Min"}</span>}
                            </span>}
                            {item.type===4 && <span style={{verticalAlign:"text-top",fontSize:11,paddingLeft:3}}>{parseFloat(parseFloat(parseInt(item.timeInvestment)/60).toPrecision(3))+" Min"}</span>}
                            {item.type===5 && <span style={{verticalAlign:"text-top",fontSize:11,paddingLeft:3}}>{parseFloat(parseFloat(parseInt(item.file[0].timeInvestment)/60).toPrecision(3))+" Min"}</span>}
                            </span>
                          </div>
                        </div>
                        {item.type===2 && <div className="resource_card-content-img-wrapper"  onClick={()=>props.openResourceLink(item,window,props.history)}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                        </div>}
                        {item.type===0 && <div className="resource_card-content-img-wrapper"  onClick={()=>props.openResourceLink(item,window,props.history)}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource" />
                        </div>}
                        {item.type===4 && <div className="resource_card-content-img-wrapper"  onClick={()=>props.openResourceLink(item,window,props.history)}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/file.svg"} alt="resource" />
                        </div>}
                        {item.type===5 && <div className="resource_card-content-img-wrapper"  onClick={()=>props.openResourceLink(item,window,props.history)}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource"  />
                        </div>}

                        {item.type===6 && <div className="resource_card-content-img-wrapper"  onClick={()=>props.openResourceLink(item,window,props.history)}>
                          <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource" />
                        </div>}

                        <div className="resource-card-float" style={(props.resourceState.borrowedResources?{display:'none'}:{marginTop:'-25px',paddingRight:5,display:"block"})} >                      
                          <div className="resource-card-float-element pull-right" style={{backgroundColor:'rgb(99, 112, 173)',color:'#fff',fontWeight:'600',padding:'1px 5px',borderRadius:14,fontSize:12}} onClick={()=>openUserRatingsPopup(item)}>
                            <span style={{marginRight:2}}>{parseFloat(parseFloat(item.average).toPrecision(2))}</span>
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" 
                                width={11} 
                                height={10} 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="currentColor" 
                                strokeWidth={2} 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                className="feather feather-star"
                                style={{fill:'#fff'}}
                              >
                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                              </svg>
                            </span>
                          </div>
                            <div className="pull-right" style={(!item.archive===true?{display:"block",marginRight:"10px"}:{display:"none"})}>
                              <span style={{color:"rgb(99, 112, 173)"}} title="Archive" onClick={(e)=>{getArchiveResource(item)}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-archive">
                                  <polyline points="21 8 21 21 3 21 3 8" /><rect x={1} y={3} width={22} height={5} />
                                  <line x1={10} y1={12} x2={14} y2={12} />
                                </svg>
                              </span>
                          </div>
                          <div className="pull-right" style={(item.archive===true?{display:"block",marginRight:"8px"}:{display:"none"})}>
                              <span style={{color:"rgb(99, 112, 173)"}} title="Unarchive" onClick={(e)=>{getUnarchiveResource(item)}}>
                                <i className="material-icons" style={{fontSize:"24",marginTop:"-3px"}}>&#xe169;</i>
                              </span>
                          </div>
                          <div className="pull-right" title="Edit" onClick={(e)=>{getPlaylistResource(item,item.type)}} style={{marginRight:"10px",color:"rgb(99, 112, 173)"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit">
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                            </svg>
                          </div>
                          <div className="pull-right" title="Share Resource" onClick={(e)=>{getResourceDetails(item)}} style={{display:"block",marginRight:"10px",color:"rgb(99, 112, 173)"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-share-2">
                              <circle cx={18} cy={5} r={3} /><circle cx={6} cy={12} r={3} />
                              <circle cx={18} cy={19} r={3} />
                              <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
                              <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
                            </svg>
                          </div>
                           
                        </div>
                      </div>
                      {/* {item.type===2 && <div className="" style={{backgroundColor:"red",height:3,width:(item.videoData.length && parseFloat(parseFloat(item.videoData[0].currentTime/item.videoData[0].duration)*100).toPrecision(2)+"%")}}></div>} */}                      
                      <div className="resource_card-footer"  onClick={()=>props.openResourceLink(item,window,props.history)}>
                        <p className="font_family_montserrat">{item.displayName}</p>
                      </div>
                    </div>
                  );
                })}
              </div>                 
            </div>
            {
              props.resourceState.borrowedResources?
                <div className="resource-proceed pull-right">
                  <button className="nj-flow-button" type="button" style={{marginLeft:10}}  onClick={($event)=>props.submitResources($event,props,"editPlaylist")}>Submit</button>
                </div>  
              :
              <div>
              {userDetails.type !== 2 && <div className="resource-proceed pull-right" >   
                  <button className="nj-flow-button" type="button"  onClick={($event)=>props.submitResources($event,props,"submit")}>Submit</button>
                  <button className="nj-flow-button" type="button" onClick={($event)=>props.submitResources($event,props,"createPlaylist")} style={{margin:'0 10px'}}>Create Playlist</button>
                  <button className="nj-flow-button bg-color-red" type="button" onClick={($event)=>props.submitResources($event,props,"skip")}  style={{marginTop:"5px"}} >Skip</button>
                </div> }
              </div>
            }
              
          </div>:
          <div className="row">
            <div className="no-resource-text">
              {props.resourceState.showLoadResourceLoader ?
                <img src="/img/loaders/spinningwheel.gif" alt="resource" height="60" width="60" /> 
                :<p>No resource found.</p>
              }
            </div>
            
            {userDetails.type===2 || props.resourceState.borrowedResources
            ? <div></div>
            : <div className="col-md-12 request-content">
              <p onClick={props.toggleRequestResource}>Can't find suitable content? Request here!</p>
            </div>}
          </div>}
        </div>:
        <RequestResource history={props.history}/>}
      </div>
      {/*popup of user ratings */}
      <div id="userRatingsPopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup" style={{maxWidth:450}}>
          <div className="popup-content" style={{padding:0}}>
            <UserRatings />
          </div>
        </div>
      </div>
      {/*popup to create resources*/}
      <div id="resourcePopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup">
          <header className="popup-header">
            <span>Create resource</span>
            <span onClick={()=>{document.getElementById('resourcePopup').style.display='none'; props.setResourceElements("timeInvestment",{hours:"",minutes:"",seconds:""});}}><i className="popup-close material-icons">close</i></span>
          </header>
          {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
          <div className="popup-content">
            <div className="col-md-12">
              <form name="resourceForm" onSubmit={props.createResource}>
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label">Name</p>
                  <input className="vs-input--input" type="text" name="name" value={props.resourceState.resource.name} onChange={handleChange} placeholder="Resource Name" autoComplete="off" required />
                </div>
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label">Select Discipline</p>
                  <div className="nj-form-select">
                    <select name="discipline" value={props.resourceState.resource.discipline} onChange={handleChange} style={{textTransform:'capitalize'}} autoComplete="off" required>
                      <option value="">Pick a discipline</option>
                      {props.resourceState.uploadResourcesPopupDisciplines.map((discipline,index)=>{
                          return (<option key={index} value={JSON.stringify(discipline)}>{discipline.name}</option>)
                      })}
                    </select>
                  </div>
                </div>
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label">Select content Type</p>
                  <div className="nj-form-select">
                    <select name="contentType" value={props.resourceState.resource.contentType} onChange={handleChange} style={{textTransform:'capitalize'}} autoComplete="off" required>
                      <option value="">Pick a content type</option>
                      {props.resourceState.createResourceContentTypes.map((contentType,index)=>{
                          return (<option key={index} value={JSON.stringify(contentType)}>{contentType.name}</option>)
                      })}
                    </select>
                  </div>
                </div>
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label">Upload File</p>
                  <input className="vs-input--input" id="resourceFileUpload" type="file" name="file" onChange={handleChange} placeholder="Petal Science" autoComplete="off" required />
                  <span style={{color: 'rgb(95, 82, 228)',fontSize:"11px"}}>*Max file size of 250 mb can be uploaded</span>
                </div>
                <div className="nj-popup-form-wrap">
                    <p className="popup-content-label">Enter Version</p>
                    <input className="vs-input--input" type="text" name="version" value={props.resourceState.resource.version} onChange={handleChange} placeholder="ex: version1" autoComplete="off" required />
                </div> 
                <div className="nj-popup-form-wrap">
                    <p className="popup-content-label">Enter Time Investment</p>
                    <div className="row">
                      <div className="col-md-4">
                        <input className="vs-input--input" type="text" name="hours" value={props.resourceState.timeInvestment.hours} onChange={handleChangeTimeInvestment} placeholder="in hours" autoComplete="off"  />
                      </div>
                      <div className="col-md-4">
                        <input className="vs-input--input" type="text" name="minutes" value={props.resourceState.timeInvestment.minutes} onChange={handleChangeTimeInvestment} placeholder="in minutes" autoComplete="off"  />
                      </div>
                      <div className="col-md-4">
                        <input className="vs-input--input" type="text" name="seconds" value={props.resourceState.timeInvestment.seconds} onChange={handleChangeTimeInvestment} placeholder="in seconds" autoComplete="off"  />
                      </div>
                    </div>
                  </div>
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label">Tags</p>
                  <input className="vs-input--input" style={{textTransform:"lowercase"}} value={props.resourceState.resource.tags} type="text" name="tags" onChange={handleChange} placeholder="Enter tags" autoComplete="off" required />
                  <span style={{color: 'rgb(95, 82, 228)',fontSize:"11px"}}>*Tags separated by comma</span>
                </div>
                { props.resourceState.showResourceCreateLoader ? <img className="pull-right" src="/img/loaders/spinningwheel.gif" height="50" width="50"  alt="resource" style={{marginTop:'-5px'}} /> 
                  :<button type="submit" className="nj-flow-button pull-right" style={{margin:5}}>Submit</button>}
                <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={(e)=>{document.getElementById('resourcePopup').style.display='none';clearPopup()}} style={{margin:5}}>Cancel</button></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*popup to play resources*/}
      <div id="playResourceVideoPopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup" style={{maxWidth:700}}>
          <header className="popup-header">
            <span style={{textTransform:"capitalize"}}>{props.resourceState.resourceVideo.displayName}</span>
            <span onClick={props.closeVideoPopup}><i className="popup-close material-icons">close</i></span>
          </header>
          {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
          <div className="popup-content">
            <div className="col-md-12">
              {Object.keys(props.resourceState.resourceVideo).length>0 && <VideoPlayer link={props.resourceState.resourceVideo.file[0].path} key={props.resourceState.resourceVideo._id} resourceId={props.resourceState.resourceVideo._id} currenttime={props.resourceState.videoCurrentTime}/>}
            </div>
          </div>
        </div>
      </div>

        {/*popup to play html resources*/}
        <div id="playResourceHtmlPopup" className={"nj-overly add-rebound-animation"}>
          <div className="popup-nj  my-popup" style={{maxWidth:"900px"}}>
            <header className="popup-header">
              <span style={{textTransform:"capitalize"}}>Digital Marketing Strategies</span>
              <span onClick={props.closeHtmlPopup}><i className="popup-close material-icons">close</i></span>
            </header>
            {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
            <div className="popup-content" style={{maxHeight:"500px"}}>
              <div className="col-md-12">
                <iframe  id='playHtml'  src={window.location.origin+"/public/sampleLmsModule/story.html"}  width="512" height="450" style={{width:'100%',outline:'none'}}>
               </iframe>
              </div>
            </div>
          </div>
      </div>


      {/*popup to play html resources*/}
        <div id="playResourceHtmlPopupType6" className={"nj-overly add-rebound-animation"}>
          <div className="popup-nj  my-popup" style={{maxWidth:"900px"}}>
            <header className="popup-header">
              <span style={{textTransform:"capitalize"}}>{Object.keys(props.resourceState.htmlFileDetails).length>0 && props.resourceState.htmlFileDetails.displayName}</span>
              <span onClick={props.closeHtmlPopup}><i className="popup-close material-icons">close</i></span>
            </header>
            {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
            <div className="popup-content" style={{maxHeight:"500px"}}>
              <div className="col-md-12">
                <iframe  id='playHtml6'  src={"/"+(Object.keys(props.resourceState.htmlFileDetails).length>0 && props.resourceState.htmlFileDetails.link)+".html"}  width="512" height="450" style={{width:'100%',outline:'none'}}>
               </iframe>
              </div>
            </div>
          </div>
      </div>



       {/*popup to archive resource*/}
       <div id="archivePopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj my-popup">
          <header className="popup-header">
            <span>Archive Resource </span>
            <span onClick={hiddenArchivePopup} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
          </header>
          <div className="popup-content">
            <div className="col-md-12">
              <form name="roleForm">
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label font_family_montserrat" style={{fontSize:"16px"}}>Do you really want to archive?</p>
                </div>                               
                  {props.resourceState.archiveSubmitLoader ? <button type="button" className="nj-flow-button pull-right" style={{margin:5}} onClick={(e)=>props.archiveResources()}>Archive</button>:<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={hiddenArchivePopup} style={{margin:5}}>Cancel</button></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*popup to unarchive resource*/}
      <div id="unarchivePopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj my-popup">
          <header className="popup-header">
            <span>Unarchive Resource </span>
            <span onClick={hiddenUnarchivePopup} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
          </header>
          <div className="popup-content">
            <div className="col-md-12">
              <form name="roleForm">
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label font_family_montserrat" style={{fontSize:"16px"}}>Do you really want to unarchive?</p>
                </div>                               
                  {props.resourceState.unarchiveSubmitLoader ? <button type="button" className="nj-flow-button pull-right" style={{margin:5}} onClick={(e)=>props.unarchiveResources()}>Unarchive</button>:<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={hiddenUnarchivePopup} style={{margin:5}}>Cancel</button></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/******************* popup to share resource start *********************/}
      <div id="shareResourcePopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj my-popup">
          <header className="popup-header">
            <span>Share Resource </span>
            <span style={{cursor:"pointer"}} onClick={handleChangeShareResourcePopup}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
          </header>
          <div className="popup-content">
            <div className="col-md-12">
              <form name="roleForm" onSubmit={props.shareResources}>
                {props.resourceState.showSharedResourceLink?
                  <div>
                    <div className="success-checkmark">
                      <div className="check-icon">
                        <span className="icon-line line-tip" />
                        <span className="icon-line line-long" />
                        <div className="icon-circle" />
                        <div className="icon-fix" />
                      </div>
                    </div>
                    <p className="popup-content-label" style={{fontSize:18,textAlign:"center"}}>Shared Successfully.</p>                  
                    <div className="nj-popup-form-wrap input-containerForCopyText">   
                      <input className="vs-input--input" type="text" name="name" id="copyText" style={{borderBottomRightRadius:0,borderTopRightRadius:0}} defaultValue={window.location.origin+'/shared/resource/'+props.resourceState.sharingResourceObject._id}  autoComplete="off"/>
                      <span className="copyButton" title="Copy to clipboard" onClick={copyLink}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-copy" style={{marginTop:8,cursor:"pointer"}}>
                          <rect x={9} y={9} width={13} height={13} rx={2} ry={2} />
                          <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                        </svg>                  
                      </span>                
                    </div>
                  </div> 
                :
                <div>               
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label">Share To</p>
                  <div className="nj-form-select">
                    <select name="selectedContentType" value={JSON.stringify(props.resourceState.selectedSmeUser)} onChange={(e)=>{props.setResourceElements("selectedSmeUser",JSON.parse(e.target.value))}} autoComplete="off" required>
                      <option value="">Select user</option>
                      {props.resourceState.smeUserList.map((smeUser,index)=>{
                          return (<option key={index} value={JSON.stringify(smeUser)}>{smeUser.email}</option>)
                      })}
                    </select>
                  </div>
                </div>                                          
                 {!props.resourceState.sharedResourceLoader ? <button type="submit" className="nj-flow-button pull-right" style={{margin:5}} >Submit</button>:<img src={"/img/loaders/spinningwheel.gif"} className="pull-right" style={{marginTop:"-10px"}} height={60} width={60} alt="loader" />}
                 </div>
                 }
              </form>
            </div>
          </div>
        </div>
      </div>
      {/******************* popup to version controll start *********************/}
      <div id="versionControllPopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup">
          <header className="popup-header">
            <span>Edit Resource</span>
            <span onClick={()=>{cleareEditResourcePopupFields()}}><i className="popup-close material-icons">close</i></span>
          </header>
          <div className="popup-content">
            <div className="col-md-12">                        
              {!props.resourceState.allowFileUpload ?
                <form name="resourceForm" onSubmit={props.changeNewVersion}>  
                  <div>
                    <div className="nj-popup-form-wrap">
                      <a href="# " className="popup-content-label" onClick={handleChangeAddNewFile} style={{fontSize:15,cursor:"pointer",color:"#0000FF"}}>Do you want to add new version ?</a>
                    </div>  
                    <div className="nj-popup-form-wrap">
                      <p className="popup-content-label">Select Version to Publish </p>
                      <div className="nj-form-select">
                        {Object.keys(props.resourceState.selectedResourceForVersionChange).length>0 && (
                        <select name="contentType" value={props.resourceState.selectedResourceForVersionChange.publishedVersion} onChange={handleChangeGetVersion} style={{textTransform:'capitalize'}} autoComplete="off" required>
                          {/* <option value="">Pick a content type</option> */}
                          {props.resourceState.selectedResourceForVersionChange.listOfVersion.map((version,index)=>{
                              return (<option key={index} value={version.version}>{version.version}</option>)
                          })}
                        </select> 
                        )}
                      </div>
                    </div> 
                    {props.resourceState.changeNewVersionLoader ? <img className="pull-right" src="/img/loaders/spinningwheel.gif"  alt="resource" height="50" width="50" style={{marginTop:'-5px'}} /> 
                    :
                    <button type="submit" className="nj-flow-button pull-right" style={{margin:5}}>Submit</button>}
                  </div> 
                </form>                
              :
              <form name="resourceForm" onSubmit={props.addNewFileForResource}>  
                <div>                 
                  <div className="nj-popup-form-wrap">
                    <p className="popup-content-label pull-right" style={{fontSize:15,cursor:"pointer",color:"#0000FF"}} onClick={handleChangeAddNewFile}>Cancel</p>
                  </div>
                  <div className="nj-popup-form-wrap">
                    <p className="popup-content-label">Upload File</p>
                    <input className="vs-input--input" id="resourceNewFileUpload" type="file" name="file" onChange={handleChangeSaveFile}  autoComplete="off" required />
                    <span style={{color: 'rgb(95, 82, 228)',fontSize:"11px"}}>*Max file size of 250 mb can be uploaded</span>
                  </div>
                  <div className="nj-popup-form-wrap">
                    <p className="popup-content-label">Enter Version</p>
                    <input className="vs-input--input" type="text" name="version" value={props.resourceState.versionControlResourceObj.version} onChange={handleChangeSaveFile} placeholder="ex: version1" autoComplete="off" required />
                  </div> 
                  <div className="nj-popup-form-wrap">
                    <label className="checkbox coloured">
                      <input type="checkbox" checked={props.resourceState.versionControlResourceObj.isChecked} onChange={getSelectedValue}/><span className="checkbox-material"><span className="check"></span></span> 
                    </label>
                    <span className="popup-content-label" style={{fontSize:14,verticalAlign:"middle"}}>Set this Version as Current Version</span>
                  </div>                  
                  <div className="nj-popup-form-wrap">
                    <p className="popup-content-label">Enter Time Investment</p>
                    <div className="row">
                      <div className="col-md-4">
                        <input className="vs-input--input" type="text" name="hours" value={props.resourceState.timeInvestment.hours} onChange={handleChangeSaveFile} style={{marginBottom:"10px"}} placeholder="in hours" autoComplete="off"  />
                      </div>
                      <div className="col-md-4">
                        <input className="vs-input--input" type="text" name="minutes" value={props.resourceState.timeInvestment.minutes} onChange={handleChangeSaveFile} style={{marginBottom:"10px"}} placeholder="in minutes" autoComplete="off"  />
                      </div>
                      <div className="col-md-4">
                        <input className="vs-input--input" type="text" name="seconds" value={props.resourceState.timeInvestment.seconds} onChange={handleChangeSaveFile} style={{marginBottom:"10px"}} placeholder="in seconds" autoComplete="off"  />
                      </div>
                    </div>
                  </div>
                  { props.resourceState.addNewVersionFileLoader ? <img className="pull-right" src="/img/loaders/spinningwheel.gif"  alt="resource" height="50" width="50" style={{marginTop:'-5px'}} /> 
                  :<button type="submit" className="nj-flow-button pull-right" style={{margin:5}}>Submit</button>}                    
              </div> 
            </form>              
            }                              
            </div>
          </div>
        </div>
      </div>
      {/* popup for pdf open */}
      <div id="pdfPopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup" style={{maxWidth:750}}>
          <header className="popup-header">
            <span id="pdfPopupTitle" style={{textTransform:'capitalize'}}></span>
            <span onClick={()=>{document.getElementById('pdfPopup').style.display='none'}}><i className="popup-close material-icons">close</i></span>
          </header>
          <div className="popup-content">
            <div className="col-md-12">
              {!props.resourceState.pdfLoader?<>
              <div id="canvas_container">
                <canvas id="pdf_renderer"></canvas>
              </div>
              <div className="pdf-controls">
                <div id="navigation_controls">
                  <button className="nj-flow-button" id="go_previous" onClick={props.goPreviousPage} style={{padding:'0 1rem',fontSize:16}}>{'<'}</button>
                  <input className="vs-input--input" id="current_page" defaultValue={1} type="number" onKeyDown={props.currentPage}  style={{width:40,margin:'0 5px',padding:0,textAlign:'center'}} />
                  <button className="nj-flow-button" id="go_next" onClick={props.goNextPage} style={{padding:'0 1rem',fontSize:16}}>{'>'}</button>
                </div>
                <div id="zoom_controls">  
                  <span style={{marginRight:5,fontWeight:'500'}}>Zoom</span>
                  <button className="nj-flow-button" id="zoom_out" onClick={props.zoomOutPage}  style={{padding:'0 1rem',fontSize:16,marginRight:5}}>-</button>
                  <button className="nj-flow-button" id="zoom_in" onClick={props.zoomInPage}  style={{padding:'0 1rem',fontSize:16}}>+</button>
                </div>
              </div>
              </>:
              <div className="" style={{height:"42vh",textAlign:'center'}}>
                <div className="col-md-12" style={{marginTop:"15vh"}}>
                  <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  resourceState: state.resourceState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllResources: resourceContainer.getAllResources,
  getAllDisciplines: resourceContainer.getAllDisciplines,
  getAllContentTypes: resourceContainer.getAllContentTypes,
  handleCheckboxChange: resourceContainer.handleCheckboxChange,
  createResource: resourceContainer.createResource,
  handleSearch: resourceContainer.handleSearch,
  handleFilterChange: resourceContainer.handleFilterChange,
  toggleRequestResource: resourceContainer.toggleRequestResource,
  setResourceElements:resourceContainer.setResourceElements,
  submitResources:resourceContainer.submitResources,
  closeVideoPopup:resourceContainer.closeVideoPopup,
  openResourceLink:resourceContainer.openResourceLink,
  displayAlert:resourceContainer.displayAlert,
  showUserRatings: userRatingsContainer.showUserRatings,
  showUserReviews: userRatingsContainer.showUserReviews,
  archiveResources:resourceContainer.archiveResources,
  handleSubmitSearch:resourceContainer.handleSubmitSearch,
  unarchiveResources:resourceContainer.unarchiveResources,
  getAllSMeUsersList:resourceContainer.getAllSMeUsersList,
  shareResources:resourceContainer.shareResources,
  addNewFileForResource:resourceContainer.addNewFileForResource,
  changeNewVersion:resourceContainer.changeNewVersion,
  handleSelectAllClick:resourceContainer.handleSelectAllClick,
  togglePdf:resourceContainer.togglePdf,
  renderPdf:resourceContainer.renderPdf,
  goPreviousPage:resourceContainer.goPreviousPage,
  goNextPage:resourceContainer.goNextPage,
  currentPage:resourceContainer.currentPage,
  zoomInPage:resourceContainer.zoomInPage,
  zoomOutPage:resourceContainer.zoomOutPage,
  closeHtmlPopup:resourceContainer.closeHtmlPopup
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(Resource);
