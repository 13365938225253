import * as CourseActionTypes from "../../../actionTypes";


export const getAllApplications = (content) => ({
    type: CourseActionTypes.COURSE_GET_ALL_APPLICATION,
    payload: {
      content
    }
});

export const getAllResources = (content) => ({
  type: CourseActionTypes.COURSE_GET_ALL_RESOURCES,
  payload: {
    content
  }
});

export const setBooleanValue = (name,value) => ({
  type: CourseActionTypes.COURSE_SET_BOOLEAN_VALUE,
  payload: {
    name:name,
    value:value
  }
});

export const selectApplication = (apps) => ({
  type: CourseActionTypes.COURSE_SELECT_APPLICATION,
  payload: {
    apps
  }
});
export const deleteSelectedApplication = (apps) => ({
  type: CourseActionTypes.COURSE_DELETE_SELECTED_APPLICATION,
  payload: {
    apps
  }
});
export const getSelectedApplications= (apps) => ({
  type: CourseActionTypes.COURSE_SAVE_SELECTED_APPLICATIONS,
  payload: {
    apps
  }
});

export const selectResource = (resource) => ({
  type: CourseActionTypes.COURSE_SELECT_RESOURCE,
  payload: {
    resource
  }
});
export const deleteSelectedResource = (resource) => ({
  type: CourseActionTypes.COURSE_DELETE_SELECTED_RESOURCE,
  payload: {
    resource
  }
});
export const getSelectedResources= (resource) => ({
  type: CourseActionTypes.COURSE_SAVE_SELECTED_RESOURCES,
  payload: {
    resource
  }
});
export const handleChangeCourseCreationInputFields= (name,value) => ({
  type: CourseActionTypes.COURSE_SAVE_COURSE_CREATION_INPUT_FIELDS,
  payload: {
    name,
    value
  }
});
  
export const getAllCreatedCourses= (data) => ({
  type: CourseActionTypes.COURSE_GET_CREATED_COURSES,
  payload: {
    content:data
  }
});

export const getSelectedCourse= (data) => ({
  type: CourseActionTypes.COURSE_GET_SELECTED_COURSEOBJECT,
  payload: {
    content:data
  }
});

export const initiateOnloadFunction= (name,data) => ({
  type: CourseActionTypes.COURSE_SET_ARRAY_TO_VARIABLE,
  payload: {
    name,
    content:data
  }
});
  
export const editCourseInputField= (name,content) => ({
  type: CourseActionTypes.COURSE_EDIT_COURSE_INPUT_FIELDS,
  payload: {
    name,
    content
  }
});

export const setDisciplines = content => ({
  type: CourseActionTypes.COURSE_DISCIPLINES,
  payload: {
    content
  }
});

export const setContentTypes = content => ({
  type: CourseActionTypes.COURSE_CONTENT_TYPES,
  payload: {
    content
  }
});

export const setResourceArray = content => ({
  type: CourseActionTypes.COURSE_RESOURCE_ARRAY,
  payload: {
    content
  }
});

export const setResourceOriginalArray = content => ({
  type: CourseActionTypes.COURSE_RESOURCE_ORIGINAL_ARRAY,
  payload: {
    content
  }
});

export const setFilteredArray = content => ({
  type: CourseActionTypes.COURSE_FILTERED_ARRAY,
  payload: {
    content
  }
});

export const setCourseElements= (name,value) => ({
  type: CourseActionTypes.COURSE_SET_COURSE_ELEMENTS,
  payload: {
    name,
    value
  }
});
