
import React, { Component,Fragment} from 'react';
import { connect } from "react-redux";
import * as courseAnalyticsContainer from "../../../container/actions/CourseAnalytics/action";
import { bindActionCreators } from 'redux';
import "./BookedVsUsedSlots.scss";
import DatePicker from 'react-date-picker';
import ReactApexChart from 'react-apexcharts';
import 'react-datepicker/dist/react-datepicker.css';
import CourseUserList from '../courseUserList';


class CourseUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
  
    }
  }

  componentDidMount(){
    setTimeout(() => {
      this.props.fetchAllCourse();
    }, 500);
  
  }

  render(){
    return(
    //   <div className="">
    //      <div className="col-md-12 col-sm-12 nj-card">
    //       <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
    //         <div className="nj-card__header" style={{borderBottom:"none",paddingTop: '10px'}}>
    //           <div className="nj-card__title col-md-12">

    //             <div className="col-md-3 col-xs-12" style={{marginLeft:"0px",paddingLeft:"0px",paddingBottom:"5px"}}>
    //               <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontWeight:"500"}}>Select Course</label>
    //               <div className="nj-form-select">
    //                 <select className="vs-input--input" style={{lineHeight:'25px',borderRadius:'5px'}}  onChange={this.handleChangeCourse}>
    //                   {this.state.allCourses.length==0 && (<option value=''>No courses available</option>)}
    //                   {this.state.allCourses.map((data,i) =>{
    //                     return <option key={i} value={JSON.stringify(data)}>{data.name}</option>
    //                   })}
    //                 </select>
    //               </div>
    //             </div>

    //             <div className="col-md-3 col-xs-12" style={{marginLeft:"0px",paddingLeft:"0px",paddingBottom:"5px"}}>
    //               <Fragment>
    //                 <label className="nj-location-label pull-left font_family_montserrat" style={{fontWeight:500}}>From Date </label>
    //                 <DatePicker className="datepicker-calender"
    //                     calendarClassName={"calender-design"}
    //                     format="dd/MM/yyyy"
    //                     clearIcon={null}
    //                     calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
    //                     showNeighboringMonth={false}
    //                     dayPlaceholder="dd"
    //                     monthPlaceholder="mm"
    //                     yearPlaceholder="yyyy"
    //                     value={this.state.startDate}
    //                     onChange={this.handleChangeStart}
    //                 />
    //               </Fragment>
    //             </div>

    //             <div className="col-md-3 col-xs-12" style={{marginLeft:"0px",paddingLeft:"0px",paddingBottom:"5px"}}>
    //               <Fragment>
    //                 <label  className="nj-location-label pull-left font_family_montserrat" style={{fontWeight:500}}>To Date </label>
    //                 <DatePicker className="datepicker-calender"
    //                     calendarClassName={"calender-design"}
    //                     format="dd/MM/yyyy"
    //                     clearIcon={null}
    //                     calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
    //                     showNeighboringMonth={false}
    //                     dayPlaceholder="dd"
    //                     monthPlaceholder="mm"
    //                     yearPlaceholder="yyyy"
    //                     value={this.state.endDate}
    //                     onChange={this.handleChangeEnd}
    //                 />
    //               </Fragment>
    //             </div>

    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="">
    //       {this.state.selectedCourseId.length>0 &&
    //       <BookedVsUsedSlotGraph  selectedCourseId={this.state.selectedCourseId} endDate={this.state.endDate} startDate={this.state.startDate} demoAndActualValue={this.props.demoAndActualValue} callChildFunction={this.getChildFunction} key={this.state.selectedCourseId}/>}
    //       <CourseUserList selectedCourse={this.state.selectedCourse} demoAndActualValue={this.props.demoAndActualValue} props={this.props.props}/>
    //     </div>

    //     {/* user list Component here */}

    //  </div>



  <div className="">
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
          <div className="col-md-12 col-sm-12 nj-card" >
            <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
              <div className="nj-card__header">
                <div className="nj-card__title">
                  <h4 className="font_family_montserrat">Course Analytics</h4>
                </div>
              </div>
            </div>
            <div >
              <div className="col-md-12" style={{marginTop:"10px"}}>
                <div className="col-md-3 col-xs-12" style={{paddingBottom:"5px"}}>
                  <label className="nj-location-label pull-left font_family_montserrat" style={{width: '100%',fontWeight:"500"}}>Select Course</label>
                  <div className="nj-form-select">
                    <select className="vs-input--input" style={{lineHeight:'25px',borderRadius:'5px'}}  onChange={this.props.handleChangeCourse}>
                      {this.props.courseAnalyticsState.totalCourses.length===0 && (<option value=''>No courses available</option>)}
                      {this.props.courseAnalyticsState.totalCourses.map((data,i) =>{
                        return <option key={i} value={JSON.stringify(data)}>{data.name}</option>
                      })}
                    </select>
                  </div>
                </div> 

                  <div className="col-md-3 col-xs-12" style={{paddingBottom:"5px"}}>
                    <Fragment>
                      <label className="nj-location-label pull-left font_family_montserrat" style={{fontWeight:500}}>From Date </label>
                      <DatePicker className="datepicker-calender"
                          calendarClassName={"calender-design"}
                          format="dd/MM/yyyy"
                          clearIcon={null}
                          calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
                          showNeighboringMonth={false}
                          dayPlaceholder="dd"
                          monthPlaceholder="mm"
                          yearPlaceholder="yyyy"
                          value={this.props.courseAnalyticsState.startDate}
                          onChange={this.props.handleChangeStart}
                      />
                    </Fragment>
                  </div> 
                  <div className="col-md-3 col-xs-12" style={{paddingBottom:"5px"}}>
                    <Fragment>
                      <label  className="nj-location-label pull-left font_family_montserrat" style={{fontWeight:500}}>To Date </label>
                      <DatePicker className="datepicker-calender"
                          calendarClassName={"calender-design"}
                          format="dd/MM/yyyy"
                          clearIcon={null}
                          calendarIcon={<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>}
                          showNeighboringMonth={false}
                          dayPlaceholder="dd"
                          monthPlaceholder="mm"
                          yearPlaceholder="yyyy"
                          value={this.props.courseAnalyticsState.endDate}
                          onChange={this.props.handleChangeEnd}
                      />
                    </Fragment>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px',marginTop:"15px"}}>
                      <div className="col-md-12 col-sm-12" >
                        {!this.props.courseAnalyticsState.initialLoaderForBookedAndUsedSlots ?
                          <div>
                            {this.props.courseAnalyticsState.bookedAndUsedSlots !== null ?
                              <div>
                                <div className="row" style={{textAlign:"left"}}>
                                <div className="col-md-12" style={{marginTop:"10px"}}>
                                  <div className="nj-card__title" style={{marginLeft:"7px"}}>
                                    <h4 className="font_family_montserrat" style={{fontWeight:600}}>Slot Usage</h4>
                                  </div>                 
                                </div>
                                  <div className="col-md-12 col-sm-12 slot-heading-div" >                         
                                    <span className="slot-heading-div_span">
                                      <span className="font_family_montserrat" >Total Booked Slots</span><br></br>
                                      <span className="font_family_montserrat slot-value" style={{color:"#626262",fontWeight:400}}>{this.props.courseAnalyticsState.bookedAndUsedSlots.bookedSlots}</span>
                                    </span>
                                    <span className="slot-heading-span">
                                      <span className="font_family_montserrat">Total Used Slots</span><br></br>
                                      <span className="font_family_montserrat slot-value" style={{color:"#626262"}}>{this.props.courseAnalyticsState.bookedAndUsedSlots.usedSlots}</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              :
                              <div className="col-md-12 font_family_montserrat idams_noDataFound" style={{height:"52vh"}}>No data found</div>
                            }

                            {Object.keys(this.props.courseAnalyticsState.bookedAndUsedSlots).length>0 && this.props.courseAnalyticsState.noDataAvailable?
                              <div>
                                <div style={{margin:"0px 10px"}}>
                                  <div id="chart">
                                    <div id="chart-timeline" className="icon-zindex">
                                      <ReactApexChart options={this.props.courseAnalyticsState.options} series={this.props.courseAnalyticsState.series}  type='area' height={340} width='100%' />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            :
                              <div className="col-md-12 font_family_montserrat idams_noDataFound" style={{height:"48vh"}}>No data found</div>
                            }
                          </div>
                        :
                          <div className="row" style={{height:"58vh"}}>
                            <div className="col-md-12 aligh-bookedslot-loader-image-center" >
                              <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                <CourseUserList props={this.props.props} />
                </div>
        
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    );




    
  }
}

const mapStateToProps = state => ({
  courseAnalyticsState: state.courseAnalyticsState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchAllCourse:courseAnalyticsContainer.fetchAllCourse,
  handleChangeStart:courseAnalyticsContainer.handleChangeStart,
  handleChangeCourse:courseAnalyticsContainer.handleChangeCourse,
  handleChangeEnd:courseAnalyticsContainer.handleChangeEnd
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(CourseUsage);


