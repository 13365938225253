export const COURSE_GET_ALL_APPLICATION = "COURSE_GET_ALL_APPLICATIONS";
export const COURSE_SET_BOOLEAN_VALUE = "COURSE_SET_BOOLEAN_VALUE";
export const COURSE_SELECT_APPLICATION = "COURSE_SELECT_APPLICATION";
export const COURSE_DELETE_SELECTED_APPLICATION = "COURSE_DELETE_SELECTED_APPLICATION";
export const COURSE_SAVE_SELECTED_APPLICATIONS = "COURSE_SAVE_SELECTED_APPLICATIONS";

export const COURSE_SELECT_RESOURCE = "COURSE_SELECT_RESOURCE";
export const COURSE_DELETE_SELECTED_RESOURCE = "COURSE_DELETE_SELECTED_RESOURCE";
export const COURSE_SAVE_SELECTED_RESOURCES = "COURSE_SAVE_SELECTED_RESOURCES";
export const COURSE_SAVE_COURSE_CREATION_INPUT_FIELDS = "COURSE_SAVE_COURSE_CREATION_INPUT_FIELDS";
export const COURSE_GET_CREATED_COURSES = "COURSE_GET_CREATED_COURSES";
export const COURSE_GET_SELECTED_COURSEOBJECT = "COURSE_GET_SELECTED_COURSEOBJECT";
export const COURSE_DELETE_COURSE = "COURSE_DELETE_COURSE";
export const COURSE_GET_ALL_RESOURCES = "COURSE_GET_ALL_RESOURCES";
export const COURSE_SET_ARRAY_TO_VARIABLE = "COURSE_SET_ARRAY_TO_VARIABLE";
export const COURSE_COMPONENT_ELEMENTS = "COURSE_COMPONENT_ELEMENTS";
export const COURSE_DISCIPLINES = "COURSE_DISCIPLINES";
export const COURSE_CONTENT_TYPES = "COURSE_CONTENT_TYPES";
export const COURSE_RESOURCE_ARRAY = "COURSE_RESOURCE_ARRAY";
export const COURSE_RESOURCE_ORIGINAL_ARRAY = "COURSE_RESOURCE_ORIGINAL_ARRAY";
export const COURSE_FILTERED_ARRAY = "COURSE_FILTERED_ARRAY";
export const COURSE_ANALYTICS_SETSEARCHUSERNAME="COURSE_ANALYTICS_SETSEARCHUSERNAME";

export const COURSE_SET_COURSE_ELEMENTS = "COURSE_SET_COURSE_ELEMENTS";

export const COURSE_EDIT_COURSE_INPUT_FIELDS = "COURSE_EDIT_COURSE_INPUT_FIELDS";

//Resources
export const RESOURCE_DISCIPLINES = "RESOURCE_DISCIPLINES";
export const RESOURCE_CONTENT_TYPES = "RESOURCE_CONTENT_TYPES";
export const RESOURCE_SELECTED_DISCIPLINE = "RESOURCE_SELECTED_DISCIPLINE";
export const RESOURCE_SELECTED_CONTENT_TYPE = "RESOURCE_SELECTED_CONTENT_TYPE";
export const RESOURCE_RESOURCE_ARRAY = "RESOURCE_RESOURCE_ARRAY";
export const RESOURCE_RESOURCE_ORIGINAL_ARRAY = "RESOURCE_RESOURCE_ORIGINAL_ARRAY";
export const RESOURCE_SHOW_REQUEST_RESOURCE = "RESOURCE_SHOW_REQUEST_RESOURCE";
export const RESOURCE_FILTERED_ARRAY = "RESOURCE_FILTERED_ARRAY";
export const RESOURCE_COMPONENT_ELEMENTS = "RESOURCE_COMPONENT_ELEMENTS";

//Request Resource
export const REQUEST_RESOURCE_RESOURCE_OBJECT = "REQUEST_RESOURCE_RESOURCE_OBJECT";
export const REQUEST_RESOURCE_COMPONENT_ELEMENTS = "REQUEST_RESOURCE_COMPONENT_ELEMENTS";

//Requested Content
export const REQUESTEDCONTENT_GET_REQUESTEDCONTENT = "REQUESTEDCONTENT_GET_REQUESTEDCONTENT";
export const REQUESTEDCONTENT_SET_BOOLEAN_VALUE = "REQUESTEDCONTENT_SET_BOOLEAN_VALUE";
export const REQUESTEDCONTENT_DELETE_REQUESTED_CONTENT = "REQUESTEDCONTENT_DELETE_REQUESTED_CONTENT";
export const REQUESTEDCONTENT_ONCHANGE_ADDCOMMENTS="REQUESTEDCONTENT_ONCHANGE_ADDCOMMENTS";
export const REQUESTEDCONTENT_GET_REQUESTEDCOMMENT = "REQUESTEDCONTENT_GET_REQUESTEDCOMMENT";
export const REQUESTEDCONTENT_SEARCH_REQUESTED_CONTENT = "REQUESTEDCONTENT_SEARCH_REQUESTED_CONTENT";
export const REQUESTEDCONTENT_GET_RECEPIENTS = "REQUESTEDCONTENT_GET_RECEPIENTS";
export const REQUESTEDCONTENT_SET_SENDNOTIFY_LOADER = "REQUESTEDCONTENT_SET_SENDNOTIFY_LOADER";
export const REQUESTEDCONTENT_COMPONENT_ELEMENTS = "REQUESTEDCONTENT_COMPONENT_ELEMENTS";


export const APPSTORE_GET_ALL_APPLICATIONS= "APPSTORE_GET_ALL_APPLICATIONS";
export const APPSTORE_FILTERED_CATEGORIES= "APPSTORE_FILTERED_CATEGORIES";
export const APPSTORE_ONCLICK_SELECT_ALL_APPS= "APPSTORE_ONCLICK_SELECT_ALL_APPS";
export const APPSTORE_FILTERED_APPLICATIONS_ARRAY= "APPSTORE_FILTERED_APPLICATIONS_ARRAY";
export const APPSTORE_SET_CATEGORY_NAME= "APPSTORE_SET_CATEGORY_NAME";
export const APPSTORE_SET_APPLICATION_NAME= "APPSTORE_SET_APPLICATION_NAME";
export const APPSTORE_SELECTED_APPLICATIONS= "APPSTORE_SELECTED_APPLICATIONS";
export const APPSTORE_SET_MESSAGE= "APPSTORE_SET_MESSAGE";
export const APPSTORE_SELECT_TEMPLATE= "APPSTORE_SELECT_TEMPLATE";

export const USER_COURSES_GET_ALLENABLEDCOURSES = "USER_COURSES_GET_ALLENABLEDCOURSES";
export const USER_COURSES_GET_ALLREQUESTEDCOURSES = "USER_COURSES_GET_ALLREQUESTEDCOURSES";
export const USER_SHOW_RESOURCES_LIST = "USER_SHOW_RESOURCES_LIST";
export const SET_DYNAMIC_VALUES = "SET_DYNAMIC_VALUES";
export const SET_DYNAMIC_VALUES_FOR_COURSERATINGS = "SET_DYNAMIC_VALUES_FOR_COURSERATINGS";
export const SET_DYNAMIC_VALUES_FOR_RESOURCERATINGS = "SET_DYNAMIC_VALUES_FOR_RESOURCERATINGS";
export const USER_SETSEARCHUSERNAME="USER_SETSEARCHUSERNAME";




export const USER_DELETE_USER = "USER_DELETE_USER";
export const CREATE_UPDATE = "CREATE_UPDATE";
export const GET_ALL_COURSES = "GET_ALL_COURSES";

//users content
export const GET_SELECTED_USER_COURSES="GET_SELECTED_USER_COURSES";
export const SEARCH_USERS_NAME="SEARCH_USERS_NAME";
export const USER_GET_SEARCH_COURSES = "USER_GET_SEARCH_COURSES"


//User Ratings
export const USER_RATINGS_SET_STATE_ELEMENTS = "USER_RATINGS_SET_STATE_ELEMENTS";

// Create Playlist
export const CREATE_PLAYLIST_COMPONENT_ELEMENTS = "CREATE_PLAYLIST_COMPONENT_ELEMENTS";
export const CREATE_PLAYLIST_DISCIPLINES = "CREATE_PLAYLIST_DISCIPLINES";
export const CREATE_PLAYLIST_CONTENT_TYPES = "CREATE_PLAYLIST_CONTENT_TYPES";
//View Playlist
export const VIEW_PLAYLIST_COMPONENT_ELEMENTS = "VIEW_PLAYLIST_COMPONENT_ELEMENTS";

//assign work flow

export const GET_ASSIGN_DATA = "GET_ASSIGN_DATA";
export const GET_CREATE_PRODUCE_DATA = "GET_CREATE_PRODUCE_DATA";

//dlt requested content
export const DLT_REQUESTEDCONTENT_GET_REQUESTEDCONTENT = "DLT_REQUESTEDCONTENT_GET_REQUESTEDCONTENT";
export const DLT_REQUESTEDCONTENT_SET_BOOLEAN_VALUE = "DLT_REQUESTEDCONTENT_SET_BOOLEAN_VALUE";
export const DLT_REQUESTEDCONTENT_ONCHANGE_ADDCOMMENTS="DLT_REQUESTEDCONTENT_ONCHANGE_ADDCOMMENTS";
export const DLT_REQUESTEDCONTENT_GET_REQUESTEDCOMMENT = "DLT_REQUESTEDCONTENT_GET_REQUESTEDCOMMENT";
export const DLT_REQUESTEDCONTENT_SEARCH_REQUESTED_CONTENT = "DLT_REQUESTEDCONTENT_SEARCH_REQUESTED_CONTENT";
export const DLT_RESOURCE_DISCIPLINES = "DLT_RESOURCE_DISCIPLINES";
export const DLT_RESOURCE_CONTENT_TYPES = "DLT_RESOURCE_CONTENT_TYPES";
export const DLT_RESOURCE_COMPONENT_ELEMENTS = "DLT_RESOURCE_COMPONENT_ELEMENTS";
export const DLT_SET_REQUESTEDCONTENT_STATUS = "DLT_SET_REQUESTEDCONTENT_STATUS";

// shared resources page
export const SHARED_RESOURCE_ASSIGN_VALUE = "SHARED_RESOURCE_ASSIGN_VALUE"

// Analytics
export const ANALYTICS_TOTALVIEWS_OF_RESOURCES = "ANALYTICS_TOTALVIEWS_OF_RESOURCES";
export const ANALYTICS_TOTALHOURS_SPENT_ON_VIDEOS = "ANALYTICS_TOTALHOURS_SPENT_ON_VIDEOS";
export const ANALYTICS_RESOURCES_USAGE = "ANALYTICS_RESOURCES_USAGE";
export const ANALYTICS_COURSE_RATINGS = "ANALYTICS_COURSE_RATINGS";
export const ANALYTICS_RESOURCES_RATINGS = "ANALYTICS_RESOURCES_RATINGS";


//Book Slot
export const BOOK_SLOT_COMPONENT_ELEMENTS = "BOOK_SLOT_COMPONENT_ELEMENTS";

//Access Lab
export const ACCESS_LAB_COMPONENT_ELEMENTS = "ACCESS_LAB_COMPONENT_ELEMENTS";

//Profile
export const PROFILE_SAVE_COMPONENT_ELEMENTS = "PROFILE_SAVE_COMPONENT_ELEMENTS";

//User Profle
export const USER_PROFILE_COMPONENT_ELEMENTS = "USER_PROFILE_COMPONENT_ELEMENTS";
export const USERPROFILE_SET_BOOLEAN_VALUE = "USERPROFILE_SET_BOOLEAN_VALUE"

//dashboard
export const DASHBOARD_COMPONENT_ELEMENTS = "DASHBOARD_COMPONENT_ELEMENTS";

//Course Analytics
export const COURSE_ANALYTICS_COMPONENT_ELEMENTS = "COURSE_ANALYTICS_COMPONENT_ELEMENTS";

//user dashboard
export const USER_DASHBOARD_COMPONENT_ELEMENTS = "USER_DASHBOARD_COMPONENT_ELEMENTS";

//subadminProfile

export const SUBADMIN_PROFILE_COMPONENT_ELEMENTS = "SUBADMIN_PROFILE_COMPONENT_ELEMENTS"

// gargi knowledge base

export const GARGI_KNOWLEDGE_BASE_COMPONENT_ELEMENTS="GARGI_KNOWLEDGE_BASE_COMPONENT_ELEMENTS";

//Admin Slot Booking
export const ADMIN_SLOT_BOOKING_COMPONENT_ELEMENTS = "ADMIN_SLOT_BOOKING_COMPONENT_ELEMENTS";

//Settings 
export const SETTINGS_GET_ALL_SUBSCRIPTIONS = "SETTINGS_GET_ALL_SUBSCRIPTIONS";
export const SETTINGS_GET_ALL_CONCURRENT_USERS = "SETTINGS_GET_ALL_CONCURRENT_USERS";
export const SETTINGS_SET_BOOLEAN_VALUE = "SETTINGS_SET_BOOLEAN_VALUE";
export const SETTINGS_SET_ARRAY_TO_VARIABLE = "SETTINGS_SET_ARRAY_TO_VARIABLE";


// proctoring
export const PROCTORING_COMPONENT_ELEMENTS="PROCTORING_COMPONENT_ELEMENTS";

//Object and Face recognition
export const OBJECTANDFACERECOGNITION_COMPONENT_ELEMENTS= "OBJECTANDFACERECOGNITION_COMPONENT_ELEMENTS"
