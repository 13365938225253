import React, {useEffect } from 'react';
import './Profile.scss';
import { connect } from "react-redux";
import * as profileContainer from "../../container/actions/Profile/action";
import { bindActionCreators } from 'redux';
//import snackbar from '../../packages/Snackbar/index';


function Profile (props) {
  useEffect( () => {
    props.getConfidentialDetails()
  },[]);
  // onload function end
  var userDetails=JSON.parse(sessionStorage.getItem("userData"))
    
  return(
    <div className="row">
      <div id="successSnackbar">{props.profileState.successSnackbarMessage}</div>
      <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.profileState.errorSnackbarMessage}</div>
      <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-md-offset-2"> 
        <div className="col-md-12 col-sm-12 nj-card">
          <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
            <div className="nj-card__header">
              <div className="nj-card__title col-md-12 col-xs-12 col-sm-12">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="font_family_montserrat pull-left">Profile </h4>
                  </div>
                  <div className="col-md-6" title="Edit" style={{cursor:"pointer",verticalAlign:"middle"}}>
                   {props.profileState.disable &&  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit pull-right" style={{color:"rgba(115,103,240)",marginTop:"4px"}} onClick={props.handleChangeEnableForm}>
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>}
                  </div>
                  </div>
              </div>  
            </div>
          </div>
          {Object.keys(props.profileState.profile).length>0 && <div className="col-md-12" style={{paddingLeft:'0px',paddingRight:'0px'}} > 
          <form onSubmit={props.updateConfidentialDetails}>
            <div className="col-md-12 col-sm-12">
            <div className="row" >
              <div className="col-md-6 col-sm-12" style={{marginTop:'10px',display:"flex",flexWrap:"wrap"}}>
                <label className="profile-input-label pull-left">Organization Name <span className="required_field_color_red">*</span></label>
                <input type="text" 
                  className="pull-left vs-input--input form-control" 
                  name="organizationName" 
                  defaultValue={props.profileState.profile.organizationName}
                  placeholder="Organization Name" 
                  autoComplete="off" 
                  disabled={props.profileState.disable || props.profileState.disableOrgName}
                  onKeyDown={props.handleKeyPress}
                  onChange={props.handleChangeCheckOrganizationName}
                  onBlur={props.handleChangeValidateOrganizationName}
                  required />
                  {props.profileState.orgValidation && <p style={{marginTop:'3px',color:'red',fontSize:12}}>
                    Organization name can only contain alphabets, numbers and hyphen(-).
                  </p>}
                  {props.profileState.orgNameExist && <p style={{marginTop:'3px',color:'red',fontSize:12}}>
                    Organization Name Already Exists.
                  </p>}
              </div>
              <div className="col-md-6 col-sm-12" style={{marginTop:'10px',display:"flex",flexWrap:"wrap"}}>
                <label className="profile-input-label pull-left">Organization Domain Name <span className="required_field_color_red">*</span></label>
                <input type="text" 
                  className="pull-left vs-input--input form-control" 
                  name="organizationDomainName" 
                  defaultValue={props.profileState.profile.organizationDomainName}
                  placeholder="Domain Name" 
                  autoComplete="off" 
                  onKeyDown={props.handleKeyPress}
                  onChange={props.handleChangeCheckDomainName}
                  onBlur={props.handleChangeValidateDomainName}
                  disabled={props.profileState.disable || props.profileState.disableDomain}
                  required />
                  {props.profileState.domainNameValidation && <p style={{marginTop:'3px',color:'red',fontSize:12}}>
                    Domain name can only contain alphabets, dot(.) and hyphen(-).
                  </p>}
                  {props.profileState.domainNameExist && <p style={{marginTop:'9px',color:'red',fontSize:12}}>
                    Domain Name Already Exists.
                  </p>}
              </div>
            </div>

            <div className="row" >
              <div className="col-md-6 col-sm-12" style={{marginTop:'10px',display:"flex",flexWrap:"wrap"}}>
                <label className="profile-input-label pull-left">Address <span className="required_field_color_red">*</span></label>
                <input type="text" 
                  className="pull-left vs-input--input form-control" 
                  name="organizationAddress" 
                  defaultValue={props.profileState.profile.organizationAddress}
                  placeholder="Address" 
                  autoComplete="off" 
                  onChange={props.handleChangeInputValue}
                  disabled={props.profileState.disable}
                  required />
              </div>
              <div className="col-md-6 col-sm-12" style={{marginTop:'10px',display:"flex",flexWrap:"wrap"}}>
                <label className="profile-input-label pull-left">City <span className="required_field_color_red">*</span></label>
                <input type="text" 
                  className="pull-left vs-input--input form-control" 
                  name="organizationCity" 
                  defaultValue={props.profileState.profile.organizationCity}
                  placeholder="City" 
                  autoComplete="off" 
                  onChange={props.handleChangeInputValue}
                  disabled={props.profileState.disable}
                  required />
              </div>
            </div>

            <div className="row" >
              <div className="col-md-6 col-sm-12" style={{marginTop:'10px',display:"flex",flexWrap:"wrap"}}>
                <label className="profile-input-label pull-left">State <span className="required_field_color_red">*</span></label>
                <input type="text" 
                  className="pull-left vs-input--input form-control" 
                  name="organizationState" 
                  defaultValue={props.profileState.profile.organizationState}
                  placeholder="State" 
                  autoComplete="off" 
                  onChange={props.handleChangeInputValue}
                  disabled={props.profileState.disable}
                  required />
              </div>
              <div className="col-md-6 col-sm-12" style={{marginTop:'10px',display:"flex",flexWrap:"wrap"}}>
                <label className="profile-input-label pull-left">Country <span className="required_field_color_red">*</span></label>
                <input type="text" 
                  className="pull-left vs-input--input form-control" 
                  name="organizationCountry" 
                  defaultValue={props.profileState.profile.organizationCountry}
                  placeholder="Country" 
                  autoComplete="off" 
                  onChange={props.handleChangeInputValue}
                  disabled={props.profileState.disable}
                  required />
              </div>
            </div>

            <div className="row" >
              <div className="col-md-6 col-sm-12" style={{marginTop:'10px',display:"flex",flexWrap:"wrap"}}>
                <label className="profile-input-label pull-left">Zip Code<span className="required_field_color_red">*</span></label>
                <input type="text" 
                  className="pull-left vs-input--input form-control" 
                  name="organizationZipCode" 
                  defaultValue={props.profileState.profile.organizationZipCode}
                  placeholder="Zip Code" 
                  autoComplete="off"
                  onChange={props.handleChangeInputValue} 
                  disabled={props.profileState.disable}
                  required />
              </div>
              <div className="col-md-6 col-sm-12" style={{marginTop:'10px',display:"flex",flexWrap:"wrap"}}>
                <label className="profile-input-label pull-left">Phone Number <span className="required_field_color_red">*</span></label>
                <input type="text" 
                  className="pull-left vs-input--input form-control" 
                  name="organizationPhoneNumber" 
                  defaultValue={props.profileState.profile.organizationPhoneNumber}
                  placeholder="Phone Number" 
                  autoComplete="off" 
                  onChange={props.handleChangeInputValue} 
                  disabled={props.profileState.disable}
                  required />
              </div>
             </div>


           <div className="row" style={{marginTop:'10px'}}>
                  <div className="col-md-6 col-sm-12">
                    <label className="checkbox coloured" style={{paddingLeft: '2px',marginTop:"10px"}}>
                      <input type="checkbox" name="enableLiveClassroom" checked={props.profileState.profile.enableLiveClassroom} onChange={props.getSelectedLiveClassroomValue}  disabled={props.profileState.disable} /><span className="checkbox-material"><span className="check"></span></span> 
                    </label>
                     <span className="font_family_montserrat" style={{fontSize:16,verticalAlign:"middle"}}  >Enable live classroom</span>                   
                   </div>
                     
                   <div className="col-md-6 col-sm-12">
                      <label className="checkbox coloured" style={{paddingLeft: '2px',marginTop:"10px"}}>
                        <input type="checkbox" name="enableRemoteProctoring" checked={props.profileState.profile.enableRemoteProctoring} onChange={props.getSelectedRemoteProctoringValue} disabled={props.profileState.disable}/><span className="checkbox-material"><span className="check"></span></span> 
                      </label>
                    <span className="font_family_montserrat"  style={{fontSize:16,verticalAlign:"middle"}}  >Enable remote proctoring</span>                   
                  </div>
              </div>
             
         
            <div className="row" style={{marginTop:"10px",marginBottom:'10px'}}>
              {!props.profileState.disable  && <div className="col-md-12 col-sm-12 profile-button-wrapper">
                {!props.profileState.formSubmitted ? <button  type="submit" className="pull-right nj-flow-button"  style={{color: "white",marginTop:"0px"}}>Submit</button>:<svg className="nj-spinner pull-right" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="path" fill="none" strokeWidth="4" strokeLinecap="round" cx="33" cy="33" r="23"></circle>
                </svg>}
                <button  type="button" className="nj-flow-button pull-right bg-color-red" style={{color: "white",marginRight:"10px"}} onClick={props.handleChangeDisableForm}>Cancel</button>               
              </div> }
            </div>
            </div>
           </form>
          </div>}
        </div>          
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  profileState: state.profileState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getConfidentialDetails:profileContainer.getConfidentialDetails,
  handleChangeInputValue:profileContainer.handleChangeInputValue,
  updateConfidentialDetails:profileContainer.updateConfidentialDetails,
  handleChangeEnableForm:profileContainer.handleChangeEnableForm,
  handleChangeDisableForm:profileContainer.handleChangeDisableForm,
  handleChangeValidateOrganizationName:profileContainer.handleChangeValidateOrganizationName,
  handleChangeCheckOrganizationName:profileContainer.handleChangeCheckOrganizationName,
  handleChangeValidateDomainName:profileContainer.handleChangeValidateDomainName,
  handleChangeCheckDomainName:profileContainer.handleChangeCheckDomainName,
  handleKeyPress:profileContainer.handleKeyPress,
  getSelectedLiveClassroomValue:profileContainer.getSelectedLiveClassroomValue,
  getSelectedRemoteProctoringValue:profileContainer.getSelectedRemoteProctoringValue

}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(Profile);