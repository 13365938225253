import React from 'react';
import { connect } from "react-redux";
import * as DashboardContainer from "../../../container/actions/Dashboard/action";
// import Store from "../../container/reducers/Store";
import { bindActionCreators } from 'redux';
import ReactApexChart from 'react-apexcharts';
import './TopCourses.scss';

function Dashboard (props) {

  return (
    <div>
      {props.dashboardState.topCourses.length>0 ? <div className="icon-zindex">
        <ReactApexChart options={props.dashboardState.topCoursesOptions} series={props.dashboardState.topCoursesSeries} type={props.dashboardState.topCoursesOptions.chart.type} height={300} />
        <div className="top-courses-label">
          <ul>
            <li style={{borderBottom:'1px solid #eee',fontWeight:'600'}}>
              <span>Course Name</span>
              <span>Booked Slots</span>
            </li>
            {props.dashboardState.topCourses.length>0 && props.dashboardState.topCourses.map((item,i)=>{
              return (
                <li key={i}>
                  <div><div style={{"borderColor":item.borderColor}}></div><span>{item.displayName}</span></div>
                  <span>{item.count}</span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>:
      <div className="top-courses-no-data">
         {!props.dashboardState.topCoursesLoader?<p>No data found..</p>:
         <img src="/img/loaders/spinningwheel.gif" alt="" height="50" width="50" />}
      </div>}
    </div>
  );
}


const mapStateToProps = state => ({
  dashboardState: state.dashboardState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  initialFunction:DashboardContainer.initialFunction
}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(Dashboard);
