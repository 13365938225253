import React ,{useState, useEffect} from 'react';
import { connect } from "react-redux";
import * as DltRequestedContentContainer from "../../container/actions/DltRequestedContent/action";
// import * as resourceContainer from '../../container/actions/Resources/action';
// import * as resourceActions from '../../container/actions/Resources'
// import RequestResource from '../Resources/RequestResource';
// import Store from "../../container/reducers/Store";
import * as Actions from "../../container/actions/DltRequestedContent";
import "./DLTRequestedContent.scss";
import { bindActionCreators } from 'redux';
// import axios from "axios";

function RequestedContent (props) {
    // const [shouldPopupAnimate, SetShouldPopupAnimate] = useState(false);
    const[comment , SetComment] = useState("");
    // const[index , Setindex] = useState("");
    const[submitComment,SetSubmitComment] = useState({});
    const [searchValue , setSearchValue] = useState('');
    const[data , setData] = useState({});
    const[downloadFiles , setDownloadFiles] = useState([]);
    // const[fetchComments,setFetchComments] = useState({});
    // const [alertMessage,setAlertMessage] = useState('');
    // const [disablePublish,setDisablePublish] = useState('');
    //var StatusArray=[{"name":"In Progress"},{"name":"Done"},{"name":"  Pending"}];
    // var ResourcesArray=[{"name":"Slot cancellationcancellation cancellationcancellationcancellation.html","path":""},{"name":"How to book a Slot.mp4"},{"name":"machinelearning.pdf"},{"name":"big data.csv"}];
    var DummyStatusArray=[{"name":"open"},{"name":"create"},{"name":"produce"},{"name":"review"},{"name":"approve"}];
    // const [status,setStatus] = useState("");
    const [currentStatus,setCurrentStatus] = useState("");
    const [currentRequestData,setCurrentRequestData] = useState([]);
    // const [date,setDate] = useState("")

    // var userDetails=JSON.parse(sessionStorage.getItem("userData"));

    // var animationFunctionForPopup=()=>{
    //   SetShouldPopupAnimate(true);
    // }

    useEffect( () => {
      props.getdltRequestedContent();
      props.getAllDisciplines();
      props.getAllContentTypes();
      // disablePublishFunction();
      },[]);

      // var disablePublishFunction=(data)=>{
      //   console.log("msg",data);
      //   // if(){
      //   //   setDisablePublish(true)
      //   // }
      // }

    var handleChangeComment1=(e)=>{
      SetComment(e.target.value)
    }

    var openPopUp=(data)=>{
      SetSubmitComment(data);
      document.getElementById("addPopup").style.display="block";
    }

    var openPublishPopUp=(data)=>{
      // SetSubmitComment(data);
      document.getElementById("publishPopup").style.display="block";
    }

    var openDownloadPopUp=(data)=>{
      // SetSubmitComment(data);
      document.getElementById("downloadPopup").style.display="block";
      setDownloadFiles(data.documents);

    }

    var OpenViewPopUp=(data)=>{
      SetSubmitComment(data);
      props.getRequestedComment(data);
      document.getElementById("viewPopup").style.display="block";
    }

    var handleSubmitComment=(e,data)=>{
      e.preventDefault();
      props.handleChangeComment(submitComment,comment);
      SetComment("");
      // setAlertMessage('Comment added successfully');
      // successSnackbar();
      document.getElementById("addPopup").style.display="none";
    }

    var handleSearchChange = (e) => {
      setSearchValue(e.target.value)
      searchFilter(e.target.value)
  };

  var searchFilter=(data)=>{
    var dublicateArray=props.DltRequestedContentState.dupRequestedContent;
    var searchRequestedArray=dublicateArray.filter(el =>{
     return el.email.toLowerCase().indexOf(data.toString().toLowerCase()) !== -1;
    })

    props.searchRequestedContent(searchRequestedArray);
  }


  var handleChange=(e)=>{
    if(e.target.name==='file'){
      var temp = {...props.DltRequestedContentState.resource, [e.target.name]:e.target.files[0]};
      props.setResourceElements("resource",temp)
    }
      else{
      props.setResourceElements("resource",{...props.DltRequestedContentState.resource, [e.target.name]:e.target.value})
    }
  }

  // var handleChangeDropdown=(e,data)=>{
  //   var status=e.target.value;
  //   if(status === "Done"){
  //     setDisablePublish(true)
  //   }
  //  props.requestedContentStatus(status,data);
  // }

  var clearPopup = ()=>{
    //setResource(resourceObj);
    props.setResourceElements('resource',{name:'',discipline:'',contentType:'',file:{} ,tags:"" })
    document.getElementById('resourceFileUpload').value = ''
  }

  // var getdownload=(data,fileName,type,item)=>{
  //   // console.log("download",data,"filename",fileName,"type",type,"item",item)
  //   axios({
  //       url: data,
  //       method: 'GET',
  //       responseType: 'blob', // important
  //       }).then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', fileName); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //     });

    
  // } 
  var handleChangeStatusProgress = (event,data) => {
    // setData(data);
     //console.log("statusprogressdata",event.target.value,data)
    var value=JSON.parse(event.target.value)
    props.statusProgressUpdate(data,value)
    
}

var openStatusProgressPopUp=(data)=>{
  // var status1=data.status
  var length = data.status.length;
  var status1 = data.status[length-1].status;
  // setStatus(status1);
  setCurrentRequestData(data.status[length-1])
  setCurrentStatus(status1)
  toShowStatus(status1);
  document.getElementById('statusProgress').style.display='block';
}

var toShowStatus=(status)=>{
  var arrlength=DummyStatusArray.length;
  
  for(var k=1;k<=DummyStatusArray.length;k++){
    document.getElementById("+"+k).style.backgroundColor="#bbb";
    document.getElementById("-"+k).style.backgroundColor="#bbb";
  }
  
  for(var i=0;i<arrlength;i++)
  {
    if(DummyStatusArray[i].name===status){
      for(var j=1;j<=i+1;j++){
        document.getElementById("+"+j).style.backgroundColor="green";
        document.getElementById("-"+j).style.backgroundColor="green";
      }
  }
}
}

var convertDate=(date)=>{
  var fromDateInSec = new Date(date.createdAt);
  var tempDate = date.createdAt.toString().substring(0,10);
  // console.log("tempdate",tempDate);
  var time = fromDateInSec.toString().substring(16,21);
  // console.log("fromDateInSec",date.createdAt,tempDate,tempMonth)
  var fromDate = tempDate + " at " + time;
  return fromDate;
 }

 var setSelectedRow = (data , i) =>{
  props.setResourceElements('selectedRequestedContentRow',data)
  props.setResourceElements('selectedIndex',i)
 }

  return (
    <div className="add-on-load-animation">
      <div className="col-md-12">
      <div id="successSnackbar">{props.DltRequestedContentState.successSnackbarMessage}</div>
      <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.DltRequestedContentState.errorSnackbarMessage}</div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}} >
          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 nj-card course-available-column">
            {/* <div className="col-md-12 col-sm-12 nj-card" > */}
              <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                <div className="nj-card__header">
                  <div className="nj-card__title">
                    <h4 className="font_family_montserrat">Requested Content</h4>
                  </div>

                </div>

                <div className="col-md-4 pull-right nj-user-search-wrap ">
                  <input className="vs-input--input nj-user-search-bar" type="text" style={{marginTop:"2%",marginBottom:"3%"}} name="searchValue" placeholder="search by email" value={searchValue} onChange={handleSearchChange}  autoComplete="off"/>
                </div>

              </div>

              {props.DltRequestedContentState.RequestedContent_loader === true ?
              <div>
              {props.DltRequestedContentState.RequestedContent.length === 0 ?
                  <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                :
              <div style={{marginBottom:"1%"}}>
              <div className="nj-table--tbody div tbl-content" style={{overflow:'auto'}}>
                  <table className="nj-table nj-table--tbody-table font_family_montserrat">
                    <thead className="nj-table--thead" >
                      <tr>
                        <th>
                          <div className="nj-table-text  nj-padding-10">Requested By</div>
                        </th>
                        <th>
                          <div className="nj-table-text nj-padding-10">Discipline</div>
                        </th>
                        <th>
                          <div className="nj-table-text nj-padding-10">Content Type</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >

                    {props.DltRequestedContentState.RequestedContent.map((data, i) =>{

                          return(

                          <tr key={i} style={(props.DltRequestedContentState.selectedRequestedContentRow !== undefined && props.DltRequestedContentState.selectedRequestedContentRow._id===data._id? {backgroundColor: "#f9f9f9"}:{backgroundColor: ""})} onClick={()=>setSelectedRow(data , i )}>
                            <td className="td nj-table--td nj-padding-10" style={{padding:"20px"}}>{data.email}</td>
                            <td className="td nj-table--td nj-padding-10 textOverflow" style={{padding:"20px",textTransform:"capitalize"}}>{data.branch.name}</td>
                            <td className="td nj-table--td nj-padding-10 textOverflow" style={{padding:"20px",textTransform:"capitalize"}}>{data.category.name}</td>
                          </tr>
                        )}
                      )}

                    </tbody>
                  </table>
                </div>
                </div>
              }
              </div>
              :
              <div className="" style={{marginLeft: '45%',paddingTop: '17%',paddingBottom: '17%'}}>
                <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" className=""/>
              </div>
            }
            </div>





            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 nj-card action-column" style={{overflowY:"auto",overflowX:"auto",maxHeight:"95vh"}}>
            {props.DltRequestedContentState.RequestedContent_loader === true ?
              <div>
              {props.DltRequestedContentState.RequestedContent.length === 0 ?
                  <p className="no-data-found" style={{margin: '12.75%'}}>No Data Found</p>
                :
                <div>
                <div className="row" style={{backgroundColor:'#f9f9f9',margin:'10px -5px 10px -5px',borderRadius:5}}>
                  <h4 className="font_family_montserrat" style={{marginLeft:20,fontWeight:600}}>Status Progress:</h4>
                  <div>
                  <div className="col-md-4 col-sm-4 " style={{marginTop:"30px"}}>  
                  <div className="nj-form-select" style={{marginLeft:"5px"}}>
                               <select  
                                  onChange={(event) => handleChangeStatusProgress(event,props.DltRequestedContentState.selectedRequestedContentRow)}
                                  value={JSON.stringify(props.DltRequestedContentState.selectedRequestedContentRow !== undefined && props.DltRequestedContentState.selectedRequestedContentRow.userStatus)}
                                  style={{textTransform:'capitalize'}} 
                                  autoComplete="off" required >
                                  <option value="">Please Select</option>
                              {   
                                 props.DltRequestedContentState.selectedRequestedContentRow !== undefined &&  props.DltRequestedContentState.selectedRequestedContentRow.assignWorkFlow !== undefined && props.DltRequestedContentState.selectedRequestedContentRow.assignWorkFlow[0].roles.map((obj,i) => {
                                    return (
                                      <option key={i}  value={JSON.stringify(obj)}>
                                        {obj.status}
                                      </option>
                                    )
                                  })
                                }
                                </select>
                              </div>
                  </div>

                  <div className="col-md-4 col-sm-4 course_actions_cards"  >
                                  <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}}  onClick={()=>openStatusProgressPopUp(props.DltRequestedContentState.selectedRequestedContentRow)}  >
                                    <img className="" src="/img/status.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                                    <p className="app_displayname font_family_montserrat">Status</p>
                                  </div>
                 </div>
                 </div>
                </div>

                <div className="row" style={{backgroundColor:'#f9f9f9',margin:'10px -5px 10px -5px',borderRadius:5}}>
                    <h4 className="font_family_montserrat" style={{marginLeft:20 ,fontWeight:600}}>Comment:</h4>
                <div  className="course_actions_cards" >

                <div className="col-md-4 col-sm-4">
                  <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}} onClick={(e)=>{openPopUp(props.DltRequestedContentState.selectedRequestedContentRow)}}  >
                    <img className="" src="/img/add_comment.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                    <p className="app_displayname font_family_montserrat">Add</p>
                  </div>
                </div>

                <div className="col-md-4 col-sm-4">
                  <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}}  onClick={(e)=>{OpenViewPopUp(props.DltRequestedContentState.selectedRequestedContentRow)}} >
                    <img className="" src="/img/view_comments.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                    <p className="app_displayname font_family_montserrat">View</p>
                  </div>
                 </div>
                </div>
                </div>


                <div className="row" style={{backgroundColor:'#f9f9f9',margin:'10px -5px 10px -5px',borderRadius:5}}>
                  <h4 className="font_family_montserrat" style={{marginLeft:20 ,fontWeight:600}}>Action:</h4>
                  <div  className="course_actions_cards">
                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}} onClick={(e)=>{openPublishPopUp(props.DltRequestedContentState.selectedRequestedContentRow)}} >
                        <img className="" src="/img/publish.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                        <p className="app_displayname font_family_montserrat">Publish</p>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-4">
                      <div className="demo-card" style={{textAlign: 'center',margin:'10px 5px',cursor: 'pointer'}} onClick={(e)=>{openDownloadPopUp(props.DltRequestedContentState.selectedRequestedContentRow)}} >
                        <img className="" src="/img/download.png" height={50} width={50} alt="" style={{marginTop:10,marginBottom:5}}/>
                        <p className="app_displayname font_family_montserrat">Download</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


              }
              </div>
              :
              <div className="" style={{marginLeft: '45%',paddingTop: '17%',paddingBottom: '17%'}}>
                 <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" className=""/>
              </div>
            } 
            </div>

          </div>
        </div>
      </div>

      <div id="addPopup" style={{display:"none"}} className={"nj-overly "+(props.DltRequestedContentState.shouldPopupAnimate ? "add-rebound-animation" : "no-animation")}>
        <div className="popup-nj my-popup">
          <header className="popup-header">
            <span>Comment</span>
            <span /* onClick={hideDeletePopUp} */ onClick={()=>{document.getElementById("addPopup").style.display="none";SetComment("")}}  style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
          </header>
          <div className="popup-content">
            <div className="col-md-12">
              <form name="commentForm">
                <div className="nj-popup-form-wrap">
                <textarea name="comment" rows="5" placeholder="Type here..." value={comment} style={{width:"100%"}} onChange={(e)=>{handleChangeComment1(e)}}></textarea>
                </div>
                {/* <button type="button" className="nj-flow-button bg-color-red" onClick={()=>{document.getElementById("addPopup").style.display="none";SetComment("")}} style={{marginLeft:"49%"}}  >Cancel</button>
                <button type="submit" className="nj-flow-button"  onClick={(e)=>handleSubmitComment(e,data)} style={{float:"right"}}>Submit</button>      */}
                <button type="submit" className="nj-flow-button pull-right" style={{margin:5}} onClick={(e)=>handleSubmitComment(e,data)}>Submit</button>
                <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={()=>{document.getElementById("addPopup").style.display="none";SetComment("")}}  style={{margin:5}}>Cancel</button></div>

              </form>
            </div>
          </div>
        </div>
      </div>



      {/*View pop up*/}
      <div id="viewPopup"  className={"nj-overly "+(props.DltRequestedContentState.shouldPopupAnimate ? "add-rebound-animation" : "no-animation")}>
        <div className="popup-nj my-popup-requested-content-view">
          <header className="popup-header">
            <span>View Comments</span>
            <span onClick={()=>document.getElementById("viewPopup").style.display="none"} style={{cursor:"pointer"}}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
          </header>
          <div className="popup-content-requested-content-view">
          {props.DltRequestedContentState.savedComments.length === 0 || props.DltRequestedContentState.savedComments === undefined ?
                  <p className="no-data-found" style={{margin: '19.75%'}}>No Data Found</p>
                :
          <div>
            <div className="col-md-12">
              <h5 className="comments-head"><b>Description:</b></h5>
              {props.DltRequestedContentState.savedComments.description}
            </div>
            <div className="col-md-12">
              <h5><b>Comments:</b></h5>
            </div>
            <div className="col-md-12"  style={{width:"100%"}} >
                  {props.DltRequestedContentState.savedComments.comments === undefined || props.DltRequestedContentState.savedComments.comments.length === 0 ?
                      <p style={{textAlign:"center"}}> No  comment found</p>
                    :
                  <div className="row" >
                  {props.DltRequestedContentState.savedComments.comments.map((data) =>{

                      return(

                    <div className="row" style={{backgroundColor:"#f4f4f4",minHeight:"50px",paddingTop: "10px",paddingBottom: "0px",marginLeft:"2%",marginRight:"2%",marginBottom:"2%"}}>
                      <div className="col-md-12 col-sm-12 ng-binding" style={{marginBottom:"0",fontSize: "14px",fontFamily: "roboto"}}>
                            {data.comment}
                          </div>
                          <div className="col-md-12 col-sm-12" style={{marginTop:"5px",fontSize: "12px",fontFamily: "roboto"}}>
                          <span className="pull-right ng-binding"> {data.email}</span><br/>
                          {/* <p className="pull-right ng-binding">{data.createdAt.substring(0,10)+" at "+data.createdAt.substring(11,16)}</p> */}
                          <p className="pull-right ng-binding">{convertDate(data)}</p>
                          </div>

                    </div>

                    )}
                  )}
                  </div>
                }
              </div>
            </div>
              }
          </div>
        </div>
      </div>

      <div id="publishPopup" className={"nj-overly add-rebound-animation"}>
        <div className="popup-nj  my-popup">
          <header className="popup-header">
            <span>Publish resource</span>
            <span onClick={()=>{document.getElementById('publishPopup').style.display='none';clearPopup()}}><i className="popup-close material-icons">close</i></span>
          </header>
          {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}
          <div className="popup-content">
            <div className="col-md-12">
              <form name="resourceForm" onSubmit={props.createResource}>
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label">Name</p>
                  <input className="vs-input--input" type="text" name="name" value={props.DltRequestedContentState.resource.name} onChange={handleChange} placeholder="Resource Name" autoComplete="off" required />
                </div>
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label">Select Discipline</p>
                  <div className="nj-form-select">
                    <select name="discipline" value={props.DltRequestedContentState.resource.discipline} onChange={handleChange} style={{textTransform:'capitalize'}} autoComplete="off" required>
                      <option value="">Pick a discipline</option>
                      {props.DltRequestedContentState.createResourceDisciplines.map((discipline,index)=>{
                          return (<option key={index} value={JSON.stringify(discipline)}>{discipline.name}</option>)
                      })}
                    </select>
                  </div>
                </div>
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label">Select content Type</p>
                  <div className="nj-form-select">
                    <select name="contentType" value={props.DltRequestedContentState.resource.contentType} onChange={handleChange} style={{textTransform:'capitalize'}} autoComplete="off" required>
                      <option value="">Pick a content type</option>
                      {props.DltRequestedContentState.createResourceContentTypes.map((contentType,index)=>{
                          return (<option key={index} value={JSON.stringify(contentType)}>{contentType.name}</option>)
                      })}
                    </select>
                  </div>
                </div>
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label">Upload File</p>
                  <input className="vs-input--input" id="resourceFileUpload" type="file" name="file" onChange={handleChange} placeholder="Petal Science" autoComplete="off" required />
                  <span style={{color: 'rgb(95, 82, 228)',fontSize:"11px"}}>*Max file size of 250 mb can be uploaded</span>
                </div>
                <div className="nj-popup-form-wrap">
                  <p className="popup-content-label">Tags</p>
                  <input className="vs-input--input" style={{textTransform:"lowercase"}} value={props.DltRequestedContentState.resource.tags} type="text" name="tags" onChange={handleChange} placeholder="Enter tags" autoComplete="off" required />
                  <span style={{color: 'rgb(95, 82, 228)',fontSize:"11px"}}>*Tags separated by comma</span>
                </div>
                { props.DltRequestedContentState.showResourceCreateLoader ? <img className="pull-right" src="/img/loaders/spinningwheel.gif" height="50" width="50" alt="" style={{marginTop:'-5px'}} />
                  :<button type="submit" className="nj-flow-button pull-right" style={{margin:5}}>Submit</button>}
                <div><button type="button" className="nj-flow-button bg-color-red pull-right" onClick={(e)=>{document.getElementById('publishPopup').style.display='none';clearPopup()}} style={{margin:5}}>Cancel</button></div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="downloadPopup" className={"nj-overly add-rebound-animation"} >
        <div className="popup-nj my-popup">
          <header className="popup-header">
          <span>Available Resources</span>
            <span onClick={()=>{document.getElementById('downloadPopup').style.display='none'}}><i className="popup-close material-icons">close</i></span>
            </header>
            {/*<a href="#"><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></a>*/}

              <div className="popup-content-requested-content-view" style={{maxHeight:"400px",maxWidth:"500px"}}>
<                 div className="col-md-12">
                    {downloadFiles === undefined || downloadFiles.length === 0 ?
                      <p className="no-data-found" >No Data Found</p>
                      :
                  <form name="resourceForm" >
                    {downloadFiles.map((item,index)=>{
                    return (
                    <div className="file " key={index} style={{marginBottom:"10px"}}>
                      <div className="col-md-2 col-xs-2 col-sm-2">
                          {item.mimetype==="video/mp4"?
                            <div className="resource_card-content-img-wrapper" style={{display:"inline"}}>
                               <img className="resource_card-content-img" src={"/img/resource-icons/video.svg"} alt="resource" style={{paddingRight:"10px",paddingTop:"5px"}}/>
                            </div>
                          :<div className="resource_card-content-img-wrapper" style={{display:"inline"}} >
                               <span><img className="resource_card-content-img" src={"/img/resource-icons/file-text.svg"} alt="resource" style={{paddingRight:"10px",paddingTop:"5px"}}/></span>
                          </div>}</div>
                          {/* <span className="field1"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-file-text">
                          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/><polyline points="14 2 14 8 20 8"/><line x1="16" y1="13" x2="8" y2="13"/><line x1="16" y1="17" x2="8" y2="17"/><polyline points="10 9 9 9 8 9"/></svg>
                          </span> */}

                        <div className=" col-md-10  col-xs-10 col-sm-10 text" style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}} >
                        <a href={"/"+item.path} target="_blank"  download={item.originalname}>{item.originalname}</a>                        
                           </div>
                         </div>
                        )})}
                    </form>
                    }
                    </div>
                  </div>
                </div>
              </div>

              
            {/* status progress */}

              <div id="statusProgress" className={"nj-overly add-rebound-animation"}>
                <div className="popup-nj  my-popup" style={{maxWidth:"600px"}}>
                  <header className="popup-header">
                    <span>Work Flow</span>
                    <span onClick={()=>{document.getElementById('statusProgress').style.display='none'}}><i className="popup-close material-icons">close</i></span>
                  </header>
                      
                        <div className="popup-content">
                        <div>
                        <div className="col-md-12" style={{width:"460px",height:"100px",marginLeft: "50px",marginTop:"60px",marginBottom:"10px"}} >
                          <div className="margin-area">
                            <div >
                             <div className="dot one" id="+1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                              <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="open"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                              <div id="-1"></div>
                              <div className="message2 message-1"> Open </div>
                            </div>
                            <div>
                              <div className="dot two" id="+2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                               <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="create"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time:{currentRequestData.time}</p></span>}</div>
                              <div className="progress-bar first" id="-2"></div>
                              <div className="message2 message-2">Created</div>
                            </div>
                            <div>
                                <div className="dot three" id="+3"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="produce"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time:{currentRequestData.time}</p></span>}</div>
                                <div className="progress-bar second" id="-3"></div>
                                 <div className="message2 message-3">Produced</div>
                            </div>
                            <div>
                               <div className="dot four" id="+4"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                               <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="review"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                                <div className="progress-bar third" id="-4"></div>
                                <div className="message2 message-4">Reviewed</div>
                             </div>
                            <div>
                               <div className="dot five" id="+5"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                <polyline points="20 6 9 17 4 12"/></svg>{currentStatus==="approve"&&<span className="popover above"><p style={{width:"180px",overflow:"hidden",lineHeight:"15px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>Email:{currentRequestData.email}</p><p>Date : {currentRequestData.date}</p><p>Time : {currentRequestData.time}</p></span>}</div>
                                 <div className="progress-bar fourth" id="-5"></div>
                                 <div className="message2 message-5" data-tooltip="dfsdffsdsdfs" >Approved</div>
                            </div>
                        </div>
                      </div>
                    </div>
                      </div>
                </div>
              </div>

           </div>
              );
            }


const mapStateToProps = state => ({
  DltRequestedContentState: state.DltRequestedContentState,
});



  const mapDispatchToProps = dispatch => bindActionCreators({
    getdltRequestedContent:DltRequestedContentContainer.getdltRequestedContent,
    handleChangeComment:DltRequestedContentContainer.handleChangeComment,
    getRequestedComment:DltRequestedContentContainer.getRequestedComment,
    searchRequestedContent:Actions.searchRequestedContent,
    getAllDisciplines: DltRequestedContentContainer.getAllDisciplines,
    getAllContentTypes: DltRequestedContentContainer.getAllContentTypes,
    createResource: DltRequestedContentContainer.createResource,
    setResourceElements:DltRequestedContentContainer.setResourceElements,
    requestedContentStatus:DltRequestedContentContainer.requestedContentStatus,
    statusProgressUpdate:DltRequestedContentContainer.statusProgressUpdate,
    setResourceElements:DltRequestedContentContainer.setResourceElements

  }, dispatch)


export default connect(
  mapStateToProps,mapDispatchToProps
)(RequestedContent);
