import * as UserActionTypes from "../../../actionTypes";


export const getAllApplications = (content) => ({
    type: UserActionTypes.COURSE_GET_ALL_APPLICATION,
    payload: {
      content
    }
});

export const setBooleanValue = (name,value) => ({
  type: UserActionTypes.COURSE_SET_BOOLEAN_VALUE,
  payload: {
    name:name,
    value:value
  }
});

export const selectApplication = (apps) => ({
  type: UserActionTypes.COURSE_SELECT_APPLICATION,
  payload: {
    apps
  }
});
export const deleteSelectedApplication = (apps) => ({
  type: UserActionTypes.COURSE_DELETE_SELECTED_APPLICATION,
  payload: {
    apps
  }
});
export const getSelectedApplications= (apps) => ({
  type: UserActionTypes.COURSE_SAVE_SELECTED_APPLICATIONS,
  payload: {
    apps
  }
});

export const selectResource = (resource) => ({
  type: UserActionTypes.COURSE_SELECT_RESOURCE,
  payload: {
    resource
  }
});
export const deleteSelectedResource = (resource) => ({
  type: UserActionTypes.COURSE_DELETE_SELECTED_RESOURCE,
  payload: {
    resource
  }
});
export const getSelectedResources = (resource) => ({
  type: UserActionTypes.COURSE_SAVE_SELECTED_RESOURCES,
  payload: {
    resource
  }
});
export const handleChangeCourseCreationInputFields = (name,value) => ({
  type: UserActionTypes.COURSE_SAVE_COURSE_CREATION_INPUT_FIELDS,
  payload: {
    name,
    value
  }
});
  
export const getAllCreatedCourses = (data) => ({
  type: UserActionTypes.COURSE_GET_CREATED_COURSES,
  payload: {
    content:data
  }
});
export const getSelectedCourse = (data) => ({
  type: UserActionTypes.COURSE_GET_SELECTED_COURSEOBJECT,
  payload: {
    content:data
  }
});

export const deleteCourse = (data) => ({
    type: UserActionTypes.USER_DELETE_USER,
    payload: {
      content:data
    }
  });

  // export const showUpload= (name,value) => ({
  //   type: UserActionTypes.USER_SHOWUPLOAD,
  //   payload: {
  //     name:name,
  //   value:value
  //   }
  // });
  
  export const handleChangeCreateUser = (data) => ({
    type: UserActionTypes.CREATE_UPDATE,
    payload: {
      content:data
    }
  });

  export const getAllCourses = (data) => ({
    type: UserActionTypes.GET_ALL_COURSES,
    payload: {
      content:data
    }
  });

  export const getUsersByCourse = (data) => ({
    type: UserActionTypes.GET_SELECTED_USER_COURSES,
    payload: {
      content:data
    }
  });

  export const searchRequestedContent = (searchRequestedArray) => ({
    type: UserActionTypes.SEARCH_USERS_NAME,
    payload: {
      searchRequestedArray
    }
  });

  export const getSearchCourses = (data) => ({
    type: UserActionTypes.USER_GET_SEARCH_COURSES,
    payload: {
      content:data
    }
  });
  
  export const setSearchUserName = (content )=> ({
    type: UserActionTypes.USER_SETSEARCHUSERNAME,
    payload: {
    content
    }
  })
  

  