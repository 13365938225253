import React, { useEffect } from 'react';
import { connect } from "react-redux";
 import * as GargiKnowledgeBaseContainer from "../../container/actions/GargiKnowledgeBase/action";
  import * as Actions from "../../container/actions/GargiKnowledgeBase/index";
import { bindActionCreators } from 'redux';
// import { Store } from '../../container/reducers/Store.js';
import './gargiKnowledgeBase.scss'
import Paginate from 'react-paginate';


function GargiKnowledgeBase (props) {
 // const [nameerui, SetName] = useState([]);
  useEffect( () => { 
   var selectedCourse=JSON.parse(sessionStorage.getItem('kbCourse'));
    if(selectedCourse !== null){
      props.getListOfQna();
    }  
    props.setGargiKnowledgeBaseElements('showUnansweredQuestion',false);
  },[]);
  // onload function end
  function auto_grow(id) {
    var ele=document.getElementById(id);
    ele.style.height = "5px";
    ele.style.height = (ele.scrollHeight)+"px";
  }
  


    return (
      <div className="add-on-load-animation">
      <div id="successSnackbar">{props.gargiKnowledgeBaseState.successSnackbarMessage}</div>
      <div id="errorSnackbar"><i className="fa fa-exclamation"></i>{props.gargiKnowledgeBaseState.errorSnackbarMessage}</div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:'0px',paddingRight:'0px'}}>
              <div className="col-md-12 col-sm-12 nj-card" >
                <div className="row" style={{textAlign:"left",fontFamily:'Montserrat'}}>
                  <div className="nj-card__header">
                    <div className="nj-card__title">
                      <h4 className="font_family_montserrat">Knowledge Base</h4>
                    </div>
                    <div style={{paddingBottom:'9px'}}>
                     {!props.gargiKnowledgeBaseState.showUnansweredQuestion ?<button className="nj-flow-button  font_family_montserrat" type="button" style={{marginRight:8}}  onClick={props.toggleShowUnansweredQuestion} disabled={props.gargiKnowledgeBaseState.saveAndTrainLoader}> Unanswered questions</button> :<button className="nj-flow-button  font_family_montserrat" type="button" style={{marginRight:6}} onClick={props.toggleShowUnansweredQuestion}>FAQ</button> } 
                      {!props.gargiKnowledgeBaseState.saveAndTrainLoader ?  <button className="nj-flow-button pull-right font_family_montserrat" type="button" disabled={!(props.gargiKnowledgeBaseState.addGargiQuestionAndAnswer.length>0 || props.gargiKnowledgeBaseState.changedQnaArray.length>0) || props.gargiKnowledgeBaseState.saveAndTrainLoader || props.gargiKnowledgeBaseState.showUnansweredQuestion} onClick={props.onSaveGargiQna}> SAVE AND TRAIN</button> : <img src={"/img/loaders/spinningwheel.gif"} style={{marginTop:"0px"}} height={40} width={40} alt="loader" />} 
                    </div>
                  </div>
                </div>
                <div className="row">
                  {!props.gargiKnowledgeBaseState.showUnansweredQuestion &&(
                  <div className="col-md-12" style={{display:'inlineFlex'}}>               
                    <div className="col-md-2 col-sm-3 col-xs-3">
                      <span>
                        <div className="kb-view-title ont_family_montserrat" >View: </div>
                        <div className="nj-form-select kb-view-dropdown" style={{width:"90px",outline:'none',boxShadow:'none'}}>
                          <select className="form-control pull-left font_family_montserrat vs-input--input"  value={props.gargiKnowledgeBaseState.countPerPage} onChange={(e)=>{props.handleChangeView(e)}} style={{border:'1px solid rgb(204, 204, 204)',outline:'none',textDecoration:'none'}}>
                            {
                              props.gargiKnowledgeBaseState.viewArray.map((data,i) => {
                              return (
                              <option key={i} value={data}>
                              {data}
                            </option>
                            )
                            })
                            }
                          </select>
                        </div>
                      </span>
                    </div>
                     <div className="col-md-9 col-sm-8 col-xs-8">
                      {props.gargiKnowledgeBaseState.listOfQnaArray.length>props.gargiKnowledgeBaseState.countPerPage && (
                      <Paginate 
                        previousLabel={<svg xmlns="http://www.w3.org/2000/svg" style={{textDecoration:"none"}} title="Previous" width={20} height={15} viewBox="0 0 20 20" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6" /></svg>}
                        nextLabel={<svg xmlns="http://www.w3.org/2000/svg" style={{textDecoration:"none"}}  title="Next" width={20} height={15} viewBox="0 0 20 20" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6" /></svg>}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={Math.ceil(props.gargiKnowledgeBaseState.listOfQnaArray.length/props.gargiKnowledgeBaseState.countPerPage)}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={1}
                        onPageChange={props.handlePageClick}
                        containerClassName={'pagination kb-pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        forcePage={props.gargiKnowledgeBaseState.currentPage}
                        />
                      )}
                     </div>                 
                  </div>)}              
                </div>
                <div>
                  {!props.gargiKnowledgeBaseState.initialLoader ?
                  <>
                  {!props.gargiKnowledgeBaseState.showUnansweredQuestion ? 
                  <div className="row" style={{paddingTop:"10px",marginBottom:'50px'}}>
                     <div className="col-md-12">
                        <div className="row" style={{backgroundColor: '#F2F2F2', padding: 8,margin:3}}>
                          <div className="col-md-6 ">
                            <input className="vs-input--input kb-search font_family_montserrat" type="text" name="searchUser" placeholder="Search the knowledge base" autoComplete="off" width="250px" onChange={props.handleFilterQna}/>
                            <span className="kb-qna-pair-count font_family_montserrat">{props.gargiKnowledgeBaseState.listOfQnaArray.length } FAQ</span>
                          </div>
                          <div className="col-md-6">
                            <div className="pull-right" style={{color: '#0078D7', fontWeight: 600, padding: 8}}>
                              <span style={{marginRight:5}}>
                                <a  href="# " style={{textDecoration:"none",cursor:'pointer'}} onClick={props.uploadQnAFilePopUp}> 
                                  {/* <i className="material-icons font_family_montserrat" style={{fontSize: 20, display: 'inline-block', textAlign: 'center', verticalAlign: 'bottom',color:'rgb(13, 138, 255)'}}>file_upload</i>  */}
                                  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-upload" style={{marginRight:1, display: 'inline-block', textAlign: 'center', verticalAlign: 'sub',color:'rgb(13, 138, 255)'}}><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="17 8 12 3 7 8" /><line x1={12} y1={3} x2={12} y2={15} /></svg>
                                  <span className="kb-add-qna font_family_montserrat">Upload CSV</span>
                                </a>
                              </span>
                              <span>
                                <a href="# " style={{textDecoration:"none",cursor:'pointer'}} onClick={props.onAddNewQnA}> 
                                  {/* <i className="material-icons font_family_montserrat" style={{fontSize: 20, display: 'inline-block', textAlign: 'center', verticalAlign: 'bottom',color:'rgb(13, 138, 255)'}}>add</i>  */}
                                  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus" style={{fontSize: 20, display: 'inline-block', textAlign: 'center', verticalAlign: 'bottom',color:'rgb(13, 138, 255)'}}><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                  <span className="kb-add-qna font_family_montserrat">Add FAQ</span>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div><br />
                      </div>

                      <div className="col-md-12 col-sm-12 col-xs-12" style={{marginBottom:"10px"}}>
                        <table className="nj-table nj-table--tbody-table font_family_montserrat" style={{tableLayout:'fixed'}}>
                          <thead className="nj-table--thead">
                            <tr style={{backgroundColor:'#F2F2F2'}}>
                              <th>
                                <div className="nj-table-text" style={{fontWeight: 600}}>Question <span title="Question should contain only alphabets with lowercase."><svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-info" style={{verticalAlign:'sub',color:'#7367f0'}} ><circle cx={12} cy={12} r={10} /><line x1={12} y1={16} x2={12} y2={12} /><line x1={12} y1={8} x2="12.01" y2={8} /></svg></span></div>
                              </th>
                              <th>
                                <div className="nj-table-text" style={{fontWeight: 600}}>Answer</div>
                              </th>
                              <th style={{width:"30px"}}>                           
                              </th>
                            </tr>
                          </thead>                       
                            <tbody>
                            {props.gargiKnowledgeBaseState.showAddNewQna && (
                              <>
                                {props.gargiKnowledgeBaseState.addGargiQuestionAndAnswer.map((data,i)=>{
                                  return (
                                  <tr key={i}  className="tr-values kb-tr-values nj-table--tr tr-table-state-null" style={{borderBottom:'1px solid #bdbbbb'}}>
                                      <td className="td nj-table--td">
                                        <textarea id={'question'+i} autoFocus={true}  className="vs-input--input font_family_montserrat kb-edit-qna-textarea" type="text" name="question" placeholder="Enter question" value={data.question} autoComplete="off" onChange={(e)=>{props.handleChangeNewQna(i,e.target.name,e.target.value)}} onFocus={(e)=>{auto_grow('question'+i)}} onInput={(e)=>{auto_grow('question'+i)}}></textarea>
                                      </td>
                                      <td className="td nj-table--td">
                                        <span>
                                        <textarea autoFocus={true}  id={'answer'+i}  className="vs-input--input font_family_montserrat kb-edit-qna-textarea" type="text" name="answer" placeholder="Enter answer" value={data.answer}  autoComplete="off" onChange={(e)=>{props.handleChangeNewQna(i,e.target.name,e.target.value)}} onFocus={(e)=>{auto_grow('answer'+i)}} onInput={(e)=>{auto_grow('answer'+i)}}></textarea>
                                        </span>
                                      </td>
                                      <td className="td nj-table--td">                                
                                        <span style={{fontSize: 20, cursor: 'pointer', outline: 'none'}} onClick={(e)=>{props.handleChangeDeleteNewQna(i,e.target.name)}}><i className="fa fa-trash-o pull-right"  style={{color:'red'}}/></span> 
                                      </td>                 
                                  </tr>
                                  )
                                })}
                              </>
                          )}

                            {props.gargiKnowledgeBaseState.showListOfQna && (
                              <>
                                {props.gargiKnowledgeBaseState.listOfQnaArray.slice((props.gargiKnowledgeBaseState.currentPage)*props.gargiKnowledgeBaseState.countPerPage,(props.gargiKnowledgeBaseState.currentPage)*props.gargiKnowledgeBaseState.countPerPage+props.gargiKnowledgeBaseState.countPerPage).map((data,i)=>{
                                  return (<tr key={i}  className="tr-values nj-table--tr tr-table-state-null" style={{borderBottom:'1px solid #bdbbbb'}}>
                                      <td className="td nj-table--td">
                                        <div>
                                          {data.questionEdit ? 
                                          <form style={{display: 'inline'}}><textarea id={'editQuestion'+i}  className="vs-input--input font_family_montserrat kb-edit-qna-textarea" value={data.question} type="text" name="question" autoFocus={true} style={{padding: 4, border: '1px solid #a6cff1', borderRadius: 2}} onChange={(e)=>{props.onEditQuestionAndAnswerWithValidation(e,data)}} onBlur={(e)=>{props.onEditQuestionAndAnswer(e,data)}} onFocus={(e)=>{auto_grow('editQuestion'+i)}} onInput={(e)=>{auto_grow('editQuestion'+i)}}></textarea></form>
                                          :
                                          <span><span className="kb-question-chip" onClick={(e)=>{props.onEditQuestionEnable(data._id)}}>{data.question || '--'}</span><span className="kb-question-close" onClick={(e)=>{props.deleteQna(data)}}><svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} style={{verticalAlign:"middle",marginTop:"-2px"}} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                                            <line x1={18} y1={6} x2={6} y2={18} /><line x1={6} y1={6} x2={18} y2={18} /></svg></span></span>
                                          }
                                        </div>
                                      </td>
                                      <td className="td nj-table--td">
                                        <div>
                                          {data.answerEdit ? 
                                          <form style={{display: 'inline'}}><textarea id={'editAnswer'+i} className="vs-input--input font_family_montserrat kb-edit-qna-textarea" value={data.answer} type="text" name="answer" autoFocus={true} style={{padding: 4, border: '1px solid #a6cff1', borderRadius: 2}} onChange={(e)=>{props.onEditQuestionAndAnswerWithValidation(e,data)}} onBlur={(e)=>{props.onEditQuestionAndAnswer(e,data)}} onFocus={(e)=>{auto_grow('editAnswer'+i)}} onInput={(e)=>{auto_grow('editAnswer'+i)}}></textarea></form>
                                          :
                                          <span onClick={(e)=>{props.onEditAnswerEnable(data._id)}}>{data.answer || "--"}</span>
                                          }
                                        </div>
                                        </td>
                                      <td className="td nj-table--td">                                
                                        <span style={{fontSize: 20, cursor: 'pointer', outline: 'none'}} onClick={(e)=>{props.deleteQna(data)}}><i className="fa fa-trash-o pull-right" style={{color:'red'}}/></span> 
                                      </td>
                                  </tr>)
                                })} 
                              </>           
                            )}
                          </tbody>                         
                        </table>
                      </div>
                      {((props.gargiKnowledgeBaseState.listOfQnaArray.length===0 && props.gargiKnowledgeBaseState.addGargiQuestionAndAnswer.length===0) && !props.gargiKnowledgeBaseState.kbSearchValueEnable && props.gargiKnowledgeBaseState.showNodataFound) && (
                        <div className="row" style={{height:"72vh",textAlign:'center'}}>
                          <div style={{marginTop:'36vh'}}>
                            <div className="">
                              <button className="nj-flow-button font_family_montserrat" type="button" onClick={props.uploadQnAFilePopUp}>  
                                {/* <i className="material-icons font_family_montserrat" style={{fontSize: 20, display: 'inline-block', textAlign: 'center', verticalAlign: 'bottom',}}>file_upload</i>  */}
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-upload" style={{marginRight:1, display: 'inline-block', textAlign: 'center', verticalAlign: 'sub'}}><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="17 8 12 3 7 8" /><line x1={12} y1={3} x2={12} y2={15} /></svg>
                                <span className=" font_family_montserrat">Upload CSV</span>
                              </button>
                            </div>
                            <div style={{fontSize: 18, margin: 6}}><img  src="/img/devider.png" alt=""/></div>
                            <div className="">
                              <button className="nj-flow-button font_family_montserrat" type="button" onClick={props.onAddNewQnA}>  
                                {/* <i className="material-icons font_family_montserrat" style={{fontSize: 20, display: 'inline-block', textAlign: 'center', verticalAlign: 'bottom'}}>add</i>  */}
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus" style={{fontSize: 20, display: 'inline-block', textAlign: 'center', verticalAlign: 'bottom'}}><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                <span className="font_family_montserrat">Add FAQ</span>
                              </button>
                            </div>
                          </div>                       
                        </div>                           
                      )}
                      {(props.gargiKnowledgeBaseState.listOfQnaArray.length===0 && props.gargiKnowledgeBaseState.kbSearchValueEnable) && (
                        <div className="row" style={{height:"70vh",textAlign:'center'}}>
                          <div style={{marginTop:'45vh'}}>
                            No data Found
                          </div>                       
                        </div>                           
                      )}

                      {/* delete qna pop up start*/}
                      <div id="deleteQnaPopup" className={"nj-overly add-rebound-animation"} style={{zIndex:"3"}}>
                        <div className="popup-nj my-popup">
                          <header className="popup-header">
                            <span>Delete Question</span>
                            <span  style={{cursor:"pointer"}} onClick={props.hideDeleteQnaPopUp}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
                          </header>
                          <div className="popup-content">
                            <div className="col-md-12">
                              <form name="roleForm">
                                <div className="nj-popup-form-wrap">
                                  <p className="popup-content-label font_family_montserrat" style={{fontSize:"16px"}}>Do you want to delete this question?</p>
                                </div>
                                {props.gargiKnowledgeBaseState.deleteConfirmLoader ?
                                  <div className={"pull-right"}>
                                    <img src={"/img/loaders/spinningwheel.gif"} style={{marginTop:"0px"}} height={50} width={50} alt="loader" />
                                  </div> 
                                :
                                <button type="button" onClick={props.confirmDeleteQna} className="nj-flow-button pull-right" style={{margin:5}}> Confirm</button>
                                }
                                <div><button type="button" onClick={props.hideDeleteQnaPopUp} disabled={props.gargiKnowledgeBaseState.deleteConfirmLoader} className="nj-flow-button bg-color-red pull-right"  style={{margin:5}}>Cancel</button></div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* delete qna pop up end*/}

                      {/* file Upload pop up start*/}
                      <div id="uploadQnaCsvPopup" className={"nj-overly add-rebound-animation"} style={{zIndex:"3"}}>
                        <div className="popup-nj my-popup">
                          <header className="popup-header">
                            <span>Upload CSV</span>
                            <span  style={{cursor:"pointer"}} onClick={props.hideFileUploadQnaPopUp}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
                          </header>
                          <div className="popup-content">
                            <div className="col-md-12">
                              <form name="kbForm" onSubmit={(e)=>props.confirmFileUploadQna(e)}>
                              <div className="">
                                  <p className="terms-form">Upload CSV</p>
                                  <div  id="file-upload-wrapper1">
                                    <input id="uploadKbFile"  className="vs-input--input font_family_montserrat" type="file" name="file" accept=".csv" onChange={props.handleUploadFile} placeholder="Select File" autoComplete="off" required />
                                  </div>
                                </div><br/>
                                {props.gargiKnowledgeBaseState.kbFileUploadConfirmLoader ?
                                  <div className={"pull-right"}>
                                    <img src={"/img/loaders/spinningwheel.gif"} style={{marginTop:"0px"}} height={50} width={50} alt="loader" />
                                  </div> 
                                :
                                <button type="submit"  className="nj-flow-button pull-right" style={{margin:5}}> Save And Train</button>
                                }
                                <div><button type="button" onClick={props.hideFileUploadQnaPopUp} disabled={props.gargiKnowledgeBaseState.kbFileUploadConfirmLoader} className="nj-flow-button bg-color-red pull-right"  style={{margin:5}}>Cancel</button></div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* file Upload pop up end*/}
                      <div className="row">
                        <div className="col-md-12" style={{textAlign:"center"}}>
                          {props.gargiKnowledgeBaseState.listOfQnaArray.length>props.gargiKnowledgeBaseState.countPerPage && (
                            <Paginate 
                            previousLabel={<svg xmlns="http://www.w3.org/2000/svg" title="Previous" width={20} height={15} viewBox="0 0 20 20" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6" /></svg>}
                            nextLabel={<svg xmlns="http://www.w3.org/2000/svg" title="Next" width={20} height={15} viewBox="0 0 20 20" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6" /></svg>}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={Math.ceil(props.gargiKnowledgeBaseState.listOfQnaArray.length/props.gargiKnowledgeBaseState.countPerPage)}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={1}
                            onPageChange={props.handlePageClick}
                            containerClassName={'pagination kb-pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            forcePage={props.gargiKnowledgeBaseState.currentPage}
                            />
                          )}                    
                        </div>
                    </div>
                  </div>
                :
                 
                  <div className="row" style={{paddingTop:"20px",marginBottom:'50px'}}> 
                   {/*unanswered questions*/}                
                    <div className="col-md-12 col-sm-12 col-xs-12" style={{marginBottom:"10px",maxHeight:550,overflowY:'scroll'}}>
                      <table className="nj-table nj-table--tbody-table font_family_montserrat" style={{tableLayout:'fixed'}}>
                        <thead className="nj-table--thead">
                          <tr style={{backgroundColor:'#F2F2F2'}}>
                            <th>
                              <div className="nj-table-text" style={{fontWeight: 600}}>Unanswered Questions </div>
                            </th> 
                            <th style={{width:"100px"}}>                           
                            </th>                      
                            <th style={{width:"30px"}}>                           
                            </th>
                          </tr>
                        </thead>                       
                        <tbody>
                          {props.gargiKnowledgeBaseState.unansweredQuestionArray.length>0 ?
                            <>
                                {props.gargiKnowledgeBaseState.unansweredQuestionArray.map((data,i)=>{
                                return (<tr key={i}  className="tr-values nj-table--tr tr-table-state-null" style={{borderBottom:'1px solid #bdbbbb'}}>
                                    <td className="td nj-table--td">
                                      <div>
                                        <span>{data.question}</span>
                                      </div>
                                    </td>
                                    <td>
                                    <button type="button" className="nj-flow-button pull-right" onClick={(e)=>{props.showUnansweredQuestionPopup(data)}} style={{margin:5}}>Answer</button>
                                    </td>
                                    <td className="td nj-table--td">                                
                                      <span style={{fontSize: 20, cursor: 'pointer', outline: 'none'}} onClick={(e)=>{props.showDeleteUnansweredQuestionPopup(data)}}><i className="fa fa-trash-o pull-right" style={{color:'red'}}/></span> 
                                    </td>
                                </tr>)
                              })}                             
                         </> 

                        :
                          <tr style={{border:"none"}}>
                            <td colSpan="3">
                              <div className="row" style={{height:"60vh",textAlign:'center'}}>
                                <div style={{marginTop:'30vh'}}>
                                  No data Found
                                </div>                       
                              </div>
                            </td>  
                          </tr>                                            
                        }
                      </tbody>                         
                    </table>
                  </div>
                  <>
                   {/* update unanswered questions */}
                   <div id="unansweredQuestionPopup" className={"nj-overly add-rebound-animation"} style={{zIndex:"3"}}>
                    <div className="popup-nj my-popup">
                      <header className="popup-header">
                        <span>Edit</span>
                        <span  style={{cursor:"pointer"}} onClick={props.hideUnansweredPopup}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
                      </header>
                      <div className="popup-content">
                        <div className="col-md-12">
                          <form name="unansweredQuestionForm" onSubmit={(e)=>props.saveUnansweredQuestion(e)}>
                            <div className="">
                              <label className="terms-form">Question</label>                                       
                              <textarea id='unansweredQuestion' className="vs-input--input font_family_montserrat kb-edit-qna-textarea" value={props.gargiKnowledgeBaseState.unansweredQuestion.question} type="text" name="question" autoFocus={true} required style={{padding: 4, border: '1px solid #a6cff1', borderRadius: 2}} onChange={(e)=>{props.handleChangeUnansweredQuestion(e)}} onFocus={(e)=>{auto_grow('unansweredQuestion')}} onInput={(e)=>{auto_grow('unansweredQuestion')}}></textarea>                                    
                            </div>
                            <br/>
                            <div>
                            <label className="terms-form">Enter Answer</label>                                       
                                <textarea id='answeredQuestion' className="vs-input--input font_family_montserrat kb-edit-qna-textarea" placeholder="Enter answer here" value={props.gargiKnowledgeBaseState.unansweredQuestion.answer} type="text" name="answer" autoFocus={true} required style={{padding: 4, border: '1px solid #a6cff1', borderRadius: 2}} onChange={(e)=>{props.handleChangeUnansweredQuestion(e)}} onFocus={(e)=>{auto_grow('answeredQuestion')}} onInput={(e)=>{auto_grow('answeredQuestion')}}></textarea>                                  
                            </div>
                            <br/>
                            {props.gargiKnowledgeBaseState.editUnansweredPopLoader ?
                              <div className={"pull-right"}>
                                <img src={"/img/loaders/spinningwheel.gif"} style={{marginTop:"-4px"}} height={50} width={50} alt="loader" />
                              </div> 
                            :
                            <button type="submit"  className="nj-flow-button pull-right" style={{margin:5}}> Save And Train</button>
                            }
                            <div><button type="button" onClick={props.hideUnansweredPopup} disabled={props.gargiKnowledgeBaseState.editUnansweredPopLoader} className="nj-flow-button bg-color-red pull-right"  style={{margin:5}}>Cancel</button></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* delete unansweredQuestion pop up start*/}
                  <div id="deleteUnansweredQuestionPopup" className={"nj-overly add-rebound-animation"} style={{zIndex:"3"}}>
                    <div className="popup-nj my-popup">
                      <header className="popup-header">
                        <span>Delete Question</span>
                        <span  style={{cursor:"pointer"}} onClick={props.hideDeleteUnansweredQuestionPopup}><i className="vs-icon notranslate icon-scale vs-dialog-cancel vs-dialog-cancel--icon notranslate material-icons null">close</i></span>
                      </header>
                      <div className="popup-content">
                        <div className="col-md-12">
                          <form name="roleForm">
                            <div className="nj-popup-form-wrap">
                              <p className="popup-content-label font_family_montserrat" style={{fontSize:"16px"}}>Do you want to delete this question?</p>
                            </div>
                            {props.gargiKnowledgeBaseState.deleteUnansweredQuestionConfirmLoader ?
                              <div className={"pull-right"}>
                                <img src={"/img/loaders/spinningwheel.gif"} style={{marginTop:"0px"}} height={50} width={50} alt="loader" />
                              </div> 
                            :
                            <button type="button" onClick={props.deleteUnansweredQuestionConfirm} className="nj-flow-button pull-right" style={{margin:5}}> Confirm</button>
                            }
                            <div><button type="button" onClick={props.hideDeleteUnansweredQuestionPopup} disabled={props.gargiKnowledgeBaseState.deleteUnansweredQuestionConfirmLoader} className="nj-flow-button bg-color-red pull-right"  style={{margin:5}}>Cancel</button></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* delete unansweredQuestion pop up end*/}
                </>
                </div>
                }   
                </>
                  :
                  <div className="row" style={{height:"72vh"}}>
                    <div className="col-md-12 aligh-loader-image-center" >
                      <img src={"/img/loaders/spinningwheel.gif"}  height={80} width={80} alt="loader" />
                    </div>
                  </div>
                  }             
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


const mapStateToProps = state => ({
  gargiKnowledgeBaseState: state.gargiKnowledgeBaseState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onAddNewQnA:GargiKnowledgeBaseContainer.onAddNewQnA,
  onSaveGargiQna:GargiKnowledgeBaseContainer.onSaveGargiQna,
  handleChangeNewQna:GargiKnowledgeBaseContainer.handleChangeNewQna,
  getListOfQna:GargiKnowledgeBaseContainer.getListOfQna,
  handleChangeDeleteNewQna:GargiKnowledgeBaseContainer.handleChangeDeleteNewQna,
  onEditQuestionEnable:GargiKnowledgeBaseContainer.onEditQuestionEnable,
  onEditQuestionAndAnswer:GargiKnowledgeBaseContainer.onEditQuestionAndAnswer,
  onEditAnswerEnable:GargiKnowledgeBaseContainer.onEditAnswerEnable,
  deleteQna:GargiKnowledgeBaseContainer.deleteQna,
  hideDeleteQnaPopUp:GargiKnowledgeBaseContainer.hideDeleteQnaPopUp,
  confirmDeleteQna:GargiKnowledgeBaseContainer.confirmDeleteQna,
  uploadQnAFilePopUp:GargiKnowledgeBaseContainer.uploadQnAFilePopUp,
  hideFileUploadQnaPopUp:GargiKnowledgeBaseContainer.hideFileUploadQnaPopUp,
  confirmFileUploadQna:GargiKnowledgeBaseContainer.confirmFileUploadQna,
  handleUploadFile:GargiKnowledgeBaseContainer.handleUploadFile,
  handleFilterQna:GargiKnowledgeBaseContainer.handleFilterQna,
  handlePageClick:GargiKnowledgeBaseContainer.handlePageClick,
  handleChangeView:GargiKnowledgeBaseContainer.handleChangeView,
  onEditQuestionAndAnswerWithValidation:GargiKnowledgeBaseContainer.onEditQuestionAndAnswerWithValidation,
  toggleShowUnansweredQuestion:GargiKnowledgeBaseContainer.toggleShowUnansweredQuestion,
  setGargiKnowledgeBaseElements:Actions.setGargiKnowledgeBaseElements,
  showUnansweredQuestionPopup:GargiKnowledgeBaseContainer.showUnansweredQuestionPopup,
  handleChangeUnansweredQuestion:GargiKnowledgeBaseContainer.handleChangeUnansweredQuestion,
  hideUnansweredPopup:GargiKnowledgeBaseContainer.hideUnansweredPopup,
  saveUnansweredQuestion:GargiKnowledgeBaseContainer.saveUnansweredQuestion,
  showDeleteUnansweredQuestionPopup:GargiKnowledgeBaseContainer.showDeleteUnansweredQuestionPopup,
  hideDeleteUnansweredQuestionPopup:GargiKnowledgeBaseContainer.hideDeleteUnansweredQuestionPopup,
  deleteUnansweredQuestionConfirm:GargiKnowledgeBaseContainer.deleteUnansweredQuestionConfirm
  
  

}, dispatch)

export default connect(
  mapStateToProps,mapDispatchToProps
)(GargiKnowledgeBase);
