import React, { useState ,useEffect} from 'react';
import './Ratings.scss'
function Ratings (props) {
  const [rating, setRating] = useState(props.rating || null);
  const [dummyRating, setdummyRating] = useState(null);
  // const [starHtml, setStarHtml] = useState([]);

  useEffect( () => {  
    //props.getAllApplications(); 
    //loadRatings();
  },[]);
  // onload function end

  var getRatings=(rating)=> {
    if(props.disable !== true){
      // console.log("ratings",rating)
      setRating(rating);
      setdummyRating(rating)
      props.getRatings(rating);
    }
    
  }
  
  var star_over=(ratings)=>{
    if(props.disable !== true){
      var tempDummyRating=rating
      setRating(ratings);
      setdummyRating(tempDummyRating)
    }    
  }
  var star_out=()=>{
    if(props.disable !== true){
      setRating(dummyRating);
    }    
  }
  return (
      <div className={"star-rating "+props.classname}>
        <label  className={'star-rating__star label-margin-padding-buttom '+(rating>=1?'is-selected':'')} onClick={()=>{getRatings(1)}} onMouseOver={()=>{star_over(1)}} onMouseOut={star_out} style={(rating>=1?{color:props.color || "#7367f0"}:{})} title="Poor">         
          <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 24 24" fill="currentColor" stroke={props.color || "#7367f0"} strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="feather feather-star">
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
          </svg>
        </label>
        <label className={'star-rating__star label-margin-padding-buttom '+(rating>=2?'is-selected':'')}  onClick={()=>{getRatings(2)}} onMouseOver={()=>{star_over(2)}} onMouseOut={star_out} style={(rating>=2?{color:props.color || "#7367f0"}:{})} title="Below Average">
          <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 24 24" fill="currentColor" stroke={props.color || "#7367f0"} strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="feather feather-star">
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
          </svg>
        </label>
        <label  className={'star-rating__star label-margin-padding-buttom '+(rating>=3?'is-selected':'')}  onClick={()=>{getRatings(3)}} onMouseOver={()=>{star_over(3)}} onMouseOut={star_out} style={(rating>=3?{color:props.color || "#7367f0"}:{})} title="Average">
          <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 24 24" fill="currentColor" stroke={props.color || "#7367f0"} strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="feather feather-star">
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
          </svg>
        </label>
        <label className={'star-rating__star label-margin-padding-buttom '+(rating>=4?'is-selected':'')} onClick={()=>{getRatings(4)}} onMouseOver={()=>{star_over(4)}} onMouseOut={star_out} style={(rating>=4?{color:props.color || "#7367f0"}:{})} title="Good">
          <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 24 24" fill="currentColor" stroke={props.color || "#7367f0"} strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="feather feather-star">
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
          </svg>
        </label>
        <label  className={'star-rating__star label-margin-padding-buttom '+(rating===5?'is-selected':'')}  onClick={()=>{getRatings(5)}} onMouseOver={()=>{star_over(5)}} onMouseOut={star_out} style={(rating>=5?{color:props.color || "#7367f0"}:{})} title="Excellent">
          <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 24 24" fill="currentColor" stroke={props.color || "#7367f0"} strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="feather feather-star">
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
          </svg>
        </label>
      </div>
  );
}
 export default Ratings;