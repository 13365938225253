
// import axios from "axios";
import * as CourseActions from "./index";
import customHttp from '../../../packages/CustomHttp/';
import snackbar from '../../../packages/Snackbar/';

// for applications

export  function getAllApplications (){
     return async (dispatch ,getState)=> {
         try {
            var userDetails=JSON.parse(sessionStorage.getItem("userData"));
            let response = await customHttp.get('/api/applications/all');
            if(response.data.message && Array.isArray(response.data.message)){
               // console.log("response",response.data.message);
               response.data.message.forEach(function(element) { element.isSelected = false});
               dispatch(CourseActions.getAllApplications(response.data.message));
               dispatch(handleAddApps())
               if(Object.keys(getState().courseState.selectedCourse).length>0){
                  dispatch(handleCourseEditAddApps());
               }
            }else{
               dispatch(CourseActions.getAllApplications([]));
            }
         } catch (err) {
            dispatch(CourseActions.getAllApplications([]));
            console.log("error",err)
         }
     }
  }

  export  function getConfidentialDetails(){
   return async (dispatch ,getState)=> {
       try {
         var userDetails=JSON.parse(sessionStorage.getItem("userData"))
          let response = await customHttp.get('/api/confidentialDetails/user/'+userDetails.belongsTo);
          console.log("response",response.data.message)
          dispatch(setCourseElements('enableLiveClassroom',response.data.message[0].enableLiveClassroom))
          dispatch(setCourseElements('enableRemoteProctoring',response.data.message[0].enableRemoteProctoring))
          dispatch(setCourseElements('adminConfidentialDetails',response.data.message))
       
       } catch (err) {
         dispatch(setCourseElements('enableLiveClassroom',false))
         dispatch(setCourseElements('enableRemoteProctoring',false))
         dispatch(setCourseElements('adminConfidentialDetails',[]))
            console.log("error",err)
       }
   }
}


  export  function getAllResources(selectedArray){
   return async (dispatch ,getState)=> {

      dispatch(CourseActions.setBooleanValue("showAllResourcesLoader",false))
       try {
          let response = await customHttp.get('/api/resourceUri/fetchAllResource');
         //  console.log("selectedResources",selectedArray);
          if(response.data.message && Array.isArray(response.data.message)){
            if(selectedArray.length>0){
             for(var k=0;k<selectedArray.length;k++){
               for(var l=0;l<response.data.message.length;l++){
                if(response.data.message[l]._id===selectedArray[k]._id){
                  response.data.message.splice(l,1);
                }
               }
             }
            }
            response.data.message.forEach(function(element) { element.isSelected = false});
            dispatch(CourseActions.getAllResources(response.data.message));
          }else{
            dispatch(CourseActions.getAllResources([]));
          }
          dispatch(CourseActions.setBooleanValue("showAllResourcesLoader",true))

        } catch (err) {
          dispatch(CourseActions.getAllResources([]));
          dispatch(CourseActions.setBooleanValue("showAllResourcesLoader",true))
          console.log("error",err)
        }
   }
}

export function selectApplication (app){
   return (dispatch ,getState)=> {
      var duplicateApplicationList=JSON.parse(JSON.stringify(getState().courseState.allApplications))
      for(var k=0;k<duplicateApplicationList.length;k++){
          if(duplicateApplicationList[k]._id===app._id){
            duplicateApplicationList[k].isSelected = !duplicateApplicationList[k].isSelected;
         }
      }
      dispatch(CourseActions.selectApplication(duplicateApplicationList));

      let dummy=[];
      getState().courseState.allApplications.forEach(element => {
         if( element.isSelected){
          dummy.push(element);
         }

     });
      dispatch(setCourseElements('appsSelected',dummy))
   }
}

export function handleAddAppsToCourse (){
   return (dispatch ,getState)=> {
      var allApps=JSON.parse(JSON.stringify(getState().courseState.allApplications));
      var apps = JSON.parse(JSON.stringify(getState().courseState.selectedCourse.applications));
      var appsLen = apps.length;
      allApps.filter(obj=>{
         if(obj.isSelected){
            apps.push(obj);
            obj.isSelected = false
         }
         return obj
      })
     
      var course = JSON.parse(JSON.stringify(getState().courseState.selectedCourse));
      course.applications = uniqueApps(apps);
      // console.log("msg",course.applications.length, appsLen);
      if(course.applications.length > appsLen){
         dispatch(CourseActions.setBooleanValue('successSnackbarMessage',"Application added successfully"));
         snackbar.successSnackbar();
         dispatch(CourseActions.setCourseElements('selectedCourse',course));
         dispatch(CourseActions.setCourseElements('allApplications',allApps)); //uncheck all apps
         dispatch(CourseActions.setBooleanValue('showSelectedApplications',true));
      }
      else{
         dispatch(CourseActions.setBooleanValue("errorSnackbarMessage","Please select atleast one application "))
        snackbar.errorSnackbar();
      }
    
   }
}

export function handleCourseEditAddApps (){
   return (dispatch ,getState)=> {
      var allApps=JSON.parse(JSON.stringify(getState().courseState.allApplications));
      var apps = JSON.parse(JSON.stringify(getState().courseState.selectedCourse.applications));
      for(var i=0;i<apps.length;i++){
         for(var j=0;j<allApps.length;j++){
            if(apps[i]._id===allApps[j]._id){
               allApps.splice(j,1)
            }
         }
      }
      dispatch(CourseActions.setCourseElements('allApplications',allApps));
   }
}

export function deleteSelectedApplication (app){
   return (dispatch ,getState)=> {
      var duplicateApplicationList=JSON.parse(JSON.stringify(getState().courseState.selectedApplications))
      for(var k=0;k<duplicateApplicationList.length;k++){
          if(duplicateApplicationList[k]._id===app._id){
            duplicateApplicationList.splice(k,1)
         }
      }
      localStorage.setItem('appsSelected',JSON.stringify(duplicateApplicationList))
      dispatch(CourseActions.deleteSelectedApplication(duplicateApplicationList));
      dispatch(CourseActions.setBooleanValue('successSnackbarMessage',"Application removed successfully"));
      snackbar.successSnackbar();
   }
}

export function deleteCourseApplication (app){
   return (dispatch ,getState)=> {
      var course=JSON.parse(JSON.stringify(getState().courseState.selectedCourse))
      for(var k=0;k<course.applications.length;k++){
          if(course.applications[k]._id===app._id){
            course.applications.splice(k,1)
         }
      }
      // console.log('delete course function',course)
      dispatch(CourseActions.setCourseElements('selectedCourse',course));
      dispatch(CourseActions.setBooleanValue("successSnackbarMessage","Application removed successfully"))
      snackbar.successSnackbar();
   }
}

export function getSelectedApplications (){
   return (dispatch ,getState)=> {
      var dummyArray=[];
      var duplicateApplicationList=JSON.parse(JSON.stringify(getState().courseState.allApplications))
      for(var k=0;k<duplicateApplicationList.length;k++){
          if(duplicateApplicationList[k].isSelected){
            dummyArray.push(duplicateApplicationList[k])
        }
      }

      if(dummyArray.length>0){
      var selectedApplicationsArray=JSON.parse(JSON.stringify(getState().courseState.selectedApplications))
      for(var j=0;j<dummyArray.length;j++){
         selectedApplicationsArray.push(dummyArray[j])
      }

      function uniqueApps(array) {
         var processed = [];
         for (var i=array.length-1; i>=0; i--){
           if (processed.indexOf(array[i].name)<0) {
             processed.push(array[i].name);
           } else {
             array.splice(i, 1);
           }
         }
         return array;
       }
      var uniqueSelectedApps= uniqueApps(selectedApplicationsArray);
      dispatch(CourseActions.getSelectedApplications(uniqueSelectedApps));
      dispatch(getAllApplications())
      dispatch(CourseActions.setBooleanValue("showSelectedApplications",true))
      dispatch(CourseActions.setBooleanValue('successSnackbarMessage',"Application added successfully"));
      snackbar.successSnackbar();
     }
     else{
      dispatch(CourseActions.setBooleanValue("errorSnackbarMessage","Please select atleast one application "))
      snackbar.errorSnackbar();
   }
  }
}
export function clearSelectApplication (){
   return (dispatch ,getState)=> {
      var duplicateApplicationList=JSON.parse(JSON.stringify(getState().courseState.allApplications))
      duplicateApplicationList.forEach(function(element) { element.isSelected = false});
      dispatch(CourseActions.getAllApplications(duplicateApplicationList));
      dispatch(CourseActions.setBooleanValue("showSelectedApplications",true))
      dispatch(CourseActions.setCourseElements('appsSelected',[]));
   }
}

export function handleAddApps (){
   return (dispatch ,getState)=> {
      var allApps=JSON.parse(JSON.stringify(getState().courseState.allApplications));
      var selectedApps = getState().courseState.selectedApplications;
      for(var i=0;i<selectedApps.length;i++){
         for(var j=0;j<allApps.length;j++){
            if(selectedApps[i]._id===allApps[j]._id){
               allApps.splice(j,1)
            }
         }
      }
      dispatch(CourseActions.getAllApplications(allApps));
   }
}

export function routeToAppStore (history){
   return (dispatch ,getState)=> {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      if(userDetails.type===1){
         history.push("/appstore")
         }else if(userDetails.type===2){
         history.push("/dlt/appstore")
         }else{
         history.push("/sub-admin/appstore")
      }
   }
}
//edit course apps
// for resources
export function selectResource (resource){
   return (dispatch ,getState)=> {

      var dublicateResourcesList=JSON.parse(JSON.stringify(getState().courseState.allResources))
      for(var k=0;k<dublicateResourcesList.length;k++){
          if(dublicateResourcesList[k]._id===resource._id){
            dublicateResourcesList[k].isSelected = !dublicateResourcesList[k].isSelected;
        }
      }

      // console.log("selected resourse",dublicateResourcesList)
      dispatch(CourseActions.selectResource(dublicateResourcesList));
      dispatch(CourseActions.setFilteredArray(dublicateResourcesList));

      let dummy=[];
     getState().courseState.filteredArray.forEach(element => {
        if( element.isSelected){
         dummy.push(element);
        }

    });
     dispatch(setCourseElements('selected',dummy))
   }
}

export const  handleSelectAllClick=(event)=>{
   return (dispatch ,getState)=> {
      dispatch(CourseActions.setCourseElements('resourceActualArray',getState().courseState.allResources));

     if (event.target.checked) {
      getState().courseState.filteredArray.forEach(element => {
         element.isSelected=true;
       });

       var selected_dummy= getState().courseState.filteredArray.map(n => n);
       dispatch(CourseActions.selectResource(selected_dummy));
       dispatch(CourseActions.setCourseElements('selected',selected_dummy));
       return;
     }
     getState().courseState.filteredArray.forEach(element => {
       element.isSelected=false;
     });
     var selected_dummy= getState().courseState.filteredArray.map(n => n);
     dispatch(CourseActions.selectResource(selected_dummy));
     dispatch(CourseActions.setCourseElements('selected',[]));
   }
 }

 export const  handleSelectAllClickApps=(event)=>{
   return (dispatch ,getState)=> {
   //   console.log("hh",  getState().courseState.allApplications)
     if (event.target.checked) {
       getState().courseState.allApplications.forEach(element => {
         element.isSelected=true;
       });

       var selected_dummy=  getState().courseState.allApplications.map(n => n);
       dispatch(CourseActions.selectApplication(selected_dummy));
       dispatch(setCourseElements('appsSelected',selected_dummy))
       return;
     }

     getState().courseState.allApplications.forEach(element => {
       element.isSelected=false;
     });
     var selected_dummy=  getState().courseState.allApplications.map(n => n);
     dispatch(CourseActions.selectApplication(selected_dummy));
     dispatch(setCourseElements('appsSelected',[]))

   }
 }


export function routeToResources (history){
   return (dispatch ,getState)=> {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      if(userDetails.type===1){
         history.push("/resources")
         }else if(userDetails.type===2){
         history.push("/dlt/resources")
         }else{
         history.push("/sub-admin/resources")
      }
   }
}

export function deleteSelectedResource (resource){
   return (dispatch ,getState)=> {
      var dublicateResourcesList=JSON.parse(JSON.stringify(getState().courseState.selectedResources))
      for(var k=0;k<dublicateResourcesList.length;k++){
          if(dublicateResourcesList[k]._id===resource._id){
            dublicateResourcesList.splice(k,1)
        }
      }
      dispatch(CourseActions.deleteSelectedResource(dublicateResourcesList));
      dispatch(CourseActions.setBooleanValue('successSnackbarMessage',"Resource removed successfully"));
      snackbar.successSnackbar();
      if(JSON.parse(localStorage.getItem("selectedResources"))!== null && JSON.parse(localStorage.getItem("selectedResources"))!== undefined){
         var temp=JSON.parse(localStorage.getItem("selectedResources"))
         for(var l=0;l<temp.length;l++){
            if(temp[l]._id===resource._id){
               temp.splice(l,1)
          }
        }
        JSON.parse(localStorage.setItem("selectedResources",JSON.stringify(temp)))
      }
   }
}

export function getSelectedResources(){
   return (dispatch ,getState)=> {
      var dummyArray=[];
      var dublicateResourcesList=JSON.parse(JSON.stringify(getState().courseState.allResources))
      for(var k=0;k<dublicateResourcesList.length;k++){
          if(dublicateResourcesList[k].isSelected){
            dummyArray.push(dublicateResourcesList[k])
        }
      }

      if(dummyArray.length>0){
         var selectedResourceArray=JSON.parse(JSON.stringify(getState().courseState.selectedResources))
         for(var j=0;j<dummyArray.length;j++){
            selectedResourceArray.push(dummyArray[j])
         }

         function uniqueApps(array) {
            var processed = [];
            for (var i=array.length-1; i>=0; i--){
              if (processed.indexOf(array[i].name)<0) {
                processed.push(array[i].name);
              } else {
                array.splice(i, 1);
              }
            }
            return array;
         }
         var uniqueSelectedResources= uniqueApps(selectedResourceArray);
         dispatch(CourseActions.getSelectedResources(uniqueSelectedResources));
         localStorage.setItem("selectedResources",JSON.stringify(uniqueSelectedResources))

        // dispatch(getAllResources())
         dispatch(CourseActions.setBooleanValue("showSelectedResources",true))
         dispatch(CourseActions.setBooleanValue("successSnackbarMessage","Resource added successfully"))
         snackbar.successSnackbar();
      } else{
         dispatch(CourseActions.setBooleanValue("errorSnackbarMessage","Please select atleast one resource"))
         snackbar.errorSnackbar();
      }
   }
}
export function clearSelectResource (){
   return (dispatch ,getState)=> {
      var dublicateResourcesList=JSON.parse(JSON.stringify(getState().courseState.allResources))
      dublicateResourcesList.forEach(function(element) { element.isSelected = false});
      dispatch(CourseActions.setFilteredArray(dublicateResourcesList));
      dispatch(CourseActions.getAllResources(dublicateResourcesList));
      dispatch(CourseActions.setBooleanValue("showSelectedResources",true));
      dispatch(CourseActions.setCourseElements('selected',[]));
   }
}

export function clearEditSelectedResource (){
   return (dispatch ,getState)=> {
      var dublicateResourcesList=JSON.parse(JSON.stringify(getState().courseState.allResources))
      dublicateResourcesList.forEach(function(element) { element.isSelected = false});
      dispatch(CourseActions.setFilteredArray(dublicateResourcesList));
      dispatch(CourseActions.getAllResources(dublicateResourcesList));
      dispatch(CourseActions.setBooleanValue("editShowSelectedResources",true));
      dispatch(CourseActions.setCourseElements('selected',[]));
   }
}

export function getEditSelectedResources(){
   return (dispatch ,getState)=> {
      var dummyArray=[];
      var dublicateResourcesList=JSON.parse(JSON.stringify(getState().courseState.allResources))
      for(var k=0;k<dublicateResourcesList.length;k++){
          if(dublicateResourcesList[k].isSelected){
            dummyArray.push(dublicateResourcesList[k])
        }
      }
      if(dummyArray.length>0){
         var selectedResourceArray=JSON.parse(JSON.stringify(getState().courseState.selectedCourse.resources))
         var selectedCourse=JSON.parse(JSON.stringify(getState().courseState.selectedCourse))
         for(var j=0;j<dummyArray.length;j++){
            selectedResourceArray.push(dummyArray[j])
         }

         function uniqueApps(array) {
            var processed = [];
            for (var i=array.length-1; i>=0; i--){
            if (processed.indexOf(array[i].name)<0) {
               processed.push(array[i].name);
            } else {
               array.splice(i, 1);
            }
            }
            return array;
         }
         var uniqueSelectedResources= uniqueApps(selectedResourceArray);
         selectedCourse.resources=uniqueSelectedResources;
         dispatch(CourseActions.initiateOnloadFunction("selectedCourse",selectedCourse))
         dispatch(getAllResources())
         const all = {_id:'all'}
         const selectedArchiveType={name:"Unarchived",_id:false}
         dispatch(getAllCourseResources(all,all,selectedArchiveType,undefined,"onload"))
         dispatch(CourseActions.setBooleanValue("editShowSelectedResources",true))
         dispatch(CourseActions.setBooleanValue("successSnackbarMessage","Resource added successfully"))
         snackbar.successSnackbar();
      }else{
         dispatch(CourseActions.setBooleanValue("errorSnackbarMessage","Please select atleast one resource"))
         snackbar.errorSnackbar();
      }
   }
}


export function createCourse(e,props){
   e.preventDefault()
   return async (dispatch ,getState)=> {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      var finalObject=getState().courseState;

      var fromDateInSec = new Date(finalObject.fromDate);
      var tempDate = fromDateInSec.getDate();
      var tempMonth = fromDateInSec.getMonth()+1;
      var tempYear = fromDateInSec.toString().substring(11,15);
      var fromDate = tempYear + "/" + tempMonth + "/" + tempDate;
      // var fromDate = tempDate + "/" + tempMonth + "/" + tempYear;

      var toDateInSec = new Date(finalObject.toDate);
      var tempToDate = toDateInSec.getDate();
      var tempToMonth = toDateInSec.getMonth()+1;
      var tempToYear = toDateInSec.toString().substring(11,15);
      var toDate = tempToYear + "/" + tempToMonth + "/" + tempToDate;
      // var toDate = tempToDate + "/" + tempToMonth + "/" + tempToYear;

      var resources=[];
      var count=0;
      var applications = []

      var applicationsSelected = JSON.parse(JSON.stringify(getState().courseState.selectedApplications)) || [];
      applicationsSelected.map(obj=>{
         applications.push(obj._id)
         return obj
      })
      var selectedResourceArray=JSON.parse(JSON.stringify(getState().courseState.selectedResources))
      for(var j=0;j<selectedResourceArray.length;j++){
         if(selectedResourceArray[j].type===4){
            count=count+ parseInt(selectedResourceArray[j].timeInvestment);
            resources.push(selectedResourceArray[j]._id)
         }else{
            count=count+ parseInt(selectedResourceArray[j].file[0].timeInvestment);
            resources.push(selectedResourceArray[j]._id)
         }

      }
      var object={};
      object.startDate=fromDate;
      object.endDate=toDate;
      object.displayName=finalObject.displayName;
      object.numberOfUsers=finalObject.numberOfUsers;
      object.slotHours=finalObject.slotHours;
      object.name=finalObject.courseName;
      object.userId=userDetails._id;
      object.belongsTo=userDetails.belongsTo;
      object.resources=resources;
      object.courseCode=finalObject.courseCode;
      object.timeInvestment=count;
      object.applications=applications;
      object.emailId=userDetails.email;
      object.adminId=userDetails._id;
      object.isLiveClassRoom=finalObject.enableLiveClassroom;
      object.isUserSlotBooking = finalObject.isUserSlotBooking;
      object.enableRemoteProctoring=finalObject.enableRemoteProctoring;
      //console.log("object",object)

      if(selectedResourceArray.length>0 || applicationsSelected.length>0){
         dispatch(CourseActions.setBooleanValue("showCourseSaveLoader",true))
         try {
            let response = await customHttp.put('/api/course/create',object);
            //console.log("response",response.data.message);
            dispatch(CourseActions.setBooleanValue("successSnackbarMessage","Course created successfully"))
            snackbar.successSnackbar();
            localStorage.setItem("selectedResources",JSON.stringify([]));
            localStorage.setItem("appsSelected",JSON.stringify([]));
            dispatch(CourseActions.setBooleanValue("showCourseSaveLoader",false))
            dispatch(CourseActions.initiateOnloadFunction("courseName",""))
            dispatch(CourseActions.initiateOnloadFunction("displayName",""))
            dispatch(CourseActions.initiateOnloadFunction("fromDate",""))
            dispatch(CourseActions.initiateOnloadFunction("toDate",""))
            dispatch(CourseActions.initiateOnloadFunction("courseCode",""))
            dispatch(CourseActions.initiateOnloadFunction("numberOfUsers",""))
            dispatch(CourseActions.initiateOnloadFunction("enableLiveClassroom",true))
            dispatch(CourseActions.initiateOnloadFunction("slotHours",""))
            dispatch(CourseActions.initiateOnloadFunction("enableRemoteProctoring",true))
            dispatch(CourseActions.initiateOnloadFunction("isUserSlotBooking",true))

            var userDetails=JSON.parse(sessionStorage.getItem("userData"))
            if(userDetails.type===1){
              props.history.push("/courses")
            }else if(userDetails.type===2){
              props.history.push("/dlt/courses")
            }else{
              props.history.push("/sub-admin/courses")
            }

          } catch (err) {
            console.log("error",err)
            dispatch(CourseActions.setBooleanValue("errorSnackbarMessage","Error try again"))
             snackbar.errorSnackbar();
            dispatch(CourseActions.setBooleanValue("showCourseSaveLoader",false))
          }
      }else{
         dispatch(CourseActions.setBooleanValue("errorSnackbarMessage","Please select atleast one resource or application"))
         snackbar.errorSnackbar();
      }
   }
}

export function updateCourse (e){
   e.preventDefault()
   return async(dispatch ,getState)=> {

      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      var finalObject=getState().courseState.selectedCourse;
      // console.log("finalObject",finalObject)
      var object={};
      // var fromDateInSec = new Date(finalObject.startDate);
      // var tempDate = fromDateInSec.getDate();
      // var tempMonth = fromDateInSec.getMonth()+1;
      // var tempYear = fromDateInSec.toString().substring(11,15);
      // var fromDate = tempDate + "/" + tempMonth + "/" + tempYear;
      //
      // var toDateInSec = new Date(finalObject.endDate);
      // var tempToDate = toDateInSec.getDate();
      // var tempToMonth = toDateInSec.getMonth()+1;
      // var tempToYear = toDateInSec.toString().substring(11,15);
      // var toDate = tempToDate + "/" + tempToMonth + "/" + tempToYear;
      var resources=[];
      var count=0;
      for(var j=0;j<finalObject.resources.length;j++){
         if(finalObject.resources[j].type===4){
            count=count+ parseInt(finalObject.resources[j].timeInvestment);
            resources.push(finalObject.resources[j]._id)
         }else{
            count=count+ parseInt(finalObject.resources[j].file[0].timeInvestment);
            resources.push(finalObject.resources[j]._id)
         }

      }
      var applications  = [];
      // var admin_id = "6346424646233747233";
      var selectedApps = getState().courseState.selectedCourse.applications;
      selectedApps.map(obj=>{
         applications.push(obj._id)
      })
      if(finalObject.displayName !== undefined || finalObject.displayName!== null){
         object.displayName=finalObject.displayName;
      }else{
         object.displayName=finalObject.name;
      }

      object.startDate=finalObject.startDate;
      object.endDate=finalObject.endDate;
      object.name=finalObject.name;
      object.numberOfUsers=finalObject.numberOfUsers;
      // object.slotHours=finalObject.slotHours;
      object.userId=userDetails._id;
      object.belongsTo=userDetails.belongsTo;
      object.resources=resources;
      object.courseCode=finalObject.courseCode;
      object.applications=applications
      object.timeInvestment=count;
      object.emailId=userDetails.email;
      object.adminId=userDetails._id;
      object.isLiveClassRoom=finalObject.isLiveClassRoom;
      object.enableRemoteProctoring=finalObject.enableRemoteProctoring;

      if(finalObject.resources.length>0 || object.applications.length>0){
         dispatch(CourseActions.setBooleanValue("showCourseUpdateLoader",true))
         try {
            let response = await customHttp.put('/api/course/' + finalObject._id + '/update',object);
            // console.log("response",response.data.message);
            dispatch(CourseActions.setBooleanValue("successSnackbarMessage","Course updated successfully"))
            snackbar.successSnackbar();
            dispatch(CourseActions.setBooleanValue("showCourseList",true))
            dispatch(CourseActions.setBooleanValue("showCourseUpdateLoader",false))
            dispatch(getAllCreatedCourses())

          } catch (err) {
            console.log("error",err)
            dispatch(CourseActions.setBooleanValue("showCourseList",true))
            dispatch(CourseActions.setBooleanValue("showCourseUpdateLoader",false))
            dispatch(getAllCreatedCourses())
            dispatch(CourseActions.setBooleanValue("errorSnackbarMessage","Error try again"))
            snackbar.errorSnackbar();
          }
      }else{
         dispatch(CourseActions.setBooleanValue("errorSnackbarMessage","Please select atleast one resource"))
         snackbar.errorSnackbar();
      }

   }
}
export function deleteEditSelectedResource (data){
   return (dispatch ,getState)=> {
      var selectedCourse=JSON.parse(JSON.stringify(getState().courseState.selectedCourse))

      for(var k=0;k<selectedCourse.resources.length;k++){
         if(selectedCourse.resources[k]._id===data._id){
            selectedCourse.resources.splice(k,1)
         }
      }
      dispatch(CourseActions.initiateOnloadFunction("selectedCourse",selectedCourse))
      const all = {_id:'all'}
      const selectedArchiveType={name:"Unarchived",_id:false}
      dispatch(getAllCourseResources(all,all,selectedArchiveType,undefined,"onload"))
      dispatch(CourseActions.setBooleanValue("successSnackbarMessage","Resource removed successfully"))
      snackbar.successSnackbar();
   }
}



// GET CREATED COURSES

export function getAllCreatedCourses (){
   return async(dispatch ,getState)=> {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
       dispatch(CourseActions.setBooleanValue("availableCourseLoader",true))
       try{
         let response = await customHttp.get('/api/course/allCourses/user/'+userDetails._id);
         // console.log("courses",response)
            if(response.data.message && Array.isArray(response.data.message)){
            var tempArray = response.data.message.reverse()
            dispatch(CourseActions.getAllCreatedCourses(tempArray));
            // console.log("tempArray",response.data.message.reverse())
            dispatch(CourseActions.setBooleanValue("createdCourseArrayFirstObject",tempArray[0]))
            dispatch(CourseActions.setBooleanValue("availableCourseLoader",false))
            dispatch(CourseActions.setBooleanValue("showOnApiResponse",true))
            sessionStorage.setItem('adminSlotBookingCourse',JSON.stringify([]));
            }else{
               dispatch(CourseActions.getAllCreatedCourses([]));
               dispatch(CourseActions.setBooleanValue("availableCourseLoader",false));
               sessionStorage.setItem('adminSlotBookingCourse',JSON.stringify([]));
            }
       }
       catch (err) {
         dispatch(CourseActions.getAllCreatedCourses([]));
         dispatch(CourseActions.setBooleanValue("availableCourseLoader",false));
         sessionStorage.setItem('adminSlotBookingCourse',JSON.stringify([]));
         console.log("error",err)
       }
   }
}

 export function animationFunctionForPopup (){
   return (dispatch ,getState)=> {
       dispatch(CourseActions.setBooleanValue("shouldPopupAnimate",true))
       setTimeout(function(){ dispatch(CourseActions.setBooleanValue("shouldPopupAnimate",false)) }, 1000);
   }
}
export function getSelectedCourse(popupId,item){
   return (dispatch ,getState)=> {
      if(popupId!=="none"){
         document.getElementById(popupId).style.display='block'
      }
      dispatch(CourseActions.getSelectedCourse(item));
   }
}


export function deleteCourse(popupId){
   return async(dispatch ,getState)=> {
      var selectedCourse=JSON.parse(JSON.stringify(getState().courseState.selectedCourse))
      dispatch(CourseActions.setBooleanValue("disableCourseDelete",false))
      try {
         let response = await customHttp.delete('/api/course/'+selectedCourse._id);
         dispatch(CourseActions.setBooleanValue("successSnackbarMessage","Course deleted successfully"))
         dispatch(CourseActions.setBooleanValue("disableCourseDelete",true))
         document.getElementById(popupId).style.display='none'
         dispatch(getAllCreatedCourses())
         snackbar.successSnackbar();
         } catch (err) {
          document.getElementById(popupId).style.display='none'
          dispatch(CourseActions.setBooleanValue("errorSnackbarMessage","Error try again"))
          snackbar.errorSnackbar();
         console.log("error",err)
         }
   }
}

// export function initiateOnloadFunction(){
//    return (dispatch ,getState)=> {
//       console.log("user data",JSON.parse(sessionStorage.getItem("userData")))
//       if(JSON.parse(localStorage.getItem("selectedResources"))!== null && JSON.parse(localStorage.getItem("selectedResources"))!== undefined){
//          var temp=JSON.parse(localStorage.getItem("selectedResources"))
//          dispatch(CourseActions.initiateOnloadFunction("selectedResources",temp))
//       }else{
//          dispatch(CourseActions.initiateOnloadFunction("selectedResources",[]))
//       }

//    }
// }

export function handleFilterChangeCreateCourse(e){
   return (dispatch, getState)=> {
      //setSearchValue('');
      dispatch(CourseActions.setCourseElements('selected',[]));
      const archive = JSON.parse(e.target.value);
         // console.log("dropdown value",archive)
      const temp = JSON.parse(e.target.value);
      if(e.target.name==='selectedDiscipline'){
         // console.log("dropdown value",e.target.name)
         dispatch(setCourseElements('selectedDiscipline',temp));
         const cate=JSON.parse(JSON.stringify(getState().courseState.selectedContentType));
         const archive=JSON.parse(JSON.stringify(getState().courseState.selectedArchiveType));
         const searchValue=getState().courseState.searchValue;
         if(Object.keys(searchValue).length===0){
            dispatch(getAllCreateCourseResources(temp,cate,archive));
            // console.log("discipline selected no search",temp,cate,archive);
         }else{
            dispatch(getAllCreateCourseResources(temp,cate,archive,searchValue,"onload"));
            // console.log("discipline selected with search",temp,cate,archive,searchValue);
         }
      } else if(e.target.name==='selectedContentType'){
         // console.log("dropdown value",e.target.name)
         dispatch(setCourseElements('selectedContentType',temp));
         const disc=JSON.parse(JSON.stringify(getState().courseState.selectedDiscipline))
         // console.log("disc",disc);
         const archive=JSON.parse(JSON.stringify(getState().courseState.selectedArchiveType))
         const searchValue=getState().courseState.searchValue;
         if(Object.keys(searchValue).length===0){
            dispatch(getAllCreateCourseResources(disc,temp,archive));
            // console.log("content selected no search",disc,temp,archive);
         }else{
            dispatch(getAllCreateCourseResources(disc,temp,archive,searchValue));
            // console.log("content selected with search",disc,temp,archive,searchValue);
         }
      }else {
         const disc=JSON.parse(JSON.stringify(getState().courseState.selectedDiscipline))
         const cate=JSON.parse(JSON.stringify(getState().courseState.selectedContentType));
         const searchValue=getState().courseState.searchValue;
         const archive = JSON.parse(e.target.value);
         dispatch(setCourseElements('selectedArchiveType',archive));
         if(Object.keys(searchValue).length===0){
            dispatch(getAllCreateCourseResources(disc,cate,archive));
         }else{
            dispatch(getAllCreateCourseResources(disc,cate,archive,searchValue));
         }
      }
   }
 }

 export function setCourseElements(name,content) {
   //  console.log("name,content",name,content);
   return (dispatch ,getState)=> {
      dispatch(CourseActions.setCourseElements(name,content))
   }
}

export function getAllDisciplines(){
   return async (dispatch, getState)=> {
      try {
      let response = await customHttp.get("/api/branch/");
      let res = JSON.parse(JSON.stringify(response.data.message));
      if(Array.isArray(response.data.message)){
         res.push({_id:'all',name:'All'});
         dispatch(CourseActions.setDisciplines(res.reverse()));
         dispatch(setCourseElements('createCourseDisciplines',response.data.message))
      } else {
         dispatch(CourseActions.setDisciplines([{_id:'all',name:'All'}]));
         dispatch(setCourseElements('createCourseDisciplines',[]))
      }
      } catch (err) {
         dispatch(CourseActions.setDisciplines([{_id:'all',name:'All'}]));
         dispatch(setCourseElements('createCourseDisciplines',[]))
         console.log("error",err);
      }
   }
 }

 export function getAllContentTypes(){
   return async (dispatch, getState)=> {
      try {
         let response = await customHttp.get("/api/category/");
         let res = JSON.parse(JSON.stringify(response.data.message));
         if(Array.isArray(response.data.message)){
            res.push({_id:'all',name:'All'})
            dispatch(CourseActions.setContentTypes(res.reverse()));
            dispatch(setCourseElements('createCourseContentTypes',response.data.message))
         } else {
            dispatch(CourseActions.setContentTypes([{_id:'all',name:'All'}]));
            dispatch(setCourseElements('createCourseContentTypes',[]))
         }
      } catch (err) {
         dispatch(CourseActions.setContentTypes([{_id:'all',name:'All'}]));
         dispatch(setCourseElements('createCourseContentTypes',[]))
         console.log("error",err)
      }
   }
 }

 export function handleSearchCreateCourse(array,search){
   return (dispatch, getState)=> {
      dispatch(CourseActions.setCourseElements('searchValue',search));
      if(Object.keys(search).length===0){
         const disc=JSON.parse(JSON.stringify(getState().courseState.selectedDiscipline))
         const cate=JSON.parse(JSON.stringify(getState().courseState.selectedContentType));
         const archive=JSON.parse(JSON.stringify(getState().courseState.selectedArchiveType))
         dispatch(getAllCreateCourseResources(disc,cate,archive));
      }
   }
 }

 export function handleSubmitSearchCreateCourse(e){
   return async (dispatch, getState)=> {
      e.preventDefault();
      const searchValue=getState().courseState.searchValue;
      if(Object.keys(searchValue).length>0){
         const disc=JSON.parse(JSON.stringify(getState().courseState.selectedDiscipline))
         const cate=JSON.parse(JSON.stringify(getState().courseState.selectedContentType));
         const archive=JSON.parse(JSON.stringify(getState().courseState.selectedArchiveType))
         dispatch(getAllCreateCourseResources(disc,cate,archive,searchValue));
      }
   }
}

export function getAllCourseResources(disc,cate,archive,searchValue,onload){
   // console.log("values",disc,cate,archive,searchValue);
   return async (dispatch ,getState)=> {
      // console.log("disc",disc,"cate",cate,archive,searchValue,"checked array",JSON.parse(JSON.stringify(getState().courseState.resourceVideo)));
      dispatch(setCourseElements('showLoadCourseLoader',true))
      try {
      let response = await customHttp.get("/api/resourceuri/"+disc._id+"/"+cate._id+"/"+archive._id+"/"+searchValue);
      dispatch(setCourseElements('showLoadCourseLoader',false))
      // console.log("response",response);
      var selectedArray=[];
      selectedArray = JSON.parse(JSON.stringify(getState().courseState.selectedCourse.resources))
         if(selectedArray.length>0){
          for(var k=0;k<selectedArray.length;k++){
            for(var l=0;l<response.data.message.length;l++){
             if(response.data.message[l]._id===selectedArray[k]._id){
               response.data.message.splice(l,1);
             }
            }
          }
         }
         //  console.log("selectedArray",selectedArray);
      if(response.data.message && Array.isArray(response.data.message)){
         var arr = response.data.message;
         arr = arr.map(obj=> ({ ...obj, isChecked: false }));
         var resourceOrgArray=JSON.parse(JSON.stringify(getState().courseState.resourceOriginalArray))
         if(resourceOrgArray.length>0 && arr.length>0 && onload!=="onload"){
            for(var i=0;i<resourceOrgArray.length;i++){
               for(var j=0;j<arr.length;j++){
                  if(resourceOrgArray[i]._id===arr[j]._id){
                     arr[j].isChecked=resourceOrgArray[i].isChecked;
                  }
               }
            }
         }
         var arr1 = JSON.parse(JSON.stringify(arr));
         dispatch(CourseActions.setResourceArray(arr))
         //dispatch(resourceActions.setResourceOriginalArray(arr1))
         dispatch(CourseActions.setFilteredArray(arr));
         //dispatch(handleSearch(arr,getState().resourceState.searchValue))
      } else {
         dispatch(CourseActions.setResourceArray([]))
         //dispatch(resourceActions.setResourceOriginalArray([]));
         dispatch(CourseActions.setFilteredArray([]))
      }
      } catch (err) {
         dispatch(setCourseElements('showLoadResourceLoader',false))
         dispatch(CourseActions.setResourceArray([]))
         //dispatch(resourceActions.setResourceOriginalArray([]))
         dispatch(CourseActions.setFilteredArray([]))
         console.log("error",err)
      }
   }
}

export function getAllCreateCourseResources(disc,cate,archive,searchValue,onload){
   // console.log("values",disc,cate,archive,searchValue);
   return async (dispatch ,getState)=> {
      // console.log("disc",disc,"cate",cate,archive,searchValue,"checked array",JSON.parse(JSON.stringify(getState().courseState.resourceVideo)));
      dispatch(setCourseElements('showLoadCourseLoader',true))
      try {
      let response = await customHttp.get("/api/resourceuri/"+disc._id+"/"+cate._id+"/"+archive._id+"/"+searchValue);
      dispatch(setCourseElements('showLoadCourseLoader',false))
      // console.log("response",response);
      var selectedArray=[];
      selectedArray = JSON.parse(JSON.stringify(getState().courseState.selectedResources))
         if(selectedArray.length>0){
          for(var k=0;k<selectedArray.length;k++){
            for(var l=0;l<response.data.message.length;l++){
             if(response.data.message[l]._id===selectedArray[k]._id){
               response.data.message.splice(l,1);
             }
            }
          }
         }
         //  console.log("selectedArray",selectedArray);
      if(response.data.message && Array.isArray(response.data.message)){
         var arr = response.data.message;
         arr = arr.map(obj=> ({ ...obj, isChecked: false }));
         var resourceOrgArray=JSON.parse(JSON.stringify(getState().courseState.resourceOriginalArray))
         if(resourceOrgArray.length>0 && arr.length>0 && onload!=="onload"){
            for(var i=0;i<resourceOrgArray.length;i++){
               for(var j=0;j<arr.length;j++){
                  if(resourceOrgArray[i]._id===arr[j]._id){
                     arr[j].isChecked=resourceOrgArray[i].isChecked;
                  }
               }
            }
         }
         var arr1 = JSON.parse(JSON.stringify(arr));
         dispatch(CourseActions.setResourceArray(arr))
         //dispatch(resourceActions.setResourceOriginalArray(arr1))
         arr.forEach(function(element) { element.isSelected = false});
         dispatch(CourseActions.setFilteredArray(arr));
         //dispatch(handleSearch(arr,getState().resourceState.searchValue))
      } else {
         dispatch(CourseActions.setResourceArray([]))
         //dispatch(resourceActions.setResourceOriginalArray([]));
         dispatch(CourseActions.setFilteredArray([]))
      }
      } catch (err) {
         dispatch(setCourseElements('showLoadResourceLoader',false))
         dispatch(CourseActions.setResourceArray([]))
         //dispatch(resourceActions.setResourceOriginalArray([]))
         dispatch(CourseActions.setFilteredArray([]))
         console.log("error",err)
      }
   }
}

export function handleFilterChange(e){
   return (dispatch, getState)=> {
      //setSearchValue('');
      const archive = JSON.parse(e.target.value);
         // console.log("dropdown value",archive)
      const temp = JSON.parse(e.target.value);
      if(e.target.name==='selectedDiscipline'){
         // console.log("dropdown value",e.target.name)
         dispatch(setCourseElements('selectedDiscipline',temp));
         const cate=JSON.parse(JSON.stringify(getState().courseState.selectedContentType));
         const archive=JSON.parse(JSON.stringify(getState().courseState.selectedArchiveType));
         const searchValue=getState().courseState.searchValue;
         if(Object.keys(searchValue).length===0){
            dispatch(getAllCourseResources(temp,cate,archive));
            // console.log("discipline selected no search",temp,cate,archive);
         }else{
            dispatch(getAllCourseResources(temp,cate,archive,searchValue,"onload"));
            // console.log("discipline selected with search",temp,cate,archive,searchValue);
         }
      } else if(e.target.name==='selectedContentType'){
         // console.log("dropdown value",e.target.name)
         dispatch(setCourseElements('selectedContentType',temp));
         const disc=JSON.parse(JSON.stringify(getState().courseState.selectedDiscipline))
         // console.log("disc",disc);
         const archive=JSON.parse(JSON.stringify(getState().courseState.selectedArchiveType))
         const searchValue=getState().courseState.searchValue;
         if(Object.keys(searchValue).length===0){
            dispatch(getAllCourseResources(disc,temp,archive));
            // console.log("content selected no search",disc,temp,archive);
         }else{
            dispatch(getAllCourseResources(disc,temp,archive,searchValue));
            // console.log("content selected with search",disc,temp,archive,searchValue);
         }
      }else {
         const disc=JSON.parse(JSON.stringify(getState().courseState.selectedDiscipline))
         const cate=JSON.parse(JSON.stringify(getState().courseState.selectedContentType));
         const searchValue=getState().courseState.searchValue;
         const archive = JSON.parse(e.target.value);
         dispatch(setCourseElements('selectedArchiveType',archive));
         if(Object.keys(searchValue).length===0){
            dispatch(getAllCourseResources(disc,cate,archive));
         }else{
            dispatch(getAllCourseResources(disc,cate,archive,searchValue));
         }
      }
   }
 }

 export function handleSearch(array,search){
   return (dispatch, getState)=> {
      dispatch(CourseActions.setCourseElements('searchValue',search));
      if(Object.keys(search).length===0){
         const disc=JSON.parse(JSON.stringify(getState().courseState.selectedDiscipline))
         const cate=JSON.parse(JSON.stringify(getState().courseState.selectedContentType));
         const archive=JSON.parse(JSON.stringify(getState().courseState.selectedArchiveType))
         dispatch(getAllCourseResources(disc,cate,archive));
      }
   }
 }

 export function handleSubmitSearch(e){
   return async (dispatch, getState)=> {
      e.preventDefault();
      const searchValue=getState().courseState.searchValue;
      if(Object.keys(searchValue).length>0){
         const disc=JSON.parse(JSON.stringify(getState().courseState.selectedDiscipline))
         const cate=JSON.parse(JSON.stringify(getState().courseState.selectedContentType));
         const archive=JSON.parse(JSON.stringify(getState().courseState.selectedArchiveType))
         dispatch(getAllCourseResources(disc,cate,archive,searchValue));
      }
   }
}

export  function downloadFile (obj){
   return async (dispatch ,getState)=> {
      var userDetails=JSON.parse(sessionStorage.getItem("userData"))
      var courseObject=JSON.parse(JSON.stringify(getState().courseState.playListArrayForDownload))
     // console.log("tdrtfyguhji","course",courseObject,"resourse",obj)
       try {
          let response = await customHttp.get('/api/userQuiz/quizResult/adminId/'+userDetails._id+'/belongsTo/'+userDetails.belongsTo+'/resourceId/'+obj._id+'/courseId/'+courseObject._id);
          if(Object.keys(response.data.message).length>0){
             var data=window.location.origin+response.data.message.filePath;
            // var data="http://localhost:2828"+response.data.message.filePath;
             var Download = document.createElement('a');
              Download.setAttribute('href', data);
              Download.setAttribute('download', response.data.message.fileName);
              Download.setAttribute('target', '_blank');
              Download.click()

          }else{
            dispatch(CourseActions.setBooleanValue("errorSnackbarMessage"," No user has attempted this quiz"))
            snackbar.errorSnackbar();
          }

        } catch (err) {
         dispatch(CourseActions.setBooleanValue("errorSnackbarMessage","Error try again"))
          snackbar.errorSnackbar();
          console.log("error",err)
        }
   }
}
//incoming
export function initiateOnloadFunction(){
   return (dispatch ,getState)=> {
      dispatch(getAllApplications())
      // console.log("user data",JSON.parse(sessionStorage.getItem("userData")))   ;
      var temp = []
      if(JSON.parse(localStorage.getItem("selectedResources"))!== null && JSON.parse(localStorage.getItem("selectedResources"))!== undefined){
         temp=JSON.parse(localStorage.getItem("selectedResources"))
         // console.log("selected resources",temp)
         dispatch(CourseActions.initiateOnloadFunction("selectedResources",temp))
      } else{
         // console.log("selected resources",JSON.parse(localStorage.getItem("selectedResources")))
         dispatch(CourseActions.initiateOnloadFunction("selectedResources",[]))
      }
      const apps = JSON.parse(localStorage.getItem("appsSelected"))
      if(apps!==null && apps!==undefined){
         temp = JSON.parse(localStorage.getItem("appsSelected"))
         // console.log("selected resources",temp)
         dispatch(CourseActions.initiateOnloadFunction("selectedApplications",temp))
      } else {
         dispatch(CourseActions.initiateOnloadFunction("selectedApplications",[]))
      }
   }
}

function uniqueApps(array) {
   var processed = [];
   for (var i=array.length-1; i>=0; i--){
     if (processed.indexOf(array[i].name)<0) {
       processed.push(array[i].name);
     } else {
       array.splice(i, 1);
     }
   }
   return array;
 }

 export function getSelectedLiveClassroomValue() {
   return (dispatch ,getState)=> {
      var liveClassroomEnabled=JSON.parse(JSON.stringify(getState().courseState.enableLiveClassroom))
      var temp=!liveClassroomEnabled;
      dispatch(CourseActions.initiateOnloadFunction("enableLiveClassroom",temp))
     
   }
}
export function getSelectedRemoteProctoringValue() {
   return (dispatch ,getState)=> {
      var remoteProctoringEnabled=JSON.parse(JSON.stringify(getState().courseState.enableRemoteProctoring))
      var temp=!remoteProctoringEnabled;
      dispatch(CourseActions.initiateOnloadFunction("enableRemoteProctoring",temp))
     
   }
}
export function editSelectedLiveClassroomValue() {
   return (dispatch ,getState)=> {
      var liveClassroomEnabled=JSON.parse(JSON.stringify(getState().courseState.selectedCourse.isLiveClassRoom))
      var temp=!liveClassroomEnabled;
      dispatch(CourseActions.editCourseInputField("isLiveClassRoom",temp))
     
   }
}
export function editSelectedRemoteProctoringValue() {
   return (dispatch ,getState)=> {
      var remoteProctoringEnabled=JSON.parse(JSON.stringify(getState().courseState.selectedCourse.enableRemoteProctoring))
      var temp=!remoteProctoringEnabled;
      dispatch(CourseActions.editCourseInputField("enableRemoteProctoring",temp))
     
   }
}

export function handleBookSlotOptionChange (e){
   return async (dispatch)=> {
      dispatch(CourseActions.setBooleanValue("isUserSlotBooking",(String(e.target.value) === "true")))
   }
}

export function getFirstValue (data){
   return (dispatch ,getState)=> {
      dispatch(CourseActions.setBooleanValue("createdCourseArrayFirstObject",data))
   }
}

export function displayAlert(type,msg) {
   return async (dispatch)=> {
      console.log("error called")
      if(type==='success'){
         dispatch(CourseActions.setBooleanValue('successSnackbarMessage',msg));
         snackbar.successSnackbar();
       } else if(type==='error'){
         dispatch(CourseActions.setBooleanValue('errorSnackbarMessage',msg));
         snackbar.errorSnackbar(); 
      }
   }
}