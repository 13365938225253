
// import axios from "axios";
import * as viewPlaylistActions from "./index";
import snackbar from '../../../packages/Snackbar/';
import customHttp from '../../../packages/CustomHttp/';
import {togglePdf} from '../Resources/action';
// var userDetails=JSON.parse(sessionStorage.getItem("userData"));

export function getPlaylistResource() {
   return (dispatch ,getState)=> {
      const playlistArray = JSON.parse(localStorage.getItem('playlistResource'));
      if(playlistArray && playlistArray.playlists){
         dispatch(setViewPlaylistElements('playlistArray',playlistArray.playlists));
         dispatch(setViewPlaylistElements('playlistName',playlistArray.displayName));
         dispatch(setViewPlaylistElements('selectedPlaylistTitle',playlistArray.playlists[0]));
         dispatch(setViewPlaylistElements('selectedPlaylistSubtitle',playlistArray.playlists[0].subtitle[0]))
      }
   }
}

export function setSelectedPlaylistTitle(playlistTitle) {
   return (dispatch ,getState)=> {
      dispatch(setViewPlaylistElements('selectedPlaylistTitle',playlistTitle));
      dispatch(setViewPlaylistElements('selectedPlaylistSubtitle',playlistTitle.subtitle[0]))
   }
}

export function setSelectedPlaylistSubtitle(playlistSubtitle) {
   return (dispatch ,getState)=> {
      
      dispatch(setViewPlaylistElements('selectedPlaylistSubtitle',playlistSubtitle))
   }
}

export function openResourceLink(item,window,history) {
   return async (dispatch ,getState)=> {
      if(item.type===2){
         dispatch(setViewPlaylistElements('resourceVideo',item))
         document.getElementById('playplayListVideoPopup').style.display='block';
      } else if(item.type===0 && item.file && item.file.length>0) {
         const itemLink = window.location.origin+"/"+item.file[0].path || '/'
         var splitItem = itemLink.split(".") || [];
         if(splitItem && splitItem.length>0 && splitItem[splitItem.length-1]==='pdf'){
            document.getElementById('userPdfPopup').style.display='block';
            document.getElementById('userPdfPopupTitle').innerText = item.displayName;
            dispatch(togglePdf(true,itemLink))
         } else {
            window.open(itemLink,'_blank');
         }
      }
   }
}

export function closeVideoPopup() {
   return async (dispatch ,getState)=> {
      var x = document.getElementById("playVideo");
      x.pause()
      dispatch(setViewPlaylistElements('resourceVideo',{}));
      document.getElementById('playplayListVideoPopup').style.display='none'
   }
}

export function setViewPlaylistElements(name,content) {
   return (dispatch ,getState)=> {
      dispatch(viewPlaylistActions.setViewPlaylistElements(name,content))
   }
}

export function displayAlert(type,msg) {
   return async (dispatch ,getState)=> {
      if(type==='success'){
         dispatch(setViewPlaylistElements('successAlertMessage',msg));
         snackbar.successSnackbar();
       } else if(type==='error'){
         dispatch(setViewPlaylistElements('errorAlertMessage',msg));
         snackbar.errorSnackbar(); 
      }
   }
}
export function handleSubmit(playlistName,title,subtitle,quiz,playlistSubtitle) {
   return async (dispatch ,getState)=> {
   //  e.preventDefault();
    var userDetails=JSON.parse(sessionStorage.getItem("userData"));
    var course=JSON.parse(localStorage.getItem("courseObj"));
    
   //  console.log("quizzzzz",quiz,JSON.parse(JSON.stringify(quiz)))

    var obj={};
    obj.adminId=userDetails.adminId;
    obj.belongsTo=userDetails.belongsTo;
    obj.resourceId=JSON.parse(localStorage.getItem('playlistResource'))._id
    obj.courseId=course._id;
    obj.userId=userDetails._id;
    obj.email=userDetails.email;
    obj.playlistName=playlistName;
    obj.title=title;
    obj.subtitle=subtitle.title;
    obj.quiz=uniqueArray(JSON.parse(JSON.stringify(quiz)));

   //  console.log("submiited obj",obj)
      try {
         let response = await customHttp.put('/api/userQuiz/saveQuiz',obj);
         dispatch(setViewPlaylistElements('successAlertMessage',"Quiz submitted successfully"));
         snackbar.successSnackbar();
        
      } catch (err) {
         dispatch(setViewPlaylistElements('errorAlertMessage',"error try again.."));
         snackbar.errorSnackbar(); 
         console.log("error",err);
      }
   
 }
}

function uniqueArray(array) {
   var processed = [];
   for (var i=array.length-1; i>=0; i--){
     if (processed.indexOf(array[i].quizQuestionId)<0) {
       processed.push(array[i].quizQuestionId);
     } else {
       array.splice(i, 1);
     }
   }
   return array;
}