import axios from 'axios';
const headers = '';
var alertPopUp = false;
var customHttp = {
    get : async (url,data) => {
        const body = data || {};
        try {
            var userData = JSON.parse(sessionStorage.getItem("userData"));
            // console.log("userData",userData);
            var token={ headers: {"Authorization" : `Bearer ${userData.token}`,"id":userData._id}}
            localStorage.setItem("config",JSON.stringify(token));
            var config = JSON.parse(localStorage.getItem("config"))
            // console.log("token generated customHttp get",url,token,config);

            var response = await axios.get(url,config);
            return response;
        } catch (err){
            if(err.response){
              if(err.response.data.message === "jwt expired" || err.response.data.message === "Invalid userId provided for the token." || err.response.data.message === "No token provided." || err.response.data.message === "invalid signature."){
                console.log("token invalid get");
                if(alertPopUp === false){
                  alert('Token Expired or Invalid Token\n'+'Please re-login!!');
                  alertPopUp = true;
                  localStorage.removeItem("userData");
                  localStorage.removeItem("config");
                  window.location = '/';
                }
              }else{
                throw err;
              }
            }else{
              console.log("throw err");
              throw err;
            }

        }
    },
    post : async (url,data) => {
        const body = data || {};
        try {
            var userData = JSON.parse(sessionStorage.getItem("userData"));
            var token={ headers: {"Authorization" : `Bearer ${userData.token}`,"id":userData._id}}
            localStorage.setItem("config",JSON.stringify(token));
            var config = JSON.parse(localStorage.getItem("config"))
            // console.log("token generated customHttp post",url,data,token,config);

            var response = await axios.post(url,body,config,{headers: { "Content-Type": undefined }});
            // console.log("response",response)
            return response;
        } catch (err){
            if(err.response){
              if(err.response.data.message === "jwt expired" || err.response.data.message === "Invalid userId provided for the token." || err.response.data.message === "No token provided." || err.response.data.message === "invalid signature."){
                console.log("token invalid");
                if(alertPopUp === false){
                  alert('Token Expired or Invalid Token\n'+'Please re-login!!');
                  alertPopUp = true;
                  localStorage.removeItem("userData");
                  localStorage.removeItem("config");
                  window.location = '/';
                }
              }else{
                throw err;
              }
            }else{
              throw err;
            }
        }
    },
    put : async (url,data) => {
        const body = data || {};
        try {
            var userData = JSON.parse(sessionStorage.getItem("userData"));
            var token={ headers: {"Authorization" : `Bearer ${userData.token}`,"id":userData._id}}
            localStorage.setItem("config",JSON.stringify(token));
            var config = JSON.parse(localStorage.getItem("config"))
            // console.log("token generated customHttp put",url,token,config);

            var response = await axios.put(url,body,config);
            return response;
        } catch (err){
            if(err.response){
              if(err.response.data.message === "jwt expired" || err.response.data.message === "Invalid userId provided for the token." || err.response.data.message === "No token provided." || err.response.data.message === "invalid signature."){
                console.log("token invalid");
                if(alertPopUp === false){
                  alert('Token Expired or Invalid Token\n'+'Please re-login!!');
                  alertPopUp = true;
                  localStorage.removeItem("userData");
                  localStorage.removeItem("config");
                  window.location = '/';
                }
              }else{
                throw err;
              }
            }else{
              throw err;
            }
        }
    },
    delete : async (url,data) => {
        const body = data || {};
        try {
            var userData = JSON.parse(sessionStorage.getItem("userData"));
            var token={ headers: {"Authorization" : `Bearer ${userData.token}`,"id":userData._id}}
            localStorage.setItem("config",JSON.stringify(token));
            var config = JSON.parse(localStorage.getItem("config"))
            // console.log("token generated customHttp delete",url,token,config);

            var response = await axios.delete(url,{data:body, headers:config.headers});
            return response;
        } catch (err){
            if(err.response){
              if(err.response.data.message === "jwt expired" || err.response.data.message === "Invalid userId provided for the token." || err.response.data.message === "No token provided." || err.response.data.message === "invalid signature."){
                console.log("token invalid");
                if(alertPopUp === false){
                  alert('Token Expired or Invalid Token\n'+'Please re-login!!');
                  alertPopUp = true;
                  localStorage.removeItem("userData");
                  localStorage.removeItem("config");
                  window.location = '/';
                }
              }else{
                throw err;
              }
            }else{
              throw err;
            }
        }
    }
}

// var customHttp = {
//     get : async (url,data) => {
//         const body = data || {};
//         try {
//             var response = await axios.get(url,body);
//             return response;
//         } catch (err){
//             throw err
//         }
//     },
//     post : async (url,data) => {
//         const body = data || {};
//         try {
//             var response = await axios.post(url,body,{headers: { "Content-Type": undefined }});
//             return response;
//         } catch (err){
//             throw err
//         }
//     },
//     put : async (url,data) => {
//         const body = data || {};
//         try {
//             var response = await axios.put(url,body);
//             return response;
//         } catch (err){
//             throw err
//         }
//     },
//     delete : async (url,data) => {
//         const body = data || {};
//         try {
//             var response = await axios.delete(url,{data:body});
//             return response;
//         } catch (err){
//             throw err
//         }
//     }
// }

export default customHttp;
