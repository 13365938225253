import * as resourceActionTypes from "../../../actionTypes";
const initialState = {
  borrowedResources:false,
  disciplines: [],
  contentTypes: [],
  selectedDiscipline:{_id:'all'},
  selectedContentType:{_id:'all'},
  resourceArray: [],
  resourceOriginalArray: [],
  showRequestResource: false,
  filteredArray:[],
  searchValue:'',
  resource: {name:'',discipline:'',contentType:'',file:{},tags:"",version:""},
  createResourceDisciplines:[],
  uploadResourcesPopupDisciplines:[],
  createResourceContentTypes:[],
  showResourceCreateLoader:false,
  showLoadResourceLoader:false,
  resourceVideo:{},
  successAlertMessage:'',
  errorAlertMessage:'',
  userRatings:[],
  archiveSubmitLoader:true,
  unarchiveSubmitLoader:true,
  selectedArchiveResource:{},
  selectedUnarchiveResource:{},
  selectedArchiveType:{name:"Unarchived",_id:false},
  archiveArray:[{name:"Unarchived",_id:false},{name:"Archived",_id:true}],
  videoCurrentTime:"",
  sharingResourceObject:{},
  smeUserList:[],
  selectedSmeUser:{},
  sharedResourceLoader:false,
  showSharedResourceLink:false,
  selectedResourceForVersionChange:{},
  allowFileUpload:false,
  versionControlResourceObj:{version:"",file:{},isChecked:false},
  timeInvestment:{hours:"",minutes:"",seconds:""},
  addNewVersionFileLoader:false,
  changeNewVersionLoader:false,
  selected:[],
  showPdf:false,
  pdfState:{pdf: null,
    currentPage: 1,
    zoom: 1
  },
  pdfLoader:false,
  htmlFileDetails:{},
}

export default function(state=initialState, action) { 
  switch (action.type) {
    case resourceActionTypes.RESOURCE_DISCIPLINES: {
      const {content } = action.payload; 
      return {
        ...state,disciplines:content
      }
    }
    case resourceActionTypes.RESOURCE_CONTENT_TYPES: {
      const {content } = action.payload; 
      return {
        ...state,contentTypes:content
      }
    }
    case resourceActionTypes.RESOURCE_SELECTED_DISCIPLINE: {
      const {content } = action.payload; 
      return {
        ...state,selectedDiscipline:content
      }
    }
    case resourceActionTypes.RESOURCE_SELECTED_CONTENT_TYPE: {
      const {content } = action.payload; 
      return {
        ...state,selectedContentType:content
      }
    }
    case resourceActionTypes.RESOURCE_RESOURCE_ARRAY: {
      const {content } = action.payload;
      return {
        ...state,resourceArray:content
      }
    }
    case resourceActionTypes.RESOURCE_RESOURCE_ORIGINAL_ARRAY: {
      const {content } = action.payload; 
      return {
        ...state,resourceOriginalArray:content
      }
    }
    case resourceActionTypes.RESOURCE_SHOW_REQUEST_RESOURCE: {
      const {content } = action.payload; 
      return {
        ...state,showRequestResource:content
      }
    }
    case resourceActionTypes.RESOURCE_FILTERED_ARRAY: {
      const {content } = action.payload; 
      return {
        ...state,filteredArray:content
      }
    }
    case resourceActionTypes.RESOURCE_COMPONENT_ELEMENTS: {
      const {name,content } = action.payload; 
      return {
        ...state,[name]:content
      }
    }
    default:
      return state;
  }
}