
import * as AppstoreActionTypes from "../../../actionTypes";


export const getAllApplications = (allApplications,applicationList_dummy) => ({
    type: AppstoreActionTypes.APPSTORE_GET_ALL_APPLICATIONS,
    payload: {
        allApplications,
        applicationList_dummy
      }
    
   });

 export const filteredCategories = (filterArray,iotFilterArray) => ({
    type: AppstoreActionTypes.APPSTORE_FILTERED_CATEGORIES,
    payload: {
        filterArray,
        iotFilterArray
      }
   });
    

  export const selectAllApplications = (selected_dummy,applicationArray,applicationList) => ({
     type: AppstoreActionTypes.APPSTORE_ONCLICK_SELECT_ALL_APPS,
     payload: {selected_dummy,
     applicationArray,
     applicationList}
   });

  
  export const setCategoryName = (value) => ({
     type: AppstoreActionTypes.APPSTORE_SET_CATEGORY_NAME,
     payload: {value}
    });

  export const setApplicationName = (value) => ({
     type: AppstoreActionTypes.APPSTORE_SET_APPLICATION_NAME,
     payload: {value}
    });

  export const filtered_ApplicationsArray = (applicationArray) => ({
     type: AppstoreActionTypes.APPSTORE_FILTERED_APPLICATIONS_ARRAY,
     payload: {applicationArray}
    });


   export const selected_Applications = (dublicateApplicationArray,dublicateAapplicationList,dummy) => ({
     type: AppstoreActionTypes.APPSTORE_SELECTED_APPLICATIONS,
     payload: {dublicateApplicationArray,
     dublicateAapplicationList,
     dummy}
    });

    export const set_Message = (msg) => ({
      type: AppstoreActionTypes.APPSTORE_SET_MESSAGE,
      payload: { msg}
    });

    export const selecttemplate = (x) => ({
      type: AppstoreActionTypes.APPSTORE_SELECT_TEMPLATE,
      payload: { x}
    });





 